import React, { Fragment, useState } from "react";
import { Container, Input } from "reactstrap";
import { Table } from "react-bootstrap";
import axios from "axios";
import { PropTypes } from "prop-types";

import { Typography, Stack, IconButton } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomButton } from "../../elements/StyledElements";
import Collapsible from "../../elements/Collapsible";
import MultiSelect from "../../elements/MultiSelect";
import DropDown from "../../elements/DropDown";
import CustomModal from "../shared/modal_utils/CustomModal";

import { API_URL_WAREHOUSEPRODUCT } from "../../settings";
import { isMobileOnly } from "react-device-detect";
import { QrReader } from "react-qr-reader";
import { defaultIfEmpty } from "../../elements/utils";

const emptyProductUpdate = {
  id: null,
  amount: 0,
};
const openButtonText = {
  add: "Rückgabe Mitarbeiter",
  subtract: "Abnahme",
  update: "Lagerdifferenz",
};
export default function WarehouseBulkUpdate({
  session,
  type,
  location,
  warehouseProducts,
  warehouseProductTypes,
  suppliers,
  employee,
  resetState,
}) {
  const [showList, setShowList] = useState(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [productUpdates, setProductUpdates] = useState([]);
  const [currentProductUpdate, setCurrentProductUpdate] =
    useState(emptyProductUpdate);
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [invalidAmountIds, setInvalidAmountIds] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleReset = (isOpen) => {
    setShowList(false);
    setCurrentProductUpdate(emptyProductUpdate);
    setProductUpdates([]);
    setIsSubmitted(false);
  };

  const handleCurrentProductUpdate = (idx) => {
    const warehouseProduct = warehouseProducts.filter((e) => e.id === idx)[0];
    setCurrentProductUpdate({
      ...currentProductUpdate,
      id: warehouseProduct.id,
      name: warehouseProduct.name,
    });
  };

  const handleProductUpdateChange = (idx, productId, amount) => {
    setInvalidAmountIds(invalidAmountIds.filter((e) => e !== productId));
    const newList = [...productUpdates];
    newList[idx] = { ...productUpdates[idx], amount };
    setProductUpdates(newList);
  };

  const handleProductUpdateAdd = () => {
    if (
      !currentProductUpdate.id ||
      productUpdates.filter((e) => e.id === currentProductUpdate.id).length > 0
    ) {
      setShowErrorFields(true);
      return;
    }
    if (!currentProductUpdate.amount && currentProductUpdate.amount !== 0) {
      setShowErrorFields(true);
      return;
    }
    const newList = [...productUpdates];
    newList.push(currentProductUpdate);
    setProductUpdates(newList);
    setCurrentProductUpdate(emptyProductUpdate);
    setShowErrorFields(false);
    setShowList(true);
  };

  const handleProductUpdateDelete = (idx) => {
    setProductUpdates(productUpdates.filter((_, i) => i !== idx));
  };

  const handleShowList = () => {
    setCurrentProductUpdate(emptyProductUpdate);
    setShowErrorFields(false);
    setShowList(true);
  };

  const handleHideList = () => {
    setInvalidAmountIds([]);
    setShowErrorFields(false);
    setShowList(false);
  };

  const handleSubmit = () => {
    const newList = productUpdates
      .filter((e) => isNaN(parseInt(e.amount)) || e.amount < 0)
      .map((e) => e.id);
    if (newList.length > 0) {
      setInvalidAmountIds(newList);
      setShowErrorFields(true);
      return;
    }
    axios
      .post(API_URL_WAREHOUSEPRODUCT, {
        type,
        updates: productUpdates,
        request_as: employee,
        location,
      })
      .then((res) => {
        setIsSubmitted(true);
        resetState();
      })
      .catch((err) => {
        setShowErrorFields(true);
        setInvalidAmountIds(
          Array.isArray(err.response.data) ? err.response.data : [],
        );
      });
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton
        color="black"
        style={{
          margin: "8px 0",
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        onClick={toggle}
      >
        <Typography variant={isMobileOnly ? "body1" : "h6"}>
          {openButtonText[type]}
        </Typography>
      </CustomButton>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      title={openButtonText[type]}
      getOpenButton={getOpenButton}
      onToggle={handleReset}
    >
      {showList ? (
        <Fragment>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              style={{ alignItems: "center", marginTop: 20 }}
            >
              {isSubmitted && (
                <Typography className="secondary-textcolor">Erfolg</Typography>
              )}
              <Table className="table-not-hover">
                <thead>
                  <tr>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Index
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        {isMobileOnly && !isSubmitted
                          ? "Material\nname"
                          : "Materialname"}
                      </Typography>
                    </th>
                    {!isSubmitted && (
                      <th className="align-middle">
                        <Typography className="secondary-textcolor">
                          {(isMobileOnly ? "Lagerbes-\ntand" : "Lagerbestand") +
                            (type === "update" ? " Ist" : "")}
                        </Typography>
                      </th>
                    )}
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        {type === "update"
                          ? isMobileOnly && !isSubmitted
                            ? "Lagerbes-\ntand Soll"
                            : "Lagerbestand Soll"
                          : "Anzahl"}
                      </Typography>
                    </th>
                    {!isSubmitted && (
                      <th className="align-middle">
                        <Typography className="secondary-textcolor">
                          {"Löschen"}
                        </Typography>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {productUpdates.map((productUpdate, idx) => (
                    <tr key={idx}>
                      <td className="align-middle">{"i" + productUpdate.id}</td>
                      <td
                        className="align-middle"
                        style={
                          isMobileOnly
                            ? { maxWidth: "100px", wordWrap: "break-word" }
                            : {}
                        }
                      >
                        {productUpdate.name}
                      </td>
                      {!isSubmitted && (
                        <td className="align-middle">
                          {
                            warehouseProducts
                              .find((e) => e.id === productUpdate.id)
                              ?.amounts.find((e) => e.location_id === location)
                              ?.amount
                          }
                        </td>
                      )}
                      <td className="align-middle">
                        <Input
                          style={{ boxShadow: "none" }}
                          type="number"
                          min="0"
                          value={productUpdate.amount}
                          disabled={isSubmitted}
                          invalid={
                            showErrorFields &&
                            invalidAmountIds.includes(productUpdate.id)
                          }
                          onChange={(e) => {
                            handleProductUpdateChange(
                              idx,
                              productUpdate.id,
                              e.target.value,
                            );
                          }}
                        />
                      </td>
                      {!isSubmitted && (
                        <td className="align-middle">
                          <IconButton
                            onClick={() => {
                              handleProductUpdateDelete(idx);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isSubmitted && (
                <CustomButton onClick={handleHideList}>
                  Material Hinzufügen
                </CustomButton>
              )}
              {!isSubmitted && (
                <CustomButton onClick={handleSubmit}>Absenden</CustomButton>
              )}
              {isSubmitted && (
                <CustomButton onClick={handleReset}>
                  {openButtonText[type]}
                </CustomButton>
              )}
            </Stack>
          </Container>
        </Fragment>
      ) : (
        <Fragment>
          <Container>
            <Stack
              direction="column"
              spacing={2}
              style={{ alignItems: "center", marginTop: 20 }}
            >
              <DropDown
                onChange={(val) => {
                  handleCurrentProductUpdate(val);
                }}
                options={warehouseProducts
                  .filter((warehouseProduct) => {
                    let satisfy = !productUpdates
                      .map((e) => e.id)
                      .includes(warehouseProduct.id);
                    satisfy =
                      (selectedProductTypes.length === 0 ||
                        (selectedProductTypes.includes(0) &&
                          warehouseProduct.product_type.length === 0) ||
                        warehouseProduct.product_type.filter((e) =>
                          selectedProductTypes.includes(e),
                        ).length > 0) &&
                      satisfy;
                    satisfy =
                      (selectedSuppliers.length === 0 ||
                        selectedSuppliers.includes(
                          warehouseProduct.supplier_id,
                        )) &&
                      satisfy;
                    return satisfy;
                  })
                  .map((warehouseProduct) => ({
                    value: warehouseProduct.id,
                    label:
                      "[i" +
                      warehouseProduct.id +
                      "] " +
                      warehouseProduct.name +
                      " ([" +
                      warehouseProduct.supplier_name +
                      "] " +
                      warehouseProduct.supplier_product_name +
                      ")",
                  }))}
                value={defaultIfEmpty(currentProductUpdate.id)}
                required={true}
                invalid={
                  showErrorFields &&
                  (!currentProductUpdate.id ||
                    productUpdates.filter(
                      (e) => e.id === currentProductUpdate.id,
                    ).length > 0)
                }
                text="Eingabe"
                search={true}
              />
              <Collapsible
                getOpenButton={(toggle) => (
                  <IconButton
                    size="medium"
                    disableFocusRipple
                    disableRipple
                    style={{ backgroundColor: "transparent" }}
                    onClick={toggle}
                  >
                    <FilterAltOutlinedIcon
                      style={{ color: "#424242" }}
                      fontSize="medium"
                    />
                    <Typography className="secondary-textcolor">
                      Material filtern
                    </Typography>
                  </IconButton>
                )}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <Stack direction="column" spacing={2}>
                  <MultiSelect
                    onChange={(val) => {
                      setSelectedProductTypes(val || []);
                    }}
                    options={[{ id: 0, name: "Unsortiert" }]
                      .concat(warehouseProductTypes)
                      .map((productType) => ({
                        value: productType.id,
                        label: productType.name,
                      }))}
                    values={defaultIfEmpty(selectedProductTypes)}
                    text="Kategorie"
                    search={true}
                    name="product_type"
                  />
                  <MultiSelect
                    onChange={(val) => setSelectedSuppliers(val || [])}
                    options={suppliers.map((supplier) => ({
                      value: supplier.id,
                      label: supplier.name,
                    }))}
                    values={defaultIfEmpty(selectedSuppliers)}
                    text="Zulieferer"
                    search={true}
                    name="supplier"
                  />
                </Stack>
              </Collapsible>

              <QrReader
                key="environment"
                containerStyle={{ height: 256, width: 256 }}
                constraints={{ facingMode: { ideal: "environment" } }}
                onResult={(result, error) => {
                  if (result) {
                    try {
                      const scanId = parseInt(result?.text);
                      if (isNaN(scanId)) {
                        throw new Error(
                          "Cannot convert",
                          result?.text,
                          "to integer",
                        );
                      }
                      handleCurrentProductUpdate(scanId);
                    } catch (err) {
                      console.error(err.message);
                    }
                  }
                }}
                onError={(error) => console.log(error?.message)}
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  style={{ boxShadow: "none" }}
                  type="number"
                  min="0"
                  value={currentProductUpdate.amount}
                  onChange={(e) => {
                    setCurrentProductUpdate({
                      ...currentProductUpdate,
                      amount: parseInt(e.target.value),
                    });
                  }}
                  invalid={
                    showErrorFields &&
                    !currentProductUpdate.amount &&
                    currentProductUpdate.amount !== 0
                  }
                />
                <CustomButton
                  onClick={() => {
                    setCurrentProductUpdate({
                      ...currentProductUpdate,
                      amount: currentProductUpdate.amount + 1,
                    });
                  }}
                >
                  +
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    setCurrentProductUpdate({
                      ...currentProductUpdate,
                      amount: Math.max(currentProductUpdate.amount - 1, 0),
                    });
                  }}
                >
                  -
                </CustomButton>
              </div>
              <CustomButton onClick={handleProductUpdateAdd}>
                Hinzufügen
              </CustomButton>
              <CustomButton onClick={handleShowList}>
                Liste anzeigen
              </CustomButton>
            </Stack>
          </Container>
        </Fragment>
      )}
    </CustomModal>
  );
}

WarehouseBulkUpdate.propTypes = {
  session: PropTypes.object.isRequired,
  type: PropTypes.string,
  location: PropTypes.number,
  warehouseProducts: PropTypes.array,
  warehouseProductTypes: PropTypes.array,
  suppliers: PropTypes.array,
  employee: PropTypes.number,
  resetState: PropTypes.func,
};
