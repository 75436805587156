import React, { Fragment, useEffect, useState, useCallback } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { FormGroup, Input } from "reactstrap";

import { invoicePropType, projectPropType } from "../../elements/PropTypes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { CustomButton } from "../../elements/StyledElements";
import PercentIcon from "@mui/icons-material/Percent";
import { defaultIfEmpty, getOpenInvoiceAmount } from "../../elements/utils";
import { API_URL_DISCOUNTINVOICE } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";

export default function DiscountInvoiceModal({
  project,
  invoice,
  resetParent,
  session,
  isOpen,
  setIsOpen = null,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  const [skonto, setSkonto] = useState(null);
  const offenValue = parseFloat(getOpenInvoiceAmount(invoice));
  const isDiscountDefault =
    invoice.cash_discount === null ||
    invoice.cash_discount === "0" ||
    invoice.cash_discount === 0 ||
    invoice.cash_discount === offenValue;
  const loadData = useCallback(() => {
    if (!invoice) return;
    setSkonto(invoice.cash_discount);
    setLoadingElements({ submitError: false, inProgress: false });
  }, [invoice.id]);

  useEffect(() => {
    loadData();
  }, [invoice.id, loadData]);

  const submit = async () =>
    axios.put(API_URL_DISCOUNTINVOICE, {
      invoice: invoice.id,
      cash_discount: skonto,
    });

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    return submit()
      .then((res) => {
        resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          submitError: false,
          inProgress: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "DiscountInvoiceModal:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const handleClose = (toggle) => {
    loadData();
    toggle();
  };

  const getOpenButton = (toggle) => {
    return (
      <Tooltip title="Skonto" placement="top">
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ float: "right", backgroundColor: "transparent" }}
          size="small"
          onClick={toggle}
        >
          <PercentIcon
            className={`secondary-textcolor ${isDiscountDefault ? "primary-color" : "success-color"}`}
            fontSize="large"
            style={{
              color: isDiscountDefault ? "primary" : "green",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const getFooter = (toggle) => {
    return (
      <Fragment>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => {
            onSubmit(toggle);
          }}
          onCancel={() => handleClose(toggle)}
        />
      </Fragment>
    );
  };

  return (
    <>
      <CustomModal
        getOpenButton={getOpenButton}
        getFooter={getFooter}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size="lg"
        title={"Skonto Rechnung " + invoice.id}
        onClose={handleClose}
      >
        <FormGroup>
          <Typography className="secondary-textcolor" style={{ margin: "5px" }}>
            <strong> Skonto:</strong>
          </Typography>
          <div
            style={{ margin: "5px", marginTop: "10px", marginBottom: "10px" }}
          >
            <Typography
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              Der Rest ist Skonto:{" "}
              {parseFloat(offenValue + invoice.cash_discount).toFixed(2)}
            </Typography>
            <CustomButton
              onClick={() =>
                setSkonto(
                  parseFloat(offenValue + invoice.cash_discount).toFixed(2),
                )
              }
              color="black"
            >
              Betrag einsetzen
            </CustomButton>
          </div>
          <Input
            step={1}
            id="input"
            placeholder={isDiscountDefault ? invoice.total_net : "Skonto"}
            type="text"
            width={"15px"}
            value={defaultIfEmpty(skonto)}
            onChange={(e) => {
              const sanitizedValue = e.target.value
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1");
              if (
                sanitizedValue === "" ||
                /^[0-9]*\.?[0-9]{0,2}$/.test(sanitizedValue)
              ) {
                setSkonto(sanitizedValue);
              }
            }}
            onBlur={(e) => {
              if (e.target.value !== "")
                setSkonto(parseFloat(e.target.value).toFixed(2));
            }}
            onKeyPress={(e) => {
              if (!/^[0-9]*\.?[0-9]{0,2}$/.test(e.currentTarget.value)) {
                e.preventDefault();
              }
            }}
            style={{ boxShadow: "none" }}
            min={0}
            onKeyDown={(e) => {
              if (
                e.key === "-" ||
                (e.key === "." && e.currentTarget.value.includes("."))
              )
                e.preventDefault();
            }}
          />
        </FormGroup>
      </CustomModal>
    </>
  );
}

DiscountInvoiceModal.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
