import React from "react";

import { PropTypes } from "prop-types";

import CardMedia from "@mui/material/CardMedia";

import Icon from "../../elements/Icon";

export default function AddTileDesktop({ onClick }) {
  return (
    <CardMedia
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      role="button"
      onClick={onClick}
    >
      <Icon icon={"dashboard-customize"} sx={{ fontSize: "80px !important" }} />
    </CardMedia>
  );
}
AddTileDesktop.propTypes = {
  onClick: PropTypes.func,
};
