import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import CustomModal from "../../shared/modal_utils/CustomModal";
import { CustomButton } from "../../../elements/StyledElements";
import axios from "axios";
import moment from "moment";
import "moment/locale/de";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, Stack } from "@mui/material";
import DatePicker from "react-datepicker";
import { Form, FormGroup } from "reactstrap";
import { Typography } from "@material-ui/core";
import { API_URL_LEADAPPOINTMENT } from "../../../settings";
import CustomerProjectFormModal from "../CustomerProjectFormModal";
import DropDown from "../../../elements/DropDown";
import { toast } from "react-toastify";
import ErrorMessage from "../../../elements/ErrorMessage";
import SaveModalFooter from "../../shared/modal_utils/SaveModalFooter";

export default function SetAppointmentModal({
  lead,
  salesmen,
  clickedSalesman,
  leadTypes,
  onSave,
  editAppointment,
  isOpen,
  setIsOpen,
  session,
  calendarTime,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedSalesman, setSelectedSalesman] = useState(lead.salesman);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  useEffect(() => {
    if (clickedSalesman) {
      setSelectedSalesman(clickedSalesman);
    }
  }, [clickedSalesman]);

  useEffect(() => {
    if (calendarTime !== null) {
      const formattedDate = moment(calendarTime).format("YYYY-MM-DD");
      const formattedStartTime = moment(calendarTime).format("HH:mm:ss");
      const formattedEndTime = moment(calendarTime)
        .add(90, "minutes")
        .format("HH:mm:ss");

      setSelectedDate(formattedDate);
      setStartTime(formattedStartTime);
      setEndTime(formattedEndTime);
    }
  }, [calendarTime]);

  const clearData = () => {
    setSelectedDate(null);
    setStartTime(null);
    setEndTime(null);
  };

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const handleStartTimeChange = (time) => {
    const formattedStartTime = moment(time).format("HH:mm:ss");
    setStartTime(formattedStartTime);

    const endTime = moment(time).add(90, "minutes").format("HH:mm:ss");
    setEndTime(endTime);
  };

  const handleEndTimeChange = (time) => {
    const formattedEndTime = moment(time).format("HH:mm:ss");
    setEndTime(formattedEndTime);
  };

  const checkConflict = async (onNoConflict) => {
    setLoadingElements({ inProgress: true, submitError: false });
    const params = {
      salesman_id: selectedSalesman,
      date: selectedDate,
      start_time: startTime,
      end_time: endTime,
    };
    const response = await axios.get(API_URL_LEADAPPOINTMENT, { params });
    if (
      response.data.length > 1 ||
      (response.data.length === 1 &&
        response.data[0].id !== lead.appointments[0].id)
    ) {
      setLoadingElements({ inProgress: false, submitError: true });
      toast.error(
        <ErrorMessage
          message={"Zu dieser Zeit ist bereits ein Termin eingetragen"}
        />,
      );
    } else {
      setLoadingElements({ inProgress: false, submitError: false });
      toast.dismiss();
      onNoConflict();
    }
  };

  const submit = (res) => {
    const promises = editAppointment
      ? axios.put(API_URL_LEADAPPOINTMENT + lead.appointments[0].id, {
          date: selectedDate,
          start_time: startTime,
          end_time: endTime,
          new_salesman_id: selectedSalesman,
        })
      : lead.customer && lead.project
        ? axios.post(API_URL_LEADAPPOINTMENT, {
            lead: lead.id,
            date: selectedDate,
            start_time: startTime,
            end_time: endTime,
            customer: lead.customer,
            project: lead.project,
            new_salesman_id: selectedSalesman,
          })
        : axios.post(API_URL_LEADAPPOINTMENT, {
            lead: lead.id,
            date: selectedDate,
            start_time: startTime,
            end_time: endTime,
            customer: res[0].id,
            project: res[1].id,
            new_salesman_id: selectedSalesman,
          });

    promises.then(() => {
      clearData();
      onSave();
      setIsOpen(false);
    });
  };

  const getOpenButton = (toggle) => {
    if (editAppointment) return null;
    return (
      <CustomButton
        icon="add"
        style={{ width: "fit-content" }}
        onClick={toggle}
      >
        Termin hinzufügen
      </CustomButton>
    );
  };

  const getFooter = (toggle) => {
    if (editAppointment || (lead.customer && lead.project)) {
      return (
        <Button
          variant="contained"
          color={"success"}
          onClick={() => checkConflict(submit)}
        >
          Termin bestätigen
        </Button>
      );
    }
    return (
      <CustomerProjectFormModal
        lead={lead}
        salesmen={salesmen}
        leadTypes={leadTypes}
        resetParent={submit}
        session={session}
        selectedSalesman={clickedSalesman}
        getOpenButton={(toggleProjectModal) => {
          return (
            <SaveModalFooter
              submitError={loadingElements.submitError}
              inProgress={loadingElements.inProgress}
              onSave={() => checkConflict(toggleProjectModal)}
              saveBtnLabel={"Termin bestätigen"}
            />
          );
        }}
      />
    );
  };

  const endOfDay = moment().endOf("day").toDate();

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title={editAppointment ? "Termin bearbeiten" : "Termin hinzufügen"}
      getFooter={getFooter}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Fragment>
        <Form>
          <FormGroup>
            <Typography className="secondary-textcolor">Verkäufer</Typography>
            <DropDown
              id="salesman"
              search={true}
              onChange={(value) => setSelectedSalesman(value)}
              options={salesmen.map((salesman) => ({
                value: salesman.id,
                label: salesman.name,
              }))}
              value={selectedSalesman}
              listBoxStyle={{ maxHeight: "200px", overflowY: "auto" }}
            />
          </FormGroup>
          <FormGroup>
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Datum
            </Typography>
            <DatePicker
              selected={
                selectedDate
                  ? moment(selectedDate, "YYYY-MM-DD").toDate()
                  : null
              }
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              popperProps={{
                strategy: "fixed",
              }}
            />
          </FormGroup>
          <Stack direction="row" gap={4}>
            <FormGroup>
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Startzeit
              </Typography>
              <DatePicker
                selected={
                  startTime ? moment(startTime, "HH:mm:ss").toDate() : null
                }
                onChange={handleStartTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Startzeit"
                dateFormat="h:mm aa"
                popperProps={{
                  strategy: "fixed",
                }}
              />
            </FormGroup>
            <FormGroup>
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Endzeit
              </Typography>
              <DatePicker
                selected={endTime ? moment(endTime, "HH:mm:ss").toDate() : null}
                onChange={handleEndTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Endzeit"
                dateFormat="h:mm aa"
                popperProps={{
                  strategy: "fixed",
                }}
                minTime={
                  startTime
                    ? moment(startTime, "HH:mm:ss").add(90, "minutes").toDate()
                    : null
                }
                maxTime={endOfDay}
              />
            </FormGroup>
          </Stack>
        </Form>
      </Fragment>
    </CustomModal>
  );
}

SetAppointmentModal.propTypes = {
  lead: PropTypes.object,
  salesmen: PropTypes.array,
  clickedSalesman: PropTypes.number,
  leadTypes: PropTypes.array,
  editAppointment: PropTypes.bool,
  onSave: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  session: PropTypes.object,
  calendarTime: PropTypes.instanceOf(Date),
};
