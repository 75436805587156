import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import axios from "axios";

import {
  Box,
  Chip,
  IconButton,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ListRoundedIcon from "@mui/icons-material/ListRounded";

import {
  hexToRGB,
  string2FormattedString,
  useDidMountEffect,
  userWithLabel,
  getCalendarTaskColor,
} from "../../elements/utils";
import { API_URL_TASK } from "../../settings";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { CustomIconButton } from "../../elements/StyledElements";
import TaskInfo from "./TaskInfo";
import { getTaskColor, getTaskEmployeeNames, getNextDate } from "./utils";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import Collapsible from "../../elements/Collapsible";
import { TaskActionsMobile } from "./TaskActions";

export default function TaskTable({
  header,
  queryParams,
  resetParent,
  defaultIsOpen = true,
  collapsible = true,
  showIfEmpty = true,
  session,
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [tasks, setTasks] = useState(null);
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosenTask, setChosenTask] = useState(null);
  const prevQueryParams = useRef(null);

  useEffect(() => {
    // ensure that a new request is only done, if the query params change
    // the useEffect is triggered at every rerender, as a new object is created in the parent component for
    if (JSON.stringify(queryParams) === JSON.stringify(prevQueryParams.current))
      return;
    prevQueryParams.current = { ...queryParams };
    if (batch !== 0) setBatch(0);
    else reloadTasks();
  }, [queryParams]);

  useDidMountEffect(() => {
    reloadTasks();
  }, [batch]);

  const reloadTasks = () => {
    axios
      .get(API_URL_TASK, {
        params: {
          ...(queryParams || {}),
          nested: true,
          order_by: "-priority",
          batch_size: 20,
          batch,
        },
      })
      .then((res) => {
        setTasks(res.data);
        setNPages(res.headers.length);
      });
  };

  // if (!tasks || tasks.length === 0) return null
  if (!tasks) return null;
  if (!showIfEmpty && tasks.length === 0) return null;

  return (
    <Fragment>
      <Paper
        elevation={3}
        width="100%"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        <div className="text-center">
          <Typography
            sx={{ cursor: "pointer" }}
            fontSize="h4.fontsize"
            fontWeight="bold"
            onClick={() => setIsOpen(!isOpen)}
          >
            {collapsible &&
              (isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}{" "}
            {header}
          </Typography>
        </div>

        {isOpen && (
          <>
            {tasks.length === 0 && (
              <CollapsibleTable
                collapsible={false}
                columns={[{ name: "", key: "col" }]}
                rows={[
                  {
                    col: "Keine Aufgaben vorhanden",
                    style: { textAlign: "center" },
                  },
                ]}
              />
            )}
            {tasks.length > 0 && (
              <>
                <Box
                  sx={{ display: { xs: "none", md: "block" }, width: "100%" }}
                >
                  <CollapsibleTable
                    columns={[
                      { name: "", key: "status" },
                      { name: "Titel", key: "title" },
                      { name: "Kunde", key: "customer" },
                      { name: "Datum", key: "date" },
                      { name: "Mitarbeiter", key: "employees" },
                      { name: "Erstellt von", key: "registered_by" },
                      { name: "Aufgaben-Typ", key: "task_type" },
                      { name: "Labels", key: "labels" },
                    ]}
                    rows={tasks.map((task) => ({
                      key: `task-${task.id}`,
                      style: { background: getTaskColor(task) },
                      // style: task.on_hold ? { background: 'repeating-linear-gradient(-45deg, #a4a4a4, #a4a4a4 5.5px, #f8f8ff 5.5px, #f8f8ff 27.5px)' } : null,
                      status: (
                        <span
                          style={{
                            backgroundColor: getCalendarTaskColor(task),
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "inline-block",
                          }}
                        />
                      ),
                      title: task.title,
                      date:
                        task.taskdates_set && task.taskdates_set.length > 0
                          ? string2FormattedString(
                              getNextDate(task.taskdates_set),
                            )
                          : task.deadline
                            ? "Frist: " + string2FormattedString(task.deadline)
                            : "",
                      customer: task.customer_name || "",
                      employees: (
                        <Typography>{getTaskEmployeeNames(task)}</Typography>
                      ),
                      registered_by: userWithLabel(task.registered_by_labels),
                      // link: () => setChosenTask(task),
                      done: task.finished ? "\u2713" : "\u2715",
                      task_type: task.task_type_obj && (
                        <Chip
                          label={task.task_type_obj.name}
                          sx={{
                            backgroundColor: hexToRGB(
                              task.task_type_obj.color,
                              0.7,
                            ),
                          }}
                          size="small"
                        />
                      ),
                      labels: task.label_set.map((t, tIdx) => (
                        <Chip
                          key={`label-${tIdx}`}
                          label={t.name}
                          sx={{ backgroundColor: hexToRGB(t.color, 0.7) }}
                          size="small"
                        />
                      )),
                      child: (
                        <TaskInfo
                          task={task}
                          setTask={(f) =>
                            setTasks((ts) =>
                              ts.map((t) =>
                                t.id !== task.id
                                  ? t
                                  : typeof f === "function"
                                    ? f(t)
                                    : t,
                              ),
                            )
                          }
                          resetParent={resetParent}
                          session={session}
                        />
                      ),
                    }))}
                    collapsible={true}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    width: "100%",
                    paddingX: 3,
                  }}
                >
                  {tasks.map((task) => (
                    <Collapsible
                      key={`task-${task.id}`}
                      getOpenButton={(toggle) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          paddingY={1}
                          flex={1}
                          style={{ background: getTaskColor(task) }}
                        >
                          <span
                            style={{
                              backgroundColor: getCalendarTaskColor(task),
                              borderRadius: "50%",
                              width: 25,
                              height: 25,
                              display: "inline-block",
                              marginLeft: 10,
                            }}
                          />
                          <Typography onClick={toggle} flexGrow={1}>
                            {task.title}
                          </Typography>
                          <Stack direction="row">
                            <IconButton onClick={toggle} size="sm">
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setChosenTask(task.id);
                              }}
                              size="sm"
                              aria-describedby="simple-popper"
                            >
                              <ListRoundedIcon fontSize="small" />
                            </IconButton>
                            <Popover
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              id="simple-popper"
                              open={chosenTask === task.id && Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => {
                                setAnchorEl(null);
                                setChosenTask(null);
                              }}
                            >
                              <TaskActionsMobile
                                task={task}
                                setTask={(f) =>
                                  setTasks((ts) =>
                                    ts.map((t) =>
                                      t.id !== task.id
                                        ? t
                                        : typeof f === "function"
                                          ? f(t)
                                          : t,
                                    ),
                                  )
                                }
                                resetParent={() => {
                                  setAnchorEl(null);
                                  resetParent();
                                }}
                                session={session}
                              />
                            </Popover>
                          </Stack>
                        </Stack>
                      )}
                    >
                      <TaskInfo
                        task={task}
                        session={session}
                        withAction={false}
                      />
                    </Collapsible>
                  ))}
                </Box>
              </>
            )}
            {nPages && nPages > 1 ? (
              <Stack direction="row" spacing={2}>
                <CustomIconButton
                  disabled={batch === 0}
                  icon="previous"
                  onClick={() => setBatch(batch - 1)}
                />
                <Typography className="secondary-textcolor">
                  {batch + 1}
                </Typography>
                <CustomIconButton
                  disabled={batch === nPages - 1}
                  icon="next"
                  onClick={() => setBatch(batch + 1)}
                />
              </Stack>
            ) : null}
          </>
        )}
      </Paper>
    </Fragment>
  );
}

TaskTable.propTypes = {
  header: PropTypes.string,
  queryParams: PropTypes.object,
  resetParent: PropTypes.func,
  boxStyle: PropTypes.object,
  showIfEmpty: PropTypes.bool,
  collapsible: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  session: PropTypes.object,
};
