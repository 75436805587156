import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import TabView from "../elements/TabView";
import { DISTINCTCOLORS, hasPermission } from "../elements/utils";
import LoadingPage from "../elements/LoadingPage";
import LeadAssignment from "./leads/lead_map/LeadAssignment";
import {
  API_URL_LEAD_SOURCE,
  API_URL_LEAD_STEP_FILTER,
  API_URL_LEADTYPE,
  API_URL_USER,
} from "../settings";
import LeadList from "./leads/LeadList";
import LeadEvaluation from "./leads/LeadEvaluation";
import SalesmenLeadEvaluation from "./leads/SalesmenLeadEvaluation";

export default function Leads({ session }) {
  const [leadTypes, setLeadTypes] = useState(null);
  const [leadType, setLeadType] = useState(null);
  const [leadStepFilterOptions, setLeadStepFilterOptions] = useState([]);
  const [leadStepFilter, setLeadStepFilter] = useState(null);
  const [leadSources, setLeadSources] = useState([]);
  const [salesmen, setSalesmen] = useState(null);
  const [salesman, setSalesman] = useState(null);

  useEffect(() => {
    axios
      .get(API_URL_LEAD_STEP_FILTER)
      .then((res) => setLeadStepFilterOptions(res.data));
    axios.get(API_URL_LEADTYPE).then((res) => setLeadTypes(res.data));
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true },
      })
      .then((res) => {
        const salesmen = res.data
          .filter((user) =>
            ["salesmen", "sales_managers"].includes(user.group_key),
          )
          .map((s, idx) => ({
            ...s,
            area_color: DISTINCTCOLORS[idx % DISTINCTCOLORS.length],
          }));
        setSalesmen(salesmen);
      });
    refreshLeadSources().then(() => {});
  }, []);

  const refreshLeadSources = async () => {
    try {
      const res = await axios.get(API_URL_LEAD_SOURCE);
      setLeadSources(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  if (salesmen === null || leadTypes === null) return <LoadingPage />;

  return (
    <Fragment>
      <TabView
        components={[
          {
            title: "Alle",
            getChild: () => (
              <LeadList
                salesman={salesman}
                setSalesman={setSalesman}
                salesmen={salesmen}
                leadType={leadType}
                setLeadType={setLeadType}
                leadTypes={leadTypes}
                leadStepFilterOptions={leadStepFilterOptions}
                leadStepFilter={leadStepFilter}
                setLeadStepFilter={setLeadStepFilter}
                assigned={null}
                archived={null}
                realized={null}
                session={session}
              />
            ),
          },
          {
            title: "Zugeteilt",
            getChild: () => (
              <LeadList
                salesman={salesman}
                setSalesman={setSalesman}
                salesmen={salesmen}
                leadType={leadType}
                setLeadType={setLeadType}
                leadTypes={leadTypes}
                leadStepFilterOptions={leadStepFilterOptions}
                leadStepFilter={leadStepFilter}
                setLeadStepFilter={setLeadStepFilter}
                assigned={true}
                archived={false}
                realized={false}
                session={session}
              />
            ),
          },
          {
            title: "Archiviert",
            getChild: () => (
              <LeadList
                salesman={salesman}
                setSalesman={setSalesman}
                salesmen={salesmen}
                leadType={leadType}
                setLeadType={setLeadType}
                leadTypes={leadTypes}
                leadStepFilterOptions={leadStepFilterOptions}
                leadStepFilter={leadStepFilter}
                setLeadStepFilter={setLeadStepFilter}
                assigned={null}
                archived={true}
                realized={false}
                session={session}
              />
            ),
          },
          {
            title: "Nicht zugeteilt",
            getChild: () => (
              <LeadList
                salesman={salesman}
                setSalesman={setSalesman}
                salesmen={salesmen}
                leadType={leadType}
                setLeadType={setLeadType}
                leadTypes={leadTypes}
                leadStepFilterOptions={leadStepFilterOptions}
                leadStepFilter={leadStepFilter}
                setLeadStepFilter={setLeadStepFilter}
                assigned={false}
                archived={false}
                realized={false}
                session={session}
              />
            ),
          },
          {
            title: "Realisiert",
            getChild: () => (
              <LeadList
                salesman={salesman}
                setSalesman={setSalesman}
                salesmen={salesmen}
                leadType={leadType}
                setLeadType={setLeadType}
                leadTypes={leadTypes}
                leadStepFilterOptions={leadStepFilterOptions}
                leadStepFilter={leadStepFilter}
                setLeadStepFilter={setLeadStepFilter}
                assigned={null}
                archived={null}
                realized={true}
                session={session}
              />
            ),
          },
          ...(hasPermission(session.user, "assign_leads")
            ? [
                {
                  title: "Zuteilung",
                  getChild: () => (
                    <LeadAssignment
                      salesman={salesman}
                      setSalesman={setSalesman}
                      salesmen={salesmen}
                      leadType={leadType}
                      setLeadType={setLeadType}
                      leadTypes={leadTypes}
                    />
                  ),
                },
              ]
            : []),
          ...(hasPermission(session.user, "page_leads_evaluation")
            ? [
                {
                  title: "Auswertung Leads",
                  getChild: () => (
                    <LeadEvaluation
                      leadType={leadType}
                      setLeadType={setLeadType}
                      leadTypes={leadTypes}
                      leadSources={leadSources}
                      refreshLeadSources={refreshLeadSources}
                    />
                  ),
                },
                {
                  title: "Auswertung Verkäufer",
                  getChild: () => (
                    <SalesmenLeadEvaluation
                      leadType={leadType}
                      setLeadType={setLeadType}
                      leadTypes={leadTypes}
                      leadSources={leadSources}
                      refreshLeadSources={refreshLeadSources}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Fragment>
  );
}

Leads.propTypes = {
  session: PropTypes.object,
};
