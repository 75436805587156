import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";

import { Typography } from "@mui/material";

import {
  API_URL_BATTERY,
  API_URL_CIRCUITDIAGRAM,
  API_URL_CONSTRUCTION,
  API_URL_CONSTRUCTIONINVERTER,
  API_URL_CUSTOMPRODUCT,
  API_URL_INVERTER,
  API_URL_MODULE,
  API_URL_PRODUCT,
  API_URL_ROOFIMAGE,
  API_URL_ROOFIMAGEPANEL,
  API_URL_STRING,
  API_URL_EMETER,
  API_URL_BATTERY_KWH,
  API_URL_PRODUCTGROUP,
  API_URL_EMPLOYEE_LABEL,
  API_URL_USER,
  API_URL_EMPLOYEESCHEDULE,
  API_URL_OPERATORDETECTIONREQUEST,
  API_URL_ADDITIONALEXTERIORHOUSEIMAGE,
  API_URL_TASK,
} from "../../../../settings";
import { projectPropType } from "../../../../elements/PropTypes";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  arrange,
  date2String,
  getCustomerName,
  getEmptyFieldsError,
  getRandomId,
  isString,
  filterObj,
  hasPermission,
  round,
  sortByOrder,
  arraysEqual,
  getFirstDate,
  date2FormattedString,
  calculateTotalKwh,
  calculatePanelStats,
  calculateKwp,
} from "../../../../elements/utils";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import PlantConstructionForm from "./PlantConstructionForm";
import ErrorMessage from "../../../../elements/ErrorMessage";
import moment from "moment";

const emptyConstructionForm = {
  project: null,
  constructiondates_set: null,
  completion_date: null,
  commissioning_date: null,
  module: null,
  inverter: 1,
  battery: 1,
  n_vertical_panels: null,
  n_horizontal_panels: null,
  n_optimizers: null,
  n_additional_battery_modules: 0,
  n_additional_modules: 0,
  n_additional_optimizers: 0,
  kwp: null,
  battery_kwh: null,
  batterykwh_set: [{ id: -1, kwh: null }],
  n_inverters: 0,
  notes: "",
  planning: null,
  cascade: false,
  full_supply: false,
  roof_tiles: false,
  electronic_domestic_meter: false,
  e_meter: false,
  construction_manager: null,
  construction_planner: null,
  electricians: [],
  employees: [],
  circuit_diagram: null,
  operator: null,
  roofimage_set: [],
  constructioninverter_set: [],
  emeter_set: [],
  additionalexteriorhouseimage_set: [],
  resourcetype: "PlantConstruction",
  scaffolding_check: false,
  roof_tiles_task: false,
};

const emptyEMeterForm = {
  id: null,
  bidirectional: null,
  value: null,
  value_180: null,
  value_280: null,
  e_meter_id: "",
  action: null,
  project: null,
};
const fixedProductKeys = [
  "anlage",
  "pv_module",
  "anlage",
  "elektroinstallation",
  "batterie",
  "batterie_kwh",
];

export default function PlantConstructionFormModal({
  project,
  chosenDate,
  disable,
  resetParent,
  session,
  isOpen,
  setIsOpen,
  onClose,
}) {
  const [constructionForm, setConstructionForm] = useState({
    ...emptyConstructionForm,
  });

  const [customProductForms, setCustomProductForms] = useState([]);
  const [currCustomProducts, setCurrCustomProducts] = useState([]);
  const [amountCols, setAmountCols] = useState({
    plannedAmountCol: false,
    realAmountCol: false,
    offerAmountCol: false,
    invoiceAmountCol: false,
  });

  const [products, setProducts] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [modules, setModules] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [circuitDiagrams, setCircuitDiagrams] = useState([]);
  const [emeters, setEmeters] = useState([]);
  const [employeeLabels, setEmployeeLabels] = useState([]);
  const [constructionManager, setConstructionManager] = useState(null);

  const [employeeSchedule, setEmployeeSchedule] = useState(null);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [extraMaterialsPopup, setExtraMaterialsPopup] = useState({
    open: false,
    confirm: true,
    inProgress: false,
    submitError: false,
  });

  const construction = project ? project.construction_obj : null;
  const acceptedPlanning = project
    ? project.planning_set.find(
        (p) => p.id === project.accepted_offer_obj.planning,
      )
    : null;

  useEffect(() => {
    if (!construction) {
      const getOperatorDetection = async () => {
        const res = await axios.get(API_URL_OPERATORDETECTIONREQUEST, {
          params: { project: constructionForm.project },
        });

        if (
          !res.data ||
          (Array.isArray(res.data) && res.data.length === 0) ||
          !Object.hasOwn(res.data[0], "mapped_network_operator")
        )
          return;

        if (
          res.data[0].status !== "SUCCESS" ||
          !res.data[0].mapped_network_operator
        ) {
          return;
        }
        const operator = parseInt(res.data[0].mapped_network_operator);
        setConstructionForm((prev) => ({ ...prev, operator }));
      };
      getOperatorDetection();
    }
  }, [construction, constructionForm.operator]);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
    toast.dismiss();
  };

  useEffect(() => {
    let nVerticalPanels = 0;
    let nHorizontalPanels = 0;
    let nOptimizers = 0;
    constructionForm.roofimage_set.forEach((roofImage) => {
      roofImage.roofimagepanel_set.forEach((panel) => {
        if (panel.vertical) nVerticalPanels += 1;
        else {
          nHorizontalPanels += 1;
        }
        if (panel.optimizer) nOptimizers += 1;
      });
    });
    setConstructionForm((form) => ({
      ...form,
      n_vertical_panels: nVerticalPanels,
      n_horizontal_panels: nHorizontalPanels,
      n_optimizers: nOptimizers,
    }));

    const newCustomProductForms = structuredClone(customProductForms);
    const optimizerProductIdx = newCustomProductForms.findIndex(
      (cp) => cp.productObj.key === "optimierer",
    );
    let optimizerProduct =
      optimizerProductIdx !== -1
        ? newCustomProductForms[optimizerProductIdx]
        : null;
    if (nOptimizers === 0) {
      if (optimizerProduct) {
        newCustomProductForms.splice(optimizerProductIdx, 1);
      }
      setCustomProductForms(newCustomProductForms);
      return;
    }
    if (!optimizerProduct) {
      const product = products.find((p) => p.key === "optimierer");
      if (!product) return;
      optimizerProduct = {
        id: null,
        name: product.name,
        description: product.description,
        product: product.id,
        price: product.price,
        amount: nOptimizers,
        order: newCustomProductForms.length,
        priority: null,
        productObj: product,
      };
      newCustomProductForms.push(optimizerProduct);
    } else {
      optimizerProduct.amount = nOptimizers;
    }

    setCustomProductForms(newCustomProductForms);
  }, [constructionForm.roofimage_set]);

  useEffect(() => {
    setConstructionForm((c) => {
      const nHorizontalPanels = c.n_horizontal_panels
        ? c.n_horizontal_panels
        : 0;
      const nVerticalPanels = c.n_vertical_panels ? c.n_vertical_panels : 0;
      const moduleId = c.module;
      const module = modules.find((module) => module.id === moduleId);
      const moduleKwp = module ? module.kwp : 0;
      const constructionNModules = nVerticalPanels + nHorizontalPanels;
      const planningNModules = acceptedPlanning
        ? acceptedPlanning.n_horizontal_panels +
          acceptedPlanning.n_vertical_panels
        : constructionNModules;
      return {
        ...c,
        kwp: constructionNModules * moduleKwp,
        n_additional_modules: Math.max(
          0,
          constructionNModules - planningNModules,
        ),
      };
    });
    setCustomProductForms((cp) => {
      const c = constructionForm;
      const nHorizontalPanels = c.n_horizontal_panels
        ? c.n_horizontal_panels
        : 0;
      const nVerticalPanels = c.n_vertical_panels ? c.n_vertical_panels : 0;
      const moduleId = c.module;
      const module = modules.find((module) => module.id === moduleId);
      const moduleKwp = module ? module.kwp : 0;
      const constructionNModules = nVerticalPanels + nHorizontalPanels;
      const newCustomProducts = cp.map((customProduct) => {
        if (customProduct.productObj.key === "pv_module") {
          customProduct.amount = constructionNModules * moduleKwp;
        }
        return customProduct;
      });
      return newCustomProducts;
    });
  }, [
    constructionForm.n_horizontal_panels,
    constructionForm.n_vertical_panels,
    constructionForm.module,
    modules,
    acceptedPlanning,
  ]);

  useEffect(() => {
    setConstructionForm((c) => {
      const constructionNModules = c.battery_kwh
        ? round(c.battery_kwh / 2.56)
        : 0;
      const planningNModules = acceptedPlanning
        ? round(acceptedPlanning.battery_kwh / 2.56)
        : constructionNModules;
      return {
        ...c,
        n_additional_battery_modules: Math.max(
          0,
          constructionNModules - planningNModules,
        ),
      };
    });
  }, [constructionForm.battery_kwh]);

  useEffect(() => {
    setConstructionForm((c) => {
      const constructionNModules = c.n_optimizers;
      const planningNModules = acceptedPlanning
        ? acceptedPlanning.n_optimizers
        : constructionNModules;
      return {
        ...c,
        n_additional_optimizers: Math.max(
          0,
          constructionNModules - planningNModules,
        ),
      };
    });
  }, [constructionForm.n_optimizers]);

  useEffect(() => {
    const nInverters = constructionForm.n_inverters;
    let inverters = [...constructionForm.constructioninverter_set];
    const length = inverters.length;
    if (length === nInverters) return;
    if (length > nInverters) {
      inverters = inverters.slice(0, 3);
    } else {
      arrange(length, nInverters).forEach((idx) =>
        inverters.push(getNewConstructionInverter(idx)),
      );
    }
    setConstructionForm((c) => ({ ...c, constructioninverter_set: inverters }));
  }, [constructionForm.n_inverters, constructionForm.constructioninverter_set]);

  const getNewConstructionInverter = (idx) => {
    if (construction && construction.constructioninverter_set.length > idx)
      return construction.constructioninverter_set[idx];
    const defaultInverter = getDefaultInverter(idx);
    return {
      id: -getRandomId(),
      inverter: defaultInverter ? defaultInverter.id : null,
    };
  };

  useEffect(() => {
    if (!constructionForm.constructiondates_set) {
      setEmployeeSchedule(null);
    } else {
      axios
        .get(API_URL_EMPLOYEESCHEDULE, {
          params: {
            date: constructionForm.constructiondates_set,
            construction: construction?.id,
          },
        })
        .then((res) => setEmployeeSchedule(res.data));
    }
  }, [constructionForm.constructiondates_set]);

  useEffect(() => {
    if (!isOpen) return;
    if (
      customProductForms.filter(
        (cp) => cp.productObj.key === "aufruestung_zaehlerschrank_16_50",
      ).length > 0
    ) {
      const circuitDiagram16qmm = circuitDiagrams.find(
        (d) => d.key === "16qmm",
      );
      if (!circuitDiagram16qmm) return;

      setConstructionForm((prevState) => ({
        ...prevState,
        circuit_diagram: circuitDiagram16qmm.id,
      }));
    }
  }, [circuitDiagrams, customProductForms, isOpen]);

  const getDefaultInverter = (idx) => {
    const defaultInverter = inverters.find((x) => x.default_idx === idx);
    return defaultInverter || null;
  };

  const _setConstructionForm = (update) => {
    setConstructionForm((oldConstruction) => {
      const newConstruction =
        typeof update === "function"
          ? update(oldConstruction)
          : { ...oldConstruction, ...update };
      if (
        oldConstruction.cascade !== newConstruction.cascade ||
        oldConstruction.n_inverters !== newConstruction.n_inverters ||
        oldConstruction.battery_kwh !== newConstruction.battery_kwh
      ) {
        const circuitDiagramName = getCircuitDiagramName(newConstruction);
        const defaultDiagram = circuitDiagrams.find(
          (x) => x.name === circuitDiagramName,
        );
        newConstruction.circuit_diagram = defaultDiagram
          ? defaultDiagram.id
          : null;
      }
      return newConstruction;
    });
  };

  const clearData = () => {
    setConstructionForm({ ...emptyConstructionForm });
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setModules([]);
    setBatteries([]);
    setInverters([]);
    setProducts([]);
    setCustomProductForms([]);
    setCurrCustomProducts([]);
    setEmeters([]);
  };

  /* FILL ############################################################## */
  const loadData = () => {
    Promise.all([
      axios.get(API_URL_MODULE),
      axios.get(API_URL_BATTERY),
      axios.get(API_URL_INVERTER),
      axios.get(API_URL_CIRCUITDIAGRAM),
      axios.get(API_URL_EMETER),
      axios.get(API_URL_EMPLOYEE_LABEL),
      loadCustomProducts(),
    ]).then(async (res) => {
      const modules = res[0].data.filter(
        (module) =>
          module.available ||
          module.id === (acceptedPlanning ? acceptedPlanning.module : null) ||
          module.id === (construction ? construction.module : null),
      );
      setModules(modules);
      const batteries = res[1].data;
      setBatteries(batteries);
      const inverters = res[2].data;
      setInverters(inverters);
      const circuitDiagrams = res[3].data;
      setCircuitDiagrams(circuitDiagrams);
      const emeter = res[4].data.filter(
        (emeter) => emeter.project === (project ? project.id : null),
      );
      setEmeters(emeter);
      const employeeLabels = res[5].data;
      setEmployeeLabels(employeeLabels);
      // fill Construction
      let filledConstruction = {
        ...emptyConstructionForm,
        planning: acceptedPlanning.id,
        project: project.id,
        emeter_set: res[4].data.filter(
          (emeter) => emeter.project === (project ? project.id : null),
        ),
      };
      const constructionDate = chosenDate ? date2String(chosenDate) : null;
      const constructionDateObj = chosenDate
        ? [
            {
              date: date2String(chosenDate),
              construction: construction ? construction.id : null,
            },
          ]
        : null;
      if (construction) {
        setConstructionManager(construction.construction_manager_labels);
        filledConstruction = {
          ...filledConstruction,
          ...construction,
        };
        if (chosenDate) {
          filledConstruction = {
            ...filledConstruction,
            constructiondates_set: constructionDateObj,
            completion_date: constructionDate,
            ...(construction.e_meter
              ? null
              : { commissioning_date: constructionDate }),
          };
        }
      } else {
        const newBatteryKwhSet = acceptedPlanning.batterykwh_set.map(
          (battery) => ({ ...battery, id: -getRandomId() }),
        );
        filledConstruction = {
          ...filledConstruction,
          module: acceptedPlanning.module,
          n_vertical_panels: acceptedPlanning.n_vertical_panels,
          n_horizontal_panels: acceptedPlanning.n_horizontal_panels,
          n_optimizers: acceptedPlanning.n_optimizers,
          kwp: acceptedPlanning.kwp,
          battery_kwh: acceptedPlanning.battery_kwh,
          batterykwh_set: newBatteryKwhSet,
          n_inverters: acceptedPlanning.n_inverters,
          e_meter: acceptedPlanning.e_meter,
          constructiondates_set: constructionDateObj,
          completion_date: constructionDate,
          commissioning_date: acceptedPlanning.e_meter
            ? null
            : constructionDate,
        };
        const circuitDiagramName = getCircuitDiagramName(
          filledConstruction,
          res[6],
        );
        const circuitDiagram = circuitDiagrams.find(
          (x) => x.name === circuitDiagramName,
        );
        filledConstruction = {
          ...filledConstruction,
          circuit_diagram: circuitDiagram ? circuitDiagram.id : null,
        };
        filledConstruction.roofimage_set = acceptedPlanning.roofimage_set.map(
          (roofImage) => ({
            ...roofImage,
            id: -getRandomId(),
            string_set: roofImage.string_set.map((string) => ({
              ...string,
              id: -getRandomId(),
            })),
            roofimagepanel_set: roofImage.roofimagepanel_set.map((panel) => ({
              ...panel,
              id: -getRandomId(),
            })),
          }),
        );
      }
      setConstructionForm(filledConstruction);
    });
  };

  const resetProducts = async () => {
    const requestOfferId = project.accepted_offer_obj.id;
    const requestPlanningId = project.accepted_offer_obj.planning_obj.id;
    return axios
      .get(API_URL_PRODUCT, {
        params: {
          project: project.id,
          offer: requestOfferId,
          planning: requestPlanningId,
        },
      })
      .then((res) => {
        const products = res.data;
        setProducts(products);
        const amountCols = {
          plannedAmountCol: res.headers.planned_amounts === "True",
          realAmountCol: res.headers.real_amounts === "True",
          offerAmountCol: res.headers.offer_amounts === "True",
          invoiceAmountCol: res.headers.invoice_amounts === "True",
        };
        setAmountCols(amountCols);
        return axios.get(API_URL_PRODUCTGROUP).then((res) => {
          const productGroups = res.data;
          setProductGroups(productGroups);
          return [products, amountCols, productGroups];
        });
      });
  };

  const loadCustomProducts = async () => {
    if (construction) {
      return resetProducts().then(([products, amountCols]) => {
        return axios
          .get(API_URL_CUSTOMPRODUCT, {
            params: { construction: project.construction_obj.id },
          })
          .then((res) => {
            const customProducts = res.data.map((customProduct, _idx) => {
              const product = products.find(
                (p) => p.id === customProduct.product,
              );
              return {
                id: customProduct.id,
                name: customProduct.name,
                description: customProduct.description,
                product: customProduct.product,
                price: customProduct.price,
                vat: customProduct ? customProduct.vat : product.vat,
                amount: customProduct.amount,
                order: customProduct.order ? customProduct.order : _idx + 1,
                priority: customProduct.priority,
                productObj: product,
              };
            });
            const customProductsCopy = customProducts.map((product) => {
              return JSON.parse(JSON.stringify(product));
            });
            setCustomProductForms([...customProductForms, ...customProducts]);
            setCurrCustomProducts([
              ...currCustomProducts,
              ...customProductsCopy,
            ]);
            return products;
          });
      });
    } else {
      return resetProducts().then(([products, amountCols]) => {
        return axios
          .get(API_URL_CUSTOMPRODUCT, {
            params: { offer: project.accepted_offer },
          })
          .then((res) => {
            const customProducts = res.data;
            setCustomProductForms(
              products
                .filter(
                  (p) =>
                    showProduct(p, amountCols) &&
                    !isOptional(p, customProducts),
                )
                .map((product, _idx) => {
                  const customProduct = customProducts.find(
                    (cp) => cp.product === product.id,
                  );
                  return {
                    id: null,
                    name: customProduct ? customProduct.name : product.name,
                    description: customProduct
                      ? customProduct.description
                      : product.description,
                    product: product.id,
                    price: product.price,
                    vat: customProduct ? customProduct.vat : product.vat,
                    amount: getAmount(product, amountCols),
                    order:
                      customProduct && customProduct.order
                        ? customProduct.order
                        : _idx + 1,
                    priority: customProduct ? customProduct.priority : null,
                    productObj: product,
                  };
                }),
            );
            return products;
          });
      });
    }
  };

  const getAmount = (product, amountCols) => {
    const amount = amountCols.offerAmountCol ? product.amount_offer : 0;
    return Math.max(amount, 0);
  };

  const showProduct = (product, amountCols) =>
    (amountCols.plannedAmountCol && product.amount_planned !== 0) ||
    (amountCols.invoiceAmountCol && product.amount_invoices !== 0) ||
    (amountCols.realAmountCol && product.amount_real !== 0) ||
    (amountCols.offerAmountCol && product.amount_offer !== 0);

  const isOptional = (product, customProducts) => {
    const foundProduct = customProducts.find((cp) => cp.product === product.id);
    if (foundProduct != null) {
      return foundProduct.optional;
    }
    return true;
  };

  const getCircuitDiagramName = (construction, _products) => {
    if (acceptedPlanning.tenant_system) {
      return "10qmm_mieterstrom";
    }

    const currentProducts = _products || products;
    const circuitDiagramParts = [];
    if (
      currentProducts.find((x) => x.key === "aufruestung_e_auto")
        .amount_offer !== 0 ||
      ((acceptedPlanning.wallbox || acceptedPlanning.wallbox_required) &&
        acceptedPlanning.heatpump)
    ) {
      circuitDiagramParts.push("16qmm");
    } else {
      circuitDiagramParts.push("10qmm");
    }
    if (construction.n_inverters > 1) circuitDiagramParts.push("zwei-WR");
    if (construction.cascade) circuitDiagramParts.push("Kaskade");
    if (!construction.battery_kwh) circuitDiagramParts.push("ohne-Speicher");
    return circuitDiagramParts.join("_");
  };

  /* MODAL ############################################################## */

  const getOpenButton = (toggle) => {
    if (isOpen === null || isOpen === undefined) {
      return (
        <CustomButton disabled={disable} onClick={toggle} icon="construction">
          Baustelle
        </CustomButton>
      );
    }
  };

  const getConstructionDateDeadline = (construction) => {
    let constructionDate = construction.constructiondates_set.sort(
      (cd1, cd2) => cd1.date - cd2.date,
    )[0].date;
    constructionDate = new Date(constructionDate);
    constructionDate.setDate(constructionDate.getDate() - 2);

    return constructionDate;
  };

  const deletePreviousExtraMaterialsTask = async () => {
    if (!construction || construction.constructiondates_set.length === 0)
      return;

    const prevDeadline = getConstructionDateDeadline(construction);

    const res = await axios.get(API_URL_TASK, {
      params: {
        project: construction.project,
        title: `Sondermaterial klären bis zum ${date2FormattedString(prevDeadline)}`,
      },
    });

    if (!(Array.isArray(res.data) && res.data.length > 0)) return;

    const prevTaskId = res.data[0].id;

    await axios.delete(API_URL_TASK + `${prevTaskId}`);
  };

  const createExtraMaterialsTask = async () => {
    if (
      !(
        constructionForm.constructiondates_set &&
        constructionForm.constructiondates_set.length > 0
      )
    )
      return;

    const deadline = getConstructionDateDeadline(constructionForm);
    const daysDiff = moment(deadline)
      .startOf("day")
      .diff(moment(Date.now()).startOf("day"), "days");
    const taskForm = {
      title: `Sondermaterial klären bis zum ${date2FormattedString(deadline)}`,
      responsible: session.user.id,
      deadline: date2String(deadline),
      project: constructionForm.project,
      priority: daysDiff <= 1 ? 3 : 2,
    };

    await axios.post(API_URL_TASK, taskForm);
  };

  const onNotConfirmExtraMaterials = async (toggle, toggleConfirmModal) => {
    setExtraMaterialsPopup({
      ...extraMaterialsPopup,
      confirm: false,
      inProgress: true,
      submitError: false,
    });
    try {
      await deletePreviousExtraMaterialsTask();
      await createExtraMaterialsTask();
      toggleConfirmModal();
      onSubmit(toggle, true);
    } catch (e) {
      setExtraMaterialsPopup({ ...extraMaterialsPopup, submitError: true });
      console.error(e);
    }
  };

  const getFooter = (toggle) => {
    return (
      <>
        <CustomModal
          getFooter={(_toggle) => (
            <SaveModalFooter
              onSave={() => {
                _toggle();
                setExtraMaterialsPopup({ ...extraMaterialsPopup, open: true });
              }}
              saveBtnLabel={"Bestätigen"}
            />
          )}
          size="lg"
          title={"Bestätigung der Eckdaten"}
          isOpen={confirmationModalOpen}
          setIsOpen={setConfirmationModalOpen}
        >
          <Typography variant="h6">
            Hiermit bestätige ich,{" "}
            {`${session.user.first_name} ${session.user.last_name}`}, dass diese
            Anlage gebaut werden kann. Ich habe die Gerüstaufstellung geprüft,
            das Material wird vorrätig sein. Ich habe alle Extrapositionen und
            das Notizfeld gelesen und gebe das OK, dass wir dies an diesem Tag
            bauen können.
          </Typography>
        </CustomModal>
        <CustomModal
          getFooter={(_toggle) => (
            <SaveModalFooter
              onComplete={async () => {
                await deletePreviousExtraMaterialsTask();
                setExtraMaterialsPopup({
                  ...extraMaterialsPopup,
                  confirm: true,
                  inProgress: true,
                  submitError: false,
                });
                _toggle();
                onSubmit(toggle, true);
              }}
              onCancel={() => {
                onNotConfirmExtraMaterials(toggle, _toggle);
              }}
              completeBtnLabel={"Ja"}
              cancelBtnLabel={"Nein"}
              inProgress={extraMaterialsPopup.inProgress}
              submitError={extraMaterialsPopup.submitError}
            />
          )}
          size="lg"
          title={"Bestätigung der Eckdaten"}
          isOpen={extraMaterialsPopup.open}
          setIsOpen={(isOpen) =>
            setExtraMaterialsPopup({ ...extraMaterialsPopup, open: isOpen })
          }
        >
          <Typography variant="h6">
            {extraMaterialsPopup.confirm
              ? `Ich ${session.user.first_name} ${session.user.last_name} bestätige, dass alles Sondermaterial für diese Baustelle vorhanden ist.`
              : "Du hast das Sondermaterial für diese Baustelle nicht geklärt. Es wird dir eine Aufgabe erzeugt."}
          </Typography>
        </CustomModal>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => {
            onSubmit(toggle, false);
          }}
          saveDisabled={
            !(
              hasPermission(session.user, "customer_handling") ||
              hasPermission(session.user, "page_constructions")
            ) ||
            (construction &&
              construction.documentation_completed &&
              !hasPermission(session.user, "finish_construction_documentation"))
          }
        />
      </>
    );
  };

  /* UPLOADING ############################################################## */

  const getBatteryKwhPromises = async (
    constructionId,
    batteryKwh,
    url,
    getPromise,
    construction,
  ) => {
    batteryKwh = batteryKwh.filter((b) => b.kwh !== null);
    const deletePromise = construction
      ? axios.delete(url, {
          data: {
            construction: constructionId,
            except_ids: batteryKwh.filter((b) => !isNewObj(b)).map((b) => b.id),
          },
        })
      : Promise.resolve();
    return deletePromise.then(() =>
      Promise.all(
        batteryKwh.map((battery) => getPromise(constructionId, battery, url)),
      ),
    );
  };

  const getBatteryKwhPromise = async (constructionId, batteryKwh, url) => {
    const formData = new FormData();
    formData.append("construction", constructionId);
    formData.append("kwh", batteryKwh.kwh);
    formData.append("order", batteryKwh.order);
    if (batteryKwh.id < 0) return axios.post(url, formData);
    formData.append("id", batteryKwh.id);
    return axios.put(url + batteryKwh.id, formData);
  };

  const getImagePromises = async (constructionId, images, url, getPromise) => {
    const deletePromise = construction
      ? axios.delete(url, {
          data: {
            construction: constructionId,
            except_ids: images.filter((i) => !isNewObj(i)).map((i) => i.id),
          },
        })
      : Promise.resolve();
    return deletePromise.then(() =>
      Promise.all(
        images.map((image) => getPromise(constructionId, image, url)),
      ),
    );
  };

  const isNewObj = (obj) => obj.id <= 0;

  const getRoofImagePromise = async (
    constructionId,
    image,
    url,
    constructionInverterIdTranslation,
  ) => {
    const roofImage = image;
    const formData = new FormData();
    if (!construction) {
      if (!isString(roofImage.image))
        formData.append("image", roofImage.image, roofImage.image.name);
      else formData.append("image", roofImage.image);
      if (!isString(roofImage.house_image))
        formData.append(
          "house_image",
          roofImage.house_image,
          roofImage.house_image.name,
        );
      else formData.append("house_image", roofImage.house_image);
    } else {
      if (!isString(roofImage.image))
        formData.append("image", roofImage.image, roofImage.image.name);
      if (!isString(roofImage.house_image))
        formData.append(
          "house_image",
          roofImage.house_image,
          roofImage.house_image.name,
        );
    }
    formData.append("construction", constructionId);
    formData.append("direction", roofImage.direction);
    formData.append("flat_roof", roofImage.flat_roof);
    formData.append("image_width_m", roofImage.image_width_m);
    formData.append("angle", roofImage.angle);

    const roofImagePromise =
      roofImage.id > 0
        ? axios.put(url + roofImage.id, formData)
        : axios.post(url, formData);

    return roofImagePromise.then(async (res) => {
      const roofImageId = res.data.id;
      return getStringPromise(
        roofImage,
        roofImageId,
        constructionInverterIdTranslation,
      )
        .then((stringIdTranslation) =>
          getRoofImagePanelPromise(roofImage, roofImageId, stringIdTranslation),
        )
        .then(async () => {
          await axios.delete(API_URL_ADDITIONALEXTERIORHOUSEIMAGE, {
            data: {
              roof_image: roofImageId,
              except_ids: constructionForm.additionalexteriorhouseimage_set
                .filter((s) => !isNewObj(s))
                .map((s) => s.id),
            },
          });
          const imgAddPromises = [];
          for (const img of constructionForm.additionalexteriorhouseimage_set) {
            if (
              img.id >= 0 ||
              (img.roof_image >= 0 && img.roof_image !== roofImageId) ||
              (img.roof_image < 0 && img.roof_image !== roofImage.id)
            )
              continue;
            const formData = new FormData();
            if (img.roof_image === roofImage.id) {
              formData.append("roof_image", roofImageId);
            }
            if (!formData.has("roof_image"))
              formData.append("roof_image", roofImageId);
            formData.append("construction", constructionId);
            formData.append("image", img.image);

            imgAddPromises.push(
              axios.post(API_URL_ADDITIONALEXTERIORHOUSEIMAGE, formData),
            );
          }
          await Promise.all(imgAddPromises);
        });
    });
  };

  const translate = (id, translation) => {
    if (!id) return null;
    const foundTranslation = translation.find(([oldId, newId]) => oldId === id);
    return foundTranslation ? foundTranslation[1] : null;
  };

  const getConstructionInverterPromise = async (constructionId) => {
    const constructionInverters =
      constructionForm.constructioninverter_set.filter((i) => i.inverter);
    const promise = construction
      ? axios.delete(API_URL_CONSTRUCTIONINVERTER, {
          data: {
            construction: constructionId,
            except_ids: constructionInverters
              .filter((i) => !isNewObj(i))
              .map((i) => i.id),
          },
        })
      : Promise.resolve();
    return promise.then(() =>
      Promise.all(
        constructionInverters.map(async (constructionInverter) => {
          const constructionInverterPromise = isNewObj(constructionInverter)
            ? axios.post(API_URL_CONSTRUCTIONINVERTER, {
                ...constructionInverter,
                construction: constructionId,
                id: null,
              })
            : axios.put(
                API_URL_CONSTRUCTIONINVERTER + constructionInverter.id,
                { ...constructionInverter, construction: constructionId },
              );
          return constructionInverterPromise.then((res) => [
            constructionInverter.id,
            res.data.id,
          ]);
        }),
      ),
    );
  };

  const getStringPromise = async (
    roofImage,
    roofImageId,
    constructionInverterIdTranslation,
  ) => {
    const promise =
      roofImage.id > 0
        ? axios.delete(API_URL_STRING, {
            data: {
              roof_image: roofImageId,
              except_ids: roofImage.string_set
                .filter((s) => !isNewObj(s))
                .map((s) => s.id),
            },
          })
        : Promise.resolve();
    return promise.then(() =>
      Promise.all(
        roofImage.string_set.map(async (string) => {
          const newConstructionInverterId = translate(
            string.construction_inverter,
            constructionInverterIdTranslation,
          );
          const stringPromise = isNewObj(string)
            ? axios.post(API_URL_STRING, {
                ...string,
                roof_image: roofImageId,
                id: null,
                construction_inverter: newConstructionInverterId,
              })
            : axios.put(API_URL_STRING + string.id, {
                ...string,
                roof_image: roofImageId,
                construction_inverter: newConstructionInverterId,
              });
          return stringPromise.then((res) => [string.id, res.data.id]);
        }),
      ),
    );
  };

  const getRoofImagePanelPromise = async (
    roofImage,
    roofImageId,
    stringIdTranslation,
  ) => {
    const promise =
      roofImage.id > 0
        ? axios.delete(API_URL_ROOFIMAGEPANEL, {
            data: {
              roof_image: roofImageId,
              except_ids: roofImage.roofimagepanel_set
                .filter((p) => !isNewObj(p))
                .map((p) => p.id),
            },
          })
        : Promise.resolve();
    return promise.then(() =>
      Promise.all(
        roofImage.roofimagepanel_set.map((panel) => {
          const newStringId = translate(panel.string, stringIdTranslation);
          return isNewObj(panel)
            ? axios.post(API_URL_ROOFIMAGEPANEL, {
                ...panel,
                roof_image: roofImageId,
                id: null,
                string: newStringId,
              })
            : axios.put(API_URL_ROOFIMAGEPANEL + panel.id, {
                ...panel,
                roof_image: roofImageId,
                string: newStringId,
              });
        }),
      ),
    );
  };

  const getConstructionManagerLabelUpdatePromise = async (
    constructionManager,
    employeeLabels,
  ) => {
    const userForm = {
      employee_label: [],
    };
    const constructionManagerLabel = constructionManager.employee_label.find(
      (label) =>
        label.label.key === "construction_managers" ||
        label.label.name === "Bauleiter",
    );
    if (constructionManagerLabel) return Promise.resolve();
    const bauleiterLabel = employeeLabels.find(
      (label) =>
        label.key === "construction_managers" || label.name === "Bauleiter",
    ).id;
    const labels = constructionManager.employee_label.map((item) => ({
      label: item.label.id,
      order: item.order,
    }));
    labels.push({ label: bauleiterLabel, order: labels.length + 1 });
    userForm.employee_label = labels;
    return axios.put(API_URL_USER + constructionManager.id, userForm);
  };

  const submit = async (form, confirmed = false) => {
    form.created_by = session.user.id;
    const checkedForm = { ...form };

    const calculatedKwh = calculateTotalKwh(checkedForm.batterykwh_set);
    if (calculatedKwh !== checkedForm.battery_kwh)
      checkedForm.battery_kwh = calculatedKwh;

    const { nOptimizers, nHorizontalPanels, nVerticalPanels } =
      calculatePanelStats(checkedForm.roofimage_set);

    const calculatedKwp = calculateKwp(
      nHorizontalPanels,
      nVerticalPanels,
      modules,
      checkedForm.module,
    );

    if (calculatedKwp !== checkedForm.kwp) checkedForm.kwp = calculatedKwp;
    if (nHorizontalPanels !== checkedForm.n_horizontal_panels)
      checkedForm.n_horizontal_panels = nHorizontalPanels;
    if (nVerticalPanels !== checkedForm.n_vertical_panels)
      checkedForm.n_vertical_panels = nVerticalPanels;
    if (nOptimizers !== checkedForm.n_optimizers)
      checkedForm.n_optimizers = nOptimizers;

    try {
      const constructionResponse = construction
        ? await axios.put(API_URL_CONSTRUCTION + construction.id, checkedForm, {
            params: { confirmed },
          })
        : await axios.post(API_URL_CONSTRUCTION, checkedForm, {
            params: { confirmed },
          });

      const constructionId = constructionResponse.data.id;

      const promises = [];

      promises.push(
        getBatteryKwhPromises(
          constructionId,
          constructionForm.batterykwh_set,
          API_URL_BATTERY_KWH,
          getBatteryKwhPromise,
          construction,
        ),
      );

      try {
        const constructionInverterIdTranslation =
          await getConstructionInverterPromise(constructionId);

        try {
          promises.push(
            getImagePromises(
              constructionId,
              constructionForm.roofimage_set,
              API_URL_ROOFIMAGE,
              (constructionId, image, url) =>
                getRoofImagePromise(
                  constructionId,
                  image,
                  url,
                  constructionInverterIdTranslation,
                ),
            ),
          );
        } catch (error) {
          console.error(
            "Error in ConstructionFormModal: Failed to save roof images:",
            error,
          );
        }
      } catch (error) {
        console.error(
          "Error in ConstructionFormModal: Failed to save inverter",
          error,
        );
      }

      promises.push(getProductPromise(constructionId));

      if (constructionResponse.data.construction_manager) {
        promises.push(
          getConstructionManagerLabelUpdatePromise(
            constructionManager,
            employeeLabels,
          ),
        );
      }

      try {
        await axios.delete(API_URL_EMETER, {
          data: {
            except_ids: checkedForm.emeter_set
              .filter((x) => x.id > 0)
              .map((x) => x.id),
            project: checkedForm.project,
          },
        });

        await Promise.allSettled(
          checkedForm.emeter_set.map((eMeterForm) => {
            const eMeterData = filterObj(
              eMeterForm,
              Object.keys(emptyEMeterForm),
            );
            return eMeterForm.id >= 0
              ? axios.put(API_URL_EMETER + eMeterForm.id, eMeterData)
              : axios.post(API_URL_EMETER, { ...eMeterData, id: null });
          }),
        );
      } catch (error) {
        console.error(
          "Error in ConstructionFormModal: Failed to update e-meter:",
          error,
        );
      }

      if (checkedForm.roof_tiles === false && !checkedForm.roof_tiles_task) {
        const firstDate = getFirstDate(checkedForm.constructiondates_set);
        const dateBefore = new Date(firstDate);
        dateBefore.setDate(dateBefore.getDate() - 7);

        try {
          promises.push(
            axios.post(API_URL_TASK, {
              title: `Pfannen besorgen ${getCustomerName(project.customer_obj) + " " + project.name}`,
              project: checkedForm.project,
              employees: [...checkedForm.employees, session.user.id],
              registered_by_id: session.user.id,
              responsible: session.user.id,
              deadline: date2String(dateBefore),
              priority: 3,
            }),
          );
          promises.push(
            axios.put(API_URL_CONSTRUCTION + constructionId, {
              roof_tiles_task: true,
            }),
          );
        } catch (error) {
          console.error(
            "Error in ConstructionFormModal: Failed to create roof tiles task:",
            error,
          );
        }
      }

      if (
        checkedForm.roof_tiles === true &&
        checkedForm.roof_tiles_task === true
      ) {
        try {
          const task = await axios.get(API_URL_TASK, {
            params: {
              project: checkedForm.project,
              title: `Pfannen besorgen ${getCustomerName(project.customer_obj) + " " + project.name}`,
            },
          });
          if (task.data.length > 0) {
            await axios.delete(API_URL_TASK + task.data[0].id);
          }
          promises.push(
            axios.put(API_URL_CONSTRUCTION + constructionId, {
              roof_tiles_task: false,
            }),
          );
        } catch (error) {
          console.error(
            "Error in ConstructionFormModal: Failed to delete roof tiles task:",
            error,
          );
        }
      }

      await Promise.allSettled(promises);

      const finalConstructionData = await axios.get(
        API_URL_CONSTRUCTION + constructionId,
      );
      return finalConstructionData;
    } catch (error) {
      console.error(
        "Error in ConstructionFormModal: Error during construction saving process:",
        error,
      );
      throw error;
    }
  };

  const getProductPromise = async (constructionId) => {
    const customProductPromise = construction
      ? axios.delete(API_URL_CUSTOMPRODUCT, {
          data: {
            construction: constructionId,
            except_ids: customProductForms.filter((x) => x.id).map((x) => x.id),
          },
        })
      : Promise.resolve();
    return customProductPromise.then(async () => {
      if (project.baseinvoice_set.length > 0) {
        const promises = customProductForms.map((customProduct) => {
          customProduct.construction = constructionId;
          customProduct.invoice = project.baseinvoice_set[0].id;

          if (customProduct.id) {
            return customProduct.amount
              ? axios.put(
                  API_URL_CUSTOMPRODUCT + customProduct.id,
                  customProduct,
                )
              : axios.delete(API_URL_CUSTOMPRODUCT + customProduct.id);
          }

          return customProduct.amount
            ? axios.post(API_URL_CUSTOMPRODUCT, customProduct)
            : Promise.resolve();
        });

        return Promise.all(promises);
      } else {
        const promises = customProductForms.map((customProduct) => {
          customProduct.construction = constructionId;

          if (customProduct.id) {
            return customProduct.amount
              ? axios.put(
                  API_URL_CUSTOMPRODUCT + customProduct.id,
                  customProduct,
                )
              : axios.delete(API_URL_CUSTOMPRODUCT + customProduct.id);
          }

          return customProduct.amount
            ? axios.post(API_URL_CUSTOMPRODUCT, customProduct)
            : Promise.resolve();
        });

        return Promise.all(promises);
      }
    });
  };

  const onSubmit = async (onSuccess, confirmed = false) => {
    const additionalOptionalKeys = [
      "notes",
      "commissioning_date",
      "construction_manager",
      "employees",
      "electricians",
      "operator",
      "completion_date",
      "emeter_set",
      "batterykwh_set",
      "additionalexteriorhouseimage_set",
      "constructiondates_set",
      "construction_planner",
    ];
    if (construction) {
      if (additionalOptionalKeys.constructiondates_set == null)
        additionalOptionalKeys.push("constructiondates_set");
      if (additionalOptionalKeys.completion_date == null)
        additionalOptionalKeys.push("completion_date");
    }
    const emptyFieldsError = getEmptyFieldsError(
      constructionForm,
      emptyConstructionForm,
      additionalOptionalKeys,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      toast.error(<ErrorMessage message={"Bitte alle Felder ausfüllen!"} />);
      return;
    }

    if (!constructionForm.scaffolding_check) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Antwort zur Gerüst Frage vergessen",
        inProgress: false,
      });
      console.error("Please check the scaffolding question");
      toast.error(
        <ErrorMessage message={"Antwort zur Gerüst Frage vergessen"} />,
      );
      return;
    }

    // open confirmation modal if date / electricians / construction manager changed
    if (!confirmed) {
      if (
        (constructionForm.constructiondates_set &&
          constructionForm.constructiondates_set.length > 0 &&
          (!construction ||
            !arraysEqual(
              construction.constructiondates_set,
              constructionForm.constructiondates_set,
            ))) ||
        (constructionForm.construction_manager &&
          (!construction ||
            construction.construction_manager !==
              constructionForm.construction_manager)) ||
        (constructionForm.electricians &&
          constructionForm.electricians.length &&
          (!construction ||
            constructionForm.electricians.some(
              (e) => !construction.electricians.includes(e),
            )))
      ) {
        setConfirmationModalOpen(true);
        return;
      }
    }

    // submit
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const constructionFormKeys = Object.keys(emptyConstructionForm);

    return submit(filterObj(constructionForm, constructionFormKeys), confirmed)
      .then((res) => {
        if (resetParent) resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          "Error in ConstructionFormModal:onSubmit",
          error,
          error.stack,
        );
        toast.error(
          <ErrorMessage
            message={"Anfrage konnte nicht an Server übermittelt werden!"}
          />,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const title = project
    ? "Bauplanung von " + getCustomerName(project.customer_obj)
    : "Bauplanung";
  return (
    <CustomModal
      size="fullscreen"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      title={title}
      getFooter={getFooter}
      onToggle={onToggle}
      onClose={onClose}
    >
      {modules === null || constructionForm === null ? null : (
        <Fragment>
          <PlantConstructionForm
            project={project}
            construction={{
              ...constructionForm,
              batterykwh_set: sortByOrder(constructionForm.batterykwh_set),
            }}
            setConstruction={_setConstructionForm}
            products={products}
            productGroups={productGroups}
            resetProducts={resetProducts}
            customProducts={customProductForms}
            setCustomProducts={setCustomProductForms}
            currCustomProducts={currCustomProducts}
            modules={modules}
            batteries={batteries}
            inverters={inverters}
            circuitDiagrams={circuitDiagrams}
            showMissingFields={loadingElements.showMissingFields}
            amountCols={amountCols}
            fixedProductKeys={fixedProductKeys}
            session={session}
            emeters={emeters}
            setEmeters={setEmeters}
            employeeSchedule={employeeSchedule}
            setConstructionManager={setConstructionManager}
          />
        </Fragment>
      )}
    </CustomModal>
  );
}

PlantConstructionFormModal.propTypes = {
  project: projectPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
};
