import React, { Fragment, useState } from "react";

import { PropTypes } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { HashLink as Link } from "react-router-hash-link";
import { Container, Row } from "reactstrap";

import { IconButton, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";

import Icon from "../elements/Icon";
import TaskWidget from "../components/tasks/TaskWidget";

export default function Home({ session }) {
  const iconfontsize = isMobileOnly ? 30 : 40;

  const [openCollapse, setOpenCollapse] = useState(null);

  // const splitNavigationIntoBatches = (nItems, buffer) => {
  //     const batches = []
  //     let batch = []

  //     const getItemLength = (item) => {
  //         let l = 1
  //         if (item.children) item.children.forEach(c => l += getItemLength(c))
  //         return l
  //     }

  //     navigation.forEach(item => {
  //         let length = getItemLength(item)
  //         let currentBatchLength = arrSum(batch.map(i => i.length))
  //         if (currentBatchLength == 0 || (currentBatchLength < nItems && currentBatchLength + length < nItems + buffer)) {
  //             batch.push({ item: item, length: length })
  //         } else {
  //             batches.push(batch)
  //             batch = [{ item: item, length: length }]
  //         }
  //     })
  //     batches.push(batch)
  //     return batches.map(batch => batch.map(i => i.item))
  // }

  const getParentItem = (item, level) => {
    return (
      <Row>
        <span
          onClick={() => setOpenCollapse(item.key)}
          onMouseOver={() => setOpenCollapse(item.key)}
          onMouseLeave={() => setOpenCollapse(null)}
        >
          <div style={{ marginLeft: 40 * level }}>
            <IconButton
              disabled
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              <Icon
                icon={item.icon}
                className="secondary-textcolor"
                style={{ fontSize: iconfontsize }}
                fontSize="large"
              />
              &nbsp;
              <Typography
                className="secondary-textcolor"
                fontSize="h6.fontSize"
                sx={{ fontWeight: "bold" }}
              >
                {item.name}
              </Typography>
            </IconButton>
          </div>
          {item.children ? (
            <Collapse
              in={openCollapse === item.key}
              timeout="auto"
              unmountOnExit
            >
              {item.children.map((c) => getListItem(c, level + 1))}
            </Collapse>
          ) : null}
        </span>
      </Row>
    );
  };

  const getChildItem = (item, level) => {
    return (
      <div style={{ marginLeft: 40 * level }}>
        <Row>
          <Link to={`/${item.url}/`} style={{ textDecoration: "none" }}>
            <IconButton
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
            >
              <Icon
                icon={item.icon}
                className="secondary-textcolor"
                style={{ fontSize: iconfontsize }}
                fontSize="large"
              />
              &nbsp;
              <Typography
                className="secondary-textcolor"
                fontSize="h6.fontSize"
              >
                {item.name}
              </Typography>
            </IconButton>
          </Link>
        </Row>
      </div>
    );
  };

  const getListItem = (item, level) => {
    return item.url ? getChildItem(item, level) : getParentItem(item, level);
  };

  return (
    <Fragment>
      {/* {!isMobileOnly && <Container>
          {session.user ? <Typography paddingTop={'10px'} className={'secondary-textcolor text-end'}>Angemeldet: {session.user.first_name} {session.user.last_name} </Typography> : null}
          <div className="text-center">
            <Typography fontSize='h4.fontSize' className='secondary-textcolor'>
              Meine Aufgaben
            </Typography>
          </div>
          <br />
        </Container>
      }
       */}
      <Container>
        {session.user ? (
          <Typography
            paddingTop={"10px"}
            className={"secondary-textcolor text-end"}
          >
            Angemeldet: {session.user.first_name} {session.user.last_name}{" "}
          </Typography>
        ) : null}
        <br />
      </Container>
      <TaskWidget session={session} />
    </Fragment>
  );
}

Home.propTypes = {
  session: PropTypes.object,
};
