import React from "react";
import { PropTypes } from "prop-types";
import { Typography } from "@mui/material";

export default function CommentCard({ comment, idx }) {
  const author = comment.author_name || "Unbekannt";
  const text = comment.text;
  const isDateExist = comment.created_date != null;
  const isTimeExist = comment.created_time != null;

  return (
    <div key={`task-comment-${idx}`} style={{ margin: "10px 0 10px 0" }}>
      <Typography key={`task-comment-text-${idx}`}>
        <i>
          <b>{author}:</b>
        </i>{" "}
        {text}
      </Typography>
      {isDateExist && isTimeExist ? (
        <Typography
          key={`task-comment-createdtime-${idx}`}
          align="right"
          style={{ color: "#838383", padding: "5px 0 5px 0" }}
        >
          {comment.created_date.split("-").reverse().join(".")} &nbsp; - &nbsp;{" "}
          {comment.created_time
            .split(":")
            .slice(0, 2)
            .join(":")
            .replace(/:/g, ".")}
        </Typography>
      ) : (
        <Typography
          key={`task-comment-createdtime-${idx}`}
          align="right"
          style={{ color: "#838383", padding: "5px 0 5px 0" }}
        >
          Kein Datum oder Uhrzeit aufgezeichnet
        </Typography>
      )}
      <hr style={{ margin: 0 }} />
    </div>
  );
}

CommentCard.propTypes = {
  comment: PropTypes.object,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
