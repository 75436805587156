import React from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { CustomToggle } from "../../elements/StyledElements";
import { defaultIfEmpty, defaultIfEmptyForPrice } from "../../elements/utils";
import { customerPropType, productPropType } from "../../elements/PropTypes";
import MultiSelect from "../../elements/MultiSelect";
import UploadImages from "../shared/images/UploadImages";
export default function ProductForm({
  customer,
  product,
  setProduct,
  showMissingFields,
  productGroup,
  groupIcon,
}) {
  // const [productGroupFilterValue, setProductGroupFilterValue] = useState(null)
  const setProductState = (update) => {
    if ("specific" in update)
      update.customer = update.specific ? customer.id : null;
    setProduct({ ...product, ...update });
  };

  const addImage = (key, imageDict) => {
    setProduct((p) => {
      const images = [...p[key]];
      images.push(imageDict);
      return { ...p, [key]: images };
    });
  };

  const deleteImage = (key, imageDict) => {
    setProduct((p) => ({
      ...p,
      [key]: p[key].filter((i) => i.id !== imageDict.id),
    }));
  };

  const onChange = (e) => {
    setProductState({ [e.target.name]: e.target.value });
  };

  const handleToggleChange = (e, newValue, key) => {
    setProductState({ [key]: newValue });
  };

  const potentialSpecific = !!customer;

  if (groupIcon) {
    return (
      <Form style={{ minHeight: "250px" }}>
        <FormGroup style={{ marginTop: "10px" }}>
          <Typography className="secondary-textcolor">Produktgruppe</Typography>
          <MultiSelect
            onChange={(e) => {
              setProductState({ product_group: e });
            }}
            options={
              productGroup
                ? productGroup
                    .filter((group) => group.state === true)
                    .map((group) => ({ value: group.id, label: group.name }))
                : null
            }
            values={defaultIfEmpty(product.product_group)}
            text="Produktgruppe"
            search={true}
            name="product_group"
          />
        </FormGroup>
      </Form>
    );
  }

  return (
    <Form>
      {product.id ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Produkt ID</Typography>
          <Input
            id="input"
            type="text"
            name="product_id"
            value={defaultIfEmpty(product.id)}
            disabled={true}
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <Typography className="secondary-textcolor">
          Priorität im Angebot (optional)
        </Typography>
        <Input
          id="input"
          type="number"
          name="priority"
          onChange={onChange}
          value={defaultIfEmpty(product.priority)}
          required={false}
          style={{ boxShadow: "none" }}
          min={1}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Produktgruppe</Typography>
        <MultiSelect
          onChange={(e) => {
            setProductState({ product_group: e });
          }}
          options={
            productGroup
              ? productGroup
                  .filter((group) => group.state === true)
                  .map((group) => ({ value: group.id, label: group.name }))
              : null
          }
          values={defaultIfEmpty(product.product_group)}
          text="Produktgruppe"
          search={true}
          name="product_group"
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Produktname</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(product.name)}
          required={true}
          invalid={!!(showMissingFields && !product.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Beschreibung</Typography>
        <Input
          id="input"
          type="textarea"
          name="description"
          onChange={onChange}
          value={defaultIfEmpty(product.description)}
          required={true}
          invalid={!!(showMissingFields && !product.description)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Preis</Typography>
        <Input
          id="input"
          type="number"
          name="price"
          step="any"
          onChange={onChange}
          value={defaultIfEmptyForPrice(product.price)}
          required={true}
          invalid={!!(showMissingFields && !product.price)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">
          Mengenangabe (z.B. Stk., kWh, etc.):
        </Typography>
        <Input
          id="input"
          type="text"
          name="quantity_description"
          onChange={onChange}
          value={defaultIfEmpty(product.quantity_description)}
          required={true}
          invalid={!!(showMissingFields && !product.quantity_description)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>

      {/* ############################################################## */}

      <FormGroup>
        <Typography className="secondary-textcolor">
          In PV-Simulation verwendet?
        </Typography>
        <ToggleButtonGroup
          size="small"
          value={product.in_pv_simulation}
          exclusive
          onChange={(e, newValue) => {
            handleToggleChange(e, newValue, "in_pv_simulation");
          }}
        >
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
        </ToggleButtonGroup>
        {showMissingFields && product.in_pv_simulation === null ? (
          <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
          </div>
        ) : null}
      </FormGroup>

      {/* ############################################################## */}

      <FormGroup>
        <Typography className="secondary-textcolor">
          Muss die MwSt. berücksichtigt werden?
        </Typography>
        <ToggleButtonGroup
          size="small"
          value={product.vat}
          exclusive
          onChange={(e, newValue) => {
            handleToggleChange(e, newValue, "vat");
          }}
        >
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
        </ToggleButtonGroup>
        {showMissingFields && product.vat === null ? (
          <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
          </div>
        ) : null}
      </FormGroup>

      {/* ############################################################## */}

      {potentialSpecific ? (
        <FormGroup>
          <Typography className="secondary-textcolor">
            Produkt zum Sortiment hinzufügen?
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={!product.specific}
            exclusive
            onChange={(e, newValue) => {
              setProductState({ specific: !newValue });
            }}
          >
            <CustomToggle value={false}>Nein</CustomToggle>
            <CustomToggle value={true}>Ja</CustomToggle>
          </ToggleButtonGroup>
          <br />
        </FormGroup>
      ) : (
        <br />
      )}
      <FormGroup>
        <Typography className="secondary-textcolor">Produktfotos</Typography>
        <UploadImages
          images={product.productphotos_set}
          addImage={(img) => addImage("productphotos_set", img)}
          deleteImage={(img) => deleteImage("productphotos_set", img)}
        />
      </FormGroup>
    </Form>
  );
}

ProductForm.propTypes = {
  customer: customerPropType,
  product: productPropType,
  setProduct: PropTypes.func,
  showMissingFields: PropTypes.bool,
  groupIcon: PropTypes.bool,
  productGroup: PropTypes.array,
};
