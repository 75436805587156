import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import LoadingPage from "../elements/LoadingPage";

import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL_UNSUBSCRIBEENERGYREPORT } from "../settings";
import LogoHeader from "../elements/LogoHeader";

export default function UnsubscribeEnergyReport({ session }) {
  const [success, setSuccess] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id !== "test")
      axios
        .post(API_URL_UNSUBSCRIBEENERGYREPORT, { customer: id })
        .then(() => setSuccess(true));
    else setSuccess(true);
  });

  return (
    <Fragment>
      <LogoHeader />
      {success ? (
        <div className="text-center">
          <Typography fontSize="h5.fontSize" style={{ padding: "40px 0px" }}>
            Sie wurden erfolgreich vom monatlichen Energiebericht abgemeldet.
          </Typography>
        </div>
      ) : (
        <LoadingPage />
      )}
      {/* <div className="text-center">
        <Typography fontSize='h5.fontSize' style={{ padding: '40px 0px' }}>{success ? 'Sie wurden erfolgreich vom Newsletter abgemeldet.' : 'Sie werden vom Newsletter abgemeldet...'}</Typography>
      </div > */}
    </Fragment>
  );
}

UnsubscribeEnergyReport.propTypes = {
  session: PropTypes.object.isRequired,
};
