import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";

import DocumentItems from "./DocumentItems";
import ProductFormModal from "./ProductFormModal";
import {
  productPropType,
  customProductPropType,
  customerPropType,
} from "../../elements/PropTypes";
import { hasPermission } from "../../elements/utils";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { Typography } from "@mui/material";

export default function CustomProductItems({
  offer,
  setOffer,
  customProducts,
  setCustomProducts,
  customer,
  products,
  project,
  productGroups,
  resetProducts,
  session,
  amountCols,
  vat = false,
  showPrices = true,
  showDescriptions = false,
  fixedProductKeys = null,
  isDraggable = false,
  editableFields = ["amount", "price", "name"],
  lineMappings,
  setLineMappings,
  stage = null,
}) {
  const isRoofProject = project && project.resourcetype === "RoofProject";
  const [isEasyScaffoldingInstallation, setIsEasyScaffoldingInstallation] =
    useState(false);

  const getDocumentItems = (customProducts) => {
    return customProducts
      .map((customProduct) => {
        const product = products.find((p) => p.id === customProduct.product);
        return product
          ? {
              id: product.id,
              name: customProduct.name ? customProduct.name : product.name,
              key: product.key,
              amount: customProduct.amount,
              price: customProduct.price,
              description: customProduct.description,
              order: customProduct.order,
              priority: customProduct.priority,
              optional: customProduct.optional,
              vat: customProduct.vat,
              amount_planned: product.amount_planned,
              amount_offer: product.amount_offer,
              amount_invoices: product.amount_invoices,
              amount_real: product.amount_real,
              product_group: product.product_group,
            }
          : null;
      })
      .filter((p) => p);
  };

  const getAllItems = (products) => {
    return products.map((product) => {
      return {
        id: product.id,
        name: product.name,
        key: product.key,
        price: product.price,
        description: product.description,
        order: product.order,
        priority: product.priority,
        vat: product.vat || vat,
        amount_planned: product.amount_planned,
        amount_offer: product.amount_offer,
        amount_invoices: product.amount_invoices,
        amount_real: product.amount_real,
        product_group: product.product_group,
      };
    });
  };

  const changeDocumentItem = (item) => {
    // change invoice product on change in table
    const newCustomProducts = [...customProducts];
    const customProduct = newCustomProducts.find((p) => p.product === item.id);
    customProduct.name = item.name;
    customProduct.amount = item.amount;
    customProduct.price = item.price;
    customProduct.description = item.description;
    customProduct.optional = item.optional;
    if (item.key === "batterie_kwh" || item.key === "batterie") {
      const batterie = newCustomProducts.find(
        (p) => p.productObj.key === "batterie",
      );
      const batterieKwh = newCustomProducts.find(
        (p) => p.productObj.key === "batterie_kwh",
      );
      if (batterie) batterie.vat = item.vat;
      if (batterieKwh) batterieKwh.vat = item.vat;
    } else {
      customProduct.vat = item.vat;
    }
    setCustomProducts(newCustomProducts);
  };

  const checkIndex = (priority, newCustomProducts) => {
    // check where priority should placed based on other priority ascending
    const index = newCustomProducts.length + 1;
    for (let i = 0; i < newCustomProducts.length; i++) {
      if (!newCustomProducts[i].priority) {
        return i;
      }
      if (
        newCustomProducts[i].priority < priority &&
        newCustomProducts[i + 1].priority > priority
      ) {
        return i + 1;
      }
    }
    return index;
  };

  const addDocumentItem = (item) => {
    const newCustomProducts = [...customProducts];
    if (item.priority) {
      const index = checkIndex(item.priority, newCustomProducts);
      newCustomProducts.splice(index, 0, {
        id: null,
        product: item.id,
        price: item.price,
        amount: 1,
        description: item.description,
        vat: item.vat,
        order: index,
        priority: item.priority,
        optional: false,
        productObj: products.find((p) => p.id === item.id),
      });
    } else {
      newCustomProducts.push({
        id: null,
        product: item.id,
        price: item.price,
        amount: 1,
        description: item.description,
        vat: item.vat,
        order: newCustomProducts.length + 1,
        priority: item.priority,
        optional: false,
        productObj: products.find((p) => p.id === item.id),
      });
    }
    newCustomProducts.forEach((p, i) => {
      p.order = i + 1;
    });
    setCustomProducts(newCustomProducts);
  };

  const deleteDocumentItem = (item) => {
    if (item.key === "besonderes_geruest" && project.accepted_offer === null) {
      setIsEasyScaffoldingInstallation(true);
      return;
    }
    const newCustomProducts = [...customProducts];
    newCustomProducts.splice(
      newCustomProducts.findIndex((p) => p.product === item.id),
      1,
    );
    newCustomProducts.forEach((p, i) => {
      p.order = i + 1;
    });
    setCustomProducts(newCustomProducts);

    if (isRoofProject) {
      const newLineMappings = { ...lineMappings };
      delete newLineMappings[item.id];
      setLineMappings(newLineMappings);
    }
  };

  const deleteScaffoldingDocument = () => {
    setOffer({ ...offer, scaffolding_easy_installation: true });
    const newCustomProducts = [...customProducts];
    newCustomProducts.forEach((p, i) => {
      if (p.productObj.key === "besonderes_geruest") {
        newCustomProducts.splice(i, 1);
      }
    });
    newCustomProducts.forEach((p, i) => {
      p.order = i + 1;
    });
    setCustomProducts(newCustomProducts);
  };

  const changeDocumentItemsOrder = (oldIndex, newIndex) => {
    const newCustomProducts = [...customProducts];
    const movedItem = newCustomProducts[oldIndex];
    newCustomProducts.splice(oldIndex, 1);
    newCustomProducts.splice(newIndex, 0, movedItem);
    newCustomProducts.forEach((p, i) => {
      p.order = i + 1;
    });
    setCustomProducts(newCustomProducts);
  };

  const createProduct = (newProduct) => {
    // add product to state
    const newCustomProducts = [...customProducts];
    newProduct.amount_planned = 0;
    newProduct.amount_offer = 0;
    newProduct.amount_real = 0;
    newProduct.amount_invoices = 0;
    if (newProduct.priority) {
      const index = checkIndex(newProduct.priority, newCustomProducts);
      newCustomProducts.splice(index, 0, {
        id: null,
        product: newProduct.id,
        price: newProduct.price,
        amount: 1,
        description: newProduct.description,
        order: newProduct.priority,
        priority: newProduct.priority,
        optional: false,
        productObj: newProduct,
      });
    } else {
      newCustomProducts.push({
        id: null,
        product: newProduct.id,
        price: newProduct.price,
        amount: 1,
        description: newProduct.description,
        order: newCustomProducts.length + 1,
        priority: newProduct.priority,
        optional: false,
        productObj: newProduct,
      });
    }
    newCustomProducts.forEach((p, i) => {
      p.order = i + 1;
    });
    setCustomProducts(newCustomProducts);
    resetProducts();
  };

  if (!products) return null;

  return (
    <Fragment>
      <DocumentItems
        documentItems={getDocumentItems(customProducts)}
        changeDocumentItem={changeDocumentItem}
        allItems={getAllItems(products)}
        productGroups={productGroups}
        project={project}
        addDocumentItem={addDocumentItem}
        deleteDocumentItem={deleteDocumentItem}
        changeDocumentItemsOrder={changeDocumentItemsOrder}
        amountCols={amountCols}
        showPrices={showPrices}
        showDescriptions={showDescriptions}
        fixedProductKeys={fixedProductKeys}
        isDraggable={isDraggable}
        editableFields={editableFields}
        session={session}
        lineMappings={lineMappings}
        setLineMappings={setLineMappings}
        stage={stage}
      />
      <br />
      <br />
      {hasPermission(session.user, "customer_handling_prices") ? (
        <ProductFormModal
          session={session}
          customer={customer}
          resetParent={createProduct}
          productGroup={productGroups}
        />
      ) : null}
      <div>
        <br />
      </div>
      <CustomModal
        size="md"
        isOpen={isEasyScaffoldingInstallation}
        setIsOpen={() => {}}
        onClose={() => setIsEasyScaffoldingInstallation(false)}
        title="Gerüst"
        getFooter={(toggleDefaultModal) => (
          <SaveModalFooter
            onSave={() => {
              toggleDefaultModal();
              deleteScaffoldingDocument();
              setIsEasyScaffoldingInstallation(false);
            }}
            onCancel={() => {
              toggleDefaultModal();
              setIsEasyScaffoldingInstallation(false);
            }}
            saveBtnLabel="Ja"
            cancelBtnLabel="Abbrechen"
          />
        )}
      >
        <br />
        <Typography>
          Du hast den &quot;besonderes Gerüst&quot; Aufschlag entfernt.
        </Typography>
        <Typography>
          Hiermit bestätige ich: {project?.salesman_name}, dass das Gerüst
          einfach aufgebaut wird.
        </Typography>
      </CustomModal>
    </Fragment>
  );
}

CustomProductItems.propTypes = {
  offer: PropTypes.object,
  setOffer: PropTypes.func,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  customer: customerPropType,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.arrayOf(PropTypes.object),
  project: PropTypes.object,
  resetProducts: PropTypes.func,
  amountCols: PropTypes.object,
  vat: PropTypes.bool,
  showPrices: PropTypes.bool,
  showDescriptions: PropTypes.bool,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  isDraggable: PropTypes.bool,
  editableFields: PropTypes.arrayOf(PropTypes.string),
  session: PropTypes.object,
  lineMappings: PropTypes.object,
  setLineMappings: PropTypes.func,
  stage: PropTypes.string,
};
