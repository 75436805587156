import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import moment from "moment";
import { PropTypes } from "prop-types";
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-datepicker/dist/react-datepicker.css";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Typography, Stack, IconButton, Grid, Button } from "@mui/material";
import Add from "@mui/icons-material/Add";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import DropDown from "../../../../elements/DropDown";
import MultiSelect from "../../../../elements/MultiSelect";
import {
  batteryPropType,
  circuitDiagramPropType,
  constructionPropType,
  customProductPropType,
  eMeterPropType,
  inverterPropType,
  modulePropType,
  productPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import {
  CustomButton,
  CustomToggle,
  CustomIconButton,
} from "../../../../elements/StyledElements";
import {
  batteryOptions,
  checkAccessRights,
  date2String,
  defaultIfEmpty,
  getCustomerName,
  getProjectOverviewTable,
  round,
  string2Date,
  getRandomId,
  hasPermission,
  sortByOrder,
  hexToRGB,
  gregorianDe,
  getFirstDate,
  projectNameWithLabel,
  getScheduleStrs,
} from "../../../../elements/utils";
import {
  API_URL_CONSTRUCTION,
  API_URL_CREATECONSTRUCTIONCOSTDIFFERENCE,
  API_URL_OPERATOR,
  API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE,
  API_URL_SENDCONSTRUCTIONDATE,
  API_URL_USER,
  API_URL_ROOFIMAGE,
  API_URL_ROOFIMAGEPANEL,
  API_URL_ROOFIMAGEPANEL_BULK,
  API_URL_PROJECT,
  API_URL_STRING,
  API_URL_WAREHOUSEDELIVERYSUPPLIER,
  API_URL_WAREHOUSESUPPLIER,
  API_URL_WAREHOUSEPRODUCT,
  API_URL_CUSTOMER,
} from "../../../../settings";
import CustomProductItems from "../../../products/CustomProductItems";
import EditRoofImagePlanning from "../../../shared/images/EditRoofImagePlanning";
import RoofImageUpload from "../../../shared/images/RoofImageUpload";
import ImageStage from "../../../shared/images/ImageStage";
import RoofImage from "../../../shared/images/RoofImage";
import EmailModal from "../../../shared/modal_utils/EmailModal";
import { tenantModelOptions } from "../variables";
import GrowattAccountModal from "./GrowattAccountModal";
import OperatorModal from "./operators/OperatorModal";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import AdditionalExteriorHouseImageModal from "./AdditionalExteriorHouseImageModal";
import { Table } from "react-bootstrap";
import WarehouseDeliveryAmountModal from "../../../warehouse/WarehouseDeliveryAmountModal";
import InverterList from "../InverterList";
import TaskTable from "../../../customers/TaskTable";
import CollapsibleTable from "../../../../elements/CollapsibleTable";
import { getProjectType } from "../../projectUtils";
import EMeterFormEntry from "../operator_requests/EMeterFormEntry";

const emptyEMeterForm = {
  id: null,
  value: null,
  e_meter_id: "",
  action: null,
  project: null,
};

export default function PlantConstructionForm({
  project,
  construction,
  setConstruction,
  products,
  productGroups,
  resetProducts,
  customProducts,
  setCustomProducts,
  currCustomProducts,
  showMissingFields,
  modules,
  inverters,
  batteries,
  circuitDiagrams,
  amountCols,
  fixedProductKeys,
  session,
  emeters,
  setEmeters,
  employeeSchedule,
  setConstructionManager,
}) {
  const [constructionManagers, setConstructionManagers] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [electricians, setElectricians] = useState(null);
  const [growattAccount, setGrowattAccount] = useState(null);
  const [operators, setOperators] = useState(
    project ? JSON.parse(localStorage.getItem(`operators:${project.id}`)) : [],
  );
  const [costDifferenceModal, setCostDifferenceModal] = useState({
    type: "email",
    isOpen: false,
    attachments: [],
  });
  const pickerRef1 = useRef(null);
  const pickerRef2 = useRef(null);
  const [defaultInverterSet, setDefaultInverterSet] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouseProducts, setWarehouseProducts] = useState([]);
  const [warehouseDeliverySuppliers, setWarehouseDeliverySuppliers] = useState(
    [],
  );

  const resetRoofImage = async () => {
    const projectId = project.id;
    if (!projectId) return;
    const res = await axios.get(API_URL_PROJECT + projectId, {
      params: { nested: true },
    });
    const currConstruction = res.data.construction_obj;

    const roofImageSet = currConstruction.roofimage_set;
    setConstruction({ ...construction, roofimage_set: roofImageSet });
  };

  const addRoofImage = async (
    newDirection,
    image,
    imageWidthM,
    houseImage,
    angle,
    panels,
    flatRoof,
  ) => {
    const constructionId = project.construction;
    if (!constructionId) {
      setConstruction((p) => {
        const roofImages = [...p.roofimage_set];
        roofImages.push({
          id: -getRandomId(),
          direction: newDirection,
          image,
          image_width_m: imageWidthM,
          angle,
          house_image: houseImage,
          flat_roof: flatRoof,
          string_set: [],
          roofimagepanel_set: panels,
        });
        return { ...p, roofimage_set: roofImages };
      });
    }
    const roofImageForm = new FormData();
    roofImageForm.append("construction", constructionId);
    roofImageForm.append("direction", newDirection);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("image", image);
    roofImageForm.append("string_set", []);
    roofImageForm.append("house_image", houseImage);
    roofImageForm.append("roofimagepanel_set", panels);
    roofImageForm.append("image_width_m", imageWidthM);
    roofImageForm.append("angle", angle);

    const roofImageRes = await axios.post(API_URL_ROOFIMAGE, roofImageForm);
    const roofImageId =
      roofImageRes && roofImageRes.data && roofImageRes.data.id;
    if (roofImageId && panels && panels.length > 0) {
      const panelBulkReq = panels.map((panel) => {
        const newPanel = { ...panel };
        delete newPanel.id;
        newPanel.roof_image = roofImageId;
        return newPanel;
      });
      await axios.post(API_URL_ROOFIMAGEPANEL_BULK, panelBulkReq);
    }

    await resetRoofImage();
  };

  const isNewObj = (obj) => obj.id <= 0;

  const translate = (id, translation) => {
    if (!id) return null;
    const foundTranslation = translation.find(([oldId, newId]) => oldId === id);
    return foundTranslation ? foundTranslation[1] : null;
  };

  const getStringPromise = async (roofImage, roofImageId) => {
    const exceptIds = roofImage.string_set
      .filter((s) => !isNewObj(s))
      .map((s) => s.id);
    const promise =
      roofImage.id > 0
        ? axios.delete(API_URL_STRING, {
            data: { roof_image: roofImageId, except_ids: exceptIds },
          })
        : Promise.resolve();
    await promise;

    const stringPromises = roofImage.string_set.map(async (string) => {
      const stringPromise = isNewObj(string)
        ? axios.post(API_URL_STRING, {
            ...string,
            roof_image: roofImageId,
            id: null,
            construction_inverter: null,
          })
        : axios.put(`${API_URL_STRING}${string.id}`, {
            ...string,
            roof_image: roofImageId,
            construction_inverter: null,
          });
      const res = await stringPromise;
      return [string.id, res.data.id];
    });

    return Promise.all(stringPromises);
  };

  useEffect(() => {
    if (project)
      setGrowattAccount(
        project.growatt_user
          ? {
              username: project.growatt_user,
              password: project.growatt_password,
            }
          : null,
      );
  }, [project]);

  const updateRoofImage = async (
    roofImageId,
    panels,
    strings,
    angle,
    direction,
    flatRoof,
  ) => {
    const constructionId = project.construction;
    if (!constructionId) {
      const roofImages = [...construction.roofimage_set];
      const roofImage = roofImages.find((x) => x.id === roofImageId);
      roofImage.direction = direction;
      roofImage.flat_roof = flatRoof;
      roofImage.angle = angle;
      roofImage.roofimagepanel_set = panels;
      roofImage.string_set = strings;
      setConstruction((p) => ({ ...p, roofimage_set: roofImages }));
      return;
    }

    const roofImageForm = new FormData();
    roofImageForm.append("construction", constructionId);
    roofImageForm.append("direction", direction);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("roofimagepanel_set", panels);
    roofImageForm.append("string_set", strings);
    roofImageForm.append("angle", angle);

    const roofImage = {
      id: roofImageId,
      string_set: strings,
      direction,
      flat_roof: flatRoof,
      angle,
      roofimagepanel_set: panels,
    };

    await axios.put(API_URL_ROOFIMAGE + roofImageId.toString(), roofImageForm);
    await axios.delete(API_URL_ROOFIMAGEPANEL, {
      data: { roof_image: roofImageId, except_ids: [] },
    });
    const stringsPromise = await getStringPromise(roofImage, roofImageId);
    const panelBulkReq = panels.map((panel) => {
      const newPanel = { ...panel };
      delete newPanel.id;
      const newStringId = translate(panel.string, stringsPromise);
      newPanel.string = newStringId;
      newPanel.roof_image = roofImageId;
      return newPanel;
    });
    await axios.post(API_URL_ROOFIMAGEPANEL_BULK, panelBulkReq);
    await resetRoofImage();

    const pvModuleProduct = products.find((p) => p.key === "pv_module");
    const roofImages = [...construction.roofimage_set];
    const newCustomProducts = customProducts.map((p) => {
      if (p.product === pvModuleProduct.id) {
        p.amount = roofImages.length;
      }
      return p;
    });
    setCustomProducts(newCustomProducts);
  };

  useEffect(() => {
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true },
      })
      .then((res) => {
        setConstructionManagers(
          res.data.filter((user) => user.group_key === "construction_managers"),
        );
        setElectricians(
          res.data.filter((user) => user.group_key === "electricians"),
        );
      });
    getOperators();
    axios.get(API_URL_WAREHOUSESUPPLIER).then((res) => {
      setSuppliers(res.data);
    });
    axios
      .get(API_URL_CUSTOMER + project.customer, { params: { nested: true } })
      .then((res) => {
        setCustomer(res.data);
      });
    getWarehouseProducts();
    getWarehouseDeliverySuppliers();
  }, []);

  const getOperators = () => {
    const localStorageOperators = localStorage.getItem(
      `operators:${project.id}`,
    );
    if (localStorageOperators) {
      const parsedOperators = JSON.parse(
        localStorage.getItem(`operators:${project.id}`),
      );
      if (parsedOperators && parsedOperators.length > 1) {
        localStorage.removeItem(`operators:${project.id}`);
      } else {
        return;
      }
    }

    axios
      .get(API_URL_OPERATOR, {
        params: {
          street_and_number: project.street_and_number_project,
          zip_and_city: project.zip_and_city_project,
        },
      })
      .then((res) => {
        const _operators = res.data;
        setOperators(_operators);
        localStorage.setItem(
          `operators:${project.id}`,
          JSON.stringify(_operators),
        );
      });
  };

  const getWarehouseProducts = () => {
    axios.get(API_URL_WAREHOUSEPRODUCT).then((res) => {
      setWarehouseProducts(res.data);
    });
  };

  const getDefaultSupplierInfo = (warehouseProduct) => {
    const defaultSupplierId = warehouseProduct.amounts.find(
      (amount) => amount.location_id === 1,
    );
    const defaultSupplier = warehouseProduct.suppliers.find(
      (supplier) => supplier.id === defaultSupplierId?.active_supplier_id,
    );
    warehouseProduct.supplier_id = defaultSupplier?.supplier_id;
    warehouseProduct.supplier_name = suppliers.find(
      (supplier) => supplier.id === defaultSupplier?.supplier_id,
    )?.name;
    warehouseProduct.supplier_product_name = defaultSupplier?.product_name;
    warehouseProduct.supplier_product_id = defaultSupplier?.product_id;
    warehouseProduct.supplier_price = defaultSupplier?.price;
    return warehouseProduct;
  };

  const getWarehouseDeliverySuppliers = () => {
    if (!project.construction) return;
    axios
      .get(API_URL_WAREHOUSEDELIVERYSUPPLIER, {
        params: { project: project.id },
      })
      .then((res) => {
        setWarehouseDeliverySuppliers(res.data);
      });
  };

  useEffect(() => {
    if (operators && operators.length === 1) {
      setConstruction((c) => ({ ...c, operator: operators[0].id }));
    }
  }, [operators, construction.operator]);

  const { kwp, n_inverters: NInverters } = construction;
  const batteriesAmount = construction.batterykwh_set.filter(
    (b) => b.kwh !== null,
  ).length;

  // This useEffect for initial check, so no infinite re-renders
  const hasRunOnceRef = useRef(false);
  useEffect(() => {
    const isFilled = customProducts && customProducts.length > 0;
    if (!hasRunOnceRef.current && isFilled) {
      handleChangeWechselrichter(NInverters);
      hasRunOnceRef.current = true;
    }
  }, [customProducts]);

  useEffect(() => {
    const isFilled = customProducts && customProducts.length > 0;
    if (isFilled) {
      handleChangeWechselrichter(NInverters);
    }
  }, [kwp, NInverters]);

  useEffect(() => {
    const isFilled = customProducts && customProducts.length > 0;
    if (isFilled) {
      handleChangeAcGekoppelt(kwp, batteriesAmount);
    }
  }, [kwp, batteriesAmount]);

  const updateConstructionInverter = (constructionInverterId, inverterId) => {
    const newConstructionInverters = [...construction.constructioninverter_set];
    const constructionInverter = newConstructionInverters.find(
      (i) => i.id === constructionInverterId,
    );
    constructionInverter.inverter = inverterId;
    setConstruction((c) => ({
      ...c,
      constructioninverter_set: newConstructionInverters,
    }));
  };

  useEffect(() => {
    if (
      !defaultInverterSet &&
      project &&
      construction &&
      !project.construction &&
      construction.constructioninverter_set.length > 0
    ) {
      const defaultInverterExists = inverters.find(
        (inverter) => inverter.name === "Growatt MAX50KTL3LV",
      );
      if (defaultInverterExists && construction.kwp > 50) {
        const constructionInverterId =
          construction.constructioninverter_set[0].id;
        updateConstructionInverter(
          constructionInverterId,
          defaultInverterExists.id,
        );
        setDefaultInverterSet(true);
      }
    }
  }, [construction.constructioninverter_set]);

  const getStringFromRoofImages = (roofImages) =>
    roofImages.map((r) => r.string_set).flat();

  const updateInverterStrings = (constructionInverterId, stringIds) => {
    setConstruction((oldConstruction) => {
      const roofImages = [...oldConstruction.roofimage_set];
      const allStrings = getStringFromRoofImages(roofImages);
      allStrings.forEach((string) => {
        if (stringIds.includes(string.id))
          string.construction_inverter = constructionInverterId;
        else if (string.construction_inverter === constructionInverterId)
          string.construction_inverter = null;
      });
      return { ...oldConstruction, roofimage_set: roofImages };
    });
  };

  const handleToggleChange = (key, e, value) => {
    if (key === "scaffolding_check") {
      if (value) {
        setConstruction((c) => ({
          ...c,
          construction_planner: session.user.id,
        }));
      }
    }
    setConstruction((c) => ({ ...c, [key]: value }));
  };

  const deleteRoofImage = async (roofImageId) => {
    const constructionId = project.construction;
    if (!constructionId) {
      setConstruction((p) => {
        const roofImages = p.roofimage_set.filter((x) => x.id !== roofImageId);
        return { ...p, roofimage_set: roofImages };
      });
      return;
    }
    await axios.delete(API_URL_ROOFIMAGE + roofImageId.toString());
    await resetRoofImage();
  };

  if (!construction || modules === null || !project) return null;

  let acceptedPlanning = project.planning_set.find(
    (p) => p.id === project.accepted_offer_obj.planning,
  );
  acceptedPlanning = {
    ...acceptedPlanning,
    onepointeight_emeterimage_set: acceptedPlanning.emeterimage_set.filter(
      (image) => image.type === "1.8.0",
    ),
    twopointeight_emeterimage_set: acceptedPlanning.emeterimage_set.filter(
      (image) => image.type === "2.8.0",
    ),
    slsswitch_emeterimage_set: acceptedPlanning.emeterimage_set.filter(
      (image) => image.type === "SLS-Switch",
    ),
    additional_emeterimage_set: acceptedPlanning.emeterimage_set.filter(
      (image) => image.type === "additional",
    ),
  };
  const module = modules.find((m) => m.id === construction.module);

  const changeEMeter = (eMeterId, attr, value) => {
    setEmeters((prevEmeters) => {
      const updatedEmeters = [...prevEmeters];
      const eMeterToUpdate = updatedEmeters.find((e) => e.id === eMeterId);
      if (eMeterToUpdate) {
        eMeterToUpdate[attr] = value;
      }
      return updatedEmeters;
    });
    setConstruction((prevConstruction) => {
      const updatedEMeters = [...prevConstruction.emeter_set];
      const eMeterToUpdateInConstruction = updatedEMeters.find(
        (e) => e.id === eMeterId,
      );
      if (eMeterToUpdateInConstruction) {
        eMeterToUpdateInConstruction[attr] = value;
      }
      return { ...prevConstruction, emeter_set: updatedEMeters };
    });
  };

  const addEMeter = () => {
    const newEMeter = {
      ...emptyEMeterForm,
      id: -getRandomId(),
      project: project.id,
    };
    setEmeters((prevEmeters) => [...prevEmeters, newEMeter]);
    setConstruction((prevConstruction) => ({
      ...prevConstruction,
      emeter_set: [...prevConstruction.emeter_set, newEMeter],
    }));
  };

  const removeEMeter = (eMeterId) => {
    setEmeters((prevEmeters) => prevEmeters.filter((e) => e.id !== eMeterId));
    setConstruction((prevConstruction) => {
      const updatedEMeters = prevConstruction.emeter_set.filter(
        (e) => e.id !== eMeterId,
      );
      return { ...prevConstruction, emeter_set: updatedEMeters };
    });
  };

  const onCostDifferenceBtnClicked = (e) => {
    axios
      .post(API_URL_CREATECONSTRUCTIONCOSTDIFFERENCE, {
        project: project.id,
        customProducts,
      })
      .then(() => {
        const constructionId = project.construction;
        const offerId = project.accepted_offer;

        if (constructionId) {
          axios.get(API_URL_CONSTRUCTION + constructionId).then((res) => {
            const pdfPath = res.data.cost_difference_pdf;
            if (pdfPath) {
              setCostDifferenceModal({
                ...costDifferenceModal,
                type: "email",
                isOpen: true,
                attachments: [
                  {
                    type: "pdf",
                    name: `Kostendifferenz_Angebot_${offerId}_Bauplanung_${constructionId}`,
                    data: res.data.cost_difference_pdf,
                  },
                ],
              });
            } else {
              setCostDifferenceModal({
                ...costDifferenceModal,
                type: "info",
                isOpen: true,
              });
            }
          });
        }
      });
  };

  const handleChangeWechselrichter = (val) => {
    if (customProducts.length === 0) return;

    const zusatz13 = products.find((p) => p.key === "zusatz_wr_13");
    const zusatz17 = products.find((p) => p.key === "zusatz_wr_17");
    const zusatz = products.find(
      (p) => p.name === "Zusätzlicher Wechselrichter",
    );
    const newCustomProducts = customProducts.filter(
      (p) =>
        p.product !== zusatz13.id &&
        p.product !== zusatz17.id &&
        p.product !== zusatz.id,
    );

    if (val === 2) {
      if (construction.kwp > 13 && construction.kwp <= 17) {
        newCustomProducts.push({
          id: null,
          product: zusatz13.id,
          price: zusatz13.price,
          amount: 1,
          description: zusatz13.description,
          productObj: zusatz13,
        });
      } else if (construction.kwp > 17 && construction.kwp <= 36) {
        newCustomProducts.push({
          id: null,
          product: zusatz17.id,
          price: zusatz17.price,
          amount: 1,
          description: zusatz17.description,
          productObj: zusatz17,
        });
      }
    } else if (val === 3) {
      if (construction.kwp > 17 && construction.kwp <= 36) {
        newCustomProducts.push({
          id: null,
          product: zusatz17.id,
          price: zusatz17.price,
          amount: 1,
          description: zusatz17.description,
          productObj: zusatz17,
        });
      }
      newCustomProducts.push({
        id: null,
        product: zusatz.id,
        price: zusatz.price,
        amount: 1,
        description: zusatz.description,
        productObj: zusatz,
      });
    }
    setCustomProducts(newCustomProducts);
  };

  const handleChangeAcGekoppelt = (kwp, batteriesAmount) => {
    if (customProducts.length === 0) return;

    if (kwp > 36) {
      const newProduct = products.find(
        (p) => p.key === "wechselrichter_ac_gekoppelt",
      );
      const isProduct = customProducts.find((p) => p.product === newProduct.id);
      if (!isProduct) {
        const newCustomProducts = [...customProducts];
        newCustomProducts.push({
          id: null,
          name: newProduct.name,
          product: newProduct.id,
          price: newProduct.price,
          amount: batteriesAmount,
          description: newProduct.description,
          productObj: newProduct,
          order: newCustomProducts.length + 1,
        });
        setCustomProducts(newCustomProducts);
      } else {
        const newCustomProducts = customProducts.map((p) => {
          if (p.product === newProduct.id) {
            p.amount = batteriesAmount;
          }
          return p;
        });
        setCustomProducts(newCustomProducts);
      }
    } else {
      const newProduct = products.find(
        (p) => p.key === "wechselrichter_ac_gekoppelt",
      );
      const isProduct = customProducts.find((p) => p.product === newProduct.id);
      if (isProduct) {
        const newCustomProducts = customProducts.filter(
          (p) => p.product !== newProduct.id,
        );
        setCustomProducts(newCustomProducts);
      }
    }
  };

  const updateCustomProductsForBattery = (batteryKwh, batteryKwhSet) => {
    const batteryAmountProduct = batteryKwhSet.filter(
      (b) => b.kwh !== null,
    ).length;
    if (batteryKwh > 0) {
      const batteryProduct = products.find((p) => p.key === "batterie");
      const batteryKwhProduct = products.find((p) => p.key === "batterie_kwh");
      const isBattery = customProducts.find(
        (p) => p.product === batteryProduct.id,
      );
      const isBatteryKwh = customProducts.find(
        (p) => p.product === batteryKwhProduct.id,
      );

      const newCustomProducts = [...customProducts];
      if (!isBattery || !isBatteryKwh) {
        if (!isBattery) {
          newCustomProducts.push({
            id: null,
            name: batteryProduct.name,
            product: batteryProduct.id,
            price: batteryProduct.price,
            amount: batteryAmountProduct,
            description: batteryProduct.description,
            productObj: batteryProduct,
            order: newCustomProducts.length + 1,
          });
        }
        if (!isBatteryKwh) {
          newCustomProducts.push({
            id: null,
            name: batteryKwhProduct.name,
            product: batteryKwhProduct.id,
            price: batteryKwhProduct.price,
            amount: batteryKwh,
            description: batteryKwhProduct.description,
            productObj: batteryKwhProduct,
            order: newCustomProducts.length + 1,
          });
        }
        setCustomProducts(newCustomProducts);
      } else {
        const newCustomProducts = customProducts.map((p) => {
          if (p.product === batteryKwhProduct.id) {
            p.amount = batteryKwh;
          }
          if (p.product === batteryProduct.id) {
            p.amount = batteryAmountProduct;
          }
          return p;
        });
        setCustomProducts(newCustomProducts);
      }
    } else {
      const batteryProduct = products.find((p) => p.key === "batterie");
      const batteryKwhProduct = products.find((p) => p.key === "batterie_kwh");

      if (batteryProduct.amount_offer > 0) {
        const newCustomProducts = customProducts.map((p) => {
          if (
            p.product === batteryProduct.id ||
            p.product === batteryKwhProduct.id
          ) {
            p.amount = 0;
          }
          return p;
        });
        setCustomProducts(newCustomProducts);
      } else {
        // remove battery and battery_kwh from customProducts
        const newCustomProducts = customProducts.filter(
          (p) =>
            p.product !== batteryProduct.id &&
            p.product !== batteryKwhProduct.id,
        );
        setCustomProducts(newCustomProducts);
      }
    }
  };

  const updateBattery = (index, val) => {
    const batterySet = [...construction.batterykwh_set];
    batterySet[index].kwh = val || 0;
    const batteryKwh = round(
      batterySet.reduce((acc, b) => acc + b.kwh, 0),
      2,
    );
    setConstruction((c) => ({
      ...c,
      battery_kwh: batteryKwh,
      batterykwh_set: batterySet,
    }));
    updateCustomProductsForBattery(batteryKwh, batterySet);
  };

  const deleteBattery = (index) => {
    const batterySet = [...construction.batterykwh_set];
    const filteredBatterySet = batterySet.filter((_, i) => i !== index);
    filteredBatterySet.forEach((b, i) => {
      b.order = i + 1;
    });
    const batteryKwh = round(
      filteredBatterySet.reduce((acc, b) => acc + b.kwh, 0),
      2,
    );
    setConstruction((c) => ({
      ...c,
      battery_kwh: batteryKwh,
      batterykwh_set: filteredBatterySet,
    }));
    updateCustomProductsForBattery(batteryKwh, filteredBatterySet);
  };

  const addBattery = () => {
    setConstruction((c) => {
      const batterySet = [...c.batterykwh_set];
      batterySet.push({
        id: -getRandomId(),
        kwh: null,
        order: batterySet.length + 1,
      });
      return { ...c, batterykwh_set: batterySet };
    });
  };

  const getScheduleColor = (scheduleInfo) => {
    if (scheduleInfo.time_management_events) return "rgba(13, 122, 46, 0.5)";
    if (scheduleInfo.n_constructions) return "rgba(122, 20, 13, 0.5)";
    if (scheduleInfo.n_tasks) return "rgba(245, 69, 49, 0.5)";
    return null;
  };

  const handleOpenDatePicker = () => {
    if (pickerRef1.current) {
      pickerRef1.current.openCalendar();
    }
  };

  const projectTable = customer && (
    <>
      <Typography className="secondary-textcolor" style={{ fontSize: 22 }}>
        Alle Projekte
      </Typography>
      <CollapsibleTable
        columns={[
          {
            name: "Name",
            key: "name",
            render: (value, row) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.hasActiveOffer && (
                  <span
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "green",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  ></span>
                )}
                {value}
              </div>
            ),
          },
          {
            name: "Typ",
            key: "type",
          },
          {
            name: "Nächster Schritt",
            key: "todo",
          },
        ]}
        rows={customer.project_set.map((project) => ({
          key: `${project.id}`,
          name: projectNameWithLabel(project),
          type: getProjectType(project),
          link: `/projekt/${project.id}`,
          todo: project.open_todo_name,
          hasActiveOffer: project.accepted_offer > 0,
        }))}
        counter={true}
        collapsible={false}
        targetBlank={true}
      />
    </>
  );

  return (
    <>
      <Container style={{ marginTop: "10px" }}>
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Kundeninformationen
        </Typography>
        {getProjectOverviewTable(project)}
        {checkAccessRights(session.user, "projekt/:id") ? (
          <Row>
            <Link
              to={"/projekt/" + project.id}
              style={{ textDecoration: "none" }}
            >
              <CustomButton icon="clipboard" style={{ width: "200px" }}>
                zur Projektseite
              </CustomButton>
            </Link>
          </Row>
        ) : null}
        <hr className="secondary-textcolor" />
      </Container>
      {customer && (
        <Container style={{ marginTop: "10px" }}>
          <Stack rowGap={2}>
            {projectTable}
            <TaskTable
              customer={customer}
              session={session}
              fromConstructionPlanning
            />
            <Stack>
              <Typography
                className="secondary-textcolor"
                style={{ fontSize: 22 }}
              >
                Notizen
              </Typography>
              <CollapsibleTable
                columns={[
                  {
                    name: "Notiz",
                    key: "note",
                  },
                  {
                    name: "Quelle",
                    key: "source",
                  },
                ]}
                rows={[
                  {
                    key: "Kundennotizen",
                    note: customer.note,
                    source: "Kundennotizen",
                    link: `/kunde/${customer.id}`,
                  },
                  ...customer.project_set
                    .filter((project) => project.note)
                    .map((project) => ({
                      key: `${project.id}`,
                      note: project.note,
                      source: project.name,
                      link: `/projekt/${project.id}`,
                    })),
                ].filter((item) => item.note)}
                collapsible={false}
                targetBlank={true}
              />
            </Stack>
          </Stack>
        </Container>
      )}
      <Container style={{ marginTop: "10px" }}>
        <Row>
          <Col>
            <Form>
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Informationen zur Baustelle
              </Typography>
              {constructionManagers !== null ? (
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Bauleiter:
                  </Typography>
                  <DropDown
                    onChange={(x) => {
                      const newEmployees = construction.employees.filter(
                        (employeeId) =>
                          employeeId !== construction.construction_manager &&
                          employeeId !== x,
                      );
                      if (x) newEmployees.push(x);
                      setConstructionManager(
                        constructionManagers.find((user) => user.id === x),
                      );
                      setConstruction((c) => ({
                        ...c,
                        construction_manager: x,
                        employees: newEmployees,
                      }));
                    }}
                    options={constructionManagers.map((user) => ({
                      label: user.first_name + " " + user.last_name,
                      value: user.id,
                      props: {
                        style:
                          employeeSchedule && employeeSchedule[user.id]
                            ? {
                                backgroundColor: getScheduleColor(
                                  employeeSchedule[user.id],
                                ),
                              }
                            : {},
                      },
                    }))}
                    value={construction.construction_manager}
                    text="Bauleiter wählen"
                    sort={true}
                    search={true}
                  />
                  {showMissingFields && !construction.construction_manager ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                  {construction.construction_manager &&
                  employeeSchedule &&
                  employeeSchedule[construction.construction_manager]
                    ? getScheduleStrs(
                        employeeSchedule[construction.construction_manager],
                      ).map((str, idx) => (
                        <Typography key={idx} color={"red"}>
                          {str}
                        </Typography>
                      ))
                    : null}
                </FormGroup>
              ) : null}
              {electricians !== null ? (
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Elektrofachkraft:
                  </Typography>
                  <MultiSelect
                    onChange={(x) => {
                      const newEmployees = construction.employees.filter(
                        (employeeId) =>
                          !construction.electricians.includes(employeeId) &&
                          !x.includes(employeeId),
                      );
                      newEmployees.push(...x);

                      setConstruction((c) => ({
                        ...c,
                        electricians: x,
                        employees: newEmployees,
                      }));
                    }}
                    options={electricians.map((e) => ({
                      label: `${e.first_name} ${e.last_name}`,
                      value: e.id,
                      hidden: !e.is_active,
                      color:
                        employeeSchedule &&
                        employeeSchedule[e.id] &&
                        getScheduleColor(employeeSchedule[e.id]),
                    }))}
                    values={construction.electricians}
                    text="Elektrofachkraft wählen"
                    sort={true}
                  />
                  {showMissingFields && !construction.electricians ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                  {construction.electricians && employeeSchedule
                    ? construction.electricians
                        .filter((electrician) => employeeSchedule[electrician])
                        .map((electrician) =>
                          getScheduleStrs(employeeSchedule[electrician]).map(
                            (str, idx) => (
                              <Typography key={idx} color={"red"}>
                                {str}
                              </Typography>
                            ),
                          ),
                        )
                    : null}
                </FormGroup>
              ) : null}
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Datum der Montage
                </Typography>
                <Row>
                  <Col md="auto">
                    <div
                      className="custom-date-picker"
                      style={{ maxWidth: 300, width: "auto", minWidth: 150 }}
                      onClick={handleOpenDatePicker}
                    >
                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: "5px",
                          minHeight: "40px",
                          overflowY: "auto",
                        }}
                      >
                        {construction.constructiondates_set &&
                          construction.constructiondates_set.map(
                            (dateString, index) => {
                              const date = dateString
                                ? string2Date(dateString.date)
                                : null;
                              const formattedDate = date
                                ? moment(date).format("DD/MM/YYYY")
                                : "";
                              return (
                                <span key={index}>
                                  {formattedDate}{" "}
                                  {index !==
                                    construction.constructiondates_set.length -
                                      1 && ","}{" "}
                                  &nbsp;
                                </span>
                              );
                            },
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col md="auto">
                    <DatePicker
                      ref={pickerRef1}
                      locale={gregorianDe}
                      weekStartDayIndex={1}
                      render={<Icon />}
                      format="DD/MM/YYYY"
                      multiple
                      showOtherDays
                      value={
                        construction.constructiondates_set !== null
                          ? construction.constructiondates_set.map((dateObj) =>
                              dateObj ? string2Date(dateObj.date) : null,
                            )
                          : null
                      }
                      onChange={(dates) => {
                        const dateObjects = dates.map((date) => ({
                          date: date2String(date.toDate()),
                          construction: construction.id,
                        }));
                        const maxDate = dateObjects.length
                          ? new Date(
                              Math.max(
                                ...dateObjects.map(
                                  (dateObj) => new Date(dateObj.date),
                                ),
                              ),
                            )
                          : null;
                        setConstruction((c) => ({
                          ...c,
                          constructiondates_set: dateObjects,
                          completion_date: maxDate
                            ? date2String(maxDate)
                            : null,
                          commissioning_date: maxDate
                            ? date2String(maxDate)
                            : null,
                          ...(c.e_meter
                            ? {}
                            : {
                                commissioning_date: dateObjects.length
                                  ? dateObjects[0].date
                                  : null,
                              }),
                        }));
                      }}
                      plugins={[<DatePanel key="date-panel" header="Daten" />]}
                      style={{ boxShadow: "none" }}
                      sort={true}
                    />
                  </Col>
                  <Col>
                    {hasPermission(session.user, "customer_handling") &&
                    project.construction !== null ? (
                      <EmailModal
                        customer={project.customer_obj}
                        title={
                          project
                            ? "Email zum Bautermin von " +
                              getCustomerName(project.customer_obj)
                            : ""
                        }
                        subject={
                          project
                            ? "Bautermin für Ihre PV-Anlage " +
                              project.street_and_number_project
                            : ""
                        }
                        getEmailMessage={() =>
                          axios
                            .get(API_URL_SENDCONSTRUCTIONDATE, {
                              params: { project: project.id },
                            })
                            .then((res) => {
                              let msg = res.data;
                              if (
                                construction.constructiondates_set.length > 0
                              ) {
                                const formattedDates = getFirstDate(
                                  construction.constructiondates_set,
                                  "DD.MM.YYYY",
                                );
                                msg = msg.replace("__DATE__", formattedDates);
                              } else {
                                msg = msg.replace("__DATE__", "kein Datum");
                              }
                              return { data: msg };
                            })
                        }
                        submit={(subject, message) =>
                          axios.post(API_URL_SENDCONSTRUCTIONDATE, {
                            project: project.id,
                            mail: { subject, message },
                            date: getFirstDate(
                              construction.constructiondates_set,
                              "YYYY-MM-DD",
                            ),
                          })
                        }
                        getModalOpenButton={(toggle) => (
                          <CustomButton onClick={toggle} icon="mail">
                            Setztermin erneut senden
                          </CustomButton>
                        )}
                        session={session}
                      />
                    ) : null}
                  </Col>
                </Row>
              </FormGroup>
              {/* ############################################################## */}
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Datum der Fertigstellung
                </Typography>
                <DatePicker
                  ref={pickerRef2}
                  locale={gregorianDe}
                  weekStartDayIndex={1}
                  format="DD/MM/YYYY"
                  showOtherDays
                  value={
                    construction.completion_date
                      ? string2Date(construction.completion_date)
                      : null
                  }
                  onChange={(date) =>
                    setConstruction((c) => ({
                      ...c,
                      completion_date: date ? date2String(date.toDate()) : null,
                      ...(c.e_meter
                        ? {}
                        : {
                            commissioning_date: date
                              ? date2String(date.toDate())
                              : null,
                          }),
                    }))
                  }
                />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Datum der dauerhaften Inbetriebsetzung
                </Typography>
                <DatePicker
                  ref={pickerRef2}
                  locale={gregorianDe}
                  weekStartDayIndex={1}
                  disabled={!construction.e_meter}
                  format="DD/MM/YYYY"
                  value={
                    construction.commissioning_date
                      ? string2Date(construction.commissioning_date)
                      : null
                  }
                  onChange={(date) =>
                    setConstruction((c) => ({
                      ...c,
                      commissioning_date: date
                        ? date2String(date.toDate())
                        : null,
                    }))
                  }
                />
              </FormGroup>

              {/* ############################################################## */}

              <hr className="secondary-textcolor" />
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Growatt-Zugangsdaten
              </Typography>
              <br />
              {growattAccount ? (
                <FormGroup width={"30px"}>
                  <Typography className="secondary-textcolor">
                    Benutzer: <strong>{growattAccount.username}</strong>
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Passwort: <strong>{growattAccount.password}</strong>
                  </Typography>
                </FormGroup>
              ) : (
                <>
                  <GrowattAccountModal
                    project={project}
                    onAccountCreation={setGrowattAccount}
                    session={session}
                  />
                  <br />
                </>
              )}

              {/* ############################################################## */}

              <hr className="secondary-textcolor" />
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Geplante PV-Panels
              </Typography>
              <br />
              <FormGroup width={"30px"}>
                <Typography className="secondary-textcolor">
                  Anzahl vertikaler Panels:{" "}
                  <strong>{construction.n_vertical_panels}</strong>
                </Typography>
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup width={"30px"}>
                <Typography className="secondary-textcolor">
                  Anzahl horizontaler Panels:{" "}
                  <strong>{construction.n_horizontal_panels}</strong>
                </Typography>
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup width={"30px"}>
                <Typography className="secondary-textcolor">
                  kWp der PV-Anlage:{" "}
                  <strong>{round(construction.kwp, 3)}</strong>{" "}
                </Typography>
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup width={"30px"}>
                <Typography className="secondary-textcolor">
                  Maximalbelegung gewünscht:{" "}
                  <strong>
                    {acceptedPlanning.maximum_occupancy ? "ja" : "nein"}
                  </strong>{" "}
                </Typography>
              </FormGroup>

              {/* ############################################################## */}

              <hr className="secondary-textcolor" />
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Dachfotos
              </Typography>
              <br />

              {/* ############################################################## */}
              {module ? (
                <FormGroup width={"30px"}>
                  <div>
                    {construction.roofimage_set.map((roofImage) => {
                      const kwp = round(
                        roofImage.roofimagepanel_set.length * module.kwp,
                        3,
                      );
                      return (
                        <div key={`roof-image-${roofImage.id}`}>
                          {roofImage.name && (
                            <Typography className="secondary-textcolor">
                              <strong>{roofImage.name} </strong>
                            </Typography>
                          )}
                          <Typography className="secondary-textcolor">
                            Himmelsrichtung:{" "}
                            <strong>{roofImage.direction} </strong>
                          </Typography>
                          <Typography className="secondary-textcolor">
                            Dachtyp:{" "}
                            <strong>
                              {roofImage.flat_roof ? "Flachdach" : "Satteldach"}{" "}
                            </strong>
                          </Typography>
                          <Typography className="secondary-textcolor">
                            kWp:{" "}
                            <strong>
                              {module ? kwp : "Modul noch nicht ausgewählt"}
                            </strong>
                          </Typography>
                          <br />
                          <CustomButton
                            color="#ff0000"
                            onClick={() => deleteRoofImage(roofImage.id)}
                          >
                            Bild löschen
                          </CustomButton>
                          <br />
                          <br />
                          <br />
                          <Container>
                            <Row>
                              <Col style={{ margin: "5px" }}>
                                <RoofImage
                                  roofImage={roofImage}
                                  module={module}
                                  height={"300"}
                                />
                              </Col>
                              <Col style={{ margin: "5px" }}>
                                <ImageStage
                                  image={roofImage.house_image}
                                  height={"300"}
                                />
                              </Col>
                            </Row>
                          </Container>
                          <Container>
                            <Row>
                              {construction.additionalexteriorhouseimage_set
                                .filter(
                                  (img) => img.roof_image === roofImage.id,
                                )
                                .map((exteriorImg) => {
                                  return (
                                    <Col
                                      style={{ margin: "5px" }}
                                      key={exteriorImg.id}
                                    >
                                      <ImageStage
                                        image={exteriorImg.image}
                                        height={"300"}
                                      />
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Container>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            <EditRoofImagePlanning
                              image={roofImage.image}
                              imageWidthM={roofImage.image_width_m}
                              module={module}
                              panels={roofImage.roofimagepanel_set}
                              angle={roofImage.angle}
                              direction={roofImage.direction}
                              flatRoof={roofImage.flat_roof}
                              strings={roofImage.string_set}
                              setAll={(
                                panels,
                                currentStrings,
                                currentAngle,
                                currentDirection,
                                currentFlatRoof,
                              ) =>
                                updateRoofImage(
                                  roofImage.id,
                                  panels,
                                  currentStrings,
                                  currentAngle,
                                  currentDirection,
                                  currentFlatRoof,
                                )
                              }
                              winWidth={window.innerWidth}
                              winHeight={window.innerHeight}
                              roofName={roofImage.name}
                            />

                            <br />
                            <AdditionalExteriorHouseImageModal
                              construction={construction}
                              setConstruction={setConstruction}
                              roofImage={roofImage}
                              session={session}
                            />
                          </div>
                          <hr className="secondary-textcolor" />
                        </div>
                      );
                    })}
                  </div>
                  <RoofImageUpload
                    addRoofImage={addRoofImage}
                    module={module}
                    project={project}
                    winWidth={window.innerWidth}
                    winHeight={window.innerHeight}
                    session={session}
                  />
                </FormGroup>
              ) : null}
              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  PV-Modul:
                </Typography>
                <br />
                <span style={{ display: "flex" }}>
                  <Input
                    style={{ width: "50px", boxShadow: "none" }}
                    id="input"
                    type="text"
                    name="pv_modules"
                    value={
                      construction.n_vertical_panels +
                      construction.n_horizontal_panels
                    }
                    disabled={true}
                  />
                  &nbsp; &nbsp;
                  <DropDown
                    text="PV-Modul wählen"
                    onChange={(module) =>
                      setConstruction((c) => ({ ...c, module }))
                    }
                    value={construction.module}
                    options={modules.map((module) => ({
                      label: module.available
                        ? module.name
                        : `${module.name} (nicht verfügbar)`,
                      value: module.id,
                    }))}
                  />
                </span>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  {" "}
                  Anzahl Wechselrichter:
                </Typography>
                <br />
                <span style={{ display: "flex" }}>
                  <DropDown
                    text="Anzahl Wechselrichter"
                    onChange={(nInverters) =>
                      setConstruction((c) => ({
                        ...c,
                        n_inverters: nInverters,
                      }))
                    }
                    value={construction.n_inverters}
                    options={[1, 2, 3]}
                  />
                </span>
              </FormGroup>

              {/* ###########################TODO################################### */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Wechselrichter zuordnen:
                </Typography>
                <br />
                {construction.n_inverters ? (
                  <div>
                    {construction.constructioninverter_set.map(
                      (constructionInverter, idx) => {
                        const strings = getStringFromRoofImages(
                          construction.roofimage_set,
                        );
                        const inverterStrings = strings.filter(
                          (s) =>
                            s.construction_inverter === constructionInverter.id,
                        );
                        return (
                          <div
                            key={`inverter_dropdowns_${constructionInverter.id}`}
                            style={{
                              display: "flex",
                              margin: "2vh",
                              gap: "2vh",
                            }}
                          >
                            <DropDown
                              text={idx + 1 + ". Wechselrichter wählen"}
                              key={`inverter_dropdown_${constructionInverter.id}`}
                              onChange={(inverter) =>
                                updateConstructionInverter(
                                  constructionInverter.id,
                                  inverter,
                                )
                              }
                              value={constructionInverter.inverter}
                              options={inverters.map((inverter) => ({
                                label: inverter.name,
                                value: inverter.id,
                              }))}
                              search={true}
                            />
                            {constructionInverter.inverter ? (
                              <MultiSelect
                                text={"Strings wählen"}
                                style={{ margin: "0.5vh" }}
                                onChange={(stringIds) =>
                                  updateInverterStrings(
                                    constructionInverter.id,
                                    stringIds,
                                  )
                                }
                                values={inverterStrings.map((s) => s.id)}
                                options={strings
                                  .filter(
                                    (s) =>
                                      s.inverter === constructionInverter.id ||
                                      s.inverter == null,
                                  )
                                  .map((string) => ({
                                    label: string.name,
                                    value: string.id,
                                    color: hexToRGB(string.color, 0.5),
                                  }))}
                              />
                            ) : null}
                            <br />
                            <br />
                          </div>
                        );
                      },
                    )}
                  </div>
                ) : null}
              </FormGroup>

              {["admins"].includes(session.user.group_key) ? (
                <InverterList></InverterList>
              ) : null}

              <hr className="secondary-textcolor" />

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Batterie:
                </Typography>
                <br />
                <span style={{ display: "flex" }}>
                  &nbsp; &nbsp;
                  {construction.battery_kwh ? (
                    <DropDown
                      text="Batterie wählen"
                      onChange={(battery) =>
                        setConstruction((c) => ({ ...c, battery }))
                      }
                      value={construction.battery}
                      options={batteries.map((battery) => ({
                        label: battery.name,
                        value: battery.id,
                      }))}
                    />
                  ) : null}
                </span>
                {construction.batterykwh_set.map((battery, index) => (
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    key={`battery_row_${index}`}
                    sx={{ marginTop: "0px" }}
                  >
                    <Grid item>
                      <Typography
                        key={`battery_kwh_${index}`}
                        className="secondary-textcolor"
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <DropDown
                        id="plantform-battery-dropdown"
                        key={`battery_kwh_${index}_${battery.kwh}`}
                        onChange={(value) => updateBattery(index, value)}
                        text="Batterie wählen"
                        options={batteryOptions}
                        value={battery.kwh}
                      />
                    </Grid>
                    {index !== 0 && (
                      <Grid item>
                        <CustomIconButton
                          icon="delete"
                          iconClassName="IconDeleteButton"
                          color="transparant"
                          onClick={() => deleteBattery(index)}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={addBattery}
                  sx={{ marginTop: "10px" }}
                >
                  Weitere Batterie
                </Button>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}
              <Typography className="secondary-textcolor">
                Montageort Wechselrichter:
              </Typography>
              <br />
              {acceptedPlanning.inverterlocationimage_set.map((image, iIdx) => (
                <>
                  <ImageStage
                    key={`inverterlocationimage-${iIdx}`}
                    image={image.image}
                  />
                  <br />
                </>
              ))}
              <Typography className="secondary-textcolor">
                {acceptedPlanning.inverterlocationimage_set.length === 0 ? (
                  <>&#x2716;</>
                ) : null}
              </Typography>
              <br />
              <Typography className="secondary-textcolor">
                Standort Batterie:
              </Typography>
              <br />
              {acceptedPlanning.batterylocationimage_set.map((image, iIdx) => (
                <>
                  <ImageStage
                    key={`batterylocationimage-${iIdx}`}
                    image={image.image}
                  />
                  <br />
                </>
              ))}
              <Typography className="secondary-textcolor">
                {acceptedPlanning.batterylocationimage_set.length === 0 ? (
                  <>&#x2716;</>
                ) : null}
              </Typography>
              <br />
              <Typography className="secondary-textcolor">
                Montageort neuer Zählerschrank:
              </Typography>
              <br />
              {acceptedPlanning.emetercabinetlocationimage_set.map(
                (image, iIdx) => (
                  <>
                    <ImageStage
                      key={`emetercabinetlocationimage-${iIdx}`}
                      image={image.image}
                    />
                    <br />
                  </>
                ),
              )}
              <Typography className="secondary-textcolor">
                {acceptedPlanning.emetercabinetlocationimage_set.length ===
                0 ? (
                  <>&#x2716;</>
                ) : null}
              </Typography>
              <hr className="secondary-textcolor" />

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">Zähler:</Typography>
                <FormGroup>
                  {emeters.map((eMeter, eidx) => (
                    <div key={eidx} style={{ padding: "0 0 0 25px" }}>
                      <EMeterFormEntry
                        key={eidx}
                        eMeter={eMeter}
                        idx={eidx}
                        changeEMeter={changeEMeter}
                        showMissingFields={showMissingFields}
                      />
                      <div style={{ padding: "0 0 0 25px" }}>
                        <CustomButton
                          onClick={() => removeEMeter(eMeter.id)}
                          icon={"delete"}
                        >
                          ZÄHLER LÖSCHEN
                        </CustomButton>
                      </div>
                    </div>
                  ))}
                  <div style={{ padding: "0 0 0 25px" }}>
                    <hr className="secondary-textcolor" />
                  </div>
                  <div>
                    <IconButton
                      size="medium"
                      disableFocusRipple
                      disableRipple
                      style={{ backgroundColor: "transparent" }}
                      onClick={addEMeter}
                    >
                      <Add style={{ color: "#424242" }} fontSize="large" />
                      <Typography className="secondary-textcolor">
                        Zähler hinzufügen
                      </Typography>
                    </IconButton>{" "}
                    <br />
                  </div>
                </FormGroup>
                <br />
                <Typography className="secondary-textcolor">
                  1.8.0 Inklusive Zählernummer
                </Typography>
                {acceptedPlanning.onepointeight_emeterimage_set.map(
                  (image, iIdx) => (
                    <>
                      <ImageStage
                        key={`onepointeight_emeterimage-${iIdx}`}
                        image={image.image}
                      />
                      <br />
                    </>
                  ),
                )}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.onepointeight_emeterimage_set.length ===
                  0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                <Typography className="secondary-textcolor">
                  2.8.0 Inklusive Zählernummer
                </Typography>
                {acceptedPlanning.twopointeight_emeterimage_set.map(
                  (image, iIdx) => (
                    <>
                      <ImageStage
                        key={`twopointeight_emeterimage-${iIdx}`}
                        image={image.image}
                      />
                      <br />
                    </>
                  ),
                )}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.twopointeight_emeterimage_set.length ===
                  0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                <Typography className="secondary-textcolor">
                  SLS Schalter
                </Typography>
                {acceptedPlanning.slsswitch_emeterimage_set.map(
                  (image, iIdx) => (
                    <>
                      <ImageStage
                        key={`slsswitch_emeterimage-${iIdx}`}
                        image={image.image}
                      />
                      <br />
                    </>
                  ),
                )}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.slsswitch_emeterimage_set.length === 0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                <Typography className="secondary-textcolor">
                  Zähler allgemein
                </Typography>
                {acceptedPlanning.additional_emeterimage_set.map(
                  (image, iIdx) => (
                    <>
                      <ImageStage
                        key={`additional_emeterimage-${iIdx}`}
                        image={image.image}
                      />
                      <br />
                    </>
                  ),
                )}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.additional_emeterimage_set.length === 0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={construction.electronic_domestic_meter}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("electronic_domestic_meter", e, value)
                  }
                >
                  <CustomToggle value={false}>Dreipunkt</CustomToggle>
                  <CustomToggle value={true}>eHZ</CustomToggle>
                  {showMissingFields &&
                  construction.electronic_domestic_meter == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Zählerschrank:
                </Typography>
                <br />
                {acceptedPlanning.emetercabinetimage_set.map((image, iIdx) => (
                  <>
                    <ImageStage
                      key={`emetercabinetimage-${iIdx}`}
                      image={image.image}
                    />
                    <br />
                  </>
                ))}
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Hausanschlusskasten:
                </Typography>
                <br />
                {acceptedPlanning.houseconnectionimage_set.map(
                  (image, iIdx) => (
                    <>
                      <ImageStage
                        key={`houseconnectionimage-${iIdx}`}
                        image={image.image}
                      />
                      <br />
                    </>
                  ),
                )}
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Dachziegel:
                </Typography>
                <br />
                {acceptedPlanning.rooftileimage_set.map((image, iIdx) => (
                  <>
                    <ImageStage
                      key={`rooftileimage-${iIdx}`}
                      image={image.image}
                    />
                    <br />
                  </>
                ))}
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">Gerüst</Typography>
                <br />
                <Typography className="secondary-textcolor">
                  Gerüst Fotos, Hausansicht
                </Typography>
                {acceptedPlanning.scaffoldingimage_set.map((image, iIdx) => (
                  <>
                    <ImageStage
                      key={`scaffoldingimage-${iIdx}`}
                      image={image.image}
                    />
                  </>
                ))}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.scaffoldingimage_set.length === 0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                <Typography className="secondary-textcolor">
                  Fotos von Straße und Haus
                </Typography>
                {acceptedPlanning.streetimage_set.map((image, iIdx) => (
                  <>
                    <ImageStage
                      key={`streetimage-${iIdx}`}
                      image={image.image}
                    />
                  </>
                ))}
                <Typography className="secondary-textcolor">
                  {acceptedPlanning.streetimage_set.length === 0 ? (
                    <>&#x2716;</>
                  ) : null}
                </Typography>
                <br />
                {acceptedPlanning.scaffolding_installation !== null ? (
                  <>
                    <Typography className="secondary-textcolor">
                      Hiermit bestätige ich: {project.salesman_name}, dass bei
                      dieser Baustelle ein einfaches Gerüst aufgebaut werden
                      kann. -{" "}
                      {acceptedPlanning.scaffolding_installation
                        ? "Ja"
                        : "Nein"}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {project.accepted_offer_obj.scaffolding_easy_installation !==
                null ? (
                  <>
                    <Typography className="secondary-textcolor">
                      Hiermit bestätige ich: {project.salesman_name}, dass das
                      Gerüst einfach aufgebaut wird. -{" "}
                      {project.accepted_offer_obj.scaffolding_easy_installation
                        ? "Ja"
                        : "Nein"}
                    </Typography>
                    <br />
                  </>
                ) : null}
                <Typography className="secondary-textcolor">
                  Ich{" "}
                  {construction.construction_planner_name === ""
                    ? `${session.user.first_name} ${session.user.last_name}`
                    : construction.construction_planner_name}{" "}
                  habe das Gerüst geprüft und eventuell beauftragt.
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={construction.scaffolding_check}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("scaffolding_check", e, value)
                  }
                >
                  <CustomToggle value={false}>Nein</CustomToggle>
                  <CustomToggle value={true}>Ja</CustomToggle>
                  {showMissingFields &&
                  construction.scaffolding_check === false ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Muss ein zwei Richtungszähler gesetzt werden?
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={construction.e_meter}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null)
                      setConstruction((c) => ({
                        ...c,
                        e_meter: value,
                        commissioning_date: value ? null : c.completion_date,
                      }));
                  }}
                >
                  <CustomToggle value={false}>Nein</CustomToggle>
                  <CustomToggle value={true}>Ja</CustomToggle>
                  {showMissingFields && construction.e_meter == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Kaskadenschaltung
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={construction.cascade}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("cascade", e, value)
                  }
                >
                  <CustomToggle value={false}>Nein</CustomToggle>
                  <CustomToggle value={true}>Ja</CustomToggle>
                  {showMissingFields && construction.cascade == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Volleinspeisung
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={construction.full_supply}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("full_supply", e, value)
                  }
                >
                  <CustomToggle value={false}>Nein</CustomToggle>
                  <CustomToggle value={true}>Ja</CustomToggle>
                  {showMissingFields && construction.full_supply == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>

              {/* ############################################################## */}
              {customProducts.some(
                (cp) => cp.productObj.key === "keine_pfannen",
              ) ? (
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Wurden die Dachpfannen besorgt?
                  </Typography>
                  <br />
                  <ToggleButtonGroup
                    size="small"
                    value={construction.roof_tiles}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange("roof_tiles", e, value)
                    }
                  >
                    <CustomToggle value={false}>Nein</CustomToggle>
                    <CustomToggle value={true}>Ja</CustomToggle>
                  </ToggleButtonGroup>
                </FormGroup>
              ) : null}
              {/* ############################################################## */}

              {circuitDiagrams !== null ? (
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Schaltbild:
                  </Typography>
                  <DropDown
                    onChange={(x) =>
                      setConstruction((c) => ({ ...c, circuit_diagram: x }))
                    }
                    options={circuitDiagrams
                      .filter(
                        (d) =>
                          (!acceptedPlanning.tenant_system &&
                            d.key !== "10qmm_mieterstrom") ||
                          (acceptedPlanning.tenant_system &&
                            d.key === "10qmm_mieterstrom"),
                      )
                      .filter((d) =>
                        customProducts.filter(
                          (cp) =>
                            cp.productObj.key ===
                            "aufruestung_zaehlerschrank_16_50",
                        ).length > 0
                          ? d.key === "16qmm"
                          : true,
                      )
                      .map((d) => ({ label: d.name, value: d.id }))}
                    value={construction.circuit_diagram}
                    text="Schaltbild wählen"
                  />
                  {showMissingFields && !construction.circuit_diagram ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                  <hr className="secondary-textcolor" />
                </FormGroup>
              ) : null}

              {/* ############################################################## */}
              {operators !== null ? (
                <FormGroup>
                  <Stack direction="row" spacing={2}>
                    <Typography className="secondary-textcolor">
                      Netzbetreiber:
                    </Typography>
                    <OperatorModal
                      resetParent={getOperators}
                      project={project}
                      session={session}
                    />
                  </Stack>
                  <DropDown
                    onChange={(x) =>
                      setConstruction((c) => ({ ...c, operator: x }))
                    }
                    options={operators.map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                    value={construction.operator}
                    text="Netzbetreiber wählen"
                    search={true}
                    sort={true}
                  />
                  {showMissingFields && !construction.operator ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                  <hr className="secondary-textcolor" />
                </FormGroup>
              ) : null}

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Notizen: (optional)
                </Typography>
                <Input
                  style={{
                    width: isMobileOnly ? "100%" : "50%",
                    boxShadow: "none",
                    minHeight: isMobileOnly ? 200 : 100,
                    resize: "none",
                  }}
                  id="input"
                  // width={"70px"}
                  type="textarea"
                  name="notes"
                  onChange={(e) =>
                    setConstruction((c) => ({ ...c, notes: e.target.value }))
                  }
                  value={defaultIfEmpty(construction.notes)}
                  autoComplete="off"
                  maxLength={1500}
                />
                <hr className="secondary-textcolor" />
              </FormGroup>

              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Informationen aus der Planung
              </Typography>
              <br />
              {acceptedPlanning.tenant_system ? (
                <>
                  <Typography className="secondary-textcolor">
                    Mieterstromanlage: Ja
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Mieterstrom-Modell:{" "}
                    {
                      tenantModelOptions.find(
                        (item) => item.value === acceptedPlanning.tenant_model,
                      ).label
                    }
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Anzahl Mieter: {acceptedPlanning.n_tenants}
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Anzahl zusätzlicher Zähler:{" "}
                    {acceptedPlanning.n_additional_emeters}
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Befestigung:{" "}
                    {acceptedPlanning.tenant_attachment
                      ? acceptedPlanning.tenant_attachment
                      : ""}
                  </Typography>
                </>
              ) : null}
              <br />
              <div style={{ display: "flex" }}>
                {acceptedPlanning.notes ? (
                  <Typography
                    style={{ color: "red", fontWeight: 800, fontSize: 30 }}
                  >
                    !
                  </Typography>
                ) : null}
                <Typography
                  className="secondary-textcolor"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Notizen: {acceptedPlanning.notes}
                </Typography>
              </div>
              <Typography className="secondary-textcolor">
                Wärmepumpe:{" "}
                {acceptedPlanning.heatpump === true ? (
                  <>&#10004;</>
                ) : (
                  <>&#x2716;</>
                )}
              </Typography>
              <Typography className="secondary-textcolor">
                Wallbox vorhanden:{" "}
                {acceptedPlanning.wallbox === true ? (
                  <>&#10004;</>
                ) : (
                  <>&#x2716;</>
                )}
              </Typography>
              <Typography className="secondary-textcolor">
                Anzahl Wallboxen: {acceptedPlanning.wallbox_required}
              </Typography>
              {acceptedPlanning.floors ? (
                <Typography className="secondary-textcolor">
                  Anzahl Etagen: {acceptedPlanning.floors}
                </Typography>
              ) : null}
              <hr className="secondary-textcolor" />

              {/* ############################################################## */}
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Komission
              </Typography>
              <Table>
                <thead>
                  <tr>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Zulieferer
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Besteller
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Lieferdatum
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Status
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Aktion
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {warehouseDeliverySuppliers.map((row) => (
                    <tr key={row.id}>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {suppliers.find((e) => e.id === row.supplier)?.name}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.employee_name}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.delivery_date}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.status}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography
                          className="secondary-textcolor"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <WarehouseDeliveryAmountModal
                            resetParent={getWarehouseProducts}
                            resetState={getWarehouseDeliverySuppliers}
                            isInfo={true}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts.map(
                              getDefaultSupplierInfo,
                            )}
                            suppliers={suppliers}
                            session={session}
                          />
                          <WarehouseDeliveryAmountModal
                            resetParent={getWarehouseProducts}
                            resetState={getWarehouseDeliverySuppliers}
                            isInfo={false}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts.map(
                              getDefaultSupplierInfo,
                            )}
                            session={session}
                          />
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* ############################################################## */}
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Produkte
              </Typography>
              {hasPermission(session.user, "customer_handling") &&
              !!project.construction ? (
                <>
                  <CustomButton
                    onClick={onCostDifferenceBtnClicked}
                    icon="mail"
                  >
                    Kostendifferenz
                  </CustomButton>

                  {costDifferenceModal.type === "email" ? (
                    <>
                      <EmailModal
                        customer={project.customer_obj}
                        title={
                          project
                            ? "Email zum Kostendifferenz von " +
                              getCustomerName(project.customer_obj)
                            : ""
                        }
                        subject={
                          project
                            ? "Kostendifferenz für Ihre PV-Anlage " +
                              project.street_and_number_project
                            : ""
                        }
                        getEmailMessage={() =>
                          axios
                            .get(API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE, {
                              params: { project: project.id },
                            })
                            .then((res) => {
                              const msg = res.data;
                              return { data: msg };
                            })
                        }
                        submit={(subject, message) =>
                          axios.post(API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE, {
                            project: project.id,
                            mail: { subject, message },
                          })
                        }
                        getModalOpenButton={() => null}
                        isOpen={costDifferenceModal.isOpen}
                        setIsOpen={(open) =>
                          setCostDifferenceModal({
                            ...costDifferenceModal,
                            isOpen: open,
                          })
                        }
                        attachments={costDifferenceModal.attachments}
                        session={session}
                      />
                      <br />
                    </>
                  ) : (
                    <CustomModal
                      size="md"
                      isOpen={costDifferenceModal.isOpen}
                      setIsOpen={(open) =>
                        setCostDifferenceModal({
                          ...costDifferenceModal,
                          isOpen: open,
                        })
                      }
                      title="Kostendifferenz"
                    >
                      <Typography fontSize="h6.fontSize">
                        Derzeit gibt es keinen Kostenunterschied
                      </Typography>
                    </CustomModal>
                  )}
                </>
              ) : (
                <></>
              )}

              <CustomProductItems
                project={project}
                showDescriptions={false}
                showPrices={false}
                construction={construction}
                customProducts={sortByOrder(customProducts)}
                setCustomProducts={setCustomProducts}
                session={session}
                customer={project.customer_obj}
                products={products}
                productGroups={productGroups}
                resetProducts={resetProducts}
                amountCols={amountCols}
                fixedProductKeys={fixedProductKeys}
                stage={"plant_construction"}
              />

              {/* ############################################################## */}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

PlantConstructionForm.propTypes = {
  project: projectPropType,
  construction: constructionPropType,
  setConstruction: PropTypes.func,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.arrayOf(productPropType),
  resetProducts: PropTypes.func,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  currCustomProducts: PropTypes.arrayOf(customProductPropType),
  showMissingFields: PropTypes.bool,
  modules: PropTypes.arrayOf(modulePropType),
  inverters: PropTypes.arrayOf(inverterPropType),
  batteries: PropTypes.arrayOf(batteryPropType),
  circuitDiagrams: PropTypes.arrayOf(circuitDiagramPropType),
  amountCols: PropTypes.object,
  session: PropTypes.object,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  emeters: PropTypes.arrayOf(eMeterPropType),
  setEmeters: PropTypes.func,
  employeeSchedule: PropTypes.object,
  setConstructionManager: PropTypes.func,
};
