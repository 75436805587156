import React, { Fragment, useState } from "react";
import axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

import {
  MuiThemeProvider,
  createTheme,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

import { API_URL_PASSWORDRESET } from "../settings";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  input: {
    color: "#424242",
  },
  inputfocused: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#424242",
    },
  },
}));

function ResetPassword(session) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setError] = useState(null);

  const handleFormFieldChange = (event) => {
    setSuccess(false);
    setError(null);
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        break;
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const headers = { Authorization: `Token ${session.token}` };
    const method = "post";
    const url = API_URL_PASSWORDRESET;
    const formData = new FormData();
    formData.append("mail", email);
    const config = { headers, method, url, data: formData };
    // Axios update_password API call
    axios(config)
      .then((res) => {
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        console.error(error);
        setError("Bitte korrekte E-Mail angeben!");
      });
  };

  return (
    <Fragment>
      <br />
      <Container component="main" maxWidth="xs">
        {/* <div className={classes.paper}> */}
        <Typography
          className="secondary-textcolor"
          component="h1"
          fontSize="h5.fontSize"
        >
          Neue Zugangsdaten anfordern
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            className={classes.inputfocused}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputProps={{ className: classes.input }}
            InputLabelProps={{ style: { color: "#424242" } }}
            name="email"
            label="Email"
            type="text"
            id="email"
            onChange={handleFormFieldChange}
          />
          <div className="d-grid gap-2">
            {success ? (
              <Typography color="success" component="h3" fontSize="h6.fontSize">
                E-Mail mit neuen Zugangsdaten wurde versendet!
              </Typography>
            ) : null}
            {errorMsg ? (
              <Typography color="error" component="h3" fontSize="h6.fontSize">
                {errorMsg}
              </Typography>
            ) : null}
            <Button
              type="submit"
              variant="outlined"
              style={{ color: "#424242", borderColor: "#424242" }}
              className={classes.submit}
            >
              Zugangsdaten anfordern
            </Button>
            <br />
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Button
                type="submit"
                variant="outlined"
                style={{ color: "#424242", borderColor: "#424242" }}
              >
                Zurück zur Startseite
              </Button>
            </Link>
          </div>
        </form>
        {/* </div> */}
      </Container>
    </Fragment>
  );
}

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

export default function ResetPasswordPage() {
  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ResetPassword />
      </MuiThemeProvider>
    </Fragment>
  );
}
