import UploadImages from "../../../shared/images/UploadImages";
import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import { CustomButton } from "../../../../elements/StyledElements";
import { API_URL_ADDITIONALEXTERIORHOUSEIMAGE } from "../../../../settings";
import axios from "axios";

export default function AdditionalExteriorHouseImageModal({
  construction,
  setConstruction,
  roofImage,
  session,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const getOpenButton = () => (
    <CustomButton
      onClick={() => {
        setIsOpen(true);
      }}
    >
      Weitere Außenansicht hinzufügen
    </CustomButton>
  );

  const resetImages = async () => {
    const imgs = await axios.get(API_URL_ADDITIONALEXTERIORHOUSEIMAGE);
    setConstruction({
      ...construction,
      additionalexteriorhouseimage_set: imgs.data,
    });
  };

  const addImage = async (key, imageDict) => {
    if (roofImage.id > 0 && construction.id) {
      const formData = new FormData();
      formData.append("roof_image", roofImage.id);
      formData.append("construction", construction.id);
      formData.append("image", imageDict.image);
      await axios.post(API_URL_ADDITIONALEXTERIORHOUSEIMAGE, formData);

      await resetImages();
      return;
    }
    setConstruction((c) => {
      const images = [...c[key]];
      images.push({
        ...imageDict,
        roof_image: roofImage.id,
        construction: construction.id,
      });
      return { ...c, [key]: images };
    });
  };

  const deleteImage = async (key, imageDict) => {
    if (imageDict.id > 0) {
      await axios.delete(API_URL_ADDITIONALEXTERIORHOUSEIMAGE + imageDict.id);
      await resetImages();
      return;
    }
    setConstruction((c) => ({
      ...c,
      [key]: c[key].filter((i) => i.id !== imageDict.id),
    }));
  };

  return (
    <Fragment>
      <CustomModal
        size="fullscreen"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Zusätzliches Außenbild des Hauses hochladen"
        getOpenButton={getOpenButton}
        getFooter={(toggle) => (
          <SaveModalFooter
            onSave={() => {
              toggle();
              setIsOpen(false);
            }}
            saveBtnLabel="Speichern"
          />
        )}
      >
        <UploadImages
          images={construction.additionalexteriorhouseimage_set.filter(
            (img) => img.roof_image === roofImage.id,
          )}
          addImage={(img) => addImage("additionalexteriorhouseimage_set", img)}
          deleteImage={(img) => {
            deleteImage("additionalexteriorhouseimage_set", img);
          }}
        />
      </CustomModal>
    </Fragment>
  );
}

AdditionalExteriorHouseImageModal.propTypes = {
  construction: PropTypes.object,
  setConstruction: PropTypes.func,
  roofImage: PropTypes.object,
  session: PropTypes.object,
};
