import React, { useRef, useState } from "react";
import { PropTypes } from "prop-types";

import RoofProjectRoofScale from "./RoofProjectRoofScale";
import TransformImage from "../../../shared/images/TransformImage";
import CropImage from "../../../shared/images/CropImage";
import { CustomButton } from "../../../../elements/StyledElements";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import UploadRoofImage from "../../../shared/images/UploadRoofImage";
import { round, urlToFile } from "../../../../elements/utils";
import { projectPropType } from "../../../../elements/PropTypes";
import RoofProjectRoofImagePlanning from "./RoofProjectRoofImagePlanning";
import html2canvas from "html2canvas";
import { Spinner } from "reactstrap";

export default function RoofProjectRoofImageUpload({
  project,
  addRoofImage,
  winWidth,
  winHeight,
  session,
}) {
  const [currentLines, setCurrentLines] = useState([]);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [currentFlatRoof, setCurrentFlatRoof] = useState(false);
  const [currentRoofImage, setCurrentRoofImage] = useState(null);
  const [roofWidthPx, setRoofWidthPx] = useState(null);
  const [roofWidthM, setRoofWidthM] = useState(null);

  const [page, setPage] = useState(0);
  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  const [originalImage, setOriginalImage] = useState(null);
  const [transformedImage, setTransformedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const blendedStageRef = useRef(null);
  const legendRef = useRef(null);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setPage(0);
    setCurrentLines([]);
    setCurrentAngle(0);
    setCurrentFlatRoof(false);
    setCurrentRoofImage(null);
    setOriginalImage(null);
    setTransformedImage(null);
    setCroppedImage(null);
  };

  const getBlendedImage = async () => {
    const uri = blendedStageRef.current.toDataURL();
    return await urlToFile(uri, "roof-line.png", "image/png");
  };

  const getLegendImage = async () => {
    const canvas = await html2canvas(legendRef.current, {
      scale: 1,
      onclone: (document, element) => {
        element.style.setProperty("zoom", 100 + "%");
      },
    });
    const uri = canvas.toDataURL("image/png", 1.0);

    return await urlToFile(uri, "roof-line-legend.png", "image/png");
  };

  const upload = async () => {
    const blendedImage = await getBlendedImage();
    const legendImage = await getLegendImage();

    await addRoofImage(
      currentRoofImage,
      roofWidthPx,
      roofWidthM,
      currentAngle,
      currentLines,
      currentFlatRoof,
      blendedImage,
      legendImage,
    );
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton id="upload-new-roof-img-btn" onClick={toggle}>
        Neues Dachfoto hochladen
      </CustomButton>
    );
  };

  const next = async () => {
    if (page === 0) setTransformedImage(originalImage); // 0 (Upload) -> 1 (Transform)
    if (page === 1) setCroppedImage(transformedImage); // 1 (Transform) -> 2 (Crop)
    if (page === 2) setCurrentRoofImage(croppedImage); // 2 (Crop) -> 3 (Scale)
    if (page === 3) {
      setCurrentLines([]);
    }
    nextPage();
  };

  const previous = () => {
    if (page === 1) setTransformedImage(null); // 0 (Upload) <- 1 (Transform)
    if (page === 2) {
      setCroppedImage(null);
      setTransformedImage(originalImage);
    } // 1 (Transform) <- 2 (Crop)
    if (page === 3) {
      setCurrentRoofImage(null);
      setCroppedImage(transformedImage);
    } // 2 (Crop) <- 3 (Scale)
    previousPage();
  };
  const getFooter = (toggle) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loadingElements.inProgress ? (
          <div>
            {" "}
            <Spinner color="light" size="sm" />{" "}
          </div>
        ) : null}
        &nbsp; &nbsp;
        {page !== 0 ? (
          <CustomButton color={"#ffffff"} onClick={previous}>
            Zurück
          </CustomButton>
        ) : null}
        &nbsp; &nbsp;
        {(page === 0 && originalImage && currentFlatRoof != null) ||
        (page === 1 && transformedImage) ||
        (page === 2 && croppedImage) ||
        (page === 3 && roofWidthM) ? (
          <CustomButton
            id="upload-roof-img-next-btn"
            color={"#ffffff"}
            onClick={next}
          >
            Weiter
          </CustomButton>
        ) : null}
        {page === 4 && readyToSubmit ? (
          <CustomButton
            color={"#ffffff"}
            onClick={async () => {
              setLoadingElements({ ...loadingElements, inProgress: true });
              await upload();
              setLoadingElements({ ...loadingElements, inProgress: false });
              toggle();
            }}
          >
            Bild hinzufügen
          </CustomButton>
        ) : null}
        {/* {(page === 4) && readyToSubmit */}
        {/*  ? <SaveModalFooter */}
        {/*    submitError={loadingElements.submitError} */}
        {/*    inProgress={loadingElements.inProgress} */}
        {/*    onComplete={} */}
        {/*    onSave={previous} */}
        {/*    completeBtnLabel={'Bild hinzufügen'} */}
        {/*    saveBtnLabel={'Zurück'} */}
        {/*  /> */}
        {/*  : null */}
        {/* } */}
        &nbsp;
      </div>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      title="Dachfoto hochladen"
      onToggle={onToggle}
    >
      {page === 0 ? (
        <UploadRoofImage
          flatRoof={currentFlatRoof}
          setFlatRoof={setCurrentFlatRoof}
          image={originalImage}
          setImage={setOriginalImage}
          maxWidth={round((95 / 100) * winWidth)}
          maxHeight={round((80 / 100) * winHeight)}
        />
      ) : null}
      {page === 1 ? (
        <TransformImage
          image={originalImage}
          setTransformedImage={setTransformedImage}
          maxWidth={round((95 / 100) * winWidth)}
          maxHeight={round((85 / 100) * winHeight)}
        />
      ) : null}
      {page === 2 ? (
        <CropImage
          image={transformedImage}
          setCroppedImage={setCroppedImage}
          maxImageWidth={round((95 / 100) * winWidth)}
          maxImageHeight={round((85 / 100) * winHeight)}
        />
      ) : null}
      {page === 3 ? (
        <RoofProjectRoofScale
          image={currentRoofImage}
          roofWidthPx={roofWidthPx}
          setRoofWidthPx={setRoofWidthPx}
          roofWidthM={roofWidthM}
          setRoofWidthM={setRoofWidthM}
          project={project}
          maxWidth={round((95 / 100) * winWidth)}
          maxHeight={round((80 / 100) * winHeight)}
          session={session}
        />
      ) : null}
      {page === 4 ? (
        <RoofProjectRoofImagePlanning
          image={currentRoofImage}
          currentImageWidthM={roofWidthM}
          currentImageWidthPx={roofWidthPx}
          maxWidth={round((95 / 100) * winWidth)}
          maxHeight={round((80 / 100) * winHeight)}
          polygons={currentLines}
          setPolygons={setCurrentLines}
          blendedStageRef={blendedStageRef}
          legendRef={legendRef}
          setReadyToSubmit={setReadyToSubmit}
        />
      ) : null}
    </CustomModal>
  );
}

RoofProjectRoofImageUpload.propTypes = {
  project: projectPropType,
  addRoofImage: PropTypes.func,
  winWidth: PropTypes.number,
  winHeight: PropTypes.number,
  session: PropTypes.object,
};
