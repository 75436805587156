import React from "react";
import { Form, FormGroup, Input } from "reactstrap";

import { Typography } from "@mui/material";

import { defaultIfEmpty, userWithLabel } from "../../elements/utils";
import { PropTypes } from "prop-types";

export default function DefaultWorkingHoursForm({
  session,
  setEmployees,
  employees,
}) {
  if (!employees) return null;

  return (
    <Form>
      {employees.map((employee) => (
        <FormGroup key={employee.id}>
          <Typography className="secondary-textcolor">
            {userWithLabel(employee)}{" "}
          </Typography>
          <Input
            id="input"
            type="number"
            name="working_hours"
            onChange={(e) => {
              setEmployees(
                employees.map((s) =>
                  s.id === employee.id
                    ? { ...s, default_working_hours: e.target.value }
                    : s,
                ),
              );
            }}
            value={defaultIfEmpty(employee.default_working_hours)}
            required={true}
            autoComplete="off"
            style={{ boxShadow: "none" }}
            min={0}
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
          <br />
        </FormGroup>
      ))}
    </Form>
  );
}

DefaultWorkingHoursForm.propTypes = {
  session: PropTypes.object,
  setEmployees: PropTypes.func,
  employees: PropTypes.array,
};
