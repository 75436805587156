import React, { useState } from "react";

import { PropTypes } from "prop-types";

import {
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Box } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { CustomIconButton, CustomToggle } from "../../elements/StyledElements";
import {
  checkForFixedProductKey,
  hasPermission,
  round,
  valueForPrice,
  checkForSalesmenToDelete,
  disabledItems,
  disabledColumns,
} from "../../elements/utils";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { Input } from "reactstrap";
import CustomProductLineMapping from "../project_types/roof_project/offers/CustomProductLineMapping";
import ProductImageModal from "./ProductImageModal";

const getColumns = (amountCols, priceCol, stage) => {
  const columns = [
    { name: "Produktname", key: "name" },
    ...(stage.includes("construction")
      ? [{ name: "", key: "productphoto" }]
      : []),
    ...(amountCols.plannedAmountCol
      ? [
          {
            name: "Menge Planung",
            key: "amount_planned",
            style: { textAlign: "right" },
          },
        ]
      : []),
    ...(amountCols.offerAmountCol
      ? [
          {
            name: "Menge Angebot",
            key: "amount_offer",
            style: { textAlign: "right" },
          },
        ]
      : []),
    ...(amountCols.realAmountCol
      ? [
          {
            name: "Menge verbaut",
            key: "amount_real",
            style: { textAlign: "right" },
          },
        ]
      : []),
    ...(amountCols.invoiceAmountCol
      ? [
          {
            name: "Menge berechnet",
            key: "amount_invoices",
            style: { textAlign: "right" },
          },
        ]
      : []),
    { name: "Menge [ändern]", key: "amount", style: { textAlign: "right" } },
    ...(priceCol
      ? [
          {
            name: "Preis [ändern]",
            key: "price",
            style: { textAlign: "right" },
          },
          {
            name: "Gesamtpreis",
            key: "total_price",
            style: { textAlign: "right" },
          },
        ]
      : []),
    ...(stage === "offer" || stage === "invoice"
      ? [{ name: "MwSt", key: "vat" }]
      : []),
    { name: "", key: "action", style: { textAlign: "right" } },
  ];

  return columns;
};

const inputStyle = {
  boxShadow: "none",
  textAlign: "left",
  padding: "0px 0px 0px 0px",
  WebkitAppearance: "none",
  MozAppearance: "textfield",
  border: "none",
  backgroundColor: "#f0f0f0",
};

const InputField = ({
  type,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  style,
}) => (
  <Input
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    style={{ ...inputStyle, ...style }}
    autoComplete="off"
    autoFocus
    onKeyPress={(e) => e.key === "Enter" && onBlur()}
  />
);

export default function DocumentItemList({
  documentItems,
  changeDocumentItem,
  deleteDocumentItem,
  changeDocumentItemsOrder,
  amountCols,
  priceCol,
  showDescriptions,
  fixedProductKeys,
  isDraggable,
  editableFields,
  project,
  session,
  lineMappings,
  setLineMappings,
  stage = null,
}) {
  const columns = getColumns(amountCols, priceCol, stage);
  const [focused, setFocused] = useState({ item: null, value: "" });
  const [editMode, setEditMode] = useState({ id: null, field: null });
  const isOfferStage =
    project &&
    !project.construction &&
    project.planning_set &&
    project.planning_set.length > 0;
  const isRoofProject = project && project.resourcetype === "RoofProject";
  const freeEdit =
    project &&
    (project.resourcetype === "BlankOfferProject" ||
      project.resourcetype === "BlankInvoiceProject");

  const checkForFixedKey = (key) =>
    checkForFixedProductKey(fixedProductKeys, key);
  const checkDifferentAmounts = (item) => {
    if (!amountCols.offerAmountCol) return false;
    return typeof item.amount === "string"
      ? item.amount_offer.toString() !== item.amount
      : item.amount_offer !== item.amount;
  };
  const handleEditClick = (id, field) => {
    setEditMode({ id, field });
  };

  const hasPermissionForSalesmen = (user, key) =>
    checkForSalesmenToDelete(user, key);
  const handleBlur = (documentItem, key, value) => {
    if (key === "price") {
      const updatedPrice = parseFloat(value.replace(",", ".")).toFixed(2);
      changeDocumentItem({ ...documentItem, price: updatedPrice });
      setFocused({ item: null, value: "" });
    }
    setEditMode({ id: null, field: null });
  };
  const handleChange = (documentItem, key, value) => {
    const updatedItem = { ...documentItem, [key]: value };
    changeDocumentItem(updatedItem);
  };

  const renderCell = (column, documentItem) => {
    const isEditing =
      editMode.id === documentItem.id && editMode.field === column.key;
    const isEditableField = editableFields.includes(column.key);
    const disabled =
      ((checkForFixedKey(documentItem.key) &&
        !hasPermission(session.user, "project_change_fixed_products")) ||
        (disabledItems(documentItem.key) && disabledColumns(column.key))) &&
      !freeEdit;
    const value = isEditing
      ? focused.item === documentItem.id
        ? focused.value
        : documentItem[column.key]
      : documentItem[column.key];
    if (isEditableField) {
      if (isEditing) {
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <InputField
                style={{
                  color: checkDifferentAmounts(documentItem) ? "red" : "black",
                }}
                type="text"
                name={`change_${column.key}`}
                value={value}
                onChange={(e) =>
                  handleChange(documentItem, column.key, e.target.value)
                }
                onBlur={(e) =>
                  handleBlur(documentItem, column.key, e.target.value)
                }
                disabled={disabled}
              />
              {column.key === "price" && (
                <Typography className="secondary-textcolor">€</Typography>
              )}
            </Stack>
          </td>
        );
      } else {
        return (
          <td
            key={`td-${documentItem.id}-${column.key}`}
            onClick={() =>
              !disabled && handleEditClick(documentItem.id, column.key)
            }
          >
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <Typography
                style={{
                  flexGrow: 1,
                  textAlign: column.key === "name" ? "left" : "right",
                  color:
                    column.key === "amount"
                      ? checkDifferentAmounts(documentItem)
                        ? "red"
                        : "black"
                      : null,
                }}
              >
                {column.key === "amount" && documentItem.amount}
                {column.key === "price" && valueForPrice(documentItem.price)}
                {column.key === "name" && documentItem.name}
              </Typography>
              {!disabled && (
                <Tooltip
                  title="Bearbeiten"
                  PopperProps={{ style: { zIndex: 9999 } }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick(documentItem.id, column.key)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </td>
        );
      }
    }

    const totalPrice = parseFloat(
      round(round(documentItem.amount, 3) * round(documentItem.price, 2), 2),
    ).toFixed(2);
    const isDisabled =
      ((checkForFixedKey(documentItem.key) &&
        !hasPermission(session.user, "project_change_fixed_products") &&
        !hasPermissionForSalesmen(session.user, documentItem.key)) ||
        disabledItems(documentItem.key)) &&
      !freeEdit;
    switch (column.key) {
      case "total_price":
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <Typography style={{ textAlign: "right" }}>
              {totalPrice} €
            </Typography>
          </td>
        );
      case "action":
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <div style={{ display: "flex" }}>
              <CustomIconButton
                key={`td-${documentItem.id}-${column.key}`}
                color={isDisabled ? "#c4c4c4" : undefined}
                disabled={isDisabled}
                onClick={() => {
                  deleteDocumentItem(documentItem);
                }}
                icon="clear"
                description="löschen"
              />
              {isOfferStage && isRoofProject && (
                <CustomProductLineMapping
                  project={project}
                  customProduct={documentItem}
                  lineMappings={lineMappings}
                  setLineMappings={setLineMappings}
                />
              )}
            </div>
          </td>
        );
      case "name":
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <Typography>{documentItem[column.key]}</Typography>
          </td>
        );
      case "productphoto":
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <ProductImageModal product={documentItem} />
          </td>
        );
      case "vat":
        if (documentItem.price <= 0)
          return <td key={`td-${documentItem.id}-${column.key}`}></td>;
        return (
          <td key={`td-${documentItem.id}-${column.key}`}>
            <ToggleButtonGroup
              size="small"
              value={documentItem[column.key]}
              exclusive
              onChange={(e, newValue) => {
                handleChange(documentItem, column.key, !!newValue);
              }}
            >
              <CustomToggle value={false}>Nein</CustomToggle>
              <CustomToggle value={true}>Ja</CustomToggle>
            </ToggleButtonGroup>
          </td>
        );
      default:
        return (
          <td
            key={`td-${documentItem.id}-${column.key}`}
            onClick={() => handleEditClick(documentItem.id, column.key)}
          >
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Typography>{documentItem[column.key]}</Typography>
            </Stack>
          </td>
        );
    }
  };

  return (
    <CollapsibleTable
      columns={columns}
      hover={false}
      isDraggable={isDraggable}
      changeOrder={changeDocumentItemsOrder}
      rows={documentItems.map((documentItem) => {
        const isEditingDescription =
          editMode.id === documentItem.id && editMode.field === "description";
        return {
          ...documentItem,
          key: `${documentItem.id}`,
          child: showDescriptions && (
            <Stack
              direction="row"
              fullwidth="true"
              spacing={2}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Stack width="86%">
                <Typography style={{ flexGrow: 1, textAlign: "left" }}>
                  Beschreibung
                </Typography>
                <Stack
                  direction="row"
                  fullwidth="true"
                  spacing={1}
                  alignItems="flex-start"
                  onClick={() =>
                    handleEditClick(documentItem.id, "description")
                  }
                >
                  <Box flex={1} marginLeft={0} marginTop={0} marginBottom={0}>
                    {isEditingDescription ? (
                      <TextField
                        style={inputStyle}
                        key={`input-desc-${documentItem.id}`}
                        variant="standard"
                        type="text"
                        name="item_desc"
                        value={
                          focused.item === documentItem.id
                            ? focused.value
                            : documentItem.description
                        }
                        onChange={(e) =>
                          handleChange(
                            documentItem,
                            "description",
                            e.target.value,
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(
                            documentItem,
                            "description",
                            e.target.value,
                          )
                        }
                        autoComplete="off"
                        multiline
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        autoFocus
                      />
                    ) : (
                      <Typography style={{ flexGrow: 1, textAlign: "left" }}>
                        {documentItem.description}
                      </Typography>
                    )}
                  </Box>
                  {!isEditingDescription && (
                    <Tooltip
                      title="Beschreibung"
                      PopperProps={{ style: { zIndex: 9999 } }}
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleEditClick(documentItem.id, "description")
                        }
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
              {stage === "offer" && (
                <Stack
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width="14%"
                >
                  <Typography style={{ flexGrow: 1, textAlign: "center" }}>
                    Optionales Produkt
                  </Typography>
                  <ToggleButtonGroup
                    size="small"
                    value={documentItem.optional}
                    exclusive
                    onChange={(e, newValue) => {
                      handleChange(documentItem, "optional", !!newValue);
                    }}
                  >
                    <CustomToggle value={false}>Nein</CustomToggle>
                    <CustomToggle value={true}>Ja</CustomToggle>
                  </ToggleButtonGroup>
                </Stack>
              )}
            </Stack>
          ),
          data: columns.map((column) => renderCell(column, documentItem)),
        };
      })}
      collapsible={showDescriptions}
    />
  );
}

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

DocumentItemList.propTypes = {
  documentItems: PropTypes.arrayOf(PropTypes.object),
  changeDocumentItem: PropTypes.func,
  deleteDocumentItem: PropTypes.func,
  changeDocumentItemsOrder: PropTypes.func,
  amountCols: PropTypes.object,
  priceCol: PropTypes.bool,
  showDescriptions: PropTypes.bool,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  isDraggable: PropTypes.bool,
  editableFields: PropTypes.arrayOf(PropTypes.string),
  project: PropTypes.object,
  session: PropTypes.object,
  lineMappings: PropTypes.object,
  setLineMappings: PropTypes.func,
  stage: PropTypes.string,
};
