import axios, { AxiosError } from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useRef } from "react";
import { isMobileOnly } from "react-device-detect";
import { HashLink as Link } from "react-router-hash-link";
import { Col, Row } from "reactstrap";
import { Button, Stack, Typography } from "@mui/material";
import { BASE_URL } from "../settings";
import { getHolidays, isHoliday } from "feiertagejs";
import Compress from "browser-image-compression";
import Label from "./Label";

export const HOLIDAYREGION = "NW";

export const wrapEmailMessage = (session, text, customer, address) => {
  let title = "";
  if (!address) {
    if (customer) {
      switch (customer.gender) {
        case "Frau":
          title =
            "Sehr geehrte Frau " +
            customer.first_name +
            " " +
            customer.last_name;
          break;
        case "Herr":
          title =
            "Sehr geehrter Herr " +
            customer.first_name +
            " " +
            customer.last_name;
          break;
        case "Firma":
          title = "Sehr geehrte Damen und Herren";
          break;
        default:
          title = "Sehr geehrte Familie " + customer.last_name;
          break;
      }
      title += ",\n\n";
    } else {
      title = "";
    }
  } else {
    title = address;
    title += ",\n\n";
  }

  return (
    title +
    text +
    "\n\n\nMit freundlichen Grüßen,\n\n" +
    session.companyConfig.signature
  );
};

export const getCustomerName = (customer) => {
  if (customer.name) return customer.name;
  if (customer.gender === "Firma") return customer.company;
  return customer.first_name
    ? customer.first_name + " " + customer.last_name
    : (customer.gender ? customer.gender + " " : "") +
        (customer.last_name ? customer.last_name : null);
};

export const numFormatter = (data) => {
  return parseFloat(data).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const removeFromObj = (obj, keys) => {
  obj = { ...obj };
  Object.keys(obj)
    .filter((key) => keys.includes(key))
    .forEach((key) => delete obj[key]);
  return obj;
};

export const removeFromArr = (arr, condition) => {
  const newArr = [];
  let item = null;
  arr.forEach((x) => {
    if (item === null && condition(x)) item = x;
    else newArr.push(x);
  });
  return [item, newArr];
};

export const filterObj = (obj, keys) => {
  obj = { ...obj };
  Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .forEach((key) => delete obj[key]);
  return obj;
};

export const splitList = (arr, condition) => {
  const objs1 = [];
  const objs2 = [];
  arr.forEach((item) => {
    if (condition(item)) objs1.push(item);
    else objs2.push(item);
  });
  return [objs1, objs2];
};

export const hasPermission = (user, permission) => {
  if (!user || !user.permission_keys) return false;
  if (Array.isArray(permission)) {
    return permission.some(
      (perm) => user.permission_keys.includes(perm) || user.is_superuser,
    );
  }
  return user.permission_keys.includes(permission) || user.is_superuser;
};

export const isString = (v) => typeof v === "string" || v instanceof String;

export const defaultIfEmpty = (value) =>
  value === null || value === undefined ? "" : value;

export const defaultIfEmptyForPrice = (value) => {
  if (value === null || value === undefined) return "";
  if (Number.isInteger(value)) return value.toFixed(2);
  return value;
};

export const valueForPrice = (value) => {
  if (value === null || value === undefined) return "";
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });
};

export const isInvoiceCompletelyPaid = (invoice) => {
  const paid = round(invoice.paid, 2);
  const cashDiscount = round(invoice.cash_discount, 2);
  const totalGross = round(invoice.total_gross, 2);
  const diff = paid - (totalGross - cashDiscount);

  return diff >= -0.005 || invoice.cancellation_invoice;
};

export const getOpenInvoiceAmount = (invoice) =>
  invoice.cancellation_invoice
    ? 0
    : Math.max(0, invoice.total_gross - invoice.cash_discount - invoice.paid);

export const isInvoicePaid = (invoice) =>
  invoice.paid || (invoice.paid === 0 && invoice.total_gross === 0);

export const isInvoiceOverpaid = (invoice) =>
  invoice.paid + invoice.cash_discount - invoice.total_gross > 0;

export const round = (number, decimal = 0) =>
  Math.round(number * 10 ** decimal) / 10 ** decimal;

export const removeDuplicates = (arr) => [...new Set(arr)];

export const removeDuplicatesByKey = (arr, key) => {
  const keyFunc = isString(key) ? (obj) => obj[key] : key;
  const newArr = [];
  const keys = [];
  arr.forEach((item) => {
    const k = keyFunc(item);
    if (!keys.includes(k)) {
      newArr.push(item);
      keys.push(k);
    }
  });
  return newArr;
};

export const arrSum = (arr) => arr.reduce((pv, cv) => pv + cv, 0);

export const arrange = (start, end = null, step = 1) => {
  if (end === null) {
    end = start;
    start = 0;
  }
  const arr = [];
  for (let i = start; i < end; i += step) arr.push(i);
  return arr;
};

export const checkAccessRightsButton = (user, buttonKey) => {
  switch (buttonKey) {
    case "openBusinessCard":
      return user.is_staff;
    case "openGoogleReview":
      return true;
    default:
      return false;
  }
};

export const checkAccessRights = (user, page) => {
  if (!user.is_superuser && !user.group_key) return false;
  let staff = true;
  let groups = [];
  let permission = null;
  switch (page) {
    case "dashboard":
      return true;
    case "lager":
      groups = null;
      permission = ["warehouse_all", "warehouse_worker", "warehouse_access"];
      break;
    case "defekte":
      groups = null;
      permission = "page_defective";
      break;
    case "appstatistiken":
      return user.is_superuser;
    case "leads":
    case "leads/:id":
      groups = null;
      permission = "page_leads";
      break;
    case "produkte":
      groups = null;
      permission = "page_products";
      break;
    case "rechnungen":
      groups = null;
      permission = "page_invoices";
      break;
    case "statistiken":
      groups = null;
      permission = "page_statistics";
      break;
    case "kunden":
    case "kunde/:id":
    case "projekt/:id":
      // case 'leads':
      groups = null;
      permission = "pages_customer_handling";
      break;
    case "kalendar":
    case "label":
    case "mitarbeiterlabel":
    case "projekt/:id/bauplanung":
    case "karte":
      groups = null;
      permission = "page_constructions";
      break;
    case "provisionen":
      groups = null;
      permission = "page_commissions";
      break;
    case "zeiterfassung":
      groups = null;
      permission = "page_timemanagement";
      break;
    case "nutzerverwaltung":
      groups = null;
      permission = "page_usermanagement";
      break;
    case "gruppenverwaltung":
      groups = null;
      permission = "page_groupmanagement";
      break;
    case "newsletter":
      groups = null;
      permission = "page_newsletter";
      break;
    case "flotte":
      groups = null;
      permission = "page_cartracking";
      break;
    case "aufgaben":
    case "aufgabe/:id":
    case "aufgabe/:id/erinnerung":
      groups = null;
      permission = null;
      break;
    case "kunde/angebote":
    case "kunde/dateneingabe":
      staff = false;
      groups = ["customers"];
      break;
    default:
      return false;
  }
  if ((staff && !user.is_staff) || (!staff && user.is_staff)) return false;
  if (
    !(user.is_superuser || groups === null || groups.includes(user.group_key))
  )
    return false;
  if (!(permission === null || hasPermission(user, permission))) return false;
  return true;
};

export const fillString = (string, len) => {
  const strLen = string.length;
  const diff = len - strLen;
  if (diff <= 0) return string;
  return " ".repeat(diff + 1) + string;
};

export const filledNumFormatter = (data, len) =>
  fillString(numFormatter(data), len);

export const date2String = (date) => moment(date).format("YYYY-MM-DD");

export const string2Date = (string) => moment(string, "YYYY-MM-DD").toDate();

export const date2FormattedString = (date) => moment(date).format("DD.MM.YYYY");

export const string2FormattedString = (string) =>
  date2FormattedString(string2Date(string));

export const formattedString2Date = (string) =>
  moment(string, "DD.MM.YYYY").toDate();

export const string2datetime = (string) => moment(string).toDate();

export const datetime2FormattedString = (date) =>
  moment(date).format("DD.MM.YYYY HH:mm:ss");

export const getRandomId = () =>
  Math.floor(Math.random() * (10000000 - 1 + 1) + 1);

export const modifyExtraCharge = (
  customProducts,
  productKeysForExtraCosts,
  prevExtraCharge,
  newExtraCharge,
) => {
  const costDiff = newExtraCharge - prevExtraCharge;
  let total = 0;
  const extraCostsForProduct = (p) =>
    (!productKeysForExtraCosts ||
      productKeysForExtraCosts.includes(p.productObj.key)) &&
    p.amount !== 0;
  customProducts.filter(extraCostsForProduct).forEach((p) => {
    total += p.price * p.amount;
  });
  const perc = (total + costDiff) / total;
  const newCustomProducts = customProducts.map((p) => {
    const pPerc = extraCostsForProduct(p) ? perc : 1;
    return { ...p, price: round(p.price * pPerc, 2) };
  });
  return newCustomProducts;
};

export const getImageWindowWithAuth = (image, handleLoad) => {
  const createImageWindow = (url) => {
    const newImageWindow = new window.Image();
    newImageWindow.src = url;
    newImageWindow.addEventListener("load", () => handleLoad(newImageWindow));
  };
  if (!isString(image)) {
    createImageWindow(URL.createObjectURL(image));
    return;
  } else if (!image.includes(BASE_URL) && image.startsWith("/")) {
    image = BASE_URL + image.substring(1);
  }

  const token = localStorage.getItem("token");
  axios
    .get(image, {
      headers: { Authorization: `Token ${token}` },
      responseType: "blob",
    })
    .then((res) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(res.data);
      reader.onload = () => {
        const imageDataUrl = reader.result;
        createImageWindow(imageDataUrl);
      };
    });
};

export const splitIntoBatches = (arr, nItems) => {
  if (arr.length === 0) return [];
  const batches = [[]];
  arr.forEach((i) => {
    const lastBatch = batches[batches.length - 1];
    if (lastBatch.length < nItems) lastBatch.push(i);
    else batches.push([i]);
  });
  return batches;
};

export function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDidMountEffect(func, deps) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

export function isValidEmailAddress(address) {
  if (address != null) return !!address.match(/.+@.+\..+/s);
  else return 0;
}

export function isValidPhoneNumber(number) {
  if (number !== null) {
    const match = number.match(
      /(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/g,
    );
    return match && match[0] === number;
  }
  return false;
}

function googleAddress(streetAndNumber, zipAndCity) {
  return (
    <a
      href={"https://maps.google.com/?q=" + streetAndNumber + " " + zipAndCity}
      rel="noreferrer"
      target="_blank"
    >
      {" "}
      {streetAndNumber}; {zipAndCity}
    </a>
  );
}

function getCustomerOverviewItems(
  customer,
  notes = true,
  linkToCustomer = false,
) {
  const customerHasSecondPhoneNumber = !!customer.phone2;
  const customerHasThirdPhoneNumber = !!customer.phone3;
  const customerHasMultiplePhoneNumbers =
    customerHasSecondPhoneNumber || customerHasThirdPhoneNumber;

  return [
    { key: "Kundennr.", value: customer.id, alwaysAddKey: true },
    ...(customer?.lead
      ? [
          {
            key: "Leadnr.",
            value: customer.lead.id,
            alwaysAddKey: true,
            wrapper: (props) => (
              <Link to={`/leads/${customer.lead.id}`} key={props.key}>
                {props.children}
              </Link>
            ),
          },
        ]
      : []),
    {
      key: customer.gender !== "Firma" ? "Name" : "Firma",
      value: linkToCustomer ? (
        <Link to={"/kunde/" + customer.id} style={{ textDecoration: "none" }}>
          {getCustomerName(customer)}
        </Link>
      ) : (
        getCustomerName(customer)
      ),
      alwaysAddKey: false,
    },
    ...(customer.gender === "Firma" && customer.last_name
      ? [
          {
            key: "Ansprechpartner",
            value:
              (customer.first_name ? `${customer.first_name} ` : "") +
              customer.last_name,
            alwaysAddKey: false,
          },
        ]
      : []),
    {
      key: "Adresse",
      value: googleAddress(customer.street_and_number, customer.zip_and_city),
      alwaysAddKey: false,
    },
    // { key: 'Bundesland', value: customer.province, alwaysAddKey: false },
    { key: "Email", value: customer.email, alwaysAddKey: false },
    {
      key: customerHasMultiplePhoneNumbers ? "Telefon 1" : "Telefon",
      value: customer.phone1,
      alwaysAddKey: false,
    },
    ...(customerHasSecondPhoneNumber
      ? [{ key: "Telefon 2", value: customer.phone2, alwaysAddKey: false }]
      : []),
    ...(customerHasThirdPhoneNumber
      ? [{ key: "Telefon 3", value: customer.phone3, alwaysAddKey: false }]
      : []),
    ...(customer.note && customer.note !== "" && notes
      ? [{ key: "Notiz", value: customer.note, alwaysAddKey: true }]
      : []),
  ];
}

function getLeadOverviewItems(lead, duplicates, notes = true) {
  const leadHasSecondPhoneNumber = !!lead.phone2;
  const leadHasThirdPhoneNumber = !!lead.phone3;
  const leadHasMultiplePhoneNumbers =
    leadHasSecondPhoneNumber || leadHasThirdPhoneNumber;
  const hasDuplicates = duplicates && duplicates.length > 0;

  return [
    { key: "Leadnr.", value: lead.id, alwaysAddKey: true },
    {
      key: lead.gender !== "Firma" ? "Name" : "Firma",
      value: getCustomerName(lead),
      alwaysAddKey: false,
    },
    ...(lead.gender === "Firma" && lead.last_name
      ? [
          {
            key: "Ansprechpartner",
            value:
              (lead.first_name ? `${lead.first_name} ` : "") + lead.last_name,
            alwaysAddKey: false,
          },
        ]
      : []),
    {
      key: "Adresse",
      value: googleAddress(lead.street_and_number, lead.zip_and_city),
      alwaysAddKey: false,
    },
    // { key: 'Bundesland', value: customer.province, alwaysAddKey: false },
    { key: "Email", value: lead.email, alwaysAddKey: false },
    {
      key: leadHasMultiplePhoneNumbers ? "Telefon 1" : "Telefon",
      value: lead.phone1,
      alwaysAddKey: false,
      wrapper: (props) => (
        <a href={`tel:${lead.phone1}`} key={props.key}>
          {props.children}
        </a>
      ),
    },
    ...(leadHasSecondPhoneNumber
      ? [{ key: "Telefon 2", value: lead.phone2, alwaysAddKey: false }]
      : []),
    ...(leadHasThirdPhoneNumber
      ? [{ key: "Telefon 3", value: lead.phone3, alwaysAddKey: false }]
      : []),
    ...(lead.note && lead.note !== "" && notes
      ? [{ key: "Notiz", value: lead.note, alwaysAddKey: true }]
      : []),
    {
      key: "Lead Type",
      value: lead.lead_type_name,
      alwaysAddKey: true,
    },
    ...(hasDuplicates
      ? [
          {
            key: "Duplikat",
            value: "",
            alwaysAddKey: false,
            wrapper: (props) => (
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Typography style={{ wordWrap: "break-word" }} color={"red"}>
                  Duplikat:
                </Typography>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  {duplicates.map((duplicate) => {
                    if (duplicate.duplicate_type === "customer") {
                      return (
                        <Link
                          key={`duplicate-customer-${duplicate.id}`}
                          to={`/kunde/${duplicate.id}`}
                        >
                          <Button variant="outlined" color="error">
                            Kunden
                          </Button>
                        </Link>
                      );
                    }
                    if (duplicate.duplicate_type === "lead") {
                      return (
                        <Link
                          key={`duplicate-lead-${duplicate.id}`}
                          to={`/leads/${duplicate.id}`}
                        >
                          <Button variant="outlined" color="error">
                            Leads
                          </Button>
                        </Link>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
}

function getProjectOverviewItems(project, notes = true) {
  const construction = project.construction_obj;
  const operatorName =
    construction && construction.operator_name
      ? construction.operator_name
      : null;
  const constructionDates =
    construction &&
    construction.constructiondates_set &&
    construction.constructiondates_set.length > 0
      ? construction.constructiondates_set
          .map((dateObj) => date2FormattedString(dateObj.date))
          .join(", ")
      : null;
  return [
    { key: "Projektnr.", value: project.id, alwaysAddKey: true },
    { key: "Name", value: projectNameWithLabel(project), alwaysAddKey: false },
    {
      key: "Adresse",
      value: googleAddress(
        project.street_and_number_project,
        project.zip_and_city_project,
      ),
      alwaysAddKey: false,
    },
    project.salesman_name
      ? {
          key: "Verkäufer",
          value: salesmanWithLabelInProject(project),
          alwaysAddKey: true,
        }
      : null,
    operatorName
      ? { key: "Netzbetreiber", value: operatorName, alwaysAddKey: true }
      : null,
    constructionDates
      ? { key: "Bautermin", value: constructionDates, alwaysAddKey: true }
      : null,
    project.note && project.note !== "" && notes
      ? { key: "Notiz", value: project.note, alwaysAddKey: true }
      : null,
  ].filter((x) => x);
}

function getOverviewHtmlItems(items) {
  return items
    .filter((item) => !!item)
    .map((item) => {
      const elems = isString(item.value)
        ? item.value.split("\n")
        : [item.value];
      const formatted = elems.map((elem, idx) => (
        <Typography
          style={{ wordWrap: "break-word" }}
          key={`overview-${item.key}-${idx}`}
        >
          {item.alwaysAddKey ? (idx === 0 ? `${item.key}: ` : "") : ""} {elem}
        </Typography>
      ));

      if (item.wrapper) {
        return item.wrapper({
          children: formatted,
          key: `overview-${item.key}`,
        });
      }

      return formatted;
    });
}

export function getCustomerOverview(customer, notes = true) {
  return (
    <div>
      {getOverviewHtmlItems(getCustomerOverviewItems(customer, notes))}
      {customer.contact_denied ? (
        <>
          <Typography style={{ color: "red" }} display="inline">
            Weitere Kontaktaufnahme abgelehnt
          </Typography>
          {customer.contact_denial_reason &&
          customer.contact_denial_reason !== "" ? (
            <Typography className="secondary-textcolor" display="inline">
              {" "}
              (Grund: {customer.contact_denial_reason})
            </Typography>
          ) : null}
          <br />
        </>
      ) : null}
    </div>
  );
}

export function getProjectOverview(project, notes = true) {
  const customer = project.customer_obj;
  return (
    <div>
      <Typography
        style={{ wordWrap: "break-word" }}
        className="secondary-textcolor"
        fontWeight={"bold"}
      >
        Kunde
      </Typography>
      {getOverviewHtmlItems(getCustomerOverviewItems(customer))}
      {customer.contact_denied ? (
        <>
          <Typography style={{ color: "red" }} display="inline">
            Weitere Kontaktaufnahme abgelehnt
          </Typography>
          {customer.contact_denial_reason &&
          customer.contact_denial_reason !== "" ? (
            <Typography className="secondary-textcolor" display="inline">
              {" "}
              (Grund: {customer.contact_denial_reason})
            </Typography>
          ) : null}
          <br />
        </>
      ) : null}
      <br />
      <Typography
        style={{ wordWrap: "break-word" }}
        className="secondary-textcolor"
        fontWeight={"bold"}
      >
        Projekt
      </Typography>
      {getOverviewHtmlItems(getProjectOverviewItems(project, notes))}
      {project.declined ? (
        <>
          <Typography style={{ color: "red" }} display="inline">
            Abgesagt
          </Typography>
          {project.declination_reason && project.declination_reason !== "" ? (
            <Typography className="secondary-textcolor" display="inline">
              {" "}
              (Grund: {project.declination_reason})
            </Typography>
          ) : null}
          <br />
        </>
      ) : null}
    </div>
  );
}

export function getLeadOverview(lead, duplicates, notes = true) {
  return (
    <div>
      <Typography
        style={{ wordWrap: "break-word" }}
        className="secondary-textcolor"
        fontWeight={"bold"}
      >
        Leads
      </Typography>
      {getOverviewHtmlItems(getLeadOverviewItems(lead, duplicates))}
    </div>
  );
}

export function getProjectOverviewTable(project) {
  if (isMobileOnly) return getProjectOverview(project);
  const customerItems = getCustomerOverviewItems(project.customer_obj);
  const projectItems = getProjectOverviewItems(project);
  return (
    <>
      <Typography
        style={{ wordWrap: "break-word" }}
        className="secondary-textcolor"
        fontWeight={"bold"}
      >
        Kunde
      </Typography>
      <Row>
        <Col xs={2} className="d-flex justify-content">
          <Typography
            style={{ wordWrap: "break-word" }}
            className="secondary-textcolor"
          >
            {customerItems.map((item) => (
              <Fragment key={`customer-items-key-${item.key}`}>
                <div>{item.key}:</div>
              </Fragment>
            ))}
          </Typography>
        </Col>
        <Col>
          <Typography
            style={{ wordWrap: "break-word" }}
            className="secondary-textcolor"
          >
            {customerItems.map((item) => (
              <Fragment key={`customer-items-value-${item.key}`}>
                <div>{item.value}</div>
              </Fragment>
            ))}
          </Typography>
        </Col>
      </Row>
      <Typography
        style={{ wordWrap: "break-word" }}
        className="secondary-textcolor"
        fontWeight={"bold"}
      >
        Projekt
      </Typography>
      <Row>
        <Col xs={2} className="d-flex justify-content">
          <Typography
            style={{ wordWrap: "break-word" }}
            className="secondary-textcolor"
          >
            {projectItems.map((item) => (
              <Fragment key={`project-items-key-${item.key}`}>
                <div>{item.key}:</div>
              </Fragment>
            ))}
          </Typography>
        </Col>
        <Col>
          <Typography
            style={{ wordWrap: "break-word" }}
            className="secondary-textcolor"
          >
            {projectItems.map((item) => (
              <Fragment key={`project-items-value-${item.key}`}>
                <div>{item.value}</div>
              </Fragment>
            ))}
          </Typography>
        </Col>
      </Row>
    </>
  );
}

export function sortById(arr) {
  // function compareFn(a, b) {
  //     return (a.id == b.id) ? 0 : ((a.id < b.id) ? -1 : 1)
  // }
  return [...arr].sort((a, b) => a.id - b.id);
}

export function sortByOrder(arr) {
  return [...arr].sort((a, b) => a.order - b.order);
}

function sortByPriorityAndId(a, b) {
  if (a.priority === null && b.priority !== null) return 1;
  if (a.priority !== null && b.priority === null) return -1;
  if (a.priority === null && b.priority === null) return a.id - b.id;
  return a.priority - b.priority || a.id - b.id;
}

export function sortByPriority(arr) {
  return [...arr].sort(sortByPriorityAndId);
}

export function generateString(length, onlyCapital = true) {
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  if (!onlyCapital) characters = characters + "abcdefghijklmnopqrstuvwxyz";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const THIS_YEAR = new Date().getFullYear();
// export const COLORS = Array.from({ length: 100 }, () => '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6))
export const COLORS = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#88AA88",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#651067",
];
export const DISTINCTCOLORS = [
  "#c41414",
  "#ba4d1e",
  "#ba6c1e",
  "#ba8b1e",
  "#d9bf2b",
  "#c8d92b",
  "#93d92b",
  "#2bd92b",
  "#2bd985",
  "#2bd9bc",
  "#2ba8d9",
  "#2b6ed9",
  "#342bd9",
  "#5f2bd9",
  "#7c2bd9",
  "#9c2bd9",
  "#c82bd9",
  "#d92b82",
  "#521010",
  "#522f10",
  "#524d10",
  "#385210",
  "#10522f",
  "#103d52",
  "#f0a95d",
  "#d5f05d",
  "#a274a3",
];
export const MONTHS = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mrz",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];
export const HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const applyNestedFcn = (
  arr,
  func,
  getChildren = (x) => x.children,
  setChildren = (x, children) => {
    x.children = children;
  },
) => {
  const applyToArr = (a) =>
    a.map((item) => {
      // apply to item
      const newItem = { ...item };
      func(newItem);
      // apply to children
      const children = getChildren(newItem);
      if (children) {
        const newChildren = applyToArr(children);
        setChildren(newItem, newChildren);
      }
      return newItem;
    });
  return applyToArr(arr);
};

export const nestedFilter = (
  arr,
  func,
  getChildren = (x) => x.children,
  setChildren = (x, children) => {
    x.children = children;
  },
) => {
  const filterArr = (a) => {
    const newArr = a
      .filter((item) => func(item))
      .map((item) => {
        const newItem = { ...item };
        const children = getChildren(newItem);
        if (children) {
          const newChildren = filterArr(children);
          setChildren(newItem, newChildren);
        }
        return newItem;
      });
    return newArr;
  };
  return filterArr(arr);
};

export const swapItem = (arr, name1, name2) => {
  const index1 = arr.findIndex((item) => item.name === name1);
  const index2 = arr.findIndex((item) => item.name === name2);

  if (index1 !== -1 && index2 !== -1) {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  }
  return arr;
};

// export const applyNestedFcn = (arr, func, getChildren = (x) => x.children) => {
//     const nestedFcn = (item) => {
//         // apply to item
//         func(item);
//         // apply to children
//         let children = getChildren(item)
//         if (children) applyToArr(children)
//     }
//     const applyToArr = (a) => a.forEach(item => nestedFcn(item))
//     const newArr = clone(arr)
//     applyToArr(newArr)
//     return newArr
// }

// export const nestedFilter = (arr, func, getChildren = (x) => x.children) => {
//     const clearArr = (a) => {
//         // remove from array
//         const remove = []
//         a.forEach((item, idx) => {
//             if (! func(item)) remove.unshift(idx) // add to the start of the remove arr to remove later the element from the end
//         })
//         remove.forEach(idx => a.splice(idx, 1))
//         //apply to children
//         a.forEach(item => {
//             let children = getChildren(item)
//             if (children) clearArr(children)
//         })
//     }
//     const newArr = clone(arr)
//     clearArr(newArr)
//     return newArr
// }

export const clone = (x) => JSON.parse(JSON.stringify(x));

const getOptionalKeys = (form, emptyForm) => {
  return Object.keys(form).filter(
    (key) => !Object.keys(emptyForm).includes(key),
  );
};

export const checkIfValueIsEmpty = (val) =>
  val === undefined ||
  val === null ||
  val === "" ||
  (Array.isArray(val) && val.length === 0);

export const getEmptyFields = (
  form,
  emptyForm,
  additionalOptionalKeys = [],
  checkIfEmpty = null,
) => {
  const optionalKeys = getOptionalKeys(form, emptyForm);
  optionalKeys.push(...additionalOptionalKeys);
  const keys = Object.keys(form).filter((x) => !optionalKeys.includes(x));
  if (checkIfEmpty == null)
    checkIfEmpty = (key, val, form) => checkIfValueIsEmpty(val);
  const emptyFields = keys.filter((key) => checkIfEmpty(key, form[key], form));
  return emptyFields.length !== 0 ? emptyFields : null;
};

export const getEmptyFieldsError = (
  form,
  emptyForm,
  additionalOptionalKeys = [],
  checkIfEmpty = null,
) => {
  additionalOptionalKeys = [...additionalOptionalKeys, "id"];
  const emptyFields = getEmptyFields(
    form,
    emptyForm,
    additionalOptionalKeys,
    checkIfEmpty,
  );
  return emptyFields
    ? `Error when submitting as following fields are not filled: ${emptyFields}`
    : null;
};

export const getUnderlinedOpenButton = (text) => {
  const getOpenButton = (toggle) => (
    <Typography
      className="secondary-textcolor"
      style={{ textDecoration: "underline", fontSize: 14, cursor: "pointer" }}
      onClick={toggle}
    >
      {text}
    </Typography>
  );
  return getOpenButton;
};

export const getHolidayEvents = () => {
  return getHolidayEventsByYear(new Date().getFullYear());
};

export const getHolidayEventsByYear = (year) => {
  const holidays = getHolidays(year, HOLIDAYREGION);
  return holidays.map((e) => {
    return {
      title: e.translate("de"),
      start: e.date,
      end: e.date,
      allDay: true,
      event: null,
      backgroundColor: "#595959",
      type: "holiday",
      tentative: false,
    };
  });
};

export const eMeterActions = [
  { label: "Keine Aktion", value: 0 },
  { label: "Ausbau", value: 1 },
  { label: "Wechsel", value: 2 },
];

export const convertToFormData = (form, fileKeys = [], excludeKeys = []) => {
  const formData = new FormData();
  Object.keys(form)
    .filter((key) => !excludeKeys.includes(key))
    .forEach((key) => {
      if (fileKeys.includes(key)) {
        if (!isString(form[key])) {
          // TODO: url
          const file = form[key];
          if (form[key]) formData.append(key, file, file.name); // null not possible
        }
      } else formData.append(key, form[key] !== null ? form[key] : "");
    });
  return formData;
};

export const germanCalendarMsgs = {
  next: <>&#707;</>,
  previous: <>&#706;</>,
  today: "Heute",
  month: "Monat",
  week: "Woche",
  day: "Tag",
  work_week: "Arbeitswoche",

  date: "Datum",
  time: "Zeit",
  event: "Event",
  allDay: "Ganzer Tag",
  yesterday: "Gestern",
  tomorrow: "Morgen",
  agenda: "Agenda",
  noEventsInRange: "In diesem Bereich gibt es keine Ereignisse.",

  showMore: (total) => `+${total} mehr`,
};

export const getCalendarRange = (date, view) => {
  let rangeStart, rangeEnd;
  switch (view) {
    case "day":
      rangeStart = moment(date).startOf("day").toDate();
      rangeEnd = moment(date).endOf("day").toDate();
      break;
    case "week":
      rangeStart = moment(date).startOf("isoWeek").toDate();
      rangeEnd = moment(date).endOf("isoWeek").toDate();
      break;
    case "month":
      // add seven days because in the month view you can see the adjacent weeks
      rangeStart = moment(date).startOf("month").subtract(7, "days").toDate();
      rangeEnd = moment(date).endOf("month").add(7, "days").toDate();
      break;
    case "work_week":
      rangeStart = moment(date).startOf("isoWeek").toDate();
      rangeEnd = moment(rangeStart).add(5, "days").toDate();
      break;
    default:
      return null;
  }
  return { start: rangeStart, end: rangeEnd };
};

export const getNextDay = (n = 1) => {
  const today = new Date(); // get today's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + n); // add n days (for tomorrow 1)
  return tomorrow;
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexTextColor = (hex) => {
  const rgbColor = hexToRgb(hex);
  return rgbColor.r * 0.299 + rgbColor.g * 0.587 + rgbColor.b * 0.114 > 186
    ? "#000000"
    : "#ffffff";
};

export const getMapUrl = (province) => {
  if (province === "Berlin" || province === "Brandenburg") {
    return "https://geoportal.brandenburg.de/de/cms/portal/start#";
  } else if (province === "Nordrhein-Westfalen") {
    return "https://www.tim-online.nrw.de/tim-online2/?bg=dop";
  } else if (province === "Bayern") {
    return "https://geoportal.bayern.de/";
  } else if (province === "Baden-Württemberg") {
    return "https://www.geoportal-bw.de/";
  }

  return "https://www.tim-online.nrw.de/tim-online2/?bg=dop";
};

export const parsePerc = (string) => {
  if (!isString(string)) return null;
  const regex = /^(?<perc>\d+(\.\d+)?)%$/;
  if (!regex.test(string)) return null;
  return regex.exec(string).groups.perc;
};

export const batteryOptions = [
  { label: "Keine Batterie", value: 0 },
  ...Array.from(Array(8).keys()).map(function (i) {
    const val = (i + 3) * 2.56;
    return { label: val.toString() + " kwh", value: val };
  }),
];

// export const popFromArr = (arr, cond) => {
//   let item
//   arr = arr.filter(_item => {
//     if (item === undefined && cond(_item)) {
//       item = _item
//       return false
//     }
//     return true
//   })
//   return { item, arr }
// }

const popItemByIndex = (arr, index) => {
  return arr.splice(index, 1)[0];
};

export const popFromObj = (obj, key) => {
  const item = obj[key];
  delete obj[key];
  return item;
};

export const popFromArr = (arr, cond = 0) => {
  if (typeof cond === "number") return popItemByIndex(arr, cond);
  let itemIdx;
  for (const [idx, item] of arr.entries()) {
    if (cond(item)) {
      itemIdx = idx;
      break;
    }
  }
  return popItemByIndex(arr, itemIdx);
};

export const isBoolean = (val) => val === true || val === false;

const isNewObj = (obj) => obj.id <= 0;

export const isNumber = (val) => !isNaN(val);

export const getImagePromise = async (
  parentKey,
  parentId,
  image,
  url,
  additionalKeys,
) => {
  const formData = new FormData();
  if (!image?.image || isString(image.image)) return Promise.resolve();
  formData.append("image", image.image, image.image.name);
  formData.append(parentKey, parentId);
  additionalKeys.forEach((key) => {
    formData.append(key, image[key]);
  });
  if (image.id < 0) return axios.post(url, formData);
  formData.append("id", image.id);
  return axios.put(url + image.id, formData);
};

export const getImagePromises = async (
  parentKey,
  parentId,
  images,
  url,
  additionalKeys = [],
  getPromise = getImagePromise,
) => {
  const deletePromise = axios.delete(url, {
    data: {
      [parentKey]: parentId,
      except_ids: images.filter((i) => !isNewObj(i)).map((i) => i.id),
    },
  });
  return deletePromise.then(() =>
    Promise.all(
      images.map((image) =>
        getPromise(parentKey, parentId, image, url, additionalKeys),
      ),
    ),
  );
};

export const checkForFixedProductKey = (fixedProductKeys, key) => {
  return (
    fixedProductKeys &&
    key &&
    fixedProductKeys.some((fKey) =>
      isString(fKey) ? fKey === key : !!key.match(fKey),
    )
  );
};

export const checkForSalesmenToDelete = (user, key) => {
  const productKeyListToDelete = [
    "na_schutz",
    "wandlermessung",
    "weitere_wandlermessung",
    "wandlermessung_kaskade",
  ];
  return user.group_key === "salesmen" && productKeyListToDelete.includes(key);
};

export const disabledItems = (disabledKey) => {
  const disabledItems = [
    "elektroinstallation",
    "pv_module",
    "batterie",
    "batterie_kwh",
    "anlage",
  ];
  return disabledItems.includes(disabledKey);
};

export const disabledColumns = (disabledKey) => {
  const disabledColumns = [
    "name",
    "amount_planned",
    "amount_offer",
    "amount_planned",
    "amount_invoices",
    "amount",
  ];
  return disabledColumns.includes(disabledKey);
};

/**
 * Check whether passed date is holiday
 * @param {Date} date The date that will be checked
 * @returns {boolean} The result of whether the passed date is holday
 */
export const isHolidayDate = (date) => {
  const region = "NW";
  return isHoliday(date, region);
};

export const getTotalDays = (startDate, endDate, calendarYearDate) => {
  // Filter for the date if not start or end in the same year
  if (new Date(startDate).getFullYear() !== new Date(endDate).getFullYear()) {
    if (new Date(startDate).getFullYear() === calendarYearDate) {
      endDate = new Date(calendarYearDate, 11, 31);
    } else {
      startDate = new Date(calendarYearDate, 0, 1);
    }
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  // Initialize a counter for business days
  let totalVacationDays = 0;

  let currentDate = new Date(start);
  while (currentDate <= end) {
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // Check if the current date is not a holiday
      // Not using the getHolidayEvents function because it returns the holidays for the CURRENT year only
      const isHoliday = isHolidayDate(currentDate);
      if (!isHoliday) {
        totalVacationDays++;
      }
    }
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return totalVacationDays;
};

export const getErrorMessage = (error) => {
  const res =
    error instanceof AxiosError &&
    error.response.headers["content-type"] === "application/json"
      ? error.response.data
      : error.message;
  return JSON.stringify(res);
};

/**
 * Check whether passed canvas is blank or not
 * @param {HTMLCanvasElement} canvas The passed canvas that will be checked
 * @returns {boolean} The result of whether the passed canvas is blank
 */
export const isBlankCanvas = (canvas) => {
  try {
    const context = canvas.getContext("2d");
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const arrData = Array.from(imageData.data);
    return arrData.every((color) => color === 0);
  } catch (err) {
    return true;
  }
};

/**
 * Get promise of signature image
 * @param {*} val result from canvas library
 * @param {string} filename filename of the signature image
 * @param {string|null} key key of the signature image
 * @returns {Promise<[string, File]>|Promise<File>|Promise<null>} result of signature image
 */
export const getSignaturePromise = async (val, filename, key = null) => {
  if (!val || isString(val)) {
    return new Promise((resolve) => {
      resolve(key ? [key, null] : null);
    });
  }

  const canvas = val.getTrimmedCanvas();
  if (isBlankCanvas(canvas)) {
    return new Promise((resolve) => {
      resolve(key ? [key, null] : null);
    });
  }

  return fetch(val.getTrimmedCanvas().toDataURL("image/png"))
    .then((r) => r.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: "image/png" }))
    .then((f) => (key ? [key, f] : f));
};

/**
 * Construct new URL based on current URL with added key value pair of query param
 * @param {string} key key of query param
 * @param {string} val value of query param
 * @returns {string} new URL with new query param
 */
export const getNewURLWithNewQueryParam = (key, val) => {
  if (!window) {
    console.error("window is not initialized yet");
  }

  const currentURL = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, val);

  const newURL = currentURL.split("?")[0] + "?" + searchParams.toString();
  return newURL;
};

/**
 * Maybe Data
 * @typedef {Object} MaybeData
 * @property {any} data
 * @property {any} error
 */

/**
 * Safely call a callback function
 * @param {Function} callback callback that need to be called
 * @returns {MaybeData} result and error of the callback
 */
export const safe = (callback) => {
  try {
    const data = callback();
    return { data, err: null };
  } catch (err) {
    return { data: null, err };
  }
};

export const dataURLToFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

/**
 * Converts a URL to a File object.
 *
 * This function supports both data URLs and URLs that need to be fetched.
 * If the URL starts with 'data:', it processes the data URL directly.
 * Otherwise, it fetches the URL and converts the response to a File.
 *
 * @param {string} url - The URL to convert to a File object.
 * @param {string} filename - The name to give to the resulting File object.
 * @param {string} [mimeType] - The MIME type of the resulting File object.
 *
 * @returns {Promise<File>} - A promise that resolves to the File object.
 *
 * @example
 * urlToFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt')
 *     .then(file => {
 *         console.log(file);
 *     });
 *
 * @example
 * urlToFile('https://example.com/image.jpg', 'image.jpg', 'image/jpeg')
 *     .then(file => {
 *         console.log(file);
 *     });
 */
export const urlToFile = (url, filename, mimeType, toBlob = false) => {
  if (url.startsWith("data:")) {
    const arr = url.split(",");
    const regex = /:(.*?);/;
    const match = regex.exec(arr[0]);
    const mime = match[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return axios
    .get(url, {
      responseType: toBlob ? "blob" : "arraybuffer",
    })
    .then(async (res) => {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        // Use webworker for faster compression
        useWebWorker: true,
      };
      const file = new File([res.data], filename, { type: mimeType });
      const compressedFile = await Compress(file, options);
      compressedFile.lastModifiedDate = new Date();

      return new File([compressedFile], filename, {
        type: mimeType,
        lastModified: Date.now(),
      });
    });
};

/*
 * Returns a new date, where "nDays" days are added to the given date
 * @param {string} date date to that "nDays" days should be added
 * @param {string} nDays number of days to add
 * @returns {Date}
 */
export const addDaysToDate = (date, nDays) => {
  const result = new Date(date);
  result.setDate(result.getDate() + nDays);
  return result;
};

export const getMimeType = (extension) => {
  const mimeTypes = {
    png: "image/png",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    gif: "image/gif",
    webp: "image/webp",
    svg: "image/svg+xml",
    bmp: "image/bmp",
    tiff: "image/tiff",
    ico: "image/x-icon",
    json: "application/json",
    html: "text/html",
    css: "text/css",
    js: "application/javascript",
    pdf: "application/pdf",
    xml: "application/xml",
    zip: "application/zip",
    txt: "text/plain",
    // Add more mappings as needed
  };
  return mimeTypes[extension.toLowerCase()] || "application/octet-stream";
};

export const getCalendarTaskColor = (task) => {
  if (task.finished) {
    return "green";
  } else if (task.started) {
    return "yellow";
  } else {
    return "red";
  }
};
export const gregorianDe = {
  name: "gregorian_de",
  months: [
    ["Januar", "Jan"],
    ["Februar", "Feb"],
    ["März", "Mär"],
    ["April", "Apr"],
    ["Mai", "Mai"],
    ["Juni", "Jun"],
    ["Juli", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["Oktober", "Okt"],
    ["November", "Nov"],
    ["Dezember", "Dez"],
  ],
  weekDays: [
    ["Samstag", "Sa"],
    ["Sonntag", "So"],
    ["Montag", "Mo"],
    ["Dienstag", "Di"],
    ["Mittwoch", "Mi"],
    ["Donnerstag", "Do"],
    ["Freitag", "Fr"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
  title: "Daten",
};

export const arraysEqual = (arr1, arr2) => {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const compareLatLong = (latLong1, latLong2, tolerance = 1e-9) => {
  if (
    !latLong1 ||
    !latLong2 ||
    latLong1.length !== 2 ||
    latLong2.length !== 2
  ) {
    return false;
  }
  const [lat1, long1] = latLong1;
  const [lat2, long2] = latLong2;

  return (
    Math.abs(lat1 - lat2) <= tolerance && Math.abs(long1 - long2) <= tolerance
  );
};

export const isDateArrayChanged = (currentArray, newArray) => {
  if (!currentArray || !newArray) return false;
  if (currentArray.length !== newArray.length) return true;
  return currentArray.some((date, index) => date.date !== newArray[index].date);
};

export const isEarliestDateChanged = (currentArray, newArray) => {
  if (!currentArray || !newArray) return false;
  return (
    currentArray.length === 0 ||
    newArray.length === 0 ||
    currentArray[0].date !== newArray[0].date
  );
};

export const getFirstDate = (dateArray, format) => {
  return dateArray.length > 0
    ? moment(
        new Date(
          Math.min(...dateArray.map((dateObj) => new Date(dateObj.date))),
        ).getTime(),
      ).format(format)
    : "";
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha
    ? "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
    : "rgb(" + r + ", " + g + ", " + b + ")";
};

export const filterInPlace = (arr, condition) => {
  let i = 0;
  let j = 0;
  while (i < arr.length) {
    const val = arr[i];
    if (condition(val, i, arr)) arr[j++] = val;
    i++;
  }
  arr.length = j;
  return arr;
};

const deg2rad = (deg) => deg * (Math.PI / 180);

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const isValidForm = (mandatoryFields, form) => {
  for (const key of Object.keys(form)) {
    if (mandatoryFields.includes(key) && checkIfValueIsEmpty(form[key])) {
      return false;
    }
  }
  return true;
};

export const isPartialValidForm = (mandatoryFields, form) => {
  for (const key of mandatoryFields) {
    if (!checkIfValueIsEmpty(form[key])) {
      return true;
    }
  }

  return false;
};

export const LEAD_SOURCES = [
  "Empfehlung",
  "Wattfox",
  "Aroundhome",
  "Tolksdorf",
  "Webseite - Funnel",
  "Webseite - Funnel - Google",
  "Webseite - Funnel - Meta",
  "Webseite - Kontaktformular",
  "MyHammer",
  "Planville.de",
  "DAA",
  "Hausfrage.de",
  "GoEnergy",
  "11880",
  "SolarGrowth",
  "SKV",
  "EZA",
  "Leads Navigator",
  "PV-Leadverkauf",
  "Fachbetrieb Leads",
  "Primest",
  "Angebots-Guru",
];

export const ProjectLabelEnum = Object.freeze({
  AUTOMATIC: 0,
  MANUAL: 1,
  BOTH: 2,
});

export const projectNameWithLabel = (project) => (
  <Stack direction="row" spacing={0.4} alignItems="center" flexWrap="wrap">
    <Typography color="black">{project.name}</Typography>
    {project.project_label_details.map((label, idx) => (
      <Label
        key={idx}
        text={label.text}
        name={label.name}
        labelColor={label.labelColor}
        textColor={label.textColor}
        textSize={10}
      />
    ))}
  </Stack>
);

export const constructionNameWithLabel = (construction) => (
  <Stack direction="row" spacing={0.4} alignItems="center" flexWrap="wrap">
    <Typography color="black">{construction.customer_name}</Typography>
    {construction.project_label_details.map((label, idx) => (
      <Label
        key={idx}
        text={label.text}
        name={label.name}
        labelColor={label.labelColor}
        textColor={label.textColor}
        textSize={10}
      />
    ))}
  </Stack>
);

export const EmployeeLabelEnum = Object.freeze({
  AUTOMATIC: 0,
  MANUAL: 1,
});

export const getEmployeesName = (employees) => {
  if (!employees) return null;
  return (
    employees.employees_labels &&
    employees.employees_labels.map((label, lIdx) => (
      <React.Fragment key={lIdx}>
        {userWithLabel(label)}
        {lIdx < employees.employees_labels.length - 1 && ", "}
      </React.Fragment>
    ))
  );
};

export const getElectriciansName = (electricians) => {
  if (!electricians) return null;
  return (
    electricians.electricians_labels &&
    electricians.electricians_labels.map((electrician, eIdx) => (
      <React.Fragment key={eIdx}>
        {userWithLabel(electrician)}
        {eIdx < electricians.electricians_labels.length - 1 && ", "}
      </React.Fragment>
    ))
  );
};

export const userWithLabel = (user) => {
  let sortedLabels = null;
  if (user && user.employee_label) {
    sortedLabels = user.employee_label
      .sort((a, b) => a.order - b.order)
      .map((item) => item.label);
  }
  return (
    <div
      style={{ display: "inline-flex", alignItems: "center", flexWrap: "wrap" }}
    >
      <Typography color="black">
        {user?.name ? user?.name : user?.employee_name}
      </Typography>
      {sortedLabels &&
        sortedLabels.map((label, idx) => (
          <React.Fragment key={idx}>
            <Label
              text={label.text}
              name={label.name}
              labelColor={label.label_color}
              textColor={label.text_color}
              textSize={10}
              marginLeft="0.2rem"
            />
          </React.Fragment>
        ))}
    </div>
  );
};

export const salesmanWithLabelInProject = (project) => {
  const sortedLabels = project.salesman_label
    .sort((a, b) => a.order - b.order)
    .map((item) => item.label);
  return (
    <div className="d-inline-flex">
      <Typography component="span" color="black">
        {project.salesman_name}
      </Typography>
      {sortedLabels.map((label, idx) => (
        <Label
          key={idx}
          text={label.text}
          name={label.name}
          labelColor={label.label_color}
          textColor={label.text_color}
          textSize={10}
        />
      ))}
    </div>
  );
};

export const taskUserWithLabel = (task, responsibleName, bold = true) => {
  let sortedLabels = null;
  if (task && task.employee_label) {
    sortedLabels = task.employee_label
      .sort((a, b) => a.order - b.order)
      .map((item) => item.label);
  }
  return (
    <div
      style={{ display: "inline-flex", alignItems: "center", flexWrap: "wrap" }}
    >
      <Typography
        color="black"
        style={{
          fontWeight:
            task?.name === responsibleName
              ? bold
                ? "bold"
                : "normal"
              : "normal",
          fontStyle:
            task?.name === responsibleName && !bold ? "italic" : "normal",
        }}
      >
        {task?.name}
      </Typography>
      {sortedLabels &&
        sortedLabels.map((label, idx) => (
          <React.Fragment key={idx}>
            <Label
              text={label.text}
              name={label.name}
              labelColor={label.label_color}
              textColor={label.text_color}
              textSize={10}
              marginLeft="0.2rem"
            />
          </React.Fragment>
        ))}
    </div>
  );
};

export const carBrandModelWithLabel = (car) => {
  let sortedLabels = null;
  if (car && car.labels) {
    sortedLabels = car.labels
      .sort((a, b) => a.order - b.order)
      .map((item) => item.label);
  }
  return (
    <div
      style={{ display: "inline-flex", alignItems: "center", flexWrap: "wrap" }}
    >
      <Typography color="secondary-textcolor">
        {car.brand_model || ""}
      </Typography>
      {sortedLabels &&
        sortedLabels.map((label, idx) => (
          <React.Fragment key={idx}>
            <Label
              text={label.text}
              name={label.name}
              labelColor={label.label_color}
              textColor={label.text_color}
              textSize={10}
              marginLeft="0.2rem"
            />
          </React.Fragment>
        ))}
    </div>
  );
};

/**
 * Returns a wrapped function that call callback everytime it being called
 * @param {Function} fn function to be called
 * @param {Function} callback callback function to be called after fn is called
 * @returns Function
 */
export const wrapCallback = (fn, callback) => {
  return async (...args) => {
    const res = await fn(...args);
    await callback();
    return res;
  };
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const formatUrl = (url) => {
  if (url.startsWith("https://")) {
    return url;
  }
  if (url.startsWith("http://")) {
    return url.replace("http://", "https://");
  }
  return `https://${url}`;
};

export const getRandomColor = () => {
  const letters = "0123456789abcdef";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getScheduleStrs = (scheduleInfo) => {
  const result = [];
  if (scheduleInfo.n_constructions || scheduleInfo.n_tasks) {
    const scheduleStrs = [
      !scheduleInfo.n_constructions
        ? null
        : scheduleInfo.n_constructions === 1
          ? "1 Baustelle"
          : `${scheduleInfo.n_constructions} Baustellen`,
      !scheduleInfo.n_tasks
        ? null
        : scheduleInfo.n_tasks === 1
          ? "1 Aufgabe"
          : `${scheduleInfo.n_tasks} Aufgaben`,
    ]
      .filter((x) => x)
      .join(" und ");
    result.push(
      `${scheduleInfo.name} ist bereits eingeplant für: ${scheduleStrs}`,
    );
  }
  if (scheduleInfo.time_management_events) {
    const timeManagementEvents = [
      scheduleInfo.time_management_events.includes(1) ? "Urlaub" : null,
      scheduleInfo.time_management_events.includes(2) ? "Krankheit" : null,
      scheduleInfo.time_management_events.includes(3)
        ? "Frei wegen 4-Tage-Woche"
        : null,
    ]
      .filter((x) => x)
      .join(" und ");
    result.push(
      `${scheduleInfo.name} hat bereits Zeiterfassungseinträge für: ${timeManagementEvents}`,
    );
  }
  return result;
};

export const EVENT_TYPES = [
  { label: "Arbeitszeit", value: 0 },
  { label: "Urlaub", value: 1 },
  { label: "Krankheit", value: 2 },
  { label: "Frei wegen 4-Tage-Woche", value: 3 },
  { label: "Schule", value: 4 },
  { label: "Überstundenabbau", value: 5 },
];

export const isNormalWorkingHoursEventType = (eventTypeOrdinalVal) => {
  return (
    eventTypeOrdinalVal === 0 ||
    eventTypeOrdinalVal === 4 ||
    eventTypeOrdinalVal === 5
  );
};

export const calculateTotalKwh = (batterykwhSet) => {
  return batterykwhSet.reduce((sum, battery) => sum + battery.kwh, 0);
};

export const calculatePanelStats = (roofimageSet) => {
  let nOptimizers = 0;
  let nHorizontalPanels = 0;
  let nVerticalPanels = 0;

  roofimageSet.forEach((roofimage) => {
    roofimage.roofimagepanel_set.forEach((panel) => {
      if (panel.optimizer) nOptimizers += 1;
      if (panel.vertical) {
        nVerticalPanels += 1;
      } else {
        nHorizontalPanels += 1;
      }
    });
  });

  return { nOptimizers, nHorizontalPanels, nVerticalPanels };
};

export const calculateKwp = (
  nHorizontalPanels,
  nVerticalPanels,
  modules,
  moduleId,
) => {
  const module = modules.find((module) => module.id === moduleId);
  const moduleKwp = module ? module.kwp : 0;
  return (nHorizontalPanels + nVerticalPanels) * moduleKwp;
};
