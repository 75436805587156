import React, { useEffect, useMemo, useState } from "react";

import { PropTypes } from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.number,
  index: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabView({ components, useUrl = false }) {
  // component: { title, child, onLoad }
  const [value, setValue] = useState(null);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const getComponentTabName = (component) =>
    component && component.title.toLowerCase().replace(" ", "");

  useEffect(() => {
    if (useUrl) {
      const tab = searchParams.get("tab");
      let componentId = null;
      if (tab)
        components.forEach((c, idx) => {
          if (getComponentTabName(c) === tab) componentId = idx;
        });
      if (componentId != null) changeTab(componentId);
      else changeTabByUrl(0);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!useUrl) setValue(0);
  }, []);

  const changeTab = (newValue) => {
    setValue(newValue);
    const onLoad = components[newValue].onLoad;
    if (onLoad) onLoad();
  };

  const changeTabByUrl = (newValue) => {
    // delete all other search params
    searchParams.forEach((value, key) => {
      if (key !== "tab") searchParams.delete(key);
    });
    searchParams.set("tab", getComponentTabName(components[newValue]));
    history.push({ search: searchParams.toString() });
  };

  const handleChange = (event, newValue) => {
    if (useUrl) changeTabByUrl(newValue);
    else changeTab(newValue);
  };

  if (value === null) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          {components.map((component, idx) => (
            <Tab
              key={`tab-title-${idx}`}
              label={component.title}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
      </Box>
      {components.map((component, idx) => (
        <CustomTabPanel key={`tab-${idx}`} value={value} index={idx}>
          {component.getChild()}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

TabView.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object),
  useUrl: PropTypes.bool,
};
