import React from "react";
import axios from "axios";

import {
  getPlantProjectModals,
  unclickablePlantProjectTodoKeys,
} from "./plant_project/plantProjectTodoItems";
import {
  getBlankInvoiceProjectModals,
  unclickableBlankInvoiceProjectTodoKeys,
} from "./blank_invoice_project/blankInvoiceProjectTodoItems";
import {
  getBlankOfferProjectModals,
  unclickableBlankOfferProjectTodoKeys,
} from "./blank_offer_project/blankOfferProjectTodoItems";
import {
  getHeatpumpProjectModals,
  unclickableHeatpumpProjectTodoKeys,
} from "./heatpump_project/heatpumpProjectTodoItems";
import UploadMap from "./plant_project/offers/UploadMap";

import { API_URL_GROWATTACCOUNT, API_URL_VOLTAVODATA } from "../../settings";
import {
  round,
  userWithLabel,
  getElectriciansName,
} from "../../elements/utils";
import {
  getRoofProjectModals,
  unclickableRoofProjectTodoKeys,
} from "./roof_project/roofProjectTodoItems";

export const constructionType2ProjectType = (constructionResourceType) => {
  if (constructionResourceType === "PlantConstruction") return "PlantProject";
  if (constructionResourceType === "HeatpumpConstruction")
    return "HeatpumpProject";
  return null;
};

export const ProjectType2ConstructionType = (projectResourceType) => {
  if (projectResourceType === "PlantProject") return "PlantConstruction";
  if (projectResourceType === "HeatpumpProject") return "HeatpumpConstruction";
  return null;
};

export const getProjectModals = (project, resetParent, session) => {
  if (project.resourcetype === "PlantProject")
    return getPlantProjectModals(project, resetParent, session);
  if (project.resourcetype === "BlankInvoiceProject")
    return getBlankInvoiceProjectModals(project, resetParent, session);
  if (project.resourcetype === "BlankOfferProject")
    return getBlankOfferProjectModals(project, resetParent, session);
  if (project.resourcetype === "HeatpumpProject")
    return getHeatpumpProjectModals(project, resetParent, session);
  if (project.resourcetype === "RoofProject")
    return getRoofProjectModals(project, resetParent, session);
};

export const getUnclickableProjectTodoKeys = (project) => {
  if (project.resourcetype === "PlantProject")
    return unclickablePlantProjectTodoKeys;
  if (project.resourcetype === "BlankOfferProject")
    return unclickableBlankOfferProjectTodoKeys;
  if (project.resourcetype === "BlankInvoiceProject")
    return unclickableBlankInvoiceProjectTodoKeys;
  if (project.resourcetype === "HeatpumpProject")
    return unclickableHeatpumpProjectTodoKeys;
  if (project.resourcetype === "RoofProject")
    return unclickableRoofProjectTodoKeys;
};

export const getProductExtraCostKeys = (project) => {
  if (project.resourcetype === "PlantProject")
    return ["anlage", "elektroinstallation"];
  return [];
};

export const getDefaultExtraCharge = (project, session) => {
  if (project.resourcetype === "PlantProject")
    return session.user.default_extra_charge;
  if (project.resourcetype === "HeatpumpProject")
    return session.user.default_extra_charge;
  return 0;
};

export const getOfferEmailSubject = (project, session) => {
  if (project.resourcetype === "PlantProject")
    return `Angebot für Ihre PV-Anlage ${project.street_and_number_project}`;
  if (project.resourcetype === "HeatpumpProject")
    return `Angebot für Ihre Wärmepumpe ${project.street_and_number_project}`;
  else return `Ihr Angebot von ${session.companyConfig.name}`;
};

export const getInvoiceEmailSubject = (project, session) => {
  if (project.resourcetype === "PlantProject")
    return `Rechnung für Ihre PV-Anlage ${project.street_and_number_project}`;
  if (project.resourcetype === "HeatpumpProject")
    return `Rechnung für Ihre Wärmepumpe ${project.street_and_number_project}`;
  else return `Ihre Rechnung von ${session.companyConfig.name}`;
};

export const getAcceptedOfferEmailSubject = (project, session) => {
  if (project.resourcetype === "PlantProject")
    return `Ihre PV-Anlage ${project.street_and_number_project}`;
  if (project.resourcetype === "HeatpumpProject")
    return `Ihre Wärmepumpe ${project.street_and_number_project}`;
  else return `Ihr Angebot von ${session.companyConfig.name}`;
};

export const getExtraOfferActions = (project, offer, session) => {
  if (project.resourcetype === "PlantProject") {
    return [
      offer.sent && offer.accepted ? (
        <UploadMap
          key={"extra-offer-actions-upload-map"}
          project={project}
          offer={offer}
          session={session}
        />
      ) : null,
    ];
  } else return null;
};

export const getPostAcceptedOfferRequest = (project) => {
  if (project.resourcetype === "PlantProject")
    return axios.put(API_URL_GROWATTACCOUNT, { project: project.id });
  else return Promise.resolve();
};

export const projectTypes = [
  { key: "PlantProject", name: "PV-Anlage", color: "#e6e227" },
  { key: "HeatpumpProject", name: "Wärmepumpe", color: "#12752c" },
  { key: "BlankOfferProject", name: "Blanko-Angebot", color: "#7b8fb5" },
  { key: "BlankInvoiceProject", name: "Blanko-Rechnung", color: "#8cbf82" },
  { key: "RoofProject", name: "Dach", color: "#753309" },
];

export const getProjectName = (project) => {
  if (project.resourcetype === "PlantProject")
    return `PV-Anlage ${project.street_and_number_project}`;
  if (project.resourcetype === "HeatpumpProject")
    return `Wärmepumpe ${project.street_and_number_project}`;
  if (project.resourcetype === "RoofProject")
    return `Dach ${project.street_and_number_project}`;
  return null;
};

export const getProjectType = (project) => {
  const projectType = projectTypes.find((t) => project.resourcetype === t.key);
  return projectType ? projectType.name : null;
};

export const useDefaultExtraCharge = (project) => {
  if (["PlantProject", "HeatpumpProject"].includes(project.resourcetype))
    return true;
  return false;
};

export const sendReferralEmail = (project) => {
  if (["PlantProject", "HeatpumpProject"].includes(project.resourcetype))
    return true;
  return false;
};

export const getFixedProductKeysForOffer = (project) => {
  if (project.resourcetype === "PlantProject") {
    return [
      "anlage",
      "pv_module",
      "anlage",
      "elektroinstallation",
      "batterie",
      "batterie_kwh",
      "neuer_schrank",
      "neuer_grosser_schrank",
      "aufruestung_e_auto",
      "wallbox",
      "kunde_setzt_selber",
      "keine_pfannen",
      "notstromdose",
      "notstromhaus",
      // 'zusatz_wr_13', 'zusatz_wr_17', 'zusatz_wr',
      "zusammenlegung_zaehlerkreise",
      "satellitenschuessel_versetzen",
      "spezial_montage_flachdach_gaube",
      "mieterstrom_messequipment",
      "mieterstrom_messequipment_pro_mieter",
      "mieterstrom_messequipment",
      "wandlermessung",
      "wandlermessung_kaskade",
      "weitere_wandlermessung",
      "na_schutz",
    ];
  }
  if (project.resourcetype === "HeatpumpProject") {
    return [/waermepumpe_*/g];
  }
  return null;
};

export const getRegularProjectUpdate = async (project) => {
  if (
    project &&
    project.resourcetype === "PlantProject" &&
    !project.voltavodata_obj
  ) {
    return axios
      .get(API_URL_VOLTAVODATA, {
        params: { project: project.id, nested: true },
      })
      .then((res) =>
        res.data.length > 0 ? { voltavodata_obj: res.data[0] } : null,
      );
  }

  return Promise.resolve();
};

export const getPlanningListFields = (project) => {
  if (project.resourcetype === "PlantProject")
    return [{ name: "kWp", key: "kwp" }];
  return [];
};

export const getConstructionListFields = (constructionResourceType) => {
  if (constructionResourceType === "PlantConstruction")
    return [
      {
        name: "Bauleiter",
        key: (c) => userWithLabel(c.construction_manager_labels),
      },
      { name: "Elektriker", key: (c) => getElectriciansName(c) },
      { name: "kWp", key: (c) => round(c.kwp, 2) },
    ];
  if (constructionResourceType === "HeatpumpConstruction")
    return [
      {
        name: "Heizungsinstallateur",
        key: (c) => userWithLabel(c.heating_installer_labels),
      },
    ];
  if (constructionResourceType === "RoofProjectConstruction")
    return [
      {
        name: "Bauleiter",
        key: (c) => userWithLabel(c.construction_manager_labels),
      },
    ];
  return [];
};

export const getConstructionName = (constructionResourceType) => {
  if (constructionResourceType === "PlantConstruction") return "PV-Anlage";
  if (constructionResourceType === "HeatpumpConstruction") return "Wärmepumpe";
  if (constructionResourceType === "RoofProjectConstruction") return "Dach";
  return "";
};

export const getConstructionFixedEmployeeIds = (construction) => {
  // if (construction.resourcetype === 'PlantConstruction') return [construction.construction_manager, ...(construction.electricians.map(e => e))]
  if (construction.resourcetype === "HeatpumpConstruction")
    return [construction.heating_installer];
  if (construction.resourcetype === "RoofProjectConstruction")
    return [construction.construction_manager];
  return "";
};

export const isTechnicalApprovalRequired = (project) => {
  // if (project.resourcetype === 'PlantProject') return true
  return false;
};

export const getTechnicalApprovalPermissionKey = (project) => {
  if (project.resourcetype === "PlantProject") return "pv_technical_approval";
  return null;
};

export const getProductGroupKeys = (project) => {
  if (project.resourcetype === "PlantProject") return ["pv"];
  if (project.resourcetype === "HeatpumpProject") return ["heating"];
  if (project.resourcetype === "RoofProject") return ["dach"];
  return [];
};

export const getConstructionDateColor = (constructions) => {
  const plantConstructions = constructions.filter(
    (c) => c.resourcetype === "PlantConstruction",
  );
  const nConstructions = plantConstructions.length;

  if (nConstructions <= 3) return "#661010"; // dark red
  if (nConstructions === 4) return "#a16c0a"; // yellow
  return "#12571b"; // dark green
};

export const getConstructionAmountDescriptions = (constructions) => {
  const desc = [];
  const plantConstructions = constructions.filter(
    (c) => c.resourcetype === "PlantConstruction",
  );
  if (plantConstructions.length > 0)
    desc.push(`PV-Anlagen: ${plantConstructions.length}`);
  const heatpumpConstructions = constructions.filter(
    (c) => c.resourcetype === "HeatpumpConstruction",
  );
  if (heatpumpConstructions.length > 0)
    desc.push(`Wärmepumpen: ${heatpumpConstructions.length}`);
  return desc;
};

export const getAdditionalOfferColsForScheduling = () => {
  return {
    PlantProject: [
      { label: "kWp", key: ["accepted_offer_obj", "planning_obj", "kwp"] },
    ],
  };
};

export const getAdditionalMapPopupDescription = (construction) => {
  if (construction.resourcetype === "PlantConstruction")
    return (
      <>
        <b>kWp:</b> {`${Math.round(construction.kwp * 100) / 100}`}
        <br />
      </>
    );
  return null;
};
