import React from "react";
import { eMeterActions } from "../../../../elements/utils";
import { eMeterPropType } from "../../../../elements/PropTypes";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";

export default function PlantProjectEMeterCard({ eMeter }) {
  return (
    <div
      className="p-3"
      style={{
        border: "1px solid black",
        borderRadius: "10px",
        maxWidth: "400px",
      }}
    >
      <Row>
        <Col xs={7}>
          <Typography className="secondary-textcolor" display="inline">
            Haben Sie einen Zweirichtungszähler?{" "}
          </Typography>
        </Col>
        <Col>
          <Typography className="secondary-textcolor" display="inline">
            {eMeter.bidirectional ? <>&#10004;</> : <>&#x2716;</>}
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col xs={7}>
          <Typography className="secondary-textcolor" display="inline">
            Zählernr.:{" "}
          </Typography>
        </Col>
        <Col>
          <Typography className="secondary-textcolor" display="inline">
            {eMeter.e_meter_id}
          </Typography>
        </Col>
      </Row>
      {eMeter.bidirectional ? (
        <>
          <Row>
            <Col xs={7}>
              <Typography className="secondary-textcolor" display="inline">
                Zählerstand 1.8.0:{" "}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {eMeter.value_180}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <Typography className="secondary-textcolor" display="inline">
                Zählerstand 2.8.0:{" "}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {eMeter.value_280}
              </Typography>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col xs={7}>
            <Typography className="secondary-textcolor" display="inline">
              Zählerstand:{" "}
            </Typography>
          </Col>
          <Col>
            <Typography className="secondary-textcolor" display="inline">
              {eMeter.value}
            </Typography>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={7}>
          <Typography className="secondary-textcolor" display="inline">
            Aktion:{" "}
          </Typography>
        </Col>
        <Col>
          <Typography className="secondary-textcolor" display="inline">
            {eMeter.action !== null
              ? eMeterActions.find((a) => a.value === eMeter.action).label
              : ""}
          </Typography>
        </Col>
      </Row>
    </div>
  );
}

PlantProjectEMeterCard.propTypes = {
  eMeter: eMeterPropType,
};
