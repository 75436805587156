import axios from "axios";
import { PropTypes } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import DropDown from "../../elements/DropDown";
import {
  customerToDoPropType,
  projectPropType,
} from "../../elements/PropTypes";
import { getCustomerName } from "../../elements/utils";
import { API_URL_TODO, API_URL_USER } from "../../settings";

export default function EditCustomerToDo({
  project,
  session,
  isOpen,
  setIsOpen,
  id,
  resetParent,
}) {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [visibleForCustomer, setVisibleForCustomer] = useState(false);
  const [customerAction, setCustomerAction] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, is_active: true, visible: true },
      })
      .then((res) => {
        setEmployees(res.data);
      });
  };

  const submit = () => {
    return axios.put(API_URL_TODO, {
      todo_id: id,
      name,
      description,
      visible_for_customer: visibleForCustomer,
      customer_action: customerAction,
      employee,
    });
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });
    return submit()
      .then(() => {
        resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "AddCustomerToDo:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const loadData = useCallback(() => {
    if (!project) return;
    const currData = project.customertodo_set.find((t) => t.id === id);
    if (currData) {
      setName(currData.name);
      setDescription(currData.description);
      setEmployee(currData.employee);
      setVisibleForCustomer(currData.visible_for_customer);
      setCustomerAction(currData.customer_action);
    }
  }, [id]);

  useEffect(() => {
    loadData();
  }, [id, loadData]);

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onCancel={toggle}
      />
    );
  };

  return (
    <CustomModal
      size="lg"
      getFooter={getFooter}
      title={"ToDo für " + getCustomerName(project.customer_obj)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <FormGroup>
        <Typography className="secondary-textcolor">ToDo-Titel</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="todo_name"
          value={name}
          autoComplete="off"
          onChange={(e) => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Beschreibung</Typography>
        <Input
          id="input"
          style={{ boxShadow: "none" }}
          type="text"
          name="todo_description"
          value={description}
          autoComplete="off"
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Mitarbeiter</Typography>
        <DropDown
          onChange={setEmployee}
          options={employees.map((user) => ({
            label: user.first_name + " " + user.last_name,
            value: user.id,
          }))}
          value={employee}
          text="Mitarbeiter wählen"
          sort={true}
          search={true}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={visibleForCustomer}
          className="secondary-textcolor"
          onChange={(e) => {
            setVisibleForCustomer(e.target.checked);
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label="Für den Kunden sichtbar"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={customerAction}
          className="secondary-textcolor"
          onChange={(e) => {
            setCustomerAction(e.target.checked);
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label="ToDo, das der Kunde erledigen muss"
        />
      </FormGroup>
    </CustomModal>
  );
}

EditCustomerToDo.propTypes = {
  project: projectPropType,
  parent: customerToDoPropType,
  prevToDo: customerToDoPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  id: PropTypes.number,
};
