import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";

import { Checkbox, Typography } from "@mui/material";
import { Table } from "react-bootstrap";
import { Form, Spinner } from "reactstrap";
import { groupPropType, userPropType } from "../../elements/PropTypes";
import { API_URL_COMMISSIONTYPE } from "../../settings";
import axios from "axios";
import LoadingPage from "../../elements/LoadingPage";
import { CustomButton } from "../../elements/StyledElements";
import { Stack } from "@mui/system";
import { swapItem } from "../../elements/utils";

export default function GroupCommissionSettings({
  groups,
  projectType,
  session,
}) {
  const [commissionTypes, setCommissionTypes] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  useEffect(() => {
    loadData();
  }, [projectType]);

  const loadData = () => {
    axios
      .get(API_URL_COMMISSIONTYPE, { params: { project_type: projectType } })
      .then((res) => {
        const commissionData = res.data;
        swapItem(
          commissionData,
          "Zusätzliche Optimierer",
          "Zusätzliche Batteriemodule",
        );
        setCommissionTypes(commissionData);
      });
  };

  const addGroup = (commissionTypeId, groupId) => {
    // update commission type
    setCommissionTypes((cts) => {
      const newCommissionTypes = [...cts];
      const commissionType = newCommissionTypes.find(
        (x) => x.id === commissionTypeId,
      );
      if (!commissionType.groups.includes(groupId))
        commissionType.groups.push(groupId);
      return newCommissionTypes;
    });
  };

  const removeGroup = (commissionTypeId, groupId) => {
    // update commission type
    setCommissionTypes((cts) => {
      const newCommissionTypes = [...cts];
      const commissionType = newCommissionTypes.find(
        (x) => x.id === commissionTypeId,
      );
      commissionType.groups = commissionType.groups.filter(
        (x) => x !== groupId,
      );
      return newCommissionTypes;
    });
  };

  const save = () => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    axios
      .put(API_URL_COMMISSIONTYPE, commissionTypes)
      .then(() =>
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        }),
      )
      .catch(() => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      })
      .then(() => {
        setCommissionTypes(null);
        loadData();
      });
  };

  if (!commissionTypes) return <LoadingPage />;
  const colWidth = `${Math.ceil(80 / commissionTypes.length)}%`;

  return (
    <Form>
      <Stack direction={"row"} spacing={1}>
        <CustomButton onClick={() => save()}>Speichern</CustomButton>
        {loadingElements.inProgress ? <Spinner size="sm" /> : null}
      </Stack>

      <Table>
        <thead>
          <tr>
            <th className="align-middle" style={{ width: "250px" }}>
              <Typography className="secondary-textcolor">Provision</Typography>
            </th>
            {commissionTypes.map((commissionType) => (
              <th
                style={{ width: colWidth }}
                key={`groupcommissions-header-commission-type-${commissionType.id}`}
              >
                <Typography className="secondary-textcolor">
                  {commissionType.name}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => (
            <tr key={`commission-type-group-${group.id}`}>
              <td>
                <Typography className="secondary-textcolor">
                  {group.name}
                </Typography>
              </td>
              {commissionTypes.map((commissionType) => (
                <td
                  key={`groupcommissions-header-group-${group.id}-commissiontype-${commissionType.id}`}
                >
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    checked={commissionType.groups.includes(group.id)}
                    onChange={(e) => {
                      if (e.target.checked)
                        addGroup(commissionType.id, group.id);
                      else removeGroup(commissionType.id, group.id);
                    }}
                    style={{ color: "#424242", backgroundColor: "transparent" }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Form>
  );
}

GroupCommissionSettings.propTypes = {
  employees: PropTypes.arrayOf(userPropType),
  groups: PropTypes.arrayOf(groupPropType),
  projectType: PropTypes.string,
  session: PropTypes.object,
};
