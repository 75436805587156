import React from "react";
import { PropTypes } from "prop-types";
import { Spinner, Fade } from "reactstrap";

import Button from "@mui/material/Button";

import { isString } from "../../../elements/utils";

export default function QuestionModalFooter({
  inProgress,
  submitError,
  onYes,
  onNo,
  yesBtnLabel = "Ja",
  noBtnLabel = "Nein",
  yesColor,
  noColor,
}) {
  return (
    <div>
      {submitError ? (
        <Fade
          in={!!submitError}
          tag="h5"
          className="mt-3"
          style={{ color: "red" }}
        >
          {isString(submitError)
            ? submitError
            : "Anfrage konnte nicht an Server übermittelt werden!"}
        </Fade>
      ) : null}
      {inProgress ? <Spinner color="danger" size="sm" /> : null}
      &nbsp;
      <Button
        variant="contained"
        color={yesColor || "success"}
        onClick={onYes}
        disabled={inProgress}
      >
        {yesBtnLabel}
      </Button>
      &nbsp;
      <Button
        variant="contained"
        color={noColor || "error"}
        onClick={onNo}
        disabled={inProgress}
      >
        {noBtnLabel}
      </Button>
    </div>
  );
}

QuestionModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  yesBtnLabel: PropTypes.string,
  noBtnLabel: PropTypes.string,
  yesColor: PropTypes.string,
  noColor: PropTypes.string,
};
