import React, { Fragment, useState, useCallback, useEffect, memo } from "react";

import { PropTypes } from "prop-types";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";

import AddTileDesktop from "./AddTileDesktop";
import CustomizedCustomerTile from "./CustomizedCustomerTile";
import { TILE_TYPES } from "./dashboard-utils";
import { CustomIconButton } from "../../elements/StyledElements";

const CardComponent = memo(
  ({ item, session, deleteTile, setSelectedItem, addTile }) => {
    if (item?._flag === "draft") {
      return <AddTileDesktop onClick={() => setSelectedItem(item)} />;
    }

    switch (item?.type) {
      case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
        return (
          <CustomizedCustomerTile
            {...{
              session,
              item,
              onEdit: () => setSelectedItem(item),
              isMobile: false,
              deleteTile,
            }}
          />
        );
      default:
        return null;
    }
  },
);

CardComponent.displayName = "CardComponent";

CardComponent.propTypes = {
  item: PropTypes.object,
  session: PropTypes.object,
  deleteTile: PropTypes.func,
  setSelectedItem: PropTypes.func,
  addTile: PropTypes.func,
};

export default function DashboardDesktop({
  session,
  dashboardTiles,
  setSelectedItem,
  deleteTile,
  addTile,
}) {
  const data = dashboardTiles || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const MAX_TILE_WIDTH = 600;

  const getItemsPerPage = useCallback(() => {
    const paddingWidth = 0;
    const windowWidth = window?.innerWidth || 0;

    if (windowWidth === 0) return 2 * 2;

    const cleanWidth = windowWidth - paddingWidth;
    const columns = Math.floor(cleanWidth / MAX_TILE_WIDTH);
    if (columns < 2) return 2 * 2;

    return columns * 2;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(getItemsPerPage());
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getItemsPerPage]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage >= totalPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  return (
    <Fragment>
      {session.user && (
        <Typography
          paddingTop={"10px"}
          className={"secondary-textcolor text-end"}
        >
          Angemeldet: {session.user.first_name} {session.user.last_name}{" "}
        </Typography>
      )}

      <div
        style={{
          margin: "1rem auto 0",
        }}
      >
        <div
          className="card-grid"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${Math.floor(itemsPerPage / 2)}, 1fr)`,
            gridTemplateRows: "repeat(2, 1fr)",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {currentItems.map((item) => (
            <Card
              key={JSON.stringify(item)}
              style={{
                padding: "5px",
                boxShadow:
                  "0px 2px 4px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                height: "calc(45vh - 2rem)",
                width: "100%",
              }}
            >
              <CardComponent
                item={item}
                session={session}
                deleteTile={deleteTile}
                setSelectedItem={setSelectedItem}
                addTile={addTile}
              />
            </Card>
          ))}
        </div>

        <div
          className="pagination"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomIconButton
              disabled={currentPage === 1}
              icon="previous"
              onClick={prevPage}
            />
            <Typography className="secondary-textcolor">
              {currentPage}
            </Typography>
            <CustomIconButton
              disabled={currentPage === totalPages}
              icon="next"
              onClick={nextPage}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

DashboardDesktop.propTypes = {
  session: PropTypes.object,
  dashboardTiles: PropTypes.array,
  setSelectedItem: PropTypes.func,
  deleteTile: PropTypes.func,
  addTile: PropTypes.func,
};
