/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Col, Container, Row, Input, FormGroup } from "reactstrap";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

// mui packages
import { Typography } from "@mui/material";

// files
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import CustomModal from "../shared/modal_utils/CustomModal";
import DropDown from "../../elements/DropDown";
import { CustomButton } from "../../elements/StyledElements";
import {
  arrSum,
  date2FormattedString,
  date2String,
  defaultIfEmpty,
  EVENT_TYPES,
  formattedString2Date,
  hasPermission,
  isNormalWorkingHoursEventType,
  string2Date,
} from "../../elements/utils";
import { API_URL_TIMEMANAGEMENTEVENT } from "../../settings";
import {
  timeManagementEventPropType,
  userPropType,
} from "../../elements/PropTypes";

const emptyTimeManagementEventForm = {
  start_time: "00:00",
  end_time: "00:00",
  start_date: null,
  end_date: null,
  duration: 0,
  event_type: 0,
};

export default function TimeManagementModal({
  events,
  timeManagementEvent,
  chosenDate,
  isOpen,
  setIsOpen,
  resetParent,
  employee,
  session,
}) {
  const [timeManagementEventForm, setTimeManagementEventForm] = useState({
    ...emptyTimeManagementEventForm,
    user: employee ? employee.id : session.user.id,
    ...timeManagementEvent,
    start_date: timeManagementEvent
      ? date2FormattedString(timeManagementEvent.start_date)
      : date2FormattedString(chosenDate),
    end_date: timeManagementEvent
      ? date2FormattedString(timeManagementEvent.end_date)
      : date2FormattedString(chosenDate),
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const onChange = (e) => {
    setTimeManagementEventForm({
      ...timeManagementEventForm,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const startdate = new Date(
      `${date2String(chosenDate)} ${timeManagementEventForm.start_time}`,
    );
    const enddate = new Date(
      `${date2String(chosenDate)} ${timeManagementEventForm.end_time}`,
    );
    const durationWithBreak = (enddate - startdate) / 36e5;
    const duration =
      durationWithBreak > 6 ? durationWithBreak - 0.75 : durationWithBreak;
    setTimeManagementEventForm((e) => ({ ...e, duration }));
  }, [timeManagementEventForm.start_time, timeManagementEventForm.end_time]);

  useEffect(() => {
    if (timeManagementEventForm.event_type !== 0) {
      setDefaultTime();
    }
  }, [timeManagementEventForm.event_type]);

  const moreThanOneMonth = (date) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    return date > currentDate;
  };

  const getEventsOverlappedByVacation = (vacation, eventArray) => {
    const vacationStart = new Date(vacation.start_date);
    const vacationEnd = new Date(vacation.end_date);

    return eventArray
      .filter((event) => {
        const eventStart = new Date(event.start_date);
        const eventEnd = new Date(event.end_date);
        if (
          (event.event_type === 2 ||
            (event.event_type === 1 &&
              hasPermission(session.user, "approve_vacation"))) &&
          event.event_type === vacation.event_type &&
          event.user === vacation.user &&
          event.id !== vacation.id &&
          event.approval
        ) {
          return eventStart >= vacationStart && eventEnd <= vacationEnd;
        }
        return false;
      })
      .map((event) => event.id);
  };

  const isEntryValid = () => {
    const start_date = formattedString2Date(timeManagementEventForm.start_date);
    const end_date = formattedString2Date(timeManagementEventForm.end_date);
    const allEvents = events.filter((e) => e.id !== timeManagementEvent?.id);
    const dayEvents = allEvents.filter((entry) => {
      const entryStartDate = string2Date(entry.start_date);
      const entryEndDate = string2Date(entry.end_date);
      const dateOverlap =
        (entryStartDate <= start_date && start_date <= entryEndDate) ||
        (entryStartDate <= end_date && end_date <= entryEndDate);
      return dateOverlap;
    });
    const { event_type, duration } = timeManagementEventForm;
    if (dayEvents.some((entry) => entry.event_type === event_type))
      return "Für diesen Tag existiert schon ein Eintrag.";
    if (dayEvents.some((entry) => entry.event_type !== 0))
      return "Für diesen Tag existiert schon ein ganztägiger Eintrag.";

    const sumHour = duration + arrSum(dayEvents.map((e) => e.duration));
    if (sumHour > 15)
      return "Die maximale Stundenanzahl des Tages ist überschritten.";

    if (
      timeManagementEvent &&
      (event_type === 1 || event_type === 2) &&
      !moreThanOneMonth(start_date)
    )
      return "Fehler: Das Startdatum muss mindestens 30 Tage in der Zukunft liegen.";

    return "";
  };

  const submit = async (timeManagementEventForm) => {
    if (
      timeManagementEventForm.event_type === 1 ||
      timeManagementEventForm.event_type === 2
    ) {
      const overlappedEvents = getEventsOverlappedByVacation(
        timeManagementEventForm,
        events,
      );
      Promise.all(
        overlappedEvents.map((overlappedEventId) =>
          axios.delete(`${API_URL_TIMEMANAGEMENTEVENT}${overlappedEventId}`),
        ),
      );
    }
    if (timeManagementEvent) {
      return axios.put(
        API_URL_TIMEMANAGEMENTEVENT + timeManagementEvent.id,
        timeManagementEventForm,
      );
    }
    return axios.post(API_URL_TIMEMANAGEMENTEVENT, timeManagementEventForm);
  };

  const onSubmit = async (onSuccess) => {
    const error = isEntryValid();
    if (error !== "") {
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: error,
      });
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit({
      ...timeManagementEventForm,
      end_date: date2String(
        formattedString2Date(timeManagementEventForm.end_date),
      ),
      start_date: date2String(
        formattedString2Date(timeManagementEventForm.start_date),
      ),
    })
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "AddTimeManagement:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const onDelete = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return axios
      .delete(API_URL_TIMEMANAGEMENTEVENT + timeManagementEvent.id)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "AddTimeManagement:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
        onDelete={
          timeManagementEvent
            ? function () {
                onDelete(toggle);
              }
            : null
        }
        disabled={timeManagementEventForm.duration <= 0}
      />
    );
  };

  const setDefaultTime = () => {
    const startdate = new Date(`${date2String(chosenDate)} 08:00`);
    const duration = session.user.default_working_hours;
    const durationWithBreak = duration > 6 ? duration + 0.75 : duration;
    const enddate = new Date(startdate.getTime() + durationWithBreak * 36e5);
    const hours = ("0" + enddate.getHours()).slice(-2);
    const minutes = ("0" + enddate.getMinutes()).slice(-2);
    const endtime = `${hours}:${minutes}`;
    setTimeManagementEventForm({
      ...timeManagementEventForm,
      start_time: "08:00",
      end_time: endtime,
    });
  };

  if (!chosenDate) return null;

  const title = employee
    ? "Zeiterfassung von " + employee.first_name + " " + employee.last_name
    : "Zeiterfassung";

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      getFooter={getFooter}
    >
      <Container>
        <Row>
          <Col>
            <FormGroup>
              <Typography className="secondary-textcolor">
                Erfassung von
              </Typography>
              <DropDown
                value={timeManagementEventForm.event_type}
                options={EVENT_TYPES}
                onChange={(value) => {
                  setTimeManagementEventForm((e) => ({
                    ...e,
                    event_type: value,
                    end_date: timeManagementEvent
                      ? value === 1 || value === 2
                        ? date2FormattedString(timeManagementEvent.end_date)
                        : e.start_date
                      : e.start_date,
                  }));
                }}
              />
              {isNormalWorkingHoursEventType(
                timeManagementEventForm.event_type,
              ) ? (
                <>
                  &nbsp;&nbsp;
                  <CustomButton onClick={setDefaultTime}>
                    {session.user.default_working_hours} Stunden
                  </CustomButton>{" "}
                </>
              ) : null}
            </FormGroup>

            <hr className="secondary-textcolor" />

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">
                {timeManagementEventForm.event_type === 1 ||
                timeManagementEventForm.event_type === 2
                  ? "Startdatum"
                  : "Datum"}{" "}
              </Typography>
              <Input
                id="input"
                type="text"
                name="start_date"
                value={timeManagementEventForm.start_date}
                autoComplete="off"
                disabled={
                  !(
                    (timeManagementEventForm.event_type === 1 ||
                      timeManagementEventForm.event_type === 2) &&
                    timeManagementEvent &&
                    moreThanOneMonth(
                      string2Date(timeManagementEvent.start_date),
                    )
                  )
                }
                style={{ boxShadow: "none" }}
                onChange={onChange}
              />
            </FormGroup>
            <Typography className="secondary-textcolor"></Typography>

            <hr className="secondary-textcolor" />

            {/* ############################################################## */}
            {isNormalWorkingHoursEventType(
              timeManagementEventForm.event_type,
            ) ? (
              <>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Startzeit:
                  </Typography>
                  <Input
                    id="input"
                    type="time"
                    name="start_time"
                    value={defaultIfEmpty(
                      timeManagementEventForm.start_time.slice(0, 5),
                    )}
                    autoComplete="off"
                    onChange={onChange}
                    style={{ boxShadow: "none" }}
                  />
                </FormGroup>
                <Typography className="secondary-textcolor"></Typography>

                <hr className="secondary-textcolor" />

                {/* ############################################################## */}

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Endzeit:
                  </Typography>
                  <Input
                    id="input"
                    type="time"
                    name="end_time"
                    value={defaultIfEmpty(
                      timeManagementEventForm.end_time.slice(0, 5),
                    )}
                    autoComplete="off"
                    onChange={onChange}
                    style={{ boxShadow: "none" }}
                  />
                </FormGroup>
              </>
            ) : null}
            {timeManagementEventForm.event_type === 1 ||
            timeManagementEventForm.event_type === 2 ? (
              <>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Enddatum:
                  </Typography>
                  <Input
                    id="input"
                    type="text"
                    name="end_date"
                    value={timeManagementEventForm.end_date}
                    autoComplete="off"
                    style={{ boxShadow: "none" }}
                    onChange={onChange}
                  />
                </FormGroup>
                <Typography className="secondary-textcolor"></Typography>
                <hr className="secondary-textcolor" />
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
}

TimeManagementModal.propTypes = {
  events: PropTypes.arrayOf(timeManagementEventPropType),
  timeManagementEvent: timeManagementEventPropType,
  chosenDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  employee: userPropType,
  session: PropTypes.object,
};
