import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import CollapsibleTable from "../../elements/CollapsibleTable";
import { CustomButton, CustomIconButton } from "../../elements/StyledElements";
import { API_URL_WAREHOUSEACTIVITY } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import { Stack, Typography } from "@mui/material";
import { isMobileOnly } from "react-device-detect";

export default function WarehouseActivities({ getOpenButton, session }) {
  const rowsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(0);
  const [nPages, setNPages] = useState(0);

  const [activities, setActivities] = useState([]);

  const onToggle = (isOpen) => {
    if (isOpen) loadData(0);
    else clearData();
  };

  const loadData = (newPage) => {
    axios
      .get(API_URL_WAREHOUSEACTIVITY, {
        params: {
          ascending_ids: false,
          batch_size: rowsPerPage,
          batch: newPage,
        },
      })
      .then((res) => {
        setNPages(res.headers.length);
        setCurrentPage(newPage);
        setActivities(res.data);
      });
  };

  const clearData = () => {
    setActivities([]);
  };

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton
        color="black"
        style={{
          margin: "8px 0",
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        onClick={toggle}
      >
        <Typography variant={isMobileOnly ? "body1" : "h6"}>
          Aktivitäten
        </Typography>
      </CustomButton>
    );
  };

  const columns = [
    {
      name: "Datum",
      key: "date",
    },
  ];

  columns.push(
    ...[
      {
        name: "Benutzer",
        key: "user",
      },
      {
        name: "Beschreibung",
        key: "description",
      },
      {
        name: "Detail",
        key: "details",
      },
    ],
  );

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title="Aktivitäten"
      onToggle={onToggle}
    >
      <CollapsibleTable
        collapsible={false}
        columns={columns}
        rows={activities.map((activity) => ({
          date: activity.date.slice(0, 10) + ", " + activity.date.slice(11, 19),
          description: activity.description,
          user: activity.user_name,
          details: activity.details,
        }))}
      />
      <Stack direction="row" spacing={2}>
        <CustomIconButton
          disabled={currentPage === 0}
          icon="previous"
          onClick={() => {
            loadData(currentPage - 1);
          }}
        />
        <Typography className="secondary-textcolor">
          {currentPage + 1}
        </Typography>
        <CustomIconButton
          disabled={currentPage + 1 >= nPages}
          icon="next"
          onClick={() => {
            loadData(currentPage + 1);
          }}
        />
      </Stack>
    </CustomModal>
  );
}

WarehouseActivities.propTypes = {
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
