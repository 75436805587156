import React from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";

import { Typography } from "@mui/material";

import { defaultIfEmpty } from "../../elements/utils";
import {
  customerPropType,
  warehouseProductPropType,
} from "../../elements/PropTypes";

export default function WarehouseProductTypeForm({
  customer,
  warehouseProductType,
  setWarehouseProductType,
  showMissingFields,
}) {
  const setWarehouseProductState = (update) => {
    setWarehouseProductType((prevWarehouseProductType) => ({
      ...prevWarehouseProductType,
      ...update,
    }));
  };

  const onChange = (e) => {
    setWarehouseProductState({ [e.target.name]: e.target.value });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup>
        <Typography className="secondary-textcolor">Kategoriename</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(warehouseProductType.name)}
          required={true}
          invalid={!!(showMissingFields && !warehouseProductType.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
    </Form>
  );
}

WarehouseProductTypeForm.propTypes = {
  customer: customerPropType,
  warehouseProductType: warehouseProductPropType,
  setWarehouseProductType: PropTypes.func,
  showMissingFields: PropTypes.bool,
};
