import React, { useState } from "react";

import { PropTypes } from "prop-types";
import ReactHtmlParser from "react-html-parser";

import { leadPropType } from "../../elements/PropTypes";
import { CustomIconButton } from "../../elements/StyledElements";
import CustomModal from "../shared/modal_utils/CustomModal";
import { getCustomerName } from "../../elements/utils";
import { Typography } from "@mui/material";
import PdfViewModal from "../shared/modal_utils/PdfViewModal";
import { PROTOCOL } from "../../settings";
import CollapsibleTable from "../../elements/CollapsibleTable";

export default function LeadMailModal({ lead, getOpenButton, session }) {
  const [chosenAttachment, setChosenAttachment] = useState(null);
  if (!lead || !lead.email_html) return;

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomIconButton
        description={"E-Mail"}
        icon={"mail"}
        onClick={toggle}
        size="medium"
      />
    );
  };

  const name = lead.name ? lead.name : getCustomerName(lead);

  return (
    <CustomModal
      title={`E-Mail Lead ${name}`}
      size="xl"
      getOpenButton={_getOpenButton}
    >
      <Typography fontWeight="bold" style={{ padding: "20px 0px" }}>
        Betreff: {lead.email_subject}
      </Typography>
      <hr className="secondary-textcolor" />
      {lead.leadattachment_set && lead.leadattachment_set.length > 0 ? (
        <>
          <CollapsibleTable
            columns={[
              {
                name: "Name",
                key: "name",
              },
            ]}
            rows={lead.leadattachment_set.map((att) => ({
              key: `${att.id}`,
              name: "Anhang.pdf",
              attachment: att,
              link: (row) => {
                setChosenAttachment(row.attachment);
              },
            }))}
            counter={false}
            collapsible={false}
            header={false}
            buttomLine={false}
          />
          <hr className="secondary-textcolor" />
        </>
      ) : null}
      {ReactHtmlParser(lead.email_html)}

      <PdfViewModal
        isOpen={!!chosenAttachment}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenAttachment(null);
        }}
        getOpenButton={() => {}}
        filepath={
          chosenAttachment && chosenAttachment.file
            ? chosenAttachment.file.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    </CustomModal>
  );
}

LeadMailModal.propTypes = {
  lead: leadPropType,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
