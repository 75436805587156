import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import { CustomButton } from "../../elements/StyledElements";
import CircleIcon from "@mui/icons-material/Circle";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Table } from "react-bootstrap";
import CustomModal from "../shared/modal_utils/CustomModal";
import { carPropType } from "../../elements/PropTypes";
import axios from "axios";
import {
  API_URL_VEHICLECHECKLIST,
  API_URL_VEHICLECHECKLISTITEM,
} from "../../settings";
import CarChecklistFormModal from "./CarChecklistFormModal";
import { carBrandModelWithLabel } from "../../elements/utils";

export default function CarChecklistModal({
  cars,
  vehicleLabels,
  resetParent,
  session,
}) {
  const [chosenChecklist, setChosenChecklist] = useState(null);
  const [checklists, setChecklists] = useState([]);
  const [carsState, setCarsState] = useState(cars);
  const [checklistChanges, setChecklistChanges] = useState([]);

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} style={{ float: "right" }} icon="checkbox">
        Checkliste
      </CustomButton>
    );
  };

  const resetVehicleChecklists = async () => {
    try {
      const response = await axios.get(API_URL_VEHICLECHECKLIST);
      setChecklists(response.data);
    } catch (error) {
      console.error(
        'Error in "CarChecklistModal:resetVehicleChecklists"',
        error,
        error.stack,
      );
    }
  };

  const resetChecklistParent = async () => {
    resetVehicleChecklists();
    resetParent();
  };

  useEffect(() => {
    resetVehicleChecklists();
    setCarsState(cars);
  }, [cars]);

  const handleCheckboxChange = (carId, checklistId, isChecked) => {
    setCarsState((prevState) => {
      return prevState.map((car) => {
        if (car.id === carId) {
          return {
            ...car,
            checklist_items: car.checklist_items.map((item) =>
              item.id === checklistId
                ? { ...item, completed: isChecked }
                : item,
            ),
          };
        }
        return car;
      });
    });

    trackChange(carId, checklistId, isChecked);
  };

  const trackChange = (carId, checklistId, isChecked) => {
    setChecklistChanges((prevChanges) => {
      const existingIndex = prevChanges.findIndex(
        (change) =>
          change.vehicle_id === carId && change.item_id === checklistId,
      );
      const newChange = {
        vehicle_id: carId,
        item_id: checklistId,
        completed: isChecked,
      };

      if (existingIndex >= 0) {
        const updatedChanges = [...prevChanges];
        updatedChanges[existingIndex] = newChange;
        return updatedChanges;
      } else {
        return [...prevChanges, newChange];
      }
    });
  };

  const saveChanges = async () => {
    if (checklistChanges.length > 0) {
      await axios.post(API_URL_VEHICLECHECKLISTITEM, {
        changes: checklistChanges,
      });
      resetParent();
      setChecklistChanges([]);
    }
  };

  return (
    <CustomModal size="md" getOpenButton={getOpenButton} title="Checkliste">
      <Table className={"table-hover"}>
        <thead>
          <tr>
            <th
              className="align-middle"
              style={{ width: "5%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor"></Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "25%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Fahrzeug</Typography>
            </th>
            {checklists.length > 0 &&
              checklists.map((checklist) => {
                return (
                  <th
                    key={checklist.id}
                    className="align-middle"
                    style={{
                      width: "20%",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                    onClick={() => setChosenChecklist(checklist)}
                  >
                    <Typography className="secondary-textcolor">
                      {checklist.name}
                    </Typography>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {carsState.map((car) => (
            <tr key={`car-${car.id}`}>
              <td className="align-middle">
                <Typography className="secondary-textcolor">
                  <CircleIcon sx={{ color: car.color }} />
                </Typography>
              </td>
              <td className="align-middle">{carBrandModelWithLabel(car)}</td>
              {car.checklist_items.length > 0 &&
                car.checklist_items.map((item) => {
                  return (
                    <td key={item.checklist} className="align-middle">
                      <FormControlLabel
                        checked={item.completed}
                        className="secondary-textcolor"
                        onChange={(e) =>
                          handleCheckboxChange(
                            car.id,
                            item.id,
                            e.target.checked,
                          )
                        }
                        control={
                          <Checkbox
                            disableFocusRipple
                            disableRipple
                            style={{
                              color: "#424242",
                              backgroundColor: "transparent",
                            }}
                          />
                        }
                      />
                    </td>
                  );
                })}
            </tr>
          ))}
        </tbody>
      </Table>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <CustomButton
          onClick={saveChanges}
          disabled={checklistChanges.length === 0}
        >
          Speichern
        </CustomButton>
        <CarChecklistFormModal
          getOpenButton={(toggle) => (
            <CustomButton onClick={toggle} icon="add">
              Checkliste hinzufügen
            </CustomButton>
          )}
          resetParent={resetChecklistParent}
          session={session}
        />
        <CarChecklistFormModal
          checklist={chosenChecklist}
          isOpen={!!chosenChecklist}
          setIsOpen={(isOpen) => {
            if (!isOpen) setChosenChecklist(null);
          }}
          resetParent={resetChecklistParent}
          session={session}
        />
      </Stack>
    </CustomModal>
  );
}

CarChecklistModal.propTypes = {
  cars: PropTypes.arrayOf(carPropType),
  vehicleLabels: PropTypes.arrayOf(PropTypes.object),
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
