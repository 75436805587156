import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { projectPropType } from "../../../../elements/PropTypes";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  convertToFormData,
  filterObj,
  getEmptyFieldsError,
  isString,
} from "../../../../elements/utils";
import { API_URL_HEATERLABEL, CAPI_URL_PROJECT } from "../../../../settings";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import {
  checkIfHeatpumpProjectFieldIsEmpty,
  emptyHeatpumpProjectForm,
} from "./forms";
import { getEmptyProjectForm } from "../projectUtils";
import HeatpumpProjectForm from "./HeatpumpProjectForm";

export default function HeatpumpProjectFormModal({
  project,
  disable,
  showAllMissingFields,
  resetParent,
  session,
}) {
  const [projectForm, setProjectForm] = useState({
    ...emptyHeatpumpProjectForm,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setProjectForm({ ...emptyHeatpumpProjectForm });
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    setProjectForm({ ...project });
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} disabled={disable}>
        Ändern
      </CustomButton>
    );
  };

  const isNewObj = (obj) => obj.id <= 0;

  const getImagePromises = async (projectId, images, url, getPromise) => {
    const deletePromise = project.id
      ? axios.delete(url, {
          data: {
            project: projectId,
            except_ids: images.filter((i) => !isNewObj(i)).map((i) => i.id),
          },
        })
      : Promise.resolve();
    return deletePromise.then(() =>
      Promise.all(images.map((image) => getPromise(projectId, image, url))),
    );
  };

  const getImagePromise = async (projectId, image, url) => {
    const formData = new FormData();
    if (!isString(image.image)) {
      formData.append("image", image.image, image.image.name);
    } else return Promise.resolve();
    formData.append("project", projectId);
    if (image.id < 0) return axios.post(url, formData);
    formData.append("id", image.id);
    return axios.put(url + image.id, formData);
  };

  const submit = async (projectForm) => {
    const emptyProjectForm = getEmptyProjectForm(project);
    return Promise.all([
      axios.put(
        CAPI_URL_PROJECT + project.id,
        convertToFormData(
          filterObj(projectForm, Object.keys(emptyProjectForm)),
          ["accept_mandate_document", "accept_mandate_signature"],
          ["labelcurrentheater_set"],
        ),
      ),
      getImagePromises(
        project.id,
        projectForm.labelcurrentheater_set,
        API_URL_HEATERLABEL,
        getImagePromise,
      ),
    ]);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const additionalOptionalKeys = [];
    const emptyFieldsError = getEmptyFieldsError(
      projectForm,
      emptyHeatpumpProjectForm,
      additionalOptionalKeys,
      checkIfHeatpumpProjectFieldIsEmpty,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        showMissingFields: true,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    return submit(projectForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
          showMissingFields: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="customer-view-submit-project-form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={getOpenButton}
      title="Projektinformationen"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <HeatpumpProjectForm
        project={projectForm}
        setProject={setProjectForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        showAllMissingFields={showAllMissingFields}
      />
    </CustomModal>
  );
}

HeatpumpProjectFormModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
