import React, { useState } from "react";
import axios from "axios";
import { PropTypes } from "prop-types";

import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { API_URL_COPYPLANNING } from "../../settings";
import { planningPropType } from "../../elements/PropTypes";
import ErrorMessage from "../../elements/ErrorMessage";
import { getErrorMessage } from "../../elements/utils";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

export default function PlanningCopy({ planning, resetParent, session }) {
  const [loading, setLoading] = useState(false);

  const copyPlanning = async () => {
    setLoading(true);

    try {
      await axios
        .post(API_URL_COPYPLANNING, { planning: planning.id })
        .then(resetParent);
    } catch (error) {
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <Spinner color="dark" size="md" style={{ verticalAlign: "middle" }} />
    );
  }

  return (
    <Tooltip title="Kopieren" PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={copyPlanning}
      >
        <ContentCopyIcon className="secondary-textcolor" fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
PlanningCopy.propTypes = {
  planning: planningPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
