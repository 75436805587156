import React, { useState, useEffect, Fragment } from "react";
import { PropTypes } from "prop-types";
import { Table, Container, FormGroup, Row } from "reactstrap";
import { isMobileOnly } from "react-device-detect";

import axios from "axios";

import { Typography } from "@mui/material";

import { DAPI_URL_APPSTATS } from "../settings";
import CustomPlot from "../elements/Plot";
import DropDown from "../elements/DropDown";
import {
  arrange,
  numFormatter,
  SHORT_MONTHS,
  COLORS,
  THIS_YEAR,
  arrSum,
} from "../elements/utils";
import LoadingPage from "../elements/LoadingPage";

export default function AppStatistics({ session }) {
  const [year, setYear] = useState(THIS_YEAR);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (year === null) return;
    axios.get(DAPI_URL_APPSTATS, { params: { year } }).then((res) => {
      setData(res.data);
    });
  }, [year]);

  if (!data) return <LoadingPage />;

  return (
    <Fragment>
      <Container>
        <Row>
          <FormGroup>
            <Typography className="secondary-textcolor">
              Jahr wählen:
            </Typography>
            <DropDown
              onChange={setYear}
              options={[THIS_YEAR, THIS_YEAR - 1]}
              value={year}
              text="Jahr"
            />
          </FormGroup>
          <hr className="secondary-textcolor" />
        </Row>
        {data ? (
          <Row>
            <Typography
              className="secondary-textcolor"
              sx={{ fontWeight: "bold" }}
            >
              Jahresübersicht:
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th key="table-header-first-col" className="align-middle">
                    <Typography className="secondary-textcolor"></Typography>
                  </th>
                  {SHORT_MONTHS.map((m, mIdx) => (
                    <th
                      key={`table-header-month-${mIdx}`}
                      className="align-middle"
                    >
                      <Typography className="secondary-textcolor">
                        {m}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      className="secondary-textcolor"
                      sx={{ fontWeight: "bold" }}
                    >
                      Anzahl
                    </Typography>
                  </td>
                  {arrange(0, 12).map((mIdx) => (
                    <td key={`table-empty-header-month-${mIdx}`}></td>
                  ))}
                </tr>
                {data.map((category, cIdx) => (
                  <tr key={"category-cidx-count"}>
                    <td className="align-middle">
                      <Typography className="secondary-textcolor">
                        {category.name}
                      </Typography>
                    </td>
                    {arrange(0, 12).map((m, mIdx) => (
                      <td
                        key={`count-row-${cIdx}-col-${mIdx}`}
                        className="align-middle"
                      >
                        <Typography className="secondary-textcolor">
                          {category.count[m]}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  <td>
                    <Typography
                      className="secondary-textcolor"
                      sx={{ fontWeight: "bold" }}
                    >
                      Gewinn in €
                    </Typography>
                  </td>
                  {arrange(0, 12).map((mIdx) => (
                    <td key={`table-empty-header-month-${mIdx}`}></td>
                  ))}
                </tr>
                {data.map((category, cIdx) => (
                  <tr key={"category-cidx-revenue"}>
                    <td className="align-middle">
                      <Typography className="secondary-textcolor">
                        {category.name}
                      </Typography>
                    </td>
                    {arrange(0, 12).map((m, mIdx) => (
                      <td
                        key={`revenue-row-${cIdx}-col-${mIdx}`}
                        className="align-middle"
                      >
                        <Typography className="secondary-textcolor">
                          {numFormatter(category.revenue[m])}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                ))}
                <tr key={"total-revenue"}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      Gesamt
                    </Typography>
                  </td>
                  {arrange(0, 12).map((m, mIdx) => (
                    <td
                      key={`revenue-row-total-col-${mIdx}`}
                      className="align-middle"
                    >
                      <Typography className="secondary-textcolor">
                        {numFormatter(
                          arrSum(data.map((category) => category.revenue[m])),
                        )}
                      </Typography>
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
            <br />
            <CustomPlot
              title={"Verkauf " + year}
              xlabel={"Monat"}
              ylabel={"Anzahl"}
              ticks={SHORT_MONTHS}
              stacked={false}
              datasets={data.map((category, cIdx) => ({
                label: category.name,
                data: category.count,
                borderColor: COLORS[cIdx],
                backgroundColor: COLORS[cIdx],
              }))}
              type={"bar"}
              height={isMobileOnly ? 200 : 120}
            />
            <br />
            <CustomPlot
              title={"Gewinn " + year}
              xlabel={"Monat"}
              ylabel={"Umsatz"}
              ticks={SHORT_MONTHS}
              stacked={false}
              datasets={data.map((category, cIdx) => ({
                label: category.name,
                data: category.revenue,
                borderColor: COLORS[cIdx],
                backgroundColor: COLORS[cIdx],
              }))}
              type={"bar"}
              height={isMobileOnly ? 200 : 120}
            />
          </Row>
        ) : null}
      </Container>
    </Fragment>
  );
}

AppStatistics.propTypes = {
  session: PropTypes.object,
};
