import React, { createContext, useState, useContext } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { API_URL_CUSTOMER } from "../settings";
import { useHistory } from "react-router-dom";

const CustomersContext = createContext();

export const useCustomersContext = () => useContext(CustomersContext);

export const CustomersProvider = ({ children }) => {
  const [customers, setCustomers] = useState([]);
  const [curIndex, setCurIndex] = useState(null);
  const [params, setParams] = useState({});
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);

  const batchSize = 50;
  const history = useHistory();

  const fetchCustomers = async (passedParams = {}) => {
    console.trace();
    const sentParams = {
      ...params,
      ...passedParams,
      batch_size: batchSize,
      batch,
    };

    try {
      const res = await axios.get(API_URL_CUSTOMER, { params: sentParams });

      setCustomers(res.data);
      setParams(sentParams);
      setNPages(res.headers.length);

      return {
        customers: res.data,
        params: sentParams,
        nPages: res.headers.length,
      };
    } catch (error) {
      console.error("Failed to fetch customers", error);
      return {
        customers: [],
        params: sentParams,
        nPages: nPages || 1,
      };
    }
  };

  const disabled = customers.length === 0 || curIndex == null;

  const nextCustomer = async () => {
    let tmpResult = { customers, nPages, params };

    if (disabled) {
      const result = await fetchCustomers({ nested: true });
      tmpResult = { ...tmpResult, ...result };
    }

    const nextIndex = curIndex == null ? 0 : curIndex + 1;

    if (nextIndex < tmpResult.customers.length) {
      setCurIndex(nextIndex);
      const nextCustomer = tmpResult.customers[nextIndex];
      history.push(`/kunde/${nextCustomer.id}`);
      return;
    }

    if (batch + 1 < tmpResult.nPages) {
      setBatch(batch + 1);
      const result = await fetchCustomers({ batch: batch + 1, nexted: true });
      tmpResult = { ...tmpResult, ...result };
      setCurIndex(0);
      if (result.customers.length > 0) {
        history.push(`/kunde/${result.customers[0].id}`);
      }
      return;
    }

    setBatch(0);
    const result = await fetchCustomers({ batch: 0, nexted: true });
    tmpResult = { ...tmpResult, ...result };
    setCurIndex(0);
    if (result.customers.length > 0) {
      history.push(`/kunde/${result.customers[0].id}`);
    }
  };

  return (
    <CustomersContext.Provider
      value={{
        customers,
        setCustomers,
        nPages,
        setNPages,
        setCurIndex,
        batch,
        setBatch,
        fetchCustomers,
        nextCustomer,
        disabled,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

CustomersProvider.propTypes = {
  children: PropTypes.object,
};
