import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Input, FormGroup } from "reactstrap";
import axios from "axios";

import Add from "@mui/icons-material/Add";
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";

import DropDown from "../../elements/DropDown";
import { API_URL_TODO, API_URL_USER } from "../../settings";
import { getCustomerName } from "../../elements/utils";
import {
  customerToDoPropType,
  projectPropType,
} from "../../elements/PropTypes";

export default function AddCustomerToDo({
  project,
  parent,
  prevToDo,
  resetParent,
  session,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibleForCustomer, setVisibleForCustomer] = useState(false);
  const [customerAction, setCustomerAction] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, is_active: true, visible: true },
      })
      .then((res) => {
        setEmployees(res.data);
      });
  };

  const submit = () => {
    return axios.post(API_URL_TODO, {
      project: project.id,
      name,
      description,
      visible_for_customer: visibleForCustomer,
      prev_todo: prevToDo.id,
      customer_action: customerAction,
      parent: parent.id,
      employee,
    });
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });
    return submit()
      .then(() => {
        resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "AddCustomerToDo:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const loadData = () => {};

  const clearData = () => {
    setName("");
    setDescription("");
    setVisibleForCustomer(true);
    setCustomerAction(false);
  };

  const getOpenButton = (toggle) => {
    return (
      <div>
        <IconButton
          size="small"
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={toggle}
        >
          <Add style={{ color: "#424242" }} fontSize="small" />
          <Typography className="secondary-textcolor">
            To Do hinzufügen
          </Typography>
        </IconButton>
      </div>
    );
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onCancel={toggle}
      />
    );
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      title={"ToDo für " + getCustomerName(project.customer_obj)}
      onToggle={onToggle}
    >
      <FormGroup>
        <Typography className="secondary-textcolor">ToDo-Titel</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="todo_name"
          value={name}
          autoComplete="off"
          onChange={(e) => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Beschreibung</Typography>
        <Input
          id="input"
          style={{ boxShadow: "none" }}
          type="text"
          name="todo_description"
          value={description}
          autoComplete="off"
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Mitarbeiter</Typography>
        <DropDown
          onChange={setEmployee}
          options={employees.map((user) => ({
            label: user.first_name + " " + user.last_name,
            value: user.id,
          }))}
          value={employee}
          text="Mitarbeiter wählen"
          sort={true}
          search={true}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={visibleForCustomer}
          className="secondary-textcolor"
          onChange={(e) => {
            setVisibleForCustomer(e.target.checked);
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label="Für den Kunden sichtbar"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={customerAction}
          className="secondary-textcolor"
          onChange={(e) => {
            setCustomerAction(e.target.checked);
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label="ToDo, das der Kunde erledigen muss"
        />
      </FormGroup>
    </CustomModal>
  );
}

AddCustomerToDo.propTypes = {
  project: projectPropType,
  parent: customerToDoPropType,
  prevToDo: customerToDoPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
