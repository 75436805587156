import React, { useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Table } from "react-bootstrap";
import { Col, Container, Row } from "reactstrap";

import { Typography, IconButton } from "@mui/material";

import { date2String, EVENT_TYPES } from "../../elements/utils";
import { API_URL_TIMEMANAGEMENTEVENT } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import Icon from "../../elements/Icon";
import { userPropType } from "../../elements/PropTypes";

export default function DayOffEmployeeModal({ date, employees, isOpen }) {
  const [dayOffEmployees, setDayOffEmployees] = useState([]);
  const [dayOffEvents, setDayOffEvents] = useState([]);

  useEffect(() => {
    if (date && isOpen && employees) {
      axios
        .get(API_URL_TIMEMANAGEMENTEVENT, {
          params: { date: date2String(date) },
        })
        .then((res) => res.data)
        .then((events) => {
          const dayoffEvents = events.filter((e) => e.event_type !== 0);
          const dayOffEmployees = employees
            .filter((user) =>
              ["construction_managers", "electricians", "installers"].includes(
                user.group_key,
              ),
            )
            .filter((employee) =>
              dayoffEvents.map((e) => e.user).includes(employee.id),
            );
          setDayOffEvents(dayoffEvents);
          setDayOffEmployees(dayOffEmployees);
        });
    }
  }, [isOpen, date, employees]);

  const getOpenButton = (toggle) => {
    if (dayOffEmployees.length === 0) return null;
    return (
      <IconButton
        size="small"
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        onClick={toggle}
      >
        <Icon icon="personoff" style={{ color: "#424242" }} fontSize="small" />
        <Typography className="secondary-textcolor">
          Abwesende Mitarbeiter
        </Typography>
      </IconButton>
    );
  };

  const getDayoffReason = (eventType) => {
    return EVENT_TYPES.find((d) => d.value === eventType);
  };

  return (
    <CustomModal
      title="Abwesende Mitarbeiter"
      getOpenButton={getOpenButton}
      size="lg"
    >
      <Container>
        <Row>
          <Col sm="10" md={{ size: 12, offset: -1 }}>
            <Table
              className={
                dayOffEmployees.length === 0 ? "table-not-hover" : "table-hover"
              }
            >
              <thead>
                <tr>
                  <th
                    className="align-middle"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Vorname
                    </Typography>
                  </th>
                  <th
                    className="align-middle"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Nachname
                    </Typography>
                  </th>
                  <th
                    className="align-middle"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Rolle
                    </Typography>
                  </th>
                  <th
                    className="align-middle"
                    style={{ width: "40%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Abwesenheitsgrund
                    </Typography>
                  </th>
                </tr>
              </thead>

              <tbody>
                {dayOffEmployees.map((employee) => {
                  return (
                    <tr key={`employee-${employee.id}`}>
                      <td
                        className="align-middle"
                        style={{ textAlign: "left" }}
                      >
                        <Typography className="secondary-textcolor">
                          {employee.first_name}
                        </Typography>
                      </td>
                      <td
                        className="align-middle"
                        style={{ textAlign: "left" }}
                      >
                        <Typography className="secondary-textcolor">
                          {employee.last_name}
                        </Typography>
                      </td>
                      <td
                        className="align-middle"
                        style={{ textAlign: "left" }}
                      >
                        <Typography className="secondary-textcolor">
                          {employee.group_obj ? employee.group_obj.name : ""}
                        </Typography>
                      </td>
                      <td
                        className="align-middle"
                        style={{ textAlign: "left" }}
                      >
                        <Typography className="secondary-textcolor">
                          {dayOffEvents
                            .filter((e) => e.user === employee.id)
                            .map((e) => getDayoffReason(e.event_type).label)
                            .join()}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
}

DayOffEmployeeModal.propTypes = {
  isOpen: PropTypes.bool,
  employees: PropTypes.arrayOf(userPropType),
  date: PropTypes.instanceOf(Date),
};
