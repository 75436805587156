import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import RoofImagePlanning from "./RoofImagePlanning";
import CustomModal from "../modal_utils/CustomModal";
import { CustomButton } from "../../../elements/StyledElements";
import DropDown from "../../../elements/DropDown";
import {
  modulePropType,
  panelPropType,
  stringPropType,
} from "../../../elements/PropTypes";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import { round } from "../../../elements/utils";
import ErrorMessage from "../../../elements/ErrorMessage";

export default function EditRoofImagePlanning({
  image,
  imageWidthM,
  module,
  panels,
  setPanels,
  angle,
  setAngle = null,
  direction,
  setDirection = null,
  flatRoof,
  setFlatRoof = null,
  strings,
  setStrings,
  winWidth,
  winHeight,
  setAll,
  roofName,
}) {
  const [currentStrings, setCurrentStrings] = useState([]);
  const [currentPanels, setCurrentPanels] = useState([]);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [currentDirection, setCurrentDirection] = useState(null);
  const [currentFlatRoof, setCurrentFlatRoof] = useState(false);

  useEffect(() => {
    setCurrentStrings(strings);
  }, [strings]);
  useEffect(() => {
    setCurrentPanels(panels);
  }, [panels]);
  useEffect(() => {
    setCurrentAngle(angle);
  }, [angle]);
  useEffect(() => {
    setCurrentDirection(direction);
  }, [direction]);
  useEffect(() => {
    setCurrentFlatRoof(flatRoof);
  }, [flatRoof]);

  const save = () => {
    if (
      currentFlatRoof &&
      currentDirection === "Süd" &&
      currentPanels.some((panel) => panel.vertical)
    ) {
      toast.error(
        <ErrorMessage message="Bei Flachdächern mit der Ausrichtung Süd können nur horizontale Module verwendet werden. Bitte zum Speichern alle vertikalen Module entfernen." />,
      );
      return false;
    }
    if (setAll) {
      setAll(
        currentPanels,
        currentStrings,
        currentAngle,
        currentDirection,
        currentFlatRoof,
      );
      return true;
    }
    setPanels(currentPanels);
    if (setStrings) setStrings(currentStrings);
    if (setAngle) setAngle(currentAngle);
    if (setDirection) setDirection(currentDirection);
    if (setFlatRoof) setFlatRoof(currentFlatRoof);
    return true;
  };

  const getOpenButton = (toggle) => (
    <CustomButton onClick={toggle}>Bearbeiten</CustomButton>
  );
  const getFooter = (toggle) => (
    <CustomButton
      onClick={() => {
        const isSuccess = save();
        if (isSuccess) {
          toggle();
        }
      }}
      color={"#ffffff"}
    >
      Speichern
    </CustomButton>
  );

  return (
    <CustomModal
      title="Dachfotos bearbeiten"
      size="fullscreen"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
    >
      <Stack direction="row" spacing={2}>
        <DropDown
          onChange={setCurrentDirection}
          text="Himmelsrichtung wählen"
          options={[
            "Nord",
            "Nord-Ost",
            "Ost",
            "Süd-Ost",
            "Süd",
            "Süd-West",
            "West",
            "Nord-West",
            "Ost/West",
          ]}
          value={currentDirection}
          disabled={!setDirection}
        />
        <DropDown
          onChange={setCurrentFlatRoof}
          text="Dachtyp wählen"
          options={[
            { value: false, label: "Satteldach" },
            { value: true, label: "Flachdach" },
          ]}
          value={currentFlatRoof}
          disabled={!setCurrentFlatRoof}
        />
      </Stack>
      <br />
      <RoofImagePlanning
        direction={currentDirection}
        image={image}
        module={module}
        imageWidthM={imageWidthM}
        panels={currentPanels}
        setPanels={setCurrentPanels}
        angle={currentAngle}
        setAngle={setAngle ? setCurrentAngle : null}
        strings={currentStrings}
        setStrings={setCurrentStrings}
        flatRoof={currentFlatRoof}
        maxWidth={round((95 / 100) * winWidth)}
        maxHeight={round((80 / 100) * winHeight)}
        roofName={roofName}
      />
    </CustomModal>
  );
}

EditRoofImagePlanning.propTypes = {
  image: PropTypes.string,
  imageWidthM: PropTypes.number,
  module: modulePropType,
  panels: PropTypes.arrayOf(panelPropType),
  setPanels: PropTypes.func,
  angle: PropTypes.number,
  setAngle: PropTypes.func,
  direction: PropTypes.string,
  setDirection: PropTypes.func,
  flatRoof: PropTypes.bool,
  setFlatRoof: PropTypes.func,
  strings: PropTypes.arrayOf(stringPropType),
  setStrings: PropTypes.func,
  winWidth: PropTypes.number,
  winHeight: PropTypes.number,
  setAll: PropTypes.func,
  roofName: PropTypes.string,
};
