import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Container, Row, FormGroup } from "reactstrap";
import { Table } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import axios from "axios";

import { Stack, Typography } from "@mui/material";

import LoadingPage from "../../elements/LoadingPage";
import DropDown from "../../elements/DropDown";
import CustomPlot from "../../elements/Plot";
import {
  numFormatter,
  MONTHS,
  SHORT_MONTHS,
  COLORS,
  THIS_YEAR,
  string2FormattedString,
  sortById,
  popFromArr,
  hasPermission,
} from "../../elements/utils";
import { getProjectType, projectTypes } from "../project_types/projectUtils";
import { CustomButton } from "../../elements/StyledElements";
import { API_URL_PROJECT } from "../../settings";

export default function CommissionOverview({
  permission,
  commissionUrl,
  monthlyCommissionUrl,
  title,
  session,
}) {
  const [year, setYear] = useState(THIS_YEAR);
  const [commissionTimespan, setCommissionTimespan] = useState(true);
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(
    hasPermission(session.user, permission) ? null : session.user.id,
  );
  const [commissionDataMonth, setCommissionDataMonth] = useState(null);
  const [comissionData, setCommissionData] = useState(null);
  const [month, setMonth] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [projectData, setProjectData] = useState(null);
  useEffect(() => {
    if (employee === null) setMonth(null);
    else if (month === null) {
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      if (commissionTimespan && date.getDate() >= 19)
        setMonth(currentMonth === 12 ? 1 : currentMonth + 1);
      else setMonth(currentMonth);
    }
  }, [employee]);

  useEffect(() => {
    if (year === null || commissionTimespan === null) return;
    axios
      .get(commissionUrl, {
        params: {
          year,
          commission_timespan: commissionTimespan,
          project_type: projectType && projectType.toLowerCase(),
        },
      })
      .then((res) => {
        setCommissionData(res.data);
        const _employees = res.data.map((x) => ({
          value: x.employee,
          label: x.employee_name,
        }));
        setEmployees(_employees);
      });
  }, [year, commissionTimespan, projectType]);

  useEffect(() => {
    if (!employee || !year || !month || commissionTimespan === null) {
      if (commissionDataMonth) setCommissionDataMonth(null);
      return;
    }
    axios
      .get(monthlyCommissionUrl, {
        params: {
          year,
          month,
          employee,
          commission_timespan: commissionTimespan,
          project_type: projectType && projectType.toLowerCase(),
        },
      })
      .then((res) => {
        setCommissionDataMonth(res.data);
      });
    axios
      .get(API_URL_PROJECT, { params: { employee, nested: true } })
      .then((res) => {
        setProjectData(res.data);
      });
  }, [employee, year, month, commissionTimespan, projectType]);

  const sortAccountings = (accountings) => {
    accountings = sortById(accountings);
    const newAccountings = [];
    let acc, cacc;
    while (accountings.length > 0) {
      acc = popFromArr(accountings);
      newAccountings.push(acc);
      if (acc.cancellation_accounting) {
        cacc = popFromArr(
          accountings,
          (a) => a.id === acc.cancellation_accounting,
        );
        newAccountings.push(cacc);
      }
    }
    return newAccountings;
  };

  if (!comissionData || !employees) return <LoadingPage />;

  const filteredData = !employee
    ? comissionData
    : comissionData.filter((data) => data.employee === employee);

  const toCSVcell = (str) => {
    if (str === null) {
      str = "";
    }
    str = `${str}`;

    str = str.replace(/"/g, '""');

    if (str.includes(",") || str.includes("\n") || /^\s+|\s+$/g.test(str)) {
      str = `"${str}"`;
    }
    return str;
  };

  const handleCSVExport = async () => {
    const filteredExportData = projectData.filter((p) =>
      commissionDataMonth.accountings.some((a) => a.project === p.id),
    );
    let strCSV =
      ",Name Mitarbeiter,Name Kunde,Adresse Kunde,Kundennummer,Projektname,kWp,kWh,Fertiggestels?,Datum Fertigstellung\n";
    for (let i = 0; i < filteredExportData.length; i++) {
      strCSV += "\r\n" + toCSVcell(i + 1);
      strCSV += "," + toCSVcell(filteredExportData[i].salesman_name);
      strCSV += "," + toCSVcell(filteredExportData[i].customer_obj.name);
      strCSV +=
        "," + toCSVcell(filteredExportData[i].customer_obj.street_and_number);
      strCSV += "," + toCSVcell(filteredExportData[i].customer_obj.id);
      strCSV += "," + toCSVcell(filteredExportData[i].name);
      strCSV +=
        "," +
        toCSVcell(
          getProjectType(filteredExportData[i]) === "PV-Anlage"
            ? filteredExportData[i].construction_obj.kwp
            : "",
        );
      strCSV +=
        "," +
        toCSVcell(
          getProjectType(filteredExportData[i]) === "PV-Anlage"
            ? filteredExportData[i].construction_obj.battery_kwh
            : "",
        );
      strCSV +=
        "," +
        toCSVcell(
          filteredExportData[i].construction_obj.completion_date
            ? "Ja"
            : "Nein",
        );
      strCSV +=
        "," +
        toCSVcell(
          filteredExportData[i].construction_obj.completion_date
            ? string2FormattedString(
                filteredExportData[i].construction_obj.completion_date,
              )
            : "",
        );
    }
    strCSV += "\r\n";
    const csvData = new Blob(["\uFEFF" + strCSV], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `Verkaufe ${filteredExportData[0].salesman_name}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Fragment>
      <Container>
        <Stack direction="row" spacing={2}>
          <>
            {hasPermission(session.user, permission) ? (
              <FormGroup style={{ flex: "1 1 auto" }}>
                <DropDown
                  onChange={setEmployee}
                  options={employees}
                  value={employee}
                  text="Mitarbeiter"
                  search={true}
                  style={{ width: "100%" }}
                />
              </FormGroup>
            ) : null}
            <FormGroup style={{ flex: "1 1 auto" }}>
              <DropDown
                onChange={setYear}
                options={[THIS_YEAR - 1, THIS_YEAR]}
                value={year}
                text="Jahr"
                search={true}
                disableClearable={true}
                style={{ width: "100%" }}
              />
            </FormGroup>
          </>
          {employee ? (
            <>
              <FormGroup style={{ flex: "1 1 auto" }}>
                <DropDown
                  onChange={setMonth}
                  options={MONTHS.map((m, idx) => ({
                    value: idx + 1,
                    label: m,
                  }))}
                  value={month}
                  text="Monat"
                  search={true}
                  style={{ width: "100%" }}
                />
              </FormGroup>
            </>
          ) : null}
          <>
            <FormGroup style={{ flex: "1 1 auto" }}>
              <DropDown
                onChange={setCommissionTimespan}
                options={[
                  { label: "1.-31.", value: false },
                  { label: "19.-18.", value: true },
                ]}
                value={commissionTimespan}
                text="Zeitraum"
                search={true}
                disableClearable={true}
                style={{ width: "100%" }}
              />
            </FormGroup>
          </>
          <>
            <FormGroup style={{ flex: "1 1 auto" }}>
              <DropDown
                onChange={setProjectType}
                options={projectTypes.map((p) => ({
                  value: p.key,
                  label: p.name,
                }))}
                value={projectType}
                text="Projekttyp"
                search={true}
                style={{ width: "100%" }}
              />
            </FormGroup>
          </>
          {employee ? (
            <>
              <FormGroup style={{ flex: "1 1 auto" }}>
                <CustomButton
                  onClick={handleCSVExport}
                  style={{ width: "100%" }}
                >
                  CSV Export
                </CustomButton>
              </FormGroup>
            </>
          ) : null}
        </Stack>
        <hr className="secondary-textcolor" />
        <Row>
          <Typography
            className="secondary-textcolor"
            sx={{ fontWeight: "bold" }}
          >
            Jahresübersicht Provision [€]:
          </Typography>
          <Table>
            <thead>
              <tr>
                <th key={"commission-header-salesmen"} className="align-middle">
                  <Typography className="secondary-textcolor">
                    Mitarbeiter (Verkäufe gesamt)
                  </Typography>
                </th>
                {SHORT_MONTHS.map((m, mIdx) => (
                  <th
                    key={`commission-header-month-${mIdx}`}
                    className="align-middle"
                  >
                    <Typography className="secondary-textcolor">{m}</Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, sIdx) => (
                <tr key={data.employee_name}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {data.employee_name} (
                      {data.total_projects ? data.total_projects : "0"})
                    </Typography>
                  </td>
                  {SHORT_MONTHS.map((m, mIdx) => (
                    <td
                      key={`commission-row-${sIdx}-col-${mIdx}`}
                      className="align-middle"
                    >
                      <Typography className="secondary-textcolor">
                        {numFormatter(data.accountings_per_month[mIdx])}
                      </Typography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
          <CustomPlot
            title={"Provision " + year}
            xlabel={"Monat"}
            ylabel={"Euro"}
            ticks={SHORT_MONTHS}
            datasets={filteredData.map((data, idx) => ({
              label: data.employee_name,
              data: data.accountings_per_month,
              borderColor: COLORS[idx % COLORS.length],
              backgroundColor: COLORS[idx % COLORS.length],
            }))}
            type={"bar"}
            height={isMobileOnly ? 200 : 120}
          />
          <hr className="secondary-textcolor" />
        </Row>
        {commissionDataMonth ? (
          <Row>
            <Typography
              className="secondary-textcolor"
              sx={{ fontWeight: "bold" }}
            >
              Übersicht Zahlungen {MONTHS[month - 1]}:
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Datum
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Zahlung
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Höhe
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortById(commissionDataMonth.accountings).map((accounting) => (
                  <tr key={`accounting_${accounting.id}`}>
                    <td className="align-middle">
                      <Typography className="secondary-textcolor">
                        {string2FormattedString(accounting.date)}
                      </Typography>
                    </td>
                    <td className="align-middle">
                      <Typography className="secondary-textcolor">
                        {accounting.name}
                      </Typography>
                    </td>
                    <td className="align-middle">
                      <Typography className="secondary-textcolor">
                        {numFormatter(accounting.amount)} €
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr className="secondary-textcolor" />
          </Row>
        ) : null}
        <Row>
          <Typography
            className="secondary-textcolor"
            sx={{ fontWeight: "bold" }}
          >
            Jahresübersicht {title}:
          </Typography>
          <Table>
            <thead>
              <tr>
                <th key={"plants-header-salesmen"} className="align-middle">
                  <Typography className="secondary-textcolor">
                    Mitarbeiter
                  </Typography>
                </th>
                {SHORT_MONTHS.map((m, mIdx) => (
                  <th
                    key={`plants-header-month-${mIdx}`}
                    className="align-middle"
                  >
                    <Typography className="secondary-textcolor">{m}</Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, sIdx) => (
                <tr key={data.employee_name}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {data.employee_name}
                    </Typography>
                  </td>
                  {SHORT_MONTHS.map((m, mIdx) => (
                    <td
                      key={`commission-row-${sIdx}-col-${mIdx}`}
                      className="align-middle"
                    >
                      <Typography className="secondary-textcolor">
                        {data.n_events_per_month[mIdx]}
                      </Typography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
          <CustomPlot
            title={title + " " + year}
            xlabel={"Monat"}
            ylabel={"Anzahl " + title}
            ticks={SHORT_MONTHS}
            datasets={filteredData.map((data, idx) => ({
              label: data.employee_name,
              data: data.n_events_per_month,
              borderColor: COLORS[idx % COLORS.length],
              backgroundColor: COLORS[idx % COLORS.length],
            }))}
            type={"bar"}
            height={isMobileOnly ? 200 : 120}
          />
          <hr className="secondary-textcolor" />
        </Row>
        {commissionDataMonth ? (
          <Row>
            <Typography
              className="secondary-textcolor"
              sx={{ fontWeight: "bold" }}
            >
              Übersicht {title} {MONTHS[month - 1]}:
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Datum
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Zahlung
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Höhe
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {commissionDataMonth.event_commissions.map((e, eIdx) => (
                  <>
                    <tr key={`event_${eIdx}`}>
                      <td colSpan="3" align="align-middle">
                        <Typography
                          fontWeight="bold"
                          className="secondary-textcolor"
                        >
                          {e.name}
                        </Typography>
                      </td>
                    </tr>
                    {sortAccountings(e.accountings).map((accounting) => {
                      const colorClassName =
                        accounting.cancellation ||
                        accounting.cancellation_accounting
                          ? "disable-textcolor"
                          : "secondary-textcolor";
                      return (
                        <tr key={`accounting_${accounting.id}`}>
                          <td className="align-middle">
                            <Typography className={colorClassName}>
                              {string2FormattedString(accounting.date)}
                            </Typography>
                          </td>
                          <td className="align-middle">
                            <Typography className={colorClassName}>
                              {accounting.name}
                            </Typography>
                          </td>
                          <td className="align-middle">
                            <Typography className={colorClassName}>
                              {numFormatter(accounting.amount)} €
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ))}
              </tbody>
            </Table>
            <hr className="secondary-textcolor" />
          </Row>
        ) : null}
      </Container>
    </Fragment>
  );
}

CommissionOverview.propTypes = {
  commissionUrl: PropTypes.string,
  monthlyCommissionUrl: PropTypes.string,
  title: PropTypes.string,
  permission: PropTypes.string,
  session: PropTypes.object,
};
