import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { Typography } from "@mui/material";

import CollapsibleTable from "../elements/CollapsibleTable";
import {
  getCustomerName,
  getCustomerOverview,
  hasPermission,
  projectNameWithLabel,
} from "../elements/utils";
import { API_URL_CREATEAPPACCESS, API_URL_CUSTOMER } from "../settings";
import LoadingPage from "./../elements/LoadingPage";
import { CustomButton, CustomIconButton } from "./../elements/StyledElements";
import CustomerFormModal from "./customers/CustomerFormModal";
import CustomerActivities from "./project/CustomerActivities";
import CustomerEmails from "./project/CustomerEmails";
import ProjectFormModal from "./project/ProjectFormModal";
import { getProjectType } from "./project_types/projectUtils";
import ConfirmationModal from "./shared/modal_utils/ConfirmationModal";
import ContactDenialModal from "./customers/ContactDenialModal";
import TextField from "../elements/TextField";
import TaskTable from "./customers/TaskTable";
import { useCustomersContext } from "../contexts/CustomersContext";

export default function Customer({ session }) {
  const history = useHistory();

  const [customer, setCustomer] = useState(null);

  const { id } = useParams();

  const { nextCustomer } = useCustomersContext();

  useEffect(() => {
    resetState();
  }, [id]);

  const resetState = () => {
    getCustomer();
  };

  const getCustomer = () => {
    axios
      .get(API_URL_CUSTOMER + id, { params: { nested: true } })
      .then((res) => {
        setCustomer(res.data);
      })
      .catch(() => history.push("/"));
  };

  if (!customer) return <LoadingPage />;

  document.title = `PV App - ${customer.name}`;

  const customerOverview = getCustomerOverview(customer, false);

  const customerInfo = (
    <CustomerFormModal
      customer={customer}
      resetParent={resetState}
      session={session}
    />
  );

  const appAccount = hasPermission(session.user, "customer_handling") ? (
    <ConfirmationModal
      title={
        customer.user
          ? "Die Zugangsdaten für '" +
            getCustomerName(customer) +
            "' zurücksetzen?"
          : "Die Zugangsdaten für '" +
            getCustomerName(customer) +
            "' erstellen?"
      }
      resetParent={resetState}
      confirm={() =>
        axios.post(API_URL_CREATEAPPACCESS, { customer: customer.id })
      }
      getOpenButton={(toggle) => (
        <CustomButton
          onClick={toggle}
          icon="openLock"
          style={{ width: "100%" }}
        >
          App-Zugang
        </CustomButton>
      )}
      btnLabel="Senden"
    />
  ) : null;

  const emailOverview = hasPermission(session.user, "customer_handling") ? (
    <CustomerEmails customer={customer} session={session} />
  ) : null;
  const activityOverview = hasPermission(session.user, "customer_handling") ? (
    <CustomerActivities customer={customer} session={session} />
  ) : null;

  const remove = hasPermission(session.user, "customer_delete") ? (
    <ConfirmationModal
      title={
        "Soll '" + getCustomerName(customer) + "' wirklich entfernt werden?"
      }
      resetParent={resetState}
      confirm={() => {
        axios
          .delete(API_URL_CUSTOMER + customer.id)
          .then(() => history.push("/kunden/"));
      }}
      getOpenButton={(toggle) => (
        <CustomButton
          onClick={toggle}
          icon="delete"
          iconClassName="IconDeleteButton"
        >
          Löschen
        </CustomButton>
      )}
    />
  ) : null;

  const projectTable = (
    <CollapsibleTable
      columns={[
        {
          name: "Name",
          key: "name",
        },
        {
          name: "Typ",
          key: "type",
        },
        {
          name: "Nächster Schritt",
          key: "todo",
        },
      ]}
      rows={customer.project_set.map((project) => ({
        key: `${project.id}`,
        name: projectNameWithLabel(project),
        type: getProjectType(project),
        link: `/projekt/${project.id}`,
        todo: project.open_todo_name,
      }))}
      counter={true}
      collapsible={false}
    />
  );

  const newProject = hasPermission(session.user, "customer_handling") ? (
    <ProjectFormModal
      customer={customer}
      resetParent={resetState}
      session={session}
    />
  ) : null;

  const denyContact =
    !customer.contact_denied &&
    hasPermission(session.user, "customer_handling") ? (
      <ContactDenialModal
        customer={customer}
        resetParent={resetState}
        session={session}
      />
    ) : null;

  if (isMobileOnly) {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col xs={10} style={{ wordBreak: "break-all" }}>
              {customerOverview}
            </Col>
            <Col xs={2} style={{ textAlign: "right" }}>
              <CustomIconButton
                icon="forward"
                size="large"
                onClick={() => nextCustomer()}
              />
            </Col>
          </Row>
          <Col
            className=" align-items-center justify-content-center"
            style={{ paddingLeft: 50, paddingRight: 50 }}
          >
            {hasPermission(session.user, "customer_handling") ? (
              <>
                <Row className="my-3">{customerInfo}</Row>
                <Row className="my-3">{appAccount}</Row>
                <Row className="my-3">{emailOverview}</Row>
                <Row className="my-3">{activityOverview}</Row>
                <hr className="secondary-textcolor" />
              </>
            ) : null}
            <Row>
              <TextField
                text={customer.note}
                save={(text) =>
                  axios
                    .put(API_URL_CUSTOMER + customer.id, { note: text })
                    .then(resetState)
                }
              />
            </Row>
            <hr className="secondary-textcolor" />
            <Row>{denyContact}</Row>
            {remove ? (
              <>
                <Row className="my-3">{remove}</Row>
              </>
            ) : null}
          </Col>
          <Col>
            <Typography
              className="secondary-textcolor"
              style={{ fontSize: 22 }}
            >
              Projekte
            </Typography>
            {projectTable}
            <div className="my-3" />
            {newProject}
            <div className="py-4" />
            <TaskTable customer={customer} session={session} />
          </Col>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <CustomButton
          startIcon={false}
          endIcon={true}
          icon="forward"
          onClick={() => nextCustomer()}
        >
          Nächster Kunde
        </CustomButton>
      </div>
      <Container>
        <Row>
          <Col className="col-4 my-3">
            <Row>{customerOverview}</Row>
            {hasPermission(session.user, "customer_handling") ? (
              <>
                <hr className="secondary-textcolor" />
                <Row>
                  <Col>
                    <Row className="px-2 my-3">{customerInfo}</Row>
                    <Row className="px-2 my-2">{emailOverview}</Row>
                  </Col>
                  <Col>
                    <Row className="px-2 my-3">{appAccount}</Row>
                    <Row className="px-2 my-2">{activityOverview}</Row>
                  </Col>
                </Row>
              </>
            ) : null}
            <hr className="secondary-textcolor" />
            <TextField
              text={customer.note}
              save={(text) =>
                axios
                  .put(API_URL_CUSTOMER + customer.id, { note: text })
                  .then(resetState)
              }
            />
            <hr className="secondary-textcolor" />
            <Row className="d-flex align-items-center justify-content-center">
              {remove ? (
                <Col className="d-flex align-items-center justify-content-center my-1">
                  {remove}
                </Col>
              ) : null}
              {denyContact ? (
                <Col className="d-flex align-items-center justify-content-center my-1">
                  {denyContact}
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col className="my-5">
            <Typography
              className="secondary-textcolor"
              style={{ fontSize: 22 }}
            >
              Projekte
            </Typography>
            <div className="my-3">{projectTable}</div>
            <div className="my-3">{newProject}</div>
            <div className="my-5">
              <TaskTable customer={customer} session={session} />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

Customer.propTypes = {
  session: PropTypes.object,
};
