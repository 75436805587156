import { PropTypes } from "prop-types";
import React, { Fragment } from "react";

import axios from "axios";

import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import DoneOutlined from "@mui/icons-material/DoneOutlined";
import ThumbUpOffAltOutlined from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownOffAltOutlined from "@mui/icons-material/ThumbDownOffAltOutlined";
// import ThumbDownOffAltOutlined from '@mui/icons-material/ThumbDownOffAltOutlined'
import FlagOutlined from "@mui/icons-material/FlagOutlined";
import Flag from "@mui/icons-material/Flag";

import { API_URL_ACCECTTASK, API_URL_TASK } from "../../settings";
import TaskFormModal from "./TaskFormModal";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import AddCommentModal from "./AddCommentModal";
import AddAttachmentModal from "./AddAttachmentModal";
import { taskPropType } from "../../elements/PropTypes";

import RejectTaskModal from "./RejectTaskModal";
import QuestionModal from "../shared/modal_utils/QuestionModal";
import ClearOutlined from "@mui/icons-material/ClearOutlined";

export const getEditTaskIcon = (toggle) => (
  <Tooltip title="Bearbeiten" PopperProps={{ style: { zIndex: 9999 } }}>
    <IconButton
      disableFocusRipple
      disableRipple
      style={{ backgroundColor: "transparent" }}
      size="small"
      onClick={toggle}
    >
      <EditOutlined className="secondary-textcolor" fontSize="medium" />
    </IconButton>
  </Tooltip>
);

export const getAddAttachmentIcon = (toggle) => (
  <Tooltip
    title={"Anhang hinzufügen"}
    PopperProps={{ style: { zIndex: 9999 } }}
  >
    <IconButton
      disableFocusRipple
      disableRipple
      style={{ backgroundColor: "transparent" }}
      size="small"
      onClick={toggle}
    >
      <AttachFileOutlinedIcon
        className="secondary-textcolor"
        fontSize="medium"
      />
    </IconButton>
  </Tooltip>
);

export const getAddCommentIcon = (toggle) => (
  <Tooltip
    title={"Kommentar hinzufügen"}
    PopperProps={{ style: { zIndex: 9999 } }}
  >
    <IconButton
      disableFocusRipple
      disableRipple
      style={{ backgroundColor: "transparent" }}
      size="small"
      onClick={toggle}
    >
      <ModeCommentOutlinedIcon
        className="secondary-textcolor"
        fontSize="medium"
      />
    </IconButton>
  </Tooltip>
);

export const getAddNextTaskIcon = (toggle) => (
  <Tooltip
    title={"Folgeaufgabe hinzufügen"}
    PopperProps={{ style: { zIndex: 9999 } }}
  >
    <IconButton
      disableFocusRipple
      disableRipple
      style={{ backgroundColor: "transparent" }}
      size="small"
      onClick={toggle}
    >
      <AddTaskOutlinedIcon className="secondary-textcolor" fontSize="medium" />
    </IconButton>
  </Tooltip>
);

export const getAddPreviousTaskIcon = (toggle) => (
  <Tooltip
    title={"Vorherige hinzufügen"}
    PopperProps={{ style: { zIndex: 9999 } }}
  >
    <IconButton
      disableFocusRipple
      disableRipple
      style={{ backgroundColor: "transparent" }}
      size="small"
      onClick={toggle}
    >
      <AddTaskOutlinedIcon className="secondary-textcolor" fontSize="medium" />
    </IconButton>
  </Tooltip>
);

const mobileIconButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid",
  borderRadius: 6,
  color: "black",
  width: "100%",
  justifyContent: "flex-start",
  gap: 6,
};

const getEditTaskIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <EditOutlined className="secondary-textcolor" fontSize="medium" />
    <Typography>Bearbeiten</Typography>
  </IconButton>
);

const getAddAttachmentIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <AttachFileOutlinedIcon className="secondary-textcolor" fontSize="medium" />
    <Typography>Anhang hinzufügen</Typography>
  </IconButton>
);

const getAddCommentIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <ModeCommentOutlinedIcon
      className="secondary-textcolor"
      fontSize="medium"
    />
    <Typography>Kommentar hinzufügen</Typography>
  </IconButton>
);

const getAcceptTaskIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <DoneOutlined className="secondary-textcolor" fontSize="medium" />
    <Typography>Bestätigen</Typography>
  </IconButton>
);

const getRejectTaskIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <ClearOutlined className="secondary-textcolor" fontSize="medium" />
    <Typography>Ablehnen</Typography>
  </IconButton>
);

const getAddNextTaskIconMobile = (toggle) => (
  <IconButton
    disableFocusRipple
    disableRipple
    style={mobileIconButtonStyle}
    size="small"
    onClick={toggle}
  >
    <AddTaskOutlinedIcon className="secondary-textcolor" fontSize="medium" />
    <Typography>Folgeaufgabe hinzufügen</Typography>
  </IconButton>
);

export function StartedAction({ task, resetParent, session, getOpenButton }) {
  const getOpenButtonDefault = (toggle) => (
    <Tooltip
      title={!task.started ? "Starten" : "Nicht gestartet"}
      PopperProps={{ style: { zIndex: 9999 } }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={toggle}
      >
        {
          <PlayCircleOutlineOutlinedIcon
            className="secondary-textcolor"
            fontSize="medium"
          />
        }{" "}
      </IconButton>
    </Tooltip>
  );

  return task.accepted && !task.started && !task.finished ? (
    <QuestionModal
      title={"Ist die Aufgabe bereits gestartet?"}
      resetParent={resetParent}
      clickYes={
        !task.started
          ? () => axios.put(API_URL_TASK + task.id, { started: true })
          : null
      }
      clickNo={
        task.started
          ? () => axios.put(API_URL_TASK + task.id, { started: false })
          : null
      }
      getOpenButton={getOpenButton || getOpenButtonDefault}
    />
  ) : null;
}

StartedAction.propTypes = {
  task: taskPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getOpenButton: PropTypes.func,
};

export function FinishedAction({ task, resetParent, session, getOpenButton }) {
  const getOpenButtonDefault = (toggle) => (
    <Tooltip
      title={!task.finished ? "Erledigen" : "Nicht Erledigen"}
      PopperProps={{ style: { zIndex: 9999 } }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={toggle}
      >
        {task.finished ? (
          <Flag className="secondary-textcolor" fontSize="medium" />
        ) : (
          <FlagOutlined className="secondary-textcolor" fontSize="medium" />
        )}{" "}
      </IconButton>
    </Tooltip>
  );

  return !task.finished ? (
    <QuestionModal
      title={"Ist die Aufgabe erledigt?"}
      resetParent={resetParent}
      clickYes={
        !task.finished
          ? () => axios.put(API_URL_TASK + task.id, { finished: true })
          : null
      }
      clickNo={
        task.finished
          ? () => axios.put(API_URL_TASK + task.id, { finished: false })
          : null
      }
      getOpenButton={getOpenButton || getOpenButtonDefault}
    />
  ) : null;
}

FinishedAction.propTypes = {
  task: taskPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getOpenButton: PropTypes.func,
};

export function AcceptAction({
  task,
  resetParent,
  isAcceptModalOpen,
  setIsAcceptModalOpen,
  isRejectModalOpen,
  setIsRejectModalOpen,
  session,
  getAcceptOpenButton,
  getRejectOpenButton,
}) {
  const getDefaultAcceptOpenButton = (toggle) => (
    <Tooltip title={"Bestätigen"} PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={toggle}
      >
        {<DoneOutlined className="secondary-textcolor" fontSize="medium" />}{" "}
      </IconButton>
    </Tooltip>
  );

  const getDefaultRejectOpenButton = (toggle) => (
    <Tooltip title={"Ablehnen"} PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={toggle}
      >
        {<ClearOutlined className="secondary-textcolor" fontSize="medium" />}{" "}
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <ConfirmationModal
        title={"Aufgabe bestätigen?"}
        resetParent={resetParent}
        confirm={() => axios.post(API_URL_ACCECTTASK, { task: task.id })}
        getOpenButton={
          !task.finished && !task.accepted
            ? getAcceptOpenButton || getDefaultAcceptOpenButton
            : () => null
        }
        isOpen={isAcceptModalOpen}
        setIsOpen={setIsAcceptModalOpen}
        btnLabel={"Bestätigen"}
      />
      <RejectTaskModal
        task={task}
        resetParent={resetParent}
        getOpenButton={
          !task.finished && task.accepted === null
            ? getRejectOpenButton || getDefaultRejectOpenButton
            : () => null
        }
        isOpen={isRejectModalOpen}
        setIsOpen={setIsRejectModalOpen}
        session={session}
      />
    </>
  );
}

AcceptAction.propTypes = {
  task: taskPropType,
  isAcceptModalOpen: PropTypes.bool,
  setIsAcceptModalOpen: PropTypes.func,
  isRejectModalOpen: PropTypes.bool,
  setIsRejectModalOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getAcceptOpenButton: PropTypes.func,
  getRejectOpenButton: PropTypes.func,
};

export function FinishedConfirmationAction({
  task,
  isOpen,
  setIsOpen,
  resetParent,
  session,
  getOpenButton,
}) {
  const getDefaultOpenButton = (toggle) => (
    <Tooltip
      title={
        !task.finished_confirmed ? "Erledigung überprüft" : "Nicht Erledigen"
      }
      PopperProps={{ style: { zIndex: 9999 } }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={toggle}
      >
        {task.finished_confirmed ? (
          <ThumbDownOffAltOutlined
            className="secondary-textcolor"
            fontSize="medium"
          />
        ) : (
          <ThumbUpOffAltOutlined
            className="secondary-textcolor"
            fontSize="medium"
          />
        )}
      </IconButton>
    </Tooltip>
  );

  return (session.user.id === task.coordinator ||
    session.user.id === task.registered_by) &&
    task.finished &&
    !task.finished_confirmed ? (
    <QuestionModal
      title={"Ist die Aufgabe erledigt?"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      resetParent={resetParent}
      clickYes={
        !task.finished_confirmed
          ? () =>
              axios.put(API_URL_TASK + task.id, {
                finished: true,
                finished_confirmed: true,
              })
          : null
      }
      clickNo={
        task.finished_confirmed
          ? () =>
              axios.put(API_URL_TASK + task.id, {
                finished: false,
                finished_confirmed: false,
              })
          : null
      }
      getOpenButton={getOpenButton || getDefaultOpenButton}
    />
  ) : null;
}

FinishedConfirmationAction.propTypes = {
  task: taskPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getOpenButton: PropTypes.func,
};

export function AddPreviousTaskModal({ task, resetParent, session }) {
  return !task.finished ? (
    <TaskFormModal
      task={null}
      getOpenButton={getAddPreviousTaskIcon}
      resetParent={resetParent}
      nextTask={task}
      project={task.project}
      session={session}
    />
  ) : null;
}

AddPreviousTaskModal.propTypes = {
  task: taskPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};

export function AddFollowingTaskModal({
  task,
  resetParent,
  session,
  getOpenButton,
}) {
  return !task.finished ? (
    <TaskFormModal
      task={null}
      getOpenButton={getOpenButton || getAddNextTaskIcon}
      resetParent={resetParent}
      previousTask={task}
      project={task.project}
      session={session}
    />
  ) : null;
}

AddFollowingTaskModal.propTypes = {
  task: taskPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getOpenButton: PropTypes.func,
};

export function TaskActions({ task, setTask, resetParent, session }) {
  return (
    <Fragment>
      <StartedAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
      />
      <FinishedAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
      />
      <FinishedConfirmationAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
      />
      <AcceptAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
      />
      <TaskFormModal
        task={task}
        getOpenButton={getEditTaskIcon}
        resetParent={resetParent}
        session={session}
      />
      <AddCommentModal
        task={task}
        getOpenButton={getAddCommentIcon}
        save={(c) => {
          setTask((t) => ({
            ...t,
            taskcomment_set: [...t.taskcomment_set, c],
          }));
        }}
        session={session}
      />
      <AddAttachmentModal
        task={task}
        getOpenButton={getAddAttachmentIcon}
        save={(c) => {
          setTask((t) => ({
            ...t,
            taskattachment_set: [...t.taskattachment_set, c],
          }));
        }}
        session={session}
      />
      <AddFollowingTaskModal
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
      />
    </Fragment>
  );
}

TaskActions.propTypes = {
  task: taskPropType,
  setTask: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};

export function TaskActionsMobile({ task, setTask, resetParent, session }) {
  return (
    <Stack direction="column" spacing={0.5} padding={1} alignItems="flex-start">
      <StartedAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
        getOpenButton={(toggle) => (
          <IconButton
            disableFocusRipple
            disableRipple
            style={mobileIconButtonStyle}
            size="small"
            onClick={toggle}
          >
            <PlayCircleOutlineOutlinedIcon
              className="secondary-textcolor"
              fontSize="medium"
            />
            <Typography>
              {!task.started ? "Starten" : "Nicht gestartet"}
            </Typography>
          </IconButton>
        )}
      />
      <FinishedAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
        getOpenButton={(toggle) => (
          <IconButton
            disableFocusRipple
            disableRipple
            style={mobileIconButtonStyle}
            size="small"
            onClick={toggle}
          >
            {task.finished ? (
              <Flag className="secondary-textcolor" fontSize="medium" />
            ) : (
              <FlagOutlined className="secondary-textcolor" fontSize="medium" />
            )}
            <Typography>
              {!task.finished ? "Erledigen" : "Nicht Erledigen"}
            </Typography>
          </IconButton>
        )}
      />
      <FinishedConfirmationAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
        getOpenButton={(toggle) => (
          <IconButton
            disableFocusRipple
            disableRipple
            style={mobileIconButtonStyle}
            size="small"
            onClick={toggle}
          >
            {task.finished_confirmed ? (
              <ThumbDownOffAltOutlined
                className="secondary-textcolor"
                fontSize="medium"
              />
            ) : (
              <ThumbUpOffAltOutlined
                className="secondary-textcolor"
                fontSize="medium"
              />
            )}
            <Typography>
              {!task.finished_confirmed
                ? "Erledigung überprüft"
                : "Nicht Erledigen"}
            </Typography>
          </IconButton>
        )}
      />
      <AcceptAction
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
        getAcceptOpenButton={getAcceptTaskIconMobile}
        getRejectOpenButton={getRejectTaskIconMobile}
      />
      <TaskFormModal
        task={task}
        getOpenButton={getEditTaskIconMobile}
        resetParent={resetParent}
        session={session}
      />
      <AddCommentModal
        task={task}
        getOpenButton={getAddCommentIconMobile}
        save={(c) => {
          setTask((t) => ({
            ...t,
            taskcomment_set: [...t.taskcomment_set, c],
          }));
        }}
        session={session}
      />
      <AddAttachmentModal
        task={task}
        getOpenButton={getAddAttachmentIconMobile}
        save={(c) => {
          setTask((t) => ({
            ...t,
            taskattachment_set: [...t.taskattachment_set, c],
          }));
        }}
        session={session}
      />
      <AddFollowingTaskModal
        task={task}
        setTask={setTask}
        resetParent={resetParent}
        session={session}
        getOpenButton={getAddNextTaskIconMobile}
      />
    </Stack>
  );
}

TaskActionsMobile.propTypes = {
  task: taskPropType,
  setTask: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
