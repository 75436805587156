import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import ExcelJS from "exceljs";
import QRCode from "qrcode";
import { saveAs } from "file-saver";

import { Typography } from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { CustomButton } from "../../elements/StyledElements";
import { defaultIfEmpty } from "../../elements/utils";

export default function WarehouseProductQRCodeModal({
  warehouseProducts,
  session,
  style,
}) {
  const [idxs, setIdxs] = useState("");
  const [invalidIdxs, setInvalidIdxs] = useState(false);

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} style={style}>
        {" "}
        Labelinformationen exportieren{" "}
      </CustomButton>
    );
  };

  const onSubmit = async (onSuccess) => {
    try {
      const ranges = idxs
        .split(",")
        .map((range) => range.split("-").map((idx) => parseInt(idx.trim())));
      console.log(ranges);
      for (let i = 0; i < ranges.length; i++) {
        if (ranges[i].length === 1) {
          ranges[i].push(ranges[i][0]);
          continue;
        } else if (ranges[i].length === 2 && ranges[i][0] <= ranges[i][1])
          continue;
        throw new Error("Invalid index range");
      }
      ranges.sort((a, b) => (a[1] === b[1] ? a[0] - b[0] : a[1] - b[1]));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Labelinformationen");
      worksheet.addRow([
        "Index",
        "Name",
        "Ausführung bei Zulieferer",
        "QRCode",
      ]);
      worksheet.getColumn(2).width = 50;
      worksheet.getColumn(4).width = 50;
      let rangeIdx = 0;
      let nbRows = 1;
      for (let i = 0; i < warehouseProducts.length; i++) {
        while (
          rangeIdx < ranges.length &&
          ranges[rangeIdx][1] < warehouseProducts[i].id
        )
          rangeIdx++;
        if (
          rangeIdx < ranges.length &&
          ranges[rangeIdx][0] <= warehouseProducts[i].id
        ) {
          worksheet.addRow([
            `i${warehouseProducts[i].id}`,
            warehouseProducts[i].name,
            warehouseProducts[i].supplier_name,
          ]);
        }
      }
      rangeIdx = 0;
      for (let i = 0; i < warehouseProducts.length; i++) {
        while (
          rangeIdx < ranges.length &&
          ranges[rangeIdx][1] < warehouseProducts[i].id
        )
          rangeIdx++;
        if (
          rangeIdx < ranges.length &&
          ranges[rangeIdx][0] <= warehouseProducts[i].id
        ) {
          const qrCodeUrl = await QRCode.toDataURL(
            `${warehouseProducts[i].id}`,
          );
          const imageId = workbook.addImage({
            base64: qrCodeUrl,
            extension: "png",
          });
          nbRows++;
          worksheet.addImage(imageId, `D${nbRows}:D${nbRows}`);
        }
      }
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) row.height = 250;
      });
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "Labelinformationen.xlsx");
      onSuccess();
    } catch (error) {
      setInvalidIdxs(true);
      console.error(error);
    }
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => {
          onSubmit(toggle);
        }}
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Labelinformationen exportieren"
      getFooter={getFooter}
    >
      <Form>
        <FormGroup>
          <Typography className="secondary-textcolor">
            Export index range, comma separated (e.g. 299, 301, 305-335)
          </Typography>
          <Input
            id="input"
            type="text"
            name="index"
            onChange={(e) => {
              setIdxs(e.target.value);
            }}
            value={defaultIfEmpty(idxs)}
            required={true}
            invalid={invalidIdxs}
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
}

WarehouseProductQRCodeModal.propTypes = {
  warehouseProducts: PropTypes.array,
  session: PropTypes.object,
  style: PropTypes.object,
};
