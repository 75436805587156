import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Slide from "@mui/material/Slide";

export default function Carousel({
  title,
  handleNext,
  handlePrev,
  loading = false,
  children,
}) {
  // slide direction for animation
  const [slideDirection, setSlideDirection] = useState("left"); // left right undefined

  // these two functions handle changing the pages
  const handleNextPage = () => {
    setSlideDirection("left");
    handleNext();
  };

  const handlePrevPage = () => {
    setSlideDirection("right");
    handlePrev();
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <IconButton
        onClick={handlePrevPage}
        sx={{ display: handlePrev !== undefined ? "flex" : "none" }}
        disabled={handlePrev === null}
      >
        <NavigateBeforeIcon />
      </IconButton>
      {loading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        <Slide direction={slideDirection} in={true}>
          <div style={{ display: "flex", flex: 1 }}>{children || null}</div>
        </Slide>
      )}
      <IconButton
        onClick={handleNextPage}
        sx={{ display: handleNext !== undefined ? "flex" : "none" }}
        style={{ marginLeft: 0 }}
        disabled={handleNext === null}
      >
        <NavigateNextIcon />
      </IconButton>
    </Stack>
  );
}

Carousel.propTypes = {
  title: PropTypes.string,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
};
