import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import Typography from "@mui/material/Typography";
import { Input } from "reactstrap";
import { defaultIfEmpty } from "./utils";
import { CustomButton } from "./StyledElements";

const MINROWS = 3;
const MAXROWS = 8;

export default function TextField({
  text,
  setText,
  save,
  description = "Notizen",
  customRows = null,
}) {
  const [_text, _setText] = useState(text);
  const [rows, setRows] = useState(
    customRows !== null
      ? customRows
      : text
        ? Math.min(Math.max(text.split("\n").length, MINROWS), MAXROWS)
        : MINROWS,
  );

  useEffect(() => {
    _setText(text);
    setRows(
      customRows !== null
        ? customRows
        : text
          ? Math.min(Math.max(text.split("\n").length, MINROWS), MAXROWS)
          : MINROWS,
    );
  }, [text, customRows]);

  const changeText = (value) => {
    if (setText) setText(value);
    else _setText(value);
  };

  return (
    <>
      {description ? (
        <Typography className="secondary-textcolor">{description}:</Typography>
      ) : null}
      <Input
        id="input"
        type="textarea"
        name="description"
        onChange={(e) => changeText(e.target.value)}
        value={defaultIfEmpty(_text)}
        required={true}
        style={{ boxShadow: "none", margin: "10px 0px" }}
        rows={rows}
      />
      {save ? (
        <CustomButton onClick={() => save(_text)}>Speichern</CustomButton>
      ) : null}
    </>
  );
}

TextField.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  save: PropTypes.func,
  description: PropTypes.string,
  customRows: PropTypes.number,
};
