import React, { useState, Fragment, useRef, useMemo } from "react";
import { PropTypes } from "prop-types";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Typography } from "@mui/material";

import { isString } from "../../../elements/utils";

export default function CropImage({
  image,
  setCroppedImage,
  maxImageWidth = 600,
  maxImageHeight = 450,
}) {
  const [imageCrop, setImageCrop] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
  });

  const cropRef = useRef(null);

  const loadedImage = useRef(null);
  const filename = useRef(null);
  const compressedImg = useRef(null);

  const makeClientCrop = async (crop) => {
    const randomPart = (Math.random() + 1).toString(36).substring(7);
    if (loadedImage.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        loadedImage.current,
        crop,
        filename.current + randomPart, // to change the image name everytime it is cropped
      );
      return croppedImageUrl;
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return fetch(canvas.toDataURL("image/jpg"))
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName + ".jpg", { type: "image/jpg" });
      });
  };

  const onCropComplete = (crop) => {
    setImageCrop(crop);
    if (crop.height === 0 && crop.width === 0) {
      setCroppedImage(compressedImg.current);
      return;
    }
    makeClientCrop(crop).then((blob) => {
      if (blob) setCroppedImage(blob);
    });
  };

  const onCropChange = (crop) => {
    setImageCrop(crop);
  };

  const imageSrc = useMemo(
    () => (isString(image) ? image : URL.createObjectURL(image)),
    [image],
  );

  return (
    <Fragment>
      <Typography fontSize="h5.fontSize" className="secondary-textcolor">
        {" "}
        Bild ausschneiden{" "}
      </Typography>
      <br />
      <ReactCrop
        onComplete={onCropComplete}
        onChange={onCropChange}
        crop={imageCrop}
        src={imageSrc}
        onImageLoaded={(img) => {
          loadedImage.current = img;
          const naturalHeight = cropRef.current.imageRef.current.naturalHeight;
          const naturalWidth = cropRef.current.imageRef.current.naturalWidth;
          const scaler = Math.min(
            maxImageHeight / naturalHeight,
            maxImageWidth / naturalWidth,
          );
          cropRef.current.imageRef.current.height = scaler * naturalHeight;
          cropRef.current.imageRef.current.width = scaler * naturalWidth;
        }}
        ref={cropRef}
      />
    </Fragment>
  );
}

CropImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setCroppedImage: PropTypes.func,
  maxImageWidth: PropTypes.number,
  maxImageHeight: PropTypes.number,
};
