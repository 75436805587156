import React from "react";

import { PropTypes } from "prop-types";
import { Col, Container, Row } from "reactstrap";

import { Typography } from "@mui/material";

import { projectPropType } from "../../elements/PropTypes";
import { CustomButton } from "../../elements/StyledElements";
import { getCustomerName, hasPermission } from "../../elements/utils";
import CustomModal from "../shared/modal_utils/CustomModal";
import InvoiceFormModal from "./InvoiceFormModal";
import InvoiceList from "./InvoiceList";
import OfferDocumentList from "./OfferDocumentList";

export default function InvoiceModal({
  project,
  disable,
  getOpenButton,
  resetParent,
  showAcceptedOffer = true,
  session,
  isOpen = null,
  setIsOpen = null,
}) {
  const finalInvoice = project.final_invoice_obj;
  const acceptedOffer = project.accepted_offer_obj;
  const construction = project.construction_obj
    ? project.construction_obj
    : null;

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton disabled={disable} onClick={toggle} icon="euro">
        Rechnung
      </CustomButton>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title={"Rechnung für " + getCustomerName(project.customer_obj)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Container>
        <Row>
          <Col>
            {showAcceptedOffer ? (
              <OfferDocumentList
                project={project}
                offers={acceptedOffer ? [acceptedOffer] : null}
                emptyTable={true}
                title="Akzeptiertes Angebot"
              ></OfferDocumentList>
            ) : null}
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Erstellte Rechnungen
            </Typography>
            <br />
            <InvoiceList
              project={project}
              resetParent={resetParent}
              session={session}
              emptyTable={true}
            />
            <br />
            {!finalInvoice &&
            (hasPermission(session.user, "customer_handling") ||
              hasPermission(session.user, "page_invoices")) ? (
              <InvoiceFormModal
                project={project}
                final={false}
                productRequestParams={{
                  offer: acceptedOffer ? acceptedOffer.id : null,
                  construction: construction ? construction.id : null,
                }}
                resetParent={resetParent}
                session={session}
              />
            ) : null}
            <br />
            <br />
            {!finalInvoice &&
            (hasPermission(session.user, "customer_handling") ||
              hasPermission(session.user, "page_invoices")) ? (
              <InvoiceFormModal
                project={project}
                final={true}
                productRequestParams={{
                  offer: acceptedOffer ? acceptedOffer.id : null,
                  construction: construction ? construction.id : null,
                }}
                resetParent={resetParent}
                session={session}
              />
            ) : null}
            <br />
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
}

InvoiceModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  showAcceptedOffer: PropTypes.bool,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
