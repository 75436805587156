import React from "react";

import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

import { Typography } from "@mui/material";

import { invoicePropType } from "../../elements/PropTypes";
import {
  filledNumFormatter,
  getCustomerName,
  isInvoiceOverpaid,
  numFormatter,
} from "../../elements/utils";
import InvoiceActions from "./InvoiceActions";

export default function FinanceInvoiceList({
  invoices,
  emptyTable,
  resetParent,
  title,
  totals,
  session,
}) {
  if ((!invoices || invoices.length <= 0) && !emptyTable) {
    return null;
  }

  const totalLength =
    !invoices || invoices.length <= 0 || !totals
      ? 4
      : numFormatter(
          Math.max(...invoices.map((invoice) => invoice.total_gross)),
        ).length;

  const sortedInvoices = invoices.sort((a, b) =>
    (b.registration_date + b.registration_time).localeCompare(
      a.registration_date + a.registration_time,
    ),
  );

  return (
    <div>
      <Typography fontSize="h5.fontSize" className="secondary-textcolor">
        {title || "Rechnungen"}
      </Typography>
      <Table
        className={
          !invoices || invoices.length <= 0 ? "table-not-hover" : "table-hover"
        }
      >
        <thead>
          <tr>
            <th
              className="align-middle"
              style={{ width: "10%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">
                Rechnungs-Nr.
              </Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "10%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">
                Erstellt am
              </Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "20%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Kunde</Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "10%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Betrag</Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "10%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Offen</Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "30%", textAlign: "center" }}
            >
              <Typography className="secondary-textcolor"></Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {!invoices || invoices.length <= 0 ? (
            <tr>
              <td colSpan="7" align="center">
                <Typography className="secondary-textcolor">
                  {" "}
                  Keine Rechnungen vorhanden{" "}
                </Typography>
              </td>
            </tr>
          ) : (
            sortedInvoices.map((invoice) => {
              const project = invoice.project_obj;
              return (
                <tr key={invoice.id}>
                  <td className="align-middle" style={{ textAlign: "left" }}>
                    <Link
                      to={"/projekt/" + project.id}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography className="secondary-textcolor">
                        {invoice.id}
                      </Typography>
                    </Link>
                  </td>
                  <td className="align-middle" style={{ textAlign: "left" }}>
                    <Link
                      to={"/projekt/" + project.id}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography className="secondary-textcolor">
                        {invoice.registration_date}
                      </Typography>
                    </Link>
                  </td>
                  <td className="align-middle" style={{ textAlign: "left" }}>
                    <Link
                      to={"/projekt/" + project.id}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography className="secondary-textcolor">
                        {project ? getCustomerName(project.customer_obj) : ""}
                      </Typography>
                    </Link>
                  </td>
                  <td className="align-middle" style={{ textAlign: "left" }}>
                    <Link
                      to={"/projekt/" + project.id}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography className="secondary-textcolor">
                        {filledNumFormatter(invoice.total_gross, totalLength)} €
                      </Typography>
                    </Link>
                  </td>
                  <td className="align-middle" style={{ textAlign: "left" }}>
                    <Link
                      to={"/projekt/" + project.id}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        className={
                          isInvoiceOverpaid(invoice)
                            ? "text-danger"
                            : "secondary-textcolor"
                        }
                      >
                        {filledNumFormatter(
                          invoice.total_gross -
                            invoice.cash_discount -
                            invoice.paid,
                          totalLength,
                        )}{" "}
                        €
                      </Typography>
                    </Link>
                  </td>
                  <td className="align-middle" align="center">
                    <InvoiceActions
                      project={project}
                      invoice={invoice}
                      resetParent={resetParent}
                      allowDeletion={true}
                      session={session}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      {totals ? (
        <>
          <div style={{ float: "right" }}>
            <Typography className="secondary-textcolor">
              <strong>
                {" "}
                Netto: &nbsp;&nbsp;{numFormatter(totals.net)}{" "}
                €&nbsp;&nbsp;&nbsp;&nbsp;
              </strong>
              <br />
              <strong>
                {" "}
                Brutto: &nbsp;{numFormatter(totals.gross)}{" "}
                €&nbsp;&nbsp;&nbsp;&nbsp;
              </strong>
              {totals.open != null ? (
                <>
                  <br />
                  <strong>
                    {" "}
                    Offen: &nbsp;&nbsp;{numFormatter(totals.open)}{" "}
                    €&nbsp;&nbsp;&nbsp;&nbsp;
                  </strong>
                </>
              ) : null}
            </Typography>
          </div>
          {totals.open != null ? <br /> : null}
          <br />
        </>
      ) : null}
    </div>
  );
}

FinanceInvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(invoicePropType),
  emptyTable: PropTypes.bool,
  resetParent: PropTypes.func,
  title: PropTypes.string,
  totals: PropTypes.object,
  session: PropTypes.object,
};
