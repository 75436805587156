import React, { useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { CustomButton } from "../../elements/StyledElements";
import {
  API_URL_PLANVILLELOCATION,
  API_URL_PREFERREDPLANVILLELOCATION,
} from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import { Form, Input, Table } from "reactstrap";
import { FormGroup, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { getEmptyFieldsError } from "../../elements/utils";

const addLocationForm = {
  name: "",
  zip_code: "",
};

export default function PlanvilleLocationAddEditModal({
  location,
  loadLocations,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [locationForm, setLocationForm] = useState(
    location || { ...addLocationForm },
  );
  const [preferredZipCodes, setPreferredZipCodes] = useState([]);

  const onChange = (e) => {
    setLocationForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const loadPreferredZipCodes = (location) => {
    axios
      .get(API_URL_PREFERREDPLANVILLELOCATION, { params: { location } })
      .then((res) => setPreferredZipCodes(res.data));
  };

  const submit = async (locationForm) => {
    if (location)
      return axios.put(API_URL_PLANVILLELOCATION + location.id, locationForm);
    return axios.post(API_URL_PLANVILLELOCATION, locationForm);
  };

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsError(locationForm, addLocationForm);
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(locationForm)
      .then(() => {
        loadLocations();
        onSuccess();
        setLocationForm(addLocationForm);
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "project:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const deletePreference = (preferenceId) => {
    axios
      .delete(API_URL_PREFERREDPLANVILLELOCATION + preferenceId)
      .then(() => loadLocations(location.id));
  };

  const getOpenButton = (toggle) => {
    if (location)
      return <CustomButton onClick={toggle}>Bearbeiten</CustomButton>;
    return <CustomButton onClick={toggle}>Standort hinzufügen</CustomButton>;
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_project_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  useEffect(() => {
    if (location) loadPreferredZipCodes(location.id);
  }, [location]);

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title={location ? "Bearbeiten" : "Standorteinstellungen"}
      getFooter={getFooter}
    >
      <Stack>
        <Form>
          <FormGroup>
            <Typography className="secondary-textcolor">Standort:</Typography>
            <div className="input-group">
              <Input
                style={{ width: "50%" }}
                type="text"
                id="input"
                onChange={onChange}
                value={locationForm.name}
                name="name"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Typography className="secondary-textcolor">
              Postleitzahl:
            </Typography>
            <div className="input-group">
              <Input
                style={{ width: "50%" }}
                type="text"
                id="input"
                onChange={onChange}
                value={locationForm.zip_code}
                name="zip_code"
              />
            </div>
          </FormGroup>
        </Form>
        {location && (
          <Stack>
            <Table>
              <thead>
                <tr>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Postleitzahl
                    </Typography>{" "}
                  </th>
                  <th style={{ textAlign: "center" }}>
                    <Typography className="secondary-textcolor"></Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!preferredZipCodes || preferredZipCodes.length <= 0 ? (
                  <tr>
                    <td colSpan="4" align="center">
                      <Typography className="secondary-textcolor">
                        Kein PLZ
                      </Typography>
                    </td>
                  </tr>
                ) : (
                  preferredZipCodes.map((preference) => (
                    <tr key={preference.id}>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {preference.zip_code}
                        </Typography>
                      </td>
                      <td align="center">
                        <IconButton
                          onClick={() => deletePreference(preference.id)}
                          style={{ width: "15%" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
}

PlanvilleLocationAddEditModal.propTypes = {
  location: PropTypes.object,
  loadLocations: PropTypes.func,
  session: PropTypes.object,
};
