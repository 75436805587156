import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";

import { operatorPropType } from "../../../../../elements/PropTypes";
import {
  getEmptyFieldsError,
  getErrorMessage,
} from "../../../../../elements/utils";
import { CustomButton } from "../../../../../elements/StyledElements";
import { API_URL_OPERATOR } from "../../../../../settings";
import CustomModal from "../../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../../shared/modal_utils/SaveModalFooter";
import OperatorForm from "./OperatorForm";
import { toast } from "react-toastify";

const emptyOperatorForm = {
  name: null,
  email: null,
};

export default function OperatorFormModal({
  operator,
  resetParent,
  project,
  session,
}) {
  const [operatorForm, setOperatorForm] = useState({ ...emptyOperatorForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const getOpenButton = (toggle) => {
    if (operator) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent", float: "right" }}
          size="small"
          onClick={toggle}
        >
          <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
        </IconButton>
      );
    }
    return (
      <CustomButton onClick={toggle}>
        {" "}
        Neuen Netzbetreiber erstellen{" "}
      </CustomButton>
    );
  };

  const submit = async (operatorForm) => {
    if (operator) {
      return axios.put(API_URL_OPERATOR + operator.id, operatorForm);
    }
    return axios.post(API_URL_OPERATOR, {
      ...operatorForm,
      key: operatorForm.name.toLowerCase().replace(" ", "_"),
    });
  };

  const onSubmit = async (onSuccess) => {
    const optionalKeys = ["email"];
    if (!operator) optionalKeys.push("id");
    const emptyFieldsError = getEmptyFieldsError(
      operatorForm,
      emptyOperatorForm,
      optionalKeys,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(operatorForm)
      .then((res) => {
        localStorage.removeItem(`operators:${project.id}`);
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setOperatorForm({ ...emptyOperatorForm });
  };

  const loadData = () => {
    setOperatorForm(operator || { ...emptyOperatorForm });
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Netzbetreiber"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <OperatorForm
        operator={operatorForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setOperator={setOperatorForm}
      />
    </CustomModal>
  );
}

OperatorFormModal.propTypes = {
  operator: operatorPropType,
  resetParent: PropTypes.func,
  project: PropTypes.object,
  session: PropTypes.object,
};
