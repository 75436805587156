import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Badge,
  Box,
  Typography,
} from "@material-ui/core";

import CustomModal from "../shared/modal_utils/CustomModal";
import { CustomButton } from "../../elements/StyledElements";
import { API_URL_TIMEMANAGEMENTEVENT, API_URL_USER } from "../../settings";
import { userWithLabel, EVENT_TYPES } from "../../elements/utils";

export default function VacationModal({
  session,
  resetParent,
  vacations,
  allEvents,
}) {
  const [loadingElements, setLoadingElements] = useState({
    submitError: false,
    eventIdClicked: null,
  });
  const [employees, setEmployees] = useState([]);

  const loadAllEmployees = () => {
    axios
      .get(API_URL_USER, { params: { is_staff: true, visible: true } })
      .then((res) => {
        setEmployees(res.data);
      });
  };

  useEffect(() => {
    loadAllEmployees();
  }, []);

  const getEventsOverlappedByVacation = (vacation, eventArray) => {
    const vacationStart = new Date(vacation.start_date);
    const vacationEnd = new Date(vacation.end_date);

    return eventArray
      .filter((event) => {
        const eventStart = new Date(event.start_date);
        const eventEnd = new Date(event.end_date);
        if (
          event.event_type === 1 &&
          event.event_type === vacation.event_type &&
          event.user === vacation.user &&
          event.id !== vacation.id &&
          event.approval
        ) {
          return eventStart >= vacationStart && eventEnd <= vacationEnd;
        }
        return false;
      })
      .map((event) => event.id);
  };

  // Only show vacations for employees that are staff and visible only. Not active employees are still shown
  const filteredVacations = vacations.filter((vacation) =>
    employees.some((e) => e.id === vacation.user),
  );

  const getOpenButton = (toggle) => {
    return (
      <Box position="relative" display="inline-block">
        <Badge
          badgeContent={filteredVacations.length}
          color="secondary"
          overlap="rectangular"
        >
          <CustomButton onClick={toggle}>Urlaubsgenehmigung</CustomButton>
        </Badge>
      </Box>
    );
  };

  const approveEvent = (eventId) => {
    setLoadingElements({ ...loadingElements, eventIdClicked: eventId });
    const vacation = vacations.find((vacation) => vacation.id === eventId);
    const overlappedEvents = getEventsOverlappedByVacation(vacation, allEvents);
    Promise.all(
      overlappedEvents.map((overlappedEventId) =>
        axios.delete(`${API_URL_TIMEMANAGEMENTEVENT}${overlappedEventId}`),
      ),
    )
      .then(() => {
        return axios.put(`${API_URL_TIMEMANAGEMENTEVENT}${eventId}`, {
          approval: true,
        });
      })
      .then(() => {
        setLoadingElements({ ...loadingElements, eventIdClicked: null });
        resetParent();
      })
      .catch((error) => {
        console.error("Error processing event", error);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          eventIdClicked: null,
        });
      });
  };

  const renderTable = () => {
    if (loadingElements.submitError) {
      return (
        <Box sx={{ my: 3, textAlign: "center" }}>
          <Typography style={{ fontSize: "20px" }}>
            Fehler beim Genehmigen des Urlaubs
          </Typography>
        </Box>
      );
    }

    if (!vacations.length) {
      return (
        <Box sx={{ my: 3, textAlign: "center" }}>
          <Typography style={{ fontSize: "20px" }}>
            Kein nicht genehmigter Urlaub
          </Typography>
        </Box>
      );
    }

    return (
      <TableContainer component={Paper} style={{ margin: "30px 0px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mitarbeitername</TableCell>
              <TableCell>Startdatum</TableCell>
              <TableCell>Enddatum</TableCell>
              <TableCell>Typ Freier Tag</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVacations.map((vacation) => {
              // Find the employee's name using the vacation.user ID
              const employee = employees.find((e) => e.id === vacation.user);

              return (
                <TableRow key={vacation.id}>
                  <TableCell>{userWithLabel(employee)}</TableCell>
                  <TableCell>{vacation.start_date}</TableCell>
                  <TableCell>{vacation.end_date}</TableCell>
                  <TableCell>
                    {EVENT_TYPES.find(
                      (type) => type.value === vacation.event_type,
                    )?.label || "Unbekannter Typ"}
                  </TableCell>
                  <TableCell>
                    <CustomButton
                      onClick={() => approveEvent(vacation.id)}
                      icon={
                        loadingElements.eventIdClicked === vacation.id
                          ? "refresh"
                          : ""
                      }
                      disabled={loadingElements.eventIdClicked}
                    >
                      Genehmigen
                    </CustomButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <CustomModal
        getOpenButton={getOpenButton}
        size="lg"
        title={"Nicht genehmigte Urlaubsliste"}
      >
        {renderTable()}
      </CustomModal>
    </Box>
  );
}

VacationModal.propTypes = {
  session: PropTypes.object,
  resetParent: PropTypes.func,
  vacations: PropTypes.array.isRequired,
  allEvents: PropTypes.array.isRequired,
};
