import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Container, Input } from "reactstrap";
import axios from "axios";
import { PropTypes } from "prop-types";

import { Typography, Stack, IconButton } from "@mui/material";
import { CustomButton } from "../../elements/StyledElements";

import {
  API_URL_CONSTRUCTION,
  API_URL_CUSTOMER,
  API_URL_WAREHOUSEUSEDATCONSTRUCTION,
} from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import DropDown from "../../elements/DropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { toast } from "react-toastify";
import ErrorMessage from "../../elements/ErrorMessage";
import { isMobileOnly } from "react-device-detect";
import { defaultIfEmpty } from "../../elements/utils";
import { debounce } from "lodash";

const emptySpecialProduct = {
  warehouse_product_id: null,
  amount: 0,
  notes: null,
};
const emptyWarehouseProduct = {
  warehouse_product_id: null,
  amount: 0,
};

export default function WarehouseUsedMaterialModal({
  session,
  warehouseProducts,
  employee,
  fromConstructionDoc,
  project,
}) {
  const [customer, setCustomer] = useState(null);
  const [construction, setConstruction] = useState(
    project ? project.construction : null,
  );
  const [isEditable, setIsEditable] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [customerSearchInput, setCustomerSearchInput] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [customProducts, setCustomProducts] = useState([]);
  const [specialProducts, setSpecialProducts] = useState([]);

  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const debouncedGetCustomerBatch = useCallback(
    debounce((input) => getCustomerBatch(input), 1000),
    [],
  );

  useEffect(() => {
    if (!fromConstructionDoc) getCustomerBatch(null);
  }, [fromConstructionDoc]);

  const resetState = () => {
    setCustomProducts([]);
    setSpecialProducts([]);
  };

  useEffect(() => {
    if (fromConstructionDoc) {
      return;
    }
    resetState();
    setConstruction(null);
    if (customer) {
      axios
        .get(API_URL_CUSTOMER + customer, { params: { nested: true } })
        .then((res) => {
          setProjectList(res.data.project_set);
        });
    }
  }, [customer]);

  const getCustomerBatch = (input = null) => {
    const params = {
      batch_size: 10,
      batch: 0,
      search: input || null,
      ascending_ids: false,
    };
    axios
      .get(API_URL_CUSTOMER, { params })
      .then((res) => setCustomerList(res.data));
  };

  useEffect(() => {
    if (customerSearchInput !== null && !fromConstructionDoc) {
      debouncedGetCustomerBatch(customerSearchInput);
    }

    return () => {
      debouncedGetCustomerBatch.cancel();
    };
  }, [customerSearchInput, debouncedGetCustomerBatch, fromConstructionDoc]);

  useEffect(() => {
    if (construction) {
      axios
        .get(API_URL_WAREHOUSEUSEDATCONSTRUCTION, {
          params: { construction_id: construction },
        })
        .then((res) => {
          setCustomProducts(
            res.data.custom_products.map((e) => ({ ...e, expand: true })),
          );
          setSpecialProducts(res.data.warehouse_products);
        });
      axios.get(API_URL_CONSTRUCTION + construction).then((res) => {
        setIsEditable(!res.data.documentation_completed);
      });
    }
  }, [construction]);

  const handleChangeCustomProducts = (idx, key, value) => {
    const newList = [...customProducts];
    newList[idx][key] = value;
    setCustomProducts(newList);
  };

  const handleChangeWarehouseProducts = (i, j, key, value) => {
    const newList = [...customProducts];
    newList[i].warehouse_products[j][key] = value;
    setCustomProducts(newList);
  };

  const handleChangeSpecialProducts = (idx, key, value) => {
    const newList = [...specialProducts];
    newList[idx][key] = value;
    setSpecialProducts(newList);
  };

  const getOpenButton = (toggle) => {
    return fromConstructionDoc ? (
      <CustomButton color="black" onClick={toggle}>
        Material Eingabe
      </CustomButton>
    ) : (
      <CustomButton
        color="black"
        style={{
          margin: "8px 0",
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        onClick={toggle}
      >
        <Typography variant={isMobileOnly ? "body1" : "h6"}>
          Auf Baustelle verwendet
        </Typography>
      </CustomButton>
    );
  };

  const getFooter = (toggle) => {
    return isEditable ? (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => {
          onSubmit(toggle);
        }}
      />
    ) : null;
  };

  const onSubmit = async (onSuccess) => {
    axios
      .post(API_URL_WAREHOUSEUSEDATCONSTRUCTION, {
        construction_id: construction,
        custom_products: customProducts,
        warehouse_products: specialProducts,
        request_as: employee,
      })
      .then((res) => {
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });

        if (!fromConstructionDoc) {
          setConstruction(null);
          setCustomer(null);
          resetState();
        }
      })
      .catch((error) => {
        console.error(
          "Error in WarehouseUsedMaterial:onSubmit",
          error,
          error.stack,
        );
        toast.error(
          <ErrorMessage
            message={"Anfrage konnte nicht an Server übermittelt werden!"}
          />,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  return (
    <CustomModal
      size="fullscreen"
      title="Auf Baustelle verwendet"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
    >
      <Fragment>
        <Container>
          <Stack spacing={4} marginTop={fromConstructionDoc ? 4 : undefined}>
            {!fromConstructionDoc && (
              <Stack
                direction={isMobileOnly ? "column" : "row"}
                marginTop={4}
                gap={2}
              >
                <DropDown
                  onChange={setCustomer}
                  options={customerList.map((cust) => ({
                    label: cust.first_name + " " + cust.last_name,
                    value: cust.id,
                  }))}
                  value={customer}
                  text="Kunden"
                  sort={true}
                  search={true}
                  style={isMobileOnly ? { width: "100%" } : undefined}
                  onInputChange={(input) => setCustomerSearchInput(input)}
                />
                <DropDown
                  onChange={setConstruction}
                  options={
                    projectList &&
                    projectList
                      .filter((e) => e.construction)
                      .map((proj) => ({
                        label: proj.name,
                        value: proj.construction,
                      }))
                  }
                  value={construction}
                  text="Projekte"
                  sort={true}
                  search={true}
                  style={isMobileOnly ? { width: "100%" } : undefined}
                />
              </Stack>
            )}
            <Stack direction={"row"} spacing={0} alignitems={"center"}>
              <Typography
                className="secondary-textcolor"
                style={{ width: "65%" }}
              >
                Produktname
              </Typography>
              <Typography
                className="secondary-textcolor"
                style={{ width: "35%" }}
              >
                Anzahl
              </Typography>
            </Stack>
            {customProducts.map((customProduct, i) => (
              <Stack
                direction={"column"}
                spacing={1}
                style={{ marginTop: "10px" }}
                borderTop={"1px solid black"}
                key={i}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  style={{ alignItems: "center" }}
                >
                  <Typography
                    className="secondary-textcolor"
                    style={{ width: "70%" }}
                  >
                    {customProduct.product__name}
                  </Typography>
                  <Typography
                    className="secondary-textcolor"
                    style={{ width: "15%" }}
                  >
                    {customProduct.amount}
                  </Typography>
                  {customProduct.expand ? (
                    <IconButton
                      onClick={() => {
                        handleChangeCustomProducts(i, "expand", false);
                      }}
                      style={{ width: "15%" }}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        handleChangeCustomProducts(i, "expand", true);
                      }}
                      style={{ width: "15%" }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  )}
                </Stack>
                {customProduct.expand &&
                  customProduct.warehouse_products.map(
                    (warehouseProduct, j) => (
                      <Stack direction={"row"} key={(i, j)} marginTop={0}>
                        <DropDown
                          onChange={(val) => {
                            handleChangeWarehouseProducts(
                              i,
                              j,
                              "warehouse_product_id",
                              val,
                            );
                          }}
                          options={warehouseProducts
                            .filter(
                              (e) =>
                                e.id ===
                                  warehouseProduct.warehouse_product_id ||
                                !customProduct.warehouse_products
                                  .map((el) => el.warehouse_product_id)
                                  .includes(e.id),
                            )
                            .map((wp) => ({
                              value: wp.id,
                              label:
                                "[i" +
                                wp.id +
                                "] " +
                                wp.name +
                                " ([" +
                                wp.supplier_name +
                                "] " +
                                wp.supplier_product_name +
                                ")",
                            }))}
                          disabled={!isEditable}
                          value={defaultIfEmpty(
                            warehouseProduct.warehouse_product_id,
                          )}
                          required={true}
                          invalid={!warehouseProduct.warehouse_product_id}
                          text="Material"
                          search={true}
                          style={{ width: "65%" }}
                        />
                        <Input
                          style={{ boxShadow: "none", width: "20%" }}
                          type="number"
                          min="1"
                          value={warehouseProduct.amount}
                          disabled={!isEditable}
                          invalid={
                            !warehouseProduct.amount ||
                            warehouseProduct.amount <= 0
                          }
                          onChange={(e) => {
                            handleChangeWarehouseProducts(
                              i,
                              j,
                              "amount",
                              parseInt(e.target.value),
                            );
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            handleChangeCustomProducts(
                              i,
                              "warehouse_products",
                              customProduct.warehouse_products.filter(
                                (_, idx) => idx !== j,
                              ),
                            );
                          }}
                          style={{ width: "15%" }}
                          disabled={!isEditable}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    ),
                  )}
                {customProduct.expand && isEditable && (
                  <CustomButton
                    onClick={() => {
                      handleChangeCustomProducts(i, "warehouse_products", [
                        ...customProduct.warehouse_products,
                        { ...emptyWarehouseProduct },
                      ]);
                    }}
                    style={{ width: "150px", alignSelf: "center" }}
                  >
                    Add Material
                  </CustomButton>
                )}
              </Stack>
            ))}

            <Typography className="secondary-textcolor">
              Außerordentlich Verwendetes Material
            </Typography>
            <Stack direction={"column"} style={{ margin: 0 }} spacing={1}>
              {specialProducts.map((specialProduct, i) => (
                <Stack
                  direction={"column"}
                  spacing={1}
                  style={{ marginTop: "10px" }}
                  borderTop={"1px solid black"}
                  key={i}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ alignItems: "center", marginTop: "10px" }}
                  >
                    <DropDown
                      onChange={(val) => {
                        handleChangeSpecialProducts(
                          i,
                          "warehouse_product_id",
                          val,
                        );
                      }}
                      options={warehouseProducts
                        .filter(
                          (e) =>
                            e.id === specialProduct.warehouse_product_id ||
                            !specialProducts
                              .map((el) => el.warehouse_product_id)
                              .includes(e.id),
                        )
                        .map((wp) => ({
                          value: wp.id,
                          label:
                            "[i" +
                            wp.id +
                            "] " +
                            wp.name +
                            " ([" +
                            wp.supplier_name +
                            "] " +
                            wp.supplier_product_name +
                            ")",
                        }))}
                      disabled={!isEditable}
                      value={defaultIfEmpty(
                        specialProduct.warehouse_product_id,
                      )}
                      required={true}
                      invalid={!specialProduct.warehouse_product_id}
                      text="Material"
                      search={true}
                      style={{ width: "65%" }}
                    />
                    <Input
                      style={{ boxShadow: "none", width: "20%" }}
                      type="number"
                      min="1"
                      value={specialProduct.amount}
                      disabled={!isEditable}
                      invalid={
                        !specialProduct.amount || specialProduct.amount <= 0
                      }
                      onChange={(e) => {
                        handleChangeSpecialProducts(
                          i,
                          "amount",
                          parseInt(e.target.value),
                        );
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setSpecialProducts(
                          specialProducts.filter((_, idx) => idx !== i),
                        );
                      }}
                      style={{ width: "15%" }}
                      disabled={!isEditable}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  <Input
                    style={{ boxShadow: "none" }}
                    type="text"
                    value={specialProduct.notes}
                    placeholder="notes"
                    disabled={!isEditable}
                    onChange={(e) => {
                      handleChangeSpecialProducts(i, "notes", e.target.value);
                    }}
                  />
                </Stack>
              ))}
            </Stack>
            {construction && isEditable && (
              <CustomButton
                onClick={() => {
                  setSpecialProducts([
                    ...specialProducts,
                    { ...emptySpecialProduct },
                  ]);
                }}
                style={{ alignSelf: "center" }}
              >
                Neue Außerordentlich Verwendetes Material
              </CustomButton>
            )}
          </Stack>
        </Container>
      </Fragment>
    </CustomModal>
  );
}

WarehouseUsedMaterialModal.propTypes = {
  session: PropTypes.object.isRequired,
  warehouseProducts: PropTypes.array,
  employee: PropTypes.number,
  fromConstructionDoc: PropTypes.bool,
  project: PropTypes.object,
};
