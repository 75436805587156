import React, { useEffect, useRef, useState } from "react";

import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
  API_URL_LEAD,
  API_URL_LEAD_EMAIL,
  API_URL_LEAD_TODO,
  API_URL_LEADAPPOINTMENT,
} from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import { toast } from "react-toastify";
import { Button, Stack } from "@mui/material";
import LeadAppointmentModal from "./lead_appointment/LeadAppointmentModal";
import moment from "moment";
import CancelAppointmentModal from "./lead_appointment/CancelAppointmentModal";
import ContactDenialModal from "../customers/ContactDenialModal";

const LeadTodoAdditionalData = ({ step, initOnDone }) => {
  const { additional_data: additionalData } = step;

  const formatKey = (key) => {
    return key
      .split("_")
      .map((k) => k.charAt(0).toUpperCase() + k.slice(1))
      .join(" ");
  };

  return (
    <div>
      {additionalData &&
        Object.keys(additionalData).map((key, idx) => {
          switch (key) {
            default:
              return (
                <div key={`additional-data-${key}`}>
                  <strong>{formatKey(key)}:</strong> {additionalData[key] + ""}
                </div>
              );
          }
        })}
      {(step?.on_done || []).includes("add_appointment_time") && (
        <div>
          <CustomButton onClick={() => initOnDone(step)} icon="calendar">
            Änderungszeit
          </CustomButton>
        </div>
      )}
    </div>
  );
};
LeadTodoAdditionalData.propTypes = {
  step: PropTypes.object,
  initOnDone: PropTypes.func,
};

export default function LeadTodos({
  lead,
  salesmen,
  leadTypes,
  resetParent,
  session,
  setArchieveModalState,
  setOpenConfirmationState,
  setEmailState,
  setIsLoading,
  setDateModalState,
  setIsOpenNoSalesmanModal,
}) {
  const [activeStep, setActiveStep] = useState(null);
  const [onDone, setOnDone] = useState({
    id: null,
    todo_key: "",
    action: [],
    countAction: 0,
    countCompletedAction: 0,
  });
  const [appointmentStateId, setAppointmentStateId] = useState(null);
  const [mapModalState, setMapModalState] = useState({
    isOpen: false,
    onSave: () => {},
  });
  const [cancelModalState, setCancelModalState] = useState({
    isOpen: false,
    onArchive: () => {},
    onContinue: () => {},
  });
  const [noContactModalState, setNoContactModalState] = useState({
    isOpen: false,
    onSave: () => {},
  });

  const timer = useRef();

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active .MuiStepIcon-root": { color: "grey" },
      "& .Mui-completed .MuiStepIcon-root": { color: "green" },
      "& .Mui-active .Mui-completed .MuiStepIcon-root": { color: "green" },
    },
  }));

  const c = useStyles();

  const lastActiveStep = useRef(null);

  useEffect(() => {
    if (lastActiveStep.current === null) {
      const openToDo = lead.leadtodo_set.find((t) => !t.done);
      setActiveStep(openToDo ? openToDo.key : null);
    }
  }, [lead.leadtodo_set]);

  const onClick = (step) => {
    setActiveStep(activeStep !== step.key ? step.key : null);
  };

  const initOnDone = (step) => {
    const action = step.on_done || [];

    setOnDone({
      id: step.id,
      todo_key: step.key,
      action,
      countAction: action.length,
      countCompletedAction: 0,
    });
  };

  const completeAction = () => {
    setOnDone((prev) => {
      return {
        ...prev,
        countCompletedAction: prev.countCompletedAction + 1,
      };
    });
  };

  const onDoubleClick = async (step) => {
    if (step.children.length !== 0) return;

    lastActiveStep.current = activeStep;

    if (!step.done) {
      if (lead?.archived) {
        toast.error("Der Lead ist archiviert");
        return;
      }

      initOnDone(step);
      resetParent();
    }
  };

  const onClickHandler = (step, nClicks) => {
    clearTimeout(timer.current);
    if (step.children.length !== 0) {
      timer.current = setTimeout(() => onClick(step), 200);
      return;
    }

    onDoubleClick(step);
  };

  const isActive = (todo) => {
    if (todo.key === activeStep) {
      return true;
    }

    if (todo.children.length === 0) {
      return false;
    }

    return todo.children.some((child) => isActive(child));
  };

  const popOnDone = () => {
    const newAction = onDone.action.slice(1);
    const newOnDone = {
      id: onDone.id,
      todo_key: newAction.length === 0 ? "" : onDone.todo_key,
      action: newAction,
    };

    setOnDone(newOnDone);
  };

  const addAppointmentTime = () => {
    setMapModalState({
      isOpen: true,
      onSave: () => {
        completeAction();
        popOnDone();
        resetParent();
      },
    });
  };

  const sendEmail = async () => {
    if (!lead.salesman) {
      setIsOpenNoSalesmanModal(true);
      return;
    }
    try {
      setIsLoading(true);
      const emailRes = await axios.get(API_URL_LEAD_EMAIL, {
        params: {
          todo_key: onDone.todo_key,
          lead_id: lead.id,
        },
      });

      setEmailState({
        title: emailRes.data.title,
        subject: emailRes.data.subject,
        emailMessage: emailRes.data.email,
        isOpen: true,
        submitParameter: {
          email_type: emailRes.data.email_type,
        },
        onDone: () => {
          completeAction();
          popOnDone();
          resetParent();
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmailAppointment = async () => {
    await sendEmail();
  };

  const askArchiveLead = async (cancelAppointment = false) => {
    setArchieveModalState((prev) => {
      return {
        ...prev,
        isOpen: true,
        callbackOnSubmit: async () => {
          if (cancelAppointment) {
            setNoContactModalState({
              isOpen: true,
              onSave: async () => {
                setCancelModalState({ ...cancelModalState, isOpen: false });
                try {
                  setIsLoading(true);
                  await Promise.all([
                    axios.put(API_URL_LEAD_TODO + appointmentStateId, {
                      done: false,
                    }),
                    axios.delete(
                      API_URL_LEADAPPOINTMENT + lead.appointments[0].id,
                    ),
                  ]);
                  completeAction();
                  popOnDone();
                  resetParent();
                } finally {
                  setIsLoading(false);
                }
              },
            });
          } else {
            completeAction();
            popOnDone();
            resetParent();
          }
        },
      };
    });
  };

  const sendEmailUnreach = async () => {
    await sendEmail();
  };

  const askAddMailbox = async () => {
    setOpenConfirmationState({
      isOpen: true,
      onConfirm: async () => {
        await axios.put(API_URL_LEAD_TODO + onDone.id, {
          additional_data: {
            mailbox: true,
          },
        });
        completeAction();
        popOnDone();
        resetParent();
      },
      onCancel: async () => {
        await axios.put(API_URL_LEAD_TODO + onDone.id, {
          additional_data: {
            mailbox: false,
          },
        });
        completeAction();
        popOnDone();
        resetParent();
      },
    });
  };

  const archiveLeadUnreachable = async () => {
    await axios.put(API_URL_LEAD + lead.id, {
      archived: true,
      archiving_reason: "mehrmals nicht erreicht",
    });
    completeAction();
    popOnDone();
    resetParent();
  };

  const cancelAppointment = async () => {
    if (activeStep !== "main_reach_5") {
      setCancelModalState({
        isOpen: true,
        onArchive: async () => {
          askArchiveLead(true);
        },
        onContinue: async () => {
          setCancelModalState({ ...cancelModalState, isOpen: false });
          try {
            setIsLoading(true);
            await Promise.all([
              axios.put(API_URL_LEAD_TODO + appointmentStateId, {
                done: false,
              }),
              axios.delete(API_URL_LEADAPPOINTMENT + lead.appointments[0].id),
            ]);
            completeAction();
            popOnDone();
            resetParent();
          } finally {
            setIsLoading(false);
          }
        },
      });
    } else {
      askArchiveLead(true);
    }
  };

  const handleOnDone = async () => {
    if (onDone.id == null) {
      return;
    }

    if (onDone.action.length === 0) {
      if (onDone.countAction !== onDone.countCompletedAction) {
        toast.error(
          "Die Anzahl der abgeschlossenen Aktionen entspricht nicht der Anzahl der auszuführenden Aktionen",
        );
      }

      await axios.put(API_URL_LEAD_TODO + onDone.id, { done: true });
      resetParent();
      return;
    }

    const doing = onDone.action[0];
    switch (doing) {
      case "add_appointment_time":
        setAppointmentStateId(onDone.id);
        addAppointmentTime(onDone.id);
        break;
      case "send_email_appointment":
        setMapModalState((prevState) => ({ ...prevState, isOpen: false }));
        sendEmailAppointment();
        break;
      case "ask_next_step_after_cancel":
        cancelAppointment(onDone.id);
        break;
      case "ask_archive_lead":
        askArchiveLead();
        break;
      case "send_email_unreach":
        sendEmailUnreach();
        break;
      case "ask_add_mailbox":
        askAddMailbox();
        break;
      case "archive_lead_unreachable":
        archiveLeadUnreachable();
        break;
      default:
        toast.error("Unbekannte Aktion", doing);
        popOnDone();
        break;
    }
  };

  useEffect(() => {
    handleOnDone();
  }, [onDone]);

  const formatOpenButtonDateTime =
    lead.appointments.length > 0 &&
    `${moment(lead.appointments[0].date).format("MMMM D, YYYY")} ${moment(lead.appointments[0].start_time, "HH:mm:ss").format("HH:mm")}-${moment(lead.appointments[0].end_time, "HH:mm:ss").format("HH:mm")}`;

  return (
    <Box>
      <Stepper orientation="vertical">
        {(lead.leadtodo_set || []).map(
          (parent, pidx) =>
            !parent.hidden && (
              <Step
                key={`child-todo-${parent.key}-${pidx}`}
                active={isActive(parent)}
                completed={parent.done}
                className={c.root}
                hidden={parent.hidden}
              >
                <StepLabel
                  StepIconProps={{
                    onClick: (e) => onClickHandler(parent, e.detail),
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      className="secondary-textcolor"
                      style={{ fontSize: 17, cursor: "pointer" }}
                      onClick={(e) => onClickHandler(parent, e.detail)}
                    >
                      {parent.name}
                    </Typography>
                  </div>
                </StepLabel>
                <StepContent>
                  <Typography>{parent.description}</Typography>
                  <Stepper orientation="vertical">
                    {parent.children.map((child, cidx) => {
                      const sx = !child.done
                        ? {
                            "& .Mui-active .MuiStepIcon-root": {
                              color: "grey",
                              padding: "1.5px",
                              borderRadius: "50%",
                              border: "1.5px solid grey",
                            },
                          }
                        : {
                            "& .MuiStepIcon-root": {
                              color: "green",
                            },
                          };
                      return (
                        !child.hidden && (
                          <Step
                            key={`child-todo-${child.key}-${cidx}`}
                            active={
                              isActive(child) ||
                              (parent.key === activeStep &&
                                child.additional_data)
                            }
                            hidden={child.hidden}
                            completed={child.done}
                            sx={sx}
                          >
                            <StepLabel
                              StepIconProps={{
                                onClick: (e) => onClickHandler(child, e.detail),
                                color: child.done ? "green" : "grey",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  className="secondary-textcolor"
                                  style={{ fontSize: 16, cursor: "default" }}
                                  onClick={(e) =>
                                    onClickHandler(child, e.detail)
                                  }
                                >
                                  {child.name}
                                </Typography>
                              </div>
                            </StepLabel>
                            <StepContent>
                              <Typography
                                className="secondary-textcolor"
                                style={{
                                  fontSize: 14,
                                  textAlign: "left",
                                  cursor: "default",
                                }}
                              >
                                {child.description}
                              </Typography>
                              {child.additional_data && (
                                <LeadTodoAdditionalData
                                  step={child}
                                  initOnDone={initOnDone}
                                />
                              )}
                              <Stepper orientation="vertical">
                                {child.children.map((gchild, gcidx) => {
                                  const gsx = !gchild.done
                                    ? {
                                        "& .Mui-active .MuiStepIcon-root": {
                                          color: "grey !important",
                                          padding: "1.5px",
                                          borderRadius: "50%",
                                          border: "1.5px solid grey",
                                        },
                                      }
                                    : {
                                        "& .MuiStepIcon-root": {
                                          color: "green !important",
                                        },
                                      };
                                  return (
                                    !gchild.hidden && (
                                      <Step
                                        key={`gchild-todo-${gchild.key}-${gcidx}`}
                                        active={true}
                                        hidden={gchild.hidden}
                                        completed={gchild.done}
                                        sx={gsx}
                                      >
                                        <StepLabel
                                          StepIconProps={{
                                            onClick: (e) => {
                                              if (!child.done) {
                                                onClickHandler(
                                                  gchild,
                                                  e.detail,
                                                );
                                              }
                                            },
                                            color: gchild.done
                                              ? "green"
                                              : "grey",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              className="secondary-textcolor"
                                              style={{
                                                fontSize: 16,
                                                cursor: "default",
                                              }}
                                              onClick={(e) => {
                                                if (!child.done)
                                                  onClickHandler(
                                                    gchild,
                                                    e.detail,
                                                  );
                                              }}
                                            >
                                              {gchild.name}
                                            </Typography>
                                          </div>
                                        </StepLabel>
                                        <StepContent>
                                          <Typography
                                            className="secondary-textcolor"
                                            style={{
                                              fontSize: 14,
                                              textAlign: "left",
                                              cursor: "default",
                                            }}
                                          >
                                            {gchild.description}
                                          </Typography>
                                          {gchild.key.startsWith(
                                            "appointment_made",
                                          ) &&
                                            ((!child.done && !gchild.done) ||
                                              (child.done && gchild.done)) && (
                                              <Stack gap={2}>
                                                <CustomButton
                                                  style={{ width: "50%" }}
                                                  onClick={(e) =>
                                                    onClickHandler(
                                                      gchild,
                                                      e.detail,
                                                    )
                                                  }
                                                >
                                                  {lead.appointments.length > 0
                                                    ? formatOpenButtonDateTime
                                                    : "Termin ausmachen"}
                                                </CustomButton>
                                                {lead.appointments.length >
                                                  0 && (
                                                  <>
                                                    <CustomButton
                                                      style={{ width: "50%" }}
                                                      onClick={() =>
                                                        initOnDone(gchild)
                                                      }
                                                    >
                                                      Termin verschieben
                                                    </CustomButton>
                                                    <Button
                                                      style={{ width: "50%" }}
                                                      variant="outlined"
                                                      color="error"
                                                      onClick={(e) => {
                                                        onClickHandler(
                                                          child.children[
                                                            gcidx + 1
                                                          ],
                                                          e.detail,
                                                        );
                                                        setAppointmentStateId(
                                                          gchild.id,
                                                        );
                                                      }}
                                                    >
                                                      Termin absagen
                                                    </Button>
                                                  </>
                                                )}
                                              </Stack>
                                            )}
                                          {gchild.additional_data && (
                                            <LeadTodoAdditionalData
                                              step={gchild}
                                              initOnDone={initOnDone}
                                            />
                                          )}
                                        </StepContent>
                                      </Step>
                                    )
                                  );
                                })}
                              </Stepper>
                            </StepContent>
                          </Step>
                        )
                      );
                    })}
                  </Stepper>
                </StepContent>
              </Step>
            ),
        )}
      </Stepper>
      <LeadAppointmentModal
        isOpenModal={mapModalState.isOpen}
        setIsOpenModal={(isOpen) => {
          setMapModalState({ ...mapModalState, isOpen });
        }}
        editAppointment={lead.appointments.length > 0}
        onSave={mapModalState.onSave}
        lead={lead}
        salesmen={salesmen}
        leadTypes={leadTypes}
        session={session}
      />
      <CancelAppointmentModal
        isOpenModal={cancelModalState.isOpen}
        setIsOpenModal={(isOpen) => {
          setCancelModalState({ ...cancelModalState, isOpen });
        }}
        onArchive={cancelModalState.onArchive}
        onContinue={cancelModalState.onContinue}
        session={session}
      />
      <ContactDenialModal
        customerId={lead.customer}
        resetParent={resetParent}
        session={session}
        isOpenModal={noContactModalState.isOpen}
        setIsOpenModal={(isOpen) => {
          setNoContactModalState({ ...noContactModalState, isOpen });
        }}
        onSave={noContactModalState.onSave}
      />
    </Box>
  );
}

LeadTodos.propTypes = {
  lead: PropTypes.object,
  salesmen: PropTypes.array,
  leadTypes: PropTypes.array,
  resetParent: PropTypes.func,
  parentKey: PropTypes.string,
  session: PropTypes.object,
  setArchieveModalState: PropTypes.func,
  setOpenConfirmationState: PropTypes.func,
  setEmailState: PropTypes.func,
  setIsLoading: PropTypes.func,
  setDateModalState: PropTypes.func,
  setIsOpenNoSalesmanModal: PropTypes.func,
};
