import React from "react";
import { PropTypes } from "prop-types";

import GoogleIcon from "@mui/icons-material/Google";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DownloadIcon from "@mui/icons-material/Download";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import SettingsIcon from '@mui/icons-material/Settings'
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RefreshIcon from "@mui/icons-material/Refresh";
import HistoryIcon from "@mui/icons-material/History";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BarChartIcon from "@mui/icons-material/BarChart";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuIcon from "@mui/icons-material/Menu";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { GiShatteredGlass } from "react-icons/gi";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function Icon({ icon, ...props }) {
  switch (icon) {
    case "map":
      return <MapOutlinedIcon {...props} />;
    case "house":
      return <HomeOutlinedIcon {...props} />;
    case "eye":
      return <RemoveRedEyeOutlinedIcon {...props} />;
    case "lead":
      return <ThreePOutlinedIcon {...props} />;
    case "savings":
      return <SavingsOutlinedIcon {...props} />;
    case "usermanagement":
      return <ManageAccountsOutlinedIcon {...props} />;
    case "settings":
      return <SettingsOutlinedIcon {...props} />;
    case "addgroup":
      return <GroupAddOutlinedIcon {...props} />;
    case "addperson":
      return <PersonAddAlt1OutlinedIcon {...props} />;
    case "statistics":
      return <BarChartIcon {...props} />;
    case "invoice":
      return <ReceiptIcon {...props} />;
    case "archive":
      return <ArchiveOutlinedIcon {...props} />;
    case "unarchive":
      return <UnarchiveOutlinedIcon {...props} />;
    case "product":
      return <Inventory2OutlinedIcon {...props} />;
    case "people":
      return <PeopleOutlineIcon {...props} />;
    case "sell":
      return <SellOutlinedIcon {...props} />;
    case "google":
      return <GoogleIcon {...props} />;
    case "stats":
      return <PollOutlinedIcon {...props} />;
    case "handshake":
      return <HandshakeOutlinedIcon {...props} />;
    case "euro":
      return <EuroOutlinedIcon {...props} />;
    case "clipboard":
      return <AssignmentOutlinedIcon {...props} />;
    case "info":
      return <InfoOutlinedIcon {...props} />;
    case "kalendar":
      return <CalendarMonthOutlinedIcon {...props} />;
    case "rotateLeft":
      return <RotateLeftIcon {...props} />;
    case "rotateRight":
      return <RotateRightIcon {...props} />;
    case "weather":
      return <WbSunnyOutlinedIcon {...props} />;
    case "filter":
      return <FilterAltOutlinedIcon {...props} />;
    case "calendar":
      return <CalendarMonthIcon {...props} />;
    case "flash":
      return <ElectricBoltIcon {...props} />;
    case "time":
      return <AccessTimeOutlinedIcon {...props} />;
    case "warehouse":
      return <WarehouseOutlinedIcon {...props} />;
    case "brokenImage":
      return <BrokenImageOutlinedIcon {...props} />;
    case "clear":
      return <ClearIcon {...props} />;
    case "addcustomer":
      return <PersonAddIcon {...props} />;
    case "person":
    case "customer":
      return <PersonIcon {...props} />;
    case "personoff":
      return <PersonOffIcon {...props} />;
    case "send":
      return <SendIcon {...props} />;
    case "mail":
      return <MailOutlineIcon {...props} />;
    case "history":
      return <HistoryIcon {...props} />;
    case "download":
      return <DownloadIcon {...props} />;
    case "refresh":
      return <RefreshIcon {...props} />;
    // case 'settings':
    //   return <SettingsIcon {...props} />
    case "electricPlug":
      return <ElectricalServicesIcon {...props} />;
    case "documentation":
      return <GradingOutlinedIcon {...props} />;
    case "openLock":
      return <LockOpenIcon {...props} />;
    case "reverse":
      return <KeyboardReturnIcon {...props} />;
    case "price":
      return <PriceCheckOutlinedIcon {...props} />;
    case "bin":
      return <DeleteOutlineIcon {...props} />;
    case "thumbUp":
      return <ThumbUpOffAltOutlinedIcon {...props} />;
    case "task":
      return <TaskAltIcon {...props} />;
    case "apps":
      return <AppsIcon {...props} />;
    case "previous":
      return <NavigateBeforeIcon {...props} />;
    case "next":
      return <NavigateNextIcon {...props} />;
    case "keyUp":
      return <KeyboardArrowUp {...props} />;
    case "keyDown":
      return <KeyboardArrowDown {...props} />;
    case "ruler":
      return <DesignServicesOutlinedIcon {...props} />;
    case "edit":
      return <EditOutlinedIcon {...props} />;
    case "delete":
      return <HighlightOffOutlinedIcon {...props} />;
    case "business":
      return <WorkOutlineOutlinedIcon {...props} />;
    case "percent":
      return <PercentIcon {...props} />;
    case "newsletter":
      return <NewspaperOutlinedIcon {...props} />;
    case "save":
      return <SaveOutlinedIcon {...props} />;
    case "load":
      return <FileUploadOutlinedIcon {...props} />;
    case "more":
      return <MoreHorizIcon {...props} />;
    case "dashboard-customize":
      return <DashboardCustomizeIcon {...props} />;
    case "warning":
      return <WarningAmberOutlinedIcon {...props} />;
    case "menu":
      return <MenuIcon {...props} />;
    case "label":
      return <LabelOutlinedIcon {...props} />;
    case "gps":
      return <GpsFixedOutlinedIcon {...props} />;
    case "truck":
      return <LocalShippingOutlinedIcon {...props} />;
    case "add":
      return <AddOutlinedIcon {...props} />;
    case "forward":
      return <ArrowForwardIcon {...props} />;
    case "engineer":
      return <EngineeringIcon {...props} />;
    case "brokenglass":
      return <GiShatteredGlass size={24} {...props} />;
    case "check":
      return <CheckIcon {...props} />;
    case "checkbox":
      return <CheckBoxIcon {...props} />;
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
};
