import React, { Fragment, useState, useCallback, useEffect } from "react";
import { PropTypes } from "prop-types";
import CustomModal from "../../shared/modal_utils/CustomModal";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./mapstyles.css";
import {
  compareLatLong,
  germanCalendarMsgs,
  round,
} from "../../../elements/utils";
import axios from "axios";
import { API_URL_LEADAPPOINTMENT, API_URL_TASK } from "../../../settings";
import moment from "moment";
import "moment/locale/de";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../shared/calendarStyle.css";
import { Stack } from "@mui/material";
import SetAppointmentModal from "./SetAppointmentModal";
import DropDown from "../../../elements/DropDown";
import DatePicker from "react-datepicker";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { CustomIconButton } from "../../../elements/StyledElements";

const DnDCalendar = withDragAndDrop(Calendar);

function Legend() {
  const map = useMap();
  useEffect(() => {
    if (!map) return;
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.setAttribute("id", "leadlegend");

      const labels = [
        { color: "green", label: "Adresse neuer Termin" },
        { color: "blue", label: "PV-Anlage" },
        { color: "red", label: "Wärmepumpe" },
        { color: "orange", label: "Mieterstrom" },
      ];

      labels.forEach((item) => {
        const labelHTML = `
          <i style="background:${item.color}"></i>
          ${item.label}`;
        div.innerHTML += `<div>${labelHTML}</div>`;
      });

      return div;
    };

    legend.addTo(map);
  }, [map]);

  return null;
}

const getMarkerIcon = (color, borderColor) => {
  color = color || "#55F5FF";

  const markerHtmlStyles = `
    background-color: ${color};
    width: 1rem;
    height: 1rem;
    display: block;
    left: 0rem;
    top: 0rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid ${borderColor || "#ffffff"}
    `;

  const markerIcon = L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [8, 20],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles}"/>`,
  });

  return markerIcon;
};

const getMarkerColor = (type) => {
  switch (type) {
    case 1:
      return "red";
    case 2:
      return "blue";
    case 3:
      return "orange";
    default:
      return "green";
  }
};

const ZOOM = 7;
const CENTER = [51.3119, 9.4916];
moment.locale("de-DE");
const localizer = momentLocalizer(moment);
const dateFormat = "YYYY-MM-DD";

export default function LeadAppointmentModal({
  isOpenModal,
  setIsOpenModal,
  editAppointment,
  onSave,
  lead,
  salesmen,
  leadTypes,
  session,
}) {
  const [leadAppointments, setLeadAppointments] = useState([]);
  const [salesmanEvents, setSalesmanEvents] = useState([]);
  const [fetchedWeekEventsDate, setFetchedWeekEventsDate] = useState({
    start_date: moment().startOf("isoWeek").format(dateFormat),
    end_date: moment().endOf("isoWeek").format(dateFormat),
  });
  const [isOpenSetAppointmentModal, setIsOpenSetAppointmentModal] =
    useState(false);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [clickedSalesman, setClickedSalesman] = useState(null);
  const [calendarTime, setCalendarTime] = useState(null);
  const [view, setView] = useState("day");

  const [filterSalesman, setFilterSalesman] = useState(lead.salesman);
  const [filterLeadType, setFilterLeadType] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  const getLeadAppointments = async () => {
    const params = {
      salesman_id: filterSalesman,
      lead_type: filterLeadType,
      start_date: filterStartDate,
      end_date: filterEndDate,
    };
    const res = await axios.get(API_URL_LEADAPPOINTMENT, { params });
    setLeadAppointments(res.data);
  };

  useEffect(() => {
    getLeadAppointments();
  }, [filterSalesman, filterLeadType, filterStartDate, filterEndDate]);

  const getSalesmanTasks = async (id, range) => {
    const params = {
      employees: id,
      lead_appointment: true,
      start_date: range.start_date,
      end_date: range.end_date,
    };
    const res = await axios.get(API_URL_TASK, { params });
    const fetchedEvents = res.data.map(formatSalesmanTaskToEvents);
    setSalesmanEvents(fetchedEvents);
    setFetchedWeekEventsDate(range);
  };

  const formatSalesmanTaskToEvents = (task) => {
    return {
      title: task.customer_name,
      start: new Date(`${task.taskdates_set[0].date}T${task.start_time}`),
      end: new Date(`${task.taskdates_set[0].date}T${task.end_time}`),
      allDay: false,
      lat_long: task.lat_lng,
    };
  };

  useEffect(() => {
    if (isOpenModal && editAppointment) {
      const startOfWeek = moment(lead.appointments[0].date).startOf("isoWeek");
      const range = {
        start_date: startOfWeek.format(dateFormat),
        end_date: startOfWeek.endOf("isoWeek").format(dateFormat),
      };
      getSalesmanTasks(lead.salesman, range);
    }
  }, [isOpenModal, editAppointment]);

  const calendarEventHandlers = useCallback(
    (appointment) => {
      return {
        mouseover: (e) => {
          if (hoveredMarker !== appointment.id) {
            setHoveredMarker(appointment.id);
            e.target.openPopup();
            getSalesmanTasks(appointment.salesman_id, {
              start_date: fetchedWeekEventsDate.start_date,
              end_date: fetchedWeekEventsDate.end_date,
            });
          }
        },
        mouseout: (e) => {
          setHoveredMarker(null);
          if (!clickedMarker) {
            e.target.closePopup();
          }
        },
        click: (e) => {
          setClickedMarker(appointment.id);
          setHoveredMarker(appointment.id);
          setClickedSalesman(appointment.salesman_id);
          e.target.openPopup();
        },
      };
    },
    [hoveredMarker, clickedMarker, getSalesmanTasks],
  );

  const handleSelectSlot = ({ start }) => {
    setIsOpenSetAppointmentModal(true);
    setCalendarTime(new Date(start));
  };

  const handleSelectEvent = (event) => {
    if (
      event.title === lead.name &&
      compareLatLong(event.lat_long, [lead.latitude, lead.longitude])
    ) {
      setIsOpenSetAppointmentModal(true);
      setCalendarTime(new Date(event.start));
    }
  };

  const eventPropGetter = (event) => {
    const currentLeadEvent =
      event.title === lead.name &&
      compareLatLong(event.lat_long, [lead.latitude, lead.longitude]);
    const backgroundColor = currentLeadEvent ? "green" : event.backgroundColor;
    return { style: { backgroundColor, opacity: event.tentative ? 0.5 : 1 } };
  };

  const handleEventDrop = ({ start, event }) => {
    const currentLeadEvent =
      event.title === lead.name &&
      compareLatLong(event.lat_long, [lead.latitude, lead.longitude]);
    if (currentLeadEvent) {
      setIsOpenSetAppointmentModal(true);
      setCalendarTime(new Date(start));
    }
  };

  const shouldFetchNewWeek = (
    newDate,
    fetchedWeekStartMoment,
    fetchedWeekEndMoment,
  ) => {
    return (
      newDate.isBefore(fetchedWeekStartMoment, "day") ||
      newDate.isAfter(fetchedWeekEndMoment, "day")
    );
  };

  const handleNavigate = (newDate) => {
    const startDate = moment(newDate).startOf("isoWeek").format(dateFormat);
    const endDate = moment(newDate).endOf("isoWeek").format(dateFormat);

    const fetchedWeekStartMoment = fetchedWeekEventsDate.start_date;
    const fetchedWeekEndMoment = fetchedWeekEventsDate.end_date;

    let currentDate;

    if (moment(newDate).isBefore(fetchedWeekStartMoment, "day")) {
      currentDate = endDate;
    } else if (moment(newDate).isAfter(fetchedWeekEndMoment, "day")) {
      currentDate = startDate;
    } else {
      currentDate = moment(newDate).format(dateFormat);
    }

    if (
      shouldFetchNewWeek(
        moment(newDate),
        fetchedWeekStartMoment,
        fetchedWeekEndMoment,
      )
    ) {
      getSalesmanTasks(
        clickedSalesman,
        { start_date: startDate, end_date: endDate },
        currentDate,
      );
    }
  };

  const onSubmit = () => {
    setCalendarTime(null);
    onSave();
  };

  const onToggle = (isOpen) => {
    if (isOpen) {
      getLeadAppointments();
    } else {
      setHoveredMarker(null);
      setClickedMarker(null);
    }
  };

  return (
    <CustomModal
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      title="Termin ausmachen"
      size="fullscreen"
      onToggle={onToggle}
    >
      <Fragment>
        <Stack height="100%" paddingY={2} gap={2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" gap={2} alignItems="center">
              <DropDown
                onChange={(value) => setFilterSalesman(value)}
                options={salesmen.map((salesman) => ({
                  value: salesman.id,
                  label: salesman.name,
                }))}
                value={filterSalesman}
                text="Verkäufer"
                search={true}
              />
              <DropDown
                onChange={(value) => setFilterLeadType(value)}
                options={leadTypes.map((p) => ({ value: p.id, label: p.name }))}
                value={filterLeadType}
                text="Projekttyp"
                search={true}
              />
              <Stack direction="row">
                <DatePicker
                  selected={
                    filterStartDate
                      ? moment(filterStartDate, "YYYY-MM-DD").toDate()
                      : null
                  }
                  onChange={(date) =>
                    setFilterStartDate(moment(date).format("YYYY-MM-DD"))
                  }
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Startdatum"
                />
                {filterStartDate && (
                  <CustomIconButton
                    icon="clear"
                    onClick={() => setFilterStartDate(null)}
                  />
                )}
              </Stack>
              <Stack direction="row">
                <DatePicker
                  selected={
                    filterEndDate
                      ? moment(filterEndDate, "YYYY-MM-DD").toDate()
                      : null
                  }
                  onChange={(date) =>
                    setFilterEndDate(moment(date).format("YYYY-MM-DD"))
                  }
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Enddatum"
                />
                {filterEndDate && (
                  <CustomIconButton
                    icon="clear"
                    onClick={() => setFilterEndDate(null)}
                  />
                )}
              </Stack>
            </Stack>
            <SetAppointmentModal
              lead={lead}
              salesmen={salesmen}
              clickedSalesman={clickedSalesman}
              leadTypes={leadTypes}
              onSave={onSubmit}
              editAppointment={editAppointment}
              isOpen={isOpenSetAppointmentModal}
              setIsOpen={setIsOpenSetAppointmentModal}
              session={session}
              calendarTime={calendarTime}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: round(window.innerHeight * 0.8),
                width: round(window.innerWidth * 0.55),
                display: "block",
              }}
            >
              <MapContainer doubleClickZoom={false} zoom={ZOOM} center={CENTER}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  position={[lead.latitude, lead.longitude]}
                  icon={getMarkerIcon("green", "black")}
                >
                  <Popup>
                    <div>
                      <b>Kunde:</b> {`${lead.name}`}
                      <br />
                      <b>Verkäufer:</b> {lead.salesman_name}
                      <br />
                      <b>Adresse:</b>{" "}
                      {`${lead.street_and_number} ${lead.zip_and_city}`}
                      <br />
                    </div>
                  </Popup>
                </Marker>
                {leadAppointments.length > 0 &&
                  leadAppointments.map((appointment) => (
                    <Marker
                      key={appointment.id}
                      position={appointment.lat_long}
                      icon={getMarkerIcon(
                        appointment.lead === lead.id
                          ? "green"
                          : getMarkerColor(appointment.lead_type),
                        "black",
                      )}
                      eventHandlers={calendarEventHandlers(appointment)}
                    >
                      <Popup>
                        <div>
                          <b>Kunde:</b> {`${appointment.customer_name}`}
                          <br />
                          <b>Verkäufer:</b> {appointment.salesman}
                          <br />
                          <b>Adresse:</b> {appointment.address}
                          <br />
                          <b>Datum:</b>{" "}
                          {moment(appointment.date).format("DD.MM.YYYY")}
                          <br />
                          <b>Startzeit:</b>{" "}
                          {appointment.start_time
                            .split(":")
                            .slice(0, 2)
                            .join(":")}
                          <br />
                          <b>Endzeit:</b>{" "}
                          {appointment.end_time
                            .split(":")
                            .slice(0, 2)
                            .join(":")}
                          <br />
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                <Legend />
              </MapContainer>
            </div>
            {(editAppointment || hoveredMarker || clickedMarker) && (
              <DnDCalendar
                className="secondary-textcolor"
                views={["day", "week"]}
                selectable
                localizer={localizer}
                defaultDate={
                  lead.appointments.length > 0
                    ? moment(lead.appointments[0].date).toDate()
                    : new Date()
                }
                view={view}
                onView={setView}
                style={{
                  width: round(window.innerWidth * 0.4),
                  height: round(window.innerHeight * 0.8),
                }}
                scrollToTime={moment().set({ h: 8, m: 0 }).toDate()}
                events={salesmanEvents}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onNavigate={handleNavigate}
                longPressThreshold={50}
                step={60}
                timeslots={1}
                eventPropGetter={eventPropGetter}
                onEventDrop={handleEventDrop}
                draggableAccessor={() => true}
                messages={germanCalendarMsgs}
              />
            )}
          </div>
        </Stack>
      </Fragment>
    </CustomModal>
  );
}

LeadAppointmentModal.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  editAppointment: PropTypes.bool,
  onSave: PropTypes.func,
  lead: PropTypes.object,
  salesmen: PropTypes.array,
  leadTypes: PropTypes.array,
  session: PropTypes.object,
};
