import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import axios from "axios";
import moment from "moment";
import "moment/locale/de";
import { PropTypes } from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import LoadingPage from "../../elements/LoadingPage";
import {
  date2String,
  germanCalendarMsgs,
  getHolidayEvents,
  getCalendarRange,
  HOLIDAYREGION,
  string2FormattedString,
  string2Date,
  numFormatter,
  projectNameWithLabel,
} from "../../elements/utils";
import {
  API_URL_CONSTRUCTION,
  API_URL_TASK,
  API_URL_CONSTRUCTIONDATESUGGESTION,
} from "../../settings";
import "../shared/calendarStyle.css";
import { Avatar, Stack, Tooltip, Typography } from "@mui/material";
import { projectPropType } from "../../elements/PropTypes";
import CustomModal from "../shared/modal_utils/CustomModal";
import {
  getConstructionAmountDescriptions,
  getConstructionDateColor,
} from "../project_types/projectUtils";
import { isHoliday } from "feiertagejs";
import ScheduleProjectAtDateModal from "./ScheduleProjectAtDateModal";

// require('moment/locale/de.js')
moment.locale("de-DE");
const localizer = momentLocalizer(moment);

function DateHeader({ label, date, constructions, suggestedDates }) {
  const dateStr = date2String(date);
  const dateConstructions = constructions.filter((c) =>
    c.constructiondates_set.some(
      (dateObj) => date2String(new Date(dateObj.date)) === dateStr,
    ),
  );

  return (
    <>
      {suggestedDates.includes(date2String(date)) ? (
        <Typography fontWeight={"bold"} color={"red"}>
          {label}
        </Typography>
      ) : (
        <Typography>{label}</Typography>
      )}
      {getConstructionAmountDescriptions(dateConstructions).map((d, idx) => (
        <Typography
          key={`construction-amount-desc-${idx}`}
          style={{ fontSize: 10 }}
        >
          {d}
        </Typography>
      ))}
    </>
  );
}

DateHeader.propTypes = {
  label: PropTypes.string,
  constructions: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.instanceOf(Date),
  suggestedDates: PropTypes.arrayOf(PropTypes.string),
};

export default function ScheduleProjectModal({
  project,
  isOpen,
  setIsOpen,
  resetParent,
  session,
}) {
  const [constructions, setConstructions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [suggestedDates, setSuggestedDates] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [view, setView] = useState("month");
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [showDate, setShowDate] = useState(null);

  const clickRef = useRef();

  useEffect(() => {
    // if (!isOpen) clearData()
    if (isOpen) loadData();
  }, [isOpen]);

  useEffect(() => {
    const handleWindowResize = () => setWinHeight(window.innerHeight);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const loadData = () => {
    setLoaded(false);
    const range = getCalendarRange(new Date(), view);
    updateConstructionsAndTasks(range).then(() => setLoaded(true));
    axios
      .get(API_URL_CONSTRUCTIONDATESUGGESTION, {
        params: { project: project.id },
      })
      .then((res) => setSuggestedDates(res.data));
  };

  const updateConstructionsAndTasks = (range) => {
    const promises = [];

    promises.push(
      axios
        .get(API_URL_CONSTRUCTION, {
          params: {
            start_date: date2String(range.start),
            end_date: date2String(range.end),
          },
        })
        .then((res) => {
          setConstructions(res.data);
        }),
    );

    promises.push(
      axios
        .get(API_URL_TASK, {
          params: {
            start_date: date2String(range.start),
            end_date: date2String(range.end),
          },
        })
        .then((res) => {
          setTasks(res.data);
        }),
    );

    return Promise.all(promises);
  };

  const onRangeChange = (range) => {
    // const range = getCalendarRange(date, view)
    if (Array.isArray(range)) {
      range = { start: range[0], end: range[range.length - 1] };
    }
    updateConstructionsAndTasks(range);
  };

  const onSelectSlot = useCallback((slotInfo) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      if (slotInfo.action === "doubleClick") {
        setCalendarDate(slotInfo.start);
        setView("work_week");
      } else setShowDate(slotInfo.start); // setView('day')
    }, 250);
  }, []);

  const legend = constructions
    .filter((c) => c.color_label && c.color)
    .reduce(function (r, c) {
      r[c.color_label] = r[c.color_label] || c.color;
      return r;
    }, {});

  const constructions2calendarEventData = (constructions) => {
    return constructions
      .filter(
        (c) => c.constructiondates_set && c.constructiondates_set.length > 0,
      )
      .flatMap((item) => {
        return item.constructiondates_set.map((dateObj) => {
          const startdate = new Date(`${dateObj.date}`);
          const enddate = new Date(`${dateObj.date}`);
          return {
            title: `${item.customer_name}`,
            start: startdate,
            end: enddate,
            allDay: true,
            event: item,
            backgroundColor: item.color || "#142b70",
            type: "construction",
            tentative: !item.construction_date_confirmed,
          };
        });
      });
  };

  const tasks2calendarEventData = (tasks) => {
    return tasks.map((item) => {
      const startdate = moment(
        date2String(item.date) + " " + item.start_time,
      ).toDate();
      const enddate = moment(
        date2String(item.date) + " " + item.end_time,
      ).toDate();
      const color = item.finished ? "#B3B3B3" : "#404040";
      const customerTitle =
        item.customer_name && item.customer_name !== ""
          ? ` (${item.customer_name})`
          : "";
      return {
        title: `${item.title}${customerTitle}`,
        start: startdate,
        end: enddate,
        event: item,
        backgroundColor: color,
        type: "task",
        tentative: false,
      };
    });
  };

  const suggestedDateStrs = (suggestedDates || []).map((sd) => sd.date);

  const components =
    view === "month"
      ? {
          month: {
            dateHeader: (props) => (
              <DateHeader
                constructions={constructions}
                suggestedDates={suggestedDateStrs}
                {...props}
              />
            ),
          },
        }
      : {};

  return (
    <CustomModal
      title={
        project
          ? `${project.customer_obj.name} ${project.zip_and_city_project}`
          : ""
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="fullscreen"
    >
      {!loaded ? (
        <LoadingPage />
      ) : (
        <>
          <br />
          <ScheduleProjectAtDateModal
            project={project}
            constructions={constructions}
            tasks={tasks}
            date={showDate}
            isOpen={!!showDate}
            setIsOpen={(isOpen) => {
              if (!isOpen) setShowDate(null);
            }}
            resetParent={() => {
              setIsOpen(false);
              resetParent();
            }}
            session={session}
          />
          <Fragment>
            <Stack spacing={2} direction="column" style={{ marginTop: "20px" }}>
              {view !== "month" ? (
                <span>
                  <div style={{ float: "right" }}>
                    <Stack direction="row" spacing={2}>
                      {Object.entries(legend).map(
                        ([colorLabel, color], cIdx) => (
                          <Tooltip
                            key={`color-avatar-${cIdx}`}
                            title={colorLabel}
                          >
                            <Avatar sx={{ bgcolor: color }}>
                              {colorLabel
                                .split(" ")
                                .filter((x) => ![" ", ""].includes(x))
                                .map((x) => x[0])
                                .join("")}
                            </Avatar>
                          </Tooltip>
                        ),
                      )}
                    </Stack>
                  </div>
                </span>
              ) : null}
              <Calendar
                className="secondary-textcolor"
                views={["day", "month", "work_week"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                date={calendarDate}
                onNavigate={(newDate) => setCalendarDate(newDate)}
                view={view}
                onView={setView}
                style={{
                  height: isMobileOnly
                    ? `${winHeight - 150}px`
                    : `${winHeight - 100}px`,
                }}
                scrollToTime={moment().set({ h: 8, m: 0 }).toDate()}
                events={
                  view === "month"
                    ? getHolidayEvents()
                    : constructions2calendarEventData(constructions)
                        .concat(getHolidayEvents())
                        .concat(tasks2calendarEventData(tasks))
                }
                onSelectSlot={onSelectSlot}
                onRangeChange={onRangeChange}
                longPressThreshold={isMobileOnly ? 30 : 50}
                step={60}
                timeslots={1}
                eventPropGetter={(event) => {
                  const backgroundColor = event.backgroundColor;
                  return {
                    style: {
                      backgroundColor,
                      opacity: event.tentative ? 0.5 : 1,
                    },
                  };
                }}
                messages={germanCalendarMsgs}
                dayPropGetter={(day) => {
                  if (isHoliday(day, HOLIDAYREGION)) return {}; // holidays
                  if ([0, 6].includes(day.getDay())) return {}; // weekend
                  const dayConstructions = constructions.filter((c) =>
                    c.constructiondates_set.some(
                      (dateObj) =>
                        date2String(new Date(dateObj.date)) ===
                        date2String(day),
                    ),
                  );
                  return {
                    style: {
                      backgroundColor:
                        getConstructionDateColor(dayConstructions),
                      opacity: 0.4,
                    },
                  };
                }}
                components={components}
              />
            </Stack>
          </Fragment>
          <br />
          <Typography>Vorgeschlagene Termine für Kleinbaustellen</Typography>
          {suggestedDates ? (
            <Table className={"table-hover"}>
              <thead>
                <tr>
                  <th
                    className="align-middle"
                    style={{ width: "15%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Datum
                    </Typography>
                  </th>
                  <th
                    className="align-middle"
                    style={{ width: "75%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">
                      Baustelle
                    </Typography>
                  </th>
                  <th
                    className="align-middle"
                    style={{ width: "10%", textAlign: "left" }}
                  >
                    <Typography className="secondary-textcolor">KWp</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {suggestedDates.map((sd, index) => (
                  <tr
                    key={`suggested-date-${sd.date}`}
                    onClick={() => setShowDate(string2Date(sd.date))}
                  >
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      <Link
                        to={"/projekt/" + sd.project.id + "/bauplanung"}
                        style={{ textDecoration: "none" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <Typography className="secondary-textcolor">
                          {string2FormattedString(sd.date)}
                        </Typography>
                      </Link>
                    </td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      <Link
                        to={"/projekt/" + sd.project.id + "/bauplanung"}
                        style={{ textDecoration: "none" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <Typography className="secondary-textcolor">
                          {projectNameWithLabel(sd.project)}
                        </Typography>
                      </Link>
                    </td>
                    <td className="align-middle" style={{ textAlign: "left" }}>
                      <Link
                        to={"/projekt/" + sd.project.id + "/bauplanung"}
                        style={{ textDecoration: "none" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <Typography className="secondary-textcolor">
                          {numFormatter(sd.kwp)}
                        </Typography>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </>
      )}
    </CustomModal>
  );
}

ScheduleProjectModal.propTypes = {
  project: projectPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
