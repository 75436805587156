import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { Fade, Typography } from "@mui/material";
import { Table } from "react-bootstrap";
import { Form, Input, Spinner } from "reactstrap";
import { API_URL_COMMISSIONTYPE } from "../../settings";
import axios from "axios";
import LoadingPage from "../../elements/LoadingPage";
import { CustomButton } from "../../elements/StyledElements";
import { Stack } from "@mui/system";
import { defaultIfEmpty, isString, swapItem } from "../../elements/utils";

export default function CommissionDefaultAmountSettings({
  projectType,
  session,
}) {
  const [commissionTypes, setCommissionTypes] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  const origCommissionTypes = useRef(null);

  useEffect(() => {
    loadData();
  }, [projectType]);

  const loadData = () => {
    axios
      .get(API_URL_COMMISSIONTYPE, { params: { project_type: projectType } })
      .then((res) => {
        const commissionData = res.data;
        swapItem(
          commissionData,
          "Zusätzliche Optimierer",
          "Zusätzliche Batteriemodule",
        );
        setCommissionTypes(commissionData);
        origCommissionTypes.current = JSON.parse(JSON.stringify(res.data));
      });
  };

  const editCommissionAmount = (commissionType, newAmount) => {
    setCommissionTypes((cts) => {
      const _commissionTypes = [...cts];
      const _commissionType = _commissionTypes.find(
        (a) => a.id === commissionType.id,
      );
      const _origCommissionType = origCommissionTypes.current.find(
        (a) => a.id === commissionType.id,
      );
      const number = newAmount;
      _commissionType.default = number || number === 0 ? number : null;
      _commissionType.changed =
        _commissionType.default !== _origCommissionType.default;
      return _commissionTypes;
    });
  };

  const editCommissionTotal = (commissionType, newAmount) => {
    setCommissionTypes((cts) => {
      const _commissionTypes = [...cts];
      const _commissionType = _commissionTypes.find(
        (a) => a.id === commissionType.id,
      );
      const _origCommissionType = origCommissionTypes.current.find(
        (a) => a.id === commissionType.id,
      );
      const number = newAmount;
      _commissionType.total = number || number === 0 ? number : null;
      _commissionType.changedTotal =
        _commissionType.total !== _origCommissionType.total;
      return _commissionTypes;
    });
  };

  const save = () => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    if (
      commissionTypes
        .filter((c) => c.changed)
        .some(
          (c) =>
            [null, undefined, ""].includes(c.default) ||
            (c.unit === "" && [null, undefined, ""].includes(c.total)),
        )
    ) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen",
        inProgress: false,
      });
      return;
    }
    axios
      .put(
        API_URL_COMMISSIONTYPE,
        commissionTypes.filter((c) => c.changed || c.changedTotal),
      )
      .then(() =>
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        }),
      )
      .catch(() => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      })
      .then(() => {
        setCommissionTypes(null);
        loadData();
      });
  };

  if (!commissionTypes) return <LoadingPage />;
  const colWidth = `${Math.ceil(80 / commissionTypes.length)}%`;

  return (
    <Form>
      <Stack direction={"row"} spacing={1}>
        <CustomButton
          disabled={loadingElements.inProgress}
          onClick={() => save()}
        >
          Speichern
        </CustomButton>
        {loadingElements.inProgress ? <Spinner size="sm" /> : null}
        {loadingElements.submitError ? (
          <Fade in={!!loadingElements.submitError}>
            <Typography color={"red"}>
              {isString(loadingElements.submitError)
                ? loadingElements.submitError
                : "Anfrage konnte nicht an Server übermittelt werden!"}
            </Typography>
          </Fade>
        ) : null}
      </Stack>
      <br />
      <Typography fontSize="h6.fontSize" className="secondary-textcolor">
        Default-Werte
      </Typography>
      <Typography className="secondary-textcolor">
        Wenn Default-Beträge geändert werden, werden alle Provisionen von
        Mitarbeitern mit der Höhe des vorherigen Default-Wertes automatisch
        angepasst. Provisionen anderer Höhe werden nicht geändert.
      </Typography>
      <Table>
        <thead>
          <tr>
            <th className="align-middle" style={{ width: "250px" }}>
              <Typography className="secondary-textcolor">Provision</Typography>
            </th>
            <th style={{ width: colWidth }} className="align-middle">
              <Typography className="secondary-textcolor">Betrag</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {commissionTypes.map((commissionType) => {
            const disabled = false;
            const textColorStyle = commissionType.changed
              ? { color: "#ff0000" }
              : disabled
                ? { color: "#a6a5a2" }
                : {};
            return (
              <tr
                key={`commissionamount-row-commission-type-${commissionType.id}`}
              >
                <td>
                  <Typography className="secondary-textcolor">{`${commissionType.name}`}</Typography>
                </td>
                <td>
                  <Stack direction="row" spacing={1}>
                    <Input
                      id="input"
                      style={{
                        ...textColorStyle,
                        textAlign: "right",
                        boxShadow: "none",
                        width: "30px",
                        padding: "0px 0px 0px 0px",
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                        border: "none",
                      }}
                      type="number"
                      name="commission"
                      value={defaultIfEmpty(commissionType.default)}
                      autoComplete="off"
                      disabled={disabled}
                      onChange={(e) =>
                        editCommissionAmount(
                          commissionType,
                          e.target.valueAsNumber,
                        )
                      }
                      min={0}
                      onKeyDown={(e) => {
                        if (e.key === "-") e.preventDefault();
                      }}
                    />
                    <Typography
                      style={textColorStyle}
                      className="secondary-textcolor"
                    >
                      {commissionType.unit}
                    </Typography>
                  </Stack>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Typography fontSize="h6.fontSize" className="secondary-textcolor">
        Gesamtbeträge
      </Typography>
      <Typography className="secondary-textcolor">
        Wenn Gesamtbeträge geändert werden, werden die Zahlungen aller Verkäufe
        bzw. aller Baustellen angepasst, die ab diesem Zeitpunkt angenommen bzw.
        abgeschlossen werden. Das betrifft auch Baustellen, die bereits
        abgeschlossen sind, bei denen jedoch erneut auf Abschließen geklickt
        wird.
      </Typography>
      <Table>
        <thead>
          <tr>
            <th className="align-middle" style={{ width: "250px" }}>
              <Typography className="secondary-textcolor">Provision</Typography>
            </th>
            <th style={{ width: colWidth }} className="align-middle">
              <Typography className="secondary-textcolor">
                Gesamtbetrag
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {commissionTypes
            .filter((c) => c.unit === "")
            .map((commissionType) => {
              const disabled = false;
              const textColorStyle = commissionType.changedTotal
                ? { color: "#ff0000" }
                : disabled
                  ? { color: "#a6a5a2" }
                  : {};
              return (
                <tr
                  key={`commissiontotalamount-row-commission-type-${commissionType.id}`}
                >
                  <td>
                    <Typography className="secondary-textcolor">{`${commissionType.name}`}</Typography>
                  </td>
                  <td>
                    <Stack direction="row" spacing={1}>
                      <Input
                        id="input"
                        style={{
                          ...textColorStyle,
                          textAlign: "right",
                          boxShadow: "none",
                          width: "30px",
                          padding: "0px 0px 0px 0px",
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                          border: "none",
                        }}
                        type="number"
                        name="commission_total"
                        value={defaultIfEmpty(commissionType.total)}
                        autoComplete="off"
                        disabled={disabled}
                        onChange={(e) =>
                          editCommissionTotal(
                            commissionType,
                            e.target.valueAsNumber,
                          )
                        }
                        min={0}
                        onKeyDown={(e) => {
                          if (e.key === "-") e.preventDefault();
                        }}
                      />
                      <Typography
                        style={textColorStyle}
                        className="secondary-textcolor"
                      >
                        €
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Form>
  );
}

CommissionDefaultAmountSettings.propTypes = {
  projectType: PropTypes.string,
  session: PropTypes.object,
};
