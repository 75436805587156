import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Image } from "react-konva";
import PropTypes from "prop-types";

import { getImageWindowWithAuth } from "../../../elements/utils";
import {
  heatpumpImagePropType,
  heatpumpPropType,
} from "../../../elements/PropTypes";

const MAXSTAGEWIDTH = 600;
const MAXSTAGEHEIGHT = 450;

export default function HeatpumpImage({
  heatpumpImage,
  heatpump,
  height = null,
  width = null,
  maxWidth = null,
  maxHeight = null,
}) {
  const image = heatpumpImage.image;
  const {
    scale_w: scaleW,
    scale_h: scaleH,
    image_width_edit: scaleR,
    image_width_m: imageWidthM,
  } = heatpumpImage;
  const position = { x: heatpumpImage.x, y: heatpumpImage.y };
  const angle = heatpumpImage.angle;

  const [imageState, setImageState] = useState({
    stageWidth: null,
    stageHeight: null,
    imageScale: null,
    imageWindow: null,
  });

  const [heatpumpImageScale, setHeatpumpImageScale] = useState({
    w: 1,
    h: 1,
  });
  const [heatpumpImageWindow, setHeatpumpImageWindow] = useState(null);

  const origHeatpumpWidthPx = useRef(null);
  const origHeatpumpHeightPx = useRef(null);

  useEffect(() => loadImage(), [image, heatpump]);

  const loadImage = () => {
    if (image === null) {
      setImageState({ ...imageState, imageWindow: null });
      return;
    }
    getImageWindowWithAuth(image, handleLoad);
  };

  const handleLoad = (newImageWindow) => {
    let scalerW, scalerH;
    if (height) {
      scalerH = height / newImageWindow.height;
      scalerW = maxWidth ? maxWidth / newImageWindow.width : Infinity;
    } else if (width) {
      scalerW = width / newImageWindow.width;
      scalerH = maxHeight ? maxHeight / newImageWindow.height : Infinity;
    } else {
      const maxStageWidth = maxWidth || MAXSTAGEWIDTH;
      scalerW = maxStageWidth / newImageWindow.width;
      const maxStageHeight = maxHeight || MAXSTAGEHEIGHT;
      scalerH = maxStageHeight / newImageWindow.height;
    }
    const scaler = scalerW >= scalerH ? scalerH : scalerW;
    const stageWidth = newImageWindow.width * scaler;
    const stageHeight = newImageWindow.height * scaler;
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight,
    });

    getImageWindowWithAuth(heatpump.image, (newImageWindow) =>
      handleHeatpumpLoad(newImageWindow, stageWidth),
    );
  };

  const handleHeatpumpLoad = (newImageWindow, stageWidth) => {
    setHeatpumpImageWindow(newImageWindow);
    if (!scaleR) {
      origHeatpumpWidthPx.current = newImageWindow.width;
      origHeatpumpHeightPx.current = newImageWindow.height;

      const heatpumpWidthM = heatpump.width;
      const heatpumpWidthPx = (stageWidth * heatpumpWidthM) / imageWidthM;
      const scalerW = heatpumpWidthPx / origHeatpumpWidthPx.current;
      const heatpumpHeightPx =
        (heatpump.height / heatpump.width) * heatpumpWidthPx;
      const scalerH = heatpumpHeightPx / origHeatpumpHeightPx.current;
      setHeatpumpImageScale({ w: scalerW, h: scalerH });
    }
  };

  if (imageState.imageWindow === null || heatpumpImageWindow === null)
    return null;

  return (
    <Stage
      width={imageState.stageWidth}
      height={imageState.stageHeight}
      listening={false}
    >
      <Layer>
        <Image
          rotation={angle != null ? angle : 0}
          id="heatpumplocationimage"
          image={imageState.imageWindow}
          scaleX={imageState.imageScale}
          scaleY={imageState.imageScale}
        />

        <Image
          id="heatpump"
          key="heatpump"
          image={heatpumpImageWindow}
          scaleX={
            !scaleR
              ? heatpumpImageScale.w
              : scaleW * (imageState.stageWidth / scaleR)
          }
          scaleY={
            !scaleR
              ? heatpumpImageScale.h
              : scaleH * (imageState.stageWidth / scaleR)
          }
          x={position.x * imageState.stageWidth}
          y={position.y * imageState.stageHeight}
        />
      </Layer>
    </Stage>
  );
}

HeatpumpImage.propTypes = {
  heatpumpImage: heatpumpImagePropType,
  heatpump: heatpumpPropType,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
