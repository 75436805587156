import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { PropTypes } from "prop-types";

import TimeManagementCalendar from "./time_management/TimeManagementCalendar";

export default function TimeManagement({ session }) {
  return (
    <Fragment>
      <Container xs="auto">
        <br />
        {session.user ? <TimeManagementCalendar session={session} /> : null}
        <br />
      </Container>
    </Fragment>
  );
}

TimeManagement.propTypes = {
  session: PropTypes.object.isRequired,
};
