import React, { useState } from "react";
import { PropTypes } from "prop-types";

import CustomModal from "../shared/modal_utils/CustomModal";
import { IconButton, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { Input } from "reactstrap";
import { taskPropType } from "../../elements/PropTypes";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_TASKCOMMENT } from "../../settings";
import { defaultIfEmpty } from "../../elements/utils";
import axios from "axios";

const emptyCommentForm = {
  task: null,
  text: null,
  created_date: null,
  created_time: null,
  author: null,
  author_name: null,
};

export default function AddCommentModal({
  task = null,
  save,
  getOpenButton,
  isOpen,
  setIsOpen,
  session,
}) {
  const [commentForm, setCommentForm] = useState({ ...emptyCommentForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (isOpen)
      setCommentForm({
        ...emptyCommentForm,
        task: task && task.id,
        author: session.user.id,
        author_name: `${session.user.first_name} ${session.user.last_name}`,
      });
  };

  const _getOpenButton = (toggle) => {
    if (isOpen != null) return null;
    return (
      <IconButton
        size="small"
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        onClick={toggle}
      >
        <Add style={{ color: "#424242" }} fontSize="small" />
        <Typography className="secondary-textcolor">
          Kommentar hinzufügen
        </Typography>
      </IconButton>
    );
  };

  const _save = async () => {
    // if (!save && !task) throw "No save method but no task provided"
    setLoadingElements((l) => ({ ...l, inProgress: true, submitError: false }));

    const timestamp = new Date();
    const finalCommentForm = {
      ...commentForm,
      created_date: timestamp.toISOString().split("T")[0],
      created_time: timestamp.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };
    // const promise = (save) ? save(commentForm) :
    return (
      task
        ? axios.post(API_URL_TASKCOMMENT, finalCommentForm)
        : Promise.resolve()
    )
      .then(() => {
        save && save(finalCommentForm);
      })
      .then(() => {
        setLoadingElements((l) => ({
          ...l,
          inProgress: false,
          submitError: false,
        }));
      })
      .catch((e) => {
        console.error(e);
        setLoadingElements((l) => ({
          ...l,
          inProgress: false,
          submitError: true,
        }));
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onCancel={toggle}
        cancelBtnLabel="Schließen"
        onSave={() => {
          _save().then(toggle);
        }}
        saveBtnLabel="Speichern"
      />
    );
  };

  return (
    <CustomModal
      size="xl"
      title={"Neuer Kommentar"}
      getFooter={getFooter}
      getOpenButton={getOpenButton || _getOpenButton}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onToggle={onToggle}
    >
      <Stack direction="column" spacing={2}>
        <Input
          id="input"
          type="textarea"
          name="description"
          onChange={(e) =>
            setCommentForm((f) => ({ ...f, text: e.target.value }))
          }
          value={defaultIfEmpty(commentForm.text)}
          required={true}
          style={{ boxShadow: "none", margin: "10px 0px" }}
        />
      </Stack>
    </CustomModal>
  );
}

AddCommentModal.propTypes = {
  task: taskPropType,
  save: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
