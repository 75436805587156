import React, { useEffect, useRef } from "react";

import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";

import Add from "@mui/icons-material/Add";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Typography, IconButton } from "@mui/material";
import { ibanToBic } from "iban-to-bic";
import DatePicker from "react-datepicker";
import EMeterFormEntry from "../../../project_types/plant_project/operator_requests/EMeterFormEntry";

import AddressInput from "../../../../elements/AddressInput";
import {
  customerPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import {
  date2String,
  getRandomId,
  string2Date,
} from "../../../../elements/utils";
import { emptyEMeterForm } from "./forms";

export default function PlantProjectForm({
  project,
  setProject,
  customer,
  showMissingFields,
  showAllMissingFields,
  session,
}) {
  if (!project) return null;

  const bankingInformationModified = useRef(false);
  const pickerRef1 = useRef(null);

  const fillBIC = (iban) => {
    const bic = ibanToBic(iban);
    if (bic) setProject((p) => ({ ...p, bic_project: bic }));
    else setProject((p) => ({ ...p, bic_project: "" }));
  };

  const onBankingChange = (e) => {
    bankingInformationModified.current = true;
    onChange(e);
  };

  const onChange = (e) => {
    setProject((c) => ({ ...c, [e.target.name]: e.target.value }));
  };

  const changeEMeter = (eMeterId, attr, value) => {
    setProject((p) => {
      const newEMeters = [...p.emeter_set];
      const eMeter = newEMeters.find((e) => e.id === eMeterId);
      eMeter[attr] = value;
      return { ...p, emeter_set: newEMeters };
    });
  };

  const addEMeter = () => {
    setProject((p) => ({
      ...p,
      emeter_set: [
        ...p.emeter_set,
        { ...emptyEMeterForm, project: project.id, id: -getRandomId() },
      ],
    }));
  };

  useEffect(() => {
    if (bankingInformationModified.current) fillBIC(project.iban_project);
  }, [project.iban_project]);

  return (
    <Form>
      <Typography
        fontSize="h6.fontSize"
        className="secondary-textcolor"
        display="inline"
      >
        Addresse
      </Typography>
      <AddressInput
        address={{
          street_and_number: project.street_and_number_project,
          zip_and_city: project.zip_and_city_project,
          province: project.province_project,
        }}
        setAddress={(address) =>
          setProject((p) => ({
            ...p,
            street_and_number_project: address.street_and_number,
            zip_and_city_project: address.zip_and_city,
            province_project: address.province,
          }))
        }
        setStreetAndNumber={(streetAndNumber) =>
          setProject((p) => ({
            ...p,
            street_and_number_project: streetAndNumber,
          }))
        }
        setZipAndCity={(zipAndCity) =>
          setProject((p) => ({ ...p, zip_and_city_project: zipAndCity }))
        }
        setProvince={(province) =>
          setProject((p) => ({ ...p, province_project: province }))
        }
        showMissingFields={showMissingFields}
        session={session}
      />
      <hr className="secondary-textcolor" />
      <FormGroup>
        <Typography className="secondary-textcolor">Geburtsdatum:</Typography>
        <DatePicker
          ref={pickerRef1}
          dateFormat="dd/MM/yyyy"
          locale="de"
          selected={
            project.birth_date_project
              ? string2Date(project.birth_date_project)
              : customer.birth_date
                ? string2Date(customer.birth_date)
                : null
          }
          onChange={(date) => {
            setProject((c) => ({
              ...c,
              birth_date_project: date ? date2String(date) : null,
            }));
          }}
          maxDate={new Date()}
        />
      </FormGroup>

      <hr className="secondary-textcolor" />
      <Typography fontSize="h6.fontSize" className="secondary-textcolor">
        Kontodaten
      </Typography>

      <FormGroup>
        <Typography className="secondary-textcolor">Kontoinhaber</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="account_owner_project"
          value={project.account_owner_project}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !project.account_owner_project)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">IBAN</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="iban_project"
          value={project.iban_project}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !project.iban_project)}
        />
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Bank</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="bank_project"
          value={project.bank_project}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !project.bank_project)}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">BIC</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          type="text"
          name="bic_project"
          value={project.bic_project}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !project.bic_project)}
        />
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Steuernummer</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: "none" }}
          name="tax_number_project"
          value={project.tax_number_project}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !project.tax_number_project)}
        />
      </FormGroup>
      <hr className="secondary-textcolor" />
      <Typography
        fontSize="h6.fontSize"
        className="secondary-textcolor"
        display="inline"
      >
        Zähler
      </Typography>
      {showAllMissingFields &&
      (!project.emeter_set || project.emeter_set.length === 0) ? (
        <div>
          &nbsp;&nbsp;
          <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
        </div>
      ) : null}
      <br />
      {project.emeter_set.map((eMeter, eIdx) => (
        <EMeterFormEntry
          key={eIdx}
          eMeter={eMeter}
          idx={eIdx}
          setProject={setProject}
          changeEMeter={changeEMeter}
          showMissingFields={showAllMissingFields}
        />
      ))}
      <div>
        <IconButton
          size="medium"
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={addEMeter}
        >
          <Add style={{ color: "#424242" }} fontSize="large" />
          <Typography className="secondary-textcolor">
            Zähler hinzufügen
          </Typography>
        </IconButton>{" "}
        <br />
      </div>
    </Form>
  );
}

PlantProjectForm.propTypes = {
  showMissingFields: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  project: projectPropType,
  customer: customerPropType,
  setProject: PropTypes.func,
  session: PropTypes.object,
};
