import { PropTypes } from "prop-types";
import React, { Fragment, useState } from "react";

import { IconButton, Tooltip, Typography, Link } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import CollapsibleTable from "../../elements/CollapsibleTable";
import { taskPropType } from "../../elements/PropTypes";
import AttachmentModal from "./AttachmentModal";

export default function AttachmentList({
  task,
  deleteAttachment,
  header = true,
  session,
}) {
  const [chosenAttachment, setChosenAttachment] = useState(null);

  const attachments = task.taskattachment_set.filter((att) => !att.deleted);

  if (!attachments || attachments.length === 0) return null;

  return (
    <Fragment>
      {deleteAttachment ? (
        <CollapsibleTable
          columns={[
            { name: "Anhänge", key: "name" },
            ...(deleteAttachment
              ? [
                  {
                    name: "",
                    key: "delete",
                    clickable: false,
                    style: { textAlign: "right" },
                  },
                ]
              : []),
          ]}
          rows={attachments.map((att) => ({
            key: `task-att-${task.id}`,
            name: att.name,
            link: () => setChosenAttachment(att),
            delete: (
              <Tooltip key={`delete-att-${att.id}`} title="Löschen">
                <IconButton
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  size="small"
                  onClick={() => {
                    deleteAttachment && deleteAttachment(att);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ),
          }))}
          collapsible={false}
          header={header}
          buttomLine={header}
        />
      ) : (
        attachments.map((att, aIdx) => (
          <Typography
            key={`task-attachment-${aIdx}`}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            <i>{att.uploader_name || "Unbekannt"}:</i>{" "}
            <Link onClick={() => setChosenAttachment(att)}>{att.name}</Link>
          </Typography>
        ))
      )}
      <AttachmentModal
        attachment={chosenAttachment}
        isOpen={!!chosenAttachment}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            setChosenAttachment(null);
          }
        }}
        session={session}
      />
    </Fragment>
  );
}

AttachmentList.propTypes = {
  task: taskPropType,
  deleteAttachment: PropTypes.func,
  header: PropTypes.bool,
  session: PropTypes.object,
};
