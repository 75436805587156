import React, { useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BiCategoryAlt } from "react-icons/bi";
import { IconButton, Tooltip } from "@mui/material";

import ProductForm from "./ProductForm";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_PRODUCT, API_URL_PRODUCTPHOTO } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import { productPropType, customerPropType } from "../../elements/PropTypes";
import { isString, removeFromObj } from "../../elements/utils";

const emptyProductForm = {
  id: null,
  priority: null,
  key: null,
  name: null,
  price: null,
  description: null,
  quantity_description: "Stk.",
  in_pv_simulation: null,
  vat: null,
  customer: null,
  product_group: [],
  productphotos_set: [],
};

export default function ProductFormModal({
  product,
  customer,
  resetParent,
  session,
  style,
  productGroup,
  groupIcon,
}) {
  const [productForm, setProductForm] = useState({ ...emptyProductForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const getOpenButton = (toggle) => {
    if (groupIcon) {
      return (
        <Tooltip
          title="Produktgruppe"
          PopperProps={{ style: { zIndex: 9999 } }}
        >
          <IconButton
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent", float: "right" }}
            size="large"
            onClick={toggle}
            sx={{ p: "5px" }}
          >
            <BiCategoryAlt className="secondary-textcolor" fontSize="34px" />
          </IconButton>
        </Tooltip>
      );
    }
    if (product) {
      return (
        <Tooltip
          title="Produktinformationen"
          PopperProps={{ style: { zIndex: 9999 } }}
        >
          <IconButton
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent", float: "right" }}
            size="small"
            onClick={toggle}
          >
            <InfoOutlinedIcon
              className="secondary-textcolor"
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <CustomButton onClick={toggle} style={style}>
        {" "}
        Neues Produkt erstellen{" "}
      </CustomButton>
    );
  };

  const submit = async (productForm) => {
    let photos = null;
    if (productForm.productphotos_set) {
      if (!isString(productForm.productphotos_set)) {
        photos = productForm.productphotos_set;
      }
      productForm = removeFromObj(productForm, ["productphotos_set"]);
    }

    const promise = product
      ? await axios.put(API_URL_PRODUCT + product.id, productForm)
      : await axios.post(API_URL_PRODUCT, productForm);

    const { data } = promise;
    const { id } = data;
    await getImagePromises(id, photos, API_URL_PRODUCTPHOTO, getImagePromise);
    return promise;
  };

  const onSubmit = async (onSuccess) => {
    const optionalKeys = [
      "key",
      "customer",
      "product_group",
      "priority",
      "productphotos_set",
    ];
    if (productForm.priority === "") productForm.priority = null;
    if (!product) optionalKeys.push("id");
    const productKeys = Object.keys(productForm).filter(
      (x) => !optionalKeys.includes(x),
    );
    const emptyFields = productKeys.filter(
      (key) => productForm[key] === "" || productForm[key] === null,
    );
    if (emptyFields.length !== 0) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        showMissingFields: true,
      });
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(productForm)
      .then((res) => {
        const resetSearch = groupIcon || product ? false : res.data;
        resetParent(resetSearch);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const isNewObj = (obj) => obj.id <= 0;

  const getImagePromises = async (productId, images, url, getPromise) => {
    const deletePromise = product
      ? axios.delete(url, {
          data: {
            product: productId,
            except_ids: images.filter((i) => !isNewObj(i)).map((i) => i.id),
          },
        })
      : Promise.resolve();
    return deletePromise.then(() =>
      Promise.all(images.map((image) => getPromise(productId, image, url))),
    );
  };

  const getImagePromise = async (productId, image, url) => {
    const formData = new FormData();
    if (!isString(image.image)) {
      formData.append("image", image.image, image.image.name);
    } else return Promise.resolve();
    formData.append("product", productId);
    if (image.id < 0) return axios.post(url, formData);
    formData.append("id", image.id);
    return axios.put(url + image.id, formData);
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setProductForm({ ...emptyProductForm });
  };

  const loadData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    let tempProductForm = product || { ...emptyProductForm };
    if (tempProductForm.id) {
      axios
        .get(API_URL_PRODUCTPHOTO, { params: { product: tempProductForm.id } })
        .then(async (res) => {
          const photos = res.data;
          tempProductForm = { ...tempProductForm, productphotos_set: photos };
          setProductForm(tempProductForm);
        });
    } else {
      setProductForm(tempProductForm);
    }
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Produktinformationen"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <ProductForm
        product={productForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setProduct={setProductForm}
        customer={customer}
        productGroup={productGroup}
        groupIcon={groupIcon}
      />
    </CustomModal>
  );
}

ProductFormModal.propTypes = {
  product: productPropType,
  customer: customerPropType,
  resetParent: PropTypes.func,
  groupIcon: PropTypes.bool,
  session: PropTypes.object,
  style: PropTypes.object,
  productGroup: PropTypes.array,
};
