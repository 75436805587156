import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Table } from "react-bootstrap";
import { PropTypes } from "prop-types";
import axios from "axios";

import { Typography, Stack, IconButton } from "@mui/material";
import { CustomButton, CustomIconButton } from "../../elements/StyledElements";
import DeleteIcon from "@mui/icons-material/Delete";
import MultiSelect from "../../elements/MultiSelect";
import CustomModal from "../shared/modal_utils/CustomModal";

import { isMobileOnly } from "react-device-detect";
import { defaultIfEmpty } from "../../elements/utils";
import WarehouseDeliveryAmountModal from "./WarehouseDeliveryAmountModal";
import { API_URL_WAREHOUSEDELIVERYSUPPLIER } from "../../settings";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";

const columnNames = [
  "Datum",
  "Zulieferer",
  "Kommission",
  "Besteller",
  "Lieferort",
  "Lieferdatum",
  "Status",
  "Aktion",
];
const statusNames = ["Bestellt", "Ausstand Lieferant", "Vollständig"];

export default function WarehouseDeliverySupplier({
  session,
  warehouseProducts,
  suppliers,
  employee,
  resetParent,
}) {
  const rowsPerPage = 50;
  const [nPages, setNPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([1, 2, 6, 7]);

  const [warehouseDeliverySuppliers, setWarehouseDeliverySuppliers] = useState(
    [],
  );

  useEffect(() => {
    resetState(0);
  }, []);

  useEffect(() => {
    resetState(0);
  }, [selectedSuppliers, selectedStatuses]);

  const resetState = (newPage) => {
    axios
      .get(API_URL_WAREHOUSEDELIVERYSUPPLIER, {
        params: {
          batch_size: rowsPerPage,
          batch: newPage,
          suppliers: selectedSuppliers.join(","),
          statuses: selectedStatuses.map((idx) => statusNames[idx]).join(","),
        },
      })
      .then((res) => {
        setWarehouseDeliverySuppliers(res.data);
        setNPages(res.headers.length);
        setCurrentPage(newPage);
      });
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton
        color="black"
        style={{
          margin: "8px 0",
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        onClick={toggle}
      >
        <Typography variant={isMobileOnly ? "body1" : "h6"}>
          Lieferrungen
        </Typography>
      </CustomButton>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      title="Lieferrungen"
      getOpenButton={getOpenButton}
    >
      <Fragment>
        <Container>
          <Stack direction="column" spacing={2} style={{ marginTop: 20 }}>
            <MultiSelect
              onChange={(val) => setSelectedSuppliers(val || [])}
              options={suppliers.map((supplier) => ({
                value: supplier.id,
                label: supplier.name,
              }))}
              values={defaultIfEmpty(selectedSuppliers)}
              text="Zulieferer"
              search={true}
            />
            <MultiSelect
              onChange={(val) => setSelectedStatuses(val || [])}
              options={statusNames.map((statusName, idx) => ({
                value: idx,
                label: statusName,
              }))}
              values={defaultIfEmpty(selectedStatuses)}
              text="Status"
              search={true}
            />
            <MultiSelect
              onChange={(val) => setSelectedColumns(val || [])}
              options={columnNames.map((columnName, idx) => ({
                value: idx,
                label: columnName,
              }))}
              values={defaultIfEmpty(selectedColumns)}
              text="Tabellenspalten"
              search={true}
            />
            <Table className="table-not-hover">
              <thead>
                <tr>
                  {columnNames.map((columnName, idx) => {
                    return selectedColumns.includes(idx) ? (
                      <th
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {columnName}
                        </Typography>
                      </th>
                    ) : null;
                  })}
                </tr>
              </thead>
              <tbody>
                {warehouseDeliverySuppliers.map((row) => (
                  <tr key={row.id}>
                    {selectedColumns.includes(0) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.create_date}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(1) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {suppliers.find((e) => e.id === row.supplier)?.name}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(2) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.comission_name}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(3) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.employee_name}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(4) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.address}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(5) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.delivery_date}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(6) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", wordWrap: "break-word" }}
                      >
                        <Typography className="secondary-textcolor">
                          {row.status}
                        </Typography>
                      </td>
                    )}
                    {selectedColumns.includes(7) && (
                      <td
                        className="align-middle"
                        style={{ maxWidth: "100px", alignItems: "center" }}
                      >
                        <Typography
                          className="secondary-textcolor"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <WarehouseDeliveryAmountModal
                            resetParent={resetParent}
                            resetState={() => {
                              resetState(currentPage);
                            }}
                            isInfo={true}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts}
                            suppliers={suppliers}
                            session={session}
                            employee={employee}
                          />
                          <WarehouseDeliveryAmountModal
                            resetParent={resetParent}
                            resetState={() => {
                              resetState(currentPage);
                            }}
                            isInfo={false}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts}
                            session={session}
                            employee={employee}
                          />
                          <ConfirmationModal
                            resetParent={() => {
                              resetState(currentPage);
                            }}
                            confirm={() =>
                              axios.delete(
                                API_URL_WAREHOUSEDELIVERYSUPPLIER + row.id,
                              )
                            }
                            title={`Soll '${row.comission_name}' wirklich aus Sortiment entfernt werden?`}
                            getOpenButton={(toggle) => (
                              <IconButton onClick={toggle}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          />
                        </Typography>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Stack direction="row" spacing={2}>
              <CustomIconButton
                disabled={currentPage === 0}
                icon="previous"
                onClick={() => {
                  resetState(currentPage - 1);
                }}
              />
              <Typography className="secondary-textcolor">
                {currentPage + 1}
              </Typography>
              <CustomIconButton
                disabled={currentPage + 1 >= nPages}
                icon="next"
                onClick={() => {
                  resetState(currentPage + 1);
                }}
              />
            </Stack>
          </Stack>
        </Container>
      </Fragment>
    </CustomModal>
  );
}

WarehouseDeliverySupplier.propTypes = {
  session: PropTypes.object.isRequired,
  warehouseProducts: PropTypes.array,
  suppliers: PropTypes.array,
  warehouseDeliverySuppliers: PropTypes.array,
  employee: PropTypes.number,
  resetParent: PropTypes.func,
};
