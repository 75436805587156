import React, { useState } from "react";

import { PropTypes } from "prop-types";
import "react-datepicker/dist/react-datepicker.css";

import ConstructionModal from "../project_types/ConstructionModal";
import ChooseProjectToScheduleModal from "./ChooseProjectToScheduleModal";

export default function ConstructionTimeManagementModal({
  chosenDate,
  isOpen,
  setIsOpen,
  resetParent,
  session,
}) {
  const [chosenProject, setChosenProject] = useState(null);

  return (
    <>
      <ChooseProjectToScheduleModal
        setChosenProject={setChosenProject}
        chosenDate={chosenDate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <ConstructionModal
        project={chosenProject}
        chosenDate={chosenDate}
        resetParent={resetParent}
        session={session}
        isOpen={!!chosenProject}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenProject(null);
        }}
      />
    </>
  );
}

ConstructionTimeManagementModal.propTypes = {
  chosenDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
