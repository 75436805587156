import React, { useState } from "react";

import { PropTypes } from "prop-types";

import CustomModal from "../modal_utils/CustomModal";
import { CustomButton } from "../../../elements/StyledElements";
import { heatpumpPropType } from "../../../elements/PropTypes";
import { round } from "../../../elements/utils";
import HeatpumpImagePlanning from "./HeatpumpImagePlanning";

export default function EditHeatpumpImagePlanning({
  image,
  defaultImage,
  setImage,
  heatpump,
  heatpumpPosition,
  setHeatpumpPosition,
  angle,
  setAngle = null,
  heatpumpImageScale,
  setHeatpumpImageScale,
  winWidth,
  winHeight,
}) {
  const [currentPosition, setCurrentPosition] = useState(heatpumpPosition);
  const [currentAngle, setCurrentAngle] = useState(angle);
  const [currentScale, setCurrentScale] = useState(heatpumpImageScale);
  const [currentImage, setCurrentImage] = useState(image);

  const save = () => {
    if (setHeatpumpPosition) setHeatpumpPosition(currentPosition);
    if (setAngle) setAngle(currentAngle);
    if (setHeatpumpImageScale) setHeatpumpImageScale(currentScale);
    if (setImage) setImage(currentImage);
  };

  const reset = () => {
    setCurrentPosition(heatpumpPosition);
    setCurrentAngle(angle);
    setCurrentScale(heatpumpImageScale);
    setCurrentImage(image);
  };

  const getOpenButton = (toggle) => (
    <CustomButton onClick={toggle}>Bearbeiten</CustomButton>
  );
  const getFooter = (toggle) => (
    <CustomButton
      onClick={() => {
        save();
        toggle();
      }}
      color={"#ffffff"}
    >
      Speichern
    </CustomButton>
  );

  return (
    <CustomModal
      title="Dachfotos bearbeiten"
      size="fullscreen"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      onToggle={reset}
    >
      <HeatpumpImagePlanning
        image={currentImage}
        defaultImage={defaultImage}
        setImage={setCurrentImage}
        heatpump={heatpump}
        heatpumpPosition={currentPosition}
        setHeatpumpPosition={setCurrentPosition}
        angle={currentAngle}
        setAngle={setAngle ? setCurrentAngle : null}
        heatpumpImageScale={currentScale}
        setHeatpumpImageScale={setCurrentScale}
        maxWidth={round((95 / 100) * winWidth)}
        maxHeight={round((80 / 100) * winHeight)}
      />
    </CustomModal>
  );
}

EditHeatpumpImagePlanning.propTypes = {
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  setImage: PropTypes.func,
  heatpump: heatpumpPropType,
  heatpumpPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  setHeatpumpPosition: PropTypes.func,
  angle: PropTypes.number,
  setAngle: PropTypes.func,
  heatpumpImageScale: PropTypes.shape({
    w: PropTypes.number,
    h: PropTypes.number,
    r: PropTypes.number,
  }),
  setHeatpumpImageScale: PropTypes.func,
  winWidth: PropTypes.number,
  winHeight: PropTypes.number,
};
