import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { splitList } from "./utils";

export default function TransferList({
  onChange,
  options,
  values,
  sort = false,
}) {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const [checked, setChecked] = useState([]);

  const [right, left] = splitList(dropdownOptions, (o) =>
    values.includes(o.value),
  );

  const leftChecked = left
    .filter((o) => checked.includes(o.value))
    .map((o) => o.value);
  const rightChecked = right
    .filter((o) => checked.includes(o.value))
    .map((o) => o.value);

  useEffect(() => {
    const newOptions = options
      ? options.map((s) => {
          return Object.prototype.toString.call(s) === "[object Object]"
            ? s
            : {
                value: s,
                label:
                  toString.call(s) === "[object String]" ? s : s.toString(),
              };
        })
      : [];
    if (sort) {
      newOptions.sort((a, b) => {
        if (a.order === "first" && b.order !== "first") return -1;
        if (a.order !== "first" && b.order === "first") return 1;
        if (a.order === "last" && b.order !== "last") return 1;
        if (a.order !== "last" && b.order === "last") return -1;
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }
    setDropdownOptions(newOptions);
  }, [options]);

  useEffect(() => {
    setChecked([]);
  }, [values]);

  const handleToggle = (value) => () => {
    const optionChecked = checked.includes(value);
    const newChecked = !optionChecked
      ? [...checked, value]
      : checked.filter((x) => x !== value);
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    // all from left to right
    onChange(dropdownOptions.map((x) => x.value));
  };

  const handleCheckedRight = () => {
    // add to selected
    onChange([...leftChecked, ...values]);
  };

  const handleCheckedLeft = () => {
    // remove from selected
    onChange(values.filter((x) => !rightChecked.includes(x)));
  };

  const handleAllLeft = () => {
    // all from right to left
    onChange([]);
  };

  const customList = (options) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {options.map((option) => {
          const labelId = `transfer-list-item-${option.value}-label`;

          return (
            <ListItem
              key={option.value}
              role="listitem"
              button
              onClick={handleToggle(option.value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(option.value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={option.label} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ),
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ),
  sort: PropTypes.instanceOf(Date),
};
