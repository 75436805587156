import React, { useEffect, useMemo, useRef, useState } from "react";

import axios from "axios";
import moment from "moment";
import "moment/locale/de";
import { PropTypes } from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { isMobileOnly } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import TaskFormModal from "../tasks/TaskFormModal";
import ConstructionDate from "./ConstructionDate";

import { isHoliday } from "feiertagejs";
import LoadingPage from "../../elements/LoadingPage";
import {
  API_URL_USER,
  API_URL_CONSTRUCTION,
  API_URL_TASK,
  API_URL_PLANVILLELOCATION,
  API_URL_TASKTYPE,
  API_URL_LABEL,
  API_URL_TASKLABEL,
  API_URL_EMPLOYEE_LABEL,
} from "../../settings";
import {
  HOLIDAYREGION,
  addDaysToDate,
  date2String,
  defaultIfEmpty,
  germanCalendarMsgs,
  getCalendarRange,
  getHolidayEvents,
  hexToRGB,
  string2Date,
  useDidMountEffect,
} from "../../elements/utils";
import {
  ProjectType2ConstructionType,
  constructionType2ProjectType,
  getConstructionDateColor,
  projectTypes,
} from "../project_types/projectUtils";
import "../shared/calendarStyle.css";
import { Col, Container, Row, FormGroup, Input } from "reactstrap";
import { Avatar, IconButton, Stack, Tooltip } from "@mui/material";
import { CustomButton } from "../../elements/StyledElements";
import DropDown from "../../elements/DropDown";
import ScheduleModal from "./ScheduleModal";
import MultiSelect from "../../elements/MultiSelect";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// require('moment/locale/de.js')
moment.locale("de-DE");
const localizer = momentLocalizer(moment);

export default function ConstructionCalendar({ session }) {
  const [cookies, setCookie] = useCookies([
    "employee",
    "projectType",
    "planvilleLocation",
    "postalCode",
    "taskType",
  ]);
  const [loaded, setLoaded] = useState(false);

  // events
  const [constructions, setConstructions] = useState([]);
  const [tasks, setTasks] = useState([]);

  // filters
  const [showFilter, setShowFilter] = useState(false);
  const [isCurrentUserFiltering, setIsCurrentUserFiltering] = useState(false);
  // filter options
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredProjectTypes, setFilteredProjectTypes] =
    useState(projectTypes);
  const [planvilleLocations, setPlanvilleLocations] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskLabels, setTaskLabels] = useState([]);
  const [projectLabels, setProjectLabels] = useState([]);
  const [employeeLabels, setEmployeeLabels] = useState([]);

  // chosen options
  const [employee, setEmployee] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [location, setLocation] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [projectLabel, setProjectLabel] = useState(null);
  const [taskLabel, setTaskLabel] = useState(null);
  const [employeeLabel, setEmployeeLabel] = useState(null);
  const [filter, setFilter] = useState({
    employee: null,
    projectType: [],
    location: [],
    postalCode: null,
    taskType: [],
    projectLabel: [],
    taskLabel: [],
    employeeLabel: [],
  });

  // open modals when selecting construction / task / date
  const [chosenTask, setChosenTask] = useState(null);
  const [chosenDate, _setChosenDate] = useState(null);

  // calendar
  const [view, setView] = useState("month");

  // window size
  const [winHeight, setWinHeight] = useState(window.innerHeight);

  const allTasks = useRef([]);
  const allConstructions = useRef([]);
  const calendarRange = useRef(getCalendarRange(new Date(), view));
  const dataRange = useRef(null);
  const allEmployees = useRef([]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWinHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const setPersistentCookie = (name, value) => {
    const farFutureDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 10),
    );
    setCookie(name, value, { path: "/", expires: farFutureDate });
  };

  const setChosenDate = (date) => {
    if (date) searchParams.set("date", date2String(date));
    else searchParams.delete("date");
    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (searchParams.get("date"))
      _setChosenDate(string2Date(searchParams.get("date")));
    else _setChosenDate(null);
  }, [searchParams]);

  useEffect(() => {
    loadData();
  }, []);

  useDidMountEffect(() => {
    updateTasks();
    updateConstructions();
    updateFilter();
  }, [
    employee,
    projectType,
    location,
    postalCode,
    taskType,
    taskLabel,
    projectLabel,
    employeeLabel,
  ]);

  const updateTasks = () => setTasks(filterTasks());
  const updateConstructions = () => setConstructions(filterConstructions());
  const updateFilter = () =>
    setFilter({
      employee,
      projectType,
      location,
      postalCode,
      taskType,
      taskLabel,
      projectLabel,
      employeeLabel,
    });

  const loadData = () => {
    setLoaded(false);

    // reset data
    allConstructions.current = [];
    allEmployees.current = [];
    allTasks.current = [];
    dataRange.current = null;

    const promises = [];
    promises.push(
      axios
        .get(API_URL_USER, {
          params: { is_staff: true, visible: true, is_active: true },
        })
        .then((res) => res.data)
        .then((_employees) => {
          setFilteredEmployees(_employees);
          allEmployees.current = _employees;
          // set filters
          setEmployee(cookies.employee || null);
          setProjectType(cookies.projectType || null);
          setLocation(cookies.planvilleLocation || null);
          setPostalCode(cookies.postalCode || null);
          setTaskType(cookies.taskType || null);
          setProjectLabel(cookies.projectLabel || null);
          setTaskLabel(cookies.taskLabel || null);
          setEmployeeLabel(cookies.employeeLabel || null);
        }),
    );

    promises.push(
      axios
        .get(API_URL_PLANVILLELOCATION)
        .then((res) => setPlanvilleLocations(res.data)),
    );
    promises.push(
      axios.get(API_URL_TASKTYPE).then((res) => setTaskTypes(res.data)),
    );
    promises.push(
      axios.get(API_URL_LABEL).then((res) => setProjectLabels(res.data)),
    );
    promises.push(
      axios.get(API_URL_TASKLABEL).then((res) => setTaskLabels(res.data)),
    );
    promises.push(
      axios
        .get(API_URL_EMPLOYEE_LABEL)
        .then((res) => setEmployeeLabels(res.data)),
    );
    promises.push(loadEvents(calendarRange.current));

    Promise.all(promises).then(() => {
      setLoaded(true);
    });
  };

  const loadEvents = async (range) => {
    const promises = [];
    promises.push(
      axios
        .get(API_URL_CONSTRUCTION, {
          params: {
            start_date: date2String(range.start),
            end_date: date2String(range.end),
            project__declined: false,
          },
        })
        .then((res) => res.data)
        .then((_constructions) => {
          const newConstructionIds = _constructions.map((c) => c.id);
          allConstructions.current = [
            ...allConstructions.current.filter(
              (c) => !newConstructionIds.includes(c.id),
            ),
            ..._constructions,
          ];
          updateConstructions();
        }),
    );
    promises.push(
      axios
        .get(API_URL_TASK, {
          params: {
            start_date: date2String(range.start),
            end_date: date2String(range.end),
          },
        })
        .then((res) => res.data)
        .then((_tasks) => {
          const newTaskIds = _tasks.map((t) => t.id);
          allTasks.current = [
            ...allTasks.current.filter((t) => !newTaskIds.includes(t.id)),
            ..._tasks,
          ];
          updateTasks();
        }),
    );

    return Promise.all(promises).then(() => {
      const prevRange = dataRange.current;
      dataRange.current =
        prevRange === null
          ? range
          : {
              start: Math.min(range.start, prevRange.start),
              end: Math.max(range.end, prevRange.end),
            };
    });
  };

  const filterConstructions = () => {
    let _constructions = [...allConstructions.current];
    const isOnlyTaskFiltersActive =
      ((taskType && taskType.length > 0) ||
        (taskLabel && taskLabel.length > 0)) &&
      !employee &&
      (!location || location.length === 0) &&
      !postalCode &&
      (!projectLabel || projectLabel.length === 0) &&
      (!employeeLabel || employeeLabel.length === 0) &&
      (!projectType || projectType.length === 0);
    if (isOnlyTaskFiltersActive) return [];
    if (projectType && projectType.length > 0)
      _constructions = _constructions.filter((c) =>
        projectType.includes(constructionType2ProjectType(c.resourcetype)),
      );
    if (employee)
      _constructions = _constructions.filter((c) =>
        c.employees.includes(employee),
      );
    if (location && location.length > 0)
      _constructions = _constructions.filter((c) =>
        location.includes(c.planville_location),
      );
    if (postalCode)
      _constructions = _constructions.filter((c) =>
        c.zip_and_city.startsWith(postalCode),
      );
    if (projectLabel && projectLabel.length > 0)
      _constructions = _constructions.filter(
        (c) =>
          c.project_label_details &&
          projectLabel.some((label) =>
            c.project_label_details.some((l) => l.id === label),
          ),
      );
    if (employeeLabel && employeeLabel.length > 0)
      _constructions = _constructions.filter(
        (c) =>
          c.employees_labels &&
          c.employees_labels
            .flat()
            .some(
              (el) =>
                el.employee_label &&
                el.employee_label.some((e) =>
                  employeeLabel.includes(e.label.id),
                ),
            ),
      );
    return _constructions;
  };

  const filterTasks = () => {
    let _tasks = [...allTasks.current];
    const isOnlyProjectFiltersActive =
      ((projectType && projectType.length > 0) ||
        (projectLabel && projectLabel.length > 0)) &&
      !employee &&
      (!location || location.length === 0) &&
      !postalCode &&
      (!taskType || taskType.length === 0) &&
      (!employeeLabel || employeeLabel.length === 0) &&
      (!taskLabel || taskLabel.length === 0);
    if (isOnlyProjectFiltersActive) return [];
    if (taskType && taskType.length > 0)
      _tasks = _tasks.filter((t) => taskType.includes(t.task_type));
    if (employee) _tasks = _tasks.filter((t) => t.employees.includes(employee));
    if (location && location.length > 0)
      _tasks = _tasks.filter((t) =>
        location.includes(t.project_planville_location),
      );
    if (postalCode)
      _tasks = _tasks.filter(
        (t) => t.address && t.address.includes(postalCode),
      );
    if (taskLabel && taskLabel.length > 0)
      _tasks = _tasks.filter(
        (t) => t.labels && taskLabel.some((l) => t.labels.includes(l)),
      );
    if (employeeLabel && employeeLabel.length > 0)
      _tasks = _tasks.filter(
        (t) =>
          t.employees_labels &&
          t.employees_labels
            .flat()
            .some(
              (el) =>
                el.employee_label &&
                el.employee_label.some((e) =>
                  employeeLabel.includes(e.label.id),
                ),
            ),
      );
    return _tasks;
  };

  const onRangeChange = (newRange) => {
    if (Array.isArray(newRange)) {
      newRange = { start: newRange[0], end: newRange[newRange.length - 1] };
    }
    calendarRange.current = newRange;
    if (!dataRange.current) {
      loadEvents(calendarRange.current);
    } else {
      const promise = Promise.resolve();
      if (calendarRange.current.start < dataRange.current.start) {
        setLoaded(false);
        promise.then(() =>
          loadEvents({
            start: calendarRange.current.start,
            end: addDaysToDate(dataRange.current.start, -1),
          }),
        );
      }
      if (calendarRange.current.end > dataRange.current.end) {
        setLoaded(false);
        promise.then(() =>
          loadEvents({
            start: addDaysToDate(dataRange.current.end, 1),
            end: calendarRange.current.end,
          }),
        );
      }
      promise.then(() => setLoaded(true));
    }
  };

  const onEmployeeChange = (employee) => {
    setEmployee(employee);
    setPersistentCookie("employee", employee);

    if (!employee) {
      setFilteredProjectTypes(projectTypes);
    } else {
      const relatedProjectTypes = constructions
        .filter((construction) => construction.employees.includes(employee))
        .map((construction) =>
          constructionType2ProjectType(construction.resourcetype),
        );
      const uniqueProjectTypeKeys = [...new Set(relatedProjectTypes)];
      const uniqueProjectTypes = projectTypes.filter((pt) =>
        uniqueProjectTypeKeys.includes(pt.key),
      );
      setFilteredProjectTypes(uniqueProjectTypes);
    }
  };

  const onProjectTypeChange = (projectType) => {
    setProjectType(projectType);
    setPersistentCookie("projectType", projectType);

    if (!projectType) {
      setFilteredEmployees(allEmployees.current);
    } else {
      const relatedEmployees = constructions
        .filter(
          (construction) =>
            construction.resourcetype ===
            ProjectType2ConstructionType(projectType),
        )
        .flatMap((construction) => construction.employees);
      const uniqueEmployeeIds = [...new Set(relatedEmployees)];
      const uniqueEmployees = allEmployees.current.filter((e) =>
        uniqueEmployeeIds.includes(e.id),
      );
      setFilteredEmployees(uniqueEmployees);
    }
  };

  const onLocationChange = (location) => {
    setLocation(location);
    setPersistentCookie("planvilleLocation", location);
  };

  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value);
    setPersistentCookie("postalCode", e.target.value);
  };

  const onTaskTypeChange = (type) => {
    setTaskType(type);
    setPersistentCookie("taskType", type);
  };

  const onProjectLabelChange = (label) => {
    setProjectLabel(label);
    setPersistentCookie("projectLabel", label);
  };

  const onTaskLabelChange = (label) => {
    setTaskLabel(label);
    setPersistentCookie("taskLabel", label);
  };
  const onEmployeeLabelChange = (label) => {
    setEmployeeLabel(label);
    setPersistentCookie("employeeLabel", label);
  };

  const resetFilter = () => {
    setEmployee(null);
    setProjectType(null);
    setLocation(null);
    setPostalCode(null);
    setTaskType(null);
    setProjectLabel(null);
    setTaskLabel(null);
    setEmployeeLabel(null);
    setPersistentCookie("employee", null);
    setPersistentCookie("projectType", null);
    setPersistentCookie("planvilleLocation", null);
    setPersistentCookie("postalCode", null);
    setPersistentCookie("taskType", null);
    setPersistentCookie("projectLabel", null);
    setPersistentCookie("taskLabel", null);
    setPersistentCookie("employeeLabel", null);
  };

  const resetState = () => loadData();

  const constructions2calendarEventData = (constructions) => {
    return constructions
      .filter(
        (c) => c.constructiondates_set && c.constructiondates_set.length > 0,
      )
      .flatMap((item) => {
        return item.constructiondates_set.map((dateObj) => {
          const startdate = new Date(`${dateObj.date}`);
          const enddate = new Date(`${dateObj.date}`);
          return {
            title: `${item.customer_name}`,
            start: startdate,
            end: enddate,
            allDay: true,
            event: item,
            backgroundColor: item.color || "#142b70",
            type: "construction",
            tentative: !item.construction_date_confirmed,
          };
        });
      });
  };

  const tasks2calendarEventData = (tasks) => {
    return tasks
      .filter((item) => item.taskdates_set && item.taskdates_set.length > 0)
      .flatMap((item) => {
        return item.taskdates_set.map((dateObj) => {
          const startdate = moment(
            date2String(dateObj.date) + " " + item.start_time,
          ).toDate();
          const enddate = moment(
            date2String(dateObj.date) + " " + item.end_time,
          ).toDate();
          const color = item.finished ? "#B3B3B3" : "#404040";
          const customerTitle =
            item.customer_name && item.customer_name !== ""
              ? ` (${item.customer_name})`
              : "";
          return {
            title: `${item.title}${customerTitle}`,
            start: startdate,
            end: enddate,
            event: item,
            backgroundColor: color,
            type: "task",
            tentative: false,
          };
        });
      });
  };

  const constructions2monthEventData = (constructions) => {
    const dateMap = {};

    constructions
      .filter(
        (c) => c.constructiondates_set && c.constructiondates_set.length > 0,
      )
      .forEach((item) => {
        item.constructiondates_set.forEach((dateObj) => {
          const startDate = new Date(`${dateObj.date}`);
          const endDate = new Date(`${dateObj.date}`);
          const dateKey = startDate.toDateString();

          if (!dateMap[dateKey]) {
            dateMap[dateKey] = {
              start: startDate,
              end: endDate,
              title: "Baustellen: 0",
              count: 0,
              backgroundColor: "#142b70",
            };
          }

          dateMap[dateKey].count += 1;
          dateMap[dateKey].title = `Baustellen: ${dateMap[dateKey].count}`;
        });
      });

    return Object.values(dateMap);
  };

  const tasks2monthEventData = (tasks) => {
    const dateMap = {};

    tasks
      .filter((item) => item.taskdates_set && item.taskdates_set.length > 0)
      .forEach((item) => {
        item.taskdates_set.forEach((dateObj) => {
          const startDate = moment(
            date2String(dateObj.date) + " " + item.start_time,
          ).toDate();
          const endDate = moment(
            date2String(dateObj.date) + " " + item.end_time,
          ).toDate();
          const dateKey = startDate.toDateString();

          if (!dateMap[dateKey]) {
            dateMap[dateKey] = {
              start: startDate,
              end: endDate,
              title: "Aufgaben: 0",
              count: 0,
              backgroundColor: "#B3B3B3",
            };
          }

          dateMap[dateKey].count += 1;
          dateMap[dateKey].title = `Aufgaben: ${dateMap[dateKey].count}`;
        });
      });

    return Object.values(dateMap);
  };

  const legend = constructions
    .filter((c) => c.color_label && c.color)
    .reduce(function (r, c) {
      r[c.color_label] = r[c.color_label] || c.color;
      return r;
    }, {});

  const togglePersonalizeFilter = () => {
    setIsCurrentUserFiltering((current) => {
      const newState = !current;
      if (newState) {
        setConstructions(
          allConstructions.current.filter((construction) =>
            construction.employees.includes(session.user.id),
          ),
        );
        setTasks(
          allTasks.current.filter((task) =>
            task.employees.includes(session.user.id),
          ),
        );
      } else {
        setConstructions([...allConstructions.current]);
        setTasks([...allTasks.current]);
      }
      return newState;
    });
  };

  const customCalendarToolbar = (toolbar) => {
    const iconButtonStyle = {
      border: "1px solid black",
      borderRadius: "4px",
      padding: "1px",
    };
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton
            onClick={() => toolbar.onNavigate("TODAY")}
            style={iconButtonStyle}
          >
            <CalendarTodayIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            onClick={() => toolbar.onNavigate("PREV")}
            style={iconButtonStyle}
          >
            <NavigateBeforeIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            onClick={() => toolbar.onNavigate("NEXT")}
            style={iconButtonStyle}
          >
            <NavigateNextIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
        <span>{toolbar.label}</span>
        <div>
          <IconButton
            onClick={() => toolbar.onView("day")}
            style={iconButtonStyle}
          >
            <CalendarTodayIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            onClick={() => toolbar.onView("work_week")}
            style={iconButtonStyle}
          >
            <DateRangeIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton
            onClick={() => toolbar.onView("month")}
            style={iconButtonStyle}
          >
            <CalendarMonthIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        {loaded ? null : <LoadingPage />}
        {isMobileOnly && (
          <>
            <ScheduleModal resetParent={resetState} session={session} />
            <CustomButton
              icon="filter"
              description="Filter"
              onClick={() => setShowFilter((prevShowFilter) => !prevShowFilter)}
              style={{ backgroundColor: showFilter ? "lightgrey" : "" }}
            >
              Filter
            </CustomButton>
          </>
        )}
      </div>
      <Row>
        <Col>
          <Row className="mb-1" style={{ width: "100%" }}>
            <span
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <Stack direction="row" spacing={2}>
                  {Object.entries(legend).map(([colorLabel, color], cIdx) => (
                    <Tooltip key={`color-avatar-${cIdx}`} title={colorLabel}>
                      <Avatar sx={{ bgcolor: color }}>
                        {colorLabel
                          .split(" ")
                          .filter((x) => ![" ", ""].includes(x))
                          .map((x) => x[0])
                          .join("")}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Stack>
              </div>
              {!isMobileOnly && (
                <div>
                  <CustomButton
                    icon="filter"
                    description="Filter"
                    onClick={() =>
                      setShowFilter((prevShowFilter) => !prevShowFilter)
                    }
                    style={{
                      backgroundColor: showFilter ? "lightgrey" : "",
                      marginRight: "8px",
                    }}
                  >
                    Filter
                  </CustomButton>
                  <ScheduleModal resetParent={resetState} session={session} />
                </div>
              )}
            </span>
          </Row>
          {showFilter && (
            <Col style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobileOnly ? "column" : "row",
                    gap: isMobileOnly ? 0 : "1rem",
                  }}
                >
                  {!isCurrentUserFiltering && (
                    <FormGroup>
                      <DropDown
                        onChange={onEmployeeChange}
                        options={filteredEmployees.map((e) => ({
                          value: e.id,
                          label: e.name,
                        }))}
                        value={employee}
                        text="Mitarbeiter"
                        search={true}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <MultiSelect
                      onChange={onProjectTypeChange}
                      options={
                        filteredProjectTypes
                          ? filteredProjectTypes.map((p) => ({
                              value: p.key,
                              label: p.name,
                            }))
                          : null
                      }
                      values={defaultIfEmpty(projectType)}
                      text="Projekttyp"
                      search={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <MultiSelect
                      onChange={onTaskTypeChange}
                      options={taskTypes.map((t) => ({
                        value: t.id,
                        label: t.name,
                        props: {
                          style: { backgroundColor: hexToRGB(t.color, 0.5) },
                        },
                      }))}
                      values={defaultIfEmpty(taskType)}
                      text="Aufgaben-Typ"
                      search={true}
                      noOptionsText={"Keine Aufgaben-Typen"}
                    />
                  </FormGroup>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobileOnly ? "column" : "row",
                    gap: isMobileOnly ? 0 : "1rem",
                  }}
                >
                  {!isCurrentUserFiltering && (
                    <FormGroup>
                      <MultiSelect
                        onChange={onEmployeeLabelChange}
                        options={employeeLabels.map((e) => ({
                          value: e.id,
                          label: e.name,
                        }))}
                        values={defaultIfEmpty(employeeLabel)}
                        text="Mitarbeiter-Label"
                        search={true}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <MultiSelect
                      onChange={onProjectLabelChange}
                      options={projectLabels.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }))}
                      values={defaultIfEmpty(projectLabel)}
                      text="Projekt-Label"
                      search={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <MultiSelect
                      onChange={onTaskLabelChange}
                      options={taskLabels.map((t) => ({
                        value: t.id,
                        label: t.name,
                        props: {
                          style: { backgroundColor: hexToRGB(t.color, 0.5) },
                        },
                      }))}
                      values={defaultIfEmpty(taskLabel)}
                      text="Aufgaben-Label"
                      search={true}
                      noOptionsText={"Keine Aufgaben-Typen"}
                    />
                  </FormGroup>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobileOnly ? "column" : "row",
                    gap: isMobileOnly ? 0 : "1rem",
                  }}
                >
                  <FormGroup>
                    <Input
                      style={{
                        flex: 1,
                        backgroundColor: "#ffffff",
                        color: "#424242",
                        borderColor: "#424242",
                        boxShadow: "none",
                        width: "250px",
                      }}
                      type="text"
                      name="searchPostalCode"
                      onChange={onPostalCodeChange}
                      value={defaultIfEmpty(postalCode)}
                      placeholder="PLZ"
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup>
                    <MultiSelect
                      onChange={onLocationChange}
                      options={planvilleLocations.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }))}
                      values={defaultIfEmpty(location)}
                      text="Standort"
                      search={true}
                    />
                  </FormGroup>
                  <CustomButton
                    description="Filter zurücksetzen"
                    onClick={resetFilter}
                    style={{ width: "250px", height: "40px" }}
                  >
                    Filter zurücksetzen
                  </CustomButton>
                </div>
              </Col>
              <Col>
                {!employee && !isMobileOnly && (
                  <CustomButton
                    icon="person"
                    description="Persönliche Baustellen/Aufgaben"
                    onClick={togglePersonalizeFilter}
                    style={{
                      marginBottom: "10px",
                      backgroundColor: isCurrentUserFiltering
                        ? "lightgrey"
                        : "",
                    }}
                  >
                    Persönliche Baustellen/Aufgaben
                  </CustomButton>
                )}
              </Col>
              {!employee && isMobileOnly && (
                <CustomButton
                  icon="person"
                  description="Persönliche Baustellen/Aufgaben"
                  onClick={togglePersonalizeFilter}
                  style={{
                    marginBottom: "10px",
                    backgroundColor: isCurrentUserFiltering ? "lightgrey" : "",
                  }}
                >
                  Persönliche Baustellen/Aufgaben
                </CustomButton>
              )}
            </Col>
          )}
          <Row>
            <Calendar
              className="secondary-textcolor"
              views={["day", "work_week", "month"]}
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              view={view}
              onView={setView}
              style={{
                height: isMobileOnly
                  ? `${winHeight - 150}px`
                  : `${winHeight - 100}px`,
              }}
              scrollToTime={moment().set({ h: 8, m: 0 }).toDate()}
              events={
                view === "month"
                  ? constructions2monthEventData(constructions)
                      .concat(getHolidayEvents())
                      .concat(tasks2monthEventData(tasks))
                  : constructions2calendarEventData(constructions)
                      .concat(getHolidayEvents())
                      .concat(tasks2calendarEventData(tasks))
              }
              onSelectEvent={(event) => {
                if (event.type === "construction" && view !== "month")
                  history.push(
                    "/projekt/" + event.event.project + "/bauplanung",
                  );
                // setChosenConstruction(event.event)
                else if (event.type === "task" && view !== "month")
                  setChosenTask(event.event);
                else setChosenDate(event.start);
              }}
              onSelectSlot={(slot) => setChosenDate(slot.start)}
              onRangeChange={onRangeChange}
              longPressThreshold={isMobileOnly ? 30 : 50}
              step={60}
              timeslots={1}
              eventPropGetter={(event) => {
                const backgroundColor = event.backgroundColor;
                return {
                  style: {
                    backgroundColor,
                    opacity: event.tentative ? 0.5 : 1,
                  },
                };
              }}
              dayPropGetter={(day) => {
                if (isHoliday(day, HOLIDAYREGION)) return {}; // holidays
                if ([0, 6].includes(day.getDay())) return {}; // weekend
                const dayConstructions = constructions.filter(
                  (c) =>
                    c !== undefined &&
                    c.constructiondates_set.some(
                      (dateObj) =>
                        date2String(dateObj.date) === date2String(day),
                    ),
                );
                return {
                  style: {
                    backgroundColor:
                      date2String(new Date()) === date2String(day)
                        ? "#51555c"
                        : getConstructionDateColor(dayConstructions),
                    opacity: 0.7,
                  },
                };
              }}
              messages={germanCalendarMsgs}
              components={{
                month: {
                  event: ({ event }) => <div>{event.title}</div>,
                },
                toolbar: isMobileOnly ? customCalendarToolbar : undefined,
              }}
            />
            <ConstructionDate
              constructions={constructions}
              tasks={tasks}
              employees={allEmployees.current}
              date={chosenDate}
              isOpen={!!chosenDate}
              setIsOpen={(isOpen) => {
                if (!isOpen) setChosenDate(null);
              }}
              session={session}
              resetParent={resetState}
              filter={filter}
            />
            <TaskFormModal
              task={chosenTask}
              isOpen={!!chosenTask}
              setIsOpen={(isOpen) => {
                if (!isOpen) {
                  setChosenTask(null);
                }
              }}
              resetParent={resetState}
              session={session}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

ConstructionCalendar.propTypes = {
  session: PropTypes.object,
};
