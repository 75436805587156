import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import {
  API_URL_WAREHOUSECOMISSION,
  API_URL_WAREHOUSEDELIVERYSUPPLIER,
} from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import { Typography } from "@mui/material";
import { Input } from "reactstrap";
import PdfViewModal from "../shared/modal_utils/PdfViewModal";
import { date2String } from "../../elements/utils";

export default function WarehouseDeliveryEmailModal({
  resetParent,
  isSubmitted,
  setIsSubmitted,
  suppliers,
  warehouseProducts,
  warehouseComission,
  setWarehouseComission,
  productUpdates,
  style,
  employee,
  employees,
}) {
  const [currentSupplierIndex, setCurrentSupplierIndex] = useState(0);
  const [warehouseDeliverySuppliers, setWarehouseDeliverySuppliers] = useState(
    [],
  );
  const [supplierEmail, setSupplierEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  useEffect(() => {
    if (currentSupplierIndex >= warehouseDeliverySuppliers.length) {
      return;
    }
    const supplier = suppliers.find(
      (e) =>
        e.id === warehouseDeliverySuppliers[currentSupplierIndex].supplier_id,
    );
    setSupplierEmail(supplier?.email ? supplier?.email : "");
    setSubject(
      "Bestellung für Kommission " +
        warehouseComission.name +
        " von Mitarbeiter " +
        employees.find((e) => e.id === employee)?.name,
    );
    setBody(
      "Zulieferer:\n\t" +
        supplier?.name +
        "\n" +
        "Komission:\n\tKomission " +
        warehouseComission.name +
        "\n" +
        "Lieferort:\n\t" +
        warehouseComission.address +
        "\n" +
        "Zeitpunkt der Lieferung:\n\t" +
        warehouseComission.delivery_date +
        "\n" +
        "Besteller:\n\t" +
        employees.find((e) => e.id === employee)?.name,
    );
    // generate pdf
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["Materialname", "Ausführung bei Zulieferer", "Menge", "Kommentar"],
      ],
      body: warehouseDeliverySuppliers[
        currentSupplierIndex
      ].warehouse_products.map((row) => [
        warehouseProducts.find((e) => e.id === row.warehouse_product_id).name,
        warehouseProducts.find((e) => e.id === row.warehouse_product_id)
          .supplier_product_name,
        row.amount,
        row.comment,
      ]),
      startY: 20,
    });
    const obj = doc.output("blob");
    const url = URL.createObjectURL(obj);
    setPdfBlob(obj);
    setPdfUrl(url);
  }, [currentSupplierIndex, isSubmitted]);

  const submitComission = (toggle) => {
    const newList = [];
    for (let i = 0; i < productUpdates.length; i++) {
      if (
        newList.length > 0 &&
        productUpdates[i].supplier_id ===
          newList[newList.length - 1].supplier_id
      ) {
        newList[newList.length - 1].warehouse_products.push({
          warehouse_product_id: productUpdates[i].id,
          amount: productUpdates[i].amount,
          comment: productUpdates[i].comment,
        });
      } else {
        newList.push({
          supplier_id: productUpdates[i].supplier_id,
          warehouse_products: [
            {
              warehouse_product_id: productUpdates[i].id,
              amount: productUpdates[i].amount,
              comment: productUpdates[i].comment,
            },
          ],
        });
      }
    }

    axios
      .post(API_URL_WAREHOUSECOMISSION, {
        ...warehouseComission,
        delivery_date: date2String(warehouseComission.delivery_date),
      })
      .then((res) => {
        setWarehouseComission(res.data);
        axios
          .post(API_URL_WAREHOUSECOMISSION + res.data.id, newList)
          .then((res) => {
            setWarehouseDeliverySuppliers(res.data);
            setIsSubmitted(true);
            toggle();
          });
      });
  };

  const getOpenButton = (toggle) => {
    return isSubmitted ? (
      currentSupplierIndex >= warehouseDeliverySuppliers.length ? (
        <CustomButton onClick={resetParent} style={style}>
          {" "}
          Abschliessen{" "}
        </CustomButton>
      ) : (
        <CustomButton onClick={toggle} style={style}>
          {" "}
          Bestellen{" "}
        </CustomButton>
      )
    ) : (
      <CustomButton
        onClick={() => submitComission(toggle)}
        style={style}
        disabled={
          (!warehouseComission.project_id &&
            !warehouseComission.warehouse_address_id) ||
          productUpdates.length === 0 ||
          productUpdates.find((e) => !e.supplier_id || !e.amount)
        }
      >
        Bestellen
      </CustomButton>
    );
  };

  const submit = async () => {
    const formData = new FormData();
    formData.append(
      "file",
      pdfBlob,
      "Komission " + warehouseComission.name + ".pdf",
    );
    formData.append(
      "emails",
      JSON.stringify(
        [
          suppliers.find(
            (e) =>
              e.id ===
              warehouseDeliverySuppliers[currentSupplierIndex].supplier_id,
          )?.email,
          employees.find((e) => e.id === employee)?.email,
        ].filter((e) => !!e),
      ),
    );
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("comission", "Komission " + warehouseComission.name);
    return axios.post(
      API_URL_WAREHOUSEDELIVERYSUPPLIER +
        warehouseDeliverySuppliers[currentSupplierIndex].id,
      formData,
    );
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(warehouseComission)
      .then((res) => {
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        saveBtnLabel={"Überspringen"}
        completeBtnLabel={"Senden"}
        onSave={() => {
          currentSupplierIndex + 1 === warehouseDeliverySuppliers.length &&
            toggle();
          setCurrentSupplierIndex(currentSupplierIndex + 1);
        }}
        onComplete={() => {
          onSubmit(() => {
            currentSupplierIndex + 1 === warehouseDeliverySuppliers.length &&
              toggle();
            setCurrentSupplierIndex(currentSupplierIndex + 1);
          });
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Lieferort"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <br />
      <div style={{ position: "relative" }}>
        <Typography className="secondary-textcolor">
          Zulieferer Email:
        </Typography>
        <Input
          style={{ boxShadow: "none" }}
          type="text"
          value={supplierEmail}
          onChange={(e) => {
            setSupplierEmail(e.target.value);
          }}
        />
        <br />
        <Typography className="secondary-textcolor">Betreff:</Typography>
        <Input
          style={{ boxShadow: "none" }}
          type="text"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
        <br />
        <Typography className="secondary-textcolor">Nachricht:</Typography>
        <Input
          style={{ boxShadow: "none", height: "200px" }}
          type="textarea"
          value={body}
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
        <br />
        <PdfViewModal
          title={
            "Material für " +
            suppliers.find(
              (e) =>
                e.id ===
                warehouseDeliverySuppliers[currentSupplierIndex]?.supplier_id,
            )?.name
          }
          filepath={pdfUrl}
          getOpenButton={(toggle) => (
            <CustomButton onClick={toggle}>PDF anzeigen</CustomButton>
          )}
        />
      </div>
    </CustomModal>
  );
}

WarehouseDeliveryEmailModal.propTypes = {
  resetParent: PropTypes.func,
  isSubmitted: PropTypes.bool,
  setIsSubmitted: PropTypes.func,
  suppliers: PropTypes.array,
  warehouseProducts: PropTypes.array,
  warehouseComission: PropTypes.object,
  setWarehouseComission: PropTypes.func,
  productUpdates: PropTypes.array,
  style: PropTypes.object,
  employee: PropTypes.number,
  employees: PropTypes.array,
};
