import React from "react";

import { PropTypes } from "prop-types";
import { Typography } from "@mui/material";
import { CustomIconButton } from "../../elements/StyledElements";

import CustomizedCustomer from "./CustomizedCustomer";

export default function CustomizedCustomerTile({
  session,
  item,
  onEdit,
  isMobile,
  deleteTile,
}) {
  return (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            paddingTop: "10px",
            maxWidth: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.name}
        </Typography>
      </div>
      <CustomizedCustomer session={session} params={item?.data?.filters} />
      <div
        className="icon-container"
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          display: "flex",
        }}
      >
        <CustomIconButton icon="settings" onClick={onEdit} />
        <CustomIconButton icon="bin" onClick={() => deleteTile(item?.id)} />
      </div>
    </div>
  );
}
CustomizedCustomerTile.propTypes = {
  session: PropTypes.object,
  item: PropTypes.object,
  onEdit: PropTypes.func,
  isMobile: PropTypes.bool,
  deleteTile: PropTypes.func,
};
