// TODO: implement documentation form here
import React from "react";
import { Input, FormGroup } from "reactstrap";

import { Stack, Typography } from "@mui/material";

import { getRandomId, hasPermission } from "../../../../elements/utils";
import WarehouseUsedMaterialModal from "../../../warehouse/WarehouseUsedMaterialModal";

export const getEmployeeSignatureUpdate = (employees, signatureSet) => {
  return employees.map((eId) => {
    return (
      signatureSet.find((s) => s.employee === eId) || {
        id: -getRandomId(),
        image: null,
        employee: eId,
      }
    );
  });
};

const impulsProductKeys = ["wallbox_impuls", "wallbox", "impuls"];
export const getPlantConstructionFormTemplate = (
  project,
  form,
  employees,
  session,
  warehouseProducts,
) => {
  const electricians = employees.filter(
    (user) => user.group_key === "electricians",
  );
  const constructionManagers = employees.filter(
    (user) => user.group_key === "construction_managers",
  );
  const impulsProducts = project.construction_obj
    ? project.construction_obj.customproduct_set.filter((p) =>
        impulsProductKeys.includes(p.product_key),
      )
    : [];

  return [
    {
      type: "subtitle",
      text: "Gerüst",
    },
    {
      type: "checkbox",
      name: "Das Gerüst wurde ordnungsgemäß aufgebaut.",
      key: "framework_checked",
      optional: true,
    },
    {
      type: "images",
      name: "Gerüstfotos hochladen:",
      key: "constructionframeworkimage_set",
    },
    {
      type: "subtitle",
      text: "Fotos hochladen",
    },
    {
      type: "images",
      name: "Fotos der Anlage hochladen:",
      key: "constructionplantimage_set",
    },
    {
      type: "image",
      name: "Foto von offenem Zählerschrank ohne Verdeck (vor Umbau):",
      key: "emeter_before_image",
    },
    {
      type: "image",
      name: "Foto von offenem Zählerschrank ohne Verdeck (nach Umbau):",
      key: "emeter_after_image",
    },
    {
      type: "image",
      name: "Foto von installiertem Wechselrichter:",
      key: "inverter_image",
    },
    {
      type: "images",
      name: "Foto von Energie-Impuls inkl. Front des Gerätes mit lesbarem ID-Aufkleber:",
      key: "energieimpulsimage_set",
      condition: () => impulsProducts.length > 0,
    },
    {
      type: "subtitle",
      text: "Funktionstest",
    },
    {
      type: "checkbox",
      name: "Die Nullleitermessung wurde durchgeführt.",
      key: "neutral_conductor_checked",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Ich habe beide Strings gegen Erde gemessen und es waren weniger als 10 Volt.",
      key: "string_and_ground_volt",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Die Türklingel funktioniert.",
      key: "doorbell_is_working",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Der Fernseher funktioniert.",
      key: "tv_is_working",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Der Wechselrichter und die Batterie funktionieren.",
      key: "plant_checked",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Die Wechselrichterkommunikation ist eingerichtet.",
      key: "inverter_communication_set_up",
      isEmpty: (val) => !val,
    },
    {
      type: "checkbox",
      name: "Wärmepumpe: Drehfeld wurde vor und nach dem Umbau eingetragen.",
      key: "rotating_field_heat_pump",
      isEmpty: (val) => !val,
      condition: () => project.accepted_offer_obj.planning_obj.heatpump,
    },
    {
      type: "subtitle",
      text: "Sonstiges",
    },
    {
      type: "custom",
      getFieldHtml: (form, setForm, showMissingFields) => (
        <WarehouseUsedMaterialModal
          session={session}
          warehouseProducts={warehouseProducts}
          fromConstructionDoc={true}
          project={project}
        />
      ),
    },
    {
      type: "checkbox",
      name: "5-Sterne-Google-Bewertung erhalten.",
      key: "five_star_google_rating",
    },
    {
      type: "input_number",
      name: "Anzahl gesetzter Module:",
      key: "documentation_n_modules",
    },
    {
      type: "input_number",
      name: "Anzahl gesetzter Installierter Optimierer:",
      key: "documentation_n_optimizers",
    },
    {
      type: "custom",
      getFieldHtml: (form, setForm, showMissingFields) => (
        <FormGroup width={"30px"}>
          <Typography className="secondary-textcolor">
            Anzahl zusätzlicher PV-Module:{" "}
            <strong>{form.n_additional_modules}</strong>
          </Typography>
        </FormGroup>
      ),
    },
    {
      type: "custom",
      getFieldHtml: (form, setForm, showMissingFields) => (
        <FormGroup width={"30px"}>
          <Typography className="secondary-textcolor">
            Anzahl zusätzlicher Batteriemodule:{" "}
            <strong>{form.n_additional_battery_modules}</strong>
          </Typography>
        </FormGroup>
      ),
    },
    {
      type: "custom",
      getFieldHtml: (form, setForm, showMissingFields) => (
        <FormGroup width={"30px"}>
          <Typography className="secondary-textcolor">
            String Spannung berechnet:{" "}
          </Typography>
          {form.roofimage_set?.map((roofimage) => {
            const stringSet = roofimage.string_set.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
            return stringSet.map((string) => (
              <Typography
                key={`calculated-string-voltage ${string.id}`}
                className="secondary-textcolor"
              >
                {string.name}:{" "}
                <strong>{string.calculated_string_voltage}</strong>
              </Typography>
            ));
          })}
        </FormGroup>
      ),
    },
    {
      type: "custom",
      getFieldHtml: (form, setForm, showMissingFields) => (
        <Stack>
          <Typography className="secondary-textcolor">
            String Spannung gemessen:{" "}
          </Typography>
          {form.roofimage_set?.map((roofimage) => {
            const stringSet = roofimage.string_set.sort((a, b) =>
              a.name.localeCompare(b.name),
            );

            const onChange = (roofId, stringId, value) => {
              setForm((prevForm) => {
                const updatedForm = { ...prevForm };

                const roofimageIndex = updatedForm.roofimage_set.findIndex(
                  (roof) => roof.id === roofId,
                );
                if (roofimageIndex === -1) return prevForm;

                const stringIndex = updatedForm.roofimage_set[
                  roofimageIndex
                ].string_set.findIndex((string) => string.id === stringId);
                if (stringIndex === -1) return prevForm;

                updatedForm.roofimage_set[roofimageIndex].string_set[
                  stringIndex
                ].measured_string_voltage =
                  value === null ? null : parseFloat(value);

                return updatedForm;
              });
            };
            return stringSet.map((string) => (
              <FormGroup
                key={`measured-string-voltage ${string.id}`}
                width={"30px"}
              >
                <Typography className="secondary-textcolor">
                  {string.name}:
                </Typography>
                <Input
                  style={{ width: "30%", boxShadow: "none" }}
                  id="input"
                  type="number"
                  name={string.name}
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? null : Number(e.target.value);
                    onChange(roofimage.id, string.id, value);
                  }}
                  value={string.measured_string_voltage ?? ""}
                  autoComplete="off"
                  invalid={showMissingFields}
                />
              </FormGroup>
            ));
          })}
        </Stack>
      ),
    },
    {
      type: "subtitle",
      text: "Mitarbeiter",
    },
    {
      type: "dropdown",
      name: "Bauleiter:",
      key: "construction_manager",
      condition: () => constructionManagers,
      options: (constructionManagers || []).map((h) => ({
        label: h.name,
        value: h.id,
      })),
      additionalUpdate: (key, value, prevForm) => {
        const prevValue = prevForm[key];
        let employees = prevForm.employees;
        if (
          prevValue &&
          employees.includes(prevValue) &&
          ((value && !employees.includes(value)) || !value)
        ) {
          employees = employees.filter((x) => x !== prevValue);
        }
        if (value && !employees.includes(value)) {
          employees = [...employees, value];
        }
        return {
          employees,
          employeesafetybriefingsignature_set: getEmployeeSignatureUpdate(
            employees,
            prevForm.employeesafetybriefingsignature_set,
          ),
        };
      },
    },
    {
      type: "multiselect",
      name: "Elektrofachkraft:",
      key: "electricians",
      condition: () => electricians,
      options: (electricians || []).map((h) => ({
        label: h.name,
        hidden: !h.is_active,
        value: h.id,
      })),
      additionalUpdate: (key, value, prevForm) => {
        let employees = prevForm.employees || [];
        const prevValue = prevForm[key];
        if (prevValue) {
          prevValue.forEach((pv) => {
            if (!value.includes(pv)) {
              employees = employees.filter((x) => x !== pv);
            }
          });
        }
        if (value && !employees.includes(value)) {
          value.forEach((v) => {
            if (!employees.includes(v)) {
              employees = [...employees, v];
            }
          });
        }
        return {
          employees,
          employeesafetybriefingsignature_set: getEmployeeSignatureUpdate(
            employees,
            prevForm.employeesafetybriefingsignature_set,
          ),
        };
      },
    },
    {
      type: "multiselect",
      name: "Mitarbeiter auf der Baustelle:",
      key: "employees",
      condition: () => employees,
      optional: false,
      disabled: !hasPermission(session.user, "task_assignment"),
      options: employees.map((e) => ({
        label: e.name,
        value: e.id,
        hidden: !e.is_active,
        disabled:
          e.id === form.construction_manager ||
          form.electricians.includes(e.id) ||
          !hasPermission(session.user, "task_assignment"),
      })),
      additionalUpdate: (key, value, prevForm) => {
        const existingElectricians = electricians.map((e) => e.id);
        const newElectricians = value.filter((v) =>
          existingElectricians.includes(v),
        );
        return {
          employeesafetybriefingsignature_set: getEmployeeSignatureUpdate(
            value,
            prevForm.employeesafetybriefingsignature_set,
          ),
          electricians: newElectricians,
        };
      },
    },
    {
      type: "n_signatures",
      name: "Sicherheitsunterweisung:",
      key: "employeesafetybriefingsignature_set",
      subfields: form.employees
        .filter((e) => employees.map((_e) => _e.id).includes(e))
        .map((eId) => employees.find((e) => e.id === eId))
        .filter((e) => e)
        .map((e) => ({
          value_id: e.id,
          name: e.name,
          text: "Ich habe eine Sicherheitsunterweisung über das Tragen von Sicherheitsschuhen, Helm und Handschuhen sowie über ein ordnungemäßes Gerüst und entsprechende Warnhinweise erhalten.",
        })),
      isEmpty: (values) => values.some((v) => !v.image),
      valueIdKey: "employee",
    },
    {
      type: "signature",
      name: "Unterschrift Bauleiter:",
      key: "construction_manager_signature",
    },
    {
      type: "signature",
      name: "Unterschrift Elektrofachkraft:",
      key: "electrician_signature",
    },
  ];
};
