import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Input, Form, FormGroup } from "reactstrap";
import axios from "axios";

import { Typography } from "@mui/material";

import { API_URL_VEHICLECHECKLIST } from "../../settings";
import ConfirmationModalFooter from "../shared/modal_utils/ConfirmationModalFooter";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import "./styles.css";
import { getErrorMessage, isValidForm } from "../../elements/utils";
import ErrorMessage from "../../elements/ErrorMessage";
import { toast } from "react-toastify";

const emptyChecklistForm = {
  name: "",
};

const mandatoryFields = ["name"];

export default function CarChecklistFormModal({
  checklist,
  isOpen,
  setIsOpen,
  getOpenButton,
  resetParent,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [checklistForm, setChecklistForm] = useState({ ...emptyChecklistForm });

  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false);
  const [deletionLoadingElements, setDeletionLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false });
    setChecklistForm({ ...emptyChecklistForm });
  };

  const loadData = () => {
    setChecklistForm({ ...(checklist || emptyChecklistForm) });
  };

  const submit = async (checklistForm) => {
    checklistForm = { ...checklistForm };
    return checklist
      ? await axios.put(API_URL_VEHICLECHECKLIST + checklist.id, checklistForm)
      : await axios.post(API_URL_VEHICLECHECKLIST, checklistForm);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    if (!isValidForm(mandatoryFields, checklistForm)) {
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
        showMissingFields: true,
      });
      toast.error(<ErrorMessage message={"Bitte alle Felder ausfüllen!"} />);
      return;
    }

    try {
      await submit(checklistForm);
      if (resetParent) resetParent();
      if (onSuccess) onSuccess();
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error(
        'Error in "CarChecklistFormModal:onSubmit"',
        error,
        error.stack,
      );
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      setLoadingElements({
        ...loadingElements,
        submitError: true,
        inProgress: false,
        showMissingFields: true,
      });
    }
  };

  const onDelete = async (onSuccess) => {
    setDeletionLoadingElements({
      ...deletionLoadingElements,
      inProgress: true,
      submitError: false,
    });
    try {
      await axios.delete(API_URL_VEHICLECHECKLIST + checklist.id);
      if (resetParent) resetParent();
      if (onSuccess) onSuccess();
      setDeletionLoadingElements({
        ...deletionLoadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error(
        'Error in "CarChecklistFormModal:onDelete"',
        error,
        error.stack,
      );
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      setDeletionLoadingElements({
        ...deletionLoadingElements,
        submitError: true,
        inProgress: false,
      });
    }
  };

  const getFooter = (toggle) => {
    return (
      <>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          onCancel={toggle}
          onDelete={
            checklist
              ? () => {
                  setDeletionModalIsOpen(true);
                }
              : null
          }
        />
        <CustomModal
          size="small"
          title={"Checkliste wirklich löschen?"}
          isOpen={deletionModalIsOpen}
          setIsOpen={(isOpen) => {
            if (!isOpen) setDeletionModalIsOpen(false);
          }}
          getFooter={(toggleDelete) => getDeletionFooter(toggleDelete, toggle)}
          onClose={() => setDeletionModalIsOpen(false)}
        ></CustomModal>
      </>
    );
  };

  const getDeletionFooter = (toggle, toogleAll) => {
    return (
      <ConfirmationModalFooter
        submitError={deletionLoadingElements.submitError}
        inProgress={deletionLoadingElements.inProgress}
        onConfirm={() =>
          onDelete(() => {
            setDeletionModalIsOpen(false);
            setIsOpen(false);
          })
        }
        onCancel={() => setDeletionModalIsOpen(false)}
        btnLabel={"Löschen"}
      />
    );
  };

  const modalTitle = checklist ? checklist.name : "Neues Checkliste";

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      title={modalTitle}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <Form>
        <FormGroup>
          <Typography className="secondary-textcolor">Name:</Typography>
          <div style={{ display: "flex", gap: "0.5vw" }}>
            <Input
              id="input"
              placeholder="Title"
              type="text"
              width={"15px"}
              value={checklistForm.name}
              onChange={(e) =>
                setChecklistForm((x) => ({ ...x, name: e.target.value }))
              }
              style={{ boxShadow: "none" }}
            />
          </div>
        </FormGroup>
      </Form>
    </CustomModal>
  );
}

CarChecklistFormModal.propTypes = {
  checklist: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
