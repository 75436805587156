import React from "react";
import { PropTypes } from "prop-types";
import { Spinner, Fade } from "reactstrap";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { CustomButton } from "../../../elements/StyledElements";
import { isString } from "../../../elements/utils";
import { Typography } from "@mui/material";

export default function SaveModalFooter({
  inProgress,
  submitError,
  disabled = false,
  onSave,
  onComplete,
  onCancel,
  onDelete,
  completeBtnLabel,
  saveBtnLabel,
  cancelBtnLabel,
  delteBtnLabel,
  saveDisabled,
  completeDisabled,
  id,
}) {
  return (
    <span>
      <Stack
        direction="row"
        spacing={2}
        alignItems="stretch"
        style={{ height: "100%" }}
      >
        {inProgress ? <Spinner color="light" size="sm" /> : null}
        &nbsp;
        {submitError ? (
          <Fade in={!!submitError}>
            <Typography color={"red"}>
              {isString(submitError)
                ? submitError
                : "Anfrage konnte nicht an Server übermittelt werden!"}
            </Typography>
          </Fade>
        ) : null}
        {onSave ? (
          <CustomButton
            id={id}
            color={disabled || saveDisabled ? "#c4c4c4" : "#ffffff"}
            style={{ display: "flex", flexGrow: 1 }}
            onClick={onSave}
            disabled={inProgress || disabled || saveDisabled}
          >
            {saveBtnLabel || "Speichern"}
            {inProgress ? "..." : ""}
          </CustomButton>
        ) : null}
        {onComplete ? (
          <CustomButton
            color={disabled || completeDisabled ? "#c4c4c4" : "#ffffff"}
            onClick={onComplete}
            disabled={inProgress || disabled || completeDisabled}
          >
            {completeBtnLabel || "Abschließen"}
          </CustomButton>
        ) : null}
        {onCancel ? (
          <CustomButton
            color={"#ffffff"}
            onClick={onCancel}
            style={{ display: "flex", flexGrow: 1 }}
            disabled={inProgress}
          >
            {cancelBtnLabel || "Abbrechen"}
          </CustomButton>
        ) : null}
        {onDelete ? (
          <Button
            variant="outlined"
            color="error"
            onClick={onDelete}
            disabled={inProgress || disabled}
          >
            {delteBtnLabel || "Löschen"}
          </Button>
        ) : null}
      </Stack>
    </span>
  );
}

SaveModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  completeBtnLabel: PropTypes.string,
  saveBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  delteBtnLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
  completeDisabled: PropTypes.bool,
  id: PropTypes.string,
};
