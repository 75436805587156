import React, { Fragment } from "react";

import { PropTypes } from "prop-types";
import { isMobileOnly } from "react-device-detect";

import "./dashboard/dashboard.css";
import DashboardDetail from "./DashboardDetail";
import DashboardDesktopContainer from "./dashboard/DashboardDesktopContainer";

export default function Dashboard({ session }) {
  return (
    <Fragment>
      {isMobileOnly ? (
        <DashboardDetail session={session} />
      ) : (
        <DashboardDesktopContainer session={session} />
      )}
    </Fragment>
  );
}

Dashboard.propTypes = {
  session: PropTypes.object,
};
