import React from "react";
import { PropTypes } from "prop-types";
import { Form, Col, Container, Row, FormGroup, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Stack, Typography } from "@mui/material";

import {
  planningPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import UploadImages from "../../../shared/images/UploadImages";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  checkIfValueIsEmpty,
  defaultIfEmpty,
  round,
} from "../../../../elements/utils";
import RoofProjectRoofImageUpload from "./RoofProjectRoofImageUpload";
import axios from "axios";
import {
  API_URL_PLANNING,
  API_URL_ROOF_PROJECT_ROOFIMAGE,
  API_URL_ROOF_PROJECT_ROOFLINE,
  API_URL_ROOF_PROJECT_ROOFLINE_BULK,
} from "../../../../settings";
import { isMobileOnly } from "react-device-detect";
import Button from "@mui/material/Button";
import RoofProjectEditRoofImagePlanning from "./RoofProjectEditRoofImagePlanning";
import ImageStage from "../../../shared/images/ImageStage";

const ADDITIONAL_IMAGE_SET = "roofprojectplanningadditionalimage_set";

export default function RoofProjectPlanningForm({
  project,
  planning,
  setPlanning,
  showMissingFields,
  session,
}) {
  const resetRoofImage = async () => {
    const currPlanning = (
      await axios.get(API_URL_PLANNING + planning.id, {
        params: { nested: true },
      })
    ).data;

    const roofImageSet = currPlanning.roofprojectroofimage_set;

    setPlanning({ ...planning, roofprojectroofimage_set: roofImageSet });
  };
  const addRoofImage = async (
    roofImage,
    roofImageWidthPx,
    roofImageWidthM,
    angle,
    lines,
    flatRoof,
    blendedImage,
    legendImage,
  ) => {
    const planningId = planning.id;

    if (!planningId) return;

    const roofImageForm = new FormData();
    roofImageForm.append("image", roofImage);
    roofImageForm.append("blended_image", blendedImage);
    roofImageForm.append("legend_image", legendImage);
    roofImageForm.append("image_width_px", roofImageWidthPx);
    roofImageForm.append("image_width_m", roofImageWidthM);
    roofImageForm.append("angle", angle);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("planning", planningId);

    const roofImageRes = await axios.post(
      API_URL_ROOF_PROJECT_ROOFIMAGE,
      roofImageForm,
    );
    const roofImageId =
      roofImageRes && roofImageRes.data && roofImageRes.data.id;

    if (roofImageId && lines && lines.length > 0) {
      const lineBulkReq = lines.map((line) => {
        const newLine = {
          roof_image: roofImageId,
          line_type: line.lineType,
          name: line.name,
          length: line.length,
          area: line.area,
          points: line.points,
          hull_points: line.hullPoints,
          centroid: line.centroid,
        };
        delete newLine.id;
        return newLine;
      });
      await axios.post(API_URL_ROOF_PROJECT_ROOFLINE_BULK, lineBulkReq);
    }

    await resetRoofImage();
  };
  const updateRoofImage = async (
    roofImageId,
    angle,
    lines,
    flatRoof,
    blendedImage,
    legendImage,
  ) => {
    const planningId = planning.id;

    if (!planningId || !roofImageId) return;

    const roofImageForm = new FormData();
    roofImageForm.append("planning", planningId);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("angle", angle);
    roofImageForm.append("blended_image", blendedImage);
    roofImageForm.append("legend_image", legendImage);

    await axios.put(
      API_URL_ROOF_PROJECT_ROOFIMAGE + roofImageId.toString(),
      roofImageForm,
    );
    await axios.delete(API_URL_ROOF_PROJECT_ROOFLINE, {
      data: { roof_image: roofImageId, except_ids: [] },
    });

    const lineBulkReq = lines.map((line) => {
      const newLine = {
        roof_image: roofImageId,
        line_type: line.lineType,
        name: line.name,
        length: line.length,
        area: line.area,
        points: line.points,
        hull_points: line.hullPoints,
        centroid: line.centroid,
      };
      delete newLine.id;
      return newLine;
    });

    await axios.post(API_URL_ROOF_PROJECT_ROOFLINE_BULK, lineBulkReq);
    await resetRoofImage();
  };

  const addImage = (key, imageDict) => {
    setPlanning((p) => {
      const images = [...p[key]];
      images.push(imageDict);
      return { ...p, [key]: images };
    });
  };

  const deleteRoofPlanningImage = async (roofImageId) => {
    await axios.delete(API_URL_ROOF_PROJECT_ROOFIMAGE + roofImageId.toString());
    await resetRoofImage();
  };
  const deleteImage = (key, imageDict) => {
    setPlanning((p) => ({
      ...p,
      [key]: p[key].filter((i) => i.id !== imageDict.id),
    }));
  };

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    const update = { [key]: value };

    setPlanning((p) => ({ ...p, ...update }));
  };

  return (
    <Container style={{ marginTop: "10px" }}>
      <Row>
        <Col>
          <Form>
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Informationen zur Planung
            </Typography>

            <hr className="secondary-textcolor" />
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Dachfotos
            </Typography>
            <br />

            {/* ========================= Dachfotos ============================== */}
            {showMissingFields &&
            checkIfValueIsEmpty(planning.roofprojectroofimage_set) ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}

            <FormGroup width={"30px"}>
              <div>
                {planning.roofprojectroofimage_set.map((roofImage) => (
                  <div key={`roof_image_${roofImage.id}`}>
                    <Typography
                      id="roof-image-flat-roof-text"
                      className="secondary-textcolor"
                    >
                      Dachtyp:{" "}
                      <strong>
                        {roofImage.flat_roof ? "Flachdach" : "Satteldach"}{" "}
                      </strong>
                    </Typography>
                    <br />
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => deleteRoofPlanningImage(roofImage.id)}
                    >
                      Bild löschen
                    </Button>
                    <br />
                    <br />
                    <br />
                    <Stack
                      direction={isMobileOnly ? "column" : "row"}
                      spacing={2}
                    >
                      <ImageStage
                        image={roofImage.blended_image}
                        width={round(
                          (isMobileOnly ? 0.9 : 0.43) * window.innerWidth,
                        )}
                      />
                      <ImageStage
                        image={roofImage.legend_image}
                        width={round(
                          (isMobileOnly ? 0.9 : 0.43) * window.innerWidth,
                        )}
                      />
                    </Stack>
                    <br />
                    <RoofProjectEditRoofImagePlanning
                      roofImage={roofImage}
                      setAll={(
                        lines,
                        angle,
                        flatRoof,
                        blendedImage,
                        legendImage,
                      ) =>
                        updateRoofImage(
                          roofImage.id,
                          angle,
                          lines,
                          flatRoof,
                          blendedImage,
                          legendImage,
                        )
                      }
                    />
                    <hr className="secondary-textcolor" />
                  </div>
                ))}
              </div>
              <RoofProjectRoofImageUpload
                project={project}
                addRoofImage={addRoofImage}
                winWidth={window.innerWidth}
                winHeight={window.innerHeight}
                session={session}
              />
            </FormGroup>

            {/* ========================= Zusätzliche Bilder  ============================== */}

            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Zusätzliche Bilder
            </Typography>
            {showMissingFields &&
            checkIfValueIsEmpty(
              planning.roofprojectplanningadditionalimage_set,
            ) ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <UploadImages
              images={planning.roofprojectplanningadditionalimage_set}
              addImage={(img) => addImage(ADDITIONAL_IMAGE_SET, img)}
              deleteImage={(img) => deleteImage(ADDITIONAL_IMAGE_SET, img)}
            />
            <br />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notizen (Dach):
              </Typography>
              {showMissingFields && checkIfValueIsEmpty(planning.notes_dach) ? (
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              ) : null}
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="textarea"
                name="notes_dach"
                onChange={onChange}
                value={defaultIfEmpty(planning.notes_dach)}
                autoComplete="off"
                maxLength={150}
              />
            </FormGroup>
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notizen (Fester):
              </Typography>
              {showMissingFields &&
              checkIfValueIsEmpty(planning.notes_fester) ? (
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              ) : null}
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="textarea"
                name="notes_fester"
                onChange={onChange}
                value={defaultIfEmpty(planning.notes_fester)}
                autoComplete="off"
                maxLength={150}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

RoofProjectPlanningForm.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  showMissingFields: PropTypes.bool,
  session: PropTypes.object,
};
