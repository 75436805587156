import { Col, Container, FormGroup, Row, Form } from "reactstrap";
import { Stack, Typography } from "@mui/material";
import {
  checkAccessRights,
  date2String,
  getCustomerName,
  getProjectOverviewTable,
  hasPermission,
  round,
  sortByOrder,
  string2Date,
  gregorianDe,
  getFirstDate,
} from "../../../../elements/utils";
import { Link } from "react-router-dom";
import { CustomButton } from "../../../../elements/StyledElements";
import React, { useEffect, useRef, useState } from "react";
import DropDown from "../../../../elements/DropDown";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EmailModal from "../../../shared/modal_utils/EmailModal";
import axios from "axios";
import {
  API_URL_CONSTRUCTION,
  API_URL_CREATECONSTRUCTIONCOSTDIFFERENCE,
  API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE,
  API_URL_SENDCONSTRUCTIONDATE,
  API_URL_USER,
  API_URL_WAREHOUSEDELIVERYSUPPLIER,
  API_URL_WAREHOUSESUPPLIER,
  API_URL_WAREHOUSEPRODUCT,
} from "../../../../settings";
import moment from "moment/moment";
import ImageStage from "../../../shared/images/ImageStage";
import {
  batteryPropType,
  circuitDiagramPropType,
  constructionPropType,
  customProductPropType,
  eMeterPropType,
  inverterPropType,
  modulePropType,
  productPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import { PropTypes } from "prop-types";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Icon from "react-multi-date-picker/components/icon";
import Button from "@mui/material/Button";
import { isMobileOnly } from "react-device-detect";
import RoofProjectEditRoofImagePlanning from "../plannings/RoofProjectEditRoofImagePlanning";
import RoofProjectRoofImageUpload from "../plannings/RoofProjectRoofImageUpload";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import CustomProductItems from "../../../products/CustomProductItems";
import { Table } from "react-bootstrap";
import WarehouseDeliveryAmountModal from "../../../warehouse/WarehouseDeliveryAmountModal";

export default function RoofConstructionForm({
  project,
  construction,
  setConstruction,
  products,
  productGroups,
  resetProducts,
  customProducts,
  setCustomProducts,
  currCustomProducts,
  showMissingFields,
  amountCols,
  fixedProductKeys,
  session,
}) {
  const [constructionManagers, setConstructionManagers] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouseProducts, setWarehouseProducts] = useState([]);
  const [warehouseDeliverySuppliers, setWarehouseDeliverySuppliers] = useState(
    [],
  );
  const [costDifferenceModal, setCostDifferenceModal] = useState({
    type: "email",
    isOpen: false,
    attachments: [],
  });

  const pickerRef1 = useRef(null);
  const pickerRef2 = useRef(null);

  const addRoofImage = async (
    roofImage,
    roofImageWidthPx,
    roofImageWidthM,
    angle,
    lines,
    flatRoof,
    blendedImage,
    legendImage,
  ) => {
    const newRoofImages = construction.roofprojectroofimage_set.slice();

    const tmpRoofImage = {
      id: newRoofImages.length,
      image: roofImage,
      blended_image: blendedImage,
      legend_image: legendImage,
      image_width_px: roofImageWidthPx,
      image_width_m: roofImageWidthM,
      angle,
      flat_roof: flatRoof,
      roofprojectroofline_set: lines.map((line) => ({
        roof_image: null,
        line_type: line.lineType,
        name: line.name,
        length: line.length,
        area: line.area,
        points: line.points,
        hull_points: line.hullPoints,
        centroid: line.centroid,
      })),
      op: "create",
    };
    newRoofImages.push(tmpRoofImage);
    setConstruction((prevConstruction) => ({
      ...prevConstruction,
      roofprojectroofimage_set: newRoofImages,
    }));
  };
  const updateRoofImage = async (
    roofImageId,
    angle,
    lines,
    flatRoof,
    blendedImage,
    legendImage,
  ) => {
    const newRoofImages = construction.roofprojectroofimage_set.slice();
    const targetRoofImage = newRoofImages.find((r) => r.id === roofImageId);
    targetRoofImage.flat_roof = flatRoof;
    targetRoofImage.angle = angle;
    targetRoofImage.blended_image = blendedImage;
    targetRoofImage.legend_image = legendImage;
    targetRoofImage.roofprojectroofline_set = lines.map((line) => ({
      roof_image: null,
      line_type: line.lineType,
      name: line.name,
      length: line.length,
      area: line.area,
      points: line.points,
      hull_points: line.hullPoints,
      centroid: line.centroid,
    }));
    targetRoofImage.op = "update";
    setConstruction((prevConstruction) => ({
      ...prevConstruction,
      roofprojectroofimage_set: newRoofImages,
    }));
  };

  const deleteRoofImage = async (roofImageId) => {
    const newRoofImages = construction.roofprojectroofimage_set.slice();
    const targetRoofImage = newRoofImages.find((r) => r.id === roofImageId);
    targetRoofImage.op = "delete";
    setConstruction((prevConstruction) => ({
      ...prevConstruction,
      roofprojectroofimage_set: newRoofImages,
    }));
  };

  const getWarehouseProducts = () => {
    axios.get(API_URL_WAREHOUSEPRODUCT).then((res) => {
      setWarehouseProducts(res.data);
    });
  };

  const getDefaultSupplierInfo = (warehouseProduct) => {
    const defaultSupplierId = warehouseProduct.amounts.find(
      (amount) => amount.location_id === 1,
    );
    const defaultSupplier = warehouseProduct.suppliers.find(
      (supplier) => supplier.id === defaultSupplierId?.active_supplier_id,
    );
    warehouseProduct.supplier_id = defaultSupplier?.supplier_id;
    warehouseProduct.supplier_name = suppliers.find(
      (supplier) => supplier.id === defaultSupplier?.supplier_id,
    )?.name;
    warehouseProduct.supplier_product_name = defaultSupplier?.product_name;
    warehouseProduct.supplier_product_id = defaultSupplier?.product_id;
    warehouseProduct.supplier_price = defaultSupplier?.price;
    return warehouseProduct;
  };

  const getWarehouseDeliverySuppliers = () => {
    if (!project.construction) return;
    axios
      .get(API_URL_WAREHOUSEDELIVERYSUPPLIER, {
        params: { project: project.id },
      })
      .then((res) => {
        setWarehouseDeliverySuppliers(res.data);
      });
  };

  const onCostDifferenceBtnClicked = (e) => {
    axios
      .post(API_URL_CREATECONSTRUCTIONCOSTDIFFERENCE, {
        project: project.id,
        customProducts,
      })
      .then(() => {
        const constructionId = project.construction;
        const offerId = project.accepted_offer;

        if (constructionId) {
          axios.get(API_URL_CONSTRUCTION + constructionId).then((res) => {
            const pdfPath = res.data.cost_difference_pdf;
            if (pdfPath) {
              setCostDifferenceModal({
                ...costDifferenceModal,
                type: "email",
                isOpen: true,
                attachments: [
                  {
                    type: "pdf",
                    name: `Kostendifferenz_Angebot_${offerId}_Bauplanung_${constructionId}`,
                    data: res.data.cost_difference_pdf,
                  },
                ],
              });
            } else {
              setCostDifferenceModal({
                ...costDifferenceModal,
                type: "info",
                isOpen: true,
              });
            }
          });
        }
      });
  };

  useEffect(() => {
    const fetchInitData = async () => {
      const resUsers = await axios.get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true },
      });

      setConstructionManagers(
        resUsers.data.filter(
          (user) => user.group_key === "construction_managers",
        ),
      );
    };
    axios.get(API_URL_WAREHOUSESUPPLIER).then((res) => {
      setSuppliers(res.data);
    });
    fetchInitData();
    getWarehouseProducts();
    getWarehouseDeliverySuppliers();
  }, []);

  const handleOpenDatePicker = () => {
    if (pickerRef1.current) {
      pickerRef1.current.openCalendar();
    }
  };

  return (
    <>
      <Container style={{ marginTop: "10px" }}>
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Kundeninformationen
        </Typography>
        {getProjectOverviewTable(project)}
        {checkAccessRights(session.user, "projekt/:id") ? (
          <Row>
            <Link
              to={"/projekt/" + project.id}
              style={{ textDecoration: "none" }}
            >
              <CustomButton icon="clipboard" style={{ width: "200px" }}>
                zur Projektseite
              </CustomButton>
            </Link>
          </Row>
        ) : null}
        <hr className="secondary-textcolor" />
      </Container>
      <Container style={{ marginTop: "10px" }}>
        <Row>
          <Col>
            <Form>
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Informationen zur Baustelle
              </Typography>
              {constructionManagers !== null ? (
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Bauleiter:
                  </Typography>
                  <DropDown
                    onChange={(x) => {
                      const newEmployees = construction.employees.filter(
                        (employeeId) =>
                          employeeId !== construction.construction_manager &&
                          employeeId !== x,
                      );
                      if (x) newEmployees.push(x);

                      setConstruction((c) => ({
                        ...c,
                        construction_manager: x,
                        employees: newEmployees,
                      }));
                    }}
                    options={constructionManagers.map((user) => ({
                      label: user.first_name + " " + user.last_name,
                      value: user.id,
                    }))}
                    value={construction.construction_manager}
                    text="Bauleiter wählen"
                    sort={true}
                    search={true}
                  />
                  {showMissingFields && !construction.construction_manager ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </FormGroup>
              ) : null}
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Datum der Montage
                </Typography>
                <Row>
                  <Col md="auto">
                    <div
                      className="custom-date-picker"
                      style={{ maxWidth: 300, width: "auto", minWidth: 150 }}
                      onClick={handleOpenDatePicker}
                    >
                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: "5px",
                          minHeight: "40px",
                          overflowY: "auto",
                        }}
                      >
                        {construction.constructiondates_set &&
                          construction.constructiondates_set.map(
                            (dateString, index) => {
                              const date = dateString
                                ? string2Date(dateString.date)
                                : null;
                              const formattedDate = date
                                ? moment(date).format("DD/MM/YYYY")
                                : "";
                              return (
                                <span key={index}>
                                  {formattedDate}{" "}
                                  {index !==
                                    construction.constructiondates_set.length -
                                      1 && ","}{" "}
                                  &nbsp;
                                </span>
                              );
                            },
                          )}
                      </div>
                    </div>
                  </Col>
                  <Col md="auto">
                    <DatePicker
                      ref={pickerRef1}
                      locale={gregorianDe}
                      weekStartDayIndex={1}
                      render={<Icon />}
                      format="DD/MM/YYYY"
                      multiple
                      showOtherDays
                      value={
                        construction.constructiondates_set !== null
                          ? construction.constructiondates_set.map((dateObj) =>
                              dateObj ? string2Date(dateObj.date) : null,
                            )
                          : null
                      }
                      onChange={(dates) => {
                        const dateObjects = dates.map((date) => ({
                          date: date2String(date.toDate()),
                          construction: construction.id,
                        }));
                        const maxDate = dateObjects.length
                          ? new Date(
                              Math.max(
                                ...dateObjects.map(
                                  (dateObj) => new Date(dateObj.date),
                                ),
                              ),
                            )
                          : null;
                        setConstruction((c) => ({
                          ...c,
                          constructiondates_set: dateObjects,
                          completion_date: maxDate
                            ? date2String(maxDate)
                            : null,
                          ...(c.e_meter
                            ? {}
                            : {
                                commissioning_date: dateObjects.length
                                  ? dateObjects[0].date
                                  : null,
                              }),
                        }));
                      }}
                      plugins={[<DatePanel key="date-panel" header="Daten" />]}
                      style={{ boxShadow: "none" }}
                      sort={true}
                    />
                  </Col>
                  <Col>
                    {hasPermission(session.user, "customer_handling") &&
                    project.construction !== null ? (
                      <EmailModal
                        customer={project.customer_obj}
                        title={
                          project
                            ? "Email zum Bautermin von " +
                              getCustomerName(project.customer_obj)
                            : ""
                        }
                        subject={
                          project
                            ? "Bautermin für Ihr Dach " +
                              project.street_and_number_project
                            : ""
                        }
                        getEmailMessage={() =>
                          axios
                            .get(API_URL_SENDCONSTRUCTIONDATE, {
                              params: { project: project.id },
                            })
                            .then((res) => {
                              let msg = res.data;
                              if (
                                construction.constructiondates_set.length > 0
                              ) {
                                const formattedDates = getFirstDate(
                                  construction.constructiondates_set,
                                  "DD.MM.YYYY",
                                );
                                msg = msg.replace("__DATE__", formattedDates);
                              } else {
                                msg = msg.replace("__DATE__", "kein Datum");
                              }
                              return { data: msg };
                            })
                        }
                        submit={(subject, message) =>
                          axios.post(API_URL_SENDCONSTRUCTIONDATE, {
                            project: project.id,
                            mail: { subject, message },
                            date: getFirstDate(
                              construction.constructiondates_set,
                              "YYYY-MM-DD",
                            ),
                          })
                        }
                        getModalOpenButton={(toggle) => (
                          <CustomButton onClick={toggle} icon="mail">
                            Setztermin erneut senden
                          </CustomButton>
                        )}
                        session={session}
                      />
                    ) : null}
                  </Col>
                </Row>
              </FormGroup>

              {/* ############################################################## */}

              <FormGroup>
                <Typography className="secondary-textcolor">
                  Datum der Fertigstellung
                </Typography>
                <DatePicker
                  ref={pickerRef2}
                  dateFormat="dd/MM/yyyy"
                  locale={gregorianDe}
                  value={
                    construction.completion_date
                      ? string2Date(construction.completion_date)
                      : null
                  }
                  onChange={(date) =>
                    setConstruction((c) => ({
                      ...c,
                      completion_date: date ? date2String(date) : null,
                      ...(c.e_meter
                        ? {}
                        : {
                            commissioning_date: date ? date2String(date) : null,
                          }),
                    }))
                  }
                />
              </FormGroup>

              {/* ========================= Dachfotos ============================== */}

              <hr className="secondary-textcolor" />
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Dachfotos
              </Typography>
              <br />

              {construction.roofprojectroofimage_set && (
                <FormGroup width={"30px"}>
                  <div>
                    {construction.roofprojectroofimage_set.map(
                      (roofImage) =>
                        roofImage.op !== "delete" && (
                          <div key={`roof_image_${roofImage.id}`}>
                            <Typography
                              id="roof-image-flat-roof-text"
                              className="secondary-textcolor"
                            >
                              Dachtyp:{" "}
                              <strong>
                                {roofImage.flat_roof
                                  ? "Flachdach"
                                  : "Satteldach"}{" "}
                              </strong>
                            </Typography>
                            <br />
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => deleteRoofImage(roofImage.id)}
                            >
                              Bild löschen
                            </Button>
                            <br />
                            <br />
                            <br />
                            <Stack
                              direction={isMobileOnly ? "column" : "row"}
                              spacing={2}
                            >
                              <ImageStage
                                image={roofImage.blended_image}
                                width={round(
                                  (isMobileOnly ? 0.9 : 0.43) *
                                    window.innerWidth,
                                )}
                              />
                              <ImageStage
                                image={roofImage.legend_image}
                                width={round(
                                  (isMobileOnly ? 0.9 : 0.43) *
                                    window.innerWidth,
                                )}
                              />
                            </Stack>
                            <br />
                            <RoofProjectEditRoofImagePlanning
                              roofImage={roofImage}
                              setAll={(
                                lines,
                                angle,
                                flatRoof,
                                blendedImage,
                                legendImage,
                              ) =>
                                updateRoofImage(
                                  roofImage.id,
                                  angle,
                                  lines,
                                  flatRoof,
                                  blendedImage,
                                  legendImage,
                                )
                              }
                            />
                            <hr className="secondary-textcolor" />
                          </div>
                        ),
                    )}
                  </div>
                  <RoofProjectRoofImageUpload
                    project={project}
                    addRoofImage={addRoofImage}
                    winWidth={window.innerWidth}
                    winHeight={window.innerHeight}
                    session={session}
                  />
                </FormGroup>
              )}
              {/* ############################################################## */}
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Komission
              </Typography>
              <Table>
                <thead>
                  <tr>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Zulieferer
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Besteller
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Lieferdatum
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Status
                      </Typography>
                    </th>
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        Aktion
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {warehouseDeliverySuppliers.map((row) => (
                    <tr key={row.id}>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {suppliers.find((e) => e.id === row.supplier)?.name}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.employee_name}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.delivery_date}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography className="secondary-textcolor">
                          {row.status}
                        </Typography>
                      </td>
                      <td className="align-middle">
                        <Typography
                          className="secondary-textcolor"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <WarehouseDeliveryAmountModal
                            resetParent={getWarehouseProducts}
                            resetState={getWarehouseDeliverySuppliers}
                            isInfo={true}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts.map(
                              getDefaultSupplierInfo,
                            )}
                            suppliers={suppliers}
                            session={session}
                          />
                          <WarehouseDeliveryAmountModal
                            resetParent={getWarehouseProducts}
                            resetState={getWarehouseDeliverySuppliers}
                            isInfo={false}
                            warehouseDeliverySupplier={row}
                            warehouseProducts={warehouseProducts.map(
                              getDefaultSupplierInfo,
                            )}
                            session={session}
                          />
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* ############################################################## */}
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Produkte
              </Typography>
              {hasPermission(session.user, "customer_handling") &&
              !!project.construction ? (
                <>
                  <CustomButton
                    onClick={onCostDifferenceBtnClicked}
                    icon="mail"
                  >
                    Kostendifferenz
                  </CustomButton>

                  {costDifferenceModal.type === "email" ? (
                    <>
                      <EmailModal
                        customer={project.customer_obj}
                        title={
                          project
                            ? "Email zum Kostendifferenz von " +
                              getCustomerName(project.customer_obj)
                            : ""
                        }
                        subject={
                          project
                            ? "Kostendifferenz für Ihre Dachprojekt " +
                              project.street_and_number_project
                            : ""
                        }
                        getEmailMessage={() =>
                          axios
                            .get(API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE, {
                              params: { project: project.id },
                            })
                            .then((res) => {
                              const msg = res.data;
                              return { data: msg };
                            })
                        }
                        submit={(subject, message) =>
                          axios.post(API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE, {
                            project: project.id,
                            mail: { subject, message },
                          })
                        }
                        getModalOpenButton={() => null}
                        isOpen={costDifferenceModal.isOpen}
                        setIsOpen={(open) =>
                          setCostDifferenceModal({
                            ...costDifferenceModal,
                            isOpen: open,
                          })
                        }
                        attachments={costDifferenceModal.attachments}
                        session={session}
                      />
                      <br />
                    </>
                  ) : (
                    <CustomModal
                      size="md"
                      isOpen={costDifferenceModal.isOpen}
                      setIsOpen={(open) =>
                        setCostDifferenceModal({
                          ...costDifferenceModal,
                          isOpen: open,
                        })
                      }
                      title="Kostendifferenz"
                    >
                      <Typography fontSize="h6.fontSize">
                        Derzeit gibt es keinen Kostenunterschied
                      </Typography>
                    </CustomModal>
                  )}
                </>
              ) : (
                <></>
              )}

              <CustomProductItems
                showDescriptions={false}
                showPrices={false}
                construction={construction}
                customProducts={sortByOrder(customProducts)}
                setCustomProducts={setCustomProducts}
                session={session}
                customer={project.customer_obj}
                products={products}
                productGroups={productGroups}
                resetProducts={resetProducts}
                amountCols={amountCols}
                fixedProductKeys={fixedProductKeys}
                stage={"roof_construction"}
              />

              {/* ############################################################## */}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

RoofConstructionForm.propTypes = {
  project: projectPropType,
  construction: constructionPropType,
  setConstruction: PropTypes.func,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.arrayOf(productPropType),
  resetProducts: PropTypes.func,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  currCustomProducts: PropTypes.arrayOf(customProductPropType),
  showMissingFields: PropTypes.bool,
  modules: PropTypes.arrayOf(modulePropType),
  inverters: PropTypes.arrayOf(inverterPropType),
  batteries: PropTypes.arrayOf(batteryPropType),
  circuitDiagrams: PropTypes.arrayOf(circuitDiagramPropType),
  amountCols: PropTypes.object,
  session: PropTypes.object,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  emeters: PropTypes.arrayOf(eMeterPropType),
  setEmeters: PropTypes.func,
};
