import React, { useState } from "react";
import { Container } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { PropTypes } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Typography } from "@mui/material";

import TimeManagementModal from "./TimeManagementModal";
import CustomModal from "../shared/modal_utils/CustomModal";
import {
  // round,
  // arrSum,
  // THIS_YEAR,
  // MONTHS,
  // getHolidayEvents,
  germanCalendarMsgs,
  hasPermission,
} from "../../elements/utils";
import { timeManagementEventPropType } from "../../elements/PropTypes";

moment.locale("de-DE");
const localizer = momentLocalizer(moment);

const CustomToolbarCalendar = (toolbar) => {
  const label = () => {
    const date = moment(toolbar.date);
    return (
      <Typography variant="subtitle1">{date.format("dddd MMM DD")}</Typography>
    );
  };

  return <div>{label()}</div>;
};
export default function DayOverviewModal({
  allEvents,
  isOpen,
  setIsOpen,
  title,
  getFooter,
  date,
  events,
  session,
  employee,
  loadData,
}) {
  const [chosenEvent, setChosenEvent] = useState(null);
  const [chosenDate, setChosenDate] = useState(false);

  const handleSelect = (slot) => {
    setChosenDate(slot.start);
  };

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      getFooter={getFooter}
    >
      <Container>
        <Calendar
          className="secondary-textcolor"
          views={["day"]}
          selectable
          localizer={localizer}
          defaultDate={date}
          defaultView="day"
          events={events}
          style={{ height: isMobileOnly ? "50vh" : "70vh" }}
          onSelectEvent={(e) => {
            if (e.event.event_type !== 0) {
              const enddate = new Date(
                `${e.event.end_date} ${e.event.end_time}`,
              );
              if (enddate.getTime() < new Date().getTime()) {
                if (
                  hasPermission(
                    session.user,
                    "timemanagement_change_previous_vacation",
                  )
                )
                  setChosenEvent(e.event);
              } else setChosenEvent(e.event);
            } else setChosenEvent(e.event);
          }}
          onSelectSlot={handleSelect}
          longPressThreshold={isMobileOnly ? 30 : 50}
          step={60}
          timeslots={1}
          eventPropGetter={(event) => {
            const backgroundColor = event.backgroundColor;
            return { style: { backgroundColor } };
          }}
          messages={germanCalendarMsgs}
          components={{
            toolbar: CustomToolbarCalendar,
          }}
        />
        {chosenDate ? (
          <TimeManagementModal
            events={allEvents}
            event={null}
            chosenDate={chosenDate}
            session={session}
            isOpen={!!chosenDate}
            setIsOpen={(isOpen) => {
              if (!isOpen) setChosenDate(null);
            }}
            resetParent={loadData}
            employee={employee}
          />
        ) : null}
        {chosenEvent && chosenEvent.user ? (
          <TimeManagementModal
            events={allEvents}
            timeManagementEvent={chosenEvent}
            chosenDate={new Date(chosenEvent.start_date)}
            session={session}
            isOpen={!!chosenEvent && !!chosenEvent.user}
            setIsOpen={(isOpen) => {
              if (!isOpen) setChosenEvent(null);
            }}
            resetParent={loadData}
            employee={employee}
          />
        ) : null}
      </Container>
    </CustomModal>
  );
}

DayOverviewModal.propTypes = {
  allEvents: PropTypes.arrayOf(timeManagementEventPropType),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  getFooter: PropTypes.func,
  date: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
};
