import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import axios from "axios";

import { Typography } from "@mui/material";

import { API_URL_TASKSETTINGS, API_URL_USER } from "../../settings";
import DropDown from "../../elements/DropDown";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { taskSettingsPropType } from "../../elements/PropTypes";
import "./styles.css";
import { defaultIfEmpty } from "../../elements/utils";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { toast } from "react-toastify";
import ErrorMessage from "../../elements/ErrorMessage";

const emptyTaskSettingsForm = {
  name: "",
  default_coordinator: null,
  coordinators: [],
  reminder_time: 45,
  warning_time: 15,
  deadline_reminder_time: 5,
  color: "#346ec9",
};

export default function TaskSettingsFormModal({
  taskSettings,
  isOpen,
  setIsOpen,
  getOpenButton,
  resetParent,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [employees, setEmployees] = useState([]);
  const [taskSettingsForm, setTaskSettingsForm] = useState({
    ...emptyTaskSettingsForm,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false });
    setTaskSettingsForm({ ...emptyTaskSettingsForm });
    setEmployees([]);
  };

  const loadData = () => {
    setTaskSettingsForm({ ...(taskSettings || emptyTaskSettingsForm) });
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true },
      })
      .then((res) => {
        setEmployees(res.data);
      });
  };

  const submit = async (taskSettingsForm) => {
    taskSettingsForm = { ...taskSettingsForm };
    return axios.put(API_URL_TASKSETTINGS, taskSettingsForm);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(taskSettingsForm)
      .then((res) => {
        if (resetParent) resetParent(res.data);
        if (onSuccess) onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "TaskSettings:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
          showMissingFields: true,
        });
        if (error.response.data.coordinators) {
          toast.error(
            <ErrorMessage message={"Bitte alle Felder ausfüllen!"} />,
          );
        }
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onCancel={toggle}
      />
    );
  };

  const modalTitle = "Einstellungen";

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      title={modalTitle}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <Form>
        <FormGroup>
          <Typography className="secondary-textcolor">
            Termin-Erinnerung (Minuten vor dem Termin):
          </Typography>
          <Input
            id="input"
            style={{ width: "300px" }}
            type="number"
            name="reminder"
            value={defaultIfEmpty(taskSettingsForm.reminder_time)}
            autoComplete="off"
            onChange={(e) =>
              setTaskSettingsForm((t) => ({
                ...t,
                reminder_time: e.target.valueAsNumber,
              }))
            }
            min={0}
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
        </FormGroup>
        <FormGroup>
          <Typography className="secondary-textcolor">
            Termin-Warnung an Koordinator (Minuten vor dem Termin):
          </Typography>
          <Input
            id="input"
            style={{ width: "300px" }}
            type="number"
            name="warning"
            value={defaultIfEmpty(taskSettingsForm.warning_time)}
            autoComplete="off"
            onChange={(e) =>
              setTaskSettingsForm((t) => ({
                ...t,
                warning_time: e.target.valueAsNumber,
              }))
            }
            min={0}
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
        </FormGroup>
        <FormGroup>
          <Typography className="secondary-textcolor">
            Frist-Erinnerung (Tage vor der Frist):
          </Typography>
          <Input
            id="input"
            style={{ width: "300px" }}
            type="number"
            name="deadline_reminder"
            value={defaultIfEmpty(taskSettingsForm.deadline_reminder_time)}
            autoComplete="off"
            onChange={(e) =>
              setTaskSettingsForm((t) => ({
                ...t,
                deadline_reminder_time: e.target.valueAsNumber,
              }))
            }
            min={0}
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
        </FormGroup>
        <FormGroup>
          <Typography className="secondary-textcolor">
            Default-Koordinator:
          </Typography>
          <div style={{ display: "flex", gap: "0.5vw" }}>
            <DropDown
              text={"Default-Koordinator"}
              onChange={(value) =>
                setTaskSettingsForm((x) => {
                  let _coordinators = x.coordinators.filter(
                    (e) => e !== x.default_coordinator,
                  );
                  if (!_coordinators.includes(value))
                    _coordinators = [value, ..._coordinators];
                  return {
                    ...x,
                    default_coordinator: value,
                    coordinators: _coordinators,
                  };
                })
              }
              options={employees.map((e) => ({
                label: `${e.first_name} ${e.last_name}`,
                value: e.id,
                hidden: !e.is_active,
              }))}
              value={taskSettingsForm.default_coordinator}
              sort={true}
              search={true}
              noOptionsText={"Keine Mitarbeiter"}
              // onInputChange={(input, reason) => { if (reason === "input") setProjectSearchInput(input) }}
            />
            {loadingElements.showMissingFields ? (
              <div>
                &nbsp;&nbsp;
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              </div>
            ) : null}
          </div>
        </FormGroup>
        {/* <FormGroup>
          <Typography className='secondary-textcolor'>Koordinatoren:</Typography>
          <MultiSelect
            text={'Koordinatoren'}
            sort={true}
            options={employees.map(e => ({ label: `${e.first_name} ${e.last_name}`, value: e.id, hidden: !e.is_active, disabled: e.id === taskSettingsForm.default_coordinator }))}
            values={taskSettingsForm.coordinators}
            onChange={chosen => setTaskSettingsForm(b => ({ ...b, coordinators: chosen }))}
            disabled={true}
          />
        </FormGroup> */}
      </Form>
    </CustomModal>
  );
}

TaskSettingsFormModal.propTypes = {
  taskSettings: taskSettingsPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
