import React from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import { Typography } from "@mui/material";
import { defaultIfEmpty } from "../../elements/utils";
export default function AddNewItemForm({
  session,
  newItemForm,
  setNewItemForm,
  showMissingFields,
}) {
  const onChange = (e) => {
    setNewItemForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  return (
    <Form>
      <FormGroup>
        <Typography>Name:</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(newItemForm.name)}
        />
      </FormGroup>
    </Form>
  );
}

AddNewItemForm.propTypes = {
  session: PropTypes.object,
  newItemForm: PropTypes.object,
  setNewItemForm: PropTypes.func,
  showMissingFields: PropTypes.bool,
};
