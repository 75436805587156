import React, { useState, useRef, useEffect } from "react";

import { PropTypes } from "prop-types";
import { FormGroup, Row, Col } from "reactstrap";

import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { HashLink as Link } from "react-router-hash-link";

import {
  CustomButton,
  CustomToggle,
} from "../../../../elements/StyledElements";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import UploadPdf from "../../../../elements/UploadPdf";
import SignatureCanvasTool from "../../../../elements/SignatureCanvasTool";
import { projectPropType } from "../../../../elements/PropTypes";
import { isBlankCanvas } from "../../../../elements/utils";

export default function PlantAddMandateSignature({
  project,
  setProject,
  session,
}) {
  const [digitallySign, setDigitallySign] = useState(true);
  const [acceptMandate, setAcceptMandate] = useState(false);
  const [_acceptMandateDoc, _setAcceptMandateDoc] = useState(null);
  const [_acceptMandateSignature, _setAcceptMandateSignature] = useState(null);

  const sigCanvas = useRef();

  const getFooter = (toggle) => (
    <SaveModalFooter
      saveBtnLabel={
        project.accept_mandate_document || project.accept_mandate_signature
          ? "Ändern"
          : "Hinzufügen"
      }
      disable={
        (digitallySign && _acceptMandateSignature) ||
        (!digitallySign && !_acceptMandateDoc)
      }
      onSave={() => {
        setProject((p) => ({
          ...p,
          accept_mandate_signature: digitallySign
            ? _acceptMandateSignature
            : null,
          accept_mandate_document: digitallySign ? null : _acceptMandateDoc,
        }));
        toggle();
      }}
      onCancel={toggle}
    />
  );

  const getOpenButton = (toggle) => (
    <CustomButton onClick={toggle}>
      {project.accept_mandate_document || project.accept_mandate_signature
        ? "Ändern"
        : "Hinzufügen"}
    </CustomButton>
  );

  const onSignatureEnd = () => {
    if (!sigCanvas.current || sigCanvas.current.isEmpty()) {
      _setAcceptMandateSignature(null);
      return;
    }

    const canvas = sigCanvas.current.getTrimmedCanvas();
    if (isBlankCanvas(canvas)) {
      _setAcceptMandateSignature(null);
      return;
    }

    fetch(canvas.toDataURL("image/png"))
      .then((r) => r.arrayBuffer())
      .then((buf) =>
        _setAcceptMandateSignature(
          new File([buf], "signature.png", { type: "image/png" }),
        ),
      );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    sigCanvas.current = null;
    setAcceptMandate(false);
    setDigitallySign(true);
    _setAcceptMandateDoc(null);
    _setAcceptMandateSignature(null);
  };

  useEffect(() => {
    if (digitallySign) _setAcceptMandateDoc(null);
    else _setAcceptMandateSignature(null);
  }, [digitallySign]);

  return (
    <CustomModal
      title="Vollmacht"
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      onToggle={onToggle}
      size="lg"
    >
      <Typography className="secondary-textcolor">
        Wir brauchen Ihre Vollmacht, um Informationen bei Ihrem Netzbetreiber zu
        melden. Folgend können sie digital unterschreiben oder eine
        unterschriebene Vollmacht hochladen.
      </Typography>
      <br />
      <FormGroup>
        <FormControlLabel
          checked={acceptMandate}
          className="secondary-textcolor"
          onChange={(e) => {
            setAcceptMandate(e.target.checked);
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label={`Ich bevollmächtige hiermit die ${session.companyConfig.company} zur Anmeldung der PV-Anlage beim Netzbetreiber.`}
        />
      </FormGroup>
      <br />
      {acceptMandate ? (
        <>
          <Typography className="secondary-textcolor">
            Sie können auswählen, ob sie digital unterschreiben oder die
            Vollmacht selbst ausfüllen und unterschrieben hochladen möchten.
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={digitallySign}
            exclusive
            onChange={(e, value) => {
              if (value !== null) setDigitallySign(value);
            }}
          >
            <CustomToggle value={true}>Digital unterschreiben</CustomToggle>
            <CustomToggle value={false}>Dokument hochladen</CustomToggle>
          </ToggleButtonGroup>
          <br />
          {digitallySign ? (
            <>
              <Row>
                <Col>
                  <br />
                  <Typography className="secondary-textcolor">
                    Bitte im roten Feld unterschreiben:
                  </Typography>
                  <Typography className="secondary-textcolor">
                    Kundenunterschrift:
                  </Typography>
                  <div style={{ border: "2px solid red" }}>
                    <SignatureCanvasTool
                      sigCanvas={sigCanvas}
                      onEnd={onSignatureEnd}
                    />
                  </div>
                </Col>
                {/* <Col></Col> */}
              </Row>{" "}
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <br />
                  <Typography className="secondary-textcolor">
                    Bitte laden Sie die Vollmacht herrunter, füllen diese aus
                    und laden die unterschriebene Vollmacht eingescannt wieder
                    hoch.
                  </Typography>
                  <Link
                    to="/D4-Vollmacht-Anlagenbetreiber.pdf"
                    target="_blank"
                    download
                  >
                    <CustomButton icon="download">
                      Vollmacht herunterladen
                    </CustomButton>
                  </Link>
                  <br />
                  <UploadPdf
                    pdf={_acceptMandateDoc}
                    setPdf={_setAcceptMandateDoc}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : null}
    </CustomModal>
  );
}

PlantAddMandateSignature.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  session: PropTypes.object,
};
