import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { HashLink as Link } from "react-router-hash-link";

import Button from "@mui/material/Button";

import ProjectDeclineModal from "./project/ProjectDeclineModal";
import CustomerTodos from "./todos/CustomerTodos";
import ProjectFormModal from "./project/ProjectFormModal";
import ConfirmationModal from "./shared/modal_utils/ConfirmationModal";
import CustomerEmails from "./project/CustomerEmails";
import CustomerActivities from "./project/CustomerActivities";
import { API_URL_PROJECT, API_URL_PROJECTCOMPLETED } from "../settings";
import {
  getCustomerName,
  getProjectOverview,
  hasPermission,
} from "../elements/utils";
import { CustomButton } from "./../elements/StyledElements";
import LoadingPage from "../elements/LoadingPage";
import TextField from "../elements/TextField";
// import SimulationHouseFormModal from './project/SimulationHouseFormModal'
import { getRegularProjectUpdate } from "./project_types/projectUtils";
import TaskTable from "./customers/TaskTable";
import ProjectLabelModal from "./project/ProjectLabelModal";

export default function Project({ session }) {
  const [project, setProject] = useState(null);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      getRegularProjectUpdate(project).then((update) => {
        if (update) setProject((p) => ({ ...p, ...update }));
      });
    }, 10000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [project]);

  useEffect(() => {
    getProject();
  }, [id]);

  const resetState = () => {
    getProject();
  };

  const getProject = () => {
    axios
      .get(API_URL_PROJECT + id, { params: { nested: true } })
      .then((res) => setProject(res.data));
    // .catch(error => history.push('/'))
  };

  const reactivateProject = () => {
    axios
      .put(API_URL_PROJECT + project.id, { declined: false })
      .then(resetState);
  };

  if (!project) return <LoadingPage />;

  document.title = `PV App - ${project.name}`;

  const customer = project.customer_obj;

  const projectOverview = getProjectOverview(project, false);

  const projectInfo = (
    <ProjectFormModal
      customer={customer}
      project={project}
      resetParent={resetState}
      session={session}
    />
  );

  const todoOverview = (
    <>
      <div className="text-center">
        <CustomerTodos
          project={project}
          resetParent={resetState}
          session={session}
        />
      </div>
    </>
  );

  const customerPage = (
    <Link
      to={"/kunde/" + customer.id}
      style={{ textDecoration: "none", display: "contents" }}
    >
      <CustomButton style={{ width: "100%" }} icon="customer">
        Kundenseite
      </CustomButton>
    </Link>
  );

  const mapPage = (
    <Link
      to={"/karte/?koordinaten=" + project.lat_lng.join(",")}
      style={{ textDecoration: "none", display: "contents" }}
    >
      <CustomButton style={{ width: "100%" }} icon="map">
        Karte
      </CustomButton>
    </Link>
  );

  const projectLabelModal = (
    <ProjectLabelModal session={session} resetParent={resetState} />
  );

  const emailOverview = hasPermission(session.user, "customer_handling") ? (
    <CustomerEmails project={project} session={session} />
  ) : null;
  const activityOverview = hasPermission(session.user, "customer_handling") ? (
    <CustomerActivities project={project} session={session} />
  ) : null;
  // const simulationHouse = <SimulationHouseFormModal simulationHouse={project.simulation_house_obj} project={project} resetParent={resetState} session={session}/>

  const reactivate =
    hasPermission(session.user, "customer_handling") && project.declined ? (
      <Button variant="outlined" color="success" onClick={reactivateProject}>
        Reaktivieren
      </Button>
    ) : null;

  const complete =
    hasPermission(session.user, "project_finish") && !project.declined ? (
      <ConfirmationModal
        title={
          "Ist der Auftrag für '" +
          getCustomerName(customer) +
          "' abgeschlossen?"
        }
        resetParent={resetState}
        confirm={() =>
          axios.post(API_URL_PROJECTCOMPLETED, {
            project: id,
          })
        }
        getOpenButton={(toggle) => (
          <Button variant="outlined" color="success" onClick={toggle}>
            {" "}
            Fertig{" "}
          </Button>
        )}
        btnLabel="Abgeschlossen"
      />
    ) : null;

  const decline =
    hasPermission(session.user, "customer_handling") && !project.declined ? (
      <ProjectDeclineModal
        project={project}
        resetParent={resetState}
        session={session}
      />
    ) : null;

  const remove = hasPermission(session.user, "project_delete") ? (
    <ConfirmationModal
      title={
        "Soll das Projekt '" + project.name + "' wirklich entfernt werden?"
      }
      resetParent={resetState}
      confirm={async () => {
        await axios.delete(API_URL_PROJECT + project.id);
        history.push("/kunde/" + customer.id);
      }}
      getOpenButton={(toggle) => (
        <CustomButton
          onClick={toggle}
          icon="delete"
          iconClassName="IconDeleteButton"
        >
          Löschen
        </CustomButton>
      )}
    />
  ) : null;

  if (isMobileOnly) {
    return (
      <Fragment>
        <Container className="my-5">
          <Col className="my-5">{projectOverview}</Col>
          <Col
            className="my-5 align-items-center justify-content-center"
            style={{ paddingLeft: 50, paddingRight: 50 }}
          >
            {hasPermission(session.user, "customer_handling") ? (
              <>
                <Row className="my-3">{projectInfo}</Row>
              </>
            ) : null}
            <Row className="my-3">{customerPage}</Row>
            <Row className="my-3">{mapPage}</Row>
            <Row className="my-3">{projectLabelModal}</Row>
            {hasPermission(session.user, "customer_handling") ? (
              <>
                <Row className="my-3">{emailOverview}</Row>
                <Row className="my-3">{activityOverview}</Row>
                {/* <br />
                <Row>{simulationHouse}</Row> */}
              </>
            ) : null}
            <hr className="secondary-textcolor" />
            <Row className="my-3">
              {reactivate}
              {complete}
            </Row>
            <hr className="secondary-textcolor" />
            <Row className="my-3">{decline}</Row>
            {remove ? (
              <>
                <Row>{remove}</Row>
              </>
            ) : null}
          </Col>
        </Container>
        <hr className="secondary-textcolor" />
        <Container className="my-5">{todoOverview}</Container>
        <Container className="my-5">
          <TaskTable project={project} session={session} />
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col className="col-4 my-3">
            <Row>{projectOverview}</Row>
            <>
              <hr className="secondary-textcolor" />
              {hasPermission(session.user, "customer_handling") ? (
                <Row>
                  <Col className="my-1">
                    <Row className="my-1 px-2">{projectInfo}</Row>
                    <Row className="my-3 px-2">{emailOverview}</Row>
                    <Row className="my-1 px-2">{mapPage}</Row>
                  </Col>
                  <Col className="my-1">
                    <Row className="my-1 px-2">{customerPage}</Row>
                    <Row className="my-3 px-2">{activityOverview}</Row>
                    <Row className="my-1 px-2">{projectLabelModal}</Row>
                  </Col>
                  {/* <br />
                    <Row>{simulationHouse}</Row> */}
                </Row>
              ) : (
                <>
                  <Row className="my-2">
                    <Col className="my-2">{customerPage}</Col>
                    <Col className="my-2">{mapPage}</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>{projectLabelModal}</Col>
                  </Row>
                </>
              )}
              <hr className="secondary-textcolor" />
              <TextField
                text={project.note}
                save={(text) =>
                  axios
                    .put(API_URL_PROJECT + project.id, { note: text })
                    .then(resetState)
                }
              />
              <hr className="secondary-textcolor" />
              <Col className="d-flex align-items-center justify-content-center">
                {reactivate ? (
                  <Col className="d-flex align-items-center justify-content-center my-1">
                    {reactivate}
                  </Col>
                ) : null}
                {complete ? <Col className="px-2">{complete}</Col> : null}
                {decline ? <Col className="px-2">{decline}</Col> : null}
                {remove ? <Col className="px-2">{remove}</Col> : null}
              </Col>
            </>
          </Col>
          <Col>
            {todoOverview}
            <div className="my-3" />
            <TaskTable project={project} session={session} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

Project.propTypes = {
  session: PropTypes.object,
};
