import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Container } from "reactstrap";
import { Table } from "react-bootstrap";
import axios from "axios";

import { Typography } from "@mui/material";

import UserFormModal from "./user_management/UserFormModal";

import { hasPermission, userWithLabel } from "../elements/utils";
import LoadingPage from "../elements/LoadingPage";
import { API_URL_GROUP, API_URL_PERMISSIONS, API_URL_USER } from "../settings";

export default function UserManagement({ session }) {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [chosenUser, setChosenUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    Promise.all([getUsers(), getPermissions(), getGroups()]).then(() =>
      setLoaded(true),
    );
  }, []);

  const resetState = async () => {
    await getUsers();
  };

  const getUsers = async () => {
    return axios
      .get(API_URL_USER, { params: { is_staff: true } })
      .then((res) => setUsers(sortUsers(res.data)));
  };

  const getGroups = async () => {
    return axios.get(API_URL_GROUP).then((res) => setGroups(res.data));
  };

  const getPermissions = async () => {
    return hasPermission(session.user, "users_change_permissions")
      ? axios.get(API_URL_PERMISSIONS).then((res) => setPermissions(res.data))
      : Promise.resolve();
  };

  const sortUsers = (users) => {
    const sortedUsers = [...users];
    sortedUsers.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return sortedUsers;
  };

  const onRowClick = (user) => {
    if (user) {
      setChosenUser(user);
    }
  };

  if (!loaded) return <LoadingPage />;

  return (
    <Fragment>
      <Container>
        <UserFormModal
          users={users}
          session={session}
          permissions={permissions}
          groups={groups}
          resetParent={resetState}
        />
        <UserFormModal
          session={session}
          resetParent={resetState}
          user={chosenUser}
          users={users}
          isOpen={!!chosenUser}
          permissions={permissions}
          groups={groups}
          setIsOpen={(isOpen) => {
            if (!isOpen) setChosenUser(null);
          }}
          getOpenButton={() => null}
        />
        <br />
        <Table
          className={
            !users || users.length <= 0 ? "table-not-hover" : "table-hover"
          }
        >
          <thead>
            <tr>
              <th className="align-middle">
                <Typography className="secondary-textcolor">#</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Name</Typography>
              </th>
              <th className="align-middle" style={{ width: "40%" }}>
                <Typography className="secondary-textcolor">Rolle</Typography>
              </th>
            </tr>
          </thead>

          <tbody>
            {!users || users.length <= 0
              ? null
              : users.map((user, index) => {
                  const className = user.is_active
                    ? "secondary-textcolor"
                    : "disable-textcolor";
                  const group = groups.find((g) => g.id === user.group);
                  let groupName = group ? group.name : "";
                  if (user.student && group) groupName += "-Lehrlinge";

                  return (
                    <tr key={user.id}>
                      <td
                        className="align-middle"
                        onClick={(e) => {
                          if (!e.ctrlKey) onRowClick(user);
                        }}
                      >
                        <Typography className={className}>
                          {index + 1}
                        </Typography>
                      </td>
                      <td
                        className="align-middle"
                        onClick={(e) => {
                          if (!e.ctrlKey) onRowClick(user);
                        }}
                      >
                        <Typography className={className}>
                          {userWithLabel(user)}{" "}
                        </Typography>
                      </td>
                      <td
                        className="align-middle"
                        style={{ width: "40%" }}
                        onClick={(e) => {
                          if (!e.ctrlKey) onRowClick(user);
                        }}
                      >
                        <Typography className={className}>
                          {groupName}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
}

UserManagement.propTypes = {
  session: PropTypes.object,
};
