import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import {
  getProjectModals,
  getUnclickableProjectTodoKeys,
} from "../project_types/projectUtils";

import { projectPropType } from "../../elements/PropTypes";
import { sortById } from "../../elements/utils";
import { API_URL_TODO } from "../../settings";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import AddCustomerToDo from "./AddCustomerToDo";
import AssignCustomerToDo from "./AssignCustomerToDo";
import EditCustomerToDo from "./EditCustomerToDo";
import { IconButton, Tooltip } from "@mui/material";

export default function CustomerTodos({
  project,
  resetParent,
  parentKey = null,
  session,
}) {
  const [activeStep, setActiveStep] = useState(null);

  // edit
  const [openEdit, setOpenEdit] = useState(false);
  const [currSelectedEdit, setCurrSelectedEdit] = useState(null);

  // assign
  const [openAssign, setOpenAssign] = useState(false);
  const [currSelectedAssign, setCurrSelectedAssign] = useState(null);

  // delete
  const [openDelete, setOpenDelete] = useState(false);
  const [currSelectedDelete, setCurrSelectedDelete] = useState(null);
  const [currSelectedName, setCurrSelectedName] = useState(null);

  const timer = useRef();

  const useStyles = makeStyles(() => ({
    root: {
      "& .Mui-active .MuiStepIcon-root": { color: "grey" },
      "& .Mui-completed .MuiStepIcon-root": { color: "green" },
      "& .Mui-active .Mui-completed .MuiStepIcon-root": { color: "green" },
    },
  }));

  const c = useStyles();

  const lastActiveStep = useRef(null);
  let todos = project ? sortById(project.customertodo_set) : [];
  if (parentKey) {
    const parent = todos.find((t) => t.key === parentKey);
    todos = todos.filter((t) => t.id === parent.id || t.parent === parent.id);
  }

  useEffect(() => {
    if (todos) {
      if (lastActiveStep.current === null) {
        const openToDo = todos.filter((t) => !t.parent).find((t) => !t.done);
        setActiveStep(openToDo ? openToDo.id : null);
      }
    }
  }, [project.customertodo_set]);

  if (!todos) return null;

  const parents = todos.filter((x) => !x.parent);
  const steps = parents.map((p) => ({
    ...p,
    children: todos.filter((x) => x.parent === p.id),
  }));

  const onClick = (step, isParent) => {
    if (isParent) {
      setActiveStep(activeStep !== step.id ? step.id : null);
    }
  };

  const onDoubleClick = (step, isParent) => {
    if (isParent) return;
    lastActiveStep.current = activeStep;

    axios.put(API_URL_TODO + step.id, { done: !step.done }).then(resetParent);
  };

  const onClickHandler = (step, isParent, nClicks) => {
    clearTimeout(timer.current);
    if (nClicks === 1) {
      timer.current = setTimeout(() => onClick(step, isParent), 200);
    } else if (nClicks === 2) {
      onDoubleClick(step, isParent);
    }
  };

  const projectItems = getProjectModals(project, resetParent, session);

  function notDefaultToDo(child) {
    if (child === null) return true;
    return false;
  }

  const deleteHandler = (delData) => {
    try {
      axios
        .delete(API_URL_TODO, {
          data: { todo_id: delData.id, parent: delData.parent },
        })
        .then(resetParent);
    } catch (error) {
      console.error('Error in "delete"', error, error.stack);
    }
  };

  const onDelete = async (delData) => {
    return deleteHandler(delData);
  };

  return (
    <Box>
      <ConfirmationModal
        title={
          "Soll To Do '" + currSelectedName + "' wirklich entfernt werden?"
        }
        resetParent={resetParent}
        confirm={() => onDelete(currSelectedDelete)}
        isOpen={openDelete}
        setIsOpen={setOpenDelete}
        getOpenButton={() => <span style={{ display: "none" }} />}
      />
      <EditCustomerToDo
        project={project}
        session={session}
        isOpen={openEdit}
        setIsOpen={setOpenEdit}
        id={currSelectedEdit}
        resetParent={resetParent}
      />
      <AssignCustomerToDo
        project={project}
        session={session}
        isOpen={openAssign}
        setIsOpen={setOpenAssign}
        id={currSelectedAssign}
        resetParent={resetParent}
      />
      <Stepper orientation="vertical">
        {steps.map((parent, pidx) => (
          <Step
            key={`child-todo-${parent.key}-${pidx}`}
            active={
              parent.id === activeStep ||
              (parentKey !== null && parent.key === parentKey)
            }
            completed={parent.done}
            className={c.root}
          >
            <StepLabel
              StepIconProps={{
                onClick: (e) => onClickHandler(parent, true, e.detail),
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  className="secondary-textcolor"
                  style={{ fontSize: 17, cursor: "pointer" }}
                  onClick={(e) => onClickHandler(parent, true, e.detail)}
                >
                  {parent.name}
                </Typography>
              </div>
              {projectItems.parents.map((item) =>
                item.key !== parent.key
                  ? null
                  : item.modals.map((modal) =>
                      typeof modal === "function" ? modal(parent) : modal,
                    ),
              )}
            </StepLabel>
            <StepContent>
              <Typography>{parent.description}</Typography>
              <Stepper orientation="vertical">
                {parent.children.map((child, cidx) => {
                  const keys = getUnclickableProjectTodoKeys(project);
                  const sx =
                    !child.done && !keys.includes(child.key)
                      ? {
                          "& .Mui-active .MuiStepIcon-root": {
                            color: "grey",
                            padding: "1.5px",
                            borderRadius: "50%",
                            border: "1.5px solid grey",
                          },
                        }
                      : {};
                  return (
                    <Step
                      key={`child-todo-${child.key}-${cidx}`}
                      active={true}
                      completed={child.done}
                      sx={sx}
                    >
                      <StepLabel
                        StepIconProps={{
                          onClick: (e) =>
                            onClickHandler(child, false, e.detail),
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className="secondary-textcolor"
                            style={{ fontSize: 16, cursor: "default" }}
                            onClick={(e) =>
                              onClickHandler(child, false, e.detail)
                            }
                          >
                            {child.name}
                          </Typography>
                          <div style={{ justifyContent: "flex-end" }}>
                            <Tooltip
                              key={`assign-btn-${child.name}`}
                              title="Mitarbeiter"
                            >
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                style={{ backgroundColor: "transparent" }}
                                size="small"
                                onClick={() => {
                                  setOpenAssign(true);
                                  setCurrSelectedAssign(child.id);
                                }}
                              >
                                <AssignmentIndIcon />
                              </IconButton>
                            </Tooltip>
                            {notDefaultToDo(child.key) && (
                              <>
                                <Tooltip
                                  key={`edit-btn-${child.name}`}
                                  title="Bearbeiten"
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    style={{ backgroundColor: "transparent" }}
                                    size="small"
                                    onClick={() => {
                                      setOpenEdit(true);
                                      setCurrSelectedEdit(child.id);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <span style={{ marginLeft: "5px" }}></span>
                                <Tooltip
                                  key={`delete-btn-${child.name}`}
                                  title="Löschen"
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    style={{ backgroundColor: "transparent" }}
                                    size="small"
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setCurrSelectedDelete({
                                        id: child.id,
                                        parent: parent.id,
                                      });
                                      setCurrSelectedName(child.name);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </div>
                        </div>
                        {projectItems.children.map((item) =>
                          item.key !== child.key
                            ? null
                            : item.modals.map((modal) =>
                                typeof modal === "function"
                                  ? modal(child)
                                  : modal,
                              ),
                        )}
                      </StepLabel>
                      <StepContent>
                        <Typography
                          className="secondary-textcolor"
                          style={{
                            fontSize: 14,
                            textAlign: "left",
                            cursor: "default",
                          }}
                        >
                          {child.description}
                        </Typography>
                        <Box sx={{ textAlign: "right" }}>
                          <AddCustomerToDo
                            project={project}
                            parent={parent}
                            prevToDo={child}
                            resetParent={resetParent}
                            session={session}
                          />
                        </Box>
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

CustomerTodos.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  parentKey: PropTypes.string,
  session: PropTypes.object,
};
