import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";
import WarehouseProductForm from "./WarehouseProductForm";

import CustomModal from "../shared/modal_utils/CustomModal";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { CustomButton } from "../../elements/StyledElements";
import { API_URL_WAREHOUSEPRODUCT } from "../../settings";
import { PropTypes } from "prop-types";
import QRCode from "qrcode";

const emptyWarehouseProductForm = {
  id: null,
  product_type: [],
  name: null,
  suppliers: [],
  min_amount_material: null,
  description: "",
  comment: "",
  material_per_box: null,
  weight: null,
  used_in_products: [],
};

export default function WarehouseProductFormModal({
  newProduct,
  location,
  warehouseProduct,
  warehouseProductTypes,
  suppliers,
  products,
  resetParent,
  session,
  employee,
}) {
  const [warehouseProductForm, setWarehouseProductForm] = useState({
    ...emptyWarehouseProductForm,
    request_as: employee,
  });
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  useEffect(() => {
    QRCode.toDataURL(
      `${warehouseProductForm.id ? warehouseProductForm.id : " "}`,
      { width: 256 },
      (err, url) => {
        if (!err) setQrCodeUrl(url);
      },
    );
  }, [warehouseProductForm]);

  const loadData = () => {
    if (!newProduct) {
      setWarehouseProductForm({
        ...warehouseProduct,
        request_as: employee,
        location,
        active_supplier: warehouseProduct.amounts.find(
          (amount) => amount.location_id === location,
        )?.active_supplier_id,
      });
    } else
      setWarehouseProductForm({
        ...emptyWarehouseProductForm,
        request_as: employee,
        location,
        active_supplier: null,
      });
  };

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false });
    setWarehouseProductForm({
      ...emptyWarehouseProductForm,
      request_as: employee,
    });
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else {
      clearData();
      // if (!newProduct) setWarehouseProduct(null)
    }
  };

  const submit = async (warehouseProductForm) => {
    if (warehouseProduct) {
      return axios.put(
        API_URL_WAREHOUSEPRODUCT + warehouseProduct.id,
        warehouseProductForm,
      );
    }
    return axios.post(API_URL_WAREHOUSEPRODUCT, warehouseProductForm);
  };

  const onSubmit = async (onSuccess) => {
    // Checking for empty supplier fields
    for (let i = 0; i < warehouseProductForm.suppliers.length; i++) {
      const supplier = warehouseProductForm.suppliers[i];
      const emptyFields = ["supplier_id", "product_name"].filter(
        (key) => supplier[key] === "" || supplier[key] === null,
      );
      if (emptyFields.length !== 0) {
        console.error("Empty fields: ", emptyFields);
        setLoadingElements({
          ...loadingElements,
          submitError: "Bitte alle Informationen eintragen!",
          showMissingFields: true,
        });
        return;
      }
    }
    // Checking for empty used in product fields
    for (let i = 0; i < warehouseProductForm.used_in_products.length; i++) {
      const product = warehouseProductForm.used_in_products[i];
      const emptyFields = ["product_id", "amount"].filter(
        (key) => product[key] === "" || product[key] === null,
      );
      if (emptyFields.length !== 0) {
        console.error("Empty fields: ", emptyFields);
        setLoadingElements({
          ...loadingElements,
          submitError: "Bitte alle Informationen eintragen!",
          showMissingFields: true,
        });
        return;
      }
    }

    const optionalKeys = [
      "description",
      "comment",
      "weight",
      "supplier_name",
      "supplier_product_name",
      "supplier_product_id",
      "supplier_price",
      "active_supplier",
    ];
    if (!warehouseProduct) optionalKeys.push("id");
    const productKeys = Object.keys(warehouseProductForm).filter(
      (x) => !optionalKeys.includes(x),
    );
    const emptyFields = productKeys.filter(
      (key) =>
        warehouseProductForm[key] === "" || warehouseProductForm[key] === null,
    );
    if (emptyFields.length !== 0) {
      console.error("Empty fields: ", emptyFields);
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        showMissingFields: true,
      });
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(warehouseProductForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error("Error in WarehouseProduct:Submit: ", error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <Container>
        <Row>
          <Col>
            {warehouseProduct ? (
              <ConfirmationModal
                title={
                  "Soll '" +
                  warehouseProduct.name +
                  "' wirklich entfernt werden?"
                }
                resetParent={resetParent}
                confirm={() =>
                  axios
                    .delete(API_URL_WAREHOUSEPRODUCT + warehouseProduct.id)
                    .then(toggle)
                }
                getOpenButton={(toggle) => (
                  <CustomButton
                    onClick={toggle}
                    icon="delete"
                    iconClassName="IconDeleteButton"
                  >
                    Löschen
                  </CustomButton>
                )}
              />
            ) : null}
          </Col>
          <Col className="d-flex justify-content-end">
            <SaveModalFooter
              submitError={loadingElements.submitError}
              inProgress={loadingElements.inProgress}
              onSave={() => {
                onSubmit(toggle);
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  const getOpenButton = (toggle) => {
    if (newProduct) {
      return (
        <CustomButton onClick={toggle}>Neues Material erstellen</CustomButton>
      );
    }
    return (
      <IconButton
        disableFocusRipple
        disableRipple
        style={{
          backgroundColor: "transparent",
          float: "right",
        }}
        size="small"
        onClick={toggle}
      >
        <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
      </IconButton>
    );
  };

  const getIsOpen = () => {
    if (newProduct) return null;
    return warehouseProduct !== emptyWarehouseProductForm;
  };

  const title = "Materialinformationen";

  return (
    <CustomModal
      size="lg"
      isOpen={getIsOpen()}
      getOpenButton={getOpenButton}
      title={title}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <WarehouseProductForm
        warehouseProduct={warehouseProductForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setWarehouseProduct={setWarehouseProductForm}
        warehouseProductTypes={warehouseProductTypes}
        suppliers={suppliers}
        products={products}
        qrCodeUrl={qrCodeUrl}
      />
    </CustomModal>
  );
}

WarehouseProductFormModal.propTypes = {
  newProduct: PropTypes.bool,
  location: PropTypes.number,
  warehouseProduct: PropTypes.object,
  warehouseProductTypes: PropTypes.array,
  suppliers: PropTypes.array,
  products: PropTypes.array,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  employee: PropTypes.number,
};
