import React, { useState, Fragment } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { FormGroup, Input, InputGroup } from "reactstrap";
import { Table } from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Typography, Tooltip } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { invoicePropType, projectPropType } from "../../elements/PropTypes";
import { CustomButton, CustomToggle } from "../../elements/StyledElements";
import { filterObj, getErrorMessage, sortByOrder } from "../../elements/utils";
import {
  API_URL_CREATEINVOICE,
  API_URL_CUSTOMPRODUCT,
  API_URL_INVOICE,
  API_URL_POSTCREATEINVOICE,
  API_URL_PRODUCT,
  API_URL_PRODUCTGROUP,
} from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import InvoiceForm from "./InvoiceForm";
import { toast } from "react-toastify";

const emptyInvoiceForm = {
  id: null,
  project: null,
  offer: null,
  sent: false,
  paid: 0,
  final: false,
  total_gross: null,
  total_net: null,
  discount: 0,
  vat: null,
};

export default function InvoiceFormModal({
  project,
  invoice,
  productRequestParams = {},
  resetParent,
  final = true,
  getOpenButton,
  disabled = false,
  session,
  setInvoiceModalOpen = null,
}) {
  const [invoiceForm, setInvoiceForm] = useState(null);
  const [customProductForms, setCustomProductForms] = useState([]);
  const [amountCols, setAmountCols] = useState({
    plannedAmountCol: false,
    realAmountCol: false,
    offerAmountCol: false,
    invoiceAmountCol: false,
  });
  const [products, setProducts] = useState(null);
  const [productGroups, setProductGroups] = useState([]);
  const [showManHours, setShowManHours] = useState(true); // TODO show man hours only if anlage
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  const [tableData, setTableData] = useState(null);
  const constructionId = project.construction ? project.construction : null;

  const resetProducts = async () => {
    return axios
      .get(API_URL_PRODUCT, {
        params: { project: project.id, ...productRequestParams },
      })
      .then((res) => {
        const products = res.data;
        setProducts(products);
        const amountCols = {
          plannedAmountCol: res.headers.planned_amounts === "True",
          realAmountCol: res.headers.real_amounts === "True",
          offerAmountCol: res.headers.offer_amounts === "True",
          invoiceAmountCol: res.headers.invoice_amounts === "True",
        };
        setAmountCols(amountCols);
        return axios.get(API_URL_PRODUCTGROUP).then((res) => {
          const productGroups = res.data;
          setProductGroups(productGroups);
          return [products, amountCols, productGroups];
        });
      });
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const clearData = () => {
    setInvoiceForm(null);
    setLoadingElements({ inProgress: false, submitError: false });
    setCustomProductForms([]);
    setProducts(null);
    setShowManHours(true);
  };

  const loadData = () => {
    setInvoiceForm(
      invoice || { ...emptyInvoiceForm, project: project.id, final },
    );
    if (invoice) {
      resetProducts().then(([products, amountCols]) => {
        axios
          .get(API_URL_CUSTOMPRODUCT, { params: { invoice: invoice.id } })
          .then((res) => {
            setCustomProductForms(
              res.data.map((customProduct, _idx) => {
                const product = products.find(
                  (p) => p.id === customProduct.product,
                );
                return {
                  id: customProduct.id,
                  name: customProduct.name,
                  description: customProduct.description,
                  product: customProduct.product,
                  price: customProduct.price,
                  vat: customProduct.vat,
                  amount: customProduct.amount,
                  order: customProduct.order ? customProduct.order : _idx + 1,
                  priority: customProduct.priority,
                  productObj: product,
                };
              }),
            );
            return products;
          });
      });
    } else if (constructionId) {
      resetProducts().then(([products, amountCols]) => {
        axios
          .get(API_URL_CUSTOMPRODUCT, {
            params: { construction: constructionId },
          })
          .then((res) => {
            setCustomProductForms(
              res.data.map((customProduct, _idx) => {
                const product = products.find(
                  (p) => p.id === customProduct.product,
                );
                return {
                  id: customProduct.id,
                  name: customProduct.name,
                  description: customProduct.description,
                  product: customProduct.product,
                  price: customProduct.price,
                  vat: customProduct.vat,
                  amount: customProduct.amount,
                  order: customProduct.order ? customProduct.order : _idx + 1,
                  priority: customProduct.priority,
                  productObj: product,
                };
              }),
            );
            return products;
          });
      });
    } else {
      resetProducts().then(([products, amountCols]) => {
        axios
          .get(API_URL_CUSTOMPRODUCT, {
            params: { offer: project.accepted_offer },
          })
          .then((res) => {
            const customProducts = res.data;
            setCustomProductForms(
              products
                .filter(
                  (p) =>
                    showProduct(p, amountCols) &&
                    !isOptional(p, customProducts),
                )
                .map((product, _idx) => {
                  const customProduct = customProducts.find(
                    (cp) => cp.product === product.id,
                  );
                  return {
                    id: null,
                    name: customProduct ? customProduct.name : product.name,
                    description: customProduct
                      ? customProduct.description
                      : product.description,
                    product: product.id,
                    price: product.price,
                    vat: customProduct ? customProduct.vat : product.vat,
                    amount: getAmount(product, amountCols),
                    order:
                      customProduct && customProduct.order
                        ? customProduct.order
                        : _idx + 1,
                    priority:
                      customProduct && customProduct.priority
                        ? customProduct.priority
                        : null,
                    productObj: product,
                  };
                }),
            );
          });
        return products;
      });
    }
  };

  const getAmount = (product, amountCols, invoiceFinal = null) => {
    const _final =
      invoiceFinal != null ? invoiceFinal : invoice ? invoice.final : final;
    const substract = _final ? 0 : product.amount_invoices;
    let amount = amountCols.realAmountCol
      ? product.amount_real - substract
      : amountCols.offerAmountCol
        ? product.amount_offer - substract
        : amountCols.invoiceAmountCol
          ? product.amount_invoices - substract
          : 0;
    if (amountCols.invoiceAmountCol && _final) {
      amount = Math.max(amount, product.amount_invoices);
    }
    return Math.max(amount, 0);
  };

  const onFinalChange = (e, val) => {
    if (val == null) return;
    setInvoiceForm({ ...invoiceForm, final: val, discount: 0 });
    setCustomProductForms(
      customProductForms.map((p) => ({
        ...p,
        amount: getAmount(p.productObj, amountCols, val),
      })),
    );
  };

  const showProduct = (product, amountCols) =>
    (amountCols.plannedAmountCol && product.amount_planned !== 0) ||
    (amountCols.invoiceAmountCol && product.amount_invoices !== 0) ||
    (amountCols.realAmountCol && product.amount_real !== 0) ||
    (amountCols.offerAmountCol && product.amount_offer !== 0);

  const isOptional = (product, customProducts) =>
    customProducts.find((cp) => cp.product === product.id).optional;

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    if (invoice) {
      return (
        <Tooltip title="Bearbeiten" PopperProps={{ style: { zIndex: 9999 } }}>
          <IconButton
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent" }}
            size="small"
            onClick={toggle}
          >
            <EditOutlinedIcon
              className="secondary-textcolor"
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
      );
    }
    const buttonLabel = final
      ? "Neue Endrechnung erstellen"
      : "Neue Teilrechnung erstellen";
    return (
      <CustomButton
        variant="outlined"
        id="secondary-textcolor"
        onClick={toggle}
      >
        {" "}
        {buttonLabel}{" "}
      </CustomButton>
    );
  };

  const submit = async (invoiceForm) => {
    let promise;
    // remove pdf as pdf is handled by backend
    const invoiceFormToSubmit = filterObj(
      invoiceForm,
      Object.keys(emptyInvoiceForm),
    );
    if (invoice) {
      invoiceFormToSubmit.sent = false;
      promise = axios.put(API_URL_INVOICE + invoiceForm.id, {
        ...invoiceFormToSubmit,
        total_gross: null,
        total_net: null,
      });
    } else {
      promise = axios.post(API_URL_INVOICE, invoiceFormToSubmit);
    }
    return promise.then(async (res) => {
      const invoiceResponse = res.data;
      const invoiceId = res.data.id;
      const customProductPromise = invoice
        ? axios.delete(API_URL_CUSTOMPRODUCT, {
            data: {
              invoice: invoiceId,
              except_ids: customProductForms
                .filter((x) => x.id)
                .map((x) => x.id),
            },
          })
        : Promise.resolve();
      return customProductPromise
        .then(async () =>
          Promise.all(
            customProductForms.map((customProduct) => {
              customProduct.invoice = invoiceId;
              if (customProduct.id) {
                return customProduct.amount
                  ? axios.put(
                      API_URL_CUSTOMPRODUCT + customProduct.id,
                      customProduct,
                    )
                  : axios.delete(API_URL_CUSTOMPRODUCT + customProduct.id);
              }
              return customProduct.amount
                ? axios.post(API_URL_CUSTOMPRODUCT, customProduct)
                : Promise.resolve();
            }),
          ),
        )
        .then(async () =>
          axios
            .post(API_URL_CREATEINVOICE, {
              invoice: invoiceId,
              show_man_hours: showManHours && invoiceForm.final,
            })
            .then((res) => {
              axios.post(API_URL_POSTCREATEINVOICE, { invoice: invoiceId });
              return res;
            }),
        )
        .then(() => invoiceResponse);
    });
  };

  const onSubmit = async (onSuccess, confirmed = false) => {
    const acceptedOffer = project.accepted_offer_obj;
    const construction = project.construction_obj;
    if (!confirmed) {
      if (
        project.resourcetype !== "BlankInvoiceProject" &&
        project.resourcetype !== "BlankOfferProject" &&
        (acceptedOffer.planning_obj.kwp !== construction.kwp ||
          acceptedOffer.planning_obj.n_horizontal_panels !==
            construction.n_horizontal_panels ||
          acceptedOffer.planning_obj.n_vertical_panels !==
            construction.n_vertical_panels)
      ) {
        const data = [
          {
            category: "Baudokumentation",
            details: {
              kwp: acceptedOffer.planning_obj.kwp,
              horizontalModules: acceptedOffer.planning_obj.n_horizontal_panels,
              verticalModules: acceptedOffer.planning_obj.n_vertical_panels,
            },
          },
          {
            category: "Bauplanung",
            details: {
              kwp: construction.kwp,
              horizontalModules: construction.n_horizontal_panels,
              verticalModules: construction.n_vertical_panels,
            },
          },
        ];
        setTableData(data);
        setConfirmationModalOpen(true);
        return;
      }
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });
    return submit(invoiceForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
        if (setInvoiceModalOpen) {
          setInvoiceModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error in InvoiceFormModal: ", error, error.stack);
        toast.error(getErrorMessage(error));
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <>
        <CustomModal
          getFooter={(_toggle) => (
            <SaveModalFooter
              onSave={() => {
                _toggle();
                onSubmit(toggle, true);
              }}
              saveBtnLabel={"Ja"}
            />
          )}
          size="lg"
          title={"Unterschiede zwischen Bauplanung & Baudokumentation"}
          isOpen={confirmationModalOpen}
          setIsOpen={setConfirmationModalOpen}
        >
          <Typography variant="h6">
            <Table striped bordered hover style={{ marginTop: "30px" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Kwp</th>
                  <th>Horizontal Modules</th>
                  <th>Vertical Modules</th>
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((data, idx) => (
                    <tr key={idx}>
                      <td>{data.category}</td>
                      <td>{data.details.kwp}</td>
                      <td>{data.details.horizontalModules}</td>
                      <td>{data.details.verticalModules}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            Die Anzahl der Module oder kWp aus der Bauplanung & Baudokumentation
            unterscheiden sich. Bitte mit &quot;Ja&quot; bestätigen, dass dieser
            Unterschied akzeptiert wird.
          </Typography>
        </CustomModal>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle, false)}
          disabled={disabled}
        />
      </>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title={invoice ? "Rechnung " + invoice.id : "Neue Rechnung"}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      {invoiceForm && !invoice ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Endrechnung:</Typography>
          <br />
          <ToggleButtonGroup
            size="small"
            value={invoiceForm.final}
            exclusive
            onChange={onFinalChange}
          >
            <CustomToggle value={false}>Nein</CustomToggle>
            <CustomToggle value={true}>Ja</CustomToggle>
          </ToggleButtonGroup>
        </FormGroup>
      ) : null}
      <InvoiceForm
        invoice={invoiceForm}
        setInvoice={setInvoiceForm}
        customProducts={sortByOrder(customProductForms)}
        setCustomProducts={setCustomProductForms}
        session={session}
        project={project}
        resetProducts={resetProducts}
        products={products}
        productGroups={productGroups}
        amountCols={amountCols}
      />
      {invoiceForm &&
      invoiceForm.final &&
      customProductForms.some(
        (p) => p.amount !== 0 && p.productObj.key === "anlage",
      ) ? (
        <FormGroup>
          <Typography className="secondary-textcolor">
            Monteurstunden extra ausweisen:
          </Typography>
          <br />
          <ToggleButtonGroup
            size="small"
            value={showManHours}
            exclusive
            onChange={(e, val) => {
              if (val !== null) setShowManHours(val);
            }}
          >
            <CustomToggle value={false}>Nein</CustomToggle>
            <CustomToggle value={true}>Ja</CustomToggle>
          </ToggleButtonGroup>
        </FormGroup>
      ) : null}
      {invoiceForm && !invoiceForm.final ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Abzug:</Typography>
          <br />
          <InputGroup style={{ width: "40%" }}>
            <Input
              id="input"
              placeholder="Eingabe"
              type="number"
              width={"15px"}
              value={invoiceForm.discount}
              onChange={(e) => {
                setInvoiceForm({ ...invoiceForm, discount: e.target.value });
              }}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) e.preventDefault();
              }}
              style={{ boxShadow: "none" }}
              min={0}
              onKeyDown={(e) => {
                if (e.key === "-") e.preventDefault();
              }}
            />
          </InputGroup>
          {/* </InputGroup>  */}
        </FormGroup>
      ) : null}
      <div>
        <br />
      </div>
    </CustomModal>
  );
}

InvoiceFormModal.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  productRequestParams: PropTypes.object,
  resetParent: PropTypes.func,
  final: PropTypes.bool,
  getOpenButton: PropTypes.func,
  disabled: PropTypes.bool,
  session: PropTypes.object,
  setInvoiceModalOpen: PropTypes.func,
};
