import React, { useState } from "react";
import { PropTypes } from "prop-types";

import CustomModal from "../shared/modal_utils/CustomModal";
import { IconButton, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { Input } from "reactstrap";
import { taskPropType } from "../../elements/PropTypes";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_TASKATTACHMENT } from "../../settings";
import axios from "axios";

const emptyFileForm = {
  name: null,
  file: null,
  uploader: null,
  author_name: null,
};

export default function AddAttachmentModal({
  task = null,
  save,
  getOpenButton,
  isOpen,
  setIsOpen,
  session,
}) {
  const [fileForm, setFileForm] = useState({ ...emptyFileForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (isOpen)
      setFileForm({
        ...emptyFileForm,
        task: task && task.id,
        uploader: session.user.id,
        uploader_name: `${session.user.first_name} ${session.user.last_name}`,
      });
  };

  const _getOpenButton = (toggle) => {
    if (isOpen != null) return null;
    return (
      <IconButton
        size="small"
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        onClick={toggle}
      >
        <Add style={{ color: "#424242" }} fontSize="small" />
        <Typography className="secondary-textcolor">
          Anhang hinzufügen
        </Typography>
      </IconButton>
    );
  };

  const getFormData = () => {
    const formData = new FormData();
    formData.append("task", task.id);
    formData.append("name", fileForm.name);
    formData.append("file", fileForm.file, fileForm.file.name);
    return formData;
  };

  const _save = async () => {
    // if (!save && !task) throw "No save method but no task provided"
    setLoadingElements((l) => ({ ...l, inProgress: true, submitError: false }));
    // const promise = (save) ? save(fileForm) :

    return (
      task
        ? axios.post(API_URL_TASKATTACHMENT, getFormData())
        : Promise.resolve()
    )
      .then(() => {
        save && save(fileForm);
      })
      .then(() => {
        setLoadingElements((l) => ({
          ...l,
          inProgress: false,
          submitError: false,
        }));
      })
      .catch((e) => {
        console.error(e);
        setLoadingElements((l) => ({
          ...l,
          inProgress: false,
          submitError: true,
        }));
      });
  };

  const onFileChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const file = e.target.files[0];

    // if (!['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
    //   setLoadingElements({ ...loadingElements, submitError: 'Bitte eine Datei vom Typ PDF/JPG/PNG/JPEG hochladen' })
    //   return
    // }
    setLoadingElements({ ...loadingElements, submitError: null });
    setFileForm((f) => ({ ...f, file }));
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onCancel={toggle}
        cancelBtnLabel="Schließen"
        onSave={() => {
          _save().then(toggle);
        }}
        saveBtnLabel="Speichern"
      />
    );
  };

  return (
    <CustomModal
      size="xl"
      title={"Neuer Anhang"}
      getFooter={getFooter}
      getOpenButton={getOpenButton || _getOpenButton}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onToggle={onToggle}
    >
      <Stack sx={{ marginTop: "10px" }} direction="column" spacing={3}>
        <div>
          <Typography>Name: </Typography>
          <Input
            id="input"
            type="text"
            onChange={(e) => {
              setFileForm((f) => ({ ...f, name: e.target.value }));
            }}
            style={{ width: "50%" }}
          />
        </div>
        <div>
          <Typography>Datei: </Typography>
          <Input
            style={{ width: "50%", boxShadow: "none" }}
            id="input"
            type="file"
            onChange={onFileChange}
          />
          {/* <Input style={{ width: '50%', boxShadow: 'none' }} id="input" type="file" accept="application/pdf, image/png, image/jpeg, image/jpg" onChange={onFileChange} /> */}
        </div>
      </Stack>
    </CustomModal>
  );
}

AddAttachmentModal.propTypes = {
  task: taskPropType,
  save: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
