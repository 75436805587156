import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { FormGroup } from "reactstrap";
import { isMobileOnly } from "react-device-detect";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";

import { Typography, Box } from "@mui/material";

import TimeManagementModal from "./TimeManagementModal";
import DefaultWorkingHoursModal from "./DefaultWorkingHoursModal";
import VacationApprovalModal from "./VacationApprovalModal";
import DayOverviewModal from "./DayOverviewModal";
import ExcelExport from "./ExcelExportTimeEvents";
import VacationAccountModal from "./VacationAccountModal";

import DropDown from "../../elements/DropDown";
import {
  round,
  arrSum,
  THIS_YEAR,
  MONTHS,
  getHolidayEvents,
  hasPermission,
  string2Date,
  getTotalDays,
  isNormalWorkingHoursEventType,
} from "../../elements/utils";
import LoadingPage from "../../elements/LoadingPage";
import "../shared/calendarStyle.css";
import { API_URL_TIMEMANAGEMENTEVENT, API_URL_USER } from "../../settings";
import CustomCalendar from "./CustomCalendar";
import VacationCalendar from "./VacationCalendar";

export default function TimeManagementCalendar({ session }) {
  const years = Array.from(
    { length: THIS_YEAR - 2022 },
    (_, i) => THIS_YEAR - i,
  );

  const [timeManagementEvents, setTimeManagementEvents] = useState([]);
  const [chosenEvent, setChosenEvent] = useState(null);
  const [chosenDate, setChosenDate] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(
    !session.user.visible ? null : session.user.id,
  );
  const [selectedYear, setSelectedYear] = useState(THIS_YEAR);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isThereEvents, setIsThereEvents] = useState(false);
  const [currentView, setCurrentView] = useState("month");
  const [listOfVacationDays, setListOfVacationDays] = useState([]);
  const [approvedVacationDays, setApprovedVacationDays] = useState(0);
  const [notApprovedVacationDays, setNotApprovedVacationDays] = useState(0);
  const [calendarYearDate, setCalendarYearDate] = useState(
    new Date().getFullYear(),
  );
  const [loading, setLoading] = useState({
    page: true,
    export: false,
  });
  const [openVacationCalendar, setOpenVacationCalendar] = useState(false);

  const allEvents = useRef([]);
  const entitledHoliday = employees
    .find((e) => e.id === employee)
    ?.holiday?.find((h) => h.year === selectedYear)?.count;

  const handleSelect = (slot) => {
    const _selectedDate = slot.start;
    if (
      employees.find(
        (e) => e.id === (employee == null ? session.user.id : employee),
      )
    )
      setChosenDate(_selectedDate);
    setIsThereEvents(
      timeManagementEvents.some(
        (event) =>
          string2Date(event.start_date) <= _selectedDate &&
          _selectedDate <= string2Date(event.end_date),
      ),
    );
  };

  const exportToExcel = () => {
    setLoading({ ...loading, export: true });
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    // filter events for year / month
    let _events = allEvents.current.filter((event) => {
      const startTime = new Date(`${event.start_date} ${event.start_time}`);
      return (
        startTime.getMonth() === selectedMonth &&
        startTime.getYear() + 1900 === selectedYear
      );
    });
    // do not include "free due to 4 day week"
    _events = _events.filter((e) => e.event_type !== 3);
    const sum = ["Gesamt"].concat(Array(employees.length).fill(0));
    const workingSum = ["Arbeitszeit"].concat(Array(employees.length).fill(0));
    const schoolSum = ["Schule"].concat(Array(employees.length).fill(0));
    const overtimeReductionSum = ["Überstundenabbau"].concat(
      Array(employees.length).fill(0),
    );
    const vacationSum = ["Urlaub"].concat(Array(employees.length).fill(0));
    const illSum = ["Krankheit"].concat(Array(employees.length).fill(0));
    employees.forEach((_user) => {
      workingSum[`${_user.first_name} ${_user.last_name}`] = 0;
      schoolSum[`${_user.first_name} ${_user.last_name}`] = 0;
      overtimeReductionSum[`${_user.first_name} ${_user.last_name}`] = 0;
      vacationSum[`${_user.first_name} ${_user.last_name}`] = 0;
      illSum[`${_user.first_name} ${_user.last_name}`] = 0;
      sum[`${_user.first_name} ${_user.last_name}`] = 0;
    });
    const newExcelData = [["Tag"].concat(employees.map((e) => e.name))];
    const excelColors = [
      ["5884c7"].concat(Array(employees.length).fill("5884c7")),
    ];
    daysInMonth.forEach((day) => {
      const dayData = [];
      const dayColors = [];
      dayData.push(day.toLocaleString("de-DE").split(",")[0]);
      dayColors.push("5884c7");
      const weekend = [0, 6].includes(day.getDay());
      const dayEvents = weekend
        ? []
        : _events.filter((event) => {
            const startDate = string2Date(event.start_date);
            const endDate = string2Date(event.end_date);
            return startDate <= day && day <= endDate;
          });

      const getDayColor = (eventType) => {
        if (weekend) return "8a96a8";
        else if (eventType === 0) return "ffffff";
        else if (eventType === 1) return "88c28c";
        else if (eventType === 4) return "964b00";
        else if (eventType === 5) return "ffff00";

        return "ed6b6d";
      };
      employees.forEach((_user, _uIdx) => {
        const userEvents = dayEvents.filter((event) => event.user === _user.id);
        const totalHours = arrSum(
          userEvents.map((event) => round(event.duration, 1)),
        );
        sum[_uIdx + 1] += totalHours;
        workingSum[_uIdx + 1] += arrSum(
          userEvents
            .filter((event) => event.event_type === 0)
            .map((event) => round(event.duration, 1)),
        );
        schoolSum[_uIdx + 1] += arrSum(
          userEvents
            .filter((event) => event.event_type === 4)
            .map((event) => round(event.duration, 1)),
        );
        overtimeReductionSum[_uIdx + 1] += arrSum(
          userEvents
            .filter((event) => event.event_type === 5)
            .map((event) => round(event.duration, 1)),
        );
        vacationSum[_uIdx + 1] += userEvents.filter(
          (event) => event.event_type === 1,
        ).length;
        illSum[_uIdx + 1] += userEvents.filter(
          (event) => event.event_type === 2,
        ).length;
        dayData.push(totalHours);
        const eventType = userEvents.length > 0 ? userEvents[0].event_type : 0;
        dayColors.push(getDayColor(eventType));
      });
      newExcelData.push(dayData);
      excelColors.push(dayColors);
    });

    newExcelData.push(vacationSum);
    excelColors.push(["88c28c"].concat(Array(employees.length).fill("88c28c")));
    newExcelData.push(illSum);
    excelColors.push(["ed6b6d"].concat(Array(employees.length).fill("ed6b6d")));
    newExcelData.push(workingSum);
    excelColors.push(["ffffff"].concat(Array(employees.length).fill("ffffff")));
    newExcelData.push(schoolSum);
    excelColors.push(["964b00"].concat(Array(employees.length).fill("964b00")));
    newExcelData.push(overtimeReductionSum);
    excelColors.push(["ffff00"].concat(Array(employees.length).fill("ffff00")));
    newExcelData.push(sum);
    excelColors.push(["5884c7"].concat(Array(employees.length).fill("5884c7")));

    setLoading({ ...loading, export: false });

    return { data: newExcelData, colors: excelColors };
  };

  useEffect(() => {
    let _events = [...allEvents.current];
    if (employee != null)
      _events = _events.filter((event) => event.user === employee);
    setTimeManagementEvents(_events);
  }, [employee, allEvents.current]);

  useEffect(() => {
    // Recalculate listOfVacationDays when calendarYearDate changes
    let _events = [...allEvents.current];
    if (employee != null)
      _events = _events.filter((event) => event.user === employee);
    getListOfVacationDays(_events);
  }, [calendarYearDate, employee, allEvents.current]);

  useEffect(() => {
    countVacationDays();
  }, [listOfVacationDays]);

  const loadEmployee = async () => {
    const { data } = await axios.get(API_URL_USER, {
      params: { is_staff: true, is_active: true, visible: true },
    });
    setEmployees(data);
    setLoading({ ...loading, page: false });
  };

  const loadData = async () => {
    const { data } = await axios.get(API_URL_TIMEMANAGEMENTEVENT);
    allEvents.current = data;

    await loadEmployee();
  };

  const getListOfVacationDays = (events) => {
    const _vacationDays = events.filter((event) => {
      const startDate = new Date(event.start_date).getFullYear();
      const endDate = new Date(event.end_date).getFullYear();
      return (
        event.event_type === 1 &&
        (startDate === calendarYearDate || endDate === calendarYearDate)
      );
    });
    setListOfVacationDays(_vacationDays);
  };

  const countVacationDays = () => {
    let _approvedVacationDays = 0;
    let _notApprovedVacationDays = 0;
    listOfVacationDays.forEach((vacationDay) => {
      if (vacationDay.approval) {
        _approvedVacationDays += getTotalDays(
          vacationDay.start_date,
          vacationDay.end_date,
          calendarYearDate,
        );
      } else {
        _notApprovedVacationDays += getTotalDays(
          vacationDay.start_date,
          vacationDay.end_date,
          calendarYearDate,
        );
      }
    });
    setApprovedVacationDays(_approvedVacationDays);
    setNotApprovedVacationDays(_notApprovedVacationDays);
  };

  const handleNavigate = (date) => {
    setCalendarYearDate(date.getFullYear());
  };

  const timeManagementEvents2calendarEventData = (events) => {
    return events.map((item) => {
      const startdate = new Date(`${item.start_date} ${item.start_time}`);
      const enddate = new Date(`${item.end_date} ${item.end_time}`);
      const addtitle = isNormalWorkingHoursEventType(item.event_type)
        ? ""
        : item.event_type === 1
          ? "(U)"
          : item.event_type === 2
            ? "(K)"
            : "(4T)";
      const employee = employees.find((e) => e.id === item.user);
      const addtitlename = employee ? employee.last_name : "";
      // employee ? addtitlename = employee.first_name + ' ' + employee.last_name  : '';
      return {
        // 'title': `${item.duration} Stunden`,
        title: `${addtitlename} ${round(item.duration, 1)}h ${addtitle}`,
        start: startdate,
        end: enddate,
        event: item,
        user: item.user,
        opacity: item.event_type !== 0 && !item.approval ? 0.5 : 1, // unapproved vacation opacity
        backgroundColor: isNormalWorkingHoursEventType(item.event_type)
          ? "#142b70"
          : item.event_type === 1
            ? "#113303"
            : "#330303",
      };
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  function getDaysInMonth(month, year) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  function getUnapprovedVacation() {
    return allEvents.current.filter(
      (event) => event.event_type !== 0 && !event.approval,
    );
  }

  if (loading.page) return <LoadingPage />;

  return (
    <div>
      {hasPermission(session.user, "timemanagement_all") ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            style={{ flexDirection: isMobileOnly ? "column" : "row" }}
          >
            <DefaultWorkingHoursModal
              session={session}
              resetParent={loadData}
            />
            {hasPermission(session.user, "approve_vacation") && (
              <VacationApprovalModal
                session={session}
                resetParent={loadData}
                vacations={getUnapprovedVacation()}
                allEvents={allEvents.current}
              />
            )}
            {hasPermission(session.user, "vacation_account") && (
              <VacationAccountModal
                resetParent={loadEmployee}
                years={years}
                employees={employees}
              />
            )}
            <VacationCalendar
              isOpen={openVacationCalendar}
              setIsOpen={setOpenVacationCalendar}
              employees={employees}
            />
          </Box>
          <br />
          <br />
          <FormGroup>
            <Typography className="secondary-textcolor">
              Mitarbeiter:
            </Typography>
            <DropDown
              onChange={setEmployee}
              options={employees.map((user) => ({
                label: user.first_name + " " + user.last_name,
                value: user.id,
              }))}
              value={employee}
              text="Mitarbeiter wählen"
              sort={true}
              search={true}
            />
          </FormGroup>
        </>
      ) : null}
      <Typography className="secondary-textcolor">
        Urlaubstage: {approvedVacationDays + notApprovedVacationDays}{" "}
        {approvedVacationDays + notApprovedVacationDays === 1 ? "Tag" : "Tage"}{" "}
        ({approvedVacationDays} genehmigt, {notApprovedVacationDays} nicht
        genehmigt)
        {employee &&
          ", verbleibende Urlaubstage " +
            selectedYear +
            ": " +
            (entitledHoliday || entitledHoliday === 0
              ? entitledHoliday - approvedVacationDays
              : "Nicht eingestellt")}
      </Typography>
      <br />
      <CustomCalendar
        onNavigate={handleNavigate}
        views={isMobileOnly ? ["month"] : ["day", "month", "work_week"]}
        defaultView="month"
        onView={(view) => {
          if (view === "day") setCurrentView("day");
          else if (view === "month") setCurrentView("month");
          else setCurrentView("work_week");
        }}
        events={timeManagementEvents2calendarEventData(
          timeManagementEvents,
        ).concat(getHolidayEvents())}
        style={{ height: isMobileOnly ? "50vh" : "70vh" }}
        onSelectEvent={(e, clickEvent, clickedDate) => {
          if (currentView === "month") {
            handleSelect({ start: string2Date(clickedDate) });
            return;
          }
          if (e.event.event_type !== 0) {
            const endDate = new Date(`${e.event.end_date} ${e.event.end_time}`);
            const isPastEvent = endDate.getTime() < new Date().getTime();
            const hasChangePermission = hasPermission(
              session.user,
              "timemanagement_change_previous_vacation",
            );
            if (!isPastEvent || hasChangePermission) setChosenEvent(e.event);
          } else setChosenEvent(e.event);
        }}
        onSelectSlot={handleSelect}
        eventPropGetter={(event) => {
          const backgroundColor = event.backgroundColor;
          const opacity = event.opacity;
          return { style: { backgroundColor, opacity } };
        }}
      />
      {currentView === "month" ? (
        chosenDate ? (
          isThereEvents ? (
            <DayOverviewModal
              allEvents={timeManagementEvents}
              isOpen={!!chosenDate}
              setIsOpen={(isOpen) => {
                if (!isOpen) setChosenDate(null);
              }}
              title={chosenDate.toLocaleDateString("de-DE")}
              date={chosenDate}
              events={timeManagementEvents2calendarEventData(
                timeManagementEvents,
              ).concat(getHolidayEvents())}
              session={session}
              employee={employees.find(
                (e) => e.id === (employee == null ? session.user.id : employee),
              )}
              loadData={loadData}
            />
          ) : (
            <TimeManagementModal
              events={timeManagementEvents}
              event={null}
              chosenDate={chosenDate}
              session={session}
              isOpen={!!chosenDate}
              setIsOpen={(isOpen) => {
                if (!isOpen) setChosenDate(null);
              }}
              resetParent={loadData}
              employee={employees.find(
                (e) => e.id === (employee == null ? session.user.id : employee),
              )}
            />
          )
        ) : null
      ) : chosenDate ? (
        <TimeManagementModal
          events={timeManagementEvents}
          event={null}
          chosenDate={chosenDate}
          session={session}
          isOpen={!!chosenDate}
          setIsOpen={(isOpen) => {
            if (!isOpen) setChosenDate(null);
          }}
          resetParent={loadData}
          employee={employees.find(
            (e) => e.id === (employee == null ? session.user.id : employee),
          )}
        />
      ) : null}
      {chosenEvent && chosenEvent.user ? (
        <TimeManagementModal
          events={timeManagementEvents}
          timeManagementEvent={chosenEvent}
          chosenDate={new Date(chosenEvent.start_date)}
          session={session}
          isOpen={!!chosenEvent && !!chosenEvent.user}
          setIsOpen={(isOpen) => {
            if (!isOpen) setChosenEvent(null);
          }}
          resetParent={loadData}
          employee={employees.find((e) => e.id === chosenEvent.user)}
        />
      ) : null}
      <hr className="secondary-textcolor" />
      {hasPermission(session.user, "timemanagement_all") ? (
        <>
          <Typography className="secondary-textcolor">
            Arbeitszeiten als Exceldatei exportieren:
          </Typography>
          <br />
          <DropDown
            onChange={setSelectedMonth}
            options={MONTHS.map((m, idx) => ({ label: m, value: idx }))}
            value={selectedMonth}
            text="Monat wählen"
          />
          &nbsp;&nbsp;
          <DropDown
            onChange={setSelectedYear}
            options={years}
            value={selectedYear}
            text="Jahr wählen"
          />
          &nbsp;&nbsp;
          <ExcelExport
            exporter={exportToExcel}
            fileName={
              "Arbeitszeit_" + MONTHS[selectedMonth] + "_" + selectedYear
            }
            sheetName="Arbeitszeit"
            disabled={
              !selectedYear ||
              selectedMonth === null ||
              !employees.length ||
              loading.export
            }
          />
          <br />
        </>
      ) : null}
    </div>
  );
}

TimeManagementCalendar.propTypes = {
  session: PropTypes.object,
};
