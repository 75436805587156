import React from "react";

import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import { HexColorPicker } from "react-colorful";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import { defaultIfEmpty, hasPermission } from "../../elements/utils";
import TransferList from "../../elements/TransferList";
import { permissionPropType, groupPropType } from "../../elements/PropTypes";

export default function GroupForm({
  group,
  setGroup,
  showMissingFields,
  permissions,
  session,
}) {
  const setGroupState = (update) => {
    setGroup((g) => ({ ...g, ...update }));
  };

  const onChange = (e) => {
    setGroupState({ [e.target.name]: e.target.value });
  };

  const filteredGroupPermissions = group.permissions.filter((ap) =>
    permissions.map((p) => p.id).includes(ap),
  );

  return (
    <Form>
      {group.id ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Gruppen ID:</Typography>
          <Input
            id="input"
            type="text"
            name="group_id"
            value={defaultIfEmpty(group.id)}
            disabled={true}
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
      ) : null}

      <FormGroup>
        <Typography className="secondary-textcolor">Name:</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(group.name)}
          required={true}
          invalid={!!(showMissingFields && !group.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>

      {session.user.is_superuser ? (
        <>
          <FormGroup>
            <Typography className="secondary-textcolor">Schlüssel:</Typography>
            <Input
              id="input"
              type="text"
              name="key"
              onChange={onChange}
              value={defaultIfEmpty(group.key)}
              required={true}
              invalid={!!(showMissingFields && !group.key)}
              disabled={!session.user.is_superuser}
              style={{ boxShadow: "none" }}
            />
          </FormGroup>
          <FormGroup>
            <Typography className="secondary-textcolor">Level:</Typography>
            <Input
              style={{ width: "30%", boxShadow: "none" }}
              id="input"
              width={"30px"}
              type="number"
              name="level"
              onChange={onChange}
              value={defaultIfEmpty(group.level)}
              autoComplete="off"
              invalid={!!(showMissingFields && group.level == null)}
              min={0}
              onKeyDown={(e) => {
                if (e.key === "-") e.preventDefault();
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              checked={group.is_construction_site_group}
              className="secondary-textcolor"
              onChange={(e) => {
                setGroup((u) => ({
                  ...u,
                  is_construction_site_group: e.target.checked,
                }));
              }}
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                />
              }
              label={"In Bauplanungen anzeigen"}
            />
          </FormGroup>
        </>
      ) : null}

      <FormGroup>
        <Typography className="secondary-textcolor">Farbe wählen:</Typography>
        <HexColorPicker
          color={group.color ? group.color : "#ffffff"}
          onChange={(value) => setGroup((g) => ({ ...g, color: value }))}
        />
        <Typography className="secondary-textcolor">
          Ausgewählte Farbe:
        </Typography>
        <div
          style={{
            background: group.color ? group.color : "#ffffff",
            width: 80,
            height: 50,
            color: "white",
          }}
        ></div>
      </FormGroup>

      {hasPermission(session.user, "groups_change_permissions") ? (
        <>
          <br />
          <Typography className="secondary-textcolor">Zugriffe:</Typography>
          <TransferList
            onChange={(values) =>
              setGroup((g) => ({ ...g, permissions: values }))
            }
            values={filteredGroupPermissions}
            options={permissions.map((p) => ({ label: p.name, value: p.id }))}
          />
          <br />
        </>
      ) : null}
    </Form>
  );
}

GroupForm.propTypes = {
  group: groupPropType,
  setGroup: PropTypes.func,
  showMissingFields: PropTypes.bool,
  permissions: PropTypes.arrayOf(permissionPropType),
  session: PropTypes.object,
};
