import React, { useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";
import { Spinner } from "reactstrap";

import UserForm from "./UserForm";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import {
  API_URL_USER,
  API_URL_USERPASSWORDRESET,
  API_URL_EMPLOYEE_LABEL,
} from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import {
  removeFromObj,
  isString,
  getEmptyFieldsError,
  hasPermission,
} from "../../elements/utils";
import {
  groupPropType,
  permissionPropType,
  userPropType,
} from "../../elements/PropTypes";

const emptyUserForm = {
  id: null,
  group: null,
  first_name: null,
  last_name: null,
  phone: null,
  email: null,
  picture: null,
  color: null,
  is_active: true,
  construction_managers: [],
  user_permissions: [],
  visible: true,
  student: false,
  groups: [],
  is_staff: true,
  gender: null,
  employee_label: [],
};

export default function UserFormModal({
  user,
  users,
  getOpenButton,
  isOpen,
  setIsOpen = null,
  resetParent,
  permissions,
  groups,
  session,
}) {
  const [userForm, setUserForm] = useState({ ...emptyUserForm });
  const [userFormErrors, setUserFormErrors] = useState({
    email: null,
    phone1: null,
    phone2: null,
    phone3: null,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [passwordInProgress, setPasswordInProgress] = useState(false);
  const [employeeLabels, setEmployeeLabels] = useState([]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    if (user) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent", float: "right" }}
          size="small"
          onClick={toggle}
        >
          <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
        </IconButton>
      );
    }
    return (
      <CustomButton onClick={toggle}> Neuen Nutzer erstellen </CustomButton>
    );
  };

  const submit = async (userForm) => {
    let picture = null;
    if (userForm.picture) {
      if (!isString(userForm.picture)) {
        picture = userForm.picture;
      }
      userForm = removeFromObj(userForm, ["picture"]);
    }
    userForm.groups = [userForm.group];
    userForm.employee_label = userForm.employee_label.map((label, index) => ({
      label: label.value,
      order: index + 1,
    }));
    const promise = user
      ? axios.put(API_URL_USER + user.id, userForm)
      : axios.post(API_URL_USER, userForm);
    return promise.then((res) => {
      if (picture) {
        const userId = res.data.id;
        const formData = new FormData();
        formData.append("picture", picture);
        return axios.put(API_URL_USER + userId, formData).then(() => res);
      } else return res;
    });
  };

  const onSubmit = async (onSuccess) => {
    const optionalKeys = [
      "phone",
      "user_permissions",
      "construction_managers",
      "groups",
    ];
    const userFormToSubmit = hasPermission(
      session.user,
      "users_change_permissions",
    )
      ? userForm
      : removeFromObj(userForm, ["user_permissions"]);
    const group = groups.find((g) => g.id === userFormToSubmit.group);
    const groupKey = group ? group.key : null;
    if (
      ![
        "construction_managers",
        "heating_installers",
        "heating_installer_construction_manager",
      ].includes(groupKey)
    ) {
      optionalKeys.push("color");
      optionalKeys.push("picture");
    }
    if (!user) optionalKeys.push("id");
    const emptyFieldsError = getEmptyFieldsError(
      userForm,
      emptyUserForm,
      optionalKeys,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    try {
      const res = await submit(userFormToSubmit);
      await resetParent(res.data);
      onSuccess();
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error(error);
      setLoadingElements({
        ...loadingElements,
        submitError: true,
        inProgress: false,
      });
    }
  };

  const isFormError = () => {
    return (
      (!!userForm.email && !!userFormErrors.email) ||
      (!!userForm.phone && !!userFormErrors.phone)
    );
  };
  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        disabled={
          (user &&
            ((user.is_superuser && !session.user.is_superuser) ||
              session.user.group_level < user.group_level)) ||
          isFormError()
        }
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setUserForm({ ...emptyUserForm });
  };

  const loadData = () => {
    axios.get(API_URL_EMPLOYEE_LABEL).then((res) => {
      setEmployeeLabels(res.data);
    });
    if (user) {
      const employeeLabels = user.employee_label
        .sort((a, b) => a.order - b.order)
        .map((item) => ({
          value: item.label.id,
          label: item.label.name,
        }));
      setUserForm({ ...user, employee_label: employeeLabels });
    } else {
      setUserForm({ ...emptyUserForm });
    }
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={_getOpenButton}
      title={user ? "Nutzerdaten ändern" : "Nutzer erstellen"}
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <UserForm
        user={userForm}
        users={users}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setUser={setUserForm}
        permissions={permissions}
        groups={groups}
        employeeLabels={employeeLabels}
        create={!user}
        errors={userFormErrors}
        setErrors={setUserFormErrors}
      />
      {user ? (
        <CustomButton
          onClick={() => {
            setPasswordInProgress(true);
            axios
              .post(API_URL_USERPASSWORDRESET, { user: user.id })
              .then(() => {
                setPasswordInProgress(false);
                if (setIsOpen) setIsOpen(false);
              });
          }}
          disabled={
            user &&
            ((user.is_superuser && !session.user.is_superuser) ||
              session.user.group_level < user.group_level)
          }
        >
          Neues Passwort senden
        </CustomButton>
      ) : null}
      {passwordInProgress ? <Spinner color="dark" size="sm" /> : null}
    </CustomModal>
  );
}

UserFormModal.propTypes = {
  user: userPropType,
  users: PropTypes.arrayOf(userPropType),
  getOpenButton: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  permissions: PropTypes.arrayOf(permissionPropType),
  groups: PropTypes.arrayOf(groupPropType),
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
