import React, { useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";

import EditProductGroupForm from "./EditProductGroupForm";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_PRODUCTGROUP } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import { productPropType, customerPropType } from "../../elements/PropTypes";
import DropDown from "../../elements/DropDown";

const emptyProductGroupForm = {
  id: null,
  name: null,
  state: null,
};

export default function EditProductGroupModal({
  product,
  customer,
  resetParent,
  session,
  style,
  productGroup,
}) {
  const [productGroupForm, setProductGroupForm] = useState({
    ...emptyProductGroupForm,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);

  const getOpenButton = (toggle) => {
    if (product) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent", float: "right" }}
          size="small"
          onClick={toggle}
        >
          <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
        </IconButton>
      );
    }
    return (
      <CustomButton onClick={toggle} style={style}>
        {" "}
        Produktgruppe Bearbeiten{" "}
      </CustomButton>
    );
  };

  const submit = async (productGroupForm) => {
    return axios.put(
      API_URL_PRODUCTGROUP + productGroupForm.id,
      productGroupForm,
    );
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(productGroupForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setProductGroupForm({ ...emptyProductGroupForm });
    setSelectedProductGroup(null);
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Produktgruppen"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <br />
      <div style={{ position: "relative" }}>
        <DropDown
          onChange={(e) => {
            setSelectedProductGroup(e);
            setProductGroupForm({
              ...productGroup.find((group) => group.id === e),
            });
          }}
          options={
            productGroup
              ? productGroup
                  .filter((group) => group.state === true)
                  .map((group) => ({ value: group.id, label: group.name }))
              : null
          }
          value={selectedProductGroup}
          text="Produktgruppe"
          search={true}
        />
        <EditProductGroupForm
          productGroup={productGroupForm}
          session={session}
          showMissingFields={loadingElements.showMissingFields}
          setProductGroup={setProductGroupForm}
          customer={customer}
          selectedProductGroup={productGroup.filter(
            (productGroup) => productGroup.id === selectedProductGroup,
          )}
        />
      </div>
    </CustomModal>
  );
}

EditProductGroupModal.propTypes = {
  product: productPropType,
  productGroup: PropTypes.array,
  customer: customerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object,
};
