import React from "react";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import CustomPlot from "../../elements/Plot";
import { arrange, round, SHORT_MONTHS } from "../../elements/utils";
import { DAPI_URL_OFFERSTATS } from "../../settings";
import StatsWrapper, { convert, getDatasets } from "./StatsWrapper";

export default function OfferStatistics({ session }) {
  const renderStats = (
    data,
    resolution,
    year,
    projectType,
    salesmanComparison,
    projectComparison,
    nTotal,
    height,
    projectTypesList,
  ) => {
    const _convert = (data, getValue) =>
      convert(
        data,
        getValue,
        salesmanComparison,
        projectComparison,
        nTotal,
        projectTypesList,
      );
    const nCustomers = _convert(data, (x, mIdx) => x.n_customers[mIdx]);
    const nOffers = _convert(data, (x, mIdx) => x.n_offers[mIdx]);
    const nAcceptedCustomers = _convert(
      data,
      (x, mIdx) => x.n_accepted_customers[mIdx],
    );
    const acceptanceRatio = data
      ? !(salesmanComparison || projectComparison)
        ? arrange(0, nTotal).map(
            (i) => (100 * nAcceptedCustomers[i]) / nCustomers[i],
          )
        : _convert(
            data,
            (x, mIdx) =>
              (100 * x.n_accepted_customers[mIdx]) / x.n_customers[mIdx],
          )
      : null;

    let nTotalCustomers = 0;
    let nTotalOffers = 0;
    let nTotalAcceptedCustomers = 0;

    if (data) {
      data.forEach((x) => {
        nTotalCustomers += x.n_customers.reduce((a, b) => a + b, 0);
        nTotalOffers += x.n_offers.reduce((a, b) => a + b, 0);
        nTotalAcceptedCustomers += x.n_accepted_customers.reduce(
          (a, b) => a + b,
          0,
        );
      });
    }

    const totalAcceptanceRatio = nTotalCustomers
      ? (100 * nTotalAcceptedCustomers) / nTotalCustomers
      : 0;
    const ticks =
      resolution === "month"
        ? SHORT_MONTHS
        : arrange(nTotal).map((i) => `W${i}`);

    return (
      <>
        <Typography className="secondary-textcolor">
          Angelegte Kunden in {year}: {nTotalCustomers}
        </Typography>
        <br />
        <CustomPlot
          title={"Angelegte Kunden " + year}
          xlabel={"Monat"}
          ylabel={"Kunden"}
          ticks={ticks}
          datasets={getDatasets(
            nCustomers,
            "Kunden",
            salesmanComparison,
            projectComparison,
          )}
          stacked={!(salesmanComparison || projectComparison)}
          type={"bar"}
          height={height}
        />
        <br />
        <br />
        <hr className="secondary-textcolor" />
        {projectType !== "blankinvoiceproject" && !projectComparison ? (
          <>
            <Typography className="secondary-textcolor">
              Akzeptierte Angebote in {year}: {nTotalOffers}
            </Typography>
            <br />
            <CustomPlot
              title={"Akzeptierte Angebote " + year}
              xlabel={"Monat"}
              ylabel={"Angebote"}
              ticks={ticks}
              datasets={getDatasets(
                nOffers,
                "Angebote",
                salesmanComparison,
                projectComparison,
              )}
              stacked={!(salesmanComparison || projectComparison)}
              type={"bar"}
              height={height}
            />
            <br />
            <br />
            <hr className="secondary-textcolor" />
          </>
        ) : null}
        <Typography className="secondary-textcolor">
          Annahmequote in {year}: {round(totalAcceptanceRatio, 1)} %
        </Typography>
        <br />
        <CustomPlot
          title={"Annahmequote " + year}
          xlabel={"Monat"}
          ylabel={"Annahmequote [%]"}
          ticks={ticks}
          datasets={getDatasets(
            acceptanceRatio,
            "Annahmequote",
            salesmanComparison,
            projectComparison,
          )}
          stacked={!(salesmanComparison || projectComparison)}
          type={"bar"}
          height={height}
        />
        <br />
        <br />
        <hr className="secondary-textcolor" />
      </>
    );
  };

  return (
    <StatsWrapper
      url={DAPI_URL_OFFERSTATS}
      dataKeys={["n_offers", "n_customers", "n_accepted_customers"]}
      renderStats={renderStats}
      session={session}
    />
  );
}

OfferStatistics.propTypes = {
  tabStats: PropTypes.object,
  session: PropTypes.object,
};
