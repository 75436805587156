import React from "react";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import CustomPlot from "../../elements/Plot";
import { arrange, SHORT_MONTHS, COLORS } from "../../elements/utils";
import { DAPI_URL_CONSTRUCTIONSTATS } from "../../settings";
import StatsWrapper, { convert } from "./StatsWrapper";

export default function ConstructionStatistics({ session }) {
  const renderStats = (
    data,
    resolution,
    year,
    projectType,
    salesmanComparison,
    projectComparison,
    nTotal,
    height,
    projectTypesList,
  ) => {
    const _convert = (data, getValue) =>
      convert(
        data,
        getValue,
        salesmanComparison,
        projectComparison,
        nTotal,
        projectTypesList,
      );
    const nPastConstructions = _convert(
      data,
      (x, mIdx) => x.n_past_constructions[mIdx],
    );
    const nFutureConstructions = _convert(
      data,
      (x, mIdx) => x.n_future_constructions[mIdx],
    );

    let nTotalConstructions = 0;
    let nPastConstructionsCount = 0;
    let nFutureConstructionsCount = 0;

    if (data) {
      data.forEach((x) => {
        nTotalConstructions += x.n_constructions.reduce((a, b) => a + b, 0);
        nPastConstructionsCount += x.n_past_constructions.reduce(
          (a, b) => a + b,
          0,
        );
        nFutureConstructionsCount += x.n_future_constructions.reduce(
          (a, b) => a + b,
          0,
        );
      });
    }

    const constructionDataset = [
      { label: "Vergangene Baustellen", data: nPastConstructions },
      { label: "Zukünftige Baustellen", data: nFutureConstructions },
    ];

    const ticks =
      resolution === "month"
        ? SHORT_MONTHS
        : arrange(nTotal).map((i) => `W${i}`);

    return (
      <>
        <Typography className="secondary-textcolor">
          Baustellen in {year}: {nTotalConstructions}
        </Typography>
        <br />
        <Typography className="secondary-textcolor">
          Vergangene Baustellen in {year}: {nPastConstructionsCount}
        </Typography>
        <br />
        <Typography className="secondary-textcolor">
          Zukünftige Baustellen in {year}: {nFutureConstructionsCount}
        </Typography>
        <br />
        <CustomPlot
          title={"Baustellen " + year}
          xlabel={"Monat"}
          ylabel={"Baustellen"}
          ticks={ticks}
          datasets={constructionDataset.map((data, idx) => ({
            label: data.label,
            data: data.data,
            borderColor: COLORS[idx % COLORS.length],
            backgroundColor: COLORS[idx % COLORS.length],
          }))}
          stacked={!(salesmanComparison || projectComparison)}
          type={"bar"}
          height={height}
        />
        <br />
        <br />
        <hr className="secondary-textcolor" />
      </>
    );
  };

  return (
    <StatsWrapper
      url={DAPI_URL_CONSTRUCTIONSTATS}
      dataKeys={[
        "n_constructions",
        "n_past_constructions",
        "n_future_constructions",
      ]}
      renderStats={renderStats}
      session={session}
    />
  );
}

ConstructionStatistics.propTypes = {
  tabStats: PropTypes.object,
  session: PropTypes.object,
};
