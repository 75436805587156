import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Input, Form, FormGroup } from "reactstrap";
import axios from "axios";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import { API_URL_VEHICLEEXTRAATTRIBUTE } from "../../settings";
import ConfirmationModalFooter from "../shared/modal_utils/ConfirmationModalFooter";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import "./styles.css";
import { getErrorMessage, isValidForm } from "../../elements/utils";
import ErrorMessage from "../../elements/ErrorMessage";
import { toast } from "react-toastify";
import DropDown from "../../elements/DropDown";

const emptyExtraAttributesForm = {
  title: "",
  key: "",
  field_type: "text",
  required: false,
};

const mandatoryFields = ["title", "field_type", "required"];

export default function CarExtraAttributesFormModal({
  extraAttributes,
  isOpen,
  setIsOpen,
  getOpenButton,
  resetParent,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [extraAttributesForm, setExtraAttributesForm] = useState({
    ...emptyExtraAttributesForm,
  });

  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false);
  const [deletionLoadingElements, setDeletionLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false });
    setExtraAttributesForm({ ...emptyExtraAttributesForm });
  };

  const loadData = () => {
    setExtraAttributesForm({
      ...(extraAttributes || emptyExtraAttributesForm),
    });
  };

  const submit = async (extraAttributesForm) => {
    extraAttributesForm = { ...extraAttributesForm };
    return extraAttributes
      ? await axios.put(
          API_URL_VEHICLEEXTRAATTRIBUTE + extraAttributes.id,
          extraAttributesForm,
        )
      : await axios.post(API_URL_VEHICLEEXTRAATTRIBUTE, extraAttributesForm);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    if (!isValidForm(mandatoryFields, extraAttributesForm)) {
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
        showMissingFields: true,
      });
      toast.error(<ErrorMessage message={"Bitte alle Felder ausfüllen!"} />);
      return;
    }

    try {
      await submit(extraAttributesForm);
      if (resetParent) resetParent();
      if (onSuccess) onSuccess();
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error(
        'Error in "CarExtraAttributesFormModal:onSubmit"',
        error,
        error.stack,
      );
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      setLoadingElements({
        ...loadingElements,
        submitError: true,
        inProgress: false,
        showMissingFields: true,
      });
    }
  };

  const onDelete = async (onSuccess) => {
    setDeletionLoadingElements({
      ...deletionLoadingElements,
      inProgress: true,
      submitError: false,
    });
    try {
      await axios.delete(API_URL_VEHICLEEXTRAATTRIBUTE + extraAttributes.id);
      if (resetParent) resetParent();
      if (onSuccess) onSuccess();
      setDeletionLoadingElements({
        ...deletionLoadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error(
        'Error in "CarExtraAttributesFormModal:onDelete"',
        error,
        error.stack,
      );
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      setDeletionLoadingElements({
        ...deletionLoadingElements,
        submitError: true,
        inProgress: false,
      });
    }
  };

  const getFooter = (toggle) => {
    return (
      <>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          onCancel={toggle}
          onDelete={
            extraAttributes
              ? () => {
                  setDeletionModalIsOpen(true);
                }
              : null
          }
        />
        <CustomModal
          size="small"
          title={"Feld wirklich löschen?"}
          isOpen={deletionModalIsOpen}
          setIsOpen={(isOpen) => {
            if (!isOpen) setDeletionModalIsOpen(false);
          }}
          getFooter={(toggleDelete) => getDeletionFooter(toggleDelete, toggle)}
          onClose={() => setDeletionModalIsOpen(false)}
        ></CustomModal>
      </>
    );
  };

  const getDeletionFooter = (toggle, toogleAll) => {
    return (
      <ConfirmationModalFooter
        submitError={deletionLoadingElements.submitError}
        inProgress={deletionLoadingElements.inProgress}
        onConfirm={() =>
          onDelete(() => {
            setDeletionModalIsOpen(false);
            setIsOpen(false);
          })
        }
        onCancel={() => setDeletionModalIsOpen(false)}
        btnLabel={"Löschen"}
      />
    );
  };

  const modalTitle = extraAttributes ? extraAttributes.title : "Neues Feld";

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      title={modalTitle}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <Form>
        <FormGroup>
          <Typography className="secondary-textcolor">Titel:</Typography>
          <div style={{ display: "flex", gap: "0.5vw" }}>
            <Input
              id="input"
              placeholder="Title"
              type="text"
              width={"15px"}
              value={extraAttributesForm.title}
              onChange={(e) => {
                setExtraAttributesForm((x) => ({
                  ...x,
                  title: e.target.value,
                  key: e.target.value
                    .replace(/([a-z])([A-Z])/g, "$1_$2")
                    .replace(/\s+/g, "_")
                    .toLowerCase(),
                }));
              }}
              style={{ boxShadow: "none" }}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Typography className="secondary-textcolor">Field type:</Typography>
          <DropDown
            onChange={(value) =>
              setExtraAttributesForm((x) => ({ ...x, field_type: value }))
            }
            options={[
              { label: "Text", value: "text" },
              { label: "Zahl", value: "input_number" },
              { label: "Kontrollkästchen", value: "checkbox" },
              { label: "Datei", value: "file" },
            ]}
            value={extraAttributesForm.field_type}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            checked={extraAttributesForm.required || false}
            className="secondary-textcolor"
            onChange={(e) => {
              setExtraAttributesForm((form) => ({
                ...form,
                required: e.target.checked,
              }));
            }}
            control={
              <Checkbox
                disableFocusRipple
                disableRipple
                style={{ color: "#424242", backgroundColor: "transparent" }}
              />
            }
            label="Feld erforderlich"
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
}

CarExtraAttributesFormModal.propTypes = {
  extraAttributes: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
