import React from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import { Table } from "react-bootstrap";

import { Typography, IconButton } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import { CustomButton } from "../../elements/StyledElements";
import { defaultIfEmpty } from "../../elements/utils";
import { customerPropType, supplierPropType } from "../../elements/PropTypes";

const emptyContactPerson = {
  name: null,
  phone: null,
  email: null,
  note: null,
};

export default function SupplierForm({
  customer,
  supplier,
  setSupplier,
  showMissingFields,
}) {
  const setSupplierState = (update) => {
    setSupplier((prevSupplier) => ({
      ...prevSupplier,
      ...update,
    }));
  };

  const onChange = (e) => {
    setSupplierState({ [e.target.name]: e.target.value });
  };

  const handleChangeContactPerson = (idx, field, value) => {
    const newList = [...supplier.contact_persons];
    newList[idx] = { ...newList[idx], [field]: value };
    setSupplier({ ...supplier, contact_persons: newList });
  };
  const deleteContactPerson = (idx) => {
    setSupplier({
      ...supplier,
      contact_persons: supplier.contact_persons.filter((e, i) => {
        return i !== idx;
      }),
    });
  };
  const addContactPerson = () => {
    setSupplier({
      ...supplier,
      contact_persons: [...supplier.contact_persons, emptyContactPerson],
    });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup>
        <Typography className="secondary-textcolor">Zulieferername</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(supplier.name)}
          required={true}
          invalid={!!(showMissingFields && !supplier.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Telefon</Typography>
        <Input
          id="input"
          type="text"
          name="phone"
          onChange={onChange}
          value={defaultIfEmpty(supplier.phone)}
          required={false}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">
          Bestell-Emailadresse
        </Typography>
        <Input
          id="input"
          type="text"
          name="email"
          onChange={onChange}
          value={defaultIfEmpty(supplier.email)}
          required={false}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Ansprechpartner</Typography>
        <Table className="table-not-hover">
          <thead>
            <tr>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Name</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Telefon</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">E-mail</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Notes</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">
                  {"Löschen"}
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {supplier.contact_persons.map((person, idx) => (
              <tr key={idx}>
                <td className="align-middle">
                  <Input
                    id="input"
                    type="text"
                    onChange={(e) => {
                      handleChangeContactPerson(idx, "name", e.target.value);
                    }}
                    value={defaultIfEmpty(person.name)}
                    required={true}
                    invalid={!!(showMissingFields && !person.name)}
                    style={{ boxShadow: "none" }}
                  />
                </td>
                <td className="align-middle">
                  <Input
                    id="input"
                    type="text"
                    onChange={(e) => {
                      handleChangeContactPerson(idx, "phone", e.target.value);
                    }}
                    value={defaultIfEmpty(person.phone)}
                    required={false}
                    style={{ boxShadow: "none" }}
                  />
                </td>
                <td className="align-middle">
                  <Input
                    id="input"
                    type="text"
                    onChange={(e) => {
                      handleChangeContactPerson(idx, "email", e.target.value);
                    }}
                    value={defaultIfEmpty(person.email)}
                    required={false}
                    style={{ boxShadow: "none" }}
                  />
                </td>
                <td className="align-middle">
                  <Input
                    id="input"
                    type="text"
                    onChange={(e) => {
                      handleChangeContactPerson(idx, "note", e.target.value);
                    }}
                    value={defaultIfEmpty(person.note)}
                    required={false}
                    style={{ boxShadow: "none" }}
                  />
                </td>
                <td className="align-middle">
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    style={{ backgroundColor: "transparent" }}
                    size="small"
                    onClick={() => {
                      deleteContactPerson(idx);
                    }}
                  >
                    <HighlightOffOutlinedIcon color="error" fontSize="large" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomButton
          style={{ alignSelf: "center" }}
          onClick={addContactPerson}
        >
          Ansprechpartner hinzufügen
        </CustomButton>
      </FormGroup>
    </Form>
  );
}

SupplierForm.propTypes = {
  customer: customerPropType,
  supplier: supplierPropType,
  setSupplier: PropTypes.func,
  showMissingFields: PropTypes.bool,
};
