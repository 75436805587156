import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import Select, { components } from "react-select";

const Option = ({ isSelected, label, ...props }) => {
  if (label === "Neues Label Hinzufügen") {
    return (
      <div>
        <components.Option {...props}>
          <label>{label}</label>
        </components.Option>
      </div>
    );
  }
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={isSelected} onChange={() => null} />{" "}
        <label>{label}</label>
      </components.Option>
    </div>
  );
};

Option.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  data: PropTypes.object,
  selectOption: PropTypes.func,
};

export default function MultiSelect({
  onChange,
  options,
  text,
  values,
  sort = false,
  disabled = false,
  valueSort = true,
}) {
  const [selected, setSelected] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const newOptions = options
      ? options.map((s) => {
          return Object.prototype.toString.call(s) === "[object Object]"
            ? s
            : {
                value: s,
                label:
                  toString.call(s) === "[object String]" ? s : s.toString(),
              };
        })
      : [];
    if (sort) {
      newOptions.sort((a, b) => {
        if (a.order === "first" && b.order !== "first") return -1;
        if (a.order !== "first" && b.order === "first") return 1;
        if (a.order === "last" && b.order !== "last") return 1;
        if (a.order !== "last" && b.order === "last") return -1;
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }
    setDropdownOptions(newOptions);
  }, [options]);

  useEffect(() => {
    if (!valueSort) setSelected(values);
    else setSelected(dropdownOptions.filter((i) => values.includes(i.value)));
  }, [values, dropdownOptions]);

  const dropdownclicked = (chosen) => {
    selected
      .filter((o) => o.disabled)
      .forEach((o) => {
        if (!chosen.some((x) => x.value === o.value)) chosen.push(o);
      });
    if (!valueSort) onChange(chosen);
    else onChange(chosen.map((i) => i.value));
  };

  const filteredOptions = dropdownOptions.filter(
    (o) => !o.hidden || values.includes(o.value),
  );

  return (
    <span
      className="d-inline-block"
      data-toggle="popover"
      data-trigger="focus"
      data-content="Select log file(s)"
    >
      <Select
        isSearchable={true}
        options={filteredOptions}
        disabled={disabled}
        isOptionDisabled={(option) => option.disabled}
        isClearable={selected && selected.some((v) => !v.disabled)}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option }}
        onChange={dropdownclicked}
        allowSelectAll={true}
        value={selected}
        placeholder={text}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#424242" : "#424242",
            backgroundColor: "#ffffff",
            color: "#424242",
            minWidth: 250,
          }),
          option: (base, state) =>
            state.data.color
              ? { ...base, backgroundColor: state.data.color }
              : base,
          multiValue: (base, state) =>
            state.data.color
              ? { ...base, backgroundColor: state.data.color }
              : base,
          multiValueRemove: (base, state) => {
            base = state.data.disabled ? { ...base, display: "none" } : base;
            return state.data.color
              ? { ...base, backgroundColor: state.data.color }
              : base;
          },
          menu: (base) => ({ ...base, zIndex: 9999 }),
        }}
        // controlShouldRenderValue={false}
      />
    </span>
  );
}

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ),
  text: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  ),
  sort: PropTypes.bool,
  disabled: PropTypes.bool,
  valueSort: PropTypes.bool,
};
