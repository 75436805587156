import React, { useState } from "react";
import { PropTypes } from "prop-types";

import CustomModal from "../shared/modal_utils/CustomModal";

import { API_URL_VEHICLEEXTRAATTRIBUTE } from "../../settings";
import axios from "axios";
import { CustomButton } from "../../elements/StyledElements";
import CollapsibleTable from "../../elements/CollapsibleTable";
import CarExtraAttributesFormModal from "./CarExtraAttributesFormModal";
import Icon from "../../elements/Icon";
import { Typography } from "@mui/material";

export default function CarExtraAttributesModal({ resetParent, session }) {
  const [extraAttributes, setExtraAttributes] = useState([]);
  const [chosenExtraAttribute, setChosenExtraAttribute] = useState(null);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
  };

  const loadData = () => {
    resetExtraAttributes();
  };

  const resetExtraAttributes = async () => {
    try {
      const response = await axios.get(API_URL_VEHICLEEXTRAATTRIBUTE);
      setExtraAttributes(response.data);
      resetParent();
    } catch (error) {
      console.error(
        'Error in "CarExtraAttributesModal:resetExtraAttributes"',
        error,
        error.stack,
      );
    }
  };

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Feldeinstellungen</CustomButton>;
  };

  return (
    <CustomModal
      title="Feldeinstellungen"
      getOpenButton={getOpenButton}
      onToggle={onToggle}
    >
      <Typography color={"red"} fontWeight={700} my={1} textAlign="center">
        Änderungen betreffen die Felder aller Fahrzeuge.
      </Typography>
      {extraAttributes.length === 0 && (
        <CollapsibleTable
          collapsible={false}
          columns={[{ name: "", key: "col" }]}
          rows={[
            {
              col: "Keine zusätzlichen Attribute hinzugefügt",
              style: { textAlign: "center" },
            },
          ]}
        />
      )}
      {extraAttributes.length > 0 && (
        <CollapsibleTable
          columns={[
            { name: "Titel", key: "title" },
            { name: "Type", key: "field_type" },
            { name: "Required", key: "required" },
          ]}
          rows={extraAttributes.map((attribute) => ({
            title: attribute.title,
            field_type: attribute.field_type,
            required: attribute.required ? (
              <Icon icon="check" />
            ) : (
              <Icon icon="clear" />
            ),
            link: () => setChosenExtraAttribute(attribute),
          }))}
          collapsible={false}
        />
      )}
      <br />
      <CarExtraAttributesFormModal
        getOpenButton={(toggle) => (
          <CustomButton onClick={toggle} style={{ float: "right" }} icon="add">
            Feld hinzufügen
          </CustomButton>
        )}
        resetParent={resetExtraAttributes}
        session={session}
      />
      <CarExtraAttributesFormModal
        extraAttributes={chosenExtraAttribute}
        isOpen={!!chosenExtraAttribute}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenExtraAttribute(null);
        }}
        resetParent={resetExtraAttributes}
        session={session}
      />
    </CustomModal>
  );
}

CarExtraAttributesModal.propTypes = {
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
