import React, { useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import CollapsibleTable from "../../elements/CollapsibleTable";
import { string2FormattedString, userWithLabel } from "../../elements/utils";
import { API_URL_TASK } from "../../settings";
import { CustomButton } from "./../../elements/StyledElements";
import TaskFormModal from "./../tasks/TaskFormModal";
import { getNextDate, getTaskEmployeeNames } from "../tasks/utils";
import { Typography } from "@mui/material";
import { customerPropType, projectPropType } from "../../elements/PropTypes";
import NewTaskModal from "./NewTaskModal";

export default function TaskTable({
  customer,
  project,
  session,
  fromConstructionPlanning = false,
}) {
  const [tasks, setTasks] = useState(null);
  const [chosenTask, setChosenTask] = useState(null);
  const [newTaskModalOpen, setNewTaskModalOpen] = useState(false);

  useEffect(() => {
    resetState();
  }, [customer, project]);

  const resetState = () => {
    loadTasks();
  };

  const loadTasks = () => {
    axios
      .get(API_URL_TASK, {
        params: {
          project__customer: customer?.id || null,
          project: project?.id || null,
        },
      })
      .then((res) => {
        const _tasks = res.data;
        _tasks.sort(
          (a, b) =>
            a.finished - b.finished ||
            (b.date && a.date && b.date.localeCompare(a.date)),
        );
        setTasks(_tasks);
      });
  };

  if (!tasks) return null;

  return (
    <>
      <Typography className="secondary-textcolor" style={{ fontSize: 22 }}>
        Aufgaben
      </Typography>
      <CollapsibleTable
        columns={[
          { name: "Titel", key: "title" },
          { name: "Datum", key: "date" },
          { name: "Mitarbeiter", key: "employees" },
          { name: "Erstellt von", key: "registered_by" },
          { name: "Erledigt", key: "done" },
        ]}
        rows={tasks.map((task) => ({
          key: `task-${task.id}`,
          title: task.title,
          date:
            task.taskdates_set && task.taskdates_set.length > 0
              ? string2FormattedString(getNextDate(task.taskdates_set))
              : "",
          employees: getTaskEmployeeNames(task),
          registered_by: userWithLabel(task.registered_by_labels),
          link: fromConstructionPlanning
            ? `/aufgabe/${task.id}`
            : () => setChosenTask(task),
          done: task.finished ? "\u2713" : "\u2715",
        }))}
        counter={false}
        collapsible={false}
      />
      {!fromConstructionPlanning && <br />}
      <>
        {!fromConstructionPlanning && (
          <CustomButton
            onClick={() => setNewTaskModalOpen(!newTaskModalOpen)}
            style={{ width: "300px" }}
            icon="add"
            className="float-end"
            id="add-project-btn"
          >
            Aufgabe hinzufügen
          </CustomButton>
        )}
        {project ? (
          <TaskFormModal
            customer={customer}
            project={project}
            resetParent={resetState}
            session={session}
            isOpen={newTaskModalOpen}
            setIsOpen={setNewTaskModalOpen}
          />
        ) : (
          <NewTaskModal
            customer={customer}
            resetParent={resetState}
            session={session}
            isOpen={newTaskModalOpen}
            setIsOpen={setNewTaskModalOpen}
          />
        )}
      </>
      <TaskFormModal
        task={chosenTask}
        isOpen={!!chosenTask}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenTask(null);
        }}
        resetParent={resetState}
        session={session}
      />
    </>
  );
}

TaskTable.propTypes = {
  customer: customerPropType,
  project: projectPropType,
  session: PropTypes.object,
  fromConstructionPlanning: PropTypes.bool,
};
