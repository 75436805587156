import React from "react";
import { PropTypes } from "prop-types";
import { customerPropType, projectPropType } from "../../../elements/PropTypes";
import PlantProjectDataInput from "./plant_projects/PlantProjectDataInput";
import HeatpumpProjectDataInput from "./heatpump_project/HeatpumpProjectDataInput";

export default function ProjectDataInput({
  project,
  customer,
  showAllMissingFields,
  setShowAllMissingFields,
  setSuccess,
  resetParent,
  session,
}) {
  if (project.resourcetype === "PlantProject") {
    return (
      <PlantProjectDataInput
        project={project}
        customer={customer}
        showAllMissingFields={showAllMissingFields}
        setShowAllMissingFields={setShowAllMissingFields}
        setSuccess={setSuccess}
        resetParent={resetParent}
        session={session}
      />
    );
  }
  if (project.resourcetype === "HeatpumpProject") {
    return (
      <HeatpumpProjectDataInput
        project={project}
        showAllMissingFields={showAllMissingFields}
        setShowAllMissingFields={setShowAllMissingFields}
        setSuccess={setSuccess}
        resetParent={resetParent}
        session={session}
      />
    );
  }
  return null;
}

ProjectDataInput.propTypes = {
  project: projectPropType,
  customer: customerPropType,
  showAllMissingFields: PropTypes.bool,
  setShowAllMissingFields: PropTypes.func,
  setSuccess: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
