import React, { Fragment } from "react";

import { PropTypes } from "prop-types";

import { hasPermission } from "../elements/utils";
import TabView from "../elements/TabView";
import TaskSettings from "./tasks/TaskSettings";
import TaskLists from "./tasks/TaskLists";
import TaskStatistics from "./tasks/TaskStatistics";

export default function Tasks({ session }) {
  return (
    <Fragment>
      <TabView
        useUrl={true}
        components={[
          {
            title: "Meine Aufgaben",
            getChild: () => (
              <TaskLists employeeId={session.user.id} session={session} />
            ),
          },
          {
            title: "Erstellt",
            getChild: () => (
              <TaskLists registeredById={session.user.id} session={session} />
            ),
          },
          ...(session.user.is_coordinator
            ? [
                {
                  title: "Koordination",
                  getChild: () => (
                    <TaskLists
                      coordinatorId={session.user.id}
                      session={session}
                    />
                  ),
                },
              ]
            : []),
          ...(hasPermission(session.user, "task_settings")
            ? [
                {
                  title: "Einstellungen",
                  getChild: () => <TaskSettings session={session} />,
                },
              ]
            : []),
          ...(hasPermission(session.user, "task_statistics")
            ? [
                {
                  title: "Statistiken",
                  getChild: () => <TaskStatistics session={session} />,
                },
              ]
            : []),
        ]}
      />
    </Fragment>
  );
}

Tasks.propTypes = {
  session: PropTypes.object,
};
