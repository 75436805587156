export const emptyCustomerForm = {
  id: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null,
  birth_date: null,
  iban: null,
  bic: null,
  bank: null,
  account_owner: null,
  tax_number: null,
  gender: null,
};
