import React, { useEffect } from "react";
import Urls from "./Urls";
import "./index.css";
import "./styles.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { VERSION_URL } from "./settings";
import { isMobileOnly } from "react-device-detect";

export default function App() {
  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const res = await axios.get(VERSION_URL);
        return res.data;
      } catch (error) {
        console.error("Error fetching version:", error);
        return null;
      }
    };

    const onRefresh = async () => {
      const latestVersion = (await fetchVersion()).version;
      localStorage.setItem("appVersion", latestVersion);
    };

    const checkVersion = async () => {
      const currentVersion = localStorage.getItem("appVersion");
      const latestVersion = (await fetchVersion()).version;
      if (!latestVersion) {
        return;
      }

      if (
        (latestVersion && latestVersion !== currentVersion) ||
        !currentVersion
      ) {
        toast.info(
          <>
            Eine neue Version der App ist verfügbar.
            <button
              onClick={() => {
                window.location.reload();
              }}
              style={{
                background: "none",
                border: "none",
                color: "blue",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              AKTUALISIEREN
            </button>
          </>,
          {
            position: isMobileOnly ? "bottom-center" : "top-center",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          },
        );
      }
    };

    onRefresh().then(() => {});

    // Set an interval to poll the API every minute (60000 ms)
    const intervalId = setInterval(checkVersion, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Urls />
      <ToastContainer
        position="top-center"
        autoClose={false}
        closeOnClick
        hideProgressBar
        theme="light"
        newestOnTop
      />
    </>
  );
}
