import React from "react";
import { PropTypes } from "prop-types";

import { CustomButton } from "./StyledElements";
import ImageStage from "../components/shared/images/ImageStage";

export default function SignatureImageStage({ image, onClick }) {
  return (
    <div className="sigPadContainer">
      <ImageStage image={image} width={250} />
      <hr className="secondary-textcolor" />
      <CustomButton
        variant="outlined"
        className="secondary-textcolor"
        onClick={onClick}
      >
        Bearbeiten
      </CustomButton>
    </div>
  );
}

SignatureImageStage.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func,
};
