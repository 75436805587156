import React from "react";

import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

import { Typography } from "@mui/material";

import { getCustomerName } from "../../../elements/utils";
import { PROTOCOL } from "../../../settings";
import { projectPropType } from "../../../elements/PropTypes";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";

export default function OfferList({ project, resetParent, session }) {
  const offers = project.baseoffer_set;

  return (
    <Table>
      <thead>
        <tr>
          <th className="align-middle" style={{ width: "25%" }}>
            <Typography className="secondary-textcolor">
              Angebots-Nr.
            </Typography>
          </th>
          <th className="align-middle" style={{ width: "25%" }}>
            <Typography className="secondary-textcolor">Erstellt am</Typography>
          </th>
          <th
            className="align-middle"
            style={{ width: "10%", textAlign: "center" }}
          >
            <Typography className="secondary-textcolor"></Typography>
          </th>
        </tr>
      </thead>

      <tbody>
        {!offers || offers.length <= 0 ? (
          <tr>
            <td colSpan="5" align="center">
              <Typography className="secondary-textcolor">
                {" "}
                Keine Angebote vorhanden{" "}
              </Typography>
            </td>
          </tr>
        ) : (
          offers.map((offer) => (
            <tr key={offer.id}>
              <td className="align-middle">
                <Typography className="secondary-textcolor">
                  {offer.id}
                </Typography>
              </td>
              <td className="align-middle">
                <Typography className="secondary-textcolor">
                  {offer.registration_date +
                    "  " +
                    offer.registration_time.split(".")[0]}
                </Typography>
              </td>
              <td className="align-middle" align="center">
                <PdfViewModal
                  title={"Angebot für " + getCustomerName(project.customer_obj)}
                  filepath={
                    offer.pdf
                      ? offer.pdf.replace("http:", PROTOCOL + ":")
                      : null
                  }
                />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

OfferList.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
