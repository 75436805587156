import React, { useEffect, useMemo, useState } from "react";
import { PropTypes } from "prop-types";

import { getCarFormTemplate } from "./CarForm";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import CustomModal from "../shared/modal_utils/CustomModal";

import {
  API_URL_USER,
  API_URL_VEHICLE,
  API_URL_VEHICLEEXTRAATTRIBUTE,
  API_URL_VEHICLELABEL,
  API_URL_VEHICLETRACKER,
} from "../../settings";
import axios from "axios";
import { carPropType } from "../../elements/PropTypes";
import CustomForm, {
  getOptionalKeysFromTemplate,
} from "../../elements/CustomForm";
import { filterObj, getErrorMessage } from "../../elements/utils";
import { toast } from "react-toastify";
import ErrorMessage from "../../elements/ErrorMessage";

const initialEmptyCarForm = {
  id: null,
  brand_model: null,
  plate: "",
  tracker_id: "",
  color: "#0000ff",
  labels: [],
  responsible: null,
  show_in_assignment: true,
};

export default function CarFormModal({
  car,
  isOpen,
  setIsOpen,
  getOpenButton,
  resetParent,
  session,
}) {
  const [carForm, setCarForm] = useState({ ...initialEmptyCarForm });
  const [emptyCarForm, setEmptyCarForm] = useState({ ...initialEmptyCarForm });
  const [vehicleTrackers, setVehicleTrackers] = useState([]);
  const [vehicleLabels, setVehicleLabels] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [vehicleAttributes, setVehicleAttributes] = useState([]);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  useEffect(() => {
    if (car) {
      const labels = car.labels
        .sort((a, b) => a.order - b.order)
        .map((item) => ({
          value: item.label.id,
          label: item.label.name,
        }));
      setCarForm({ ...car, labels });
    }
  }, [car]);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setCarForm({ ...emptyCarForm });
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = async () => {
    await resetVehicleAttributes();
    await resetVehicleLabels();

    const vehicleTrackerResponse = await axios.get(API_URL_VEHICLETRACKER, {
      params: { not_assigned: true },
    });
    setVehicleTrackers(vehicleTrackerResponse.data);

    const employeesResponse = await axios.get(API_URL_USER, {
      params: { is_staff: true, visible: true, is_active: true },
    });
    setEmployees(employeesResponse.data);
  };

  const resetVehicleLabels = async () => {
    try {
      const response = await axios.get(API_URL_VEHICLELABEL);
      setVehicleLabels(response.data);
    } catch (error) {
      console.error(
        'Error in "CarFormModal:resetVehicleLabels"',
        error,
        error.stack,
      );
    }
  };

  const resetVehicleAttributes = async () => {
    try {
      const response = await axios.get(API_URL_VEHICLEEXTRAATTRIBUTE);
      setVehicleAttributes(response.data);

      const initialCarForm = carForm
        ? { ...carForm }
        : car
          ? { ...car }
          : { ...emptyCarForm };
      const extraAttributes = response.data.reduce((items, attribute) => {
        items[attribute.key] =
          initialCarForm[attribute.key] !== undefined
            ? initialCarForm[attribute.key]
            : attribute.field_type === "checkbox"
              ? false
              : null;
        return items;
      }, {});

      setCarForm({ ...initialCarForm, ...extraAttributes });
      setEmptyCarForm((prev) => ({ ...prev, ...extraAttributes }));
    } catch (error) {
      console.error(
        'Error in "CarFormModal:resetVehicleAttributes"',
        error,
        error.stack,
      );
    }
  };

  const submit = async (carForm) => {
    try {
      const fileData = new FormData();
      const carFormWithoutFiles = { ...carForm };

      carFormWithoutFiles.labels = carFormWithoutFiles.labels.map(
        (label, index) => ({
          label: label.value,
          order: index + 1,
        }),
      );

      vehicleAttributes.forEach((attribute) => {
        if (attribute.field_type === "file") {
          const file = carForm[attribute.key];

          if (file && (file instanceof File || file instanceof Blob)) {
            fileData.append(attribute.key, file, file.name);
            delete carFormWithoutFiles[attribute.key];
          }
        }
      });

      const promise = car
        ? await axios.put(API_URL_VEHICLE + car.id, carFormWithoutFiles)
        : await axios.post(API_URL_VEHICLE, carFormWithoutFiles);
      const { data } = promise;
      const { id } = data;

      if ([...fileData.entries()].length > 0) {
        await axios.put(API_URL_VEHICLE + id, fileData);
      }
    } catch (error) {
      console.error('Error in "CarFormModal:submit"', error, error.stack);
    }
  };

  const onSubmit = async (onSuccess) => {
    let carFormToSubmit = carForm;
    carFormToSubmit = filterObj(carFormToSubmit, Object.keys(emptyCarForm));
    const optionalKeys = getOptionalKeysFromTemplate(template, carFormToSubmit);

    Object.keys(carForm)
      .filter((key) => !Object.keys(emptyCarForm).includes(key))
      .forEach((key) => optionalKeys.push(key));

    if (!car) optionalKeys.push("id");
    const formKeys = Object.keys(carFormToSubmit).filter(
      (x) => !optionalKeys.includes(x),
    );
    const checkIfEmpty = (key, val) => {
      return (
        val === null ||
        val === "" ||
        (Array.isArray(val) && val.length === 0) ||
        val < 0 ||
        val === false
      );
    };
    const emptyFields = formKeys.filter((key) =>
      checkIfEmpty(key, carFormToSubmit[key]),
    );
    if (emptyFields.length !== 0) {
      console.error("Following fields are missing: ", emptyFields);
      toast.error(
        <ErrorMessage message="Bitte alle Informationen eintragen!" />,
      );
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });

    try {
      await submit(carFormToSubmit);
      resetParent();
      onSuccess();
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
      });
    } catch (error) {
      console.error('Error in "CarFormModal:onSubmit"', error, error.stack);
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      setLoadingElements({
        ...loadingElements,
        submitError: true,
        inProgress: false,
      });
    }
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_car_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  const template = useMemo(
    () =>
      getCarFormTemplate(
        carForm,
        vehicleTrackers,
        vehicleLabels,
        setVehicleLabels,
        resetVehicleLabels,
        employees,
        vehicleAttributes,
        resetVehicleAttributes,
        session,
      ),
    [
      carForm,
      vehicleTrackers,
      vehicleLabels,
      setVehicleLabels,
      resetVehicleLabels,
      employees,
      vehicleAttributes,
      resetVehicleAttributes,
      session,
    ],
  );

  return (
    <CustomModal
      title={car && car.plate ? `Auto ${car.plate}` : "Auto registrieren"}
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="xl"
    >
      <CustomForm
        template={template}
        form={carForm}
        setForm={setCarForm}
        defaultForm={emptyCarForm}
        showMissingFields={loadingElements.showMissingFields}
      />
    </CustomModal>
  );
}

CarFormModal.propTypes = {
  car: carPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
