import React, { useState } from "react";

import {
  getCustomerName,
  getUnderlinedOpenButton,
  hasPermission,
} from "../../../elements/utils";
import { PROTOCOL } from "../../../settings";
import InvoiceFormModal from "../../invoices/InvoiceFormModal";
import InvoiceModal from "../../invoices/InvoiceModal";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";

export const getBlankInvoiceProjectModals = (project, resetParent, session) => {
  const finalInvoice = project.final_invoice_obj
    ? project.final_invoice_obj
    : null;
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  // --------------- invoices

  const invoiceModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.baseinvoice_set.length > 0 ? (
      <InvoiceModal
        key="invoice-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Rechnungen öffnen")}
        resetParent={resetParent}
        showAcceptedOffer={false}
        isOpen={invoiceModalOpen}
        setIsOpen={setInvoiceModalOpen}
      />
    ) : null;

  const newInvoiceModal =
    finalInvoice &&
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? null : (
      <InvoiceFormModal
        key="new-invoice-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Neue Rechnung")}
        session={session}
        resetParent={resetParent}
        setInvoiceModalOpen={setInvoiceModalOpen}
      />
    );

  const finalInvoiceModal =
    finalInvoice && hasPermission(session.user, "customer_handling_prices") ? (
      <PdfViewModal
        key="final-invoice-modal"
        title={"Endrechnung für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Endrechnung")}
        filepath={
          finalInvoice.pdf
            ? finalInvoice.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  return {
    parents: [
      {
        key: "main_point_invoice",
        modals: [
          invoiceModal,
          finalInvoice ? finalInvoiceModal : newInvoiceModal,
        ],
      },
    ],
    children: [
      {
        key: "invoice_sent",
        modals: [invoiceModal],
      },
      {
        key: "invoice_paid",
        modals: [invoiceModal],
      },
      ...project.baseinvoice_set.map((invoice) => ({
        key: `partial_invoice_${invoice.id}_paid`,
        modals: [invoiceModal],
      })),
      ...project.baseinvoice_set
        .filter((invoice) => !invoice.final)
        .map((invoice) => ({
          key: `partial_invoice_${invoice.id}_sent`,
          modals: [invoiceModal],
        })),
    ],
  };
};

export const unclickableBlankInvoiceProjectTodoKeys = [
  "invoice_sent",
  "invoice_paid",
  "partial_invoice_sent",
  "cancellation_invoice_sent",
  "cancelled_invoice_sent",
  "cancelled_invoice_paid",
  "cancellaltion_invoice_paid",
  "partial_invoice_paid",
];
