import React from "react";
import { PropTypes } from "prop-types";
import CustomModal from "../../shared/modal_utils/CustomModal";
import { CustomButton } from "../../../elements/StyledElements";
import "moment/locale/de";
import "react-big-calendar/lib/css/react-big-calendar.css";

export default function CancelAppointmentModal({
  isOpenModal,
  setIsOpenModal,
  onArchive,
  onContinue,
  session,
}) {
  const getFooter = (toggle) => {
    return (
      <div>
        <CustomButton color={"#ffffff"} onClick={onArchive}>
          Lead archivieren, Kunde deaktivieren
        </CustomButton>
        &nbsp; &nbsp;
        <CustomButton color={"#ffffff"} onClick={onContinue}>
          Zum nächsten Anwahlversuch
        </CustomButton>
      </div>
    );
  };

  return (
    <CustomModal
      size="lg"
      title="Soll der Lead archiviert und der erstellte Kunde deaktiviert werden oder soll noch ein weiterer Anwahlversuch stattfinden?"
      getFooter={getFooter}
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
    />
  );
}

CancelAppointmentModal.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  onArchive: PropTypes.func,
  onContinue: PropTypes.func,
  session: PropTypes.object,
};
