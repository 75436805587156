import React, { useState } from "react";
import CustomModal from "./modal_utils/CustomModal";
import { PropTypes } from "prop-types";
import { Button, Typography } from "@mui/material";
import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateTimeModal({
  title,
  isOpenModal,
  setIsOpenModal,
  saveText,
  onSave,
}) {
  const [date, setDate] = useState(new Date());

  const getFooter = (toggle) => {
    return (
      <div>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            toggle();
            onSave(date);
          }}
        >
          {saveText}
        </Button>
      </div>
    );
  };

  return (
    <CustomModal
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      title={title}
      getFooter={getFooter}
    >
      <Form>
        <FormGroup>
          <Typography fontSize="h5.fontSize" className="secondary-textcolor">
            Datum
          </Typography>
          <br />
          <DatePicker
            selected={date}
            onChange={(date) => {
              setDate(date);
            }}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            popperProps={{
              strategy: "fixed",
            }}
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
}

DateTimeModal.propTypes = {
  title: PropTypes.string,
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  saveText: PropTypes.string,
  onSave: PropTypes.func,
};
