import React, { Fragment, useRef } from "react";
import { PropTypes } from "prop-types";
import { Input } from "reactstrap";
import Compress from "browser-image-compression";

import ImageStage from "./ImageStage";

export default function UploadImage({
  image,
  setImage,
  maxWidth = 600,
  maxHeight = 450,
  id,
}) {
  const filename = useRef(null);

  const onFileChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const file = e.target.files[0];
    filename.current = file.name.split(".")[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1600,
      // Use webworker for faster compression
      useWebWorker: true,
    };

    Compress(file, options)
      .then((compressedBlob) => {
        compressedBlob.lastModifiedDate = new Date();
        const convertedBlobFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
        setImage(convertedBlobFile);
        // const reader = new FileReader()
        // reader.addEventListener('load', () => { setImage(reader.result) }, false)
        // reader.readAsDataURL(convertedBlobFile)
      })
      .catch((e) => {
        alert("Fehler bei der Bildkompression!");
        console.error(e);
      });
  };

  return (
    <Fragment>
      <Input
        id={id || "input"}
        style={{ width: "400px", boxShadow: "none" }}
        type="file"
        onChange={onFileChange}
      />
      <br />
      {image ? (
        <ImageStage image={image} maxWidth={maxWidth} maxHeight={maxHeight} />
      ) : null}
    </Fragment>
  );
}

UploadImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setImage: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
};
