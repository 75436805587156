import React from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Stack, Tooltip } from "@mui/material";

export default function Label({
  text,
  name,
  labelColor,
  textColor,
  textSize,
  minWidth = "none",
  marginLeft = "none",
}) {
  return (
    <Tooltip title={name} placement="top">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        marginLeft={marginLeft}
      >
        <Typography
          style={{
            color: textColor,
            fontSize: textSize,
            backgroundColor: labelColor,
            minWidth,
            padding: "0.1em 0.4em",
            borderRadius: "0.5em",
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

Label.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.number,
  minWidth: PropTypes.string,
  marginLeft: PropTypes.string,
};
