import React from "react";
import { PropTypes } from "prop-types";

import { Tooltip, styled, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import MuiInput from "@mui/material/Input";

import Icon from "./Icon";

const getButtonIcon = (icon, color, btnDisabled, iconClassName, fontsize) => {
  const buttonfontsize = fontsize || "large";
  const _color =
    !btnDisabled && ["bin", "delete"].includes(icon) ? "error" : color;
  return (
    <Icon
      icon={icon}
      size={"large"}
      fontSize={buttonfontsize}
      color={_color}
      className={iconClassName}
    />
  );
};

export const CustomButton = ({
  disabled,
  onClick,
  icon,
  color,
  className,
  iconClassName,
  style,
  children,
  id,
  variant = "outlined",
  startIcon = true,
  endIcon = false,
}) => {
  const btnDisabled = disabled === undefined ? false : disabled;
  let btnbcolor = btnDisabled ? "#c4c4c4" : "#424242";
  btnbcolor = color || btnbcolor;

  return (
    <Button
      id={id}
      disabled={btnDisabled}
      onClick={onClick}
      className={className}
      variant={variant}
      style={{ color: btnbcolor, borderColor: btnbcolor, ...style }}
      startIcon={
        startIcon
          ? getButtonIcon(icon, color, btnDisabled, iconClassName)
          : null
      }
      endIcon={
        endIcon ? getButtonIcon(icon, color, btnDisabled, iconClassName) : null
      }
    >
      {children || null}
    </Button>
  );
};

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.bool,
  endIcon: PropTypes.bool,
};

export const CustomIconButton = ({
  disabled,
  onClick,
  icon,
  className,
  iconClassName,
  size = "small",
  description,
  color,
  style = {},
}) => {
  const btnDisabled = disabled === undefined ? false : disabled;
  const _color = color || (btnDisabled ? "#c4c4c4" : "#424242");

  return (
    <Tooltip title={description} PopperProps={{ style: { zIndex: 9999 } }}>
      <div>
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ color: _color, backgroundColor: "transparent", ...style }}
          size="small"
          onClick={onClick}
          disabled={btnDisabled}
          className={className}
        >
          {getButtonIcon(icon, color, btnDisabled, iconClassName, size)}
        </IconButton>
      </div>
    </Tooltip>
  );
};

CustomIconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

export const CustomToggle = styled(ToggleButton)({
  color: "#424242", // font color if not choosen
  borderColor: "#424242",
  "&:hover": {
    backgroundColor: "transparent", // hover if not choosen
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#828282",
    color: "#0a1929ff",
  },
});

export const CustomInput = styled(MuiInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input": {
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    boxShadow: "none",
    textAlign: "right",
    padding: "0px 0px 0px 0px",
    border: "none",
    textDecoration: "none",
  },
}));
