import React, { useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";

import WarehouseProductTypeForm from "./WarehouseProductTypeForm";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_WAREHOUSEPRODUCTTYPE } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import {
  warehouseProductPropType,
  customerPropType,
} from "../../elements/PropTypes";

const emptyWarehouseProductTypeForm = {
  name: null,
};

export default function WarehouseProductTypeModal({
  warehouseProduct,
  customer,
  resetParent,
  session,
  style,
  employee,
}) {
  const [warehouseProductTypeForm, setWarehouseProductTypeForm] = useState({
    ...emptyWarehouseProductTypeForm,
    request_as: employee,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const getOpenButton = (toggle) => {
    if (warehouseProduct) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent", float: "right" }}
          size="small"
          onClick={toggle}
        >
          <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
        </IconButton>
      );
    }
    return (
      <CustomButton onClick={toggle} style={style}>
        {" "}
        Neue Kategorie erstellen{" "}
      </CustomButton>
    );
  };

  const submit = async (warehouseProductTypeForm) => {
    return axios.post(API_URL_WAREHOUSEPRODUCTTYPE, warehouseProductTypeForm);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(warehouseProductTypeForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={
          loadingElements.submitError
            ? "Doppelter Gruppenname"
            : loadingElements.submitError
        }
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setWarehouseProductTypeForm({
      ...emptyWarehouseProductTypeForm,
      request_as: employee,
    });
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Materialkategorie"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <WarehouseProductTypeForm
        warehouseProductType={warehouseProductTypeForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setWarehouseProductType={setWarehouseProductTypeForm}
        customer={customer}
      />
    </CustomModal>
  );
}

WarehouseProductTypeModal.propTypes = {
  warehouseProduct: warehouseProductPropType,
  customer: customerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object,
  employee: PropTypes.number,
};
