import React, { useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import CollapsibleTable from "../../elements/CollapsibleTable";
import { CustomButton } from "../../elements/StyledElements";
import { getCustomerName } from "../../elements/utils";
import { API_URL_LEAD_EMAIL_HISTORY } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import { Box } from "@material-ui/core";

export default function LeadEmails({ lead, getOpenButton }) {
  const leadEmailModal = new URLSearchParams(window.location.search).get(
    "leademailmodal",
  );
  const [isOpen, _setIsOpen] = useState(leadEmailModal === "true");

  const setIsOpen = (value) => {
    _setIsOpen(value);
    const newURL = new URL(window.location.href);
    newURL.searchParams.set("leademailmodal", value);
    window.history.pushState({}, "", newURL);
  };

  const [emails, setEmails] = useState([]);

  const loadData = async () => {
    const res = await axios.get(API_URL_LEAD_EMAIL_HISTORY, {
      params: {
        lead: lead.id,
      },
    });
    setEmails(res.data);
  };

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [isOpen]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton onClick={toggle} icon="mail">
        E-Mails
      </CustomButton>
    );
  };

  const columns = [
    {
      name: "Datum",
      key: "date",
    },
  ];

  columns.push(
    ...[
      {
        name: "E-Mail",
        key: "emailKey",
      },
      {
        name: "Absender",
        key: "sender",
      },
      {
        name: "Betreff",
        key: "subject",
      },
    ],
  );

  const formatDate = (date) => {
    if (!date) return "";

    return date.slice(0, 10) + ", " + date.slice(11, 19);
  };

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title={"Emails für " + getCustomerName(lead)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CollapsibleTable
        columns={columns}
        rows={emails.map((email, eidx) => ({
          date: formatDate(email.sent_date),
          key: `${email.key}-${eidx}`,
          emailKey: email.key,
          sender: email.sender_name,
          subject: email.subject,
          child: (
            <Box marginLeft={0} marginTop={0} marginBottom={0}>
              <Typography
                className={"secondary-textcolor"}
                style={{ textAlign: "left" }}
              >
                {email.message}
              </Typography>
            </Box>
          ),
        }))}
      />
    </CustomModal>
  );
}

LeadEmails.propTypes = {
  lead: PropTypes.object,
  getOpenButton: PropTypes.func,
};
