import React, { Fragment } from "react";

import { isMobileOnly } from "react-device-detect";
import { PropTypes } from "prop-types";

import { MuiThemeProvider, createTheme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import MiniDrawer from "./MiniDrawer";

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          // backgroundColor: '#0a1929ff',
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

export default function Layout({
  session,
  pageName,
  children,
  stripPaddingMobile = false,
}) {
  document.title = `PV App - ${pageName}`;
  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MiniDrawer session={session} pageName={pageName} />
        <div
          style={{
            paddingLeft: isMobileOnly
              ? stripPaddingMobile
                ? "0px"
                : "10px"
              : "100px",
            paddingRight: isMobileOnly
              ? stripPaddingMobile
                ? "0px"
                : "10px"
              : "100px",
          }}
        >
          {children}
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
}

Layout.propTypes = {
  session: PropTypes.object,
  pageName: PropTypes.string,
  children: PropTypes.node,
  stripPaddingMobile: PropTypes.bool,
};
