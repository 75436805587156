import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";

import { CustomButton } from "../../elements/StyledElements";
import CircleIcon from "@mui/icons-material/Circle";
import { Typography } from "@mui/material";
import { Table } from "react-bootstrap";
import CustomModal from "../shared/modal_utils/CustomModal";
import { carPropType } from "../../elements/PropTypes";
import CarFormModal from "./CarFormModal";
import {
  carBrandModelWithLabel,
  datetime2FormattedString,
  string2datetime,
} from "../../elements/utils";

export default function ManageFleetModal({ cars, resetParent, session }) {
  const [chosenCar, setChosenCar] = useState(null);

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} style={{ float: "right" }} icon="truck">
        Flotte verwalten
      </CustomButton>
    );
  };

  useEffect(() => {
    if (chosenCar) {
      const updatedCar = cars.find((car) => car.id === chosenCar.id);
      if (updatedCar) setChosenCar(updatedCar);
    }
  }, [cars]);

  return (
    <CustomModal
      size="md"
      getOpenButton={getOpenButton}
      title="Flotte verwalten"
    >
      <Table className={"table-hover"}>
        <thead>
          <tr>
            <th
              className="align-middle"
              style={{ width: "5%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor"></Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "35%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Fahrzeug</Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "20%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">
                Kennzeichen
              </Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "10%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">Km</Typography>
            </th>
            <th
              className="align-middle"
              style={{ width: "20%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">
                Zuletzt Online
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car) => (
            <tr key={`car-${car.id}`}>
              <td
                className="align-middle"
                onClick={(e) => {
                  if (!e.ctrlKey) setChosenCar(car);
                }}
              >
                <Typography className="secondary-textcolor">
                  <CircleIcon sx={{ color: car.color }} />
                </Typography>
              </td>
              <td
                className="align-middle"
                onClick={(e) => {
                  if (!e.ctrlKey) setChosenCar(car);
                }}
              >
                {carBrandModelWithLabel(car)}
              </td>
              <td
                className="align-middle"
                onClick={(e) => {
                  if (!e.ctrlKey) setChosenCar(car);
                }}
              >
                <Typography className="secondary-textcolor">
                  {car.plate || ""}
                </Typography>
              </td>
              <td
                className="align-middle"
                onClick={(e) => {
                  if (!e.ctrlKey) setChosenCar(car);
                }}
              >
                <Typography className="secondary-textcolor">
                  {car.last_state?.km || ""}
                </Typography>
              </td>
              <td
                className="align-middle"
                onClick={(e) => {
                  if (!e.ctrlKey) setChosenCar(car);
                }}
              >
                <Typography className="secondary-textcolor">
                  {car.last_state
                    ? datetime2FormattedString(
                        string2datetime(car.last_state.timestamp),
                      )
                    : ""}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CarFormModal
        getOpenButton={(toggle) => (
          <CustomButton onClick={toggle} icon="add" style={{ float: "right" }}>
            Fahrzeug hinzufügen
          </CustomButton>
        )}
        resetParent={resetParent}
        session={session}
      />
      <CarFormModal
        car={chosenCar}
        isOpen={!!chosenCar}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChosenCar(null);
        }}
        resetParent={resetParent}
        session={session}
      />
    </CustomModal>
  );
}

ManageFleetModal.propTypes = {
  cars: PropTypes.arrayOf(carPropType),
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
