import React, { Fragment, useState } from "react";
import { PropTypes } from "prop-types";
import { Col, Container, Row } from "reactstrap";

import ImageStage from "../../../shared/images/ImageStage";
import { voltavoDataPropType } from "../../../../elements/PropTypes";
import { Slider, Typography } from "@mui/material";
import { SHORT_MONTHS, HOURS } from "../../../../elements/utils";
import Collapsible from "react-collapsible";

export default function VoltavoData({ voltavoData, session }) {
  const [monthIdx, setMonthIdx] = useState(6);
  const [hourIdx, setHourIdx] = useState(12);

  const handleChangeMonth = (event, newValue) => {
    if (typeof newValue === "number") setMonthIdx(newValue);
  };

  const handleChangeHour = (event, newValue) => {
    if (typeof newValue === "number") setHourIdx(newValue);
  };

  const valueLabelFormatMonth = (value) => {
    return SHORT_MONTHS[value];
  };

  const valueLabelFormatHour = (value) => {
    return HOURS[value];
  };

  const monthlyMarks = SHORT_MONTHS.map((month, mIdx) => ({
    value: mIdx,
    label: month,
  }));
  // .filter((month, mIdx) => mIdx % 2 === 0)
  const hourlyMarks = HOURS.map((hour, hIdx) => ({
    value: hIdx,
    label: hour,
  })).filter((mark) => mark.value % 2 === 0);

  return (
    <Fragment>
      <Typography fontSize="h5.fontSize" className="secondary-textcolor">
        Sonneneinstrahlung
      </Typography>
      <Collapsible
        trigger="[Informationen öffnen]"
        triggerWhenOpen="[Informationen schließen]"
      >
        <>
          <p>
            Die folgenden Bilder stellen die jährliche bzw. montaliche
            Sonneneinstrahlung auf Dächern dar. Dabei bildet die Farbe die
            Sonneneinstrahlung in kWh/kWp pro Jahr bzw. pro Monat ab. Sie gibt
            damit an, wie viel kWh pro kWp am entsprechenden Ort im Jahr bzw. im
            ausgewählten Montat erzeugt werden könnten.
          </p>
        </>
      </Collapsible>
      <br />
      <Container>
        <Row>
          <Col>
            <Typography
              fontSize="h6.fontSize"
              align="center"
              className="secondary-textcolor"
            >
              Jährlich
            </Typography>
            <ImageStage image={voltavoData.annual_flux_image} />
            <ImageStage image={voltavoData.annual_flux_legend} maxWidth={450} />
          </Col>
          <Col>
            <Typography
              fontSize="h6.fontSize"
              align="center"
              className="secondary-textcolor"
            >
              Monatlich
            </Typography>
            <ImageStage image={voltavoData.monthly_flux_images[monthIdx]} />
            <ImageStage
              image={voltavoData.monthly_flux_legend}
              maxWidth={450}
            />
          </Col>
          <Col>
            <br />
            <br />
            <Slider
              sx={{ height: 450 }}
              defaultValue={0}
              value={monthIdx}
              min={0}
              step={1}
              max={11}
              aria-label="Monat"
              valueLabelDisplay="auto"
              getAriaValueText={valueLabelFormatMonth}
              valueLabelFormat={valueLabelFormatMonth}
              onChange={handleChangeMonth}
              marks={monthlyMarks}
              orientation="vertical"
            />
          </Col>
        </Row>
      </Container>
      <hr className="secondary-textcolor" />
      <Typography fontSize="h5.fontSize" className="secondary-textcolor">
        Verschattung
      </Typography>
      <Collapsible
        trigger="[Informationen öffnen]"
        triggerWhenOpen="[Informationen schließen]"
      >
        <>
          <p>
            Die folgenden Bilder stellen die jährliche bzw. montaliche
            Verschattung zu den verschiedenen Uhrzeiten dar. Die Verschattung
            gibt dabei die Anzahl an Tagen im Monat bzw. Jahr an, an denen auf
            diese Stelle kein Sonnenlicht fällt, und wird in den Bildern durch
            Verdunklung der entprechenden Bereiche abgebildet.
          </p>
        </>
      </Collapsible>
      <br />
      <Container>
        <Row>
          <Col>
            <Typography
              fontSize="h6.fontSize"
              align="center"
              className="secondary-textcolor"
            >
              Jährlich
            </Typography>
            <ImageStage image={voltavoData.annual_shade_image[hourIdx]} />
          </Col>
          <Col>
            <Typography
              fontSize="h6.fontSize"
              align="center"
              className="secondary-textcolor"
            >
              Monatlich
            </Typography>
            <ImageStage
              image={voltavoData.monthly_shade_images[monthIdx][hourIdx]}
            />
          </Col>
          <Col>
            <br />
            <br />
            <Slider
              sx={{ height: 450 }}
              defaultValue={0}
              value={monthIdx}
              min={0}
              step={1}
              max={11}
              aria-label="Monat"
              valueLabelDisplay="auto"
              getAriaValueText={valueLabelFormatMonth}
              valueLabelFormat={valueLabelFormatMonth}
              onChange={handleChangeMonth}
              marks={monthlyMarks}
              orientation="vertical"
            />
          </Col>
        </Row>
        <Row>
          <Typography
            id="input-slider"
            gutterBottom
            className="secondary-text-color"
          >
            Uhrzeit
          </Typography>
          <Slider
            sx={{ width: 950 }}
            defaultValue={0}
            value={hourIdx}
            min={0}
            step={1}
            max={23}
            aria-label="Stunde"
            valueLabelDisplay="auto"
            getAriaValueText={valueLabelFormatHour}
            valueLabelFormat={valueLabelFormatHour}
            onChange={handleChangeHour}
            marks={hourlyMarks}
          />
        </Row>
      </Container>
      <hr className="secondary-textcolor" />
    </Fragment>
  );
}

VoltavoData.propTypes = {
  voltavoData: voltavoDataPropType,
  session: PropTypes.object,
};
