import React, { Fragment } from "react";

import { PropTypes } from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

import {
  customerPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import FinishDataInput from "../../data_input/FinishDataInput";
import PlantProjectFormModal from "./PlantProjectFormModal";
import PlantProjectEMeterCard from "./PlantProjectEMeterCard";

export default function PlantProjectDataInput({
  project,
  customer,
  showAllMissingFields,
  setShowAllMissingFields,
  setSuccess,
  resetParent,
  session,
}) {
  return (
    <Fragment>
      <Container>
        <div
          style={{
            maxWidth: "450px",
          }}
        >
          <Row>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                Straße Nr.:{" "}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {project.street_and_number_project}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                PLZ Ort:{" "}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {project.zip_and_city_project}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                Vollmacht:{" "}
                {showAllMissingFields &&
                !project.accept_mandate_signature &&
                !project.accept_mandate_document ? (
                  <Typography
                    display="inline"
                    style={{ color: "red", fontWeight: 500 }}
                  >
                    !
                  </Typography>
                ) : null}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {project.accept_mandate_signature ||
                project.accept_mandate_document ? (
                  <>&#10004;</>
                ) : (
                  <>&#x2716;</>
                )}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                Zähler:{" "}
                {showAllMissingFields && project.emeter_set.length === 0 ? (
                  <Typography
                    display="inline"
                    style={{ color: "red", fontWeight: 500 }}
                  >
                    !
                  </Typography>
                ) : null}
              </Typography>
            </Col>
            <Col>
              <Typography className="secondary-textcolor" display="inline">
                {project.emeter_set.length === 0
                  ? "Keine Zähler eingetragen"
                  : null}
              </Typography>
            </Col>
          </Row>
          {project.emeter_set.length > 0
            ? project.emeter_set.map((emeter, idx) => (
                <Row className="my-2" key={idx}>
                  <PlantProjectEMeterCard eMeter={emeter} />
                </Row>
              ))
            : null}
        </div>
        <Row className="py-2">
          <Col>
            <PlantProjectFormModal
              project={project}
              customer={customer}
              disable={project.data_input_done}
              showAllMissingFields={showAllMissingFields}
              resetParent={resetParent}
              session={session}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FinishDataInput
              project={project}
              disable={project.data_input_done}
              setShowAllMissingFields={setShowAllMissingFields}
              session={session}
              resetParent={(data) => {
                setSuccess();
                resetParent(data, false);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

PlantProjectDataInput.propTypes = {
  project: projectPropType,
  customer: customerPropType,
  showAllMissingFields: PropTypes.bool,
  setShowAllMissingFields: PropTypes.func,
  setSuccess: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
