import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { projectPropType } from "../../../../elements/PropTypes";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  getCustomerName,
  usePreviousValue,
  getFirstDate,
  isEarliestDateChanged,
} from "../../../../elements/utils";
import { API_URL_SENDCONSTRUCTIONDATE } from "../../../../settings";
import EmailModal from "../../../shared/modal_utils/EmailModal";
import PlantConstructionFormModal from "./PlantConstructionFormModal";

export default function PlantConstructionModal({
  project,
  chosenDate,
  disable,
  getOpenButton,
  resetParent,
  isOpen,
  setIsOpen,
  session,
}) {
  const [_isOpen, _setIsOpen] = useState(setIsOpen ? isOpen : false);
  const [constructionIsOpen, setConstructionIsOpen] = useState(false);
  const [appointmentEmailIsOpen, setAppointmentEmailIsOpen] = useState(false);

  const prevIsOpen = usePreviousValue(_isOpen);
  const prevAppointmentEmailIsOpen = usePreviousValue(appointmentEmailIsOpen);
  const prevConstructionIsOpen = usePreviousValue(constructionIsOpen);

  const newConstruction = useRef(null);

  const construction = project ? project.construction_obj : null;

  const toggleFullModal = (newIsOpen) => {
    if (setIsOpen) setIsOpen(newIsOpen);
    else _setIsOpen(newIsOpen);
  };

  useEffect(() => {
    if (setIsOpen && _isOpen !== isOpen) _setIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (prevIsOpen && !_isOpen) {
      // close
      if (constructionIsOpen) setConstructionIsOpen(false);
      if (appointmentEmailIsOpen) setAppointmentEmailIsOpen(false);
      if (constructionIsOpen) setConstructionIsOpen(false);

      newConstruction.current = null;
    } else if (!prevIsOpen && _isOpen) {
      // open
      if (!constructionIsOpen) setConstructionIsOpen(true);
    }
  }, [_isOpen]);

  useEffect(() => {
    if (prevConstructionIsOpen === true && !constructionIsOpen && _isOpen) {
      if (
        (!construction &&
          newConstruction.current.constructiondates_set &&
          newConstruction.current.constructiondates_set.length > 0) ||
        (construction &&
          isEarliestDateChanged(
            construction.constructiondates_set,
            newConstruction.current.constructiondates_set,
          ))
      ) {
        setAppointmentEmailIsOpen(true);
      } else {
        close();
      }
    }
  }, [constructionIsOpen]);

  useEffect(() => {
    if (
      prevAppointmentEmailIsOpen === true &&
      !appointmentEmailIsOpen &&
      _isOpen
    ) {
      close();
    }
  }, [appointmentEmailIsOpen]);

  const close = () => {
    resetParent();
    toggleFullModal(false);
  };

  const submitConstructionDateEmail = (subject, message) => {
    return axios.post(API_URL_SENDCONSTRUCTIONDATE, {
      project: project.id,
      mail: { subject, message },
      date: getFirstDate(
        newConstruction.current.constructiondates_set,
        "YYYY-MM-DD",
      ),
    });
  };

  const getConstructionDateEmailMessage = async () => {
    return axios
      .get(API_URL_SENDCONSTRUCTIONDATE, { params: { project: project.id } })
      .then((res) => {
        let msg = res.data;
        const formattedDates = getFirstDate(
          newConstruction.current.constructiondates_set,
          "DD.MM.YYYY",
        );
        msg = msg.replace("__DATE__", formattedDates);
        return { data: msg };
      });
  };

  return (
    <>
      {!setIsOpen ? (
        getOpenButton ? (
          getOpenButton(() => toggleFullModal(true))
        ) : (
          <CustomButton
            disabled={disable}
            onClick={() => toggleFullModal(true)}
            icon="construction"
          >
            Baustelle
          </CustomButton>
        )
      ) : null}
      <PlantConstructionFormModal
        project={project}
        chosenDate={chosenDate}
        disable={disable}
        session={session}
        isOpen={constructionIsOpen}
        setIsOpen={setConstructionIsOpen}
        resetParent={(_newConstruction) => {
          newConstruction.current = _newConstruction;
          if (
            (!construction &&
              newConstruction.current.constructiondates_set &&
              newConstruction.current.constructiondates_set.length > 0) ||
            (construction &&
              isEarliestDateChanged(
                construction.constructiondates_set,
                newConstruction.current.constructiondates_set,
              ))
          ) {
            setAppointmentEmailIsOpen(true);
          } else {
            close();
          }
        }}
        onClose={close}
      />
      <EmailModal
        customer={project ? project.customer_obj : null}
        title={
          project
            ? "Email zum Bautermin von " + getCustomerName(project.customer_obj)
            : ""
        }
        subject={
          project
            ? "Bautermin für Ihre PV-Anlage " +
              project.street_and_number_project
            : ""
        }
        getEmailMessage={getConstructionDateEmailMessage}
        submit={(subject, message) =>
          submitConstructionDateEmail(subject, message).then((res) => {
            setAppointmentEmailIsOpen(false);
            return res;
          })
        }
        skip={() =>
          axios.post(API_URL_SENDCONSTRUCTIONDATE, {
            project: project.id,
            date: getFirstDate(
              newConstruction.current.constructiondates_set,
              "YYYY-MM-DD",
            ),
          })
        }
        getModalOpenButton={() => null}
        isOpen={appointmentEmailIsOpen}
        setIsOpen={setAppointmentEmailIsOpen}
        session={session}
      />
    </>
  );
}

PlantConstructionModal.propTypes = {
  project: projectPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  session: PropTypes.object,
};
