import React from "react";
import { Spinner, Fade } from "reactstrap";

import { CustomButton } from "../../../elements/StyledElements";
import { Stack } from "@mui/material";
import { PropTypes } from "prop-types";

export default function EmailModalFooter({
  inProgress,
  skipInProgress,
  submitError,
  onSave,
  onSkip,
  disabled,
}) {
  return (
    <Stack direction="row" spacing={2}>
      {submitError ? (
        <Fade
          in={submitError}
          tag="h5"
          className="mt-3"
          style={{ color: "red" }}
        >
          Anfrage konnte nicht an Server übermittelt werden!
        </Fade>
      ) : null}
      {!inProgress ? (
        <CustomButton
          onClick={onSkip}
          disabled={skipInProgress}
          color={"#ffffff"}
        >
          Überspringen{skipInProgress ? "..." : ""}
        </CustomButton>
      ) : null}
      &nbsp;
      {!skipInProgress ? (
        <CustomButton
          onClick={onSave}
          disabled={inProgress || disabled}
          color={inProgress || disabled ? "#c4c4c4" : "#ffffff"}
        >
          Senden{inProgress ? "..." : ""}
        </CustomButton>
      ) : null}
      &nbsp;
      {inProgress || skipInProgress ? (
        <Spinner color="light" size="sm" />
      ) : null}
    </Stack>
  );
}

EmailModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  skipInProgress: PropTypes.bool,
  submitError: PropTypes.bool,
  onSave: PropTypes.func,
  onSkip: PropTypes.func,
  disabled: PropTypes.bool,
};
