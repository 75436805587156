import React from "react";
import { Table } from "reactstrap";
import { PropTypes } from "prop-types";
import axios from "axios";

import { Typography } from "@mui/material";

import PlanningCopy from "./PlanningCopy";
import { API_URL_PLANNING, PROTOCOL } from "../../settings";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import { getCustomerName, hasPermission, sortById } from "../../elements/utils";
import { projectPropType } from "../../elements/PropTypes";
import { getPlanningListFields } from "../project_types/projectUtils";
import PdfViewModal from "../shared/modal_utils/PdfViewModal";
import { isMobileOnly } from "react-device-detect";
import PlanningFormModal from "../project_types/PlanningFormModal";

export default function PlanningList({ project, resetParent, session }) {
  const planningFields = getPlanningListFields(project);
  const planningOffer = project.baseoffer_set.map((offer) => offer.planning);

  return (
    <Table>
      <thead>
        <tr>
          <th className="align-middle">
            <Typography className="secondary-textcolor">#</Typography>{" "}
          </th>
          <th className="align-middle">
            <Typography className="secondary-textcolor">Erstellt am</Typography>{" "}
          </th>
          {planningFields.map((f) => (
            <th key={`planning-header-${f.key}`} className="align-middle">
              <Typography className="secondary-textcolor">{f.name}</Typography>{" "}
            </th>
          ))}
          <th style={{ textAlign: "center" }}>
            <Typography className="secondary-textcolor"></Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {!project.planning_set || project.planning_set.length <= 0 ? (
          <tr>
            <td colSpan="4" align="center">
              <Typography className="secondary-textcolor">
                {" "}
                Keine Planung vorhanden{" "}
              </Typography>
            </td>
          </tr>
        ) : (
          sortById(project.planning_set).map((planning) => {
            const existOffer = planningOffer.includes(planning.id);

            return (
              <tr
                key={planning.id}
                style={{
                  backgroundColor: planning.is_finished ? "" : "#f0f0f0",
                }}
              >
                <td className="align-middle">
                  <Typography
                    className={
                      planning.is_finished
                        ? "secondary-textcolor"
                        : "disable-textcolor"
                    }
                  >
                    {planning.id}
                  </Typography>
                </td>
                <td className="align-middle">
                  <Typography
                    className={
                      planning.is_finished
                        ? "secondary-textcolor"
                        : "disable-textcolor"
                    }
                  >
                    {planning.registration_date +
                      "  " +
                      planning.registration_time.split(".")[0]}
                  </Typography>
                </td>
                {planningFields.map((f) => (
                  <td
                    key={`planning-columns-${f.key}`}
                    className="align-middle"
                  >
                    <Typography
                      className={
                        planning.is_finished
                          ? "secondary-textcolor"
                          : "disable-textcolor"
                      }
                    >
                      {planning[f.key]}
                    </Typography>
                  </td>
                ))}
                <td align="center">
                  <PlanningFormModal
                    project={project}
                    planning={planning}
                    resetParent={resetParent}
                    session={session}
                  />
                  {hasPermission(session.user, "customer_handling") ? (
                    <>
                      &nbsp;
                      <PlanningCopy
                        planning={planning}
                        resetParent={resetParent}
                        session={session}
                      />
                      &nbsp;
                      {!existOffer && (
                        <ConfirmationModal
                          title={
                            "Soll Planung " +
                            planning.id +
                            " wirklich entfernt werden?"
                          }
                          confirm={() =>
                            axios
                              .delete(API_URL_PLANNING + planning.id)
                              .catch((error) =>
                                console.error(
                                  "Error deleting planning:",
                                  error,
                                ),
                              )
                          }
                          resetParent={resetParent}
                        />
                      )}
                    </>
                  ) : null}
                  {planning.pdf ? (
                    <>
                      &nbsp;
                      <PdfViewModal
                        key="view-plant-modal"
                        title={
                          isMobileOnly
                            ? "Planung"
                            : "Planung für " +
                              getCustomerName(project.customer_obj)
                        }
                        filepath={
                          planning.pdf
                            ? planning.pdf.replace("http:", PROTOCOL + ":")
                            : null
                        }
                      />
                    </>
                  ) : null}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
}

PlanningList.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
