import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { customerPropType } from "../../../elements/PropTypes";
import { CustomButton } from "../../../elements/StyledElements";
import { filterObj, getEmptyFieldsError } from "../../../elements/utils";
import { CAPI_URL_CUSTOMER } from "../../../settings";
import CustomModal from "../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../shared/modal_utils/SaveModalFooter";
import CustomerForm from "./CustomerForm";
import { emptyCustomerForm } from "./forms";

export default function CustomerFormModal({
  customer,
  showAllMissingFields,
  resetParent,
  session,
}) {
  const [customerForm, setCustomerForm] = useState({ ...emptyCustomerForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setCustomerForm(emptyCustomerForm);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    setCustomerForm({ ...customer });
  };

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Ändern</CustomButton>;
  };

  const submit = async (customerForm) => {
    return axios.put(
      CAPI_URL_CUSTOMER,
      filterObj(customerForm, Object.keys(emptyCustomerForm)),
    );
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const additionalOptionalKeys = [
      "account_owner",
      "bank",
      "bic",
      "tax_number",
      "iban",
    ];
    if (customerForm.gender === "Firma")
      additionalOptionalKeys.push("first_name");
    if (customerForm.gender === "Firma")
      additionalOptionalKeys.push("last_name");
    if (customerForm.gender !== "Firma") additionalOptionalKeys.push("company");
    const emptyFieldsError = getEmptyFieldsError(
      customerForm,
      emptyCustomerForm,
      additionalOptionalKeys,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      console.error(emptyFieldsError);
      return;
    }
    return submit(customerForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
          showMissingFields: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="customer-view-submit-customer-form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={getOpenButton}
      title="Kundeninformationen"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <CustomerForm
        customer={customerForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        showAllMissingFields={showAllMissingFields}
        setCustomer={setCustomerForm}
      />
    </CustomModal>
  );
}

CustomerFormModal.propTypes = {
  customer: customerPropType,
  showAllMissingFields: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
