import React from "react";

import { PropTypes } from "prop-types";
import { Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { HexColorPicker } from "react-colorful";
import { HashLink as Link } from "react-router-hash-link";

import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import {
  defaultIfEmpty,
  hasPermission,
  isValidEmailAddress,
  isValidPhoneNumber,
} from "../../elements/utils";
import DropDown from "../../elements/DropDown";
import UploadImage from "../shared/images/UploadImage";
import {
  groupPropType,
  permissionPropType,
  userPropType,
} from "../../elements/PropTypes";
import MultiSelect from "../../elements/MultiSelect";
import TransferList from "../../elements/TransferList";
import { CustomButton } from "../../elements/StyledElements";

export default function UserForm({
  user,
  users,
  setUser,
  showMissingFields,
  create,
  permissions,
  groups,
  employeeLabels,
  errors,
  setErrors,
  session,
}) {
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      if (!!value && !isValidEmailAddress(value)) {
        setErrors({ ...errors, email: "Ungültige E-Mail." });
      } else {
        setErrors({ ...errors, email: null });
      }
    } else if (name === "phone") {
      if (!!value && !isValidPhoneNumber(value)) {
        setErrors({ ...errors, [name]: "Ungültige Telefonnummer." });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }
    setUser((u) => ({ ...u, [name]: value }));
  };

  const setUserGroup = (value) => {
    const group = value != null ? groups.find((g) => g.id === value) : null;
    const userPermissions = group ? group.permissions : [];
    const label = employeeLabels.find(
      (l) => l.key === groups.find((g) => g.id === value).key,
    );
    if (label) {
      const newLabel = [{ value: label.id, label: label.name }];
      setUser((u) => ({
        ...u,
        group: value,
        user_permissions: userPermissions,
        employee_label: newLabel,
      }));
    } else {
      setUser((u) => ({
        ...u,
        group: value,
        user_permissions: userPermissions,
      }));
    }
  };

  const setUserColor = (value) => {
    setUser((u) => ({ ...u, color: value }));
  };

  const setUserImage = (image) => {
    setUser((u) => ({ ...u, picture: image }));
  };

  const setEmployeeLabel = (value) => {
    setUser((u) => ({ ...u, employee_label: value }));
  };

  const filteredUserPermissions = user.user_permissions.filter((ap) =>
    permissions.map((p) => p.id).includes(ap),
  );
  const group = groups.find((g) => g.id === user.group);
  const groupKey = group ? group.key : null;

  return (
    <Form>
      {user.id ? (
        <>
          <FormGroup>
            <Typography className="secondary-textcolor">Nutzer ID:</Typography>
            <Input
              id="input"
              type="text"
              name="user_id"
              value={defaultIfEmpty(user.id)}
              disabled={true}
              style={{ boxShadow: "none" }}
            />
          </FormGroup>
          <FormGroup>
            <Typography className="secondary-textcolor">Nutzername:</Typography>
            <Input
              id="input"
              type="text"
              name="user_id"
              value={user.username}
              disabled={true}
              style={{ boxShadow: "none" }}
            />
          </FormGroup>
        </>
      ) : null}

      <FormGroup>
        <Typography className="secondary-textcolor">
          Nutzergruppe wählen:
        </Typography>
        <DropDown
          search={true}
          onChange={setUserGroup}
          options={groups.map((g) => ({
            label: g.name,
            value: g.id,
            disabled: g.level > session.user.group_level,
          }))}
          value={user.group}
          test={"Nutzergruppe"}
        />
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Label:</Typography>
        <MultiSelect
          text={"Label"}
          sort={false}
          onChange={setEmployeeLabel}
          options={employeeLabels.map((label) => ({
            value: label.id,
            label: label.name,
          }))}
          values={user.employee_label}
          search={true}
          valueSort={false}
        />
        <br />
        <br />
        <Link
          to={"/mitarbeiterlabel"}
          style={{ textDecoration: "none", display: "contents" }}
        >
          <CustomButton>Labeleinstellungen</CustomButton>
        </Link>
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Anrede:</Typography>
        <DropDown
          id="gender_dropdown"
          search={true}
          onChange={(value) => setUser((c) => ({ ...c, gender: value }))}
          text={"Anrede"}
          options={["Frau", "Herr"]}
          value={user.gender}
        />
        {showMissingFields && !user.gender ? (
          <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
        ) : null}
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Vorname:</Typography>
        <Input
          id="input"
          type="text"
          name="first_name"
          onChange={onChange}
          value={defaultIfEmpty(user.first_name)}
          required={true}
          invalid={!!(showMissingFields && !user.first_name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Nachname:</Typography>
        <Input
          id="input"
          type="text"
          name="last_name"
          onChange={onChange}
          value={defaultIfEmpty(user.last_name)}
          required={true}
          invalid={!!(showMissingFields && !user.last_name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">Telefon:</Typography>
        <Input
          id="input"
          type="text"
          name="phone"
          onChange={onChange}
          value={defaultIfEmpty(user.phone)}
          required={false}
          invalid={
            !!(
              showMissingFields ||
              (!!user.phone && !isValidPhoneNumber(user.phone))
            )
          }
          style={{ boxShadow: "none" }}
        />
        {errors.phone && <FormFeedback>{errors.phone}</FormFeedback>}
      </FormGroup>

      <FormGroup>
        <Typography className="secondary-textcolor">E-mail-Adresse:</Typography>
        {create ? (
          <Typography className="secondary-textcolor" style={{ fontSize: 12 }}>
            Wichtig: an diese E-Mail-Adresse werden die Zugangsdaten geschickt!
          </Typography>
        ) : null}
        <Input
          id="input"
          type="text"
          name="email"
          onChange={onChange}
          value={defaultIfEmpty(user.email)}
          required={true}
          invalid={
            !!(
              showMissingFields ||
              (!!user.email && !isValidEmailAddress(user.email))
            )
          }
          style={{ boxShadow: "none" }}
        />
        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
      </FormGroup>

      <Typography className="secondary-textcolor">
        {`Nutzerfoto hochladen${["construction_managers", "heating_installers", "heating_installer_construction_manager"].includes(groupKey) ? "" : " (optional)"}:`}
      </Typography>
      <UploadImage
        image={user.picture}
        setImage={setUserImage}
        width={"90%"}
        maxWidth={320}
      />
      {showMissingFields &&
      user.picture == null &&
      [
        "construction_managers",
        "heating_installers",
        "heating_installer_construction_manager",
      ].includes(groupKey) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
      {[
        "construction_managers",
        "heating_installers",
        "heating_installer_construction_manager",
      ].includes(groupKey) ? (
        <>
          <FormGroup>
            <Typography className="secondary-textcolor">
              Farbe wählen:
            </Typography>
            <HexColorPicker
              color={user.color ? user.color : "#ffffff"}
              onChange={setUserColor}
            />
            <Typography className="secondary-textcolor">
              Ausgewählte Farbe:
            </Typography>
            <div
              style={{
                background: user.color ? user.color : "#ffffff",
                width: 80,
                height: 50,
                color: "white",
              }}
            ></div>
          </FormGroup>
        </>
      ) : null}

      {groupKey === "installers" || groupKey === "construction_managers" ? (
        <FormGroup>
          <Typography className="secondary-textcolor">
            Bauleiter für Aufgabenzuordnung wählen:
          </Typography>
          <MultiSelect
            text={"Bauleiter"}
            sort={true}
            options={
              users
                ? users
                    .filter(
                      (u) =>
                        u.id !== user.id &&
                        u.group_key === "construction_managers",
                    )
                    .map((u) => ({ label: u.name, value: u.id }))
                : []
            }
            values={user.construction_managers}
            onChange={(values) =>
              setUser((u) => ({ ...u, construction_managers: values }))
            }
          />
        </FormGroup>
      ) : null}

      {create ? null : (
        <FormGroup>
          <FormControlLabel
            checked={user.is_active}
            className="secondary-textcolor"
            onChange={(e) => {
              setUser((u) => ({ ...u, is_active: e.target.checked }));
            }}
            control={
              <Checkbox
                disableFocusRipple
                disableRipple
                style={{ color: "#424242", backgroundColor: "transparent" }}
              />
            }
            label={"Aktiver Nutzer"}
          />
        </FormGroup>
      )}
      <FormGroup>
        <FormControlLabel
          checked={user.visible}
          className="secondary-textcolor"
          onChange={(e) => {
            setUser((u) => ({ ...u, visible: e.target.checked }));
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label={"Sichtbarer Nutzer"}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={user.student}
          className="secondary-textcolor"
          onChange={(e) => {
            setUser((u) => ({ ...u, student: e.target.checked }));
          }}
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label={"Student Nutzer"}
        />
      </FormGroup>
      {hasPermission(session.user, "users_change_permissions") ? (
        <>
          <br />
          <Typography className="secondary-textcolor">Zugriffe:</Typography>
          <TransferList
            onChange={(values) =>
              setUser((u) => ({ ...u, user_permissions: values }))
            }
            values={filteredUserPermissions}
            options={permissions.map((p) => ({ label: p.name, value: p.id }))}
          />
          <br />
        </>
      ) : null}
    </Form>
  );
}

UserForm.propTypes = {
  user: userPropType,
  users: PropTypes.arrayOf(userPropType),
  setUser: PropTypes.func,
  showMissingFields: PropTypes.bool,
  create: PropTypes.func,
  permissions: PropTypes.arrayOf(permissionPropType),
  groups: PropTypes.arrayOf(groupPropType),
  employeeLabels: PropTypes.array,
  session: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};
