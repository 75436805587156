import React from "react";

import { PropTypes } from "prop-types";
import { Table } from "reactstrap";

import CheckIcon from "@mui/icons-material/Check";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Typography } from "@mui/material";

import { projectPropType } from "../../elements/PropTypes";
import { isInvoiceCompletelyPaid, isInvoicePaid } from "../../elements/utils";
import InvoiceActions from "./InvoiceActions";

export default function InvoiceList({
  project,
  resetParent,
  emptyTable,
  session,
}) {
  const invoices = project.baseinvoice_set;
  if ((!invoices || invoices.length <= 0) && !emptyTable) return null;

  const paidSymbol = (invoice) => {
    if (invoice.custom_products_count === 0) {
      return (
        <Typography
          className={invoice.pdf ? "secondary-textcolor" : "disable-textcolor"}
        >
          Leer
        </Typography>
      );
    } else if (invoice.custom_products_count > 0 && invoice.paid === 0) {
      return <ClearOutlinedIcon color="error" fontSize="large" />;
    } else if (isInvoiceCompletelyPaid(invoice)) {
      return <CheckIcon color="success" fontSize="large" />;
    } else if (isInvoicePaid(invoice)) {
      return <CheckIcon style={{ color: "#d4bc0b" }} fontSize="large" />;
    } else {
      return <ClearOutlinedIcon color="error" fontSize="large" />;
    }
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th className="align-middle">
              <Typography className="secondary-textcolor">
                Rechnungs-Nr.
              </Typography>
            </th>
            <th className="align-middle">
              <Typography className="secondary-textcolor">
                Erstellt am
              </Typography>
            </th>
            <th className="align-middle" style={{ textAlign: "center" }}>
              <Typography className="secondary-textcolor">Bezahlt</Typography>
            </th>
            <th className="align-middle" style={{ textAlign: "center" }}>
              <Typography className="secondary-textcolor">Aktion</Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {!invoices || invoices.length <= 0 ? (
            <tr>
              <td colSpan="4" align="center">
                <Typography className="secondary-textcolor">
                  Keine Rechnungen vorhanden
                </Typography>
              </td>
            </tr>
          ) : (
            invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="align-middle">
                  <Typography
                    className={
                      invoice.pdf ? "secondary-textcolor" : "disable-textcolor"
                    }
                  >
                    {invoice.id}
                  </Typography>
                </td>
                <td className="align-middle">
                  <Typography
                    className={
                      invoice.pdf ? "secondary-textcolor" : "disable-textcolor"
                    }
                  >
                    {invoice.registration_date +
                      "  " +
                      invoice.registration_time.split(".")[0]}
                  </Typography>
                </td>
                <td className="align-middle" align="center">
                  {paidSymbol(invoice)}
                </td>
                <td className="align-middle" align="center">
                  <InvoiceActions
                    project={project}
                    invoice={invoice}
                    resetParent={resetParent}
                    session={session}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

InvoiceList.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  emptyTable: PropTypes.bool,
  session: PropTypes.object,
};
