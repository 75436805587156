import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { CustomButton } from "../../elements/StyledElements";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DropDown from "../../elements/DropDown";
import CustomModal from "../shared/modal_utils/CustomModal";
import { Container } from "reactstrap";
import {
  CircularProgress,
  IconButton,
  Input,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import {
  API_URL_TIMEMANAGEMENTEVENT,
  API_URL_VACATIONACCOUNT,
} from "../../settings";
import _ from "lodash";
import {
  THIS_YEAR,
  getErrorMessage,
  getTotalDays,
  userWithLabel,
} from "../../elements/utils";
import { toast } from "react-toastify";
import ExcelExport from "./ExcelExportTimeEvents";
import ErrorMessage from "../../elements/ErrorMessage";

export default function VacationAccountModal({
  resetParent,
  years,
  employees,
}) {
  const [selectedYear, setSelectedYear] = useState(THIS_YEAR);
  const [editingId, setEditingId] = useState(-1);
  const [vacData, setVacData] = useState([]);
  const [loading, setLoading] = useState({
    submit: false,
    export: false,
  });

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Urlaubskonto</CustomButton>;
  };

  const exportToExcel = () => {
    const excelData = [
      [
        "Mitarbeiter",
        "Krankheitstage",
        "Urlaubstage genommen",
        "Urlaubsanspruch",
        "Urlaubstage verbleibend",
      ],
    ];
    const colors = [[].concat(Array(5).fill("5884c7"))];

    vacData.forEach((vac) => {
      excelData.push([
        vac.employee,
        vac.sickDay,
        vac.approvedVacation,
        vac.holidayEntitlement,
        vac.id !== -1
          ? vac.holidayEntitlement - vac.approvedVacation
          : "Nicht eingestellt",
      ]);
      colors.push(["5884c7"].concat(Array(4).fill("ffffff")));
    });

    return { data: excelData, colors };
  };

  const submit = async () => {
    setLoading({ ...loading, submit: true });

    try {
      const { id, ...data } = vacData[editingId];
      const payload = {
        user: data.employeeId,
        year: selectedYear,
        count: data.holidayEntitlement,
      };

      if (id === -1) {
        await axios.post(API_URL_VACATIONACCOUNT, payload);
      } else {
        await axios.put(API_URL_VACATIONACCOUNT + id, payload);
      }

      setEditingId(-1);
      await resetParent();
    } catch (error) {
      toast.error(<ErrorMessage message={getErrorMessage(error)} />);
      console.error(error);
    }

    setLoading({ ...loading, submit: false });
  };

  useEffect(() => {
    const parseData = async () => {
      if (!selectedYear) {
        setVacData([]);
        return;
      }

      const { data: eventList } = await axios.get(API_URL_TIMEMANAGEMENTEVENT, {
        params: {
          year: selectedYear,
        },
      });

      const eventMap = _.groupBy(eventList, "user");
      const data = employees.map((employee) => {
        const events = eventMap[employee.id] || [];
        const sickDays = events.filter((event) => event.event_type === 2) || [];
        const approvedVacations =
          events.filter((event) => event.approval && event.event_type === 1) ||
          [];
        const holiday = _.keyBy(employee.holiday, "year");

        return {
          id: holiday[selectedYear]?.id || -1,
          employeeId: employee.id,
          employee: employee.name,
          employee_name: employee.name,
          employee_label: employee.employee_label,
          sickDay: sickDays.reduce(
            (acc, curr) =>
              acc + getTotalDays(curr.start_date, curr.end_date, selectedYear),
            0,
          ),
          approvedVacation: approvedVacations.reduce(
            (acc, curr) =>
              acc + getTotalDays(curr.start_date, curr.end_date, selectedYear),
            0,
          ),
          holidayEntitlement: holiday[selectedYear]?.count || 0,
        };
      });

      setVacData(data);
    };

    parseData();
  }, [selectedYear, employees]);

  return (
    <CustomModal size="lg" title="Urlaubskonto" getOpenButton={getOpenButton}>
      <Container style={{ paddingTop: 16 }}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <DropDown
              text="Jahr"
              value={selectedYear}
              onChange={setSelectedYear}
              options={years}
              sort={false}
              search={true}
            />
            <ExcelExport
              exporter={exportToExcel}
              fileName={`Urlaubskonto_${selectedYear}`}
              sheetName="Urlaubskonto"
              disabled={!vacData.length || loading.export}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Mitarbeiter
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Krankheitstage
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Urlaubstage genommen
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Urlaubsanspruch
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Urlaubstage verbleibend
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vacData.length ? (
                  vacData.map((vac, index) => (
                    <TableRow key={index}>
                      <TableCell>{userWithLabel(vac)}</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {vac.sickDay}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {vac.approvedVacation}
                      </TableCell>
                      <TableCell>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {editingId === index ? (
                            <>
                              <Input
                                type="text"
                                pattern="[0-9]*"
                                value={vac.holidayEntitlement}
                                style={{ width: "35px" }}
                                inputProps={{
                                  min: 0,
                                  style: { textAlign: "center" },
                                }}
                                disabled={loading.submit}
                                autoFocus
                                onBlur={submit}
                                onChange={(e) => {
                                  let value = parseInt(e.target.value);
                                  if (value < 0 || isNaN(value)) value = 0;

                                  setVacData((prev) => {
                                    const copy = [...prev];
                                    copy[index].holidayEntitlement = value;
                                    return copy;
                                  });
                                }}
                              />
                              {!loading.submit ? (
                                <Tooltip
                                  title="Speichern"
                                  PopperProps={{ style: { zIndex: 9999 } }}
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    style={{ backgroundColor: "transparent" }}
                                    size="small"
                                    onClick={submit}
                                  >
                                    <CheckOutlinedIcon
                                      className="secondary-textcolor"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <CircularProgress size={16} color="inherit" />
                              )}
                            </>
                          ) : (
                            <>
                              <Typography>{vac.holidayEntitlement}</Typography>
                              <Tooltip
                                title="Bearbeiten"
                                PopperProps={{ style: { zIndex: 9999 } }}
                              >
                                <IconButton
                                  disableFocusRipple
                                  disableRipple
                                  style={{ backgroundColor: "transparent" }}
                                  size="small"
                                  onClick={() => setEditingId(index)}
                                >
                                  <EditOutlinedIcon
                                    className="secondary-textcolor"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {vac.id !== -1
                          ? vac.holidayEntitlement - vac.approvedVacation
                          : "Nicht eingestellt"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                      Wählen Sie zunächst ein Jahr aus
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </CustomModal>
  );
}

VacationAccountModal.propTypes = {
  resetParent: PropTypes.func,
  years: PropTypes.array,
  employees: PropTypes.array,
};
