import React, { Fragment } from "react";

import { PropTypes } from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Typography } from "@mui/material";

import CustomerFormModal from "./CustomerFormModal";
import { customerPropType } from "../../../elements/PropTypes";

export default function CustomerDataInput({
  customer,
  showAllMissingFields,
  resetParent,
  session,
}) {
  return (
    <>
      <Fragment>
        <Container>
          <div
            style={{
              maxWidth: "450px",
            }}
          >
            <Row>
              {customer.gender === "Firma" ? (
                <>
                  <Col>
                    <Typography
                      className="secondary-textcolor"
                      display="inline"
                    >
                      Firma:{" "}
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      className="secondary-textcolor"
                      display="inline"
                    >
                      {customer.company}
                    </Typography>
                  </Col>
                </>
              ) : null}
            </Row>
            <Row>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  Vorname:{" "}
                </Typography>
              </Col>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  {customer.first_name}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  Nachname:{" "}
                </Typography>
              </Col>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  {customer.last_name}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  Straße Nr.:{" "}
                </Typography>
              </Col>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  {customer.street_and_number}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  PLZ Ort:{" "}
                </Typography>
              </Col>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  {customer.zip_and_city}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  Geburtsdatum:{" "}
                </Typography>
              </Col>
              <Col>
                <Typography className="secondary-textcolor" display="inline">
                  {customer.birth_date ? (
                    <>{customer.birth_date.split("-").reverse().join(".")}</>
                  ) : (
                    <>-</>
                  )}
                </Typography>
              </Col>
            </Row>
            <Row xs={1} className="my-3">
              <Col>
                <CustomerFormModal
                  customer={customer}
                  showAllMissingFields={showAllMissingFields}
                  resetParent={resetParent}
                  session={session}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    </>
  );
}

CustomerDataInput.propTypes = {
  customer: customerPropType,
  showAllMissingFields: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
