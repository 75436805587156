import React, { useState } from "react";

import {
  getCustomerName,
  getUnderlinedOpenButton,
  hasPermission,
} from "../../../elements/utils";
import { PROTOCOL } from "../../../settings";
import InvoiceFormModal from "../../invoices/InvoiceFormModal";
import InvoiceModal from "../../invoices/InvoiceModal";
import OfferModal from "../../offers/OfferModal";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";
import NewOfferModal from "../NewOfferModal";

export const getBlankOfferProjectModals = (project, resetParent, session) => {
  const acceptedOffer = project.accepted_offer_obj
    ? project.accepted_offer_obj
    : null;
  const finalInvoice = project.final_invoice_obj
    ? project.final_invoice_obj
    : null;
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  // --------------- offer

  const offerModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.baseoffer_set.length > 0 ? (
      <OfferModal
        key="offer-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Angebote öffnen")}
        resetParent={resetParent}
      />
    ) : null;

  const newOfferModal =
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <NewOfferModal
        key="new-offer-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Neues Angebot")}
        session={session}
        resetParent={resetParent}
      />
    ) : null;

  const acceptedOfferModal =
    hasPermission(session.user, "customer_handling_prices") && acceptedOffer ? (
      <PdfViewModal
        key="accepted-offer-modal"
        title={"Angebot für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Angenommenes Angebot")}
        filepath={
          acceptedOffer.pdf
            ? acceptedOffer.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  // --------------- invoices

  const invoiceModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.accepted_offer_obj &&
    project.baseinvoice_set.length > 0 ? (
      <InvoiceModal
        key="invoice-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Rechnungen öffnen")}
        resetParent={resetParent}
        isOpen={invoiceModalOpen}
        setIsOpen={setInvoiceModalOpen}
      />
    ) : null;

  const newInvoiceModal =
    acceptedOffer &&
    !finalInvoice &&
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <InvoiceFormModal
        key="new-invoice-modal"
        project={project}
        productRequestParams={{
          offer: acceptedOffer ? acceptedOffer.id : null,
        }}
        getOpenButton={getUnderlinedOpenButton("Neue Rechnung")}
        session={session}
        resetParent={resetParent}
        setInvoiceModalOpen={setInvoiceModalOpen}
      />
    ) : null;

  const finalInvoiceModal =
    finalInvoice && hasPermission(session.user, "customer_handling_prices") ? (
      <PdfViewModal
        key="final-invoice-modal"
        title={"Endrechnung für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Endrechnung")}
        filepath={
          finalInvoice.pdf
            ? finalInvoice.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  return {
    parents: [
      {
        key: "main_point_offer",
        modals: [
          offerModal,
          acceptedOffer ? acceptedOfferModal : newOfferModal,
        ],
      },
      {
        key: "main_point_invoice",
        modals: [
          invoiceModal,
          finalInvoice ? finalInvoiceModal : newInvoiceModal,
        ],
      },
    ],
    children: [
      {
        key: "offer_sent",
        modals: [offerModal],
      },
      {
        key: "offer_accepted",
        modals: [offerModal],
      },
      {
        key: "offer_confirmed",
        modals: [offerModal],
      },
      {
        key: "invoice_sent",
        modals: [invoiceModal],
      },
      {
        key: "invoice_paid",
        modals: [invoiceModal],
      },
      ...project.baseinvoice_set.map((invoice) => ({
        key: `partial_invoice_${invoice.id}_paid`,
        modals: [invoiceModal],
      })),
      ...project.baseinvoice_set
        .filter((invoice) => !invoice.final)
        .map((invoice) => ({
          key: `partial_invoice_${invoice.id}_sent`,
          modals: [invoiceModal],
        })),
    ],
  };
};

export const unclickableBlankOfferProjectTodoKeys = [
  "offer_sent",
  "offer_accepted",
  "offer_confirmed",
  "invoice_sent",
  "invoice_paid",
  "partial_invoice_sent",
  "partial_invoice_paid",
  "cancellation_invoice_sent",
  "cancelled_invoice_sent",
  "cancelled_invoice_paid",
  "cancellaltion_invoice_paid",
];
