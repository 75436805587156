import React, { useState } from "react";

import { PropTypes } from "prop-types";
import { Container } from "reactstrap";

import axios from "axios";
import { CustomButton } from "../../elements/StyledElements";
import { API_URL_NEWSLETTEREMAILDESIGN } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";

import CollapsibleTable from "../../elements/CollapsibleTable";

export default function LoadDesignModal({
  disable,
  getOpenButton,
  chooseDesign,
  resetParent,
  session,
}) {
  const [newsletterEmailDesigns, setNewsletterEmailDesigns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton disabled={disable} onClick={toggle} icon="load">
        Vorlage Laden
      </CustomButton>
    );
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const loadData = () => {
    loadEmailDesign();
  };

  const loadEmailDesign = () =>
    axios
      .get(API_URL_NEWSLETTEREMAILDESIGN)
      .then((res) => setNewsletterEmailDesigns(res.data));

  const clearData = () => {
    setNewsletterEmailDesigns([]);
  };

  const resetState = () => {
    loadEmailDesign();
  };

  return (
    <CustomModal
      getOpenButton={_getOpenButton}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Newsletter Vorlage laden"
    >
      <Container>
        <CollapsibleTable
          columns={[
            {
              name: "Name",
              key: "name",
            },
            {
              name: "",
              key: "actions",
              clickable: false,
            },
          ]}
          rows={newsletterEmailDesigns.map((design) => ({
            key: `${design.id}`,
            name: design.name,
            link: () => {
              setIsOpen(false);
              chooseDesign(design.design);
            },
            actions: (
              <ConfirmationModal
                title={
                  "Soll die Vorlage " +
                  design.name +
                  " wirklich entfernt werden?"
                }
                confirm={() =>
                  axios.delete(API_URL_NEWSLETTEREMAILDESIGN + design.id)
                }
                resetParent={resetState}
              />
            ),
          }))}
          counter={true}
          collapsible={false}
        />
      </Container>
    </CustomModal>
  );
}

LoadDesignModal.propTypes = {
  emailEditorRef: PropTypes.object,
  chooseDesign: PropTypes.func,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
