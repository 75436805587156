import React, { useState, useEffect } from "react";
import DropDown from "../../../../elements/DropDown";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { PropTypes } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { Col, Row, Input, FormGroup } from "reactstrap";
import { eMeterPropType } from "../../../../elements/PropTypes";
import {
  CustomButton,
  CustomToggle,
} from "../../../../elements/StyledElements";
import { defaultIfEmpty, eMeterActions } from "../../../../elements/utils";
import CustomModal from "../../../shared/modal_utils/CustomModal";

export default function EMeterFormEntry({
  eMeter,
  idx,
  setProject,
  changeEMeter,
  showMissingFields,
}) {
  const eidx = idx;
  const [haveBidirectional, setHaveBidirectional] = useState(null);

  const handleHaveBidirectional = (e, val) => {
    eMeter.action = val ? null : 2;
    setHaveBidirectional(val);
    changeEMeter(eMeter.id, "bidirectional", val);
  };

  const getOpenButtonHint = (toggle) => {
    return <CustomButton onClick={toggle}>Hinweis</CustomButton>;
  };

  useEffect(() => {
    if (eMeter.bidirectional == null) return;
    if (eMeter.bidirectional) setHaveBidirectional(true);
    else setHaveBidirectional(false);
  }, [eMeter]);

  return (
    <div key={`emeter-${eidx}`} style={{ padding: "0 0 0 25px" }}>
      <hr className="secondary-textcolor" />
      <FormGroup>
        <Typography className="secondary-textcolor">
          Haben Sie einen Zweirichtungszähler?
        </Typography>
        <ToggleButtonGroup
          size="small"
          value={eMeter.bidirectional}
          exclusive
          onChange={handleHaveBidirectional}
        >
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
          {showMissingFields && haveBidirectional == null ? (
            <div>
              &nbsp;&nbsp;
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            </div>
          ) : null}
        </ToggleButtonGroup>
      </FormGroup>
      {haveBidirectional == null ? null : (
        <Row key={`emeter-settings-${eidx}`}>
          <Row>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Zählernummer
                </Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name={"emeter_id_" + eidx}
                  value={defaultIfEmpty(eMeter.e_meter_id)}
                  autoComplete="off"
                  onChange={(e) =>
                    changeEMeter(eMeter.id, "e_meter_id", e.target.value)
                  }
                  invalid={!!(showMissingFields && !eMeter.e_meter_id)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography>&nbsp;</Typography>
                <CustomModal
                  size="lg"
                  getOpenButton={getOpenButtonHint}
                  title="Hinweis Zähler und Zählernummer"
                >
                  <Typography className="secondary-textcolor my-3">
                    {
                      "Die Zählernummer befindet sich auf Ihrem aktuellen Stromzähler. Meist befindet er sich in der Nähe des Strichcodes und wird entweder mit “Nr.” oder “Eigentum..” eingeleitet. Bei neueren Zählern beginnt die Nummer in der Regel mit “1Z…” oder “1E…”. Sollten Sie sich weiterhin unsicher sein, finden Sie die Zählernummer auch auf Ihrem Stromvertrag."
                    }
                  </Typography>
                  <Grid container justifyContent="center" alignItems="center">
                    <img
                      src="/emeter_examples.png"
                      alt="emeter_examples"
                      width="706"
                      height="268"
                    />
                  </Grid>
                </CustomModal>
              </FormGroup>
            </Col>
          </Row>
          {haveBidirectional ? (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Zählerstand 1.8.0
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="number"
                      name={"emeter_value180_" + eidx}
                      value={defaultIfEmpty(eMeter.value_180)}
                      min="0"
                      onKeyPress={(e) => {
                        if (e.code === "Minus") {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      onChange={(e) =>
                        changeEMeter(eMeter.id, "value_180", e.target.value)
                      }
                      invalid={!!(showMissingFields && !eMeter.value_180)}
                      onKeyDown={(e) => {
                        if (e.key === "-") e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography>&nbsp;</Typography>
                    <CustomModal
                      size="lg"
                      getOpenButton={getOpenButtonHint}
                      title="Hinweis Zählerwechsel"
                    >
                      <Typography className="secondary-textcolor my-1">
                        {
                          "Sollte in Ihrem Haushalt noch ein klassischer Ferraris-Zähler installiert sein oder ein Zähler, der den Stromfluss nicht in beide Richtungen erfassen kann, muss dieser zwingend gegen einen Zweirichtungszähler ausgetauscht werden. Digitale Zähler, die sowohl den Bezug von Strom (Kennzeichnung 180) als auch die Einspeisung ins Netz (Kennzeichnung 280) messen können, wechseln sichtbar zwischen diesen beiden Modi. Für den Fall, dass ein Zählerwechsel notwendig ist, übernehmen wir die Beantragung beim zuständigen Netzbetreiber für Sie."
                        }
                      </Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          src="/emeter_change.png"
                          alt="emeter_examples"
                          width="536"
                          height="298"
                        />
                      </Grid>
                    </CustomModal>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Zählerstand 2.8.0
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="number"
                      name={"emeter_value280_" + eidx}
                      value={defaultIfEmpty(eMeter.value_280)}
                      min="0"
                      onKeyPress={(e) => {
                        if (e.code === "Minus") {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      onChange={(e) =>
                        changeEMeter(eMeter.id, "value_280", e.target.value)
                      }
                      invalid={!!(showMissingFields && !eMeter.value_280)}
                      onKeyDown={(e) => {
                        if (e.key === "-") e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography>&nbsp;</Typography>
                    <CustomModal
                      size="lg"
                      getOpenButton={getOpenButtonHint}
                      title="Hinweis Zählerwechsel"
                    >
                      <Typography className="secondary-textcolor my-1">
                        {
                          "Sollte in Ihrem Haushalt noch ein klassischer Ferraris-Zähler installiert sein oder ein Zähler, der den Stromfluss nicht in beide Richtungen erfassen kann, muss dieser zwingend gegen einen Zweirichtungszähler ausgetauscht werden. Digitale Zähler, die sowohl den Bezug von Strom (Kennzeichnung 180) als auch die Einspeisung ins Netz (Kennzeichnung 280) messen können, wechseln sichtbar zwischen diesen beiden Modi. Für den Fall, dass ein Zählerwechsel notwendig ist, übernehmen wir die Beantragung beim zuständigen Netzbetreiber für Sie."
                        }
                      </Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          src="/emeter_change.png"
                          alt="emeter_examples"
                          width="536"
                          height="298"
                        />
                      </Grid>
                    </CustomModal>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Zählerstand
                  </Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: "none" }}
                    type="number"
                    name={"emeter_value_" + eidx}
                    value={defaultIfEmpty(eMeter.value)}
                    min="0"
                    onKeyPress={(e) => {
                      if (e.code === "Minus") {
                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    onChange={(e) =>
                      changeEMeter(eMeter.id, "value", e.target.value)
                    }
                    invalid={!!(showMissingFields && !eMeter.value)}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Typography>&nbsp;</Typography>
                  <CustomModal
                    size="lg"
                    getOpenButton={getOpenButtonHint}
                    title="Hinweis Zählerwechsel"
                  >
                    <Typography className="secondary-textcolor my-1">
                      {
                        "Sollte in Ihrem Haushalt noch ein klassischer Ferraris-Zähler installiert sein oder ein Zähler, der den Stromfluss nicht in beide Richtungen erfassen kann, muss dieser zwingend gegen einen Zweirichtungszähler ausgetauscht werden. Digitale Zähler, die sowohl den Bezug von Strom (Kennzeichnung 180) als auch die Einspeisung ins Netz (Kennzeichnung 280) messen können, wechseln sichtbar zwischen diesen beiden Modi. Für den Fall, dass ein Zählerwechsel notwendig ist, übernehmen wir die Beantragung beim zuständigen Netzbetreiber für Sie."
                      }
                    </Typography>
                    <Grid container justifyContent="center" alignItems="center">
                      <img
                        src="/emeter_change.png"
                        alt="emeter_examples"
                        width="536"
                        height="298"
                      />
                    </Grid>
                  </CustomModal>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">Aktion</Typography>
                <DropDown
                  text={"Auswählen"}
                  onChange={(value) => changeEMeter(eMeter.id, "action", value)}
                  options={eMeterActions}
                  value={eMeter.action}
                  search={true}
                  style={{ width: "100%" }}
                />
                {showMissingFields && eMeter.action === null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography>&nbsp;</Typography>
                <CustomModal
                  size="lg"
                  getOpenButton={getOpenButtonHint}
                  title="Hinweis Zählerwechsel"
                >
                  <Typography className="secondary-textcolor my-1">
                    {
                      "Sollte in Ihrem Haushalt noch ein klassischer Ferraris-Zähler installiert sein oder ein Zähler, der den Stromfluss nicht in beide Richtungen erfassen kann, muss dieser zwingend gegen einen Zweirichtungszähler ausgetauscht werden. Digitale Zähler, die sowohl den Bezug von Strom (Kennzeichnung 180) als auch die Einspeisung ins Netz (Kennzeichnung 280) messen können, wechseln sichtbar zwischen diesen beiden Modi. Für den Fall, dass ein Zählerwechsel notwendig ist, übernehmen wir die Beantragung beim zuständigen Netzbetreiber für Sie."
                    }
                  </Typography>
                  <Grid container justifyContent="center" alignItems="center">
                    <img
                      src="/emeter_change.png"
                      alt="emeter_examples"
                      width="536"
                      height="298"
                    />
                  </Grid>
                </CustomModal>
              </FormGroup>
            </Col>
          </Row>
        </Row>
      )}
      {setProject && (
        <div>
          <CustomButton
            onClick={() => {
              setProject((p) => {
                const eMeters = p.emeter_set.filter((e) => e.id !== eMeter.id);
                return { ...p, emeter_set: eMeters };
              });
            }}
            icon={"bin"}
          >
            ZÄHLER LÖSCHEN
          </CustomButton>
        </div>
      )}
      <hr className="secondary-textcolor" />
    </div>
  );
}

EMeterFormEntry.propTypes = {
  eMeter: eMeterPropType,
  setProject: PropTypes.func,
  changeEMeter: PropTypes.func,
  showMissingFields: PropTypes.bool,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
