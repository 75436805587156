import React, { Fragment, useEffect, useState } from "react";

import { isMobileOnly } from "react-device-detect";
import {
  getCustomerName,
  getUnderlinedOpenButton,
  hasPermission,
  round,
  string2Date,
} from "../../../elements/utils";
import { PROTOCOL } from "../../../settings";
import PlanningModal from "../../plannings/PlanningModal";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";
import RoofProjectPlanningFormModal from "./plannings/RoofProjectPlanningFormModal";
import OfferModal from "../../offers/OfferModal";
import NewOfferModal from "../NewOfferModal";
import InvoiceModal from "../../invoices/InvoiceModal";
import InvoiceFormModal from "../../invoices/InvoiceFormModal";
import { Stack, Typography } from "@mui/material";
import ImageStage from "../../shared/images/ImageStage";
import RoofConstructionModal from "./constructions/RoofConstructionModal";
import RoofConstructionDocumentationFormModal from "./constructions/RoofConstructionDocumentationFormModal";

export const getRoofProjectModals = (project, resetParent, session) => {
  const [planning, setPlanning] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const acceptedPlanning =
    project.accepted_offer_obj && project.accepted_offer_obj.planning_obj
      ? project.accepted_offer_obj.planning_obj
      : null;
  const acceptedOffer = project.accepted_offer_obj
    ? project.accepted_offer_obj
    : null;
  const finalInvoice = project.final_invoice_obj
    ? project.final_invoice_obj
    : null;

  const currPlanning = planning;
  useEffect(() => {
    localStorage.setItem("currPlanning", JSON.stringify(currPlanning));
  }, [currPlanning]);

  const offerAdditionalDetails =
    currPlanning && currPlanning.roofprojectroofimage_set ? (
      <Fragment>
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Dachfotos
        </Typography>
        <br />
        {currPlanning.roofprojectroofimage_set.map((roofImage) => (
          <div key={`roof_image_${roofImage.id}`}>
            <Stack direction={isMobileOnly ? "column" : "row"} spacing={2}>
              <ImageStage
                image={roofImage.blended_image}
                width={round((isMobileOnly ? 0.9 : 0.43) * window.innerWidth)}
              />
              <ImageStage
                image={roofImage.legend_image}
                width={round((isMobileOnly ? 0.9 : 0.43) * window.innerWidth)}
              />
            </Stack>
            <br />
          </div>
        ))}
      </Fragment>
    ) : (
      <></>
    );

  const planningModal =
    project.planning_set.length > 0 ? (
      <PlanningModal
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Planungen öffnen")}
        resetParent={resetParent}
        planning={planning}
        setPlanning={setPlanning}
      />
    ) : null;

  const newPlantPlanningModal = hasPermission(
    session.user,
    "customer_handling",
  ) ? (
    <RoofProjectPlanningFormModal
      key="new-plant-modal"
      project={project}
      getOpenButton={getUnderlinedOpenButton("Neue Planung")}
      resetParent={resetParent}
      planning={planning}
      setPlanning={setPlanning}
      session={session}
    />
  ) : null;

  const acceptedPlanningModal = acceptedPlanning ? (
    <PdfViewModal
      key="accepted-plant-modal"
      title={
        isMobileOnly
          ? "Planung"
          : "Planung für " + getCustomerName(project.customer_obj)
      }
      getOpenButton={getUnderlinedOpenButton("Angenommene Planung")}
      filepath={
        acceptedPlanning.pdf
          ? acceptedPlanning.pdf.replace("http:", PROTOCOL + ":")
          : null
      }
    />
  ) : null;

  // --------------- offer

  const offerModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.planning_set.length > 0 &&
    project.baseoffer_set.length > 0 ? (
      <OfferModal
        key="offer-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Angebote öffnen")}
        resetParent={resetParent}
        additionalDetails={offerAdditionalDetails}
        planning={planning}
        setPlanning={setPlanning}
      />
    ) : null;

  const newOfferModal =
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <NewOfferModal
        key="new-offer-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Neues Angebot")}
        session={session}
        resetParent={resetParent}
        additionalDetails={offerAdditionalDetails}
        setPlanning={setPlanning}
      />
    ) : null;

  const acceptedOfferModal =
    hasPermission(session.user, "customer_handling_prices") && acceptedOffer ? (
      <PdfViewModal
        key="accepted-offer-modal"
        title={"Angebot für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Angenommenes Angebot")}
        filepath={
          acceptedOffer.pdf
            ? acceptedOffer.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  // --------------- construction

  const constructionModal = acceptedOffer ? (
    <RoofConstructionModal
      key="construction-modal"
      project={project}
      getOpenButton={getUnderlinedOpenButton("Bauplanung")}
      session={session}
      resetParent={resetParent}
    />
  ) : null;

  const constructionDocumentationModal =
    project.construction_obj &&
    project.construction_obj.constructiondates_set &&
    project.construction_obj.constructiondates_set.some(
      (dateObj) => string2Date(dateObj.date) <= new Date(),
    ) ? (
      <RoofConstructionDocumentationFormModal
        key="construction-documentation-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Baudokumentation")}
        resetParent={resetParent}
        session={session}
      />
    ) : null;

  // --------------- invoices

  const invoiceModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.accepted_offer_obj &&
    project.baseinvoice_set.length > 0 ? (
      <InvoiceModal
        key="invoice-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Rechnungen öffnen")}
        resetParent={resetParent}
        isOpen={invoiceModalOpen}
        setIsOpen={setInvoiceModalOpen}
      />
    ) : null;

  const newInvoiceModal =
    acceptedOffer &&
    !finalInvoice &&
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <InvoiceFormModal
        key="new-invoice-modal"
        project={project}
        productRequestParams={{
          offer: acceptedOffer ? acceptedOffer.id : null,
          // construction: (construction) ? construction.id : null
        }}
        getOpenButton={getUnderlinedOpenButton("Neue Rechnung")}
        session={session}
        final={true}
        resetParent={resetParent}
        setInvoiceModalOpen={setInvoiceModalOpen}
      />
    ) : null;

  const finalInvoiceModal =
    finalInvoice && hasPermission(session.user, "customer_handling_prices") ? (
      <PdfViewModal
        key="final-invoice-modal"
        title={"Endrechnung für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Endrechnung")}
        filepath={
          finalInvoice.pdf
            ? finalInvoice.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  return {
    parents: [
      {
        key: "main_point_planning",
        modals: [
          planningModal,
          acceptedPlanning ? acceptedPlanningModal : newPlantPlanningModal,
        ],
      },
      {
        key: "main_point_offer",
        modals: [
          offerModal,
          acceptedOffer ? acceptedOfferModal : newOfferModal,
        ],
      },
      {
        key: "main_point_construction",
        modals: [constructionModal, constructionDocumentationModal],
      },
      {
        key: "main_point_invoice",
        modals: [
          invoiceModal,
          finalInvoice ? finalInvoiceModal : newInvoiceModal,
        ],
      },
    ],
    children: [
      {
        key: "planning",
        modals: [newPlantPlanningModal],
      },
      {
        key: "offer_sent",
        modals: [offerModal],
      },
      {
        key: "offer_accepted",
        modals: [offerModal],
      },
      {
        key: "offer_confirmed",
        modals: [offerModal],
      },
      {
        key: "construction",
        modals: [constructionModal],
      },
      {
        key: "construction_documentation",
        modals: [constructionDocumentationModal],
      },
      {
        key: "invoice_sent",
        modals: [invoiceModal],
      },
      {
        key: "invoice_paid",
        modals: [invoiceModal],
      },
      ...project.baseinvoice_set.map((invoice) => ({
        key: `partial_invoice_${invoice.id}_paid`,
        modals: [invoiceModal],
      })),
      ...project.baseinvoice_set
        .filter((invoice) => !invoice.final)
        .map((invoice) => ({
          key: `partial_invoice_${invoice.id}_sent`,
          modals: [invoiceModal],
        })),
    ],
  };
};

export const unclickableRoofProjectTodoKeys = [
  "planning",
  "offer_sent",
  "offer_accepted",
  "offer_confirmed",
  "construction_planning",
  "invoice_sent",
  "invoice_paid",
  "partial_invoice_sent",
  "partial_invoice_paid",
  "construction",
  "construction_documentation",
  "cancellation_invoice_sent",
  "cancelled_invoice_sent",
  "cancelled_invoice_paid",
  "cancellaltion_invoice_paid",
];
