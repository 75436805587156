import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
import axios from "axios";
import { API_URL_CUSTOMER } from "../../settings";
import { Stack, Typography } from "@mui/material";

import CustomersTable from "../CustomersTable";
import { CustomIconButton } from "../../elements/StyledElements";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Customer Params
 * @typedef {Object} CustomerParams
 * @property {string} customerSearchInput
 * @property {string|null} openToDoTypeFilter
 * @property {number|null} salesmenFilter
 * @property {string|null} toDoFilter
 * @property {number|null} employeeFilter
 * @property {string|null} projectTypeFilter
 * @property {Array|null} productsFilter
 * @property {number} batchSize
 * @property {number} batch
 */

/**
 * Customized Customer
 * @param {CustomerParams} params
 * @returns {JSX.Element}
 */
export default function CustomizedCustomer({ session, params }) {
  const batchSize = 50;
  const defaultParams = {
    openToDoTypeFilter: null,
    salesmenFilter: ["salesmen"].includes(session.user.group_key)
      ? session.user.id
      : null,
    toDoFilter: null,
    employeeFilter: null,
    projectTypeFilter: null,
    productsFilter: [],
  };

  const [loaded, setLoaded] = useState(false);
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(0);
  const [customers, setCustomers] = useState([]);

  const mapperParams = (passedParams) => {
    const params = {
      ...defaultParams,
      ...passedParams,
    };
    return {
      batch_size: batchSize,
      batch,
      salesman: params.salesmanFilter,
      nested: true,
      ascending_ids: false,
      included_todos: params.toDoFilter
        ? params.toDoFilter.included_todos.map((x) => x.toString()).join()
        : null,
      excluded_todos: params.toDoFilter
        ? params.toDoFilter.excluded_todos.map((x) => x.toString()).join()
        : null,
      employee: params.employeeFilter,
      products:
        params.productsFilter.length > 0
          ? params.productsFilter.join(",")
          : null,
      project_type: params.projectTypeFilter?.toLowerCase(),
      open_todo_type: params.openToDoTypeFilter,
      open_tasks: params.openTasksFilter,
    };
  };

  const getCustomers = async () => {
    setLoaded(false);
    const mappedParams = mapperParams(params);
    const response = await axios.get(API_URL_CUSTOMER, {
      params: mappedParams,
    });
    setNPages(response.headers.length);
    setCustomers(response.data);
    setLoaded(true);
  };

  useEffect(() => {
    if (batch !== 0) {
      setBatch(0);
    } else {
      getCustomers();
    }
  }, [params]);

  useEffect(() => {
    getCustomers();
  }, [batch]);

  return (
    <React.Fragment>
      {!loaded ? (
        <>
          <CircularProgress color="inherit" />
          &nbsp;&nbsp;
          <Typography fontSize="h4.fontSize" className="primary-textcolor">
            Wird geladen...
          </Typography>
        </>
      ) : (
        <CustomersTable
          {...{
            customers,
            session,
            batch,
            batchSize,
          }}
        />
      )}
      {nPages && nPages > 1 && (
        <Stack direction="row" spacing={2}>
          <CustomIconButton
            disabled={batch === 0}
            icon="previous"
            onClick={() => setBatch(batch - 1)}
          />
          <Typography className="secondary-textcolor">{batch + 1}</Typography>
          <CustomIconButton
            disabled={batch === nPages - 1}
            icon="next"
            onClick={() => setBatch(batch + 1)}
          />
        </Stack>
      )}
    </React.Fragment>
  );
}

CustomizedCustomer.propTypes = {
  session: PropTypes.object,
  params: PropTypes.object,
};
