import React, { useState } from "react";

import { isMobileOnly } from "react-device-detect";
import {
  getCustomerName,
  getUnderlinedOpenButton,
  hasPermission,
  string2Date,
} from "../../../elements/utils";
import { PROTOCOL } from "../../../settings";
import InvoiceFormModal from "../../invoices/InvoiceFormModal";
import InvoiceModal from "../../invoices/InvoiceModal";
import OfferModal from "../../offers/OfferModal";
import PlanningModal from "../../plannings/PlanningModal";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";
import NewOfferModal from "../NewOfferModal";
import PlantConstructionDocumentationFormModal from "./constructions/PlantConstructionDocumentationFormModal";
import PlantConstructionModal from "./constructions/PlantConstructionModal";
import DataInputFormModal from "./operator_requests/DataInputFormModal";
import OperatorRequestModal from "./operator_requests/OperatorRequestModal";
import PlantPlanningFormModal from "./plannings/PlantPlanningFormModal";

export const getPlantProjectModals = (project, resetParent, session) => {
  const [planning, setPlanning] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const acceptedPlanning =
    project.accepted_offer_obj && project.accepted_offer_obj.planning_obj
      ? project.accepted_offer_obj.planning_obj
      : null;
  const acceptedOffer = project.accepted_offer_obj
    ? project.accepted_offer_obj
    : null;
  const finalInvoice = project.final_invoice_obj
    ? project.final_invoice_obj
    : null;
  const construction = project.construction_obj
    ? project.construction_obj
    : null;

  const planningModal =
    project.planning_set.length > 0 ? (
      <PlanningModal
        key="plant-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Planungen öffnen")}
        resetParent={resetParent}
        planning={planning}
        setPlanning={setPlanning}
      />
    ) : null;

  const newPlantPlanningModal = hasPermission(
    session.user,
    "customer_handling",
  ) ? (
    <PlantPlanningFormModal
      key="new-plant-modal"
      project={project}
      getOpenButton={getUnderlinedOpenButton("Neue Planung")}
      resetParent={resetParent}
      planning={planning}
      setPlanning={setPlanning}
      session={session}
    />
  ) : null;

  const acceptedPlanningModal = acceptedPlanning ? (
    <PdfViewModal
      key="accepted-plant-modal"
      title={
        isMobileOnly
          ? "Planung"
          : "Planung für " + getCustomerName(project.customer_obj)
      }
      getOpenButton={getUnderlinedOpenButton("Angenommene Planung")}
      filepath={
        acceptedPlanning.pdf
          ? acceptedPlanning.pdf.replace("http:", PROTOCOL + ":")
          : null
      }
    />
  ) : null;

  // --------------- offer

  const offerModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.planning_set.length > 0 &&
    project.baseoffer_set.length > 0 ? (
      <OfferModal
        key="offer-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Angebote öffnen")}
        resetParent={resetParent}
      />
    ) : null;

  const newOfferModal =
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <NewOfferModal
        key="new-offer-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Neues Angebot")}
        session={session}
        resetParent={resetParent}
      />
    ) : null;

  const acceptedOfferModal =
    hasPermission(session.user, "customer_handling_prices") && acceptedOffer ? (
      <PdfViewModal
        key="accepted-offer-modal"
        title={"Angebot für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Angenommenes Angebot")}
        filepath={
          acceptedOffer.pdf
            ? acceptedOffer.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  // --------------- operator requests

  const dataInputModal = (
    <DataInputFormModal
      key="data-input-modal"
      project={project}
      getOpenButton={getUnderlinedOpenButton("Dateneingabe öffnen")}
      resetParent={resetParent}
      session={session}
    />
  );

  const getOperatorRequestModal = (requestMode) =>
    hasPermission(session.user, "customer_handling") &&
    project.accepted_offer_obj &&
    project.construction_obj ? (
      <OperatorRequestModal
        key={`operatorr-request-modal-${requestMode}`}
        project={project}
        resetParent={resetParent}
        getOpenButton={getUnderlinedOpenButton("Netzantrag")}
        requestMode={requestMode}
        session={session}
      />
    ) : null;

  const getRequestModes = (parent) => {
    const requestModes = [];
    if (parent.children.some((c) => c.key === "operator_request_1_sent"))
      requestModes.push(0);
    if (parent.children.some((c) => c.key === "operator_request_2_sent"))
      requestModes.push(1);
    if (parent.children.some((c) => c.key === "operator_request_3_sent"))
      requestModes.push(2);
    if (parent.children.some((c) => c.key === "operator_request_4_sent"))
      requestModes.push(3);
    return requestModes;
  };

  // --------------- construction

  const constructionModal = acceptedOffer ? (
    <PlantConstructionModal
      key="construction-modal"
      project={project}
      getOpenButton={getUnderlinedOpenButton("Bauplanung")}
      session={session}
      resetParent={resetParent}
    />
  ) : null;

  const constructionDocumentationModal =
    project.construction_obj &&
    project.construction_obj.constructiondates_set &&
    project.construction_obj.constructiondates_set.length > 0 &&
    project.construction_obj.constructiondates_set.some(
      (dateObj) => string2Date(dateObj.date) <= new Date(),
    ) ? (
      <PlantConstructionDocumentationFormModal
        key="construction-documentation-modal"
        project={project}
        getOpenButton={getUnderlinedOpenButton("Baudokumentation")}
        resetParent={resetParent}
        session={session}
      />
    ) : null;

  // --------------- invoices

  const invoiceModal =
    hasPermission(session.user, "customer_handling_prices") &&
    project.accepted_offer_obj &&
    project.baseinvoice_set.length > 0 ? (
      <InvoiceModal
        key="invoice-modal"
        project={project}
        session={session}
        getOpenButton={getUnderlinedOpenButton("Rechnungen öffnen")}
        resetParent={resetParent}
        isOpen={invoiceModalOpen}
        setIsOpen={setInvoiceModalOpen}
      />
    ) : null;

  const newInvoiceModal =
    acceptedOffer &&
    !finalInvoice &&
    hasPermission(session.user, "customer_handling") &&
    hasPermission(session.user, "customer_handling_prices") ? (
      <InvoiceFormModal
        key="new-invoice-modal"
        project={project}
        productRequestParams={{
          offer: acceptedOffer ? acceptedOffer.id : null,
          construction: construction ? construction.id : null,
        }}
        getOpenButton={getUnderlinedOpenButton("Neue Rechnung")}
        session={session}
        final={true}
        resetParent={resetParent}
        setInvoiceModalOpen={setInvoiceModalOpen}
      />
    ) : null;

  const finalInvoiceModal =
    finalInvoice && hasPermission(session.user, "customer_handling_prices") ? (
      <PdfViewModal
        key="final-invoice-modal"
        title={"Endrechnung für " + getCustomerName(project.customer_obj)}
        getOpenButton={getUnderlinedOpenButton("Endrechnung")}
        filepath={
          finalInvoice.pdf
            ? finalInvoice.pdf.replace("http:", PROTOCOL + ":")
            : null
        }
      />
    ) : null;

  return {
    parents: [
      {
        key: "main_point_planning",
        modals: [
          planningModal,
          acceptedPlanning ? acceptedPlanningModal : newPlantPlanningModal,
        ],
      },
      {
        key: "main_point_offer",
        modals: [
          offerModal,
          acceptedOffer ? acceptedOfferModal : newOfferModal,
        ],
      },
      {
        key: "main_point_operator_request_1",
        modals: [(parent) => getOperatorRequestModal(getRequestModes(parent))], // ADD DATAIN
      },
      {
        key: "main_point_operator_request_2",
        modals: [(parent) => getOperatorRequestModal(getRequestModes(parent))],
      },
      {
        key: "main_point_construction",
        modals: [constructionModal, constructionDocumentationModal],
      },
      {
        key: "main_point_invoice",
        modals: [
          invoiceModal,
          finalInvoice ? finalInvoiceModal : newInvoiceModal,
        ],
      },
    ],
    children: [
      {
        key: "planning",
        modals: [newPlantPlanningModal],
      },
      {
        key: "offer_sent",
        modals: [offerModal],
      },
      {
        key: "offer_accepted",
        modals: [offerModal],
      },
      // {
      //   key: 'offer_technical_approval',
      //   modals: [offerModal]
      // },
      {
        key: "offer_confirmed",
        modals: [offerModal],
      },
      {
        key: "data_input",
        modals: [dataInputModal],
      },
      {
        key: "construction_planning",
        modals: [constructionModal],
      },
      {
        key: "construction",
        modals: [constructionModal],
      },
      {
        key: "construction_documentation",
        modals: [constructionDocumentationModal],
      },
      {
        key: "operator_request_1_sent",
        modals: [getOperatorRequestModal(0)],
      },
      {
        key: "operator_request_2_sent",
        modals: [getOperatorRequestModal(1)],
      },
      {
        key: "operator_request_3_sent",
        modals: [getOperatorRequestModal(2)],
      },
      {
        key: "operator_request_4_sent",
        modals: [getOperatorRequestModal(3)],
      },
      {
        key: "invoice_sent",
        modals: [invoiceModal],
      },
      {
        key: "invoice_paid",
        modals: [invoiceModal],
      },
      ...project.baseinvoice_set.map((invoice) => ({
        key: `partial_invoice_${invoice.id}_paid`,
        modals: [invoiceModal],
      })),
      ...project.baseinvoice_set
        .filter((invoice) => !invoice.final)
        .map((invoice) => ({
          key: `partial_invoice_${invoice.id}_sent`,
          modals: [invoiceModal],
        })),
    ],
  };
};

export const unclickablePlantProjectTodoKeys = [
  "planning",
  "offer_sent",
  "offer_accepted",
  "offer_technical_approval",
  "offer_confirmed",
  "construction_planning",
  "send_construction_appointment",
  "invoice_sent",
  "invoice_paid",
  "partial_invoice_sent",
  "partial_invoice_paid",
  "construction",
  "construction_documentation",
  "data_input",
  "cancellation_invoice_sent",
  "cancelled_invoice_sent",
  "cancelled_invoice_paid",
  "cancellaltion_invoice_paid",
];
