import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";

import { API_URL_LEAD, API_URL_LEADDUPLICATES } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import {
  string2FormattedString,
  isBoolean,
  hasPermission,
  isValidEmailAddress,
  checkIfValueIsEmpty,
  getEmptyFieldsError,
} from "../../elements/utils";
import {
  leadPropType,
  leadTypePropType,
  userPropType,
} from "../../elements/PropTypes";
import CollapsibleTable from "../../elements/CollapsibleTable";
import LeadForm from "./LeadForm";
import { Stack } from "@mui/material";
import LeadMailModal from "./LeadMailModal";
import ArchiveLeadModal from "./ArchiveLeadModal";

const emptyLeadForm = {
  id: null,
  gender: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null,
  email: null,
  phone1: null,
  phone2: null,
  phone3: null,
  notes: null,
  source: null,
  lead_type: null,
  salesman: null,
};

export default function LeadFormModal({
  isOpen,
  setIsOpen,
  getOpenButton,
  lead,
  resetParent,
  leadTypes,
  salesmen,
  session,
}) {
  const [leadForm, setLeadForm] = useState({ ...emptyLeadForm });
  const [leadFormErrors, setLeadFormErrors] = useState({
    email: null,
    phone1: null,
    phone2: null,
    phone3: null,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [duplicates, setDuplicates] = useState(null);
  const [_isOpen, _setIsOpen] = useState(setIsOpen ? isOpen : false);

  useEffect(() => {
    if (isBoolean(isOpen) && _isOpen !== isOpen) _setIsOpen(isOpen);
  }, [isOpen]);

  const changeIsOpen = (isOpen) => {
    if (setIsOpen) setIsOpen(isOpen);
    else _setIsOpen(isOpen);
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setLeadForm(emptyLeadForm);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    setLeadForm(lead || { ...emptyLeadForm });
  };

  useEffect(() => {
    loadData();
  }, [lead]);

  useEffect(() => {
    if (!lead) loadData();
  }, [session.user]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    if (lead) {
      return (
        <CustomButton onClick={toggle} icon="info">
          Lead
        </CustomButton>
      );
    }
    return (
      <CustomButton
        onClick={toggle}
        style={{ maxWidth: "300px" }}
        icon="lead"
        className="float-end"
        id="add_lead_btn"
      >
        Lead hinzufügen
      </CustomButton>
    );
  };

  const submit = async (leadForm) => {
    if (lead) {
      return axios.put(API_URL_LEAD + lead.id, leadForm);
    }
    const promise = axios.post(API_URL_LEAD, leadForm);
    return promise;
  };

  const onSubmit = async (onSuccess, checkForDuplicates = true) => {
    const optionalKeys = ["phone2", "phone3", "notes"];
    if (leadForm.gender !== "Firma") {
      optionalKeys.push("company");
    } else {
      optionalKeys.push("first_name");
      optionalKeys.push("last_name");
    }

    if (!lead) {
      optionalKeys.push("id");
    }

    const checkIfEmpty = (key, val) => {
      if (key === "email") {
        return !isValidEmailAddress(leadForm.email);
      }

      return checkIfValueIsEmpty(val);
    };

    const emptyFieldsError = getEmptyFieldsError(
      leadForm,
      emptyLeadForm,
      optionalKeys,
      checkIfEmpty,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const duplicatePromise =
      lead || !checkForDuplicates
        ? Promise.resolve([])
        : axios
            .get(API_URL_LEADDUPLICATES, {
              params: {
                company: leadForm.gender === "Firma" ? leadForm.company : "",
                first_name: leadForm.first_name,
                last_name: leadForm.last_name,
                email: leadForm.email,
                phone1: leadForm.phone1,
                phone2: leadForm.phone2,
                phone3: leadForm.phone3,
              },
            })
            .then((res) => res.data);

    duplicatePromise
      .then((duplicates) => {
        if (duplicates && duplicates.length > 0) {
          setDuplicates(duplicates);
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
          return;
        }
        return submit(leadForm).then((res) => {
          resetParent(res.data);
          onSuccess();
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
        });
      })
      .catch((error) => {
        console.error('Error in "lead:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const isFormError = () => {
    return (
      (!!leadForm.email && !!leadFormErrors.email) ||
      (!!leadForm.phone1 && !!leadFormErrors.phone1) ||
      (!!leadForm.phone2 && !!leadFormErrors.phone2) ||
      (!!leadForm.phone3 && !!leadFormErrors.phone3)
    );
  };
  const getFooter = (toggle) => {
    return (
      <Stack direction="row" spacing={2}>
        {leadForm.customer ? (
          <CustomButton
            size="large"
            description={"Kundenseite"}
            icon={"person"}
            onClick={() => {
              history.push(`/kunde/${lead.customer}`);
            }}
            color="#ffffff"
          >
            Kunde
          </CustomButton>
        ) : null}
        {leadForm.email_html ? (
          <LeadMailModal
            lead={leadForm}
            getOpenButton={(toggle) => (
              <CustomButton
                description={"E-Mail"}
                icon={"mail"}
                onClick={toggle}
                color="#ffffff"
                size="large"
              >
                E-Mail
              </CustomButton>
            )}
          />
        ) : null}
        {lead && lead.archived ? (
          <CustomButton
            color="#ffffff"
            description={"Aktiveren"}
            icon={"unarchive"}
            onClick={() => {
              axios
                .put(API_URL_LEAD + lead.id, { archived: !lead.archived })
                .then(() => {
                  toggle();
                  resetParent();
                  changeIsOpen(false);
                });
            }}
          >
            Aktiveren
          </CustomButton>
        ) : null}
        {lead && !lead.archived ? (
          <ArchiveLeadModal
            lead={lead}
            resetParent={(args) => {
              resetParent(args);
              changeIsOpen(false);
            }}
            session={session}
            getOpenButton={(toggle) => (
              <CustomButton
                color="#ffffff"
                description={"Archivieren"}
                icon={"archive"}
                onClick={toggle}
              >
                Archivieren
              </CustomButton>
            )}
          />
        ) : null}
        <SaveModalFooter
          id="submit_customer_form"
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          disabled={
            (lead && lead.customer) ||
            !hasPermission(session.user, "edit_leads") ||
            isFormError()
          }
        />
      </Stack>
    );
  };

  const getDuplicateFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => {
          toggle();
          onSubmit(() => changeIsOpen(false), false);
        }}
        onCancel={toggle}
        saveBtnLabel="Trotzdem Speichern"
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={_getOpenButton}
      title="Leadinformationen"
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={_isOpen}
      setIsOpen={changeIsOpen}
    >
      <CustomModal
        title="Lead/Kunde ist bereits vorhanden"
        size="xl"
        getFooter={getDuplicateFooter}
        isOpen={duplicates !== null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setDuplicates(null);
        }}
        getOpenButton={() => null}
      >
        {duplicates ? (
          <CollapsibleTable
            columns={[
              {
                name: "Nachname",
                key: "last_name",
              },
              {
                name: "Vorname",
                key: "first_name",
              },
              {
                name: "Datum",
                key: "registration",
              },
              {
                name: "EMail",
                key: "email",
              },
              {
                name: "Telefon",
                key: "phone",
              },
              {
                name: "Typ",
                key: "duplicate_type",
              },
            ]}
            rows={duplicates.map((duplicate) => ({
              key: `${duplicate.id}`,
              first_name: duplicate.first_name,
              last_name: duplicate.last_name,
              registration: string2FormattedString(
                duplicate.registration_date || duplicate.registration_datetime,
              ),
              email: duplicate.email,
              phone: [duplicate.phone1, duplicate.phone2, duplicate.phone3]
                .filter((x) => x)
                .join("; "),
              duplicate_type:
                duplicate.duplicate_type === "customer" ? "Kunde" : "Lead",
            }))}
            collapsible={false}
          />
        ) : null}
      </CustomModal>
      <LeadForm
        lead={leadForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setLead={setLeadForm}
        leadTypes={leadTypes}
        salesmen={salesmen}
        errors={leadFormErrors}
        setErrors={setLeadFormErrors}
      />
    </CustomModal>
  );
}

LeadFormModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  lead: leadPropType,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  leadTypes: PropTypes.arrayOf(leadTypePropType),
  salesmen: PropTypes.arrayOf(userPropType),
  session: PropTypes.object,
};
