import React, { useState } from "react";
import { Input } from "reactstrap";
import { PropTypes } from "prop-types";
import axios from "axios";

import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../shared/modal_utils/CustomModal";
import { CustomButton } from "../../elements/StyledElements";
import { API_URL_WAREHOUSEUSEDINPRODUCT } from "../../settings";
import { Table } from "react-bootstrap";
import DropDown from "../../elements/DropDown";
import { defaultIfEmpty } from "../../elements/utils";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";

const emptyWarehouseUsedInProduct = {
  warehouse_product_id: null,
  amount: 1,
};

export default function WarehouseUsedInProduct({
  selectedProduct,
  warehouseUsedInProduct,
  setWarehouseUsedInProduct,
  warehouseProducts,
  resetParent,
  style,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const handleUsedInProductChange = (idx, key, val) => {
    const newList = [...warehouseUsedInProduct];
    newList[idx][key] = val;
    setWarehouseUsedInProduct(newList);
  };

  const handleUsedInProductDelete = (idx) => {
    setWarehouseUsedInProduct(
      warehouseUsedInProduct.filter((_, id) => id !== idx),
    );
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} style={style} disabled={!selectedProduct}>
        {" "}
        Update Produkt{" "}
      </CustomButton>
    );
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => {
          setLoadingElements({
            inProgress: true,
            submitError: false,
            showMissingFields: false,
          });
          if (
            warehouseUsedInProduct.find(
              (e) => !e.warehouse_product_id || !e.amount,
            )
          ) {
            setLoadingElements({
              inProgress: false,
              submitError: false,
              showMissingFields: true,
            });
            return;
          }
          axios
            .post(API_URL_WAREHOUSEUSEDINPRODUCT, {
              product_id: selectedProduct,
              warehouse_products: warehouseUsedInProduct,
            })
            .then((res) => {
              setLoadingElements({
                inProgress: false,
                submitError: false,
                showMissingFields: false,
              });
              resetParent();
              toggle();
            })
            .catch((err) => {
              setLoadingElements({
                inProgress: false,
                submitError: true,
                showMissingFields: false,
              });
              console.error(err);
            });
        }}
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      title="Update Material Verwendet in Produkt"
    >
      <br />
      <div style={{ position: "relative" }}>
        <Table className="table-not-hover">
          <thead>
            <tr>
              <th className="align-middle">
                <Typography className="secondary-textcolor">
                  Material
                </Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Anzahl</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Loschen</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {warehouseUsedInProduct.map((row, idx) => (
              <tr key={idx}>
                <td className="align-middle">
                  <DropDown
                    onChange={(val) => {
                      handleUsedInProductChange(
                        idx,
                        "warehouse_product_id",
                        val,
                      );
                    }}
                    options={warehouseProducts
                      .filter(
                        (product) =>
                          product.id === row.warehouse_product_id ||
                          !warehouseUsedInProduct.find(
                            (e) => e.warehouse_product_id === product.id,
                          ),
                      )
                      .map((warehouseProduct) => ({
                        value: warehouseProduct.id,
                        label:
                          "[i" +
                          warehouseProduct.id +
                          "] " +
                          warehouseProduct.name,
                      }))}
                    value={defaultIfEmpty(row.warehouse_product_id)}
                    required={true}
                    invalid={
                      loadingElements.showMissingFields &&
                      !row.warehouse_product_id
                    }
                    search={true}
                  />
                </td>
                <td className="align-middle">
                  <Input
                    style={{ boxShadow: "none" }}
                    type="number"
                    min="1"
                    value={row.amount}
                    onChange={(e) => {
                      handleUsedInProductChange(idx, "amount", e.target.value);
                    }}
                    invalid={loadingElements.showMissingFields && !row.amount}
                  />
                </td>
                <td className="align-middle">
                  <IconButton
                    onClick={() => {
                      handleUsedInProductDelete(idx);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomButton
          onClick={() => {
            setWarehouseUsedInProduct([
              ...warehouseUsedInProduct,
              { ...emptyWarehouseUsedInProduct },
            ]);
          }}
        >
          Verwendet in Produkt Hinzufügen
        </CustomButton>
      </div>
    </CustomModal>
  );
}

WarehouseUsedInProduct.propTypes = {
  selectedProduct: PropTypes.number,
  warehouseUsedInProduct: PropTypes.array,
  setWarehouseUsedInProduct: PropTypes.func,
  warehouseProducts: PropTypes.array,
  resetParent: PropTypes.func,
  style: PropTypes.object,
};
