import React, { Fragment, useRef } from "react";
import { PropTypes } from "prop-types";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobileOnly } from "react-device-detect";

import { Stack, Typography, Grid } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Collapsible from "react-collapsible";

import Optimization from "./Optimization";

import DropDown from "../../../../elements/DropDown";
import {
  CustomToggle,
  CustomIconButton,
} from "../../../../elements/StyledElements";
import {
  batteryOptions,
  date2String,
  defaultIfEmpty,
  round,
  string2Date,
  getRandomId,
} from "../../../../elements/utils";
import UploadImages from "../../../shared/images/UploadImages";
import RoofImageUpload from "../../../shared/images/RoofImageUpload";
import ImageStage from "../../../shared/images/ImageStage";
import EditRoofImagePlanning from "../../../shared/images/EditRoofImagePlanning";
import RoofImage from "../../../shared/images/RoofImage";
import { tenantModelOptions } from "../variables";
import {
  modulePropType,
  planningPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import VoltavoData from "./VolavoData";
import axios from "axios";
import {
  API_URL_PLANNING,
  API_URL_ROOFIMAGE,
  API_URL_ROOFIMAGEPANEL,
  API_URL_ROOFIMAGEPANEL_BULK,
} from "../../../../settings";

const heatingSystemOptions = [
  { label: "Gas", value: 0 },
  { label: "Öl", value: 1 },
  { label: "Fernwärme", value: 2 },
  { label: "Holz/Pellets", value: 3 },
  { label: "Elektro", value: 4 },
  { label: "Wärmepumpe", value: 5 },
  { label: "Sonstiges", value: 6 },
];

const floorsOptions = [
  { label: "1", value: "1" },
  { label: "1.5", value: "1.5" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export default function PlantPlanningForm({
  project,
  planning,
  setPlanning,
  modules,
  showMissingFields,
  session,
}) {
  const pickerRef1 = useRef(null);
  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    let update = { [key]: value };

    switch (key) {
      case "wallbox_required":
        update = { ...update, circuit_preparation: value > 0 ? false : null };
        break;
      case "floors":
        update = { ...update, floors: value };
        if (value <= 1.5) {
          update = { ...update, scaffoldingimage_set: [] };
        }
        break;
      default:
        break;
    }

    setPlanning((p) => ({ ...p, ...update }));
  };

  const resetRoofImage = async () => {
    const currPlanning = (
      await axios.get(API_URL_PLANNING + planning.id, {
        params: { nested: true },
      })
    ).data;

    const roofImageSet = currPlanning.roofimage_set;

    setPlanning({ ...planning, roofimage_set: roofImageSet });
  };
  const addRoofImage = async (
    newDirection,
    image,
    imageWidthM,
    houseImage,
    angle,
    panels,
    flatRoof,
  ) => {
    const planningId = planning.id;

    if (!planningId) return;

    const prevRoofNumber = planning.roofimage_set.length
      ? parseInt(
          planning.roofimage_set[planning.roofimage_set.length - 1].name?.split(
            " ",
          )[1],
        )
      : 0;

    const roofImageForm = new FormData();
    roofImageForm.append("name", `Dachseite ${prevRoofNumber + 1}`);
    roofImageForm.append("image", image);
    roofImageForm.append("house_image", houseImage);
    roofImageForm.append("planning", planningId);
    roofImageForm.append("direction", newDirection);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("image_width_m", imageWidthM);
    roofImageForm.append("angle", angle);

    const roofImageRes = await axios.post(API_URL_ROOFIMAGE, roofImageForm);
    const roofImageId =
      roofImageRes && roofImageRes.data && roofImageRes.data.id;

    if (roofImageId && panels && panels.length > 0) {
      const panelBulkReq = panels.map((panel) => {
        const newPanel = { ...panel };
        delete newPanel.id;
        newPanel.roof_image = roofImageId;
        return newPanel;
      });
      await axios.post(API_URL_ROOFIMAGEPANEL_BULK, panelBulkReq);
    }

    await resetRoofImage();
  };

  const deleteRoofImage = async (roofImageId) => {
    await axios.delete(API_URL_ROOFIMAGE + roofImageId.toString());
    await resetRoofImage();
  };

  const updateRoofImage = async (
    roofImageId,
    panels,
    strings,
    angle,
    direction,
    flatRoof,
  ) => {
    const planningId = planning.id;

    if (!planningId || !roofImageId) return;

    const roofImageForm = new FormData();
    roofImageForm.append("planning", planningId);
    roofImageForm.append("direction", direction);
    roofImageForm.append("flat_roof", flatRoof);
    roofImageForm.append("angle", angle);

    await axios.put(API_URL_ROOFIMAGE + roofImageId.toString(), roofImageForm);
    await axios.delete(API_URL_ROOFIMAGEPANEL, {
      data: { roof_image: roofImageId, except_ids: [] },
    });
    const panelBulkReq = panels.map((panel) => {
      const newPanel = { ...panel };
      delete newPanel.id;
      newPanel.roof_image = roofImageId;
      return newPanel;
    });
    await axios.post(API_URL_ROOFIMAGEPANEL_BULK, panelBulkReq);

    await resetRoofImage();
  };

  const addImage = (key, imageDict) => {
    setPlanning((p) => {
      const images = [...p[key]];
      images.push(imageDict);
      return { ...p, [key]: images };
    });
  };

  const deleteImage = (key, imageDict) => {
    setPlanning((p) => ({
      ...p,
      [key]: p[key].filter((i) => i.id !== imageDict.id),
    }));
  };

  const addBattery = () => {
    setPlanning((p) => {
      const batterySet = [...p.batterykwh_set];
      batterySet.push({
        id: -getRandomId(),
        kwh: null,
        order: batterySet.length + 1,
      });
      return { ...p, batterykwh_set: batterySet };
    });
  };

  const deleteBattery = (index) => {
    setPlanning((p) => {
      const batterySet = [...p.batterykwh_set];
      const filteredBatterySet = batterySet.filter((b, i) => i !== index);
      filteredBatterySet.forEach((b, i) => {
        b.order = i + 1;
      });
      const batteryKwh = round(
        filteredBatterySet.reduce((acc, b) => acc + b.kwh, 0),
        2,
      );
      return {
        ...p,
        battery_kwh: batteryKwh,
        batterykwh_set: filteredBatterySet,
      };
    });
  };

  const updateBattery = (index, value) => {
    setPlanning((p) => {
      const batterySet = [...p.batterykwh_set];
      batterySet[index].kwh = value || 0;
      const batteryKwh = round(
        batterySet.reduce((acc, b) => acc + b.kwh, 0),
        2,
      );
      return { ...p, battery_kwh: batteryKwh, batterykwh_set: batterySet };
    });
  };

  const handleToggleChange = (key, e, value) => {
    let update = { [key]: value };

    switch (key) {
      case "e_meter_cabinet":
        update = {
          ...update,
          big_e_meter_cabinet: value === false ? false : null,
        };
        break;
      case "wallbox":
        if (value === true) update = { ...update, wallbox_required: 0 };
        break;
      case "converter_measurement_available":
        update = {
          ...update,
          grid_plant_protection_available: value === false ? false : null,
        };
        break;
      case "tenant_system":
        if (value === false) {
          update = {
            ...update,
            n_tenants: null,
            n_additional_emeters: null,
            tenant_attachment: null,
            e_meter: false,
          };
        } else {
          update = { ...update, e_meter: true };
        }
        break;
      case "twopointeight_unavailability":
        if (value === true) {
          update = {
            ...update,
            twopointeight_unavailability: value,
            e_meter: true,
            twopointeight_emeterimage_set: [],
          };
        } else {
          update = {
            ...update,
            twopointeight_unavailability: value,
            e_meter: planning.tenant_system,
          };
        }
        break;
      case "slsswitch_unavailability":
        if (value === true) {
          update = {
            ...update,
            slsswitch_unavailability: value,
            slsswitch_emeterimage_set: [],
          };
        } else {
          update = { ...update, slsswitch_unavailability: value };
        }
        break;
      case "floors":
        if (value <= 1.5) {
          update = { ...update, floors: value, scaffoldingimage_set: [] };
        } else {
          update = { ...update, floors: value };
        }
        break;
      case "near_street":
        if (value) {
          update = { ...update, near_street: value };
        } else {
          update = { ...update, near_street: value, streetimage_set: [] };
        }
        break;
      default:
        break;
    }

    setPlanning((p) => ({ ...p, ...update }));
  };

  if (!planning || modules === null) return null;

  const module = modules.find((module) => module.id === planning.module);

  return (
    <Container style={{ marginTop: "10px" }}>
      <Row>
        <Col>
          <Form>
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Informationen zur Planung
            </Typography>

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">
                Mieterstromanlage:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.tenant_system}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("tenant_system", e, value)
                }
              >
                <CustomToggle id="no-tenant-system-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-tenant-system-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.tenant_system == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>
            <hr className="secondary-textcolor" />

            {/* ############################################################## */}

            {planning.tenant_system === true ? (
              <div>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Mieterstrom-Modell:
                  </Typography>
                  <br />
                  <div className="secondary-textcolor">
                    <Collapsible
                      trigger="[Info öffnen]"
                      triggerWhenOpen="[Info schließen]"
                    >
                      <>
                        Mieterstrommodelle:
                        <ul>
                          <li>
                            Installation: Der Kunde erwirbt die PV-Anlage und
                            die Messtechnik. Er verkauft den erzeugten Strom an
                            die Mieter und kümmert sich selbst um die
                            Abrechnung. Er ist verantwortlich für den Ausstausch
                            der Messtechnik alle acht Jahre sowie für den
                            Rückbau der Zähler von Mietern, die keinen PV-Strom
                            mehr beziehen wollen.
                          </li>
                          <li>
                            Installation + Abrechnung: Der Kunde erwirbt nur die
                            PV-Anlage. Die Messtechnik wird durch{" "}
                            {session.companyConfig.name} betrieben und
                            verwaltet. Der Kunde verkauft den PV-Strom an seine
                            Mieter, wobei die Abrechnung von{" "}
                            {session.companyConfig.name} übernommen wird. Dafür
                            fällt eine jährliche Pauschale pro Mieter an. Der
                            Austausch und Rückbau der Messtechnik wird
                            kostenfrei von {session.companyConfig.name}{" "}
                            durchgeführt.{" "}
                          </li>
                          <li>
                            Dachpachtung: Der Kunde verpachtet seine Dachfläche
                            an {session.companyConfig.name}. Es fallen keine
                            Kosten für die Installation der PV-Anlage bzw. der
                            Messtechnik an. {session.companyConfig.name}{" "}
                            verkauft den erzeugten Strom an die Mieter.
                          </li>
                        </ul>
                      </>
                    </Collapsible>
                  </div>
                  <br />
                  <DropDown
                    id="tenant-model-dropdown"
                    text="Modell wählen"
                    onChange={(value) => {
                      setPlanning((p) => ({ ...p, tenant_model: value }));
                      if (value === 0) {
                        setPlanning((p) => ({ ...p, smartmeter_rent: 10 }));
                      } else if (value === 1) {
                        setPlanning((p) => ({ ...p, smartmeter_rent: 0 }));
                      }
                    }}
                    value={planning.tenant_model}
                    options={tenantModelOptions}
                  />
                  <hr className="secondary-textcolor" />
                </FormGroup>

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Anzahl Mieter:
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    width={"50px"}
                    type="number"
                    name="n_tenants"
                    onChange={onChange}
                    value={defaultIfEmpty(planning.n_tenants)}
                    autoComplete="off"
                    invalid={
                      !!(showMissingFields && planning.n_tenants == null)
                    }
                    min={0}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                  />
                </FormGroup>
                <hr className="secondary-textcolor" />

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Anzahl zusätzlicher Zähler:
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    width={"50px"}
                    type="number"
                    name="n_additional_emeters"
                    onChange={onChange}
                    value={defaultIfEmpty(planning.n_additional_emeters)}
                    autoComplete="off"
                    invalid={
                      !!(
                        showMissingFields &&
                        planning.n_additional_emeters == null
                      )
                    }
                    min={0}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                  />
                </FormGroup>
                <hr className="secondary-textcolor" />

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Befestigung:
                  </Typography>
                  <ToggleButtonGroup
                    size="small"
                    value={planning.tenant_attachment}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange("tenant_attachment", e, value)
                    }
                  >
                    <CustomToggle
                      id="attachment-tenant-system-btn-1"
                      value={"eHZ"}
                    >
                      eHZ
                    </CustomToggle>
                    <CustomToggle
                      id="attachment-tenant-system-btn-2"
                      value={"Dreipunktbefestigung"}
                    >
                      Dreipunktbefestigung
                    </CustomToggle>
                  </ToggleButtonGroup>
                  {showMissingFields && planning.tenant_attachment == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </FormGroup>
                <hr className="secondary-textcolor" />
              </div>
            ) : null}

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">
                Anlagenerweiterung:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.plant_expansion}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("plant_expansion", e, value)
                }
              >
                <CustomToggle id="no-plant-expansion-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-plant-expansion-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.plant_expansion == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>
            <hr className="secondary-textcolor" />

            {/* ############################################################## */}

            {planning.plant_expansion === true ? (
              <div>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Datum der Inbetriebnahme der zuletzt angeschlossenen
                    Erzeugungsanlagen:
                  </Typography>
                  <br />
                  <DatePicker
                    ref={pickerRef1}
                    locale="de"
                    dateFormat="dd/MM/yyyy"
                    selected={
                      planning.commissioning_date_primary_plant
                        ? string2Date(planning.commissioning_date_primary_plant)
                        : null
                    }
                    onChange={(date) =>
                      setPlanning((p) => ({
                        ...p,
                        commissioning_date_primary_plant: date
                          ? date2String(date)
                          : null,
                      }))
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Gesamtmodulleistung der bestehen Erzeugeranlage in kVa:
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    width={"50px"}
                    type="number"
                    name="total_power_primary_plant_kVa"
                    onChange={onChange}
                    value={defaultIfEmpty(
                      planning.total_power_primary_plant_kVa,
                    )}
                    autoComplete="off"
                    invalid={
                      !!(
                        showMissingFields &&
                        planning.total_power_primary_plant_kVa == null
                      )
                    }
                    min={0}
                    onKeyDown={(e) => {
                      if (e.key === "-") e.preventDefault();
                    }}
                  />
                </FormGroup>
                <hr className="secondary-textcolor" />

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Zählpunktbezeichung der bestehende Erzeugungsanlage:
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    width={"50px"}
                    type="textarea"
                    name="metering_point_identifier_primary_plant"
                    onChange={onChange}
                    value={defaultIfEmpty(
                      planning.metering_point_identifier_primary_plant,
                    )}
                    autoComplete="off"
                    maxLength={150}
                  />
                </FormGroup>
                <hr className="secondary-textcolor" />

                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Vertragskontonummer des bestehenden Einspeisevertrags:{" "}
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    width={"50px"}
                    type="textarea"
                    name="contract_account_number_primary_plant"
                    onChange={onChange}
                    value={defaultIfEmpty(
                      planning.contract_account_number_primary_plant,
                    )}
                    autoComplete="off"
                    maxLength={150}
                  />
                </FormGroup>
                <hr className="secondary-textcolor" />
              </div>
            ) : null}

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">PV-Modul:</Typography>
              <br />
              <DropDown
                id="plantform-pvmodule-dropdown"
                text="PV-Modul wählen"
                onChange={(module) => setPlanning((p) => ({ ...p, module }))}
                value={planning.module}
                options={Object.values(modules).map((module) => ({
                  label: module.available
                    ? module.name
                    : `${module.name} (nicht verfügbar)`,
                  value: module.id,
                  disabled: !module.available,
                }))}
              />
              <hr className="secondary-textcolor" />
            </FormGroup>

            {/* ############################################################## */}

            {planning.tenant_system === false ? (
              <div>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Muss ein Zweirichtungszähler gesetzt werden?
                  </Typography>
                  <br />
                  <ToggleButtonGroup
                    size="small"
                    value={planning.e_meter}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange("e_meter", e, value)
                    }
                  >
                    <CustomToggle
                      id="no-bidirectional-counter-btn"
                      value={false}
                    >
                      Nein
                    </CustomToggle>
                    <CustomToggle
                      id="yes-bidirectional-counter-btn"
                      value={true}
                    >
                      Ja
                    </CustomToggle>
                    {showMissingFields && planning.e_meter == null ? (
                      <div>
                        &nbsp;&nbsp;
                        <ErrorOutlineOutlinedIcon
                          color="error"
                          fontSize="large"
                        />
                      </div>
                    ) : null}
                  </ToggleButtonGroup>
                </FormGroup>
                <hr className="secondary-textcolor" />
              </div>
            ) : null}

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">
                Muss ein neuer Zählerschrank gesetzt werden?
              </Typography>
              <br />
              <div className="secondary-textcolor">
                <Collapsible
                  trigger="[Info öffnen]"
                  triggerWhenOpen="[Info schließen]"
                >
                  <>
                    Gründe für neuen Zählerschrank:
                    <ul>
                      <li>
                        Der NAR Platz, der unterhalb des Zählers ist, ist
                        kleiner als 30cm hoch
                      </li>
                      <li>
                        Der Zähler ist nicht in einem Zählerschrank verbaut,
                        z.b. in einem kleinen Kasten oder auf einer Holztafel
                      </li>
                    </ul>
                  </>
                </Collapsible>
              </div>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.e_meter_cabinet}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("e_meter_cabinet", e, value)
                }
              >
                <CustomToggle id="no-meter-cabinet-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-meter-cabinet-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.e_meter_cabinet == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            {planning.e_meter_cabinet ? (
              <>
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Ausführung des Zählerschranks:
                  </Typography>
                  <br />
                  <div className="secondary-textcolor">
                    <Collapsible
                      trigger="[Info öffnen]"
                      triggerWhenOpen="[Info schließen]"
                    >
                      <p>
                        Ausführungen des Zählerschranks:
                        <ul>
                          <li>Normal: Zählerschrank mit einem Zählerplatz</li>
                          <li>
                            Gross: Zählerschrank mit zwei Zählerplätzen (z.b.
                            bei Voll-Einspeiser oder extra Wärmepumpen-Zähler)
                          </li>
                        </ul>
                      </p>
                    </Collapsible>
                  </div>
                  <br />
                  <ToggleButtonGroup
                    size="small"
                    value={planning.big_e_meter_cabinet}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange("big_e_meter_cabinet", e, value)
                    }
                  >
                    <CustomToggle id="normal-meter-cabinet-btn" value={false}>
                      Normal
                    </CustomToggle>
                    <CustomToggle id="gross-meter-cabinet-btn" value={true}>
                      Gross
                    </CustomToggle>
                    {showMissingFields &&
                    planning.big_e_meter_cabinet == null ? (
                      <div>
                        &nbsp;&nbsp;
                        <ErrorOutlineOutlinedIcon
                          color="error"
                          fontSize="large"
                        />
                      </div>
                    ) : null}
                  </ToggleButtonGroup>
                </FormGroup>
                <Typography className="secondary-textcolor">
                  Foto vom Haus Anschluss Kasten (HAK):
                </Typography>
                <br />
                <div className="secondary-textcolor">
                  <Collapsible
                    trigger="[Info öffnen]"
                    triggerWhenOpen="[Info schließen]"
                  >
                    <p>
                      Der neue Zählerschrank und der HAK dürfen maximal 7 Meter
                      voneinander entfernt sein. Das Kabel muss offen verlegt
                      sein. Der Netzbetreiber will zumeist den Zählerschrank
                      direkt neben dem HAK haben.
                    </p>
                  </Collapsible>
                </div>
                <UploadImages
                  images={planning.hakimage_set}
                  addImage={(img) => addImage("hakimage_set", img)}
                  deleteImage={(img) => deleteImage("hakimage_set", img)}
                />
                {showMissingFields && planning.hakimage_set.length === 0 ? (
                  <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                ) : null}
                <br />
              </>
            ) : null}

            {/* ############################################################## */}

            <FormGroup>
              <Typography className="secondary-textcolor">
                Ist eine Erdung mit einem Erdanker/Fundamenterder vorhanden?
              </Typography>
              <br />
              <div className="secondary-textcolor">
                <Collapsible
                  trigger="[Info öffnen]"
                  triggerWhenOpen="[Info schließen]"
                >
                  <p>
                    Nach der Norm muss jedes Haus in Deutschland extra geerdet
                    sein. Dies erkennt man an einem Erdungkabel was an einer
                    Potentialausgleichschiene hängt und dann mit einen Erdanker,
                    einem Tiefenerder oder einem Fundamenterder verbunden ist.
                    Oft ignoriert der Netzbetreiber aber diese Norm und man kann
                    sich das erstmal sparen. Die Planville bietet an dies, wenn
                    der Netzbetreiber das nicht akzeptiert, für den selben Preis
                    nachzurüsten.
                  </p>
                </Collapsible>
              </div>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.ground_anchor}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("ground_anchor", e, value)
                }
              >
                <CustomToggle id="nein-ground-anchor-btn" value={0}>
                  Nein
                </CustomToggle>
                <CustomToggle id="ja-ground-anchor-btn" value={1}>
                  Ja
                </CustomToggle>
                <CustomToggle id="nicht-gewünscht-ground-anchor-btn" value={2}>
                  Nicht gewünscht
                </CustomToggle>
                {showMissingFields && planning.ground_anchor == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Ist eine Wallbox vorhanden:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.wallbox}
                exclusive
                onChange={(e, value) => handleToggleChange("wallbox", e, value)}
              >
                <CustomToggle id="no-wallbox-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-wallbox-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.wallbox == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            {planning.wallbox === false ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Möchte der Kunde eine Wallbox erwerben:
                </Typography>
                <br />
                <Input
                  style={{ width: "30%", boxShadow: "none" }}
                  id="input"
                  type="number"
                  name="wallbox_required"
                  onChange={onChange}
                  value={defaultIfEmpty(planning.wallbox_required)}
                  autoComplete="off"
                  invalid={
                    !!(showMissingFields && planning.wallbox_required == null)
                  }
                  min={0}
                  onKeyDown={(e) => {
                    if (e.key === "-") e.preventDefault();
                  }}
                />
              </FormGroup>
            ) : null}

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Ist ein E-Auto vorhanden:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.ecar}
                exclusive
                onChange={(e, value) => handleToggleChange("ecar", e, value)}
              >
                <CustomToggle id="no-ecar-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-ecar-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.ecar == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            {planning.ecar === false ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Interesse an einem E-Auto?
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={planning.ecar_interest}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("ecar_interest", e, value)
                  }
                >
                  <CustomToggle id="no-ecar-interest-btn" value={false}>
                    Nein
                  </CustomToggle>
                  <CustomToggle id="yes-ecar-interest-btn" value={true}>
                    Ja
                  </CustomToggle>
                  {showMissingFields && planning.ecar_interest == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
              </FormGroup>
            ) : (
              <Fragment></Fragment>
            )}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Soll Energie-Impuls hinzugefügt werden?
              </Typography>
              <br />
              <div className="secondary-textcolor">
                <Collapsible
                  trigger="[Info öffnen]"
                  triggerWhenOpen="[Info schließen]"
                >
                  <p>
                    Energie-Impuls ist ein Energiemanagementsystem (EMS), das
                    eine automatisierte Steuerung des Energieflusses zuhause
                    ermöglicht. Das EMS vernetzt Photovoltaikanlage,
                    Wechselrichter, Wärmepumpe, Wallbox und Energiespeicher, um
                    die erzeugte Energie möglichst effizient im Heimnetz zu
                    verteilen.
                    <br />
                    <br />
                    Funktionen (teilweise erst mit künftigen Softwareupdates
                    verfügbar):
                    <ol>
                      <li>
                        Übersicht der Energieflüsse aller Komponenten in einer
                        App
                      </li>
                      <li>
                        Ansteuerung der Wallboxen für PV-Überschuss-Laden und
                        Lastmanagement
                      </li>
                      <li>
                        Ansteuerung der Wolf Wärmepumpe zur Einstellung der
                        Betriebszustände „Normalbetrieb“ bzw. „Einschaltbefehl
                        (PV-Anhebung aktiv)“
                      </li>
                      <li>
                        Anpassung an aktuelle und künftige Wetterbedingungen
                        mittels Wetterdaten, um die Leistung der PV-Anlage
                        optimal für die Wärmepumpe zu nutzen (bald verfügbar)
                      </li>
                      <li>
                        Preissignalfähigkeit des Energiespeichers (bald
                        verfügbar)
                      </li>
                    </ol>
                  </p>
                </Collapsible>
              </div>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.impuls_interest}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("impuls_interest", e, value)
                }
              >
                <CustomToggle id="no-ecar-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-ecar-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.impuls_interest == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}
            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Aktuelles Heizsystem:
              </Typography>
              <br />
              <DropDown
                id="heating-system-dropdown"
                text="Aktuelles Heizsystem wählen"
                onChange={(value) =>
                  setPlanning((p) => ({ ...p, heating_system: value }))
                }
                value={planning.heating_system}
                options={heatingSystemOptions}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Ist eine Wärmepumpe vorhanden:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.heatpump}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("heatpump", e, value)
                }
              >
                <CustomToggle id="no-heatpump-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-heatpump-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.heatpump == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            {planning.heatpump === false ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Interesse an einer Wärmepumpe?
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={planning.heatpump_interest}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("heatpump_interest", e, value)
                  }
                >
                  <CustomToggle id="no-heatpump-interest-btn" value={false}>
                    Nein
                  </CustomToggle>
                  <CustomToggle id="yes-heatpump-interest-btn" value={true}>
                    Ja
                  </CustomToggle>
                  {showMissingFields && planning.heatpump_interest == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
              </FormGroup>
            ) : (
              <Fragment></Fragment>
            )}

            {/* ############################################################## */}

            {planning.heatpump === false ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Interesse an einer Brauchwasser-Wärmepumpe?
                </Typography>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={planning.service_water_heatpump_interest}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange(
                      "service_water_heatpump_interest",
                      e,
                      value,
                    )
                  }
                >
                  <CustomToggle
                    id="no-service-water-heatpump-interest-btn"
                    value={false}
                  >
                    Nein
                  </CustomToggle>
                  <CustomToggle
                    id="yes-service-water-heatpump-interest-btn"
                    value={true}
                  >
                    Ja
                  </CustomToggle>
                  {showMissingFields &&
                  planning.service_water_heatpump_interest == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
              </FormGroup>
            ) : (
              <Fragment></Fragment>
            )}

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            {!planning.wallbox_required ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Schaltungsvorbereitung Wallbox + Wärmepumpe:
                </Typography>
                <br />
                <div className="secondary-textcolor">
                  <Collapsible
                    trigger="[Info öffnen]"
                    triggerWhenOpen="[Info schließen]"
                  >
                    <p>
                      Wenn eine Wallbox UND eine Wärmepumpe im Haushalt
                      existiert ODER in Zukunft hinzugefügt werden soll, macht
                      das Sinn, dass wir schon jetzt die Vorbereitung dafür
                      machen.
                    </p>
                  </Collapsible>
                </div>
                <br />
                <ToggleButtonGroup
                  size="small"
                  value={planning.circuit_preparation}
                  exclusive
                  onChange={(e, value) =>
                    handleToggleChange("circuit_preparation", e, value)
                  }
                >
                  <CustomToggle id="no-circuit-preparation-btn" value={false}>
                    Nein
                  </CustomToggle>
                  <CustomToggle id="yes-circuit-preparation-btn" value={true}>
                    Ja
                  </CustomToggle>
                  {showMissingFields && planning.circuit_preparation == null ? (
                    <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon
                        color="error"
                        fontSize="large"
                      />
                    </div>
                  ) : null}
                </ToggleButtonGroup>
                <hr className="secondary-textcolor" />
              </FormGroup>
            ) : null}

            {/* ############################################################## */}
            <FormGroup>
              <Typography className="secondary-textcolor">
                Große Verbraucher im Haushalt:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.large_household_consumers}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("large_household_consumers", e, value)
                }
              >
                <CustomToggle
                  id="no-large-household-consumers-btn"
                  value={false}
                >
                  Nein
                </CustomToggle>
                <CustomToggle
                  id="yes-large-household-consumers-btn"
                  value={true}
                >
                  Ja
                </CustomToggle>
                {showMissingFields &&
                planning.large_household_consumers == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notstromdosen:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.emergency_socket}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("emergency_socket", e, value)
                }
              >
                <CustomToggle id="no-emergency-socket-btn" value={0}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-emergency-socket-btn" value={1}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.emergency_socket == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Ersatzstromsystem:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.emergency_house}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("emergency_house", e, value)
                }
              >
                <CustomToggle id="no-emergency-house-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-emergency-house-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.emergency_house == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Kunde montiert PV-Anlage:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.pv_setbycustomer}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("pv_setbycustomer", e, value)
                }
              >
                <CustomToggle id="no-pv-setbycustomer-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-pv-setbycustomer-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.pv_setbycustomer == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Dachpfannen vorhanden? (mind. 15 bei Tonpfannen, 5 bei
                Betonpfannen)
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.rooftiles}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("rooftiles", e, value)
                }
              >
                <CustomToggle id="no-rooftiles-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-rooftiles-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.rooftiles == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Zusammenlegung von zwei Stromkreisen:
              </Typography>
              <br />
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                type="number"
                name="merge_circuits"
                onChange={onChange}
                value={defaultIfEmpty(planning.merge_circuits)}
                autoComplete="off"
                invalid={
                  !!(showMissingFields && planning.merge_circuits == null)
                }
                min={0}
                onKeyDown={(e) => {
                  if (e.key === "-") e.preventDefault();
                }}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <div>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Stromverbrauch Haus in kWh p.a.:
                </Typography>
                <Input
                  style={{ width: "30%", boxShadow: "none" }}
                  id="input"
                  type="number"
                  name="consumption_house"
                  onChange={onChange}
                  value={defaultIfEmpty(planning.consumption_house)}
                  autoComplete="off"
                  invalid={
                    !!(showMissingFields && planning.consumption_house == null)
                  }
                  min={0}
                  onKeyDown={(e) => {
                    if (e.key === "-") e.preventDefault();
                  }}
                />
              </FormGroup>
            </div>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Stromverbrauch Wärmepumpe in kWh p.a.:
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="number"
                name="consumption_heatpump"
                onChange={onChange}
                value={defaultIfEmpty(planning.consumption_heatpump)}
                autoComplete="off"
                invalid={
                  !!(showMissingFields && planning.consumption_heatpump == null)
                }
                min={0}
                onKeyDown={(e) => {
                  if (e.key === "-") e.preventDefault();
                }}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Stromverbrauch E-Auto in kWh p.a.:
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="number"
                name="consumption_car"
                onChange={onChange}
                value={defaultIfEmpty(planning.consumption_car)}
                autoComplete="off"
                invalid={
                  !!(showMissingFields && planning.consumption_car == null)
                }
                min={0}
                onKeyDown={(e) => {
                  if (e.key === "-") e.preventDefault();
                }}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Anzahl Durchlauferhitzer:
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="number"
                name="water_heater"
                onChange={onChange}
                value={defaultIfEmpty(planning.water_heater)}
                autoComplete="off"
                min={0}
                onKeyDown={(e) => {
                  if (e.key === "-") e.preventDefault();
                }}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                PV-Anlage über Wintergarten: (optional)
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.above_winter_garden}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("above_winter_garden", e, value)
                }
              >
                <CustomToggle id="no-above-winter-garden-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-above-winter-garden-btn" value={true}>
                  Ja
                </CustomToggle>
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Anzahl Etagen:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={
                  floorsOptions
                    .map((floor) => floor.value)
                    .includes(planning.floors)
                    ? planning.floors
                    : planning.floors === null
                      ? null
                      : ""
                }
                exclusive
                onChange={(e, value) => handleToggleChange("floors", e, value)}
              >
                {floorsOptions.map((floor, index) => (
                  <CustomToggle
                    key={`floor_${index}`}
                    id={`floor_${index}_btn`}
                    value={floor.value}
                  >
                    {floor.label}
                  </CustomToggle>
                ))}
                <CustomToggle id="custom-floor-btn" value={""}>
                  Benutzerdefiniert
                </CustomToggle>
              </ToggleButtonGroup>
              <br />
              {!floorsOptions
                .map((floor) => floor.value)
                .includes(planning.floors) &&
                planning.floors !== null && (
                  <>
                    <br />
                    <Input
                      style={{ width: "30%", boxShadow: "none" }}
                      id="input"
                      width={"50px"}
                      type="number"
                      name="floors"
                      onChange={onChange}
                      value={defaultIfEmpty(planning.floors)}
                      autoComplete="off"
                      min={0}
                      onKeyDown={(e) => {
                        if (e.key === "-") e.preventDefault();
                      }}
                    />
                  </>
                )}
              {showMissingFields &&
              (planning.floors == null || planning.floors === "") ? (
                <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                </div>
              ) : null}
              {planning.floors > 1.5 && planning.floors && (
                <>
                  <br />
                  <Typography className="secondary-textcolor">
                    Gerüst Fotos, Hausansicht
                  </Typography>
                  <UploadImages
                    images={planning.scaffoldingimage_set}
                    addImage={(img) => addImage("scaffoldingimage_set", img)}
                    deleteImage={(img) =>
                      deleteImage("scaffoldingimage_set", img)
                    }
                  />
                  {showMissingFields &&
                  planning.scaffoldingimage_set.length === 0 &&
                  planning.floors > 1.5 ? (
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  ) : null}
                </>
              )}
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Steht das Haus in einer Stadt oder ist sehr nah an der Straße
                gebaut?
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.near_street}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("near_street", e, value)
                }
              >
                <CustomToggle id="no-near-street-city-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-near-street-city-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.near_street == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
              <br />
              {planning.near_street === true && (
                <>
                  <br />
                  <Typography className="secondary-textcolor">
                    Fotos von Straße und Haus
                  </Typography>
                  <UploadImages
                    images={planning.streetimage_set}
                    addImage={(img) => addImage("streetimage_set", img)}
                    deleteImage={(img) => deleteImage("streetimage_set", img)}
                  />
                  {showMissingFields &&
                  planning.streetimage_set.length === 0 &&
                  planning.near_street ? (
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  ) : null}
                </>
              )}
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notizen zum Kabel: (optional)
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="textarea"
                name="notes_cable"
                onChange={onChange}
                value={defaultIfEmpty(planning.notes_cable)}
                autoComplete="off"
                maxLength={150}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notizen zum Gerüst: (optional)
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"50px"}
                type="textarea"
                name="notes_frame"
                onChange={onChange}
                value={defaultIfEmpty(planning.notes_frame)}
                autoComplete="off"
                maxLength={1000}
              />
              <hr className="secondary-textcolor" />
            </FormGroup>

            {/* ############################################################## */}

            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Optimierung
            </Typography>
            <br />
            <Optimization
              planning={planning}
              setPlanning={setPlanning}
              session={session}
            />
            <hr className="secondary-textcolor" />

            {/* ############################################################## */}

            {project.voltavodata_obj ? (
              <>
                <VoltavoData
                  voltavoData={project.voltavodata_obj}
                  session={session}
                />
              </>
            ) : null}

            {/* ############################################################## */}

            <FormGroup>
              <Typography
                fontSize="h5.fontSize"
                className="secondary-textcolor"
              >
                Batterie
              </Typography>
              {planning.batterykwh_set.map((battery, index) => (
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  key={`battery_row_${index}`}
                  sx={{ marginTop: "0px" }}
                >
                  <Grid item>
                    <Typography
                      key={`battery_kwh_${index}`}
                      className="secondary-textcolor"
                    >
                      {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <DropDown
                      id="plantform-battery-dropdown"
                      key={`battery_kwh_${index}_${battery.kwh}`}
                      onChange={(value) => updateBattery(index, value)}
                      text="Batterie wählen"
                      options={batteryOptions}
                      value={battery.kwh}
                    />
                  </Grid>
                  {index !== 0 && (
                    <Grid item>
                      <CustomIconButton
                        icon="delete"
                        iconClassName="IconDeleteButton"
                        color="transparant"
                        onClick={() => deleteBattery(index)}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
              <Button
                variant="outlined"
                color="inherit"
                onClick={addBattery}
                sx={{ marginTop: "10px" }}
              >
                Weitere Batterie
              </Button>
              {showMissingFields && planning.battery_kwh == null ? (
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              ) : null}
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Geplante PV-Panels
            </Typography>
            <br />
            <FormGroup width={"30px"}>
              <Typography
                id="n-vertical-panel-text"
                className="secondary-textcolor"
              >
                Anzahl vertikaler Panels:{" "}
                <strong>{planning.n_vertical_panels}</strong>
              </Typography>
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup width={"30px"}>
              <Typography
                id="n-horizontal-panel-text"
                className="secondary-textcolor"
              >
                Anzahl horizontaler Panels:{" "}
                <strong>{planning.n_horizontal_panels}</strong>
              </Typography>
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup width={"30px"}>
              <Typography
                id="n-optimizers-text"
                className="secondary-textcolor"
              >
                Anzahl optimierer Panels:{" "}
                <strong>{planning.n_optimizers}</strong>
              </Typography>
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup width={"30px"}>
              <Typography className="secondary-textcolor">
                kWp der PV-Anlage: <strong>{round(planning.kwp, 3)}</strong>{" "}
              </Typography>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Dachfotos
            </Typography>
            <br />

            {/* ############################################################## */}

            {module ? (
              <>
                <FormGroup width={"30px"}>
                  <div>
                    {planning.roofimage_set.map((roofImage) => {
                      const kwp = round(
                        roofImage.roofimagepanel_set.length * module.kwp,
                        3,
                      );
                      return (
                        <div key={`roof_image_${roofImage.id}`}>
                          {roofImage.name && (
                            <Typography className="secondary-textcolor">
                              <strong>{roofImage.name} </strong>
                            </Typography>
                          )}
                          <Typography
                            id="roof-image-direction-text"
                            className="secondary-textcolor"
                          >
                            Himmelsrichtung:{" "}
                            <strong>{roofImage.direction} </strong>
                          </Typography>
                          <Typography
                            id="roof-image-flat-roof-text"
                            className="secondary-textcolor"
                          >
                            Dachtyp:{" "}
                            <strong>
                              {roofImage.flat_roof ? "Flachdach" : "Satteldach"}{" "}
                            </strong>
                          </Typography>
                          <Typography className="secondary-textcolor">
                            kWp:{" "}
                            <strong>
                              {module ? kwp : "Modul noch nicht ausgewählt"}
                            </strong>
                          </Typography>
                          <br />
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => deleteRoofImage(roofImage.id)}
                          >
                            Bild löschen
                          </Button>
                          <br />
                          <br />
                          <br />
                          <Stack
                            direction={isMobileOnly ? "column" : "row"}
                            spacing={2}
                          >
                            <RoofImage
                              roofImage={roofImage}
                              module={module}
                              width={round(
                                (isMobileOnly ? 0.9 : 0.43) * window.innerWidth,
                              )}
                            />
                            <ImageStage
                              image={roofImage.house_image}
                              width={round(
                                (isMobileOnly ? 0.9 : 0.43) * window.innerWidth,
                              )}
                            />
                          </Stack>
                          <br />
                          <EditRoofImagePlanning
                            image={roofImage.image}
                            imageWidthM={roofImage.image_width_m}
                            module={module}
                            panels={roofImage.roofimagepanel_set}
                            angle={roofImage.angle}
                            direction={roofImage.direction}
                            flatRoof={roofImage.flat_roof}
                            setAll={(
                              panels,
                              currentStrings,
                              currentAngle,
                              currentDirection,
                              currentFlatRoof,
                            ) =>
                              updateRoofImage(
                                roofImage.id,
                                panels,
                                currentStrings,
                                currentAngle,
                                currentDirection,
                                currentFlatRoof,
                              )
                            }
                            winWidth={window.innerWidth}
                            winHeight={window.innerHeight}
                            roofName={roofImage.name}
                          />
                          <hr className="secondary-textcolor" />
                        </div>
                      );
                    })}
                  </div>
                  <RoofImageUpload
                    addRoofImage={addRoofImage}
                    module={module}
                    project={project}
                    winWidth={window.innerWidth}
                    winHeight={window.innerHeight}
                    session={session}
                  />
                </FormGroup>
              </>
            ) : // {project.voltavodata_obj
            //   ? <>
            //     <hr className='secondary-textcolor' />
            //     <Typography fontSize='h5.fontSize' className='secondary-textcolor'>Dachfotos</Typography>
            //     <br />
            //     <FormGroup>
            //       <RoofImageAreaPlanning
            //         image={project.voltavodata_obj.rgb_image}
            //         polygons={planning.voltavopolygon_set}
            //       />
            //     </FormGroup>
            //     <FormGroup>
            //       <EditRoofImageAreaPlanning
            //         image={project.voltavodata_obj.rgb_image}
            //         polygons={planning.voltavopolygon_set}
            //         setPolygons={(polygons) => setPlanning(p => ({ ...p, voltavopolygon_set: polygons }))}
            //       />
            //     </FormGroup>
            //   </>
            //   : null}

            null}

            {/* ############################################################## */}

            {planning.tenant_system === true || planning.kwp >= 30 ? (
              <div>
                <hr className="secondary-textcolor" />
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    Wandlermessung vorhanden?
                  </Typography>
                  <br />
                  <ToggleButtonGroup
                    size="small"
                    value={planning.converter_measurement_available}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange(
                        "converter_measurement_available",
                        e,
                        value,
                      )
                    }
                  >
                    <CustomToggle
                      id="no-converter-measurement-btn"
                      value={false}
                    >
                      Nein
                    </CustomToggle>
                    <CustomToggle
                      id="yes-converter-measurement-btn"
                      value={true}
                    >
                      Ja
                    </CustomToggle>
                    {showMissingFields &&
                    planning.converter_measurement_available == null ? (
                      <div>
                        &nbsp;&nbsp;
                        <ErrorOutlineOutlinedIcon
                          color="error"
                          fontSize="large"
                        />
                      </div>
                    ) : null}
                  </ToggleButtonGroup>
                </FormGroup>
              </div>
            ) : null}

            {planning.converter_measurement_available === true &&
            planning.kwp >= 30 ? (
              <div>
                <hr className="secondary-textcolor" />
                <FormGroup>
                  <Typography className="secondary-textcolor">
                    NA-Schutz vorhanden?
                  </Typography>
                  <br />
                  <ToggleButtonGroup
                    size="small"
                    value={planning.grid_plant_protection_available}
                    exclusive
                    onChange={(e, value) =>
                      handleToggleChange(
                        "grid_plant_protection_available",
                        e,
                        value,
                      )
                    }
                  >
                    <CustomToggle
                      id="no-grid-plant-protection-btn"
                      value={false}
                    >
                      Nein
                    </CustomToggle>
                    <CustomToggle
                      id="yes-grid-plant-protection-btn"
                      value={true}
                    >
                      Ja
                    </CustomToggle>
                    {showMissingFields &&
                    planning.grid_plant_protection_available == null ? (
                      <div>
                        &nbsp;&nbsp;
                        <ErrorOutlineOutlinedIcon
                          color="error"
                          fontSize="large"
                        />
                      </div>
                    ) : null}
                  </ToggleButtonGroup>
                </FormGroup>
              </div>
            ) : null}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Maximalbelegung gewünscht:
              </Typography>
              <br />
              <div className="secondary-textcolor">
                <Collapsible
                  trigger="[Info öffnen]"
                  triggerWhenOpen="[Info schließen]"
                >
                  <p>
                    Wünscht der Kunde die maximal mögliche Anzahl an Modulen /
                    maximale kWp?
                  </p>
                </Collapsible>
              </div>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.maximum_occupancy}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("maximum_occupancy", e, value)
                }
              >
                <CustomToggle id="no-maximum-occupancy-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-maximum-occupancy-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.maximum_occupancy == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Satellitenschüssel verschieben:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.move_satellite_dish}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("move_satellite_dish", e, value)
                }
              >
                <CustomToggle id="no-move-satellite-dish-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-move-satellite-dish-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.move_satellite_dish == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Kabel kann einfach verlegt werden:
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.easy_cable}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("easy_cable", e, value)
                }
              >
                <CustomToggle id="no-easy-cable-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-easy-cable-btn" value={true}>
                  Ja
                </CustomToggle>
                {showMissingFields && planning.easy_cable == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Spezialmontage (z.B. Flachdach, Gaube):
              </Typography>
              <br />
              <ToggleButtonGroup
                size="small"
                value={planning.special_installation}
                exclusive
                onChange={(e, value) =>
                  handleToggleChange("special_installation", e, value)
                }
              >
                <CustomToggle id="no-special-installation-btn" value={false}>
                  Nein
                </CustomToggle>
                <CustomToggle id="yes-special-installation-btn" value={true}>
                  Ja{" "}
                </CustomToggle>
                {showMissingFields && planning.special_installation == null ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </ToggleButtonGroup>
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Anzahl Wechselrichter:
              </Typography>
              <br />
              <DropDown
                id="plantform-number-of-inverters-dropdown"
                text="Anzahl Wechselrichter"
                onChange={(nInverters) =>
                  setPlanning((p) => ({ ...p, n_inverters: nInverters }))
                }
                value={
                  planning.n_inverters != null
                    ? planning.n_inverters
                    : planning.kwp >= 17
                      ? 3
                      : planning.kwp >= 13
                        ? 2
                        : 1
                }
                options={[1, 2, 3]}
              />
            </FormGroup>

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <Typography className="secondary-textcolor">
              Montageort Wechselrichter
            </Typography>
            <UploadImages
              images={planning.inverterlocationimage_set}
              addImage={(img) => addImage("inverterlocationimage_set", img)}
              deleteImage={(img) =>
                deleteImage("inverterlocationimage_set", img)
              }
            />
            {showMissingFields &&
            planning.inverterlocationimage_set.length === 0 ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            {planning.battery_kwh && planning.battery_kwh > 0 ? (
              <div>
                <Typography className="secondary-textcolor">
                  Standort Batterie
                </Typography>
                <UploadImages
                  images={planning.batterylocationimage_set}
                  addImage={(img) => addImage("batterylocationimage_set", img)}
                  deleteImage={(img) =>
                    deleteImage("batterylocationimage_set", img)
                  }
                />
                {showMissingFields &&
                planning.batterylocationimage_set.length === 0 ? (
                  <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                ) : null}
                <br />
              </div>
            ) : null}

            {planning.e_meter_cabinet ? (
              <div>
                <Typography className="secondary-textcolor">
                  Montageort neuer Zählerschrank
                </Typography>
                <UploadImages
                  images={planning.emetercabinetlocationimage_set}
                  addImage={(img) =>
                    addImage("emetercabinetlocationimage_set", img)
                  }
                  deleteImage={(img) =>
                    deleteImage("emetercabinetlocationimage_set", img)
                  }
                />
                {showMissingFields &&
                planning.emetercabinetlocationimage_set.length === 0 ? (
                  <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                ) : null}
                <br />
              </div>
            ) : null}

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Zählerschrank und Umgebung
            </Typography>
            <br />
            <UploadImages
              id="emeter-cabinet-image-set"
              images={planning.emetercabinetimage_set}
              addImage={(img) => addImage("emetercabinetimage_set", img)}
              deleteImage={(img) => deleteImage("emetercabinetimage_set", img)}
            />
            {showMissingFields &&
            planning.emetercabinetimage_set.length === 0 ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Hausanschlusskasten
            </Typography>
            <br />
            <UploadImages
              images={planning.houseconnectionimage_set}
              addImage={(img) => addImage("houseconnectionimage_set", img)}
              deleteImage={(img) =>
                deleteImage("houseconnectionimage_set", img)
              }
            />
            {showMissingFields &&
            planning.houseconnectionimage_set.length === 0 ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />

            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Zähler
            </Typography>
            <br />

            <Typography className="secondary-textcolor">
              1.8.0 Inklusive Zählernummer
            </Typography>
            <UploadImages
              id="one-point-eight-emeter-image-set"
              images={planning.onepointeight_emeterimage_set}
              addImage={(img) => addImage("onepointeight_emeterimage_set", img)}
              deleteImage={(img) =>
                deleteImage("onepointeight_emeterimage_set", img)
              }
            />
            {showMissingFields &&
            planning.onepointeight_emeterimage_set.length === 0 ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            <Typography className="secondary-textcolor">
              2.8.0 Inklusive Zählernummer
            </Typography>
            <br />
            <CustomToggle
              id="two-point-eight-available-btn"
              value={planning.twopointeight_unavailability}
              selected={planning.twopointeight_unavailability}
              onChange={(e, value) =>
                handleToggleChange("twopointeight_unavailability", e, !value)
              }
            >
              2.8.0 nicht vorhanden
            </CustomToggle>
            <br />
            {!planning.twopointeight_unavailability && (
              <UploadImages
                images={planning.twopointeight_emeterimage_set}
                addImage={(img) =>
                  addImage("twopointeight_emeterimage_set", img)
                }
                deleteImage={(img) =>
                  deleteImage("twopointeight_emeterimage_set", img)
                }
              />
            )}
            {showMissingFields &&
            planning.twopointeight_emeterimage_set.length === 0 &&
            !planning.twopointeight_unavailability ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            <Typography className="secondary-textcolor">
              SLS Schalter
            </Typography>
            <br />
            <CustomToggle
              id="sls-switch-available-btn"
              value={planning.slsswitch_unavailability}
              selected={planning.slsswitch_unavailability}
              onChange={(e, value) =>
                handleToggleChange("slsswitch_unavailability", e, !value)
              }
            >
              SLS Schalter nicht vorhanden
            </CustomToggle>
            <br />
            {!planning.slsswitch_unavailability && (
              <UploadImages
                images={planning.slsswitch_emeterimage_set}
                addImage={(img) => addImage("slsswitch_emeterimage_set", img)}
                deleteImage={(img) =>
                  deleteImage("slsswitch_emeterimage_set", img)
                }
              />
            )}
            {showMissingFields &&
            planning.slsswitch_emeterimage_set.length === 0 &&
            !planning.slsswitch_unavailability ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            <Typography className="secondary-textcolor">
              Zähler allgemein (optional)
            </Typography>
            <UploadImages
              images={planning.additional_emeterimage_set}
              addImage={(img) => addImage("additional_emeterimage_set", img)}
              deleteImage={(img) =>
                deleteImage("additional_emeterimage_set", img)
              }
            />
            <br />
            <hr className="secondary-textcolor" />

            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Dachziegel
            </Typography>
            <br />
            <UploadImages
              id="rooftileimage-set"
              images={planning.rooftileimage_set}
              addImage={(img) => addImage("rooftileimage_set", img)}
              deleteImage={(img) => deleteImage("rooftileimage_set", img)}
            />
            {showMissingFields && planning.rooftileimage_set.length === 0 ? (
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            ) : null}
            <br />

            {/* ############################################################## */}

            <hr className="secondary-textcolor" />
            <FormGroup>
              <Typography className="secondary-textcolor">
                Notizen: (optional)
              </Typography>
              <Input
                style={{ width: "30%", boxShadow: "none" }}
                id="input"
                width={"70px"}
                type="textarea"
                name="notes"
                onChange={onChange}
                value={defaultIfEmpty(planning.notes)}
                autoComplete="off"
                maxLength={1500}
              />
            </FormGroup>

            {/* ############################################################## */}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

PlantPlanningForm.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  modules: PropTypes.arrayOf(modulePropType),
  showMissingFields: PropTypes.bool,
  // voltavoData: voltavoDataPropType,
  session: PropTypes.object,
};
