import React, { Fragment } from "react";

import { PropTypes } from "prop-types";
import { FormGroup } from "reactstrap";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";
import { CustomToggle } from "../../elements/StyledElements";

import {
  customProductPropType,
  invoicePropType,
  productPropType,
  projectPropType,
} from "../../elements/PropTypes";
import CustomProductItems from "../products/CustomProductItems";

export default function InvoiceForm({
  project,
  invoice,
  setInvoice,
  customProducts,
  setCustomProducts,
  products,
  productGroups,
  resetProducts,
  amountCols,
  session,
}) {
  const allVatValue = (project, invoice) => {
    if (project.baseoffer_set.length === 0 && invoice.vat == null) {
      invoice.vat = false;
      return invoice.vat;
    } // Blank Invoice
    if (invoice.vat !== null) {
      // invoice exist
      return invoice.vat;
    }
    return project.baseoffer_set.find((offer) => offer.accepted).vat; // invoice doesn't exist
  };

  const vatValue = allVatValue(project, invoice);

  const handleVatChange = (e, val) => {
    if (val !== null) {
      setInvoice({ ...invoice, vat: val });
      if (val === true) {
        const newCustomProducts = customProducts.map((cp) => {
          return { ...cp, vat: val };
        });
        setCustomProducts(newCustomProducts);
      } else {
        const newCustomProducts = customProducts.map((cp) => {
          const product = products.find((p) => p.id === cp.product);
          return { ...cp, vat: product.vat };
        });
        setCustomProducts(newCustomProducts);
      }
    }
  };

  return (
    <Fragment>
      <CustomProductItems
        invoice={invoice}
        customProducts={customProducts}
        setCustomProducts={setCustomProducts}
        session={session}
        customer={project.customer_obj}
        products={products}
        project={project}
        productGroups={productGroups}
        amountCols={amountCols}
        resetProducts={resetProducts}
        isDraggable={true}
        showDescriptions={true}
        stage={"invoice"}
      />
      <div>
        <br />
      </div>
      <FormGroup>
        <Typography className="secondary-textcolor">
          Alle Produkte mit MwSt.:
        </Typography>
        <ToggleButtonGroup
          size="small"
          value={vatValue}
          exclusive
          onChange={handleVatChange}
        >
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
        </ToggleButtonGroup>
      </FormGroup>
    </Fragment>
  );
}

InvoiceForm.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  setInvoice: PropTypes.func,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.array,
  resetProducts: PropTypes.func,
  amountCols: PropTypes.object,
  session: PropTypes.object,
};
