import React, { Fragment, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";
import { hasPermission } from "../elements/utils";
import WarehouseActivities from "./warehouse/WarehouseActivity";
import DropDown from "../elements/DropDown";
import axios from "axios";
import WarehouseStockModal from "./warehouse/WarehouseStockModal";
import WarehouseBulkUpdate from "./warehouse/WarehouseBulkUpdateModal";
import WarehouseDelivery from "./warehouse/WarehouseDeliveryModal";
import WarehouseUsedMaterialModal from "./warehouse/WarehouseUsedMaterialModal";
import {
  API_URL_USER,
  API_URL_WAREHOUSEPRODUCT,
  API_URL_WAREHOUSEPRODUCTTYPE,
  API_URL_WAREHOUSESUPPLIER,
  API_URL_PRODUCT,
  API_URL_WAREHOUSEADDRESS,
} from "../settings";
import WarehouseDeliverySupplier from "./warehouse/WarehouseDeliverySupplierModal";

export default function Warehouse({ session }) {
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(
    !session.user.visible ? null : session.user.id,
  );
  const [warehouseProducts, setWarehouseProducts] = useState([]);
  const [warehouseProductTypes, setWarehouseProductTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);

  const getDefaultSupplierInfo = (warehouseProduct) => {
    const defaultSupplierId = warehouseProduct.amounts.find(
      (amount) => amount.location_id === location,
    );
    const defaultSupplier = warehouseProduct.suppliers.find(
      (supplier) => supplier.id === defaultSupplierId?.active_supplier_id,
    );
    warehouseProduct.supplier_id = defaultSupplier?.supplier_id;
    warehouseProduct.supplier_name = suppliers.find(
      (supplier) => supplier.id === defaultSupplier?.supplier_id,
    )?.name;
    warehouseProduct.supplier_product_name = defaultSupplier?.product_name;
    warehouseProduct.supplier_product_id = defaultSupplier?.product_id;
    warehouseProduct.supplier_price = defaultSupplier?.price;
    return warehouseProduct;
  };

  const resetState = () => {
    axios.get(API_URL_WAREHOUSEPRODUCTTYPE).then((res) => {
      setWarehouseProductTypes(res.data);
    });
    axios.get(API_URL_WAREHOUSESUPPLIER).then((res) => {
      setSuppliers(res.data);
    });
    axios.get(API_URL_PRODUCT).then((res) => {
      setProducts(res.data);
    });
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, is_active: true, visible: true },
      })
      .then((res) => {
        setEmployees(res.data);
      });
    axios.get(API_URL_WAREHOUSEPRODUCT).then((result) => {
      setWarehouseProducts(result.data);
    });
  };

  useEffect(() => {
    axios.get(API_URL_WAREHOUSEADDRESS).then((res) => {
      setLocations(res.data);
    });
    resetState();
  }, []);

  return (
    <Fragment>
      <Container>
        <Stack>
          <Stack direction="row">
            <DropDown
              onChange={setLocation}
              options={locations.map((loc) => ({
                label: loc.name,
                value: loc.id,
              }))}
              value={location}
              text="Standort"
              search={true}
              style={{ margin: "8px 0", width: "100%" }}
            />
          </Stack>
          <Stack direction="row">
            <DropDown
              onChange={setEmployee}
              options={employees.map((user) => ({
                label: user.first_name + " " + user.last_name,
                value: user.id,
              }))}
              value={employee}
              text="Für Mitarbeiter"
              sort={true}
              search={true}
              style={{ margin: "8px 0", width: "100%" }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <WarehouseBulkUpdate
              session={session}
              type="subtract"
              location={location}
              warehouseProducts={warehouseProducts.map(getDefaultSupplierInfo)}
              warehouseProductTypes={warehouseProductTypes}
              suppliers={suppliers}
              employee={employee}
              resetState={resetState}
            />
            <WarehouseActivities session={session} />
          </Stack>
          <Stack direction="row" gap={2}>
            <WarehouseUsedMaterialModal
              session={session}
              warehouseProducts={warehouseProducts.map(getDefaultSupplierInfo)}
              employee={employee}
              fromConstructionDoc={false}
            />
            <WarehouseBulkUpdate
              session={session}
              type="add"
              location={location}
              warehouseProducts={warehouseProducts.map(getDefaultSupplierInfo)}
              warehouseProductTypes={warehouseProductTypes}
              suppliers={suppliers}
              employee={employee}
              resetState={resetState}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <WarehouseDelivery
              session={session}
              warehouseProducts={warehouseProducts.map(getDefaultSupplierInfo)}
              warehouseProductTypes={warehouseProductTypes}
              products={products}
              suppliers={suppliers}
              employee={employee}
              employees={employees}
              resetState={resetState}
            />
            <WarehouseDeliverySupplier
              session={session}
              warehouseProducts={warehouseProducts}
              suppliers={suppliers}
              employee={employee}
              resetParent={resetState}
            />
          </Stack>
          {(hasPermission(session.user, "warehouse_worker") ||
            hasPermission(session.user, "warehouse_all")) && (
            <Stack direction="row" gap={2}>
              <WarehouseBulkUpdate
                session={session}
                type="update"
                location={location}
                warehouseProducts={warehouseProducts.map(
                  getDefaultSupplierInfo,
                )}
                warehouseProductTypes={warehouseProductTypes}
                suppliers={suppliers}
                employee={employee}
                resetState={resetState}
              />
              <WarehouseStockModal
                session={session}
                location={location}
                warehouseProducts={warehouseProducts.map(
                  getDefaultSupplierInfo,
                )}
                warehouseProductTypes={warehouseProductTypes}
                suppliers={suppliers}
                products={products}
                employee={employee}
                resetState={resetState}
              />
            </Stack>
          )}

          <Stack direction="row">
            <DropDown
              onChange={setLocation}
              options={locations.map((loc) => ({
                label: loc.name,
                value: loc.id,
              }))}
              value={location}
              text="Standort"
              search={true}
              style={{ margin: "8px 0", width: "100%" }}
            />
          </Stack>
          <Stack direction="row">
            <DropDown
              onChange={setEmployee}
              options={employees.map((user) => ({
                label: user.first_name + " " + user.last_name,
                value: user.id,
              }))}
              value={employee}
              text="Für Mitarbeiter"
              sort={true}
              search={true}
              style={{ margin: "8px 0", width: "100%" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Fragment>
  );
}

Warehouse.propTypes = {
  session: PropTypes.object.isRequired,
};
