import React from "react";

import { PropTypes } from "prop-types";

import { CustomButton } from "../../elements/StyledElements";
import CustomModal from "../shared/modal_utils/CustomModal";
import { Table } from "reactstrap";
import { IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlanvilleLocationAddEditModal from "./PlanvilleLocationAddEditModal";

export default function PlanvilleLocationModal({
  locations,
  loadLocations,
  deleteLocation,
  session,
}) {
  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Standorteinstellungen</CustomButton>;
  };

  return (
    <CustomModal getOpenButton={getOpenButton} title="Standorteinstellungen">
      <Stack>
        <Table>
          <thead>
            <tr>
              <th className="align-middle">
                <Typography className="secondary-textcolor">
                  Standort
                </Typography>{" "}
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">
                  Postleitzahl
                </Typography>
              </th>
              <th style={{ textAlign: "center" }}>
                <Typography className="secondary-textcolor"></Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {!locations || locations.length <= 0 ? (
              <tr>
                <td colSpan="4" align="center">
                  <Typography className="secondary-textcolor">
                    Kein Standort
                  </Typography>
                </td>
              </tr>
            ) : (
              locations.map((location) => (
                <tr key={location.id}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {location.name}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {location.zip_code}
                    </Typography>
                  </td>
                  <td align="center">
                    <PlanvilleLocationAddEditModal
                      location={location}
                      loadLocations={loadLocations}
                      session={session}
                    />
                    <IconButton
                      onClick={() => deleteLocation(location.id)}
                      style={{ width: "15%" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <PlanvilleLocationAddEditModal
          session={session}
          loadLocations={loadLocations}
        />
      </Stack>
    </CustomModal>
  );
}

PlanvilleLocationModal.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  loadLocations: PropTypes.func,
  deleteLocation: PropTypes.func,
  session: PropTypes.object,
};
