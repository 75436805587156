import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import {
  customerPropType,
  projectPropType,
} from "../../../../elements/PropTypes";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  convertToFormData,
  filterObj,
  getEmptyFieldsError,
} from "../../../../elements/utils";
import { CAPI_URL_PROJECT, CAPI_URL_EMETER } from "../../../../settings";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import PlantProjectForm from "./PlantProjectForm";
import {
  checkIfPlantProjectFieldIsEmpty,
  emptyPlantProjectForm,
  eMeterEmptyFieldsError,
} from "./forms";
import PlantMandateSignatureForm from "./PlantMandateSignatureForm";
import { getEmptyProjectForm } from "../projectUtils";

const emptyProjectField = {
  iban_project: null,
  bic_project: null,
  bank_project: null,
  account_owner_project: null,
  tax_number_project: null,
  birth_date_project: null,
};

export default function PlantProjectFormModal({
  project,
  customer,
  disable,
  showAllMissingFields,
  resetParent,
  session,
}) {
  const [projectForm, setProjectForm] = useState({
    ...project,
    emeter_set: [...project.emeter_set.map((e) => ({ ...e }))],
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setProjectForm({ ...emptyPlantProjectForm });
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    const projectVal = Object.keys(emptyProjectField).reduce((acc, key) => {
      const customerKey = key.replace("_project", "");
      acc[key] = project[key] || customer[customerKey] || null;
      return acc;
    }, {});
    setProjectForm({
      ...project,
      ...projectVal,
      emeter_set: [...project.emeter_set.map((e) => ({ ...e }))],
    });
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} disabled={disable}>
        Ändern
      </CustomButton>
    );
  };

  const submit = async (projectForm) => {
    const emptyProjectForm = getEmptyProjectForm(project);
    return Promise.all([
      axios.put(
        CAPI_URL_PROJECT + project.id,
        convertToFormData(
          filterObj(projectForm, Object.keys(emptyProjectForm)),
          [
            "accept_mandate_document",
            "accept_mandate_signature",
            ["emeter_set"],
          ],
        ),
      ),
      axios
        .delete(CAPI_URL_EMETER, {
          data: {
            project: project.id,
            except_ids: projectForm.emeter_set
              .filter((e) => e.id > 0)
              .map((e) => e.id),
          },
        })
        .then(() =>
          Promise.all(
            projectForm.emeter_set.map((eMeterForm) =>
              eMeterForm.id > 0
                ? axios.put(CAPI_URL_EMETER + eMeterForm.id, {
                    ...eMeterForm,
                    project: project.id,
                  })
                : axios.post(CAPI_URL_EMETER, {
                    ...eMeterForm,
                    project: project.id,
                    id: null,
                  }),
            ),
          ),
        ),
    ]);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const additionalOptionalKeys = ["emeter_set"];
    let emptyFieldsError = getEmptyFieldsError(
      projectForm,
      emptyPlantProjectForm,
      additionalOptionalKeys,
      checkIfPlantProjectFieldIsEmpty,
    );
    emptyFieldsError =
      emptyFieldsError || eMeterEmptyFieldsError(projectForm.emeter_set);
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        showMissingFields: true,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    for (const emeter of projectForm.emeter_set) {
      if (emeter.bidirectional) emeter.value = null;
      else {
        emeter.value_180 = null;
        emeter.value_280 = null;
      }
    }
    return submit(projectForm)
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
          showMissingFields: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="customer-view-submit-project-form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={getOpenButton}
      title="Projektinformationen"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <PlantProjectForm
        project={projectForm}
        setProject={setProjectForm}
        customer={customer}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        showAllMissingFields={showAllMissingFields}
      />
      <hr className="secondary-textcolor" />
      <PlantMandateSignatureForm
        project={projectForm}
        setProject={setProjectForm}
        showAllMissingFields={showAllMissingFields}
        session={session}
      />
    </CustomModal>
  );
}

PlantProjectFormModal.propTypes = {
  project: projectPropType,
  customer: customerPropType,
  disable: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
