import React from "react";
import { PropTypes } from "prop-types";

import axios from "axios";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";

import {
  API_URL_COPYOFFER,
  API_URL_CREATEOFFER,
  API_URL_POSTCREATEOFFER,
} from "../../settings";
import { offerPropType } from "../../elements/PropTypes";

export default function OfferCopy({
  offer,
  resetParent,
  session,
  setLoadingElements,
}) {
  const copyPlanning = async () => {
    setLoadingElements((prev) => ({ ...prev, inProgress: true }));
    const copyOfferResponse = await axios.post(API_URL_COPYOFFER, {
      offer: offer.id,
    });
    const offerId = copyOfferResponse?.data?.offer;

    await axios.post(API_URL_CREATEOFFER, { offer: offerId });
    await axios.post(API_URL_POSTCREATEOFFER, { offer: offerId });

    setLoadingElements((prev) => ({ ...prev, inProgress: false }));
    resetParent();
  };

  return (
    <Tooltip title="Kopieren" PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        size="small"
        onClick={copyPlanning}
      >
        <ContentCopyIcon className="secondary-textcolor" fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}

OfferCopy.propTypes = {
  offer: offerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  setLoadingElements: PropTypes.func,
};
