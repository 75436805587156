import React from "react";

import { PropTypes } from "prop-types";

import { Typography, IconButton } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import CustomModal from "./CustomModal";
import ImageStage from "../images/ImageStage";

// class PdfViewModal extends Component {
export default function ImageViewModal({
  title,
  disable,
  width,
  filepath,
  getOpenButton,
  iconSize = "large",
}) {
  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent" }}
        disabled={disable}
        size="small"
        onClick={toggle}
      >
        <RemoveRedEyeOutlinedIcon
          className="secondary-textcolor"
          fontSize={iconSize}
        />
      </IconButton>
    );
  };

  // filepath = isString(filepath) ? filepath.replace(`http://${HOST_PORT}`, `${PROTOCOL}://${HOST_PORT}`) : URL.createObjectURL(filepath)

  return (
    <CustomModal getOpenButton={_getOpenButton} size="small" title={title}>
      {filepath ? (
        <ImageStage image={filepath} width={width} />
      ) : (
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Keine PDF verfügbar.
        </Typography>
      )}
    </CustomModal>
  );
}

ImageViewModal.propTypes = {
  title: PropTypes.string,
  disable: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filepath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
    PropTypes.instanceOf(Blob),
  ]),
  getOpenButton: PropTypes.func,
  iconSize: PropTypes.string,
};
