import React from "react";
import * as FileSaver from "file-saver";

import { CustomButton } from "../../elements/StyledElements";
import { PropTypes } from "prop-types";
const ExcelJS = await import("exceljs");

export default function ExcelExport({
  exporter,
  fileName,
  sheetName,
  disabled,
}) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const { data, colors } = exporter();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    for (let i = 0; i < data.length; i++) {
      const row = worksheet.getRow(i + 1);
      for (let j = 0; j < data[i].length; j++) {
        const cell = row.getCell(j + 1);
        cell.value = data[i][j];
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors[i][j] },
        };
      }
      row.commit();
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const sheetData = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(sheetData, fileName + fileExtension);
  };

  return (
    <>
      <CustomButton onClick={exportToExcel} disabled={disabled}>
        Export
      </CustomButton>
    </>
  );
}

ExcelExport.propTypes = {
  exporter: PropTypes.func,
  fileName: PropTypes.string,
  sheetName: PropTypes.string,
  disabled: PropTypes.bool,
};
