import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";
import { Typography } from "@mui/material";

import { PROTOCOL } from "../../../settings";
import {
  defaultIfEmpty,
  defaultIfEmptyForPrice,
  isString,
} from "../../../elements/utils";
import { inverterPropType } from "../../../elements/PropTypes";
import PdfViewModal from "../../shared/modal_utils/PdfViewModal";

export default function InverterForm({
  inverter,
  setInverter,
  showMissingFields,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  const onChange = (e) => {
    setInverter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onFileChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const file = e.target.files[0];
    const { name } = e.target;
    if (!file) {
      setInverter((prevState) => ({
        ...prevState,
        [name]: null,
      }));
      return;
    }
    setLoadingElements({ ...loadingElements, submitError: null });
    setInverter((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };

  return (
    <Form>
      {inverter.id ? (
        <FormGroup>
          <Typography className="secondary-textcolor">
            Wechselrichter ID
          </Typography>
          <Input
            id="input"
            type="text"
            name="inverter_id"
            value={defaultIfEmpty(inverter.id)}
            disabled={true}
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <Typography className="secondary-textcolor">Name</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(inverter.name)}
          required={true}
          invalid={!!(showMissingFields && !inverter.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">kWp</Typography>
        <Input
          id="input"
          type="number"
          name="power"
          step="0.01"
          onChange={onChange}
          value={defaultIfEmptyForPrice(inverter.power)}
          required={true}
          invalid={!!(showMissingFields && !inverter.power)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Zertifikat</Typography>
        <Input
          id="input"
          type="file"
          name="certificate"
          accept="application/pdf"
          onChange={onFileChange}
          required={inverter.certificate === null}
          invalid={!!(showMissingFields && !inverter.certificate)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      {inverter.certificate ? (
        <PdfViewModal
          filepath={
            isString(inverter.certificate)
              ? inverter.certificate.replace("http:", PROTOCOL + ":")
              : URL.createObjectURL(inverter.certificate)
          }
          title={"Zertifikat " + inverter.name}
        />
      ) : null}
    </Form>
  );
}

InverterForm.propTypes = {
  inverter: inverterPropType,
  setInverter: PropTypes.func,
  showMissingFields: PropTypes.bool,
};
