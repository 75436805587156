import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Input, Form, FormGroup } from "reactstrap";
import axios from "axios";
import { HexColorPicker } from "react-colorful";

import { Typography } from "@mui/material";

import { API_URL_TASKLABEL } from "../../settings";
import ConfirmationModalFooter from "../shared/modal_utils/ConfirmationModalFooter";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { taskLabelPropType } from "../../elements/PropTypes";
import "./styles.css";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { isValidForm } from "../../elements/utils";
import ErrorMessage from "../../elements/ErrorMessage";
import { toast } from "react-toastify";

const emptyTaskLabelForm = {
  name: "",
  color: "#346ec9",
};

const mandatoryFields = ["name"];

export default function TaskLabelFormModal({
  taskLabel,
  isOpen,
  setIsOpen,
  getOpenButton,
  resetParent,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [taskLabelForm, setTaskLabelForm] = useState({ ...emptyTaskLabelForm });

  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false);
  const [deletionLoadingElements, setDeletionLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false });
    setTaskLabelForm({ ...emptyTaskLabelForm });
  };

  const loadData = () => {
    setTaskLabelForm({ ...(taskLabel || emptyTaskLabelForm) });
  };

  const submit = async (taskLabelForm) => {
    taskLabelForm = { ...taskLabelForm };
    return taskLabel
      ? axios.put(API_URL_TASKLABEL + taskLabel.id, taskLabelForm)
      : axios.post(API_URL_TASKLABEL, taskLabelForm);
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    if (!isValidForm(mandatoryFields, taskLabelForm)) {
      setLoadingElements({
        ...loadingElements,
        inProgress: false,
        submitError: false,
        showMissingFields: true,
      });
      toast.error(<ErrorMessage message={"Bitte alle Felder ausfüllen!"} />);
      return;
    }
    return submit(taskLabelForm)
      .then((res) => {
        if (resetParent) resetParent(res.data);
        if (onSuccess) onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "TaskLabel:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
          showMissingFields: true,
        });
      });
  };

  const onDelete = async (onSuccess) => {
    setDeletionLoadingElements({
      ...deletionLoadingElements,
      inProgress: true,
      submitError: false,
    });
    return axios
      .delete(API_URL_TASKLABEL + taskLabel.id)
      .then((res) => {
        if (resetParent) resetParent(res.data);
        if (onSuccess) onSuccess();
        setDeletionLoadingElements({
          ...deletionLoadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "TaskLabel:onDelete"', error, error.stack);
        setDeletionLoadingElements({
          ...deletionLoadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          onCancel={toggle}
          onDelete={
            taskLabel
              ? () => {
                  setDeletionModalIsOpen(true);
                }
              : null
          }
        />
        <CustomModal
          size="small"
          title={"Label wirklich löschen?"}
          isOpen={deletionModalIsOpen}
          setIsOpen={(isOpen) => {
            if (!isOpen) setDeletionModalIsOpen(false);
          }}
          getFooter={(toggleDelete) => getDeletionFooter(toggleDelete, toggle)}
          onClose={() => setDeletionModalIsOpen(false)}
        ></CustomModal>
      </>
    );
  };

  const getDeletionFooter = (toggle, toogleAll) => {
    return (
      <ConfirmationModalFooter
        submitError={deletionLoadingElements.submitError}
        inProgress={deletionLoadingElements.inProgress}
        onConfirm={() =>
          onDelete(() => {
            setDeletionModalIsOpen(false);
            setIsOpen(false);
          })
        }
        onCancel={() => setDeletionModalIsOpen(false)}
        btnLabel={"Löschen"}
      />
    );
  };

  const modalTitle = taskLabel ? taskLabel.name : "Neues Label";

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      title={modalTitle}
      getFooter={getFooter}
      onToggle={onToggle}
    >
      <Form>
        <FormGroup>
          <Typography className="secondary-textcolor">Name:</Typography>
          <div style={{ display: "flex", gap: "0.5vw" }}>
            <Input
              id="input"
              placeholder="Name"
              type="text"
              width={"15px"}
              value={taskLabelForm.name}
              onChange={(e) =>
                setTaskLabelForm((x) => ({ ...x, name: e.target.value }))
              }
              style={{ boxShadow: "none" }}
            />
            {loadingElements.showMissingFields && !taskLabelForm.name.trim() ? (
              <div>
                &nbsp;&nbsp;
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              </div>
            ) : null}
          </div>
        </FormGroup>
        <FormGroup>
          <HexColorPicker
            color={taskLabelForm.color}
            onChange={(value) =>
              setTaskLabelForm((t) => ({ ...t, color: value }))
            }
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
}

TaskLabelFormModal.propTypes = {
  taskLabel: taskLabelPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
