import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import {
  API_URL_TASKTYPE,
  API_URL_TASKSETTINGS,
  API_URL_TASKLABEL,
} from "../../settings";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { Typography } from "@mui/material";
import TaskTypeFormModal from "./TaskTypeFormModal";
import TaskLabelFormModal from "./TaskLabelFormModal";
import { CustomButton } from "../../elements/StyledElements";
import TaskSettingsFormModal from "./TaskSettingsFormModal";

export default function TaskSettings({ session }) {
  // const [employees, setEmployees] = useState(null)
  const [chosenTaskLabel, setChosenTaskLabel] = useState(null);
  const [chosenTaskType, setChosenTaskType] = useState(null);
  const [taskLabels, setTaskLabels] = useState(null);
  const [taskTypes, setTaskTypes] = useState(null);
  const [taskSettings, setTaskSettings] = useState(null);

  useEffect(() => {
    // axios.get(API_URL_USER, {
    //   params: { is_staff: true, visible: true, is_active: true }
    // }).then(res => setEmployees(res.data))
    resetTaskLabels();
    resetTaskTypes();
    resetTaskSettings();
  }, []);

  const resetTaskSettings = () => {
    axios.get(API_URL_TASKSETTINGS).then((res) => setTaskSettings(res.data));
  };

  const resetTaskTypes = () => {
    axios.get(API_URL_TASKTYPE).then((res) => setTaskTypes(res.data));
  };

  const resetTaskLabels = () => {
    axios.get(API_URL_TASKLABEL).then((res) => setTaskLabels(res.data));
  };

  const getSettingsCoordinators = (taskSettings, bold = true) => {
    return taskSettings.coordinator_names.map((n, nIdx) =>
      n === taskSettings.default_coordinator_name ? (
        <>
          {`${nIdx !== 0 ? ", " : ""}`}
          {bold ? <b>{n}</b> : <i>{n}</i>}
        </>
      ) : (
        `${nIdx !== 0 ? ", " : ""}${n}`
      ),
    );
  };

  return (
    <Fragment>
      {taskSettings && (
        <>
          <Typography fontSize="h6.fontSize">Benachrichtigungen</Typography>
          <Typography>
            Termin-Erinnerung: {taskSettings.reminder_time} min
          </Typography>
          <Typography>
            Termin-Warnung an Koordinator: {taskSettings.warning_time} min
          </Typography>
          <Typography>
            Frist-Erinnerung: {taskSettings.deadline_reminder_time} Tage
          </Typography>
          {/* <Typography>Default-Koordinator: {taskSettings.default_coordinator_name || ''}</Typography> */}
          <Typography>
            Koordinatoren: {getSettingsCoordinators(taskSettings)}
          </Typography>
          <br />
          <TaskSettingsFormModal
            taskSettings={taskSettings}
            getOpenButton={(toggle) => (
              <CustomButton onClick={toggle}>
                Einstellungen bearbeiten
              </CustomButton>
            )}
            resetParent={() => {
              resetTaskSettings();
              resetTaskTypes();
            }} // also task types due to removed coordinators
            session={session}
          />
        </>
      )}
      <br />
      <br />
      {taskTypes && (
        <>
          <Typography fontSize="h6.fontSize">Aufgaben-Typen</Typography>
          <CollapsibleTable
            columns={[
              { name: "Name", key: "name" },
              { name: "Koordinator", key: "default_coordinator" },
              { name: "Farbe", key: "color" },
            ]}
            rows={taskTypes.map((t) => ({
              name: t.name,
              default_coordinator: t.default_coordinator_name,
              color: (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: t.color,
                    borderRadius: "10px",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ),
              link: () => setChosenTaskType(t),
            }))}
            collapsible={false}
          />
          <br />
          <TaskTypeFormModal
            getOpenButton={(toggle) => (
              <CustomButton onClick={toggle}>Neuer Aufgaben-Typ</CustomButton>
            )}
            resetParent={() => {
              resetTaskTypes();
              resetTaskSettings();
            }} // also reset settings due to added coordinators
            session={session}
          />
          <TaskTypeFormModal
            taskType={chosenTaskType}
            isOpen={!!chosenTaskType}
            setIsOpen={(isOpen) => {
              if (!isOpen) setChosenTaskType(null);
            }}
            resetParent={() => {
              resetTaskTypes();
              resetTaskSettings();
            }} // also reset settings due to added coordinators
            session={session}
          />
        </>
      )}
      <br />
      <br />
      {taskLabels && (
        <>
          <Typography fontSize="h6.fontSize">Labels</Typography>
          <CollapsibleTable
            columns={[
              { name: "Name", key: "name" },
              { name: "Farbe", key: "color" },
            ]}
            rows={taskLabels.map((t) => ({
              name: t.name,
              color: (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: t.color,
                    borderRadius: "10px",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ),
              link: () => setChosenTaskLabel(t),
            }))}
            collapsible={false}
          />
          <br />
          <TaskLabelFormModal
            getOpenButton={(toggle) => (
              <CustomButton onClick={toggle}>Neues Label</CustomButton>
            )}
            resetParent={resetTaskLabels}
            session={session}
          />
          <TaskLabelFormModal
            taskLabel={chosenTaskLabel}
            isOpen={!!chosenTaskLabel}
            setIsOpen={(isOpen) => {
              if (!isOpen) setChosenTaskLabel(null);
            }}
            resetParent={resetTaskLabels}
            session={session}
          />
        </>
      )}
    </Fragment>
  );
}

TaskSettings.propTypes = {
  session: PropTypes.object,
};
