import React, { Fragment, useState } from "react";
import { Container, Input, Button } from "reactstrap";
import { Table } from "react-bootstrap";
import axios from "axios";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";

import Collapsible from "../../elements/Collapsible";
import MultiSelect from "../../elements/MultiSelect";
import ConfirmationModal from "../shared/modal_utils/ConfirmationModal";
import { defaultIfEmpty, hasPermission } from "../../elements/utils";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Typography, Stack, IconButton, Checkbox } from "@mui/material";
import { CustomButton, CustomIconButton } from "../../elements/StyledElements";

import WarehouseProductFormModal from "./WarehouseProductFormModal";
import WarehouseProductTypeModal from "./WarehouseProductTypeModal";
import EditWarehouseProductTypeModal from "./EditWarehouseProductTypeModal";
import SupplierModal from "./SupplierModal";

import { API_URL_WAREHOUSEPRODUCT } from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import { isMobileOnly } from "react-device-detect";
import DeleteIcon from "@mui/icons-material/Delete";
import WarehouseProductQRCodeModal from "./WarehouseProductQRCodeModal";

export default function WarehouseStockModal({
  session,
  location,
  warehouseProducts,
  warehouseProductTypes,
  suppliers,
  products,
  employee,
  resetState,
}) {
  const rowsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [warehouseFile, setWarehouseFile] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProductType, setSelectedProductType] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [showColumns, setShowColumns] = useState([
    { name: "Index", show: false },
    { name: "Name", show: true },
    { name: "Kategorie", show: true },
    { name: "Zulieferer", show: true },
    { name: "Ausführung bei Zulieferer", show: true },
    { name: "Nummer bei Zulieferer", show: false },
    ...(hasPermission(session.user, "warehouse_all")
      ? [{ name: "Preis Netto", show: false }]
      : []),
    { name: "Mindestens im Lager", show: false },
    { name: "Beschreibung", show: false },
    { name: "Kommentar", show: false },
    { name: "Stückzahl in VPE (Verpackungseinheit)", show: false },
    { name: "Gewicht", show: false },
    { name: "Verwendet in Produkt", show: false },
    { name: "Lagerbestand", show: true },
    { name: "Aktion", show: true },
  ]);
  const [loadingCSVImport, setLoadingCSVImport] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const onFileChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) {
      setWarehouseFile(null);
    } else {
      setWarehouseFile(e.target.files[0]);
    }
  };

  const handleCSVImport = async () => {
    try {
      const formData = new FormData();
      formData.append("file", warehouseFile);
      formData.append("location", location);
      formData.append("request_as", employee);
      setLoadingCSVImport(true);
      return axios
        .post(API_URL_WAREHOUSEPRODUCT, formData)
        .then((res) => {
          resetState();
          setLoadingCSVImport(false);
          setWarehouseFile(null);
          setInputKey(Date.now());
        })
        .catch((err) => {
          setLoadingCSVImport(false);
          console.error(err.response.data);
          if (Array.isArray(err.response.data)) {
            const ids = err.response.data;
            toast.error(
              "" +
                ids.length +
                " vorhandene Index: [" +
                (ids.length > 6
                  ? ids.slice(0, 3).join(", ") +
                    ", ..., " +
                    ids.slice(ids.length - 3).join(", ")
                  : ids.join(", ")) +
                "]",
            );
          } else {
            toast.error(err.response.data);
          }
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const toCSVcell = (str) => {
    if (str === null) {
      str = "";
    }
    str = `${str}`;

    // If the string contains a double quote, escape it by replacing it with two double quotes
    str = str.replace(/"/g, '""');
    // If the string contains a comma, or newline character, or starts/ends with whitespace, enclose it in double quotes
    if (str.includes(",") || str.includes("\n") || /^\s+|\s+$/g.test(str)) {
      str = `"${str}"`;
    }
    return str;
  };
  const handleCSVExport = async () => {
    let strCSV =
      ",Index,Material,Zulieferer,Ausführung bei Zulieferer,Nummer Bei Zulieferer,Preis Netto,,Mindestens im Lager,Mindestens im Überlager,Kommentar,Stückzahl in VPE (Verpackungseinheit)";
    for (let i = 0; i < warehouseProducts.length; i++) {
      strCSV +=
        "\r\n" + toCSVcell(getWarehouseProductType(warehouseProducts[i], true));
      strCSV += "," + toCSVcell("i" + warehouseProducts[i].id);
      strCSV += "," + toCSVcell(warehouseProducts[i].name);
      strCSV += "," + toCSVcell(warehouseProducts[i].supplier_name);
      strCSV += "," + toCSVcell(warehouseProducts[i].supplier_product_name);
      strCSV += "," + toCSVcell(warehouseProducts[i].supplier_product_id);
      strCSV +=
        "," +
        toCSVcell(
          warehouseProducts[i].supplier_price
            ? warehouseProducts[i].supplier_price + " €"
            : "",
        ) +
        ",";
      strCSV += "," + toCSVcell(warehouseProducts[i].min_amount_material) + ",";
      strCSV += "," + toCSVcell(warehouseProducts[i].comment);
      strCSV += "," + toCSVcell(warehouseProducts[i].material_per_box);
    }
    strCSV += "\r\n";
    const csvData = new Blob(["\uFEFF" + strCSV], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "Lagerbestand_-_Material.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterRows = (rows) => {
    return rows.filter((row, idx) => {
      let satisfy =
        !searchInput ||
        ("i" + row.id).toLowerCase().includes(searchInput.toLowerCase()) ||
        (row.name &&
          row.name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (row.supplier_name &&
          row.supplier_name
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (row.supplier_product_name &&
          row.supplier_product_name
            .toLowerCase()
            .includes(searchInput.toLowerCase()));
      satisfy =
        (selectedProductType.length === 0 ||
          (selectedProductType.includes(0) && row.product_type.length === 0) ||
          row.product_type.filter((e) => selectedProductType.includes(e))
            .length > 0) &&
        satisfy;
      satisfy =
        (selectedSupplier.length === 0 ||
          selectedSupplier.includes(row.supplier_id)) &&
        satisfy;
      return satisfy;
    });
  };
  const handleSearchChange = (searchValue) => {
    setSearchInput(searchValue);
    setCurrentPage(1);
  };
  const handleTypeChange = (selectedValues) => {
    setSelectedProductType(selectedValues || []);
    setCurrentPage(1);
  };
  const handleSupplierChange = (selectedValues) => {
    setSelectedSupplier(selectedValues || []);
    setCurrentPage(1);
  };

  const getWarehouseProductType = (warehouseProduct, isCSV) => {
    let productTypes = "";
    for (
      let i = 0, j = 0;
      i < warehouseProductTypes.length &&
      j < warehouseProduct.product_type.length;

    ) {
      if (warehouseProductTypes[i].id < warehouseProduct.product_type[j]) {
        i++;
      } else if (
        warehouseProductTypes[i].id > warehouseProduct.product_type[j]
      ) {
        j++;
      } else {
        if (productTypes !== "") {
          productTypes += ",";
        }
        productTypes += warehouseProductTypes[i].name;
        i++;
        j++;
      }
    }
    return productTypes === "" && !isCSV ? "Unsortiert" : productTypes;
  };

  const getProductName = (warehouseProducts) => {
    let productNames = "";
    const usedInProductsId = warehouseProducts.used_in_products.map(
      (usedInProduct) => {
        return usedInProduct.product_id;
      },
    );
    for (let i = 0; i < products.length; i++) {
      if (usedInProductsId.includes(products[i].id)) {
        if (productNames !== "") {
          productNames += ",";
        }
        productNames += products[i].name;
      }
    }
    return productNames;
  };

  const handleSetShowColumns = (idx) => {
    const newList = [...showColumns];
    newList[idx].show = !newList[idx].show;
    setShowColumns(newList);
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton
        color="black"
        style={{
          margin: "8px 0",
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        onClick={toggle}
      >
        <Typography variant={isMobileOnly ? "body1" : "h6"}>
          Lagerbestand
        </Typography>
      </CustomButton>
    );
  };

  const detectLink = (text) => {
    if (text) {
      const urlPattern =
        // eslint-disable-next-line no-useless-escape
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const parts = text.split(/(\s+)/);
      return parts.map((part, index) => {
        if (urlPattern.test(part)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        }
        return part;
      });
    }
  };

  const getOpenButtonDelete = (toggle) => {
    return (
      <IconButton onClick={toggle}>
        <DeleteIcon />
      </IconButton>
    );
  };

  return (
    <CustomModal
      size="fullscreen"
      title="Lagerbestand"
      getOpenButton={getOpenButton}
    >
      <Fragment>
        <Container>
          {hasPermission(session.user, "warehouse_all") && (
            <Stack
              direction={isMobileOnly ? "column" : "row"}
              spacing={2}
              mt={2}
            >
              <WarehouseProductFormModal
                newProduct={true}
                location={location}
                resetParent={resetState}
                session={session}
                warehouseProduct={null}
                warehouseProductTypes={warehouseProductTypes}
                suppliers={suppliers}
                products={products}
                employee={employee}
              />
              <WarehouseProductTypeModal
                session={session}
                resetParent={resetState}
                employee={employee}
              />
              <EditWarehouseProductTypeModal
                session={session}
                resetParent={resetState}
                warehouseProductType={warehouseProductTypes}
                employee={employee}
              />
              <SupplierModal
                session={session}
                resetParent={resetState}
                employee={employee}
              />
              <SupplierModal
                suppliers={suppliers}
                session={session}
                resetParent={resetState}
                employee={employee}
              />

              <WarehouseProductQRCodeModal
                warehouseProducts={warehouseProducts.sort(
                  (a, b) => a.id - b.id,
                )}
                session={session}
              />
            </Stack>
          )}

          <Stack direction={isMobileOnly ? "column" : "row"} mt={2} spacing={2}>
            <Input
              key={inputKey}
              style={{
                width: isMobileOnly ? "100%" : "70%",
                boxShadow: "none",
              }}
              type="file"
              accept=".csv"
              onChange={onFileChange}
            />
            <CustomButton
              onClick={handleCSVImport}
              disabled={!warehouseFile || loadingCSVImport}
            >
              CSV Import
            </CustomButton>
            <CustomButton onClick={handleCSVExport}>CSV Export</CustomButton>
          </Stack>

          <Stack direction={isMobileOnly ? "column" : "row"} mt={2} spacing={2}>
            <Collapsible
              getOpenButton={(toggle) => (
                <IconButton
                  size="medium"
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  onClick={toggle}
                >
                  <FilterAltOutlinedIcon
                    style={{ color: "#424242" }}
                    fontSize="medium"
                  />
                  <Typography className="secondary-textcolor">
                    Material filtern
                  </Typography>
                </IconButton>
              )}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Stack direction="column" spacing={2}>
                <MultiSelect
                  onChange={handleTypeChange}
                  options={[{ id: 0, name: "Unsortiert" }]
                    .concat(warehouseProductTypes)
                    .map((productType) => ({
                      value: productType.id,
                      label: productType.name,
                    }))}
                  values={defaultIfEmpty(selectedProductType)}
                  text="Kategorie"
                  search={true}
                  name="product_type"
                />
                <MultiSelect
                  onChange={handleSupplierChange}
                  options={suppliers.map((supplier) => ({
                    value: supplier.id,
                    label: supplier.name,
                  }))}
                  values={defaultIfEmpty(selectedSupplier)}
                  text="Zulieferer"
                  search={true}
                  name="supplier"
                />
              </Stack>
            </Collapsible>

            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                width: isMobileOnly ? "100%" : "40%",
                backgroundColor: "#ffffff",
                color: "#424242",
                borderColor: "#424242",
                boxShadow: "none",
              }}
            >
              <Input
                style={{
                  paddingRight: "30px",
                  backgroundColor: "#ffffff",
                  color: "#424242",
                  borderColor: "#424242",
                  boxShadow: "none",
                }}
                type="text"
                value={searchInput}
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Material suchen"
                autoComplete="off"
              />
              {searchInput && (
                <Button
                  color="danger"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 10,
                    borderRadius: "50%",
                    fontWeight: "bold",
                    width: "25px",
                    height: "25px",
                    padding: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    color: "red",
                  }}
                  onClick={() => handleSearchChange("")}
                >
                  x
                </Button>
              )}
            </div>
          </Stack>

          <Collapsible
            getOpenButton={(toggle) => (
              <IconButton
                size="medium"
                disableFocusRipple
                disableRipple
                style={{ backgroundColor: "transparent" }}
                onClick={toggle}
              >
                <FilterAltOutlinedIcon
                  style={{ color: "#424242" }}
                  fontSize="medium"
                />
                <Typography className="secondary-textcolor">
                  Tabellenspalten
                </Typography>
              </IconButton>
            )}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack direction="column" mt={0} spacing={0}>
              {showColumns.map((showColumn, idx) => (
                <Stack direction={"row"} mt={0} spacing={0} key={idx}>
                  <td className="align-middle">
                    <Checkbox
                      disableFocusRipple
                      disableRipple
                      checked={showColumns[idx].show}
                      onChange={() => {
                        handleSetShowColumns(idx);
                      }}
                      style={{
                        color: "#424242",
                        backgroundColor: "transparent",
                      }}
                    />
                  </td>
                  <Typography style={{ alignSelf: "center" }}>
                    {showColumns[idx].name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Collapsible>

          <Table
            className={
              !warehouseProducts || warehouseProducts.length <= 0
                ? "table-not-hover"
                : "table-hover"
            }
          >
            <thead>
              <tr>
                {showColumns.map((showColumn) => {
                  return showColumn.show ? (
                    <th className="align-middle">
                      <Typography className="secondary-textcolor">
                        {showColumn.name}
                      </Typography>
                    </th>
                  ) : null;
                })}
              </tr>
            </thead>
            <tbody>
              {!warehouseProducts || warehouseProducts.length <= 0 ? (
                <tr>
                  <td colSpan="15" align="center">
                    <Typography className="secondary-textcolor">
                      {" "}
                      Leere Datenbank{" "}
                    </Typography>
                  </td>
                </tr>
              ) : (
                filterRows(warehouseProducts)
                  .filter((_, idx) => {
                    return (
                      (currentPage - 1) * rowsPerPage <= idx &&
                      idx < currentPage * rowsPerPage
                    );
                  })
                  .map((warehouseProduct) => (
                    <tr key={warehouseProduct.id}>
                      {showColumns.map((column, index) => {
                        if (!column.show) return null;

                        let content;

                        switch (column.name) {
                          case "Index":
                            content = `i${warehouseProduct.id}`;
                            break;
                          case "Name":
                            content = warehouseProduct.name;
                            break;
                          case "Kategorie":
                            content = getWarehouseProductType(
                              warehouseProduct,
                              false,
                            );
                            break;
                          case "Zulieferer":
                            content = warehouseProduct.supplier_name;
                            break;
                          case "Ausführung bei Zulieferer":
                            content = detectLink(
                              warehouseProduct.supplier_product_name,
                            );
                            break;
                          case "Nummer bei Zulieferer":
                            content = warehouseProduct.supplier_product_id;
                            break;
                          case "Preis Netto":
                            content = warehouseProduct.supplier_price;
                            break;
                          case "Mindestens im Lager":
                            content = warehouseProduct.min_amount_material;
                            break;
                          case "Beschreibung":
                            content = detectLink(warehouseProduct.description);
                            break;
                          case "Kommentar":
                            content = detectLink(warehouseProduct.comment);
                            break;
                          case "Stückzahl in VPE (Verpackungseinheit)":
                            content = warehouseProduct.material_per_box;
                            break;
                          case "Gewicht":
                            content = warehouseProduct.weight;
                            break;
                          case "Verwendet in Produkt":
                            content = getProductName(warehouseProduct);
                            break;
                          case "Lagerbestand":
                            content = warehouseProduct.amounts.find(
                              (e) => e.location_id === location,
                            )?.amount;
                            break;
                          case "Aktion":
                            content = (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {hasPermission(
                                  session.user,
                                  "warehouse_all",
                                ) && (
                                  <WarehouseProductFormModal
                                    newProduct={false}
                                    resetParent={resetState}
                                    session={session}
                                    location={location}
                                    warehouseProduct={warehouseProduct}
                                    warehouseProductTypes={
                                      warehouseProductTypes
                                    }
                                    suppliers={suppliers}
                                    products={products}
                                    style={{ marginRight: "10px" }}
                                    employee={employee}
                                  />
                                )}
                                <ConfirmationModal
                                  resetParent={resetState}
                                  confirm={() =>
                                    axios.delete(
                                      API_URL_WAREHOUSEPRODUCT +
                                        warehouseProduct.id,
                                    )
                                  }
                                  title={`Soll '${warehouseProduct.name}' wirklich aus Sortiment entfernt werden?`}
                                  getOpenButton={getOpenButtonDelete}
                                />
                              </div>
                            );
                            break;
                          default:
                            content = null;
                            break;
                        }

                        return (
                          <td
                            key={index}
                            className="align-middle"
                            style={{
                              maxWidth: "100px",
                              wordWrap: "break-word",
                            }}
                          >
                            <Typography className="secondary-textcolor">
                              {content}
                            </Typography>
                          </td>
                        );
                      })}
                    </tr>
                  ))
              )}
            </tbody>
          </Table>
          <Stack direction="row" spacing={2}>
            <CustomIconButton
              disabled={currentPage === 1}
              icon="previous"
              onClick={() => setCurrentPage(currentPage - 1)}
            />
            <Typography className="secondary-textcolor">
              {currentPage}
            </Typography>
            <CustomIconButton
              disabled={
                currentPage * rowsPerPage >=
                filterRows(warehouseProducts).length
              }
              icon="next"
              onClick={() => setCurrentPage(currentPage + 1)}
            />
          </Stack>
        </Container>
      </Fragment>
    </CustomModal>
  );
}

WarehouseStockModal.propTypes = {
  session: PropTypes.object.isRequired,
  location: PropTypes.number,
  warehouseProducts: PropTypes.array,
  warehouseProductTypes: PropTypes.array,
  suppliers: PropTypes.array,
  products: PropTypes.array,
  employee: PropTypes.number,
  resetState: PropTypes.func,
};
