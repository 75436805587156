import React, { Fragment, useState } from "react";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import CustomForm, {
  getEmptyFieldsErrorFromTemplate,
} from "../../../../elements/CustomForm";
import { projectPropType } from "../../../../elements/PropTypes";
import { PropTypes } from "prop-types";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  filterObj,
  getCustomerName,
  getImagePromises,
  getSignaturePromise,
  hasPermission,
  removeFromObj,
} from "../../../../elements/utils";
import { getEmployeeSignatureUpdate } from "../../plant_project/constructions/PlantConstructionDocumentationForm";
import { getRoofConstructionFormTemplate } from "./RoofConstructionDocumentationForm";
import axios from "axios";
import {
  API_URL_CONSTRUCTION,
  API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_AFTER,
  API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_BEFORE,
  API_URL_ROOF_PROJECT_CONSTRUCTIONEMPLOYEESIG,
  API_URL_USER,
} from "../../../../settings";

const emptyConstructionDocumentationForm = {
  project: null,
  five_star_google_rating: false,
  construction_manager: null,
  documentation_completed: false,
  construction_manager_signature: null,
  roofprojectconstructionimagebefore_set: [],
  roofprojectconstructionimageafter_set: [],
  employees: [],
  roofprojectemployeesafetybriefingsignature_set: [],
  constructiondates_set: [],
};

const signatureKeys = ["construction_manager_signature"];
const imagesKeys = [
  "roofprojectconstructionimagebefore_set",
  "roofprojectconstructionimageafter_set",
];
const signaturesKeys = ["roofprojectemployeesafetybriefingsignature_set"];

export default function RoofConstructionDocumentationFormModal({
  project,
  disable,
  getOpenButton,
  resetParent,
  session,
}) {
  const [constructionDocumentationForm, setConstructionDocumentationForm] =
    useState(emptyConstructionDocumentationForm);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [employees, setEmployees] = useState([]);

  const construction = project.construction_obj;

  const loadData = () => {
    const documentationForm = {
      ...emptyConstructionDocumentationForm,
      ...construction,
      project: project.id,
    };
    if (
      !!documentationForm.construction_manager &&
      !documentationForm.employees.includes(
        documentationForm.construction_manager,
      )
    )
      documentationForm.employees = [
        ...documentationForm.employees,
        construction.construction_manager,
      ];
    documentationForm.roofprojectemployeesafetybriefingsignature_set =
      getEmployeeSignatureUpdate(
        documentationForm.employees,
        documentationForm.roofprojectemployeesafetybriefingsignature_set,
      );
    setConstructionDocumentationForm(documentationForm);
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true },
      })
      .then((res) => {
        const groups = [
          "construction_managers",
          "installers",
          "heating_installers",
        ];
        const employees = res.data.filter((user) =>
          groups.includes(user.group_key),
        );
        setEmployees(employees);
      });
  };

  const submit = async (form) => {
    form = filterObj(form, Object.keys(emptyConstructionDocumentationForm));
    let _form = removeFromObj(form, [...signaturesKeys, ...imagesKeys]);
    const keys = [...signatureKeys];
    keys.forEach((key) => {
      _form = form[key] ? removeFromObj(_form, [key]) : _form;
    });
    _form.documentation = true;
    _form.created_by = session.user.id;

    /**
     * Prepare form for put request by removing empty keys
     * @param {Record<string, unknown>} form
     * @returns Record<string, unknown> form
     */
    const preventEmptyKeysOnPutRequest = (form) => {
      const removeNullKeys = ["construction_manager_signature"];
      return Object.keys(form).reduce((acc, key) => {
        if (!removeNullKeys.includes(key) || form[key] !== null) {
          acc[key] = form[key];
        }
        return acc;
      }, {});
    };

    const getConstructionSignaturePromise = async (val, key = null) => {
      return getSignaturePromise(val, "sig.png", key);
    };

    const constructionPromise = construction
      ? axios.put(
          API_URL_CONSTRUCTION + construction.id,
          preventEmptyKeysOnPutRequest(_form),
        )
      : axios.post(API_URL_CONSTRUCTION, _form);
    return constructionPromise.then((constructionRes) => {
      const promises = [];
      promises.push(
        getImagePromises(
          "construction",
          constructionRes.data.id,
          form.roofprojectconstructionimagebefore_set,
          API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_BEFORE,
        ),
      );
      promises.push(
        getImagePromises(
          "construction",
          constructionRes.data.id,
          form.roofprojectconstructionimageafter_set,
          API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_AFTER,
        ),
      );
      const sigFilePromises =
        form.roofprojectemployeesafetybriefingsignature_set.map((sig) =>
          getConstructionSignaturePromise(sig.image).then((res) => ({
            ...sig,
            image: res,
          })),
        );
      promises.push(
        Promise.all(sigFilePromises).then((sigs) =>
          getImagePromises(
            "construction",
            constructionRes.data.id,
            sigs,
            API_URL_ROOF_PROJECT_CONSTRUCTIONEMPLOYEESIG,
            ["employee"],
          ),
        ),
      );
      return Promise.all(promises).then(() =>
        Promise.all([
          ...signatureKeys.map((key) =>
            getConstructionSignaturePromise(form[key], key),
          ),
        ]).then((res) => {
          const formData = new FormData();
          res
            .filter(([key, img]) => img)
            .forEach(([key, img]) => {
              formData.append(key, img);
            });
          formData.append("documentation", true);
          formData.append("form", true);
          return axios.put(
            API_URL_CONSTRUCTION + constructionRes.data.id,
            formData,
          );
        }),
      );
    });
  };

  const onSubmit = async (onSuccess, finalize, frameworkChecked = false) => {
    if (finalize) {
      const emptyFieldsError = getEmptyFieldsErrorFromTemplate(
        template,
        constructionDocumentationForm,
        emptyConstructionDocumentationForm,
      );
      if (emptyFieldsError) {
        setLoadingElements({
          ...loadingElements,
          submitError: "Bitte alle Informationen eintragen!",
          inProgress: false,
          showMissingFields: true,
        });
        console.error(emptyFieldsError);
        return;
      }
      constructionDocumentationForm.documentation_completed = true;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(constructionDocumentationForm)
      .then((res) => {
        if (resetParent) resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          "Error in ConstructionFormModal:onSubmit",
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const clearData = () => {
    setConstructionDocumentationForm({ ...emptyConstructionDocumentationForm });
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setEmployees([]);
  };
  const _getOpenButton = (toggle) => {
    return getOpenButton ? (
      getOpenButton(toggle)
    ) : (
      <CustomButton disabled={disable} onClick={toggle} icon="documentation">
        Dokumentation
      </CustomButton>
    );
  };

  const title = project
    ? "Dokumentation zur Bauplanung von " +
      getCustomerName(project.customer_obj)
    : "Baudokumentation";

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle, false)}
        onComplete={
          hasPermission(session.user, "finish_construction_documentation")
            ? () => onSubmit(toggle, true)
            : null
        }
        saveDisabled={
          !(
            hasPermission(session.user, "customer_handling") ||
            hasPermission(session.user, "page_constructions")
          ) ||
          !construction ||
          (construction.documentation_completed &&
            !hasPermission(session.user, "finish_construction_documentation"))
        }
      />
    );
  };

  const onToggle = (isOpen) => {
    if (isOpen) {
      loadData();
    } else clearData();
  };

  const template = getRoofConstructionFormTemplate(
    project,
    constructionDocumentationForm,
    employees,
    session,
  );

  return (
    <Fragment>
      <CustomModal
        size="fullscreen"
        getOpenButton={_getOpenButton}
        title={title}
        getFooter={getFooter}
        onToggle={onToggle}
      >
        <Fragment>
          <CustomForm
            title="Baudokumentation"
            template={template}
            form={constructionDocumentationForm}
            setForm={setConstructionDocumentationForm}
            defaultForm={emptyConstructionDocumentationForm}
            showMissingFields={loadingElements.showMissingFields}
          />
        </Fragment>
      </CustomModal>
    </Fragment>
  );
}

RoofConstructionDocumentationFormModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
