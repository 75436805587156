import React, { useState, useEffect } from "react";
import axios from "axios";
import { PropTypes } from "prop-types";
import { Table } from "react-bootstrap";
import { Col, Container, Row } from "reactstrap";
import { Typography, IconButton, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomModal from "../shared/modal_utils/CustomModal";
import TaskFormModal from "../tasks/TaskFormModal";

import { API_URL_TASK, API_URL_CUSTOMER } from "../../settings";
import LoadingPage from "../../elements/LoadingPage";
import { Link } from "react-router-dom";
import { useDidMountEffect, getCalendarTaskColor } from "../../elements/utils";
import DropDown from "../../elements/DropDown";

export default function TasksBacklogModal({
  isOpen,
  setIsOpen,
  session,
  resetParent,
  date,
}) {
  const [tasks, setTasks] = useState([]);
  const [chosenTask, setChosenTask] = useState(null);
  const [isAddTask, setIsAddTask] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerSearchInput, setCustomerSearchInput] = useState(null);

  const title = "Aufgaben";

  useEffect(() => {
    if (isOpen) loadData();
  }, [isOpen]);

  useDidMountEffect(() => {
    getTasks(customer);
  }, [customer]);

  const resetState = () => {
    loadData();
    resetParent();
  };

  const loadData = () => {
    getTasks().then(() => setLoaded(true));
  };

  const getTasks = (customer) => {
    return axios
      .get(API_URL_TASK, {
        params: {
          scheduled: false,
          customer,
          finished: false,
        },
      })
      .then((res) => setTasks(res.data));
  };

  const getCustomerBatch = (input = null) => {
    const params = {
      batch_size: 10,
      batch: 0,
      search: input || null,
      ascending_ids: false,
    };
    axios
      .get(API_URL_CUSTOMER, { params })
      .then((res) => setCustomers(res.data));
  };

  useDidMountEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCustomerBatch(customerSearchInput);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [customerSearchInput]);

  return (
    <CustomModal size="lg" isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <Container>
        {loaded ? null : <LoadingPage />}
        <Row style={{ marginTop: 10 }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              overlfow: "hidden",
            }}
          >
            <div style={{ alignSelf: "flex-end" }}>
              <Stack direction="row" spacing={2}>
                <DropDown
                  text={"Kundensuche"}
                  onChange={(value) => setCustomer(value)}
                  options={customers.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  value={customer}
                  sort={true}
                  search={true}
                  noOptionsText={"Keine Kunden"}
                  onInputChange={(input) => setCustomerSearchInput(input)}
                />
                <IconButton
                  size="small"
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => setIsAddTask(true)}
                >
                  <Add style={{ color: "#424242" }} fontSize="small" />
                  <Typography className="secondary-textcolor">
                    Neue Aufgabe
                  </Typography>
                </IconButton>
              </Stack>
            </div>
          </div>
        </Row>
        <Row>
          <Col sm="10" md={{ size: 12, offset: -1 }}>
            <Table
              className={
                !tasks || tasks.length <= 0 ? "table-not-hover" : "table-hover"
              }
            >
              <thead>
                <tr>
                  <th
                    className="align-middle"
                    style={{ width: "5%", textAlign: "left" }}
                  ></th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Kunde
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Titel
                    </Typography>
                  </th>
                  <th className="align-middle">
                    <Typography className="secondary-textcolor">
                      Erstellt von
                    </Typography>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tasks.length <= 0 ? (
                  <tr>
                    <td colSpan="5" align="center">
                      <Typography className="secondary-textcolor">
                        {" "}
                        Keine Aufgaben{" "}
                      </Typography>
                    </td>
                  </tr>
                ) : (
                  <>
                    {tasks.map((task) => (
                      <tr key={`task-row-${task.id}`}>
                        <td
                          className="align-middle"
                          style={{ textAlign: "left" }}
                          onClick={() => setChosenTask(task)}
                        >
                          <span
                            style={{
                              backgroundColor: getCalendarTaskColor(task),
                              borderRadius: "50%",
                              width: 25,
                              height: 25,
                              display: "inline-block",
                            }}
                          />
                        </td>
                        {/* <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}><Typography className='secondary-textcolor'>{task.customer_name}</Typography></td> */}
                        <td
                          className="align-middle"
                          style={{ textAlign: "left" }}
                          onClick={() => setChosenTask(task)}
                        >
                          {task.customer && (
                            <Link
                              to={"/kunde/" + task.customer}
                              style={{ textDecoration: "none" }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  borderRadius: "4px",
                                  padding: "5px 15px",
                                  border: "1px solid #424242",
                                }}
                              >
                                <Typography className="secondary-textcolor">
                                  {task.customer_name}{" "}
                                </Typography>
                              </div>
                            </Link>
                          )}
                        </td>
                        <td
                          className="align-middle"
                          style={{ textAlign: "left" }}
                          onClick={() => setChosenTask(task)}
                        >
                          <Typography className="secondary-textcolor">
                            {task.title}
                          </Typography>
                        </td>
                        <td
                          className="align-middle"
                          style={{ textAlign: "left" }}
                          onClick={() => setChosenTask(task)}
                        >
                          <Typography className="secondary-textcolor">
                            {task.registered_by_name}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <TaskFormModal
        isOpen={isAddTask}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            setIsAddTask(false);
          }
        }}
        resetParent={resetState}
        session={session}
        date={date}
      />
      <TaskFormModal
        task={chosenTask}
        date={date}
        isOpen={!!chosenTask}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            setChosenTask(null);
          }
        }}
        resetParent={resetState}
        session={session}
      />
    </CustomModal>
  );
}

TasksBacklogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  resetParent: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};
