import React, { Fragment, useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { Stage, Layer, Image, Circle, Arrow, Rect } from "react-konva";
import { FormGroup, Input } from "reactstrap";

import { Stack, Typography } from "@mui/material";

import { getImageWindowWithAuth, round } from "../../../elements/utils";
import { projectPropType } from "../../../elements/PropTypes";
import MapSite from "../MapSite";

export default function RoofScale({
  project,
  image,
  onImageWidthChange,
  maxHeight,
  maxWidth,
  session,
}) {
  const [imageState, setImageState] = useState({
    stageWidth: null,
    stageHeight: null,
    imageScale: null,
    imageWindow: null,
  });

  const [line, setLine] = useState({
    start: { x: null, y: null },
    end: { x: null, y: null, set: false },
  });
  const [magnifierCursor, setMagnifierCursor] = useState({
    cursor: null,
    signHeight: 1,
    signWidth: 1,
  });
  const [roofWidthPx, setRoofWidthPx] = useState(null);
  const [roofWidthM, setRoofWidthM] = useState(null);

  const prevCursor = useRef({ x: null, y: null });
  const drawActive = useRef(false);
  // const divRef = useRef(null)

  const maxImageWidth = maxWidth;
  const maxImageHeight = maxHeight;

  useEffect(() => {
    loadImage();
  }, [image]);

  useEffect(() => {
    if (
      roofWidthPx == null ||
      roofWidthPx === 0 ||
      roofWidthM == null ||
      imageState.stageWidth == null
    )
      return;
    const imageWidthM = roofWidthM / (roofWidthPx / imageState.stageWidth);
    onImageWidthChange(imageWidthM);
  }, [roofWidthPx, roofWidthM]);

  const loadImage = () => {
    if (image === null) {
      setImageState({ ...imageState, imageWindow: null });
      return;
    }
    getImageWindowWithAuth(image, handleLoad);
  };

  const handleLoad = (newImageWindow) => {
    const scalerW = maxImageWidth / newImageWindow.width;
    const scalerH = maxImageHeight / newImageWindow.height;
    const scaler = scalerW >= scalerH ? scalerH : scalerW;
    const stageWidth = round(newImageWindow.width * scaler);
    const stageHeight = round(newImageWindow.height * scaler);
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight,
    });
  };

  const handleMoveStart = (e) => {
    const stage = e.target.getStage();
    prevCursor.current = stage.getPointerPosition();
    drawActive.current = true;
    updateMagnifierCursor(prevCursor.current);
  };

  const handleMove = (e) => {
    const stage = e.target.getStage();
    const cursor = stage.getPointerPosition();
    if (drawActive.current)
      setLine({ start: prevCursor.current, end: { ...cursor, set: false } });
    updateMagnifierCursor(cursor);
  };

  const handleMoveEnd = (e) => {
    const stage = e.target.getStage();
    const cursor = stage.getPointerPosition();
    if (drawActive.current) {
      drawActive.current = false;
      const roofWidthArrow = {
        x: cursor.x - prevCursor.current.x,
        y: cursor.y - prevCursor.current.y,
      };
      setLine({ start: prevCursor.current, end: { ...cursor, set: true } });
      setRoofWidthPx(Math.sqrt(roofWidthArrow.x ** 2 + roofWidthArrow.y ** 2));
    }
    updateMagnifierCursor(cursor);
  };

  const setEndLine = (e) => {
    const stage = e.target.getStage();
    const cursor = stage.getPointerPosition();
    const roofWidthArrow = {
      x: cursor.x - line.start.x,
      y: cursor.y - line.start.y,
    };
    setLine({ start: line.start, end: { ...cursor, set: true } });
    setRoofWidthPx(Math.sqrt(roofWidthArrow.x ** 2 + roofWidthArrow.y ** 2));
    updateMagnifierCursor(cursor);
  };

  const setStartLine = (e) => {
    const stage = e.target.getStage();
    const cursor = stage.getPointerPosition();
    const roofWidthArrow = {
      x: line.end.x - cursor.x,
      y: line.end.y - cursor.y,
    };
    setLine({ start: cursor, end: line.end });
    setRoofWidthPx(Math.sqrt(roofWidthArrow.x ** 2 + roofWidthArrow.y ** 2));
    updateMagnifierCursor(cursor);
  };

  const updateMagnifierCursor = (cursor) => {
    // const signWidth = (cursor.x >= imageState.stageWidth - 100) ? -1 : 1
    const signWidth = cursor.x <= 100 ? 1 : -1;
    const signHeight = cursor.y <= 100 ? -1 : 1; // above except for on top
    setMagnifierCursor({ cursor, signHeight, signWidth });
  };

  const handleMoveLeave = (e) => {
    const stage = e.target.getStage();
    const cursor = stage.getPointerPosition();
    if (
      cursor.x >= imageState.stageWidth ||
      cursor.y >= imageState.stageHeight ||
      cursor.x <= 0 ||
      cursor.y <= 0
    )
      handleMoveEnd(e);
  };

  const magnifierZoom = 2;

  return (
    <Fragment key="roof_image_planning">
      <Stack spacing={2}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          justifyContent={{ xs: "flex-start", md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Stack>
            <Typography fontSize="h5.fontSize" className="secondary-textcolor">
              Skalierung
            </Typography>
            <Typography
              sx={{ fontWeight: "bold" }}
              className="secondary-textcolor"
            >
              Dach ausmessen
            </Typography>
            <Stack direction="row">
              <MapSite project={project} />
            </Stack>
          </Stack>

          <Stack>
            <Typography className="secondary-textcolor">
              {project.street_and_number_project}
            </Typography>
            <Typography className="secondary-textcolor">
              {project.zip_and_city_project}
            </Typography>
            <Typography fontSize={10} className="secondary-textcolor">
              (ist in die Zwischenablage kopiert)
            </Typography>
          </Stack>
          <Typography
            sx={{ fontWeight: "bold" }}
            className="secondary-textcolor"
          >
            {" "}
            Dachbreite [Pixel]:{" "}
            <strong> {roofWidthPx ? parseInt(roofWidthPx) : null} </strong>
          </Typography>
          <FormGroup>
            <Typography
              sx={{ fontWeight: "bold" }}
              className="secondary-textcolor"
            >
              {" "}
              Dachbreite [Meter]:{" "}
            </Typography>
            <Input
              style={{ width: "200px", boxShadow: "none" }}
              type="number"
              name="roof_width_m"
              id="input"
              onChange={(e) =>
                setRoofWidthM(
                  e.target.value !== ""
                    ? parseFloat(e.target.value.replace(",", "."))
                    : null,
                )
              }
              min={0}
              onKeyDown={(e) => {
                if (e.key === "-") e.preventDefault();
              }}
            />
          </FormGroup>
          <Stage width={150} height={150}>
            <Layer>
              {magnifierCursor.cursor ? (
                <>
                  <Rect
                    fillPatternImage={imageState.imageWindow}
                    fillPatternX={parseInt(
                      -magnifierCursor.cursor.x * magnifierZoom + 75,
                    )}
                    fillPatternY={parseInt(
                      -magnifierCursor.cursor.y * magnifierZoom + 75,
                    )}
                    fillPatternScaleX={imageState.imageScale * magnifierZoom}
                    fillPatternScaleY={imageState.imageScale * magnifierZoom}
                    x={0}
                    y={0}
                    width={150}
                    height={150}
                    stroke={"black"}
                    strokeWidth={1}
                  />
                  <Circle x={75} y={75} radius={3} fill={"red"} />
                </>
              ) : null}
            </Layer>
          </Stage>
        </Stack>
        <Stage width={imageState.stageWidth} height={imageState.stageHeight}>
          <Layer>
            <Image
              id="scaleroofimage"
              image={imageState.imageWindow}
              scaleX={imageState.imageScale}
              scaleY={imageState.imageScale}
              onTouchStart={handleMoveStart}
              onTouchMove={handleMove}
              onTouchEnd={handleMoveEnd}
              onMouseDown={handleMoveStart}
              onMouseMove={handleMove}
              onMouseUp={handleMoveEnd}
              onMouseLeave={handleMoveLeave}
            />
            {magnifierCursor.cursor ? (
              <Circle
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleMoveStart}
                onMouseMove={handleMove}
                onMouseUp={handleMoveEnd}
                fillPatternImage={imageState.imageWindow}
                fillPatternX={parseInt(
                  -magnifierCursor.cursor.x * magnifierZoom,
                )}
                fillPatternY={parseInt(
                  -magnifierCursor.cursor.y * magnifierZoom,
                )}
                fillPatternScaleX={imageState.imageScale * magnifierZoom}
                fillPatternScaleY={imageState.imageScale * magnifierZoom}
                x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                radius={50}
                stroke={"black"}
                strokeWidth={1}
              />
            ) : null}
            {magnifierCursor.cursor ? (
              <Circle
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleMoveStart}
                onMouseMove={handleMove}
                onMouseUp={handleMoveEnd}
                x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                radius={3}
                fill={"red"}
              />
            ) : null}
            {line.end.x !== null ? (
              <Arrow
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleMoveStart}
                onMouseMove={handleMove}
                onMouseUp={handleMoveEnd}
                pointerAtBeginning={true}
                pointerLength={5}
                pointerWidth={5}
                x={0}
                y={0}
                points={[
                  parseInt(line.start.x),
                  parseInt(line.start.y),
                  parseInt(line.end.x),
                  parseInt(line.end.y),
                ]}
                stroke="red"
                strokeWidth={3}
                listening={false}
              />
            ) : null}
            {line.end.x !== null && line.end.set ? (
              <Circle
                // onTouchStart={handleMoveStart}
                // onTouchEnd={handleMoveEnd}
                onTouchMove={handleMove}
                // onMouseDown={handleMoveStart}
                // onMouseUp={handleMoveEnd}
                onMouseMove={handleMove}
                onDragEnd={setEndLine}
                onDragMove={setEndLine}
                x={parseInt(line.end.x)}
                y={parseInt(line.end.y)}
                radius={35}
                fill={"red"}
                opacity={0}
                draggable
              />
            ) : null}
            {line.start.x !== null ? (
              <Circle
                // onTouchStart={handleMoveStart}
                // onTouchEnd={handleMoveEnd}
                onTouchMove={handleMove}
                // onMouseDown={handleMoveStart}
                // onMouseUp={handleMoveEnd}
                onMouseMove={handleMove}
                onDragEnd={setStartLine}
                onDragMove={setStartLine}
                x={parseInt(line.start.x)}
                y={parseInt(line.start.y)}
                radius={35}
                fill={"red"}
                opacity={0}
                draggable
              />
            ) : null}
          </Layer>
        </Stage>
        <br />
      </Stack>
    </Fragment>
  );
}

RoofScale.propTypes = {
  project: projectPropType,
  image: PropTypes.object,
  onImageWidthChange: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  session: PropTypes.object,
};
