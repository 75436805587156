import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { Input, Table } from "reactstrap";

import { Button, Typography } from "@mui/material";

import { invoicePropType, projectPropType } from "../../elements/PropTypes";
import { CustomIconButton } from "../../elements/StyledElements";
import {
  defaultIfEmpty,
  getOpenInvoiceAmount,
  isInvoiceCompletelyPaid,
  isInvoiceOverpaid,
  numFormatter,
  round,
} from "../../elements/utils";
import {
  API_URL_PAYINVOICE,
  API_URL_SENDPAYMENTCONFIRMATION,
  API_URL_TRANSACTIONS,
} from "../../settings";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import EmailModal from "../shared/modal_utils/EmailModal";

export default function PaidInvoiceModal({
  project,
  invoice,
  resetParent,
  session,
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });
  const [transferred, setTransferred] = useState(null);
  const [payIsOpen, setPayIsOpen] = useState(false);
  const [emailIsOpen, setEmailIsOpen] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [transactionId, setTransactionId] = useState(null);

  const [overpaidModalOpen, setOverpaidModalOpen] = useState(false);

  useEffect(() => {
    if (payIsOpen) loadData();
  }, [payIsOpen]);

  const loadData = () => {
    setEmailIsOpen(false);
    setTransactionId(null);
    getTransactions();
    setTransferred(
      round(invoice.total_gross - invoice.paid - invoice.cash_discount, 2),
    );
    setLoadingElements({ submitError: false, inProgress: false });
  };

  const getTransactions = () => {
    axios
      .get(API_URL_TRANSACTIONS, { params: { matched: false } })
      .then((res) => setTransactions(res.data));
  };

  const selectTransaction = (e, id, amount) => {
    if (id === transactionId) {
      e.target.checked = false;
      setTransactionId(null);
    } else {
      setTransactionId(id);
      setTransferred(amount);
    }
  };

  const payInvoice = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    return Promise.all([
      axios.post(API_URL_PAYINVOICE, { invoice: invoice.id, transferred }),
      transactionId
        ? axios.put(API_URL_TRANSACTIONS + transactionId, { matched: true })
        : Promise.resolve(),
    ])
      .then(() => {
        // resetParent(); // reset parent when email modal is closed
        if (onSuccess) onSuccess();
        setLoadingElements({
          ...loadingElements,
          submitError: false,
          inProgress: false,
        });
      })
      .catch(() => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getOpenButton = (toggle) => {
    const paid = isInvoiceOverpaid(invoice)
      ? false
      : isInvoiceCompletelyPaid(invoice);
    return (
      <CustomIconButton
        title="Bezahlt"
        style={{ float: "right" }}
        description="Bezahlt"
        icon="price"
        size="large"
        color={paid ? "success" : "#d4bc0b"}
        disabled={paid}
        onClick={toggle}
      />
    );
  };

  const onSave = (toggle) => {
    const overpaidAmount =
      invoice.paid + transferred + invoice.cash_discount - invoice.total_gross;
    if (overpaidAmount > 0) {
      setOverpaidModalOpen(true);
    } else {
      payInvoice().then(() => {
        toggle();
        if (transferred >= 0) {
          setEmailIsOpen(true);
        } else {
          resetParent();
        }
      });
    }
  };

  const submit = (subject, message) => {
    return axios.post(API_URL_SENDPAYMENTCONFIRMATION, {
      invoice: invoice.id,
      project: project.id,
      mail: { subject, message },
    });
  };

  const getFooter = (toggle) => {
    return (
      <Fragment>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSave(toggle)}
          onCancel={toggle}
        />
      </Fragment>
    );
  };

  const getOverpaidAlertButton = (toggle) => {
    return (
      <div>
        <Button
          variant="contained"
          color={"success"}
          onClick={() => {
            toggle();
            payInvoice().then(() => {
              setPayIsOpen(false);
              resetParent();
            });
          }}
        >
          Ja
        </Button>
        &nbsp; &nbsp;
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            toggle();
          }}
        >
          Nein
        </Button>
      </div>
    );
  };

  const title = "Wurde die Rechnung " + invoice.id + " bezahlt?";
  return (
    <>
      <CustomModal
        getOpenButton={getOpenButton}
        getFooter={getFooter}
        isOpen={payIsOpen}
        setIsOpen={setPayIsOpen}
        size="lg"
        title={title}
      >
        <Typography className="secondary-textcolor">
          <strong>
            {" "}
            Gesamt: &nbsp;&nbsp;{numFormatter(invoice.total_gross)} €
          </strong>
          <br />
          <strong>
            {" "}
            Offen: &nbsp;
            {isInvoiceOverpaid(invoice)
              ? numFormatter(transferred)
              : numFormatter(getOpenInvoiceAmount(invoice))}{" "}
            €
          </strong>
        </Typography>
        <Table className="table-hover">
          <thead>
            <tr>
              <th className="align-middle" style={{ textAlign: "left" }}>
                <Typography className="secondary-textcolor">Name</Typography>
              </th>
              <th className="align-middle" style={{ textAlign: "left" }}>
                <Typography className="secondary-textcolor">Account</Typography>
              </th>
              <th className="align-middle" style={{ textAlign: "left" }}>
                <Typography className="secondary-textcolor">Amount</Typography>
              </th>
              <th className="align-middle" style={{ textAlign: "left" }}>
                <Typography className="secondary-textcolor">Date</Typography>
              </th>
              <th className="align-middle" style={{ textAlign: "left" }}>
                <Typography className="secondary-textcolor">Comment</Typography>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, tIdx) => (
              <tr key={`table-transaction-${tIdx}`}>
                <td>{transaction.name}</td>
                <td>{transaction.account}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.date}</td>
                <td>{transaction.comment}</td>
                <td>
                  <Input
                    name="transaction_id"
                    type="radio"
                    onClick={(e) => {
                      selectTransaction(e, transaction.id, transaction.amount);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Typography className="secondary-textcolor">
          <strong> Neue Zahlung:</strong>
        </Typography>
        <Input
          step={0.01}
          id="input"
          placeholder="Überwiesen"
          type="number"
          width={"15px"}
          value={defaultIfEmpty(
            transferred !== null ? round(Math.abs(transferred), 2) : null,
          )}
          onChange={(e) => {
            setTransferred(
              e.target.value !== ""
                ? isInvoiceOverpaid(invoice)
                  ? round(parseFloat(e.target.value) * -1, 2)
                  : round(parseFloat(e.target.value), 2)
                : null,
            );
          }}
          onKeyPress={(e) => {
            if (!/[0-9,]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          style={{ boxShadow: "none" }}
          disabled={transactionId !== null}
          min={0}
          onKeyDown={(e) => {
            if (e.key === "-") e.preventDefault();
          }}
        />
      </CustomModal>
      <CustomModal
        size="medium"
        isOpen={overpaidModalOpen}
        setIsOpen={setOverpaidModalOpen}
        title={`Der bezahlte Betrag ist um ${round(invoice.paid + transferred + invoice.cash_discount - invoice.total_gross, 2)}€ höher als der Rechnungsbetrag. Soll der eingegebene Betrag gespeichert werden?`}
        getFooter={getOverpaidAlertButton}
      />
      <EmailModal
        customer={project.customer_obj}
        title={"Eingegange Zahlung"}
        subject={"Eingegangene Zahlung"}
        getEmailMessage={() =>
          axios
            .get(API_URL_SENDPAYMENTCONFIRMATION, {
              params: { invoice: invoice.id },
            })
            .then((res) => {
              let msg = res.data;
              msg = msg.replace(
                "__ZAHLUNGSEINGANG__",
                `${numFormatter(transferred)} €`,
              );
              return { data: msg };
            })
        }
        submit={submit}
        resetParent={resetParent}
        session={session}
        getModalOpenButton={() => null}
        isOpen={emailIsOpen}
        setIsOpen={setEmailIsOpen}
      />
    </>
  );
}

PaidInvoiceModal.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
