import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import CustomerForm from "./CustomerForm";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";

import { API_URL_CUSTOMER, API_URL_CUSTOMERDUPLICATES } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import {
  removeFromObj,
  isValidEmailAddress,
  string2FormattedString,
  getEmptyFieldsError,
  checkIfValueIsEmpty,
} from "../../elements/utils";
import { customerPropType } from "../../elements/PropTypes";
import CollapsibleTable from "../../elements/CollapsibleTable";

export const emptyCustomerForm = {
  id: null,
  gender: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null,
  email: null,
  phone1: null,
  phone2: null,
  phone3: null,
  note: null,
  notes_attention: null,
  iban: null,
  bic: null,
  bank: null,
  account_owner: null,
  tax_number: null,
  data_policy_accepted: false,
  contact_denied: false,
  newsletter_subscribed: true,
};
export default function CustomerFormModal({
  customer,
  resetParent,
  getOpenButton,
  session,
}) {
  const [customerForm, setCustomerForm] = useState({ ...emptyCustomerForm });
  const [customerFormErrors, setCustomerFormErrors] = useState({
    email: null,
    phone1: null,
    phone2: null,
    phone3: null,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [duplicates, setDuplicates] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setCustomerForm(emptyCustomerForm);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    setCustomerForm(customer || { ...emptyCustomerForm });
  };

  useEffect(() => {
    loadData();
  }, [customer]);

  useEffect(() => {
    if (!customer) loadData();
  }, [session.user]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    if (customer) {
      return (
        <CustomButton onClick={toggle} icon="info" style={{ width: "100%" }}>
          Kunde
        </CustomButton>
      );
    }
    return (
      <CustomButton
        onClick={toggle}
        style={{ maxWidth: "300px" }}
        icon="addcustomer"
        className="float-end"
        id="add_customer_btn"
      >
        Kunde hinzufügen
      </CustomButton>
    );
  };

  const submit = async (customerForm) => {
    if (customer) {
      return axios.put(
        API_URL_CUSTOMER + customer.id,
        removeFromObj(customerForm, [
          "accept_mandate_signature",
          "accept_mandate_document",
        ]),
      );
    }
    const promise = axios.post(API_URL_CUSTOMER, customerForm);
    return promise;
  };

  const onSubmit = async (onSuccess, checkForDuplicates = true) => {
    const optionalKeys = [
      "phone2",
      "phone3",
      "note",
      "notes_attention",
      "iban",
      "bic",
      "bank",
      "account_owner",
      "tax_number",
      "user",
      "referral_code",
    ];
    if (customerForm.gender !== "Firma") optionalKeys.push("company");
    if (customerForm.gender === "Firma") optionalKeys.push("first_name");
    if (customerForm.gender === "Firma") optionalKeys.push("last_name");
    if (!customer) optionalKeys.push("id");
    const checkIfEmpty = (key, val) => {
      if (key === "email") return !isValidEmailAddress(customerForm.email);
      return checkIfValueIsEmpty(val);
    };
    const emptyFieldsError = getEmptyFieldsError(
      customerForm,
      emptyCustomerForm,
      optionalKeys,
      checkIfEmpty,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const duplicatePromise =
      customer || !checkForDuplicates
        ? Promise.resolve([])
        : axios
            .get(API_URL_CUSTOMERDUPLICATES, {
              params: {
                company:
                  customerForm.gender === "Firma" ? customerForm.company : "",
                first_name: customerForm.first_name,
                last_name: customerForm.last_name,
                email: customerForm.email,
                phone1: customerForm.phone1,
                phone2: customerForm.phone2,
                phone3: customerForm.phone3,
                leads: true,
              },
            })
            .then((res) => res.data);

    duplicatePromise
      .then((duplicates) => {
        if (duplicates && duplicates.length > 0) {
          setDuplicates(duplicates);
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
          return;
        }
        return submit(customerForm).then((res) => {
          resetParent(res.data);
          onSuccess();
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const isFormError = () => {
    return (
      (!!customerForm.email && !!customerFormErrors.email) ||
      (!!customerForm.phone1 && !!customerFormErrors.phone1) ||
      (!!customerForm.phone2 && !!customerFormErrors.phone2) ||
      (!!customerForm.phone3 && !!customerFormErrors.phone3)
    );
  };
  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_customer_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        disabled={
          (customerForm && !customerForm.data_policy_accepted) || isFormError()
        }
      />
    );
  };

  const getDuplicateFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => {
          toggle();
          onSubmit(() => setIsOpen(false), false);
        }}
        onCancel={toggle}
        saveBtnLabel="Trotzdem Speichern"
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={_getOpenButton}
      title="Kundeninformationen"
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CustomModal
        title="Kunde ist bereits vorhanden"
        size="xl"
        getFooter={getDuplicateFooter}
        isOpen={duplicates !== null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setDuplicates(null);
        }}
        getOpenButton={() => null}
      >
        {duplicates ? (
          <CollapsibleTable
            columns={[
              {
                name: "Nachname",
                key: "last_name",
              },
              {
                name: "Vorname",
                key: "first_name",
              },
              {
                name: "Datum",
                key: "registration",
              },
              {
                name: "Erstellt durch",
                key: "registered_by_name",
              },
              {
                name: "EMail",
                key: "email",
              },
              {
                name: "Telefon",
                key: "phone",
              },
              {
                name: "Typ",
                key: "duplicate_type",
              },
            ]}
            rows={duplicates.map((customer) => ({
              key: `${customer.id}`,
              first_name: customer.first_name,
              last_name: customer.last_name,
              registration: string2FormattedString(
                customer.registration_date || customer.registration_datetime,
              ),
              registered_by_name: customer.registered_by_name,
              email: customer.email,
              phone: [customer.phone1, customer.phone2, customer.phone3]
                .filter((x) => x)
                .join("; "),
              duplicate_type:
                customer.duplicate_type === "customer" ? "Kunde" : "Lead",
            }))}
            collapsible={false}
          />
        ) : null}
      </CustomModal>
      <CustomerForm
        customer={customerForm}
        errors={customerFormErrors}
        setErrors={setCustomerFormErrors}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setCustomer={setCustomerForm}
      />
    </CustomModal>
  );
}

CustomerFormModal.propTypes = {
  customer: customerPropType,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
};
