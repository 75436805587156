import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "reactstrap";
import { Typography, Stack } from "@mui/material";

import UploadImage from "./UploadImage";

import DropDown from "../../../elements/DropDown";
import { isMobileOnly } from "react-device-detect";

export default function UploadRoofImage({
  direction,
  setDirection,
  flatRoof,
  setFlatRoof,
  image,
  setImage,
  maxWidth,
  maxHeight,
}) {
  const colWidth = isMobileOnly ? 0 : 300;
  const imageMaxWidth = maxWidth - colWidth;
  const imageMaxHeight = maxHeight - 100;
  const directions = [
    "Nord",
    "Nord-Ost",
    "Ost",
    "Süd-Ost",
    "Süd",
    "Süd-West",
    "West",
    "Nord-West",
    "Ost/West",
  ];
  const [directionOptions, setDirectionOptions] = useState(directions);
  useEffect(() => {
    if (flatRoof) {
      setDirectionOptions(["Süd", "Ost/West"]);
    } else {
      setDirectionOptions(directions);
    }
  }, [flatRoof, direction]);

  return (
    <Fragment key="roof_image_planning">
      <Row>
        <Col style={isMobileOnly ? {} : { maxWidth: colWidth }}>
          <Typography fontSize="h6.fontSize" className="secondary-textcolor">
            Informationen:
          </Typography>
          <br />
          <Stack direction="column" spacing={2}>
            {setDirection && (
              <DropDown
                id="set-roof-direction-dropdown"
                onChange={setDirection}
                text="Himmelsrichtung"
                options={directionOptions}
                value={direction}
                style={{ width: 200 }}
              />
            )}
            <DropDown
              id="set-flat-roof-dropdown"
              onChange={setFlatRoof}
              text="Dachtyp"
              style={{ width: 200 }}
              options={[
                { value: false, label: "Satteldach" },
                { value: true, label: "Flachdach" },
              ]}
              value={flatRoof}
            />
          </Stack>
        </Col>
        <Col>
          <Typography fontSize="h6.fontSize" className="secondary-textcolor">
            Dachfoto auswählen:
          </Typography>
          <br />
          <UploadImage
            id="roof-img-input"
            image={image}
            setImage={setImage}
            maxWidth={imageMaxWidth}
            maxHeight={imageMaxHeight}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

UploadRoofImage.propTypes = {
  direction: PropTypes.string,
  setDirection: PropTypes.func,
  flatRoof: PropTypes.bool,
  setFlatRoof: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setImage: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  session: PropTypes.object,
};
