import React, { useState } from "react";

import { PropTypes } from "prop-types";
import { Table } from "react-bootstrap";

import Typography from "@material-ui/core/Typography";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import "./CollapsibleTableStyle.css";
import CollapsibleTableRow, { CollapseButton } from "./CollapsibleTableRow";

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => (
  <CollapsibleTableRow {...props} />
));

export default function CollapsibleTable({
  columns,
  rows,
  counter = false,
  collapsible = true,
  collapseAllButton = false,
  header = true,
  startCounter = 1,
  hover = true,
  buttomLine = true,
  isDraggable = false,
  changeOrder,
  targetBlank = false,
}) {
  // column: {style: <style>, name: <name>, key: <key>, clickable: <true/false>}
  const [openRow, setOpenRow] = useState(null);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    changeOrder(oldIndex, newIndex);
  };

  return (
    <Table
      className={hover ? "my-table-hover" : "my-table-not-hover"}
      style={{ margin: 0 }}
    >
      <thead>
        <tr style={header ? {} : { display: "none" }} key={"table-row-header"}>
          {isDraggable ? <th></th> : null}
          {collapsible ? (
            <th
              className="align-middle"
              style={{ width: "5%", textAlign: "left" }}
            >
              {collapseAllButton ? (
                <CollapseButton
                  onClick={() => setOpenRow(openRow === -1 ? null : -1)}
                  isOpen={openRow === -1}
                />
              ) : null}
            </th>
          ) : null}
          {counter ? (
            <th
              className="align-middle"
              style={{ width: "5%", textAlign: "left" }}
            >
              <Typography className="secondary-textcolor">#</Typography>
            </th>
          ) : null}
          {columns.map((column, cIdx) => (
            <th
              key={`table-column-header-${cIdx}`}
              style={column.style ? column.style : {}}
            >
              <Typography className="secondary-textcolor">
                {column.name}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      {isDraggable ? (
        <SortableCont
          axis="y"
          onSortEnd={onSortEnd}
          useDragHandle={true}
          helperClass="sortable-helper"
        >
          {rows.map((row, index) => (
            <SortableItem
              key={`table-row-${index}`}
              index={index}
              row={row}
              rowsLength={rows.length}
              counter={counter}
              collapsible={collapsible}
              startCounter={startCounter}
              hover={hover}
              buttomLine={buttomLine}
              openRow={openRow}
              setOpenRow={setOpenRow}
              isDraggable={isDraggable}
              colSpan={counter ? columns.length + 1 : columns.length}
              columns={columns}
            />
          ))}
        </SortableCont>
      ) : (
        <tbody>
          {rows.map((row, rIdx) => {
            return (
              <CollapsibleTableRow
                key={`table-row-${rIdx}`}
                row={row}
                index={rIdx}
                rowsLength={rows.length}
                counter={counter}
                collapsible={collapsible}
                startCounter={startCounter}
                hover={hover}
                buttomLine={buttomLine}
                openRow={openRow}
                setOpenRow={setOpenRow}
                isDraggable={isDraggable}
                colSpan={counter ? columns.length + 1 : columns.length}
                columns={columns}
                targetBlank={targetBlank}
              />
            );
          })}
        </tbody>
      )}
    </Table>
  );
}

CollapsibleTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  counter: PropTypes.bool,
  collapsible: PropTypes.bool,
  collapseAllButton: PropTypes.bool,
  header: PropTypes.bool,
  startCounter: PropTypes.number,
  hover: PropTypes.bool,
  buttomLine: PropTypes.bool,
  isDraggable: PropTypes.bool,
  changeOrder: PropTypes.func,
  targetBlank: PropTypes.bool,
};
