import React, { useState } from "react";
import { PropTypes } from "prop-types";
import CustomModal from "../shared/modal_utils/CustomModal";
import { CustomButton } from "../../elements/StyledElements";
import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DefectiveDeviceForm from "./DefectiveDeviceForm";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import axios from "axios";
import { defectiveDevicePropType } from "../../elements/PropTypes";
import { API_URL_DEFECTIVEDEVICE } from "../../settings";
import { isString, removeFromObj } from "../../elements/utils";

const emptyDefectiveDeviceForm = {
  id: null,
  name: null,
  manufacturer: null,
  serial_id: null,
  type: null,
  supplier: null,
  description: null,
  label: null,
  photo: null,
  additional_file: null,
};

export default function DefectiveDeviceModal({
  session,
  style,
  resetParent,
  defectiveDevice,
  defectiveDeviceLabels,
  defectiveDeviceManufacturer,
  defectiveDeviceType,
  title,
}) {
  const [defectiveDeviceForm, setDefectiveDeviceForm] = useState({
    ...emptyDefectiveDeviceForm,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const getOpenButton = (toggle) => {
    if (defectiveDevice) {
      return (
        <Tooltip
          title="Produktinformationen"
          PopperProps={{ style: { zIndex: 9999 } }}
        >
          <IconButton
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent", float: "right" }}
            size="small"
            onClick={toggle}
          >
            <InfoOutlinedIcon
              className="secondary-textcolor"
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <CustomButton onClick={toggle} style={style}>
        Defektes Gerät hinzufügen
      </CustomButton>
    );
  };
  const getFooter = (onToggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(onToggle)}
      />
    );
  };

  const submit = async (defectiveDeviceForm) => {
    let photo = null;
    let file = null;
    if (defectiveDeviceForm.photo || defectiveDeviceForm.additional_file) {
      if (
        !isString(defectiveDeviceForm.photo) ||
        !isString(defectiveDeviceForm.additional_file)
      ) {
        photo = defectiveDeviceForm.photo;
        file = defectiveDeviceForm.additional_file;
      }
      defectiveDeviceForm = removeFromObj(defectiveDeviceForm, [
        "photo",
        "additional_file",
      ]);
    }
    const promise = defectiveDevice
      ? axios.put(
          API_URL_DEFECTIVEDEVICE + defectiveDevice.id,
          defectiveDeviceForm,
        )
      : axios.post(API_URL_DEFECTIVEDEVICE, defectiveDeviceForm);
    return promise.then((res) => {
      const promises = [];
      if (photo) {
        const formData = new FormData();
        formData.append("photo", photo);
        promises.push(
          axios.put(API_URL_DEFECTIVEDEVICE + res.data.id, formData),
        );
      }
      if (file) {
        const formData = new FormData();
        formData.append("additional_file", file);
        promises.push(
          axios.put(API_URL_DEFECTIVEDEVICE + res.data.id, formData),
        );
      }
      return Promise.all(promises).then(() => res);
    });
  };

  const onSubmit = async (onToggle) => {
    const optionalKeys = ["photo", "additional_file"];
    if (!defectiveDevice) optionalKeys.push("id");
    const defectiveDeviceKeys = Object.keys(defectiveDeviceForm).filter(
      (x) => !optionalKeys.includes(x),
    );
    const emptyFields = defectiveDeviceKeys.filter(
      (key) =>
        defectiveDeviceForm[key] === "" || defectiveDeviceForm[key] === null,
    );
    if (emptyFields.length > 0) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        showMissingFields: true,
      });
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit(defectiveDeviceForm)
      .then((res) => {
        resetParent();
        onToggle();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };
  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };
  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setDefectiveDeviceForm({ ...emptyDefectiveDeviceForm });
  };
  const loadData = () => {
    setDefectiveDeviceForm(defectiveDevice || { ...emptyDefectiveDeviceForm });
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title={title}
      getFooter={getFooter}
      onToggle={onToggle}
      size={200}
    >
      <DefectiveDeviceForm
        session={session}
        defectiveDevice={defectiveDeviceForm}
        setDefectiveDevice={setDefectiveDeviceForm}
        showMissingFields={loadingElements.showMissingFields}
        manufacturer={defectiveDeviceManufacturer}
        types={defectiveDeviceType}
        labels={defectiveDeviceLabels}
        resetParent={resetParent}
      />
    </CustomModal>
  );
}

DefectiveDeviceModal.propTypes = {
  session: PropTypes.object.isRequired,
  style: PropTypes.object,
  defectiveDevices: PropTypes.array,
  defectiveDeviceLabels: PropTypes.array,
  defectiveDeviceManufacturer: PropTypes.array,
  defectiveDeviceType: PropTypes.array,
  resetParent: PropTypes.func,
  title: PropTypes.string,
  defectiveDevice: defectiveDevicePropType,
};
