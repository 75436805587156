import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { DAPI_URL_TASKSTATS } from "../../settings";
import LoadingPage from "../../elements/LoadingPage";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { Typography } from "@mui/material";
import { userWithLabel } from "../../elements/utils";

export default function TaskStatistics({ session }) {
  const [taskStats, setTaskStats] = useState(null);

  useEffect(() => {
    axios.get(DAPI_URL_TASKSTATS).then((res) => {
      const stats = res.data;
      // stats.sort((a, b) => a.employee_name.localeCompare(b.employee_name))
      setTaskStats(stats);
    });
  }, []);

  if (!taskStats) return <LoadingPage />;
  return (
    <Fragment>
      <CollapsibleTable
        columns={[
          { key: "employee", name: "" },
          ...taskStats.months.map((month, mIdx) => ({
            key: `${mIdx}`,
            name: month,
          })),
        ]}
        rows={taskStats.stats
          .sort((a, b) => a.employee_name.localeCompare(b.employee_name))
          .map((stats) => {
            const data = { employee: userWithLabel(stats) };
            taskStats.months.forEach((month, mIdx) => {
              data[`${mIdx}`] = (
                <>
                  {/* <Typography>{stats.scheduled_tasks_finished[mIdx]}/{stats.scheduled_tasks[mIdx]} ({stats.scheduled_tasks[mIdx] === 0 ? '-' : Math.round(stats.scheduled_tasks_finished[mIdx] / stats.scheduled_tasks[mIdx] * 100)}%)</Typography> */}
                  <Typography>
                    {stats.tasks_finished[mIdx]}/{stats.tasks[mIdx]} (
                    {stats.tasks[mIdx] === 0
                      ? "  -"
                      : Math.round(
                          (stats.tasks_finished[mIdx] / stats.tasks[mIdx]) *
                            100,
                        )}
                    %)
                  </Typography>
                </>
              );
            });
            return data;
          })}
        collapsible={false}
      />
    </Fragment>
  );
}

TaskStatistics.propTypes = {
  session: PropTypes.object,
};
