import React, { useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { IconButton, Typography } from "@mui/material";

import {
  attachmentPropType,
  customerPropType,
} from "../../../elements/PropTypes";
import {
  defaultIfEmpty,
  isString,
  wrapEmailMessage,
} from "../../../elements/utils";
import CustomModal from "./CustomModal";
import EmailModalFooter from "./EmailModalFooter";
import PdfViewModal from "./PdfViewModal";

export default function EmailModal({
  customer,
  title,
  subject,
  getEmailMessage,
  submit,
  skip,
  resetParent,
  getModalOpenButton,
  isOpen,
  setIsOpen,
  onToggle,
  address = null,
  attachments = [],
  session,
  emailMessage = null,
  emailDestination = null,
  onDone = null,
}) {
  const [currentSubject, setCurrentSubject] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    skipInProgress: false,
  });
  const [disabled, setDisabled] = useState(true);
  const [curEmailDestination, setCurEmailDestination] = useState("");

  const _onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
    if (onToggle) onToggle(isOpen);
  };

  const loadData = () => {
    if (emailDestination) setCurEmailDestination(emailDestination);

    if (emailMessage) {
      setCurrentSubject(subject);
      setCurrentMessage(
        wrapEmailMessage(session, emailMessage, customer, address),
      );
      setDisabled(false);
      return;
    }

    const getEmailMessageFcn = isString(getEmailMessage)
      ? () => axios.get(getEmailMessage)
      : getEmailMessage;
    getEmailMessageFcn().then((res) => {
      setCurrentSubject(subject);
      setCurrentMessage(wrapEmailMessage(session, res.data, customer, address));
      setDisabled(false);
    });
  };

  const clearData = () => {
    setCurrentSubject("");
    setCurrentMessage("");
    setLoadingElements({
      inProgress: false,
      submitError: false,
      skipInProgress: false,
    });
    setDisabled(true);
  };

  const getOpenButton = (toggle) => {
    if (getModalOpenButton) return getModalOpenButton(toggle);
    return (
      <IconButton
        disableFocusRipple
        disableRipple
        style={{ backgroundColor: "transparent", float: "right" }}
        size="small"
        onClick={toggle}
      >
        <MailOutlineIcon style={{ color: "#d4bc0b" }} fontSize="large" />
      </IconButton>
    );
  };

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });

    const submitPromise = emailDestination
      ? submit(currentSubject, currentMessage, curEmailDestination)
      : submit(currentSubject, currentMessage);

    return submitPromise
      .then((res) => {
        if (resetParent) resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .then(() => {
        if (setIsOpen) setIsOpen(false);
      })
      .catch((error) => {
        console.error("Error in Email Modal:", error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const onSkip = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      skipInProgress: true,
      submitError: false,
    });
    const skipFcn = skip || (() => Promise.resolve());
    return skipFcn()
      .then(() => {
        if (resetParent) resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          skipInProgress: false,
          submitError: false,
        });
      })
      .then(() => {
        if (setIsOpen) setIsOpen(false);
      })
      .catch((error) => {
        console.error("Error in Email Modal:", error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          skipInProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <EmailModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        disabled={disabled}
        skipInProgress={loadingElements.skipInProgress}
        onSave={() => {
          const result = onSubmit(toggle);
          if (onDone) onDone();
          return result;
        }}
        onSkip={() => {
          const result = onSkip(toggle);
          if (onDone) onDone();
          return result;
        }}
      />
    );
  };

  title = title || "E-Mail Senden";

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      title={title}
      onToggle={_onToggle}
    >
      <Form>
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          E-Mail verfassen
        </Typography>
        <br />
        {emailDestination && (
          <FormGroup>
            <Typography className="secondary-textcolor">
              E-Mail-Adresse:
            </Typography>
            <Input
              id="input"
              type="text"
              name="emailDestination"
              onChange={(e) => setCurEmailDestination(e.target.value)}
              value={defaultIfEmpty(curEmailDestination)}
              required={false}
              style={{ boxShadow: "none" }}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Typography className="secondary-textcolor">Betreff:</Typography>
          <Input
            id="input"
            type="text"
            name="subject"
            onChange={(e) => setCurrentSubject(e.target.value)}
            value={defaultIfEmpty(currentSubject)}
            required={false}
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
        <FormGroup>
          <Typography className="secondary-textcolor">Nachricht:</Typography>
          <Input
            style={{ height: 300, boxShadow: "none" }}
            id="input"
            name="emailText"
            onChange={(e) => setCurrentMessage(e.target.value)}
            type="textarea"
            value={defaultIfEmpty(currentMessage)}
          />
        </FormGroup>
        {attachments.length > 0 && (
          <FormGroup>
            <Typography className="secondary-textcolor">Anhänge:</Typography>
            {attachments.map((attachment) => {
              if (attachment.type === "pdf" && !!attachment.data) {
                const fileName = attachment.name;
                const fileData = attachment.data;
                return (
                  <>
                    <Typography
                      className="secondary-textcolor"
                      display="inline"
                    >
                      {fileName}{" "}
                    </Typography>
                    <PdfViewModal
                      title={fileName}
                      filepath={
                        isString(fileData)
                          ? fileData
                          : URL.createObjectURL(fileData)
                      }
                    />
                  </>
                );
              } else {
                return <></>;
              }
            })}
          </FormGroup>
        )}
      </Form>
    </CustomModal>
  );
}

EmailModal.propTypes = {
  customer: customerPropType,
  title: PropTypes.string,
  subject: PropTypes.string,
  getEmailMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  submit: PropTypes.func,
  skip: PropTypes.func,
  resetParent: PropTypes.func,
  getModalOpenButton: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onToggle: PropTypes.func,
  address: PropTypes.string,
  attachments: PropTypes.arrayOf(attachmentPropType),
  session: PropTypes.object,
  emailMessage: PropTypes.string,
  emailDestination: PropTypes.string,
  onDone: PropTypes.func,
};
