import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import axios from "axios";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import Collapsible from "../elements/Collapsible";
import DateInput from "../elements/DateInput";
import DropDown from "../elements/DropDown";
import LoadingPage from "../elements/LoadingPage";
import {
  addDaysToDate,
  date2String,
  hasPermission,
  hexToRGB,
  string2FormattedString,
} from "../elements/utils";
import { getNextDate } from "./tasks/utils";
import {
  API_URL_CONSTRUCTION,
  API_URL_GROUP,
  API_URL_TASK,
  API_URL_TASKLABEL,
  API_URL_TASKTYPE,
  API_URL_USER,
  API_URL_VEHICLE,
} from "../settings";
import EventMap from "./map/EventMap";

import L from "leaflet";
import {
  ProjectType2ConstructionType,
  projectTypes as allProjectTypes,
  getAdditionalMapPopupDescription,
} from "./project_types/projectUtils";
import AddressSearch from "./map/AddressSearch";
import { CustomButton } from "../elements/StyledElements";

function getCarMarkerIcon(color) {
  const markerIcon = new L.DivIcon({
    className: "svg-icon",
    iconSize: [24, 40],
    iconAnchor: [8, 20],
    html: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="${color}">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path stroke="#000000" d="M18.92 5.01C18.72 4.42 18.16 4 17.5 4h-11c-.66 0-1.21.42-1.42 1.01L3 11v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 15c-.83 0-1.5-.67-1.5-1.5S5.67 12 6.5 12s1.5.67 1.5 1.5S7.33 15 6.5 15zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z"/>
</svg>`,
  });
  return markerIcon;
}

const getCoordinatesFromString = (string) => {
  if (!string) return null;
  try {
    let [lat, lng] = string.split(",");
    lat = parseFloat(lat);
    lng = parseFloat(lng);
    return !isNaN(lat) && !isNaN(lng) ? { lat, lng } : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const searchParamTranslate = {
  group: "rolle",
  employee: "mitarbeiter",
  showTasks: "aufgaben",
  includeNotScheduled: "nicht-terminiert",
  taskType: "aufgaben-typ",
  taskLabel: "label",
  showConstructions: "baustellen",
  projectType: "projekt-typ",
  showCars: "autos",
  car: "auto",
};

export default function Map({ session }) {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const permissionAllTasks = hasPermission(session.user, "tasks_all_employees");
  const projectTypes = allProjectTypes.filter((t) =>
    ProjectType2ConstructionType(t.key),
  );
  const history = useHistory();
  const defaultFilter = {
    startDate: new Date(),
    endDate:
      searchParams.get("heute") !== null
        ? new Date()
        : addDaysToDate(new Date(), 7 * 4),
    group: searchParams.get("rolle")
      ? parseInt(searchParams.get("rolle"))
      : null,
    employee: permissionAllTasks
      ? searchParams.get("mitarbeiter")
        ? parseInt(searchParams.get("mitarbeiter"))
        : null
      : session.user.id,
    showTasks: searchParams.get("aufgaben") === null,
    includeNotScheduled: searchParams.get("nicht-terminiert") === null,
    taskType: searchParams.get("aufgaben-typ")
      ? parseInt(searchParams.get("aufgaben-typ"))
      : null,
    taskLabel: searchParams.get("label")
      ? parseInt(searchParams.get("label"))
      : null,
    showConstructions: searchParams.get("baustellen") === null,
    projectType: searchParams.get("projekt-typ"),
    showCars: searchParams.get("autos") === null,
    car: searchParams.get("auto") ? parseInt(searchParams.get("auto")) : null,
    currentLocation: getCoordinatesFromString(searchParams.get("koordinaten")),
  };

  const [filter, setFilter] = useState(defaultFilter);
  const [tasks, setTasks] = useState(null);
  const [cars, setCars] = useState(null);
  const [taskLabels, setTaskLabels] = useState(null);
  const [taskTypes, setTaskTypes] = useState(null);
  const [constructions, setConstructions] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [groups, setGroups] = useState(null);

  const allCars = useRef(null);

  const handleChange = (key, value) => {
    const newFilter = { ...filter, [key]: value };
    const boolKey = [
      "showTasks",
      "includeNotScheduled",
      "showConstructions",
      "showCars",
    ];

    if (key in searchParamTranslate) {
      if (boolKey.includes(key)) {
        if (!value) {
          searchParams.set(searchParamTranslate[key], value);
          if (key === "showTasks") {
            searchParams.delete("nicht-terminiert");
            searchParams.delete("aufgaben-typ");
            searchParams.delete("label");
            newFilter.includeNotScheduled = true;
            newFilter.taskType = null;
            newFilter.taskLabel = null;
          }
          if (key === "showConstructions") {
            searchParams.delete("projekt-typ");
            newFilter.projectType = null;
          }
          if (key === "showCars") {
            searchParams.delete("auto");
            newFilter.car = null;
          }
        } else {
          searchParams.delete(searchParamTranslate[key]);
        }
      } else {
        if (value) {
          searchParams.set(searchParamTranslate[key], value);
        } else {
          searchParams.delete(searchParamTranslate[key]);
        }
      }

      history.push({ search: searchParams.toString() });
    }

    setFilter(newFilter);
  };

  useEffect(() => {
    setCars(
      allCars.current &&
        (filter.car
          ? allCars.current.filter((c) => c.id === filter.car)
          : allCars.current),
    );
  }, [filter.car, allCars.current]);

  useEffect(() => {
    if (permissionAllTasks) {
      axios
        .get(API_URL_USER, {
          params: { is_staff: true, visible: true, is_active: true },
        })
        .then((res) => setEmployees(res.data));
      axios.get(API_URL_GROUP).then((res) => setGroups(res.data));
    }
    axios.get(API_URL_TASKTYPE).then((res) => setTaskTypes(res.data));
    axios.get(API_URL_TASKLABEL).then((res) => setTaskLabels(res.data));
  }, []);

  useEffect(() => {
    axios
      .get(API_URL_TASK, {
        params: {
          start_date: date2String(filter.startDate),
          end_date: filter.endDate && date2String(filter.endDate),
          project_assigned: true,
          include_not_scheduled: filter.includeNotScheduled,
          finished: false,
          on_site: true,
          employees: filter.employee,
          task_type: filter.taskType,
          labels: filter.taskLabel,
          group: filter.group,
          // nested: true,
        },
      })
      .then((res) => {
        setTasks(res.data);
      });
  }, [
    filter.startDate,
    filter.endDate,
    filter.group,
    filter.employee,
    filter.includeNotScheduled,
    filter.taskType,
    filter.taskLabel,
  ]);

  useEffect(() => {
    axios
      .get(API_URL_CONSTRUCTION, {
        params: {
          start_date: date2String(filter.startDate),
          end_date: filter.endDate && date2String(filter.endDate),
          employees: filter.employee,
          group: filter.group,
          project_type: filter.projectType,
        },
      })
      .then((res) => {
        setConstructions(res.data);
      });
  }, [
    filter.startDate,
    filter.endDate,
    filter.group,
    filter.employee,
    filter.projectType,
  ]);

  useEffect(() => {
    axios
      .get(API_URL_VEHICLE, {
        params: { date: date2String(filter.startDate), nested: false },
      })
      .then((res) => {
        allCars.current = res.data;
      });
  }, [filter.startDate]);

  const carMarkers =
    filter.showCars &&
    cars &&
    cars.map((car) => ({
      coords: [car.last_state.lat, car.last_state.lng],
      popup: (
        <p>
          <b>{car.plate}</b> {car.brand_model}
        </p>
      ),
      color: car.color,
      getIcon: getCarMarkerIcon,
    }));

  const taskType2Color = taskTypes
    ? Object.fromEntries(taskTypes.map((t) => [t.id, t.color]))
    : {};
  taskType2Color.null = "#acacad";

  const taskBorderColor = "#f7313f";
  let includedTaskTypes = new Set([]);
  const taskMarkers =
    filter.showTasks &&
    tasks &&
    tasks.map((task) => {
      includedTaskTypes.add(task.task_type);
      return {
        coords: task.lat_lng,
        popup: (
          <Link
            to={`/aufgabe/${task.id}`}
            style={{
              textDecoration: "none",
              display: "contents",
              color: "black",
            }}
          >
            <div style={{ cursor: "pointer" }}>
              {task.taskdates_set && task.taskdates_set.length > 0 ? (
                <>
                  <b>Datum:</b>{" "}
                  {string2FormattedString(getNextDate(task.taskdates_set))}
                  <br />
                </>
              ) : (
                <>
                  <b>Datum: Nicht Terminierte</b> <br />
                </>
              )}
              <b>Kunde:</b> {`${task.customer_name}`}
              <br />
              <b>Aufgabe:</b> {task.title}
              <br />
              <b>Adresse:</b> {task.address}
              <br />
              {task.employee_names.length > 0 ? (
                <>
                  <b>Mitarbeiter:</b> {task.employee_names.join(", ")}
                  <br />
                </>
              ) : null}
            </div>
          </Link>
        ),
        color: taskType2Color[task.task_type] || taskType2Color.null,
        borderColor: taskBorderColor,
      };
    });
  includedTaskTypes = Array.from(includedTaskTypes);
  const notAssignedTasksIncluded = includedTaskTypes.includes(null);
  includedTaskTypes = includedTaskTypes.filter((t) => t);
  const taskLegend = taskMarkers &&
    taskMarkers.length > 0 && [
      { label: "Aufgaben" },
      ...(taskTypes || [])
        .filter((t) => includedTaskTypes.includes(t.id))
        .map((t) => ({
          label: t.name,
          color: t.color,
          borderColor: taskBorderColor,
        })),
      ...(notAssignedTasksIncluded
        ? [
            {
              label: "Nicht zugeordnet",
              color: taskType2Color.null,
              borderColor: taskBorderColor,
            },
          ]
        : []),
    ];

  const constructionTypeType2Color = Object.fromEntries(
    projectTypes.map((t) => [ProjectType2ConstructionType(t.key), t.color]),
  );
  const constructionBorderColor = "#000000";
  let includedConstructionTypes = new Set([]);
  const constructionMarkers =
    filter.showConstructions &&
    constructions &&
    constructions.map((construction) => {
      includedConstructionTypes.add(construction.resourcetype);
      return {
        coords: construction.lat_lng,
        popup: (
          <Link
            to={`/projekt/${construction.project}/bauplanung`}
            style={{
              textDecoration: "none",
              display: "contents",
              color: "black",
            }}
          >
            <div style={{ cursor: "pointer" }}>
              {construction.constructiondates_set &&
              construction.constructiondates_set.length > 0 ? (
                <>
                  <b>Datum:</b>{" "}
                  {string2FormattedString(
                    getNextDate(construction.constructiondates_set),
                  )}
                  <br />
                </>
              ) : (
                <>
                  <b>Datum: Nicht Terminierte</b> <br />
                </>
              )}
              <b>Kunde:</b> {`${construction.customer_name}`}
              <br />
              <b>Adresse:</b>{" "}
              {`${construction.street_and_number} ${construction.zip_and_city}`}
              <br />
              {construction.employee_names.length > 0 ? (
                <>
                  <b>Mitarbeiter:</b> {construction.employee_names.join(", ")}
                  <br />
                </>
              ) : null}
              {getAdditionalMapPopupDescription(construction)}
            </div>
          </Link>
        ),
        color: constructionTypeType2Color[construction.resourcetype],
        borderColor: constructionBorderColor,
      };
    });
  includedConstructionTypes = Array.from(includedConstructionTypes);
  const constructionLegend = constructionMarkers &&
    constructionMarkers.length > 0 && [
      { label: "Baustellen" },
      ...projectTypes
        .filter((t) =>
          includedConstructionTypes.includes(
            ProjectType2ConstructionType(t.key),
          ),
        )
        .map((t) => ({
          label: t.name,
          color: t.color,
          borderColor: constructionBorderColor,
        })),
    ];

  if (!cars || !tasks || !constructions) return <LoadingPage />;

  const gridItemProps = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };

  return (
    <Fragment>
      <Collapsible
        getOpenButton={(toggle) => (
          <IconButton
            size="medium"
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent" }}
            onClick={toggle}
          >
            <FilterAltOutlinedIcon
              style={{ color: "#424242" }}
              fontSize="medium"
            />
            <Typography className="secondary-textcolor">Filtern</Typography>
          </IconButton>
        )}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0px",
            marginLeft: "20px",
            marginRight: "30px",
            marginBottom: "10px",
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item {...gridItemProps}>
            <Typography>Startdatum</Typography>
            <DateInput
              value={filter.startDate}
              onChange={(date) => setFilter({ ...filter, startDate: date })}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <Typography>Enddatum</Typography>
            <DateInput
              value={filter.endDate}
              onChange={(date) => setFilter({ ...filter, endDate: date })}
            />
          </Grid>
          {employees && (
            <Grid item {...gridItemProps}>
              <DropDown
                value={filter.employee}
                onChange={(e) => handleChange("employee", e)}
                options={(employees || []).map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                text="Mitarbeiter"
                search={true}
              />
            </Grid>
          )}
          <Grid item {...gridItemProps}>
            {groups && (
              <DropDown
                value={filter.group}
                onChange={(e) => handleChange("group", e)}
                options={groups.map((g) => ({ value: g.id, label: g.name }))}
                text="Rolle"
                search={true}
              />
            )}
          </Grid>
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            {/* <Grid item xs={12} md={12} lg={12} xl={12}> */}
            {/* <Typography fontWeight={'bold'}>Aufgaben</Typography> */}
            <FormControlLabel
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  checked={!!filter.showTasks}
                  onChange={(e) => handleChange("showTasks", e.target.checked)}
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                />
              }
              label={<Typography fontWeight={"bold"}>Aufgaben</Typography>}
            />
          </Grid>
          {filter.showTasks && taskTypes && (
            <Grid item {...gridItemProps}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    checked={!!filter.includeNotScheduled}
                    onChange={(e) =>
                      handleChange("includeNotScheduled", e.target.checked)
                    }
                    style={{ color: "#424242", backgroundColor: "transparent" }}
                  />
                }
                label="Nicht terminierte Aufgaben"
              />
            </Grid>
          )}
          {filter.showTasks && taskTypes && (
            <Grid item {...gridItemProps}>
              <DropDown
                value={filter.taskType}
                onChange={(e) => handleChange("taskType", e)}
                options={taskTypes.map((t) => ({
                  value: t.id,
                  label: t.name,
                  props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } },
                }))}
                text="Aufgaben-Typ"
                search={true}
              />
            </Grid>
          )}
          {filter.showTasks && taskLabels && (
            <Grid item {...gridItemProps}>
              <DropDown
                value={filter.taskLabel}
                onChange={(e) => handleChange("taskLabel", e)}
                options={taskLabels.map((t) => ({
                  value: t.id,
                  label: t.name,
                  props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } },
                }))}
                text="Label"
                search={true}
              />
            </Grid>
          )}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            <FormControlLabel
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  checked={!!filter.showConstructions}
                  onChange={(e) =>
                    handleChange("showConstructions", e.target.checked)
                  }
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                />
              }
              label={<Typography fontWeight={"bold"}>Baustellen</Typography>}
            />
          </Grid>
          {filter.showConstructions && projectTypes && (
            <Grid item {...gridItemProps}>
              <DropDown
                value={filter.projectType}
                onChange={(e) => handleChange("projectType", e)}
                options={projectTypes.map((t) => ({
                  value: t.key,
                  label: t.name,
                  props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } },
                }))}
                text="Projekt-Typ"
                search={true}
              />
            </Grid>
          )}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            <FormControlLabel
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  checked={!!filter.showCars}
                  onChange={(e) => handleChange("showCars", e.target.checked)}
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                />
              }
              label={<Typography fontWeight={"bold"}>Autos</Typography>}
            />
          </Grid>
          {filter.showCars && cars && (
            <Grid item {...gridItemProps}>
              <DropDown
                value={filter.car}
                onChange={(e) => handleChange("car", e)}
                options={cars.map((c) => ({
                  value: c.id,
                  label: c.plate,
                  props: { style: { backgroundColor: hexToRGB(c.color, 0.5) } },
                }))}
                text="Auto"
                search={true}
              />
            </Grid>
          )}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            <AddressSearch
              setLocation={(val) =>
                setFilter({ ...filter, currentLocation: val })
              }
              session={session}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <CustomButton
              onClick={() => {
                setFilter(defaultFilter);
                const heute = searchParams.get("heute") !== null ? "true" : "";
                history.push({ search: heute ? `?heute=${heute}` : "" });
              }}
            >
              Filter zurücksetzen
            </CustomButton>
          </Grid>
        </Grid>
      </Collapsible>
      <EventMap
        location={filter.currentLocation}
        markers={[
          ...(taskMarkers || []),
          ...(constructionMarkers || []),
          ...(carMarkers || []),
        ]}
        legend={[...(constructionLegend || []), ...(taskLegend || [])]}
      />
    </Fragment>
  );
}

Map.propTypes = {
  session: PropTypes.object,
};
