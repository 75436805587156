import React, { useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import { FormGroup } from "@mui/material";

import SupplierForm from "./SupplierForm";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_WAREHOUSESUPPLIER } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import { customerPropType } from "../../elements/PropTypes";
import DropDown from "../../elements/DropDown";
import { defaultIfEmpty } from "../../elements/utils";

const emptySupplierForm = {
  id: null,
  name: null,
  phone: null,
  email: null,
  contact_persons: [],
};

export default function SupplierModal({
  suppliers,
  customer,
  resetParent,
  session,
  style,
  employee,
}) {
  const [supplierForm, setSupplierForm] = useState({
    ...emptySupplierForm,
    request_as: employee,
  });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);

  const handleSelectSupplier = (val) => {
    setSelectedSupplierId(val);
    const form =
      suppliers.filter((e) => e.id === val).length > 0
        ? suppliers.filter((e) => e.id === val)[0]
        : emptySupplierForm;
    form.request_as = employee;
    setSupplierForm(form);
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle} style={style}>
        {" "}
        {suppliers ? "Zulieferer Bearbiten" : "Neue Zulieferer"}
      </CustomButton>
    );
  };

  const submit = async (supplierForm) => {
    return suppliers
      ? axios.post(API_URL_WAREHOUSESUPPLIER + supplierForm.id, supplierForm)
      : axios.post(API_URL_WAREHOUSESUPPLIER, supplierForm);
  };

  const onSubmit = async (onSuccess) => {
    const emptyFields = ["name"].filter(
      (key) => supplierForm[key] === "" || supplierForm[key] === null,
    );
    if (suppliers && !selectedSupplierId) {
      emptyFields.push("id");
    }
    if (emptyFields.length !== 0) {
      console.error("Empty fields: ", emptyFields);
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        showMissingFields: true,
      });
      return;
    }
    // Checking for empty contact person fields
    for (let i = 0; i < supplierForm.contact_persons.length; i++) {
      const person = supplierForm.contact_persons[i];
      const emptyFields = ["name"].filter(
        (key) => person[key] === "" || person[key] === null,
      );
      if (emptyFields.length !== 0) {
        console.error("Empty fields: ", emptyFields);
        setLoadingElements({
          ...loadingElements,
          submitError: "Bitte alle Informationen eintragen!",
          showMissingFields: true,
        });
        return;
      }
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    return submit({ ...supplierForm, request_as: employee })
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
        console.error(error);
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={
          loadingElements.submitError
            ? "Doppelter Gruppenname"
            : loadingElements.submitError
        }
        inProgress={loadingElements.inProgress}
        onSave={function () {
          onSubmit(toggle);
        }}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setSupplierForm({ ...emptySupplierForm, request_as: employee });
    setSelectedSupplierId(null);
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      title="Zulieferer"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      {suppliers && (
        <FormGroup>
          <DropDown
            onChange={(val) => {
              handleSelectSupplier(val);
            }}
            options={suppliers.map((type) => ({
              value: type.id,
              label: type.name,
            }))}
            value={defaultIfEmpty(selectedSupplierId)}
            required={true}
            invalid={
              !!(loadingElements.showMissingFields && !setSelectedSupplierId)
            }
            text="Zulieferer"
            search={true}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
        </FormGroup>
      )}

      <SupplierForm
        supplier={supplierForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setSupplier={setSupplierForm}
        customer={customer}
      />
    </CustomModal>
  );
}

SupplierModal.propTypes = {
  suppliers: PropTypes.array,
  customer: customerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object,
  employee: PropTypes.number,
};
