import React, { useEffect, useRef, cloneElement } from "react";
import { PropTypes } from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { isMobileOnly } from "react-device-detect";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import { germanCalendarMsgs } from "../../elements/utils";
import "../shared/calendarStyle.css";

moment.locale("de-DE");
const localizer = momentLocalizer(moment);

export default function CustomCalendar({ onSelectEvent, ...props }) {
  const calendarRef = useRef(null);
  const selectedDate = useRef(null);

  useEffect(() => {
    const ref = calendarRef.current;
    const listenSlotClick = (event) => {
      // get nearest elements from click
      const elements = document.elementsFromPoint(event.clientX, event.clientY);
      // get day wrapper
      const dayElement = elements.find((element) =>
        element.matches(".rbc-day-bg"),
      );
      if (dayElement) {
        const date = new Date(dayElement.getAttribute("data-date"));
        selectedDate.current = date;
      }
    };
    if (calendarRef && ref) {
      ref.addEventListener("click", listenSlotClick);
      return () => {
        ref.removeEventListener("click", listenSlotClick);
      };
    }
  });

  return (
    <div ref={calendarRef}>
      <Calendar
        className="secondary-textcolor"
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        components={{
          dateCellWrapper: ({ children, value }) =>
            cloneElement(children, { "data-date": value }),
        }}
        onSelectEvent={
          onSelectEvent
            ? (event, e) => onSelectEvent(event, e, selectedDate.current)
            : null
        }
        longPressThreshold={isMobileOnly ? 30 : 50}
        step={60}
        timeslots={1}
        messages={germanCalendarMsgs}
        {...props}
      />
    </div>
  );
}

CustomCalendar.propTypes = {
  onSelectEvent: PropTypes.func,
};
