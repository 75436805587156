import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
import axios from "axios";
import { Input } from "reactstrap";

import Button from "@mui/material/Button";

import { API_URL_DECLINE } from "../../settings";
import EmailModal from "../shared/modal_utils/EmailModal";
import { getCustomerName } from "../../elements/utils";
import { projectPropType } from "../../elements/PropTypes";
import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import DropDown from "../../elements/DropDown";

export default function ProjectDeclineModal({ project, resetParent, session }) {
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isReasonOpen, setIsReasonOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [textInput, setTextInput] = useState(false);

  useEffect(() => {
    if (isReasonOpen) setReason("");
  }, [isReasonOpen]);

  const submit = (subject, message) => {
    return axios.post(API_URL_DECLINE, {
      project: project.id,
      declination_reason: reason,
      mail: { subject, message },
    });
  };

  const skip = () => {
    return axios.post(API_URL_DECLINE, {
      project: project.id,
      declination_reason: reason,
    });
  };

  const getOpenButton = (toggle) => {
    return (
      <Button variant="outlined" color="error" onClick={toggle}>
        Absage
      </Button>
    );
  };

  const getFooter = (toggle) => (
    <SaveModalFooter
      onSave={() => {
        toggle();
        setIsEmailOpen(true);
      }}
      onCancel={toggle}
      saveBtnLabel={"Weiter"}
      cancelBtnLabel={"Abbrechen"}
    />
  );

  return (
    <>
      <CustomModal
        title="Projekt wurde abgesagt"
        isOpen={isReasonOpen}
        setIsOpen={setIsReasonOpen}
        getOpenButton={getOpenButton}
        getFooter={getFooter}
      >
        <DropDown
          onChange={(val) => {
            setReason(val !== "Sonstiges" ? val : "");
            setTextInput(val === "Sonstiges");
          }}
          options={[
            "zu teuer",
            "doch gegen PV entschieden",
            "für Konkurrenz entschieden",
            "nicht mehr gemeldet",
            "Sonstiges",
          ]}
          value={reason}
          text="Grund für die Absage"
          style={{ padding: "20px 0px", overflowY: "visible" }}
          listBoxStyle={{
            padding: "20px 0px",
            maxHeight: "90px",
            overflowY: "auto",
          }}
          search={true}
        />
        {textInput ? (
          <Input
            type="text"
            name="decline_reason"
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Grund für die Absage"
            autoComplete="off"
          />
        ) : null}
      </CustomModal>
      <EmailModal
        customer={project.customer_obj}
        title={"Absage " + getCustomerName(project.customer_obj)}
        subject={"Ihre Absage"}
        getEmailMessage={API_URL_DECLINE}
        submit={submit}
        skip={skip}
        resetParent={resetParent}
        getModalOpenButton={() => null}
        isOpen={isEmailOpen}
        setIsOpen={setIsEmailOpen}
        session={session}
      />
    </>
  );
}

ProjectDeclineModal.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
