import React from "react";
import { PropTypes } from "prop-types";

import "./SignatureStyle.css";
import { CustomButton } from "./StyledElements";
const SignatureCanvas = (await import("react-signature-canvas")).default;

export default function SignatureCanvasTool({
  sigCanvas,
  onEnd,
  sigCanvasLen,
  index,
  employeeId,
}) {
  if (
    sigCanvasLen >= 0 &&
    sigCanvas.current[index] !== undefined &&
    sigCanvas.current[index] !== null &&
    employeeId !== null
  ) {
    sigCanvas.current[index].employeeId = employeeId;
    const canvasElement = sigCanvas.current[index].getCanvas(); // Pastikan ada metode untuk mendapatkan elemen canvas
    const context = canvasElement.getContext("2d");
    const imageData = context.getImageData(
      0,
      0,
      canvasElement.width,
      canvasElement.height,
    );
    const isCanvasEmpty = Array.from(imageData.data).every(
      (value) => value === 0,
    );
    sigCanvas.current[index].isSignEmpty = isCanvasEmpty;
  }
  return (
    <div className="sigPadContainer">
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: "sigCanvas" }}
        ref={(element) => {
          if (sigCanvasLen >= 0) {
            sigCanvas.current[index] = element;
          } else {
            sigCanvas.current = element;
          }
        }}
        onEnd={onEnd}
      />
      <hr className="secondary-textcolor" />
      <CustomButton
        variant="outlined"
        className="secondary-textcolor"
        onClick={() => {
          if (sigCanvasLen >= 0) {
            sigCanvas.current[index].clear();
          } else {
            sigCanvas.current.clear();
          }
          if (onEnd) {
            onEnd();
          }
        }}
      >
        Löschen
      </CustomButton>
    </div>
  );
}

SignatureCanvasTool.propTypes = {
  sigCanvas: PropTypes.object,
  onEnd: PropTypes.func,
  sigCanvasLen: PropTypes.number,
  index: PropTypes.number,
  employeeId: PropTypes.number,
};
