import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import { Table } from "react-bootstrap";
import { Container, Input, Button } from "reactstrap";

import { Typography } from "@mui/material";

import LoadingPage from "../elements/LoadingPage";
import { sortById, defaultIfEmpty } from "../elements/utils";
import { API_URL_PRODUCT, API_URL_PRODUCTGROUP } from "../settings";
import ProductFormModal from "./products/ProductFormModal";
import ProductGroupModal from "./products/ProductGroupModal";
import EditProductGroupModal from "./products/EditProductGroupModal";
import ConfirmationModal from "./shared/modal_utils/ConfirmationModal";
import MultiSelect from "../elements/MultiSelect";

export default function Products({ session }) {
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [productGroupFilter, setProductGroupFilter] = useState(null);
  const [selectedProductGroup, setSelectedProductGroup] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    getProducts();
    getProductGroups();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [selectedProductGroup, searchInput, products, productGroupFilter]);

  const resetState = (resetSearch = true) => {
    getProducts();
    getProductGroups();
    if (resetSearch) setSelectedProductGroup([]);
    if (resetSearch) setSearchInput("");
  };

  const getProducts = () => {
    axios
      .get(API_URL_PRODUCT)
      .then((res) => {
        setProducts(sortById(res.data));
        const filtered = res.data.filter(
          (product) => !product.product_group.includes(3),
        );
        setFilteredProducts(sortById(filtered));
      })
      .then(() => setLoaded(true));
  };

  const getProductGroups = () => {
    axios
      .get(API_URL_PRODUCTGROUP)
      .then((res) => {
        setProductGroupFilter(sortById(res.data));
      })
      .then(() => {
        setLoaded(true);
      });
  };

  const filterProducts = () => {
    const filtered = products.filter(
      (product) =>
        ((selectedProductGroup && selectedProductGroup.length === 0) ||
          (selectedProductGroup &&
            selectedProductGroup.some((val) =>
              product.product_group.includes(val),
            ))) &&
        (searchInput
          ? product.name.toLowerCase().includes(searchInput.toLowerCase())
          : true) &&
        (selectedProductGroup.includes(keyToId("deactivated")) ||
          !product.product_group.includes(keyToId("deactivated"))),
    );
    setFilteredProducts(filtered);
  };

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedProductGroup(selectedValues || []);
  };

  const handleSearchChange = (searchValue) => {
    setSearchInput(searchValue);
  };

  const keyToId = (key) => {
    return productGroupFilter
      ? productGroupFilter.find((productGroup) => productGroup.key === key).id
      : null;
  };

  if (!loaded) return <LoadingPage />;

  return (
    <Fragment>
      <Container>
        <ProductFormModal
          session={session}
          resetParent={resetState}
          style={{ marginRight: "10px" }}
          productGroup={productGroupFilter}
        />
        <ProductGroupModal
          session={session}
          resetParent={resetState}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        />
        <EditProductGroupModal
          session={session}
          resetParent={resetState}
          style={{ marginLeft: "10px" }}
          productGroup={productGroupFilter}
        />
        <br />
        <br />
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "40%",
            backgroundColor: "#ffffff",
            color: "#424242",
            borderColor: "#424242",
            boxShadow: "none",
          }}
        >
          <Input
            style={{
              paddingRight: "30px",
              backgroundColor: "#ffffff",
              color: "#424242",
              borderColor: "#424242",
              boxShadow: "none",
            }}
            type="text"
            value={searchInput}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder="Produkt suchen"
            autoComplete="off"
          />
          {searchInput && (
            <Button
              color="danger"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                borderRadius: "50%",
                fontWeight: "bold",
                width: "25px",
                height: "25px",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                color: "red",
              }}
              onClick={() => handleSearchChange("")}
            >
              x
            </Button>
          )}
        </div>
        <br />
        <MultiSelect
          onChange={handleMultiSelectChange}
          options={
            productGroupFilter
              ? productGroupFilter
                  .filter((productGroup) => productGroup.state === true)
                  .map((productGroup) => ({
                    value: productGroup.id,
                    label: productGroup.name,
                  }))
              : null
          }
          values={defaultIfEmpty(selectedProductGroup)}
          text="Produktgruppe"
          search={true}
          name="product_group"
        />
        <br />
        <Table>
          <thead>
            <tr>
              <th className="align-middle">
                <Typography className="secondary-textcolor">#</Typography>
              </th>
              <th className="align-middle">
                <Typography className="secondary-textcolor">Name</Typography>
              </th>
              <th style={{ textAlign: "center" }} className="align-middle">
                <Typography className="secondary-textcolor">Preis</Typography>
              </th>
              <th style={{ textAlign: "center", width: "20%" }}>
                <Typography className="secondary-textcolor">Aktion</Typography>
              </th>
            </tr>
          </thead>

          <tbody>
            {!filteredProducts || filteredProducts.length <= 0 ? (
              <tr>
                <td colSpan="4" align="center">
                  <Typography className="secondary-textcolor">
                    {" "}
                    Leere Datenbank{" "}
                  </Typography>
                </td>
              </tr>
            ) : (
              filteredProducts.map((product) => (
                <tr key={product.id}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {product.id}{" "}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {product.name}{" "}
                    </Typography>
                  </td>
                  <td className="align-middle" style={{ textAlign: "right" }}>
                    <Typography className="secondary-textcolor">
                      {product.price.toFixed(2)}
                    </Typography>
                  </td>
                  <td align="center">
                    <ConfirmationModal
                      resetParent={resetState}
                      confirm={() => axios.delete(API_URL_PRODUCT + product.id)}
                      title={
                        "Soll '" +
                        product.name +
                        "' wirklich aus Sortiment entfernt werden?"
                      }
                    />
                    <ProductFormModal
                      groupIcon
                      session={session}
                      product={product}
                      resetParent={resetState}
                      productGroup={productGroupFilter}
                    />
                    <ProductFormModal
                      session={session}
                      product={product}
                      resetParent={resetState}
                      productGroup={productGroupFilter}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
}

Products.propTypes = {
  session: PropTypes.object,
};
