import React, { useState, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";

import ImageIcon from "@mui/icons-material/Image";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import { Container, Row, Col } from "reactstrap";

import CustomModal from "../shared/modal_utils/CustomModal";
import { API_URL_PRODUCTPHOTO } from "../../settings";
import { productPropType } from "../../elements/PropTypes";
import ImageStage from "../shared/images/ImageStage";

export default function ProductImageModal({
  product,
  session,
  isOpen,
  setIsOpen = null,
}) {
  const [photos, setPhotos] = useState(null);
  const loadData = useCallback(() => {
    axios
      .get(API_URL_PRODUCTPHOTO, { params: { product: product.id } })
      .then(async (res) => {
        const productPhotos = res.data;
        setPhotos(productPhotos);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const _getOpenButton = (toggle) => {
    return (
      <>
        {photos && photos.length !== 0 && (
          <Tooltip
            title="Produktfotos"
            PopperProps={{ style: { zIndex: 9999 } }}
          >
            <IconButton
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
              size="small"
              onClick={toggle}
              className=""
            >
              <ImageIcon className="secondary-textcolor" fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const handleClose = (toggle) => {
    loadData();
    toggle();
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={_getOpenButton}
      title={product.name}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={handleClose}
    >
      <Container>
        <Row xs={10} md={7}>
          {photos &&
            photos.map((photo, iIdx) => (
              <Col
                key={`product-photo-${iIdx}`}
                className="my-1"
                style={{ minWidth: 400 }}
              >
                <ImageStage image={photo.image} width={400} />
              </Col>
            ))}
        </Row>
      </Container>
    </CustomModal>
  );
}

ProductImageModal.propTypes = {
  product: productPropType,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
