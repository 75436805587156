import React, { Fragment, useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { Container, Input } from "reactstrap";

import { Grid, Stack, Typography } from "@mui/material";

import CollapsibleTable from "../../elements/CollapsibleTable";
import LoadingPage from "../../elements/LoadingPage";
import { API_URL_LEAD } from "../../settings";
import axios from "axios";
import DropDown from "../../elements/DropDown";
import { leadTypePropType, userPropType } from "../../elements/PropTypes";
import LeadFormModal from "./LeadFormModal";
import { CustomIconButton } from "../../elements/StyledElements";
import { useHistory } from "react-router-dom";
import LeadMailModal from "./LeadMailModal";
import { hasPermission } from "../../elements/utils";
import ArchiveLeadModal from "./ArchiveLeadModal";
import CustomerProjectFormModal from "./CustomerProjectFormModal";
import { useLeadsContextContext } from "../../contexts/LeadsContext";

export default function LeadList({
  salesman,
  setSalesman,
  salesmen,
  leadType,
  setLeadType,
  leadTypes,
  leadStepFilterOptions,
  leadStepFilter,
  setLeadStepFilter,
  archived = false,
  assigned = true,
  realized = false,
  session,
}) {
  const { leads, nPages, fetch, batch, setBatch, setCurIndex } =
    useLeadsContextContext();

  const [chosenLead, setChosenLead] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const batchSize = 20;

  const history = useHistory();

  useEffect(() => {
    getLeads();
  }, [batch, salesman, leadType, leadStepFilter]);

  useEffect(() => {
    if (!loaded) return; // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      getLeads();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const resetState = () => {
    getLeads();
  };

  const getLeads = async () => {
    setLoaded(false);
    const params = {
      batch_size: batchSize,
      batch,
      salesman: assigned === false ? null : salesman,
      lead_type: leadType,
      archived,
      assigned,
      realized,
      ascending_ids: false,
      search: searchInput || null,
      lead_step_filter: leadStepFilter,
      nested: true,
    };

    await fetch(params);
    setLoaded(true);
  };

  if (!loaded) return <LoadingPage />;

  const pagination =
    nPages && nPages > 1 ? (
      <Stack direction="row" spacing={2}>
        <CustomIconButton
          disabled={batch === 0}
          icon="previous"
          onClick={() => {
            setBatch(batch - 1);
          }}
        />
        <Typography className="secondary-textcolor">{batch + 1}</Typography>
        <CustomIconButton
          disabled={batch === nPages - 1}
          icon="next"
          onClick={() => {
            setBatch(batch + 1);
          }}
        />
      </Stack>
    ) : null;

  return (
    <Fragment>
      <Container>
        <Stack direction="column" spacing={2}>
          <Grid container>
            <Grid xs={10} sm={10} md={10} item justifyContent="flex-start">
              <Stack direction="row" spacing={2}>
                <DropDown
                  onChange={setLeadType}
                  options={
                    leadTypes
                      ? leadTypes.map((t) => ({ label: t.name, value: t.id }))
                      : []
                  }
                  value={leadType}
                  text="Lead-Typ"
                  sort={true}
                  search={true}
                  style={{ paddingBottom: "5px" }}
                />
                {assigned === false ? null : (
                  <DropDown
                    onChange={setSalesman}
                    options={
                      salesmen
                        ? salesmen.map((user) => ({
                            label: user.name,
                            value: user.id,
                          }))
                        : []
                    }
                    value={salesman}
                    text="Verkäufer"
                    sort={true}
                    search={true}
                    style={{ paddingBottom: "5px" }}
                  />
                )}
                <DropDown
                  onChange={setLeadStepFilter}
                  options={leadStepFilterOptions}
                  value={leadStepFilter}
                  text="Schritt"
                  sort={true}
                  search={true}
                  style={{ paddingBottom: "5px" }}
                />
                <Input
                  style={{
                    width: 250,
                    backgroundColor: "#ffffff",
                    color: "#424242",
                    borderColor: "#424242",
                    boxShadow: "none",
                  }}
                  type="text"
                  name="search-input"
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  placeholder="Suche"
                  autoComplete="off"
                />
              </Stack>
            </Grid>
            <Grid
              xs={2}
              sm={2}
              md={2}
              item
              justifyContent="flex-end"
              display="grid"
            >
              {hasPermission(session.user, "edit_leads") ? (
                <LeadFormModal
                  session={session}
                  resetParent={resetState}
                  leadTypes={leadTypes}
                  salesmen={salesmen}
                />
              ) : null}
              <LeadFormModal
                session={session}
                resetParent={resetState}
                lead={chosenLead}
                isOpen={!!chosenLead}
                setIsOpen={(isOpen) => {
                  if (!isOpen) setChosenLead(null);
                }}
                getOpenButton={() => null}
                leadTypes={leadTypes}
                salesmen={salesmen}
              />
            </Grid>
          </Grid>

          <CollapsibleTable
            columns={[
              {
                name: "Name",
                key: "name",
              },
              {
                //   name: 'Nachname',
                //   key: 'last_name'
                // }, {
                //   name: 'Vorname',
                //   key: 'first_name'
                // }, {
                name: "Typ",
                key: "type",
              },
              {
                name: "Telefon",
                key: "phone",
              },
              {
                name: "E-Mail",
                key: "email",
              },
              {
                name: "",
                key: "actions",
                clickable: false,
              },
            ]}
            rows={leads.map((lead, idx) => ({
              key: `${lead.id}`,
              // first_name: lead.first_name,
              // last_name: lead.last_name,
              style: lead.duplicate
                ? { backgroundColor: "rgba(155, 155, 155, 0.4)" }
                : null,
              lead,
              name: lead.name,
              type: lead.lead_type_name,
              phone: lead.phone1,
              email: lead.email,
              link: () => {
                setCurIndex(idx);
                history.push(`/leads/${lead.id}`);
              },
              actions: (
                <Stack direction="row" spacing={2}>
                  {lead.email_html ? <LeadMailModal lead={lead} /> : null}

                  <CustomIconButton
                    size="medium"
                    description={"Bearbeiten"}
                    icon={"info"}
                    onClick={() => setChosenLead(lead)}
                  />

                  {lead.archived ? (
                    <CustomIconButton
                      size="medium"
                      description={"Aktivieren"}
                      icon={"unarchive"}
                      onClick={() => {
                        axios
                          .put(API_URL_LEAD + lead.id, {
                            archived: !lead.archived,
                          })
                          .then(resetState);
                      }}
                    />
                  ) : (
                    <ArchiveLeadModal
                      lead={lead}
                      resetParent={resetState}
                      session={session}
                      getOpenButton={(toggle) => (
                        <CustomIconButton
                          size="medium"
                          description={"Archivieren"}
                          icon={"archive"}
                          onClick={toggle}
                        />
                      )}
                    />
                  )}
                  {lead.customer ? (
                    <CustomIconButton
                      size="medium"
                      description={"Kundenseite"}
                      icon={"person"}
                      onClick={() => {
                        history.push(`/kunde/${lead.customer}`);
                      }}
                    />
                  ) : (
                    <CustomerProjectFormModal
                      lead={lead}
                      salesmen={salesmen}
                      leadTypes={leadTypes}
                      resetParent={([c, p]) =>
                        axios
                          .put(API_URL_LEAD + lead.id, { customer: c.id })
                          .then(resetState)
                      }
                      session={session}
                    />
                  )}
                </Stack>
              ),
              // link: `/projekt/${project.id}`,
            }))}
            counter={true}
            collapsible={false}
            startCounter={1 + batchSize * batch}
          />
        </Stack>
        <div style={{ padding: "20px 0px" }}>{pagination}</div>
      </Container>
    </Fragment>
  );
}

LeadList.propTypes = {
  leadType: PropTypes.number,
  setLeadType: PropTypes.func,
  leadTypes: PropTypes.arrayOf(leadTypePropType),
  salesman: PropTypes.number,
  setSalesman: PropTypes.func,
  salesmen: PropTypes.arrayOf(userPropType),
  archived: PropTypes.bool,
  assigned: PropTypes.bool,
  realized: PropTypes.bool,
  session: PropTypes.object,
  leadStepFilterOptions: PropTypes.array,
  setLeadStepFilter: PropTypes.func,
  leadStepFilter: PropTypes.string,
};
