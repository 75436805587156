import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import axios from "axios";
import { Container } from "reactstrap";

import { CAPI_URL_PROJECT } from "../../settings";
import OfferList from "./offers/OfferList";
import LoadingPage from "../../elements/LoadingPage";
import { Typography } from "@mui/material";

export default function Offers({ session }) {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    getProjects();
  }, []);

  const resetState = () => {
    getProjects();
  };

  const getProjects = () => {
    axios.get(CAPI_URL_PROJECT, { params: { nested: true } }).then((res) => {
      setProjects(res.data);
    });
  };

  if (!projects) return <LoadingPage />;

  return (
    <Fragment>
      <Container>
        {projects.map((project) => (
          <Fragment key={`project-offer-list-${project.id}`}>
            {projects.length > 0 ? (
              <Typography className="secondary-textcolor">
                Angebote {project.name}
              </Typography>
            ) : null}
            <OfferList
              project={project}
              resetParent={resetState}
              session={session}
            />
          </Fragment>
        ))}
      </Container>
    </Fragment>
  );
}

Offers.propTypes = {
  session: PropTypes.object,
};
