import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import {
  API_URL_CUSTOMER,
  API_URL_CUSTOMERDUPLICATES,
  API_URL_NEARESTPLANVILLELOCATION,
  API_URL_PLANVILLELOCATION,
  API_URL_POSTCREATEPROJECT,
  API_URL_PREFERREDPLANVILLELOCATION,
  API_URL_PROJECT,
} from "../../settings";
import { CustomIconButton } from "../../elements/StyledElements";
import {
  isValidEmailAddress,
  string2FormattedString,
  getEmptyFieldsError,
  checkIfValueIsEmpty,
} from "../../elements/utils";
import {
  leadPropType,
  leadTypePropType,
  userPropType,
} from "../../elements/PropTypes";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { getProjectName, projectTypes } from "../project_types/projectUtils";
import { emptyCustomerForm } from "../customers/CustomerFormModal";
import { emptyProjectForm } from "../project/ProjectFormModal";
import CustomerForm from "../customers/CustomerForm";
import ProjectForm from "../project/ProjectForm";
import { Typography } from "@mui/material";

export default function CustomerProjectFormModal({
  lead,
  salesmen,
  resetParent,
  getOpenButton,
  leadTypes,
  session,
  selectedSalesman = null,
}) {
  const [customerForm, setCustomerForm] = useState({ ...emptyCustomerForm });
  const [customerFormErrors, setCustomerFormErrors] = useState({
    email: null,
    phone1: null,
    phone2: null,
    phone3: null,
  });
  const [projectForm, setProjectForm] = useState({ ...emptyProjectForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [varyingAddress, setVaryingAddress] = useState(false);
  const [duplicates, setDuplicates] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [locations, setLocations] = useState([]);
  const [nearestLocation, setNearestLocation] = useState(null);

  const loadLocations = async () => {
    const res = await axios.get(API_URL_PLANVILLELOCATION);
    setLocations(res.data);

    const res2 = await axios.get(API_URL_NEARESTPLANVILLELOCATION, {
      params: { lead: lead.id },
    });
    setNearestLocation(res2.data);
    setProjectForm((form) => ({ ...form, planville_location: res2.data }));
  };

  const deleteLocation = async (id) => {
    await axios.delete(API_URL_PLANVILLELOCATION + id);
    loadLocations();
  };

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const clearData = () => {
    setCustomerForm(emptyCustomerForm);
    setProjectForm(emptyProjectForm);
    setVaryingAddress(false);
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
  };

  const loadData = () => {
    const prefillCustomer = {
      ...lead,
      lead: lead.id,
      id: null,
      note: lead.notes,
      notes_attention: [
        "Webseite - Funnel",
        "Webseite - Funnel - Meta",
        "Webseite - Funnel - Google",
        "Webseite - Kontaktformular",
      ].includes(lead.source)
        ? "Planville.de"
        : lead.source,
    };
    setCustomerForm({
      ...emptyCustomerForm,
      ...prefillCustomer,
      prefilled: true,
    });
    const leadType = leadTypes.find((t) => t.id === lead.lead_type);
    const prefillProject = {
      salesman: selectedSalesman || lead.salesman,
      resourcetype: leadType
        ? projectTypes
            .map((t) => t.key)
            .find((k) => k.toLowerCase() === leadType.project_type_name)
        : null,
      street_and_number_project: lead.street_and_number,
      zip_and_city_project: lead.zip_and_city,
      province_project: lead.province,
    };
    const newProjectForm = { ...emptyProjectForm, ...prefillProject };
    newProjectForm.name = getProjectName(newProjectForm) || "";
    setProjectForm(newProjectForm);
    loadLocations();
  };

  useEffect(() => {
    if (!varyingAddress) {
      const newProjectForm = {
        ...projectForm,
        street_and_number_project: customerForm.street_and_number,
        zip_and_city_project: customerForm.zip_and_city,
        province_project: customerForm.province,
      };

      setProjectForm({
        ...newProjectForm,
        name: getProjectName(newProjectForm),
      });
    }
  }, [varyingAddress, customerForm]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomIconButton
        size="medium"
        description={"Neuer Kunde"}
        icon={"addperson"}
        onClick={toggle}
      />
    );
  };

  const submit = async (customerForm, projectForm) => {
    return axios.post(API_URL_CUSTOMER, customerForm).then((cRes) => {
      return axios
        .post(API_URL_PROJECT, { ...projectForm, customer: cRes.data.id })
        .then((res) => {
          axios.post(API_URL_POSTCREATEPROJECT, { project: res.data.id });
          axios.post(API_URL_PREFERREDPLANVILLELOCATION, {
            location: nearestLocation,
            zip_code: projectForm.zip_and_city_project.split(" ")[0],
          });
          return res;
        })
        .then((pRes) => [cRes, pRes]);
    });
  };

  const onSubmit = async (onSuccess, checkForDuplicates = true) => {
    const customerOptionalKeys = [
      "id",
      "phone2",
      "phone3",
      "note",
      "notes_attention",
      "iban",
      "bic",
      "bank",
      "account_owner",
      "tax_number",
      "user",
      "referral_code",
    ];
    if (customerForm.gender !== "Firma") customerOptionalKeys.push("company");
    if (customerForm.gender === "Firma")
      customerOptionalKeys.push("first_name");
    if (customerForm.gender === "Firma") customerOptionalKeys.push("last_name");
    const checkIfEmpty = (key, val) => {
      if (key === "email") return !isValidEmailAddress(customerForm.email);
      return checkIfValueIsEmpty(val);
    };
    let emptyFieldsError = getEmptyFieldsError(
      customerForm,
      emptyCustomerForm,
      customerOptionalKeys,
      checkIfEmpty,
    );
    const projectOptionalKeys = ["note", "customer", "planville_location"];
    emptyFieldsError =
      emptyFieldsError ||
      getEmptyFieldsError(projectForm, emptyProjectForm, projectOptionalKeys);
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }

    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const duplicatePromise = !checkForDuplicates
      ? Promise.resolve([])
      : axios
          .get(API_URL_CUSTOMERDUPLICATES, {
            params: {
              company:
                customerForm.gender === "Firma" ? customerForm.company : "",
              first_name: customerForm.first_name,
              last_name: customerForm.last_name,
              email: customerForm.email,
              phone1: customerForm.phone1,
              phone2: customerForm.phone2,
              phone3: customerForm.phone3,
              leads: false,
            },
          })
          .then((res) => res.data);

    duplicatePromise
      .then((duplicates) => {
        if (duplicates && duplicates.length > 0) {
          setDuplicates(duplicates);
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
          return;
        }
        return submit(customerForm, projectForm).then((res) => {
          resetParent([res[0].data, res[1].data]);
          onSuccess();
          setLoadingElements({
            ...loadingElements,
            inProgress: false,
            submitError: false,
          });
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_customer_project_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        disabled={customerForm && !customerForm.data_policy_accepted}
      />
    );
  };

  const getDuplicateFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => {
          toggle();
          onSubmit(() => setIsOpen(false), false);
        }}
        onCancel={toggle}
        saveBtnLabel="Trotzdem Speichern"
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={_getOpenButton}
      title="Kundeninformationen"
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CustomModal
        title="Kunde ist bereits vorhanden"
        size="xl"
        getFooter={getDuplicateFooter}
        isOpen={duplicates !== null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setDuplicates(null);
        }}
        getOpenButton={() => null}
      >
        {duplicates ? (
          <CollapsibleTable
            columns={[
              {
                name: "Nachname",
                key: "last_name",
              },
              {
                name: "Vorname",
                key: "first_name",
              },
              {
                name: "Datum",
                key: "registration",
              },
              {
                name: "Erstellt durch",
                key: "registered_by_name",
              },
              {
                name: "EMail",
                key: "email",
              },
              {
                name: "Telefon",
                key: "phone",
              },
            ]}
            rows={duplicates.map((customer) => ({
              key: `${customer.id}`,
              first_name: customer.first_name,
              last_name: customer.last_name,
              registration: string2FormattedString(customer.registration_date),
              registered_by_name: customer.registered_by_name,
              email: customer.email,
              phone: [customer.phone1, customer.phone2, customer.phone3]
                .filter((x) => x)
                .join("; "),
            }))}
            collapsible={false}
          />
        ) : null}
      </CustomModal>
      <Typography
        fontSize="h6.fontSize"
        fontWeight="bold"
        className="secondary-textcolor"
      >
        Kunde
      </Typography>
      <CustomerForm
        customer={customerForm}
        errors={customerFormErrors}
        setErrors={setCustomerFormErrors}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setCustomer={setCustomerForm}
      />
      <Typography
        fontSize="h6.fontSize"
        fontWeight="bold"
        className="secondary-textcolor"
      >
        Projekt
      </Typography>
      <ProjectForm
        project={projectForm}
        setProject={setProjectForm}
        locations={locations}
        loadLocations={loadLocations}
        nearestLocation={nearestLocation}
        setNearestLocation={setNearestLocation}
        deleteLocation={deleteLocation}
        salesmen={salesmen}
        showMissingFields={loadingElements.showMissingFields}
        varyingAddress={varyingAddress}
        setVaryingAddress={setVaryingAddress}
        session={session}
      />
    </CustomModal>
  );
}

CustomerProjectFormModal.propTypes = {
  lead: leadPropType,
  salesmen: PropTypes.arrayOf(userPropType),
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  leadTypes: PropTypes.arrayOf(leadTypePropType),
  session: PropTypes.object,
  selectedSalesman: PropTypes.number,
};
