import React, { Fragment, createRef, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input, FormGroup, Row } from "reactstrap";
import { HexColorPicker } from "react-colorful";

import {
  Grid,
  Slider,
  ToggleButtonGroup,
  Typography,
  Input as MuiInput,
  IconButton,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadImages from "../components/shared/images/UploadImages";
import { CustomButton, CustomToggle } from "./StyledElements";
import {
  checkIfValueIsEmpty,
  date2String,
  defaultIfEmpty,
  isString,
  string2Date,
} from "./utils";
import DropDown from "./DropDown";
import MultiSelect from "./MultiSelect";
import SignatureImageStage from "./SignatureImageStage";
import SignatureCanvasTool from "./SignatureCanvasTool";
import Collapsible from "react-collapsible";
import UploadImage from "../components/shared/images/UploadImage";
import PdfViewModal from "../components/shared/modal_utils/PdfViewModal";
import de from "date-fns/locale/de";

const fieldPropTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  defaultForm: PropTypes.object,
  template: PropTypes.arrayOf(PropTypes.object),
  showMissingFields: PropTypes.bool,
  wrap: PropTypes.bool,
};

const MIN_WIDTH = "45px";

const onChange = (value, field, setForm, template, defaultForm) => {
  if (field.onChange) return field.onChange(field.key, value);
  setForm((f) => {
    value = typeof value === "function" ? value(f[field.key], f) : value;
    let newForm = { ...f, [field.key]: value };
    const additionalUpdate = field.additionalUpdate
      ? field.additionalUpdate(field.key, value, f)
      : {};
    newForm = { ...newForm, ...additionalUpdate };
    template
      .filter((field) => field.key)
      .filter(
        (field) =>
          field.condition &&
          !field.condition(newForm) &&
          newForm[field.key] !== defaultForm[field.key],
      )
      .forEach((field) => {
        newForm[field.key] = defaultForm[field.key];
      });
    return newForm;
  });
};

const onEventChange = (e, field, setForm, template, defaultForm) => {
  onChange(e.target.value, field, setForm, template, defaultForm);
};

export const wrapField = (html, name, separator = true, br = false) => {
  return (
    <FormGroup>
      {name ? (
        <Typography className="secondary-textcolor">{name}</Typography>
      ) : null}
      {html}
      {br ? <br /> : null}
      {separator !== false ? <hr className="secondary-textcolor" /> : null}
    </FormGroup>
  );
};

wrapField.propTypes = fieldPropTypes;

export const ImagesField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const deleteImage = (field, img) => {
    onChange(
      (imgs) => imgs.filter((i) => i.id !== img.id),
      field,
      setForm,
      template,
      defaultForm,
    );
  };

  const addImage = (field, img) => {
    onChange((imgs) => [...imgs, img], field, setForm, template, defaultForm);
  };

  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;

  const html = (
    <>
      <UploadImages
        images={form[field.key]}
        addImage={(img) => addImage(field, img)}
        deleteImage={(img) => deleteImage(field, img)}
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

ImagesField.propTypes = fieldPropTypes;

export const ImageField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <UploadImage
        image={form[field.key]}
        setImage={(image) =>
          onChange(image, field, setForm, template, defaultForm)
        }
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

ImageField.propTypes = fieldPropTypes;

export const SubtitleField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const html = (
    <Typography
      fontSize={field.fontsize || "h6.fontSize"}
      className="secondary-textcolor"
    >
      {field.text}
    </Typography>
  );
  return wrap ? wrapField(html, null, field.separator) : html;
};

SubtitleField.propTypes = fieldPropTypes;

export const SliderField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const [minVal, maxVal, stepVal] = [
    field.min || 0,
    field.max || 100,
    field.step || 1,
  ];
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Slider
            aria-label={field.key}
            name={field.key}
            step={stepVal}
            min={minVal - stepVal}
            max={maxVal}
            // marks={[{ value: minVal - stepVal, label: 'Bitte wählen' }].concat(arrange(minVal, maxVal, stepVal).map(i => ({ value: i })))}
            valueLabelFormat={(value) => (
              <div>{value < minVal ? "Bitte wählen" : value}</div>
            )}
            value={
              form[field.key] !== null ? form[field.key] : minVal - stepVal
            }
            // valueLabelDisplay={(form[field.key] === null || (minVal <= form[field.key] && form[field.key] <= maxVal)) ? 'on' : 'off'}
            valueLabelDisplay={
              form[field.key] === null
                ? "on"
                : minVal <= form[field.key] && form[field.key] <= maxVal
                  ? "auto"
                  : "off"
            }
            onChange={(e) =>
              onChange(
                e.target.value === minVal - stepVal ? null : e.target.value,
                field,
                setForm,
                template,
                defaultForm,
              )
            }
            style={{ margin: "20px 0px 0px 0px", color: "#424242" }}
            error={
              !field.optional && showMissingFields && isEmpty(form[field.key])
            }
          />
          <Grid item>
            <MuiInput
              value={defaultIfEmpty(form[field.key])}
              size="small"
              name={field.key}
              onChange={(e) =>
                onChange(
                  e.target.valueAsNumber || e.target.valueAsNumber === 0
                    ? e.target.valueAsNumber
                    : null,
                  field,
                  setForm,
                  template,
                  defaultForm,
                )
              }
              // onBlur={handleBlur}
              error={
                !field.optional &&
                showMissingFields &&
                checkIfValueIsEmpty(form[field.key])
              }
              inputProps={{
                step: { stepVal },
                min: { minVal },
                max: { maxVal },
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

SliderField.propTypes = fieldPropTypes;

export const ToggleField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      {field.info && (
        <>
          <br />
          <div className="secondary-textcolor">
            <Collapsible
              trigger="[Info öffnen]"
              triggerWhenOpen="[Info schließen]"
            >
              {field.info}
            </Collapsible>
          </div>
          <br />
        </>
      )}
      <ToggleButtonGroup
        size="small"
        value={form[field.key]}
        exclusive
        onChange={(e, value) =>
          onChange(value, field, setForm, template, defaultForm)
        }
      >
        {(
          field.options || [
            { value: true, name: "Ja" },
            { value: false, name: "Nein" },
          ]
        ).map((i) => (
          <CustomToggle
            key={`${field.key}-${i.value}`}
            id={i.id || `${field.key}-${i.value}`}
            value={i.value}
            style={{ minWidth: MIN_WIDTH }}
          >
            {i.name || i.value}
          </CustomToggle>
        ))}
        {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
          <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
          </div>
        ) : null}
      </ToggleButtonGroup>
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator, true) : html;
};

ToggleField.propTypes = fieldPropTypes;

export const CustomToggleField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const customConditionMet =
    field.custom_field.condition && field.custom_field.condition(form);
  const html = (
    <>
      <Stack direction="column" spacing={1} flexWrap="wrap">
        <ToggleButtonGroup
          size="small"
          value={form[field.key]}
          exclusive
          onChange={(e, value) =>
            onChange(value, field, setForm, template, defaultForm)
          }
        >
          {(
            field.options || [
              { value: true, name: "Ja" },
              { value: false, name: "Nein" },
            ]
          ).map((i) => (
            <CustomToggle
              key={`${field.key}-${i.value}`}
              id={i.id || `${field.key}-${i.value}`}
              value={i.value}
              style={{ minWidth: MIN_WIDTH }}
            >
              {i.name || i.value}
            </CustomToggle>
          ))}
          {!field.optional &&
          showMissingFields &&
          isEmpty(form[field.key]) &&
          !customConditionMet ? (
            <div>
              &nbsp;&nbsp;
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            </div>
          ) : null}
        </ToggleButtonGroup>
        {customConditionMet && (
          <Stack direction="row" spacing={1}>
            <Input
              id="input"
              style={{ width: "30%", boxShadow: "none" }}
              type="number"
              min={0}
              name={field.custom_field.key}
              onChange={(e) =>
                onChange(
                  e.target.valueAsNumber || e.target.valueAsNumber === 0
                    ? e.target.valueAsNumber
                    : null,
                  field.custom_field,
                  setForm,
                  template,
                  defaultForm,
                )
              }
              value={defaultIfEmpty(form[field.custom_field.key])}
              invalid={
                (!field.custom_field.optional &&
                  showMissingFields &&
                  isEmpty(form[field.custom_field.key])) ||
                form[field.custom_field.key] < 0
              }
            />
            {(!field.custom_field.optional &&
              showMissingFields &&
              isEmpty(form[field.custom_field.key])) ||
            form[field.custom_field.key] < 0 ? (
              <div>
                &nbsp;&nbsp;
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              </div>
            ) : null}
          </Stack>
        )}
      </Stack>
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

CustomToggleField.propTypes = fieldPropTypes;

export const NumberInputField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <Input
      style={{ width: "30%", boxShadow: "none" }}
      id="input"
      type="number"
      min={0}
      name={field.key}
      onChange={(e) =>
        onChange(
          e.target.valueAsNumber || e.target.valueAsNumber === 0
            ? e.target.valueAsNumber
            : null,
          field,
          setForm,
          template,
          defaultForm,
        )
      }
      value={defaultIfEmpty(form[field.key])}
      autoComplete="off"
      invalid={
        (!field.optional && showMissingFields && isEmpty(form[field.key])) ||
        form[field.key] < 0
      }
    />
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

NumberInputField.propTypes = fieldPropTypes;

export const SignatureField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const sigRef = useRef();
  const value = form[field.key];
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <div
      style={
        showMissingFields && isEmpty(value) ? { border: "2px solid red" } : {}
      }
    >
      {value && isString(value) && !isEditing ? (
        <SignatureImageStage
          image={value}
          onClick={() => setIsEditing((prev) => !prev)}
        />
      ) : (
        <SignatureCanvasTool
          sigCanvas={sigRef}
          onEnd={() => {
            onChange(
              sigRef.current && !sigRef.current.isEmpty()
                ? sigRef.current
                : null,
              field,
              setForm,
              template,
              defaultForm,
            );
          }}
        />
      )}
    </div>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

SignatureField.propTypes = fieldPropTypes;

export const SignatureFields = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const sigRef = useRef({});
  const [expandedSignatures, setExpandedSignatures] = useState([]);
  const values = form[field.key];
  const [arrIsEditing, setArrIsEditing] = useState(
    Array(field.subfields.length).fill(false),
  );

  const getNewValue = (identifier, ref) => {
    const sigImg = ref.current && !ref.current.isEmpty() ? ref.current : null;
    return (sigs) =>
      sigs.map((s) => (s.id === identifier ? { ...s, image: sigImg } : s));
  };

  const toggleExpansion = (identifier) => {
    setExpandedSignatures((expanded) =>
      expanded.includes(identifier)
        ? expanded.filter((x) => x !== identifier)
        : [...expanded, identifier],
    );
  };

  // const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty # is empty is for everything
  const html = (
    <>
      {field.subfields.map((subfield, vidx) => {
        const isEditing = arrIsEditing[vidx];
        const value = values.find(
          (v) => v[field.valueIdKey || "id"] === subfield.value_id,
        );
        if (!value) return <></>;

        const sigIdentifier = value.id;
        sigRef.current[sigIdentifier] =
          sigRef.current[sigIdentifier] || createRef();
        return (
          <div key={`signature-${vidx}`}>
            <Row>
              <CustomButton
                style={{
                  width: "25%",
                  justifyContent: "space-between",
                  textTransform: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  toggleExpansion(sigIdentifier);
                }}
                variant="text"
                startIcon={false}
              >
                <Typography className="secondary-textcolor">
                  {subfield.name}
                  <br />
                </Typography>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                >
                  {expandedSignatures.includes(sigIdentifier) ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </CustomButton>
            </Row>
            <Collapsible
              open={expandedSignatures.includes(sigIdentifier)}
              transitionTime={150}
              easing="ease-in-out"
              transitionCloseTime={150}
            >
              {subfield.text ? (
                <Typography className="secondary-textcolor">
                  {subfield.text}
                </Typography>
              ) : null}
              <div
                style={
                  showMissingFields && !(value && value.image)
                    ? { border: "2px solid red" }
                    : {}
                }
              >
                {value && isString(value.image) && !isEditing ? (
                  <SignatureImageStage
                    image={value.image}
                    onClick={() => {
                      setArrIsEditing((arr) => {
                        const cp = [...arr];
                        cp[vidx] = !cp[vidx];
                        return cp;
                      });
                    }}
                  />
                ) : (
                  <SignatureCanvasTool
                    sigCanvas={sigRef.current[sigIdentifier]}
                    onEnd={() => {
                      onChange(
                        getNewValue(
                          sigIdentifier,
                          sigRef.current[sigIdentifier],
                        ),
                        field,
                        setForm,
                        template,
                        defaultForm,
                      );
                    }}
                  />
                )}
              </div>
            </Collapsible>
          </div>
        );
      })}
    </>
  );

  return wrap ? wrapField(html, field.name, field.separator) : html;
};

SignatureFields.propTypes = fieldPropTypes;

export const CheckBoxField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <FormControlLabel
        checked={form[field.key]}
        className="secondary-textcolor"
        onChange={(e) =>
          onChange(e.target.checked, field, setForm, template, defaultForm)
        }
        control={
          <Checkbox
            disableFocusRipple
            disableRipple
            style={{ color: "#424242", backgroundColor: "transparent" }}
          />
        }
        label={field.name}
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <>
          <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
        </>
      ) : null}
    </>
  );
  return wrap ? wrapField(html, null, field.separator, true) : html;
};

CheckBoxField.propTypes = fieldPropTypes;

export const DropdownField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <DropDown
        id="facade-dropdown"
        onChange={(value) =>
          onChange(value, field, setForm, template, defaultForm)
        }
        text={field.text || field.name}
        options={field.options}
        value={form[field.key]}
        search={field.search}
        noOptionsText={field.noOptionsText}
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator, true) : html;
};

DropdownField.propTypes = fieldPropTypes;

export const CustomDropdownField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const customConditionMet =
    field.custom_field.condition && field.custom_field.condition(form);
  const html = (
    <>
      <Stack direction="row" spacing={1}>
        <>
          <DropDown
            id="facade-dropdown"
            onChange={(value) =>
              onChange(value, field, setForm, template, defaultForm)
            }
            text={field.text || field.name}
            options={field.options}
            value={form[field.key]}
          />
          {!field.optional &&
          showMissingFields &&
          isEmpty(form[field.key]) &&
          !customConditionMet ? (
            <div>
              &nbsp;&nbsp;
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            </div>
          ) : null}
        </>
        {customConditionMet && (
          <Stack direction="row" spacing={1}>
            <Input
              style={{ width: "100%", boxShadow: "none" }}
              id="input"
              type="text"
              onChange={(e) =>
                onEventChange(
                  e,
                  field.custom_field,
                  setForm,
                  template,
                  defaultForm,
                )
              }
              value={defaultIfEmpty(form[field.custom_field.key])}
              autoComplete="off"
              maxLength={1500}
              invalid={
                !field.optional &&
                showMissingFields &&
                isEmpty(form[field.custom_field.key])
              }
            />
            {!field.custom_field.optional &&
            showMissingFields &&
            isEmpty(form[field.custom_field.key]) ? (
              <div>
                &nbsp;&nbsp;
                <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
              </div>
            ) : null}
          </Stack>
        )}
      </Stack>
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

CustomDropdownField.propTypes = fieldPropTypes;

export const MultiSelectField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <MultiSelect
        text={field.text || field.name}
        sort={field.sort || true}
        valueSort={field.valueSort}
        options={field.options}
        values={form[field.key]}
        disabled={!!form.disabled}
        onChange={(value) =>
          onChange(value, field, setForm, template, defaultForm)
        }
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator, true) : html;
};

MultiSelectField.propTypes = fieldPropTypes;

export const TextAreaField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <Input
        style={{ width: "30%", boxShadow: "none" }}
        id="input"
        width={"70px"}
        type="textarea"
        name="notes"
        onChange={(e) =>
          onEventChange(e, field, setForm, template, defaultForm)
        }
        value={defaultIfEmpty(form[field.key])}
        autoComplete="off"
        maxLength={1500}
        invalid={
          !field.optional && showMissingFields && isEmpty(form[field.key])
        }
      />
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

TextAreaField.propTypes = fieldPropTypes;

export const TextField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <Input
      style={{ width: field.width ? field.width : "30%", boxShadow: "none" }}
      id="input"
      width={"70px"}
      type="text"
      name="notes"
      onChange={(e) => onEventChange(e, field, setForm, template, defaultForm)}
      value={defaultIfEmpty(form[field.key])}
      autoComplete="off"
      maxLength={1500}
      disabled={!!field.disabled}
      invalid={!field.optional && showMissingFields && isEmpty(form[field.key])}
    />
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

TextField.propTypes = fieldPropTypes;

registerLocale("de", de);

export const DateField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <Stack direction="row" spacing={2}>
      <DatePicker
        // maxDate={moment().toDate()}
        locale="de"
        showIcon
        showYearDropdown
        ref={field.ref}
        dateFormat="dd.MM.yyyy"
        selected={form[field.key] ? string2Date(form[field.key]) : null}
        onChange={(date) =>
          onChange(
            date ? date2String(date) : null,
            field,
            setForm,
            template,
            defaultForm,
          )
        }
        {...(field.attributes || {})}
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="smalle" />
      ) : null}
    </Stack>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

DateField.propTypes = fieldPropTypes;

export const PDFField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const [pdf, setPdf] = useState(form[field.key]);

  useEffect(() => {
    onChange(pdf, field, setForm, template, defaultForm);
  }, [pdf]);

  const onPdfChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) {
      e.target.value = null;
      setPdf(null);
      return;
    }
    const file = e.target.files[0];
    setPdf(file);
  };

  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <Input
        style={{ width: "30%", boxShadow: "none" }}
        width={"70px"}
        id="input"
        type="file"
        accept="application/pdf"
        onChange={onPdfChange}
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
      {pdf !== null ? (
        <Fragment>
          <PdfViewModal
            title={field.name}
            filepath={isString(pdf) ? pdf : URL.createObjectURL(pdf)}
          />
        </Fragment>
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

PDFField.propTypes = fieldPropTypes;

export const ColorField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      <HexColorPicker
        color={form[field.key]}
        onChange={(value) =>
          onChange(value, field, setForm, template, defaultForm)
        }
      />
      {!field.optional && showMissingFields && isEmpty(form[field.key]) ? (
        <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      ) : null}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator, true) : html;
};

ColorField.propTypes = fieldPropTypes;

export const CustomField = ({
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
}) => {
  const isEmpty = field.isEmpty ? field.isEmpty : checkIfValueIsEmpty;
  const html = (
    <>
      {field.getFieldHtml(
        form,
        setForm,
        showMissingFields,
        field.key ? isEmpty(form[field.key], true) : false,
      )}
    </>
  );
  return wrap ? wrapField(html, field.name, field.separator) : html;
};

CustomField.propTypes = fieldPropTypes;
