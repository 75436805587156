import React, { useState, useEffect, useRef } from "react";
import {
  Stage,
  Layer,
  Image,
  Line,
  Group,
  Circle,
  Text as TextKonva,
} from "react-konva";
import { PropTypes } from "prop-types";

import { getImageWindowWithAuth } from "../../../elements/utils";
import { modulePropType, roofImagePropType } from "../../../elements/PropTypes";

const MAXSTAGEWIDTH = 600;
const MAXSTAGEHEIGHT = 450;

export default function RoofImage({
  roofImage,
  module,
  height = null,
  width = null,
  maxWidth = null,
  maxHeight = null,
}) {
  const image = roofImage.image;
  const imageWidthM = roofImage.image_width_m;
  const panels = roofImage.roofimagepanel_set;
  const angle = roofImage.angle;
  const strings = roofImage.string_set;

  const [imageState, setImageState] = useState({
    stageWidth: null,
    stageHeight: null,
    imageScale: null,
    imageWindow: null,
  });

  const [pvImageScaleW, setPvImageScaleW] = useState(null);
  const [pvImageScaleH, setPvImageScaleH] = useState(null);
  const [pvImageWindowVertical, setPvImageWindowVertical] = useState(null);
  const [pvImageWindowHorizontal, setPvImageWindowHorizontal] = useState(null);

  const origModuleWidthPx = useRef(null);
  const origModuleHeightPx = useRef(null);

  useEffect(() => {
    loadImage();
  }, [image, module]);

  const loadImage = () => {
    if (image === null) {
      setImageState({ ...imageState, imageWindow: null });
      return;
    }
    getImageWindowWithAuth(image, handleLoad);
  };

  const handleLoad = (newImageWindow) => {
    let scalerW, scalerH;
    if (height) {
      scalerH = height / newImageWindow.height;
      scalerW = maxWidth ? maxWidth / newImageWindow.width : Infinity;
    } else if (width) {
      scalerW = width / newImageWindow.width;
      scalerH = maxHeight ? maxHeight / newImageWindow.height : Infinity;
    } else {
      const maxStageWidth = maxWidth || MAXSTAGEWIDTH;
      scalerW = maxStageWidth / newImageWindow.width;
      const maxStageHeight = maxHeight || MAXSTAGEHEIGHT;
      scalerH = maxStageHeight / newImageWindow.height;
    }
    const scaler = scalerW >= scalerH ? scalerH : scalerW;
    const stageWidth = newImageWindow.width * scaler;
    const stageHeight = newImageWindow.height * scaler;
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight,
    });

    getImageWindowWithAuth(module.image_v, (newImageWindow) =>
      handlePvVerticalLoad(newImageWindow, stageWidth),
    );
    getImageWindowWithAuth(module.image_h, handlePvHorizontalLoad);
  };

  const handlePvVerticalLoad = (newImageWindowVertical, stageWidth) => {
    origModuleWidthPx.current = newImageWindowVertical.width;
    origModuleHeightPx.current = newImageWindowVertical.height;
    const moduleWidthM = module.width;
    const moduleWidthPx = (stageWidth * moduleWidthM) / imageWidthM;
    const scalerW = moduleWidthPx / origModuleWidthPx.current;
    setPvImageScaleW(scalerW);
    const moduleHeightPx = (module.height / module.width) * moduleWidthPx;
    const scalerH = moduleHeightPx / origModuleHeightPx.current;
    setPvImageScaleH(scalerH);
    setPvImageWindowVertical(newImageWindowVertical);
  };

  const handlePvHorizontalLoad = (newImageWindowHorizontal) => {
    setPvImageWindowHorizontal(newImageWindowHorizontal);
  };

  const getEdge = (p1, p2, wOffsetV, hOffsetV, wOffsetH, hOffsetH) => {
    const RADV = 0.03;
    const RADH = (RADV * imageState.stageWidth) / imageState.stageHeight;
    const x1 = p1.vertical ? p1.x - wOffsetV : p1.x - wOffsetH;
    const y1 = p1.vertical ? p1.y - hOffsetV : p1.y - hOffsetH;
    let p = null;
    let symbol = null;
    if (p1.x === p2.x) {
      if (p1.y < p2.y) {
        p = { x: p1.x, y: y1 + RADH };
        symbol = { x: p1.x, y: y1 + RADH / 2 };
      } else {
        p = {
          x: p1.x,
          y: p1.vertical ? p1.y + hOffsetV - RADH : p1.y + hOffsetH - RADH,
        };
        symbol = {
          x: p1.x,
          y: p1.vertical
            ? p1.y + hOffsetV - RADH / 2
            : p1.y + hOffsetH - RADH / 2,
        };
      }
    } else if (p1.y === p2.y) {
      if (p1.x < p2.x) {
        p = { x: x1 + RADV, y: p1.y };
        symbol = { x: x1 + RADV / 2, y: p1.y };
      } else {
        p = {
          x: p1.vertical ? p1.x + wOffsetV - RADV : p1.x + wOffsetH - RADV,
          y: p1.y,
        };
        symbol = {
          x: p1.vertical
            ? p1.x + wOffsetV - RADV / 2
            : p1.x + wOffsetH - RADV / 2,
          y: p1.y,
        };
      }
    } else {
      p = {
        x: p1.vertical
          ? p1.x - RADV * (p2.x - p1.x)
          : p1.x - RADH * (p2.x - p1.x),
        y: p1.vertical
          ? p1.y - RADV * (p2.y - p1.y)
          : p1.y - RADH * (p2.y - p1.y),
      };
      symbol = {
        x: p1.vertical
          ? p1.x - RADV * (p2.x - p1.x)
          : p1.x - RADH * (p2.x - p1.x),
        y: p1.vertical
          ? p1.y - RADV * (p2.y - p1.y)
          : p1.y - RADH * (p2.y - p1.y),
      };
    }
    return { p, symbol };
  };

  const getStringLine = (string, preview) => {
    const moduleWidthM = module.width;
    const imageHeightM =
      (imageState.stageHeight / imageState.stageWidth) * imageWidthM;
    const moduleHeightM =
      (origModuleHeightPx.current / origModuleWidthPx.current) * moduleWidthM;
    const widthOffset = moduleWidthM / imageWidthM / 2;
    const heightOffset = moduleHeightM / imageHeightM / 2;
    const widthOffsetHorizontal = moduleHeightM / imageWidthM / 2;
    const heightOffsetHorizontal = moduleWidthM / imageHeightM / 2;
    const line = panels
      .filter((p) => p.string === string.id)
      .sort((a, b) => a.idx_in_string - b.idx_in_string)
      .map((panel) => {
        const xOffset = panel.vertical ? widthOffset : widthOffsetHorizontal;
        const yOffset = panel.vertical ? heightOffset : heightOffsetHorizontal;
        return {
          x: panel.x + xOffset,
          y: panel.y + yOffset,
          vertical: panel.vertical,
        };
      });
    let minus = null;
    let plus = null;
    if (!preview && line.length > 1) {
      let p1, p2;
      p1 = line[0];
      p2 = line[1];
      const edge1 = getEdge(
        p1,
        p2,
        widthOffset,
        heightOffset,
        widthOffsetHorizontal,
        heightOffsetHorizontal,
      );
      line.unshift(edge1.p);
      plus = edge1.symbol;
      p1 = line[line.length - 1];
      p2 = line[line.length - 2];
      const edge2 = getEdge(
        p1,
        p2,
        widthOffset,
        heightOffset,
        widthOffsetHorizontal,
        heightOffsetHorizontal,
      );
      line.push(edge2.p);
      minus = edge2.symbol;
    }
    return { color: string.color, line, plus, minus };
  };

  if (imageState.imageWindow === null) return null;

  const stringLines = strings
    ? strings.map((string) => getStringLine(string))
    : null;

  return (
    <Stage
      width={imageState.stageWidth}
      height={imageState.stageHeight}
      listening={false}
    >
      <Layer>
        <Image
          rotation={angle != null ? angle : 0}
          id="roofimage"
          image={imageState.imageWindow}
          scaleX={imageState.imageScale}
          scaleY={imageState.imageScale}
        />

        {panels.map((panel, pidx) => (
          <Image
            id={"panel_" + pidx}
            key={"panel_" + pidx}
            image={
              panel.vertical ? pvImageWindowVertical : pvImageWindowHorizontal
            }
            scaleX={panel.vertical ? pvImageScaleW : pvImageScaleH}
            scaleY={panel.vertical ? pvImageScaleH : pvImageScaleW}
            x={panel.x * imageState.stageWidth}
            y={panel.y * imageState.stageHeight}
            stroke={panel.optimizer ? "rgb(255,0,0)" : null}
            strokeWidth={20}
          />
        ))}

        {strings !== undefined && strings !== null
          ? stringLines.map((stringLine, sidx) => (
              <Group key={`string-line-group-${sidx}`}>
                <Line
                  key={"string_" + sidx.toString()}
                  points={stringLine.line
                    .map((coord) => [
                      coord.x * imageState.stageWidth,
                      coord.y * imageState.stageHeight,
                    ])
                    .flat()}
                  stroke={stringLine.color}
                  strokeWidth={3}
                  listening={false}
                />
                {stringLine.plus !== null ? (
                  <Group>
                    <TextKonva
                      key={"line_number_" + sidx.toString()}
                      x={
                        stringLine.plus.x * imageState.stageWidth -
                        0.01 * imageState.stageWidth
                      }
                      y={
                        stringLine.plus.y * imageState.stageHeight -
                        0.075 * imageState.stageHeight
                      }
                      text={sidx + 1}
                      fontSize={15}
                      fill={stringLine.color}
                      listening={false}
                    />
                    <Circle
                      key={"plus_c_" + sidx.toString()}
                      x={stringLine.plus.x * imageState.stageWidth}
                      y={stringLine.plus.y * imageState.stageHeight}
                      radius={0.015 * imageState.stageWidth}
                      stroke={stringLine.color}
                      strokeWidth={3}
                      listening={false}
                    />
                    <Line
                      key={"plus_h_" + sidx.toString()}
                      points={[
                        stringLine.plus.x * imageState.stageWidth -
                          0.01 * imageState.stageWidth,
                        stringLine.plus.y * imageState.stageHeight,
                        stringLine.plus.x * imageState.stageWidth +
                          0.01 * imageState.stageWidth,
                        stringLine.plus.y * imageState.stageHeight,
                      ]}
                      stroke={stringLine.color}
                      strokeWidth={3}
                      listening={false}
                    />
                    <Line
                      key={"plus_v_" + sidx.toString()}
                      points={[
                        stringLine.plus.x * imageState.stageWidth,
                        stringLine.plus.y * imageState.stageHeight -
                          0.01 * imageState.stageWidth,
                        stringLine.plus.x * imageState.stageWidth,
                        stringLine.plus.y * imageState.stageHeight +
                          0.01 * imageState.stageWidth,
                      ]}
                      stroke={stringLine.color}
                      strokeWidth={3}
                      listening={false}
                    />
                  </Group>
                ) : null}
                {stringLine.minus !== null ? (
                  <Group>
                    <Circle
                      key={"minus_c_" + sidx.toString()}
                      x={stringLine.minus.x * imageState.stageWidth}
                      y={stringLine.minus.y * imageState.stageHeight}
                      radius={0.015 * imageState.stageWidth}
                      stroke={stringLine.color}
                      strokeWidth={3}
                      listening={false}
                    />
                    <Line
                      key={"minus_" + sidx.toString()}
                      points={[
                        stringLine.minus.x * imageState.stageWidth -
                          0.01 * imageState.stageWidth,
                        stringLine.minus.y * imageState.stageHeight,
                        stringLine.minus.x * imageState.stageWidth +
                          0.01 * imageState.stageWidth,
                        stringLine.minus.y * imageState.stageHeight,
                      ]}
                      stroke={stringLine.color}
                      strokeWidth={3}
                      listening={false}
                    />
                  </Group>
                ) : null}
              </Group>
            ))
          : null}
      </Layer>
    </Stage>
  );
}

RoofImage.propTypes = {
  roofImage: roofImagePropType,
  module: modulePropType,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
