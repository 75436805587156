import { PropTypes } from "prop-types";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import { Checkbox, IconButton, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import ImageStage from "../../../shared/images/ImageStage";
import PolylineIcon from "@mui/icons-material/Polyline";
import { Table } from "react-bootstrap";
import { Col } from "reactstrap";
import { isMobileOnly } from "react-device-detect";

export default function CustomProductLineMapping({
  project,
  customProduct,
  lineMappings,
  setLineMappings,
}) {
  const currPlanning = JSON.parse(localStorage.getItem("currPlanning"));

  const [localMappings, setLocalMappings] = useState(
    customProduct.id && lineMappings[customProduct.id]
      ? structuredClone(lineMappings[customProduct.id])
      : [],
  );

  const reset = () => {
    setLocalMappings(
      customProduct.id && lineMappings[customProduct.id]
        ? structuredClone(lineMappings[customProduct.id])
        : [],
    );
  };
  const getModalOpenButton = (toggle) => {
    return (
      <IconButton
        size={"small"}
        style={{ padding: "1px" }}
        onClick={() => {
          reset();
          toggle();
        }}
      >
        <PolylineIcon />
      </IconButton>
    );
  };

  const getNewLineTypeFooter = (toggle) => {
    return (
      <SaveModalFooter
        onSave={() => {
          setLineMappings((prev) => ({
            ...prev,
            [customProduct.id]: structuredClone(localMappings),
          }));
          toggle();
        }}
      />
    );
  };

  const allMappings = useMemo(() => {
    let lines = [];
    Object.keys(lineMappings).forEach((key) => {
      if (key.toString() === customProduct.id.toString()) {
        lines = lines.concat(localMappings);
        return;
      }
      lines = lines.concat(lineMappings[key]);
    });
    return lines;
  }, [localMappings, lineMappings, customProduct]);

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={getModalOpenButton}
      title="Linienproduktzuordnung"
      getFooter={getNewLineTypeFooter}
    >
      <Typography fontSize="h5.fontSize" className="secondary-textcolor">
        Dachfotos
      </Typography>
      <br />
      {currPlanning &&
        currPlanning.roofprojectroofimage_set.map((roofImage) => (
          <div key={`roof_image_${roofImage.id}`}>
            <Stack direction="column" spacing={2}>
              <ImageStage
                image={roofImage.blended_image}
                width={0.95 * window.innerWidth}
              />
              <div style={{ display: "flex" }}>
                <Col
                  style={
                    !isMobileOnly ? { flexGrow: 0, flexBasis: "50em" } : {}
                  }
                >
                  <Table>
                    <thead>
                      <tr>
                        <th
                          style={{ color: "white", backgroundColor: "#424242" }}
                        >
                          #
                        </th>
                        <th
                          style={{ color: "white", backgroundColor: "#424242" }}
                        >
                          Name
                        </th>
                        <th
                          style={{ color: "white", backgroundColor: "#424242" }}
                        >
                          Länge [m]
                        </th>
                        <th
                          style={{ color: "white", backgroundColor: "#424242" }}
                        >
                          Fläche [m2]
                        </th>
                        <th
                          style={{ color: "white", backgroundColor: "#424242" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {roofImage.roofprojectroofline_set.map((polygon, i) => {
                        const disabled =
                          !localMappings.includes(polygon.id) &&
                          allMappings.includes(polygon.id);
                        const checkboxProps = {};
                        checkboxProps.checked = localMappings.includes(
                          polygon.id,
                        );
                        if (disabled) {
                          checkboxProps.checked = true;
                        }
                        return (
                          <tr
                            key={polygon.id}
                            style={
                              disabled ? { backgroundColor: "lightgrey" } : {}
                            }
                          >
                            <td>{i + 1}</td>
                            <td>{polygon.name}</td>
                            <td>{polygon.length}</td>
                            <td>{polygon.area}</td>
                            <Checkbox
                              {...checkboxProps}
                              disabled={disabled}
                              disableFocusRipple
                              disableRipple
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const lineMapping = [...localMappings];
                                if (isChecked) {
                                  lineMapping.push(polygon.id);
                                } else {
                                  const idx = lineMapping.indexOf(polygon.id);
                                  if (idx > -1) {
                                    lineMapping.splice(idx, 1);
                                  }
                                }
                                setLocalMappings(lineMapping);
                              }}
                              style={{
                                color: "#424242",
                                backgroundColor: "transparent",
                              }}
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </div>
            </Stack>
            <br />
          </div>
        ))}
    </CustomModal>
  );
}

CustomProductLineMapping.propTypes = {
  project: PropTypes.object,
  customProduct: PropTypes.object,
  lineMappings: PropTypes.object,
  setLineMappings: PropTypes.func,
};
