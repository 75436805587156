import React from "react";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import { HOST_PORT, PROTOCOL } from "../../settings";
import PDFView from "../../elements/PDFViewer";
import { isString } from "../../elements/utils";
import CustomModal from "../shared/modal_utils/CustomModal";
import ImageStage from "../shared/images/ImageStage";
import { CustomButton } from "../../elements/StyledElements";
import axios from "axios";

export default function AttachmentModal({
  attachment,
  isOpen,
  setIsOpen,
  session,
}) {
  const attachmentFile = attachment?.file;
  let filename = null;
  let filepath = null;
  let isBlob = false;

  if (attachmentFile) {
    if (isString(attachmentFile)) {
      filepath = attachmentFile.replace(
        `http://${HOST_PORT}`,
        `${PROTOCOL}://${HOST_PORT}`,
      );
      filename = filepath.split("/").pop();
    } else {
      filepath = URL.createObjectURL(attachmentFile);
      filename = attachmentFile.name;
      isBlob = true;
    }
  }

  const noFile = !attachmentFile;
  const pdf = !noFile && filename.endsWith(".pdf");
  const image =
    !noFile &&
    !pdf &&
    ["jpg", "jpeg", "png"].some((e) => filename.endsWith(`.${e}`));
  const other = !noFile && !pdf && !image;

  console.log(image);

  const _download = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  };

  const download = () => {
    console.log(filepath);
    if (!isString(attachmentFile)) return _download(filepath, filename);
    axios.get(filepath, { responseType: "blob" }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      _download(url, filename);
    });
  };

  const getFooter = () => {
    // <Link to={filepath} target="_blank" download>
    //   <CustomButton
    //     color={'#ffffff'}>
    //     Download
    //   </CustomButton>
    // </Link>
    return filepath ? (
      <CustomButton onClick={download} color={"#ffffff"}>
        Download
      </CustomButton>
    ) : (
      <CustomButton color={"#ffffff"} disabled={true}>
        Download
      </CustomButton>
    );
  };

  return (
    <CustomModal
      size="xl"
      title={attachment?.name || ""}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      getFooter={getFooter}
    >
      <br />
      {noFile ? (
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Keine Datei verfügbar.
        </Typography>
      ) : null}
      {pdf ? <PDFView filepath={filepath} /> : null}
      {image ? (
        <ImageStage image={isBlob ? attachment.file : filepath} />
      ) : null}
      {other ? (
        <Typography fontSize="h5.fontSize" className="secondary-textcolor">
          Keine Vorschau verfügbar.
        </Typography>
      ) : null}
    </CustomModal>
  );
}

AttachmentModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  attachment: PropTypes.object,
  session: PropTypes.object,
};
