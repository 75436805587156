import React from "react";
import { PropTypes } from "prop-types";
import { Form } from "reactstrap";
import { Typography } from "@mui/material";
import {
  CheckBoxField,
  ColorField,
  CustomDropdownField,
  CustomField,
  CustomToggleField,
  DateField,
  DropdownField,
  ImageField,
  ImagesField,
  MultiSelectField,
  NumberInputField,
  PDFField,
  SignatureField,
  SignatureFields,
  SliderField,
  SubtitleField,
  TextField,
  ToggleField,
} from "./CustomFormFields";
import { checkIfValueIsEmpty, getEmptyFieldsError } from "./utils";
import _ from "lodash";

export const getOptionalKeysFromTemplate = (template, form) => {
  return template
    .filter((field) => field.key)
    .filter(
      (field) => field.optional || (field.condition && !field.condition(form)),
    )
    .map((f) => f.key);
};

export const getCustomKeysFromTemplate = (template, form) => {
  return template
    .filter((field) => field.custom_field)
    .map((field) => field.custom_field.key);
};

export const getCustomOptionsFromTemplate = (template) => {
  const arr = template
    .filter((field) => field.custom_field)
    .map((field) => ({
      key: field.custom_field.key,
      options: field.options.map((option) => option.value),
    }));

  return _.keyBy(arr, "key");
};

export const getCheckIfEmptyFuncFromTemplate = (template, form) => {
  const optionalFields = template
    .filter((field) => field.key)
    .filter(
      (field) => field.optional || (field.condition && !field.condition(form)),
    );
  const fields = template.filter((f) => f.key).filter((f) => f.isEmpty);
  return (key, val) => {
    if (optionalFields.find((f) => f.key === key)) return false;
    const field = fields.find((f) => f.key === key);
    if (field) return field.isEmpty(val);
    return checkIfValueIsEmpty(val);
  };
};

export const getEmptyFieldsErrorFromTemplate = (
  template,
  form,
  defaultForm,
) => {
  const checkIfEmpty = getCheckIfEmptyFuncFromTemplate(template, form);
  const emptyFieldsError = getEmptyFieldsError(
    form,
    defaultForm,
    [],
    checkIfEmpty,
  );
  return emptyFieldsError;
};

const getFieldHtml = (
  field,
  form,
  setForm,
  defaultForm,
  template,
  showMissingFields,
  wrap = true,
) => {
  switch (field.type) {
    case "slider":
      return (
        <SliderField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "image":
      return (
        <ImageField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "images":
      return (
        <ImagesField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "toggle":
      return (
        <ToggleField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "custom_toggle":
      return (
        <CustomToggleField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "input_number":
      return (
        <NumberInputField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "dropdown":
      return (
        <DropdownField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "custom_dropdown":
      return (
        <CustomDropdownField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "multiselect":
      return (
        <MultiSelectField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "text":
      return (
        <TextField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "date":
      return (
        <DateField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "signature":
      return (
        <SignatureField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "n_signatures":
      return (
        <SignatureFields
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "checkbox":
      return (
        <CheckBoxField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "custom":
      return (
        <CustomField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "subtitle":
      return (
        <SubtitleField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "pdf":
      return (
        <PDFField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    case "color":
      return (
        <ColorField
          field={field}
          form={form}
          setForm={setForm}
          defaultForm={defaultForm}
          template={template}
          showMissingFields={showMissingFields}
          wrap={wrap}
        />
      );
    default:
      return null;
  }
};

export default function CustomForm({
  title,
  template,
  form,
  setForm,
  defaultForm,
  showMissingFields,
}) {
  const elements = template
    .filter((field) => !field.condition || field.condition(form))
    .map((field) => {
      const html = getFieldHtml(
        field,
        form,
        setForm,
        defaultForm,
        template,
        showMissingFields,
        !field.htmlWrapper,
      );
      return field.htmlWrapper ? field.htmlWrapper(html, form) : html;
    })
    .filter((i) => i);
  return (
    <Form>
      {title ? (
        <>
          <Typography fontSize="h4.fontSize" className="secondary-textcolor">
            {title}
          </Typography>
          <hr className="secondary-textcolor" />
        </>
      ) : null}
      {elements}
    </Form>
  );
}

CustomForm.propTypes = {
  title: PropTypes.string,
  template: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object,
  setForm: PropTypes.func,
  defaultForm: PropTypes.object,
  showMissingFields: PropTypes.bool,
};
