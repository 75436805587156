import React, { useState } from "react";
import { PropTypes } from "prop-types";

import axios from "axios";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_GROUP, API_URL_EMPLOYEE_LABEL } from "../../settings";
import { CustomButton } from "../../elements/StyledElements";
import {
  getEmptyFieldsError,
  hasPermission,
  removeFromObj,
  EmployeeLabelEnum,
  getRandomColor,
} from "../../elements/utils";
import GroupForm from "./GroupForm";
import { groupPropType, permissionPropType } from "../../elements/PropTypes";

const emptyGroupForm = {
  id: null,
  level: 0,
  permissions: [],
  name: "",
  is_construction_site_group: false,
};

export default function GroupFormModal({
  group,
  label,
  getOpenButton,
  isOpen,
  setIsOpen = null,
  resetParent,
  permissions,
  session,
}) {
  const [groupForm, setGroupForm] = useState({ ...emptyGroupForm });
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    if (group) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent", float: "right" }}
          size="small"
          onClick={toggle}
        >
          <InfoOutlinedIcon className="secondary-textcolor" fontSize="large" />
        </IconButton>
      );
    }
    return (
      <CustomButton onClick={toggle}> Neue Gruppe erstellen </CustomButton>
    );
  };

  const submit = async (groupForm) => {
    if (group) {
      const labelForm = {
        name: groupForm.name,
        key: groupForm.key
          ? groupForm.key
          : groupForm.name.toLowerCase().replace(/ /g, "_"),
        text: label.text,
        text_color: label.text_color,
        label_color: label.label_color,
        description: label.description,
        type: label.type,
      };
      return Promise.all([
        axios.put(API_URL_GROUP + group.id, groupForm),
        axios.put(API_URL_EMPLOYEE_LABEL + label.id, labelForm),
      ]);
    } else {
      const labelForm = {
        name: groupForm.name,
        key: groupForm.key
          ? groupForm.key
          : groupForm.name.toLowerCase().replace(/ /g, "_"),
        text: groupForm.name,
        text_color: "#FFFFFF",
        label_color: getRandomColor(),
        description: null,
        type: EmployeeLabelEnum.AUTOMATIC,
      };
      return Promise.all([
        axios.post(API_URL_GROUP, groupForm),
        axios.post(API_URL_EMPLOYEE_LABEL, labelForm),
      ]);
    }
  };

  const onSubmit = async (onSuccess) => {
    const optionalKeys = ["permissions"];
    if (!group) optionalKeys.push("id");
    const emptyFieldsError = getEmptyFieldsError(
      groupForm,
      emptyGroupForm,
      optionalKeys,
    );
    if (emptyFieldsError) {
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
      });
      console.error(emptyFieldsError);
      return;
    }
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
      showMissingFields: false,
    });
    const groupFormToSubmit = hasPermission(
      session.user,
      "groups_change_permissions",
    )
      ? groupForm
      : removeFromObj(groupForm, ["permissions"]);
    return submit(groupFormToSubmit)
      .then((res) => {
        resetParent(res[0].data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    );
  };

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
    else loadData();
  };

  const clearData = () => {
    setLoadingElements({
      inProgress: false,
      submitError: false,
      showMissingFields: false,
    });
    setGroupForm({ ...emptyGroupForm });
  };

  const loadData = () => {
    setGroupForm(group || { ...emptyGroupForm });
  };

  return (
    <CustomModal
      size="lg"
      getOpenButton={_getOpenButton}
      title={group ? "Gruppe ändern" : "Gruppe erstellen"}
      getFooter={getFooter}
      onToggle={onToggle}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <GroupForm
        group={groupForm}
        session={session}
        showMissingFields={loadingElements.showMissingFields}
        setGroup={setGroupForm}
        permissions={permissions}
      />
    </CustomModal>
  );
}

GroupFormModal.propTypes = {
  group: groupPropType,
  label: PropTypes.object,
  getOpenButton: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  permissions: PropTypes.arrayOf(permissionPropType),
  session: PropTypes.object,
};
