import React from "react";
import { PropTypes } from "prop-types";

import { projectPropType } from "../../elements/PropTypes";
import PlantConstructionModal from "./plant_project/constructions/PlantConstructionModal";
import HeatpumpConstructionModal from "./heatpump_project/constructions/HeatpumpConstructionModal";
import RoofConstructionModal from "./roof_project/constructions/RoofConstructionModal";

export default function ConstructionModal({
  project,
  chosenDate,
  disable,
  getOpenButton,
  resetParent,
  isOpen,
  setIsOpen,
  session,
}) {
  if (project?.resourcetype === "PlantProject") {
    return (
      <PlantConstructionModal
        project={project}
        chosenDate={chosenDate}
        disable={disable}
        session={session}
        getOpenButton={getOpenButton}
        resetParent={resetParent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  if (project?.resourcetype === "HeatpumpProject") {
    return (
      <HeatpumpConstructionModal
        project={project}
        chosenDate={chosenDate}
        disable={disable}
        session={session}
        getOpenButton={getOpenButton}
        resetParent={resetParent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  if (project?.resourcetype === "RoofProject") {
    return (
      <RoofConstructionModal
        project={project}
        chosenDate={chosenDate}
        disable={disable}
        session={session}
        getOpenButton={getOpenButton}
        resetParent={resetParent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  }
  return null;
}

ConstructionModal.propTypes = {
  project: projectPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  session: PropTypes.object,
};
