import React, { Fragment, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import { Container } from "reactstrap";

import { Typography } from "@mui/material";

import LoadingPage from "../../../../elements/LoadingPage";
import { projectPropType } from "../../../../elements/PropTypes";
import { CustomButton } from "../../../../elements/StyledElements";
import { filterObj, hasPermission } from "../../../../elements/utils";
import {
  API_URL_CUSTOMER,
  API_URL_EMETER,
  API_URL_PROJECT,
  API_URL_PLANTPROJECTDATA,
} from "../../../../settings";
import CustomModal from "../../../shared/modal_utils/CustomModal";
import SaveModalFooter from "../../../shared/modal_utils/SaveModalFooter";
import DataInputForm from "./DataInputForm";
import DataInputRequestModal from "./DataInputRequestModal";
import ErrorMessage from "../../../../elements/ErrorMessage";

const eMeterNonBidirectionalKey = [
  "bidirectional",
  "value",
  "e_meter_id",
  "action",
  "project",
];
const eMeterBidirectionalKey = [
  "bidirectional",
  "value_180",
  "value_280",
  "e_meter_id",
  "action",
  "project",
];
const emptyEMeterForm = {
  id: null,
  bidirectional: null,
  value: null,
  value_180: null,
  value_280: null,
  e_meter_id: "",
  action: null,
  project: null,
};
const emptyCustomerForm = {
  id: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null,
};
const emptyProjectForm = {
  id: null,
  street_and_number_project: null,
  zip_and_city_project: null,
  province: null,
  emeter_set: [],
};

const emptyProjectDataForm = {
  birth_date_project: null,
  iban_project: null,
  bic_project: null,
  bank_project: null,
  account_owner_project: null,
  tax_number_project: null,
};

export default function DataInputFormModal({
  project,
  getOpenButton,
  resetParent,
  session,
}) {
  const [customerForm, setCustomerForm] = useState(null);
  const [projectForm, setProjectForm] = useState(null);
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
    showMissingFields: false,
  });
  const [sent, setSent] = useState(true);

  const onToggle = (isOpen) => {
    if (isOpen) loadData();
    else clearData();
  };

  const loadData = () => {
    setCustomerForm(project.customer_obj);

    const projectVal = Object.keys(emptyProjectDataForm).reduce((acc, key) => {
      const customerKey = key.replace("_project", "");
      acc[key] = project[key] || project.customer_obj[customerKey] || null;
      return acc;
    }, {});
    setProjectForm({ ...project, ...projectVal });
  };

  const clearData = () => {
    setCustomerForm(null);
    setProjectForm(null);
    setLoadingElements({ inProgress: false, submitError: false });
  };

  const submit = async () => {
    await axios.put(
      API_URL_PLANTPROJECTDATA + project.id,
      filterObj(projectForm, Object.keys(emptyProjectDataForm)),
    );
    await axios.put(
      API_URL_PROJECT + project.id,
      filterObj(projectForm, Object.keys(emptyProjectForm)),
    );

    const promises = [
      axios.put(
        API_URL_CUSTOMER + project.customer,
        filterObj(customerForm, Object.keys(emptyCustomerForm)),
      ),
      axios
        .delete(API_URL_EMETER, {
          data: {
            except_ids: projectForm.emeter_set
              .filter((x) => x.id > 0)
              .map((x) => x.id),
            project: project.id,
          },
        })
        .then(() =>
          Promise.all(
            projectForm.emeter_set.map((eMeterForm) => {
              const form = filterObj(eMeterForm, Object.keys(emptyEMeterForm));
              return eMeterForm.id && eMeterForm.id > 0
                ? axios.put(API_URL_EMETER + eMeterForm.id, form)
                : axios.post(API_URL_EMETER, { ...form, id: null });
            }),
          ),
        ),
    ];
    return Promise.all(promises).then((res) => {
      setSent(true);
      return res;
    });
  };

  const onSubmit = async (onSuccess) => {
    const checkIfEmpty = (key, val) => {
      return (
        val === null ||
        val === "" ||
        (Array.isArray(val) && val.length === 0) ||
        val < 0
      );
    };
    const emptyEMeterFields = () => {
      const isEmpty = false;
      if (projectForm.emeter_set.length === 0) return !isEmpty;
      for (const emeter of projectForm.emeter_set) {
        let emptyFields = [];
        if (emeter.bidirectional) {
          emptyFields = eMeterBidirectionalKey.filter((key) =>
            checkIfEmpty(key, emeter[key]),
          );
        } else {
          emptyFields = eMeterNonBidirectionalKey.filter((key) =>
            checkIfEmpty(key, emeter[key]),
          );
        }
        if (emptyFields.length !== 0) return !isEmpty;
      }
      return isEmpty;
    };

    if (emptyEMeterFields()) {
      toast.error(
        <ErrorMessage message="Bitte alle Informationen eintragen!" />,
      );
      setLoadingElements({
        ...loadingElements,
        submitError: "Bitte alle Informationen eintragen!",
        inProgress: false,
        showMissingFields: true,
      });
      return;
    }

    setSent(false);
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });

    for (const emeter of projectForm.emeter_set) {
      if (emeter.bidirectional) emeter.value = null;
      else {
        emeter.value_180 = null;
        emeter.value_280 = null;
      }
    }

    return submit()
      .then((res) => {
        resetParent(res.data);
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error('Error in "customer:onSubmit"', error, error.stack);
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        saveDisabled={!hasPermission(session.user, "customer_handling")}
      />
    );
  };

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <CustomButton onClick={toggle} icon="clipboard">
        Dateneingabe
      </CustomButton>
    );
  };

  if (!sent) return <LoadingPage />;

  return (
    <CustomModal
      getOpenButton={_getOpenButton}
      size="fullscreen"
      title="Dateneingabe"
      getFooter={getFooter}
      onToggle={onToggle}
    >
      {customerForm ? (
        <DataInputForm
          customer={customerForm}
          setCustomer={setCustomerForm}
          project={projectForm}
          setProject={setProjectForm}
          showMissingFields={loadingElements.showMissingFields}
          session={session}
        />
      ) : null}

      <Fragment>
        <Container>
          <hr className="secondary-textcolor" />
          <Typography fontSize="h6.fontSize" className="secondary-textcolor">
            Daten anfragen
          </Typography>
          <br />
          {hasPermission(session.user, "customer_handling") ? (
            <DataInputRequestModal
              project={project}
              resetParent={resetParent}
              session={session}
            />
          ) : null}
        </Container>
      </Fragment>
    </CustomModal>
  );
}

DataInputFormModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
