import React from "react";
import { Input } from "reactstrap";

import { Typography } from "@mui/material";
import { isString } from "../../elements/utils";
import CarLabelModal from "./CarLabelModal";
import CarExtraAttributesModal from "./CarExtraAttributesModal";
import ImageStage from "../shared/images/ImageStage";
import { BASE_URL } from "../../settings";
import PdfViewModal from "../shared/modal_utils/PdfViewModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const getCarFormTemplate = (
  carForm,
  vehicleTrackers,
  vehicleLabels,
  setVehicleLabels,
  resetVehicleLabels,
  employees,
  vehicleAttributes,
  resetVehicleAttributes,
  session,
) => [
  {
    type: "text",
    key: "plate",
    name: "Kennzeichen",
  },
  {
    type: "text",
    key: "brand_model",
    name: "Fahrzeug",
  },
  ...(!carForm.id
    ? [
        {
          type: "dropdown",
          key: "tracker_id",
          name: "Tracker-ID",
          options: vehicleTrackers.map((t) => ({
            label: t.tracker_id,
            value: t.tracker_id,
          })),
          search: true,
          noOptionsText: "Keine Tracker-ID",
        },
      ]
    : [
        {
          type: "text",
          key: "tracker_id",
          name: "Tracker-ID",
          disabled: true,
        },
      ]),
  {
    type: "color",
    key: "color",
    name: "Farbe",
  },
  {
    type: "multiselect",
    key: "labels",
    name: "Labels",
    options: vehicleLabels.map((label) => ({
      label: label.name,
      value: label.id,
    })),
    sort: false,
    valueSort: false,
    optional: true,
  },
  {
    type: "custom",
    getFieldHtml: () => (
      <CarLabelModal
        vehicleLabels={vehicleLabels}
        setVehicleLabels={setVehicleLabels}
        resetVehicleLabels={resetVehicleLabels}
        session={session}
      />
    ),
  },
  {
    type: "dropdown",
    key: "responsible",
    name: "Zuständiger Mitarbeiter",
    options: employees.map((e) => ({
      label: `${e.first_name} ${e.last_name}`,
      value: e.id,
      hidden: !e.is_active,
    })),
    search: true,
  },
  {
    type: "checkbox",
    key: "show_in_assignment",
    name: "In Zuordnung anzeigen",
    optional: true,
  },
  ...(vehicleAttributes.length > 0
    ? vehicleAttributes.map((attribute) => {
        if (attribute.field_type !== "file") {
          return {
            type: attribute.field_type,
            key: attribute.key,
            name: attribute.title,
            optional: !attribute.required,
            isEmpty:
              attribute.field_type === "checkbox"
                ? attribute.required
                  ? (value) => value === false
                  : null
                : null,
          };
        } else {
          return {
            type: "custom",
            key: attribute.key,
            optional: !attribute.required,
            getFieldHtml: (form, setForm, showMissingFields, fieldIsEmpty) => {
              const onFileChange = (e) => {
                if (!e.target.files || e.target.files.length < 1) return;
                const file = e.target.files[0];
                setForm((f) => ({ ...f, [attribute.key]: file }));
              };

              const attachmentFile = form[attribute.key];
              let filename = null;
              let filepath = null;
              let isBlob = false;

              if (attachmentFile) {
                if (isString(attachmentFile)) {
                  filepath = BASE_URL.slice(0, -1) + attachmentFile;
                  filename = filepath.split("/").pop();
                } else {
                  filepath = URL.createObjectURL(attachmentFile);
                  filename = attachmentFile.name;
                  isBlob = true;
                }
              }

              const noFile = !attachmentFile;
              const pdf = !noFile && filename.endsWith(".pdf");
              const image =
                !noFile &&
                !pdf &&
                ["jpg", "jpeg", "png"].some((e) => filename.endsWith(`.${e}`));

              return (
                <>
                  <Typography className="secondary-textcolor">
                    {attribute.title}
                  </Typography>
                  <Input
                    style={{ width: "30%", boxShadow: "none" }}
                    id="input"
                    type="file"
                    onChange={onFileChange}
                  />
                  {pdf ? <PdfViewModal filepath={filepath} /> : null}
                  {image ? (
                    <ImageStage
                      image={isBlob ? form[attribute.key] : filepath}
                    />
                  ) : null}
                  {showMissingFields && fieldIsEmpty && attribute.required ? (
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  ) : null}
                </>
              );
            },
          };
        }
      })
    : [{}]),
  {
    type: "custom",
    getFieldHtml: () => (
      <CarExtraAttributesModal
        resetParent={resetVehicleAttributes}
        session={session}
      />
    ),
  },
];
