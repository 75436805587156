import React, { Fragment, useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { Col, Container, Row, Input, FormGroup } from "reactstrap";
import Collapsible from "react-collapsible";
import { ibanToBic } from "iban-to-bic";

import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { CustomButton } from "../../../../elements/StyledElements";
import DropDown from "../../../../elements/DropDown";
import {
  getRandomId,
  hasPermission,
  string2Date,
} from "../../../../elements/utils";
import {
  eMeterPropType,
  projectPropType,
  customerPropType,
} from "../../../../elements/PropTypes";
import LoadingPage from "../../../../elements/LoadingPage";
import DatePicker from "react-multi-date-picker";
import EMeterFormEntry from "./EMeterFormEntry";

const emptyEMeterForm = {
  id: null,
  bidirectional: null,
  value: null,
  value_180: null,
  value_280: null,
  e_meter_id: "",
  action: null,
  project: null,
};

export default function DataInputForm({
  project,
  setProject,
  customer,
  setCustomer,
  showMissingFields,
  session,
}) {
  const [varyingAddress, setVaryingAddress] = useState(null);

  const customerModified = useRef(false);
  const pickerRef1 = useRef(null);

  useEffect(() => {
    if (customerModified.current) fillBIC(project.iban_project);
  }, [project.iban_project]);

  useEffect(() => {
    if (varyingAddress === false) {
      setProject({
        ...project,
        street_and_number_project: customer.street_and_number,
        zip_and_city_project: customer.zip_and_city,
        province: customer.province,
      });
    }
  }, [
    varyingAddress,
    customer.street_and_number,
    customer.zip_and_city,
    customer.province,
  ]);

  useEffect(() => {
    setVaryingAddress(
      project.street_and_number_project !== customer.street_and_number ||
        project.zip_and_city_project !== customer.zip_and_city ||
        project.province_project !== customer.province,
    );
  }, []);

  const addEMeter = () => {
    setProject((p) => ({
      ...p,
      emeter_set: [
        ...p.emeter_set,
        { ...emptyEMeterForm, project: project.id, id: -getRandomId() },
      ],
    }));
  };

  const changeEMeter = (eMeterId, attr, value) => {
    setProject((p) => {
      const newEMeters = [...p.emeter_set];
      const eMeter = newEMeters.find((e) => e.id === eMeterId);
      eMeter[attr] = value;
      return { ...p, emeter_set: newEMeters };
    });
  };

  const onCustomerChange = (e) => {
    customerModified.current = true;
    setCustomer((c) => ({ ...c, [e.target.name]: e.target.value }));
  };

  const onProjectChange = (e) => {
    if (e.target.name === "iban_project") customerModified.current = true;
    setProject((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const fillBIC = (iban) => {
    const bic = ibanToBic(iban);
    if (bic) setProject((p) => ({ ...p, bic_project: bic }));
    else setProject((p) => ({ ...p, bic_project: "" }));
  };

  if (!customer || !project) return <LoadingPage />;

  return (
    <Fragment>
      <>
        <Container>
          <Typography fontSize="h6.fontSize" className="secondary-textcolor">
            Persönliche Daten
          </Typography>
          <Row>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">Anrede:</Typography>
                <DropDown
                  id="gender_dropdown"
                  onChange={(value) =>
                    setCustomer((c) => ({ ...c, gender: value }))
                  }
                  text={customer.gender === "Familie" ? "Familie" : "Anrede"}
                  options={["Frau", "Herr", "Firma"]}
                  value={customer.gender}
                />
                {showMissingFields && !customer.gender ? (
                  <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">Vorname</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name="first_name"
                  value={customer.first_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={
                    !!(
                      showMissingFields &&
                      customer.gender !== "Firma" &&
                      !customer.first_name
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Nachname
                </Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name="last_name"
                  value={customer.last_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={
                    !!(
                      showMissingFields &&
                      customer.gender !== "Firma" &&
                      !customer.last_name
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          {customer.gender === "Firma" ? (
            <Row>
              <Col>
                <FormGroup>
                  <Typography className="secondary-textcolor">Firma</Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: "none" }}
                    type="text"
                    name="company"
                    value={customer.company}
                    autoComplete="off"
                    onChange={onCustomerChange}
                    invalid={
                      !!(
                        showMissingFields &&
                        customer.gender === "Firma" &&
                        !customer.company
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Straße Hausnr.
                </Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name="street_and_number"
                  value={customer.street_and_number}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !customer.street_and_number)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">PLZ Ort</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name="zip_and_city"
                  value={customer.zip_and_city}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !customer.zip_and_city)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Bundesland:
                </Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: "none" }}
                  type="text"
                  name="province"
                  value={customer.province}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !customer.province)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Geburtsdatum:
                </Typography>
                <DatePicker
                  ref={pickerRef1}
                  dateFormat="YYYY-MM-DD"
                  showYearDropdown
                  locale="de"
                  value={
                    project.birth_date_project
                      ? string2Date(project.birth_date_project)
                      : customer.birth_date
                        ? string2Date(customer.birth_date)
                        : null
                  }
                  onChange={(e) => {
                    const date = e.format("YYYY-MM-DD");
                    setProject((c) => ({
                      ...c,
                      birth_date_project: date,
                    }));
                  }}
                  maxDate={new Date()}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <FormControlLabel
              className="secondary-textcolor"
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                  checked={!varyingAddress}
                  onChange={(e) => {
                    setVaryingAddress(!e.target.checked);
                  }}
                />
              }
              label="Anlagenanschrift und Betreiberanschrift stimmen überein."
            />
          </FormGroup>
          {varyingAddress ? (
            <>
              <Typography
                fontSize="h6.fontSize"
                className="secondary-textcolor"
              >
                Abweichende Adresse der Anlage
              </Typography>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Straße Hausnr.
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="street_and_number_project"
                      value={project.street_and_number_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={
                        !!(
                          showMissingFields &&
                          !project.street_and_number_project
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      PLZ Ort
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="zip_and_city_project"
                      value={project.zip_and_city_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={
                        !!(showMissingFields && !project.zip_and_city_project)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Bundesland:
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="province_project"
                      value={project.province_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={
                        !!(showMissingFields && !project.province_project)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
          <hr className="secondary-textcolor" />
          {hasPermission(session.user, "customer_handling_prices") ? (
            <>
              <Typography
                fontSize="h6.fontSize"
                className="secondary-textcolor"
              >
                Kontodaten
              </Typography>
              <div className="secondary-textcolor">
                <Collapsible
                  trigger="[Info öffnen]"
                  triggerWhenOpen="[Info schließen]"
                >
                  <p>
                    Damit der Netzbetreiber Ihnen das Geld für den eingespeisten
                    Strom auszahlen kann, braucht dieser Ihre Kontodaten.
                  </p>
                </Collapsible>
              </div>

              <Row>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Kontoinhaber
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="account_owner_project"
                      value={project.account_owner_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={
                        !!(showMissingFields && !project.account_owner_project)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      IBAN
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="iban_project"
                      value={project.iban_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !project.iban_project)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Bank
                    </Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="bank_project"
                      value={project.bank_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !project.bank_project)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Typography className="secondary-textcolor">BIC</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      type="text"
                      name="bic_project"
                      value={project.bic_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !project.bic_project)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className="secondary-textcolor">
                      Steuernummer
                    </Typography>
                    <div className="secondary-textcolor">
                      <Collapsible
                        trigger="[Info öffnen]"
                        triggerWhenOpen="[Info schließen]"
                      >
                        <p>
                          Es ist die Vorschrift, dass Sie die Anlage beim
                          Finanzamt melden. Damit der Netzbetreiber dies
                          verknüpfen kann, braucht dieser Ihre Steuernummer.
                        </p>
                      </Collapsible>
                    </div>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: "none" }}
                      name="tax_number_project"
                      value={project.tax_number_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={
                        !!(showMissingFields && !project.tax_number_project)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col></Col>
              </Row>
              <hr className="secondary-textcolor" />
            </>
          ) : null}
          <Typography fontSize="h6.fontSize" className="secondary-textcolor">
            Zähler
          </Typography>
          {showMissingFields && project.emeter_set.length === 0 ? (
            <div>
              &nbsp;&nbsp;
              <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            </div>
          ) : null}
          {project.emeter_set.map((eMeter, eidx) => (
            <EMeterFormEntry
              key={eidx}
              eMeter={eMeter}
              idx={eidx}
              setProject={setProject}
              changeEMeter={changeEMeter}
              showMissingFields={showMissingFields}
            />
          ))}
          <div className="my-3">
            <CustomButton
              className="primary-textcolor"
              onClick={addEMeter}
              style={{
                width: "200px",
                color: "#424242",
                borderColor: "#424242",
              }}
              icon="add"
              id="add-roof-btn"
            >
              Zähler hinzufügen
            </CustomButton>
            <br />
          </div>
        </Container>
      </>
    </Fragment>
  );
}

DataInputForm.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  customer: customerPropType,
  setCustomer: PropTypes.func,
  eMeters: PropTypes.arrayOf(eMeterPropType),
  setEMeters: PropTypes.func,
  showMissingFields: PropTypes.bool,
  session: PropTypes.object,
};
