import React from "react";
import { PropTypes } from "prop-types";
import { Form, FormGroup, Input } from "reactstrap";

import { Typography } from "@mui/material";

import { defaultIfEmpty } from "../../elements/utils";
import { customerPropType, productPropType } from "../../elements/PropTypes";

export default function ProductGroupForm({
  customer,
  productGroup,
  setProductGroup,
  showMissingFields,
}) {
  const setProductState = (update) => {
    setProductGroup((prevProductGroup) => ({
      ...prevProductGroup,
      ...update,
    }));
  };

  const onChange = (e) => {
    setProductState({ [e.target.name]: e.target.value });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup>
        <Typography className="secondary-textcolor">Gruppenname</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(productGroup.name)}
          required={true}
          invalid={!!(showMissingFields && !productGroup.name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
    </Form>
  );
}

ProductGroupForm.propTypes = {
  customer: customerPropType,
  productGroup: productPropType,
  setProductGroup: PropTypes.func,
  showMissingFields: PropTypes.bool,
};
