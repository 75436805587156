import axios from "axios";
import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import LoadingPage from "../../elements/LoadingPage";
import { sortById } from "../../elements/utils";
import { API_URL_GROUP, API_URL_USER } from "../../settings";
import TabView from "../../elements/TabView";
import GroupCommissionSettings from "./GroupCommissionSettings";
import DropDown from "../../elements/DropDown";
import { projectTypes } from "../project_types/projectUtils";
import UserCommissionSettings from "./UserCommissionSettings";
import CommissionDefaultAmountSettings from "./CommissionDefaultAmountSettings";

export default function CommissionSettings({ session }) {
  const [employees, setEmployees] = useState(null);
  const [groups, setGroups] = useState(null);
  const [projectType, setProjectType] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    axios
      .get(API_URL_USER, {
        params: { is_staff: true, is_active: true, visible: true },
      })
      .then((res) => setEmployees(sortById(res.data)));
    axios.get(API_URL_GROUP).then((res) => setGroups(res.data));
  };

  if (!employees || !groups) return <LoadingPage />;

  return (
    <Fragment>
      <DropDown
        onChange={setProjectType}
        options={projectTypes.map((p) => ({ value: p.key, label: p.name }))}
        value={projectType}
        text="Projekttyp"
        disableClearable={true}
        search={true}
      />
      {projectType ? (
        <TabView
          components={[
            {
              title: "Gruppen",
              getChild: () => (
                <GroupCommissionSettings
                  groups={groups}
                  projectType={projectType}
                  session={session}
                />
              ),
            },
            {
              title: "Nutzer",
              getChild: () => (
                <UserCommissionSettings
                  employees={employees}
                  groups={groups}
                  projectType={projectType}
                  session={session}
                />
              ),
            },
            {
              title: "Provisionshöhe",
              getChild: () => (
                <CommissionDefaultAmountSettings
                  projectType={projectType}
                  session={session}
                />
              ),
            },
          ]}
        />
      ) : null}
    </Fragment>
  );
}

CommissionSettings.propTypes = {
  session: PropTypes.object,
};
