import React, { createContext, useState, useContext } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { API_URL_LEAD } from "../settings";
import { useHistory } from "react-router-dom";

const LeadsContext = createContext();

export const useLeadsContextContext = () => useContext(LeadsContext);

export const LeadsProvider = ({ children }) => {
  const [leads, setLeads] = useState([]);
  const [curIndex, setCurIndex] = useState(null);
  const [params, setParams] = useState({});
  const [batch, setBatch] = useState(0);
  const [nPages, setNPages] = useState(null);

  const batchSize = 20;
  const history = useHistory();

  const fetch = async (passedParams = {}) => {
    const sentParams = {
      ...params,
      ...passedParams,
      batch_size: batchSize,
      batch,
    };

    const res = await axios.get(API_URL_LEAD, { params: sentParams });
    setLeads(res.data);
    setParams(sentParams);
    setNPages(res.headers.length);

    return {
      leads: res.data,
      params: sentParams,
      nPages: res.headers.length,
    };
  };

  const disabled = () => {
    return leads.length === 0 || curIndex == null;
  };

  const nextLead = async () => {
    let tmpResult = {
      leads,
      nPages,
      params,
    };
    if (disabled()) {
      // initialize
      const result = await fetch();
      tmpResult = {
        ...tmpResult,
        ...result,
      };
    }

    const nextIndex = curIndex == null ? 0 : curIndex + 1;
    if (nextIndex < tmpResult.leads.length) {
      setCurIndex(nextIndex);
      const nextLead = tmpResult.leads[nextIndex];
      history.push(`/leads/${nextLead.id}`);
      return;
    }

    if (batch + 1 < tmpResult.nPages) {
      setBatch(batch + 1);
      const result = await fetch();
      tmpResult = {
        ...tmpResult,
        ...result,
      };
      setCurIndex(0);
      history.push(`/leads/${tmpResult.leads[0].id}`);
      return;
    }

    // back to first page
    setBatch(0);
    const result = await fetch();
    tmpResult = {
      ...tmpResult,
      ...result,
    };
    setCurIndex(0);
    history.push(`/leads/${tmpResult.leads[0].id}`);
  };

  return (
    <LeadsContext.Provider
      value={{
        leads,
        setLeads,
        nPages,
        setNPages,
        setCurIndex,
        batch,
        setBatch,
        fetch,
        nextLead,
        disabled,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

LeadsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
