import React, { Fragment, useEffect, useState, useRef } from "react";

import { PropTypes } from "prop-types";
import { THIS_YEAR, MONTHS, COLORS } from "../../elements/utils";
import DropDown from "../../elements/DropDown";
import CustomPlot from "../../elements/Plot";
import { isMobileOnly } from "react-device-detect";
import { Table } from "react-bootstrap";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import LoadingPage from "../../elements/LoadingPage";
import axios from "axios";
import {
  API_URL_MODULE,
  DAPI_URL_MODULESTATS,
  DAPI_URL_MODULECONSTRUCTIONS,
} from "../../settings";

const height = isMobileOnly ? 250 : 160;

export default function ComponentsStatistics({ session }) {
  const [year, setYear] = useState(THIS_YEAR);
  const [month, setMonth] = useState(null);
  const [modules, setModules] = useState(null);
  const [module, setModule] = useState(null);
  const [moduleStats, setModuleStats] = useState(null);
  const [moduleConstructions, setModuleConstructions] = useState(null);

  const allModules = useRef(null);

  useEffect(() => {
    if (module) {
      axios
        .get(DAPI_URL_MODULECONSTRUCTIONS, { params: { module, year, month } })
        .then((r) => setModuleConstructions(r.data));
    } else setModuleConstructions(null);
  }, [module]);

  useEffect(() => {
    if (allModules.current && moduleStats) {
      const moduleStatsModules = moduleStats.data.map((s) => s.module);
      setModules(
        allModules.current.filter((m) => moduleStatsModules.includes(m.id)),
      );
    }
  }, [allModules.current, moduleStats]);

  useEffect(() => {
    axios.get(API_URL_MODULE).then((res) => {
      allModules.current = res.data;
    });
  }, []);

  useEffect(() => {
    axios
      .get(DAPI_URL_MODULESTATS, { params: { year, month } })
      .then((res) => setModuleStats(res.data));
  }, [year, month]);

  if (!moduleStats || !modules) return <LoadingPage />;

  const ticks = moduleStats.times;
  const moduleDataset = moduleStats.data
    .filter((s) => module == null || s.module === module)
    .map((s, sIdx) => ({
      label: s.module_name,
      data: s.data,
      borderColor: COLORS[sIdx % COLORS.length],
      backgroundColor: COLORS[sIdx % COLORS.length],
    }));

  return (
    <Fragment>
      <Stack direction="row" spacing={2}>
        <DropDown
          onChange={setYear}
          options={[THIS_YEAR, THIS_YEAR + 1]}
          value={year}
          search={false}
          text="Jahr wählen"
          disableClearable={true}
        />
        <DropDown
          onChange={setMonth}
          options={MONTHS.map((m, midx) => ({ value: midx + 1, label: m }))}
          value={month}
          search={false}
          text="Monat wählen"
        />
        {modules ? (
          <DropDown
            onChange={setModule}
            options={modules.map((m) => ({ value: m.id, label: m.name }))}
            value={module}
            search={true}
            text="Modul wählen"
          />
        ) : null}
      </Stack>
      <br />
      {moduleDataset ? (
        <>
          <CustomPlot
            title={"Verplante Module"}
            ylabel={"Anzahl Module"}
            ticks={ticks}
            datasets={moduleDataset}
            stacked={false}
            type="line"
            zoom={false}
            height={height}
          />
          <br />
          <br />
          <hr className="secondary-textcolor" />
        </>
      ) : null}
      {moduleConstructions ? (
        <Row>
          <Typography
            className="secondary-textcolor"
            sx={{ fontWeight: "bold" }}
          >
            Übersicht Baustellen:
          </Typography>
          <Table>
            <thead>
              <tr>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">Datum</Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">Kunde</Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    # Module
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {moduleConstructions
                .filter((cs) => cs.constructions.length > 0)
                .map((constructions, cIdx) => (
                  <>
                    <tr key={`constructions_${cIdx}`}>
                      <td colSpan="3" align="align-middle">
                        <Typography
                          fontWeight="bold"
                          className="secondary-textcolor"
                        >
                          {constructions.time}
                        </Typography>
                      </td>
                    </tr>
                    {constructions.constructions.map((construction) => {
                      return (
                        <tr key={`construction_${construction.construction}`}>
                          <td className="align-middle">
                            <Link
                              to={"/projekt/" + construction.project}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography className={"secondary-textcolor"}>
                                {construction.date}
                              </Typography>
                            </Link>
                          </td>
                          <td className="align-middle">
                            <Link
                              to={"/projekt/" + construction.project}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography className={"secondary-textcolor"}>
                                {construction.customer_name}
                              </Typography>
                            </Link>
                          </td>
                          <td className="align-middle">
                            <Link
                              to={"/projekt/" + construction.project}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography className={"secondary-textcolor"}>
                                {construction.n_modules}
                              </Typography>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ))}
            </tbody>
          </Table>
          <hr className="secondary-textcolor" />
        </Row>
      ) : null}
    </Fragment>
  );
}

ComponentsStatistics.propTypes = {
  session: PropTypes.object,
};
