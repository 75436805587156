import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { Form, FormFeedback, FormGroup, Input } from "reactstrap";

import Add from "@mui/icons-material/Add";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";

import AddressInput from "../../elements/AddressInput";
import DropDown from "../../elements/DropDown";
import { customerPropType } from "../../elements/PropTypes";
import {
  defaultIfEmpty,
  isValidEmailAddress,
  isValidPhoneNumber,
  LEAD_SOURCES,
} from "../../elements/utils";
import TextField from "../../elements/TextField";

export default function CustomerForm({
  showMissingFields,
  customer,
  setCustomer,
  errors,
  setErrors,
  session,
}) {
  const [nPhones, setNPhones] = useState(1);
  const [otherLeadSource, setOtherLeadSource] = useState(false);

  useEffect(() => {
    if (customer.id || customer.prefilled) {
      setNPhones(customer.phone3 ? 3 : customer.phone2 ? 2 : 1);
      if (!LEAD_SOURCES.includes(customer.notes_attention))
        setOtherLeadSource(true);
    }
  }, [customer]);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      if (!!value && !isValidEmailAddress(value)) {
        setErrors({ ...errors, email: "Ungültige E-Mail." });
      } else {
        setErrors({ ...errors, email: null });
      }
    } else if (name === "phone1" || name === "phone2" || name === "phone3") {
      if (!!value && !isValidPhoneNumber(value)) {
        setErrors({ ...errors, [name]: "Ungültige Telefonnummer." });
      } else {
        setErrors({ ...errors, [name]: null });
      }
    }
    setCustomer((c) => ({ ...c, [name]: value }));
  };

  const setGender = (gender) => {
    setCustomer((c) => ({ ...c, gender }));
  };

  const setLeadSource = (value) => {
    if (value === "Andere") {
      setCustomer((customer) => ({ ...customer, notes_attention: null }));
      setOtherLeadSource(true);
    } else {
      setOtherLeadSource(false);
      setCustomer((customer) => ({ ...customer, notes_attention: value }));
    }
  };

  if (!customer) return null;

  return (
    <Form>
      {customer.id ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Kundennummer:</Typography>
          <div className="input-group">
            <Input
              style={{ width: "50%" }}
              type="text"
              id="input"
              name="customer_id"
              value={defaultIfEmpty(customer.id)}
              disabled={true}
            />
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Typography className="secondary-textcolor">Anrede:</Typography>
        <DropDown
          id="gender_dropdown"
          onChange={setGender}
          text={customer.gender === "Familie" ? "Familie" : "Anrede"}
          options={["Frau", "Herr", "Firma"]}
          value={customer.gender}
        />
        {showMissingFields && !customer.gender ? (
          <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
          </div>
        ) : null}
      </FormGroup>
      {customer.gender === "Firma" ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Firma:</Typography>
          <Input
            type="text"
            id="input"
            name="company"
            onChange={onChange}
            value={defaultIfEmpty(customer.company)}
            required={true}
            autoComplete="off"
            invalid={
              !!(
                showMissingFields &&
                customer.gender === "Firma" &&
                !customer.company
              )
            }
            style={{ boxShadow: "none" }}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <Typography className="secondary-textcolor">Vorname:</Typography>
        <Input
          type="text"
          id="input"
          name="first_name"
          onChange={onChange}
          value={defaultIfEmpty(customer.first_name)}
          required={true}
          autoComplete="off"
          invalid={
            !!(
              showMissingFields &&
              customer.gender !== "Firma" &&
              !customer.first_name
            )
          }
          style={{ boxShadow: "none" }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Nachname:</Typography>
        <Input
          id="input"
          type="text"
          name="last_name"
          onChange={onChange}
          value={defaultIfEmpty(customer.last_name)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields && !customer.last_name)}
          style={{ boxShadow: "none" }}
        />
      </FormGroup>

      <AddressInput
        address={{
          street_and_number: customer.street_and_number,
          zip_and_city: customer.zip_and_city,
          province: customer.province,
        }}
        setAddress={(address) =>
          setCustomer((customer) => ({
            ...customer,
            street_and_number: address.street_and_number,
            zip_and_city: address.zip_and_city,
            province: address.province,
          }))
        }
        setStreetAndNumber={(streetAndNumber) =>
          setCustomer((customer) => ({
            ...customer,
            street_and_number: streetAndNumber,
          }))
        }
        setZipAndCity={(zipAndCity) =>
          setCustomer((customer) => ({ ...customer, zip_and_city: zipAndCity }))
        }
        setProvince={(province) =>
          setCustomer((customer) => ({ ...customer, province }))
        }
        showMissingFields={showMissingFields}
        session={session}
      />
      <FormGroup>
        <Typography className="secondary-textcolor">E-Mail:</Typography>
        <Input
          id="input"
          type="email"
          name="email"
          onChange={onChange}
          value={defaultIfEmpty(customer.email)}
          required={true}
          autoComplete="off"
          invalid={
            !!(
              showMissingFields ||
              (!!customer.email && !isValidEmailAddress(customer.email))
            )
          }
          style={{ boxShadow: "none" }}
        />
        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">Telefon:</Typography>
        <Input
          id="input"
          type="text"
          name="phone1"
          onChange={onChange}
          value={defaultIfEmpty(customer.phone1)}
          required={true}
          autoComplete="off"
          invalid={
            !!(
              showMissingFields ||
              (!!customer.phone1 && !isValidPhoneNumber(customer.phone1))
            )
          }
          style={{ boxShadow: "none" }}
          onKeyDown={(e) => {
            if (e.key === "-") e.preventDefault();
          }}
        />
        {errors.phone1 && <FormFeedback>{errors.phone1}</FormFeedback>}
      </FormGroup>
      {nPhones >= 2 ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Telefon:</Typography>
          <Input
            id="input"
            type="text"
            name="phone2"
            onChange={onChange}
            value={defaultIfEmpty(customer.phone2)}
            required={false}
            autoComplete="off"
            style={{ boxShadow: "none" }}
            invalid={
              !!(
                showMissingFields ||
                (!!customer.phone2 && !isValidPhoneNumber(customer.phone2))
              )
            }
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
          {errors.phone2 && <FormFeedback>{errors.phone2}</FormFeedback>}
        </FormGroup>
      ) : null}
      {nPhones >= 3 ? (
        <FormGroup>
          <Typography className="secondary-textcolor">Telefon:</Typography>
          <Input
            id="input"
            type="text"
            name="phone3"
            onChange={onChange}
            value={defaultIfEmpty(customer.phone3)}
            required={false}
            autoComplete="off"
            style={{ boxShadow: "none" }}
            invalid={
              !!(
                showMissingFields ||
                (!!customer.phone3 && !isValidPhoneNumber(customer.phone3))
              )
            }
            onKeyDown={(e) => {
              if (e.key === "-") e.preventDefault();
            }}
          />
          {errors.phone3 && <FormFeedback>{errors.phone3}</FormFeedback>}
        </FormGroup>
      ) : null}
      {nPhones < 3 ? (
        <div>
          <IconButton
            disableFocusRipple
            disableRipple
            style={{ backgroundColor: "transparent" }}
            size="small"
            onClick={() => setNPhones(nPhones + 1)}
          >
            <Add className="secondary-textcolor" fontSize="large" />
            <Typography className="secondary-textcolor">
              Telefonnr. hinzufügen
            </Typography>
          </IconButton>{" "}
          <br />
        </div>
      ) : null}
      <FormGroup>
        <TextField
          text={customer.note}
          setText={(value) => setCustomer((c) => ({ ...c, note: value }))}
          description="Notiz"
          customRows={2}
        />
      </FormGroup>
      <FormGroup>
        <Typography className="secondary-textcolor">{`Auf ${session.companyConfig.name} aufmerksam geworden durch:`}</Typography>
        <DropDown
          id="aware_of_company_dropdown"
          search={true}
          onChange={setLeadSource}
          options={LEAD_SOURCES.concat(["Andere"])}
          value={otherLeadSource ? "Andere" : customer.notes_attention}
        />
        <br />
        {otherLeadSource ? (
          <>
            <Typography className="secondary-textcolor">Leadquelle:</Typography>
            <Input
              id="input"
              type="text"
              name="notes_attention"
              onChange={onChange}
              value={defaultIfEmpty(customer.notes_attention)}
              required={false}
              autoComplete="off"
              maxLength={100}
              style={{ boxShadow: "none" }}
            />
          </>
        ) : null}
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={customer.newsletter_subscribed}
          className="secondary-textcolor"
          onChange={(e) => {
            setCustomer((c) => ({
              ...c,
              newsletter_subscribed: e.target.checked,
            }));
          }}
          control={
            <Checkbox
              id="newsletter_checkbox"
              disableFocusRipple
              disableRipple
              style={{ color: "#424242", backgroundColor: "transparent" }}
            />
          }
          label={"Kunde für Newsletter angemeldet."}
        />
      </FormGroup>
      {!customer.id ? (
        <>
          <FormGroup>
            <FormControlLabel
              checked={customer.data_policy_accepted}
              className="secondary-textcolor"
              onChange={(e) => {
                setCustomer((c) => ({
                  ...c,
                  data_policy_accepted: e.target.checked,
                }));
              }}
              control={
                <Checkbox
                  id="data_policy_checkbox"
                  disableFocusRipple
                  disableRipple
                  style={{ color: "#424242", backgroundColor: "transparent" }}
                />
              }
              label={`Ich stimme hiermit den Datenschutzbestimmungen der ${session.companyConfig.company} zu.`}
            />
          </FormGroup>
          <hr className="secondary-textcolor" />
        </>
      ) : null}
    </Form>
  );
}

CustomerForm.propTypes = {
  showMissingFields: PropTypes.bool,
  customer: customerPropType,
  setCustomer: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  session: PropTypes.object,
};
