import React, { useState, useEffect, useCallback } from "react";
import { PropTypes } from "prop-types";

import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import axios from "axios";
import { FormGroup, Input } from "reactstrap";
import { IconButton, Typography, Tooltip } from "@mui/material";

import CustomModal from "../shared/modal_utils/CustomModal";
import SaveModalFooter from "../shared/modal_utils/SaveModalFooter";
import { API_URL_COMMENTOFFER } from "../../settings";
import { offerPropType, projectPropType } from "../../elements/PropTypes";

export default function CommentOfferModal({
  project,
  offer,
  isOpen,
  setIsOpen = null,
  getOpenButton,
  resetParent,
  session,
}) {
  const [comment, setComment] = useState("");
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const submit = async () =>
    axios.put(API_URL_COMMENTOFFER, { offer: offer.id, comment });

  const onSubmit = async (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      inProgress: true,
      submitError: false,
    });
    return submit()
      .then(() => {
        resetParent();
        onSuccess();
        setLoadingElements({
          ...loadingElements,
          inProgress: false,
          submitError: false,
        });
      })
      .catch((error) => {
        console.error(
          'Error in "CommentOfferModal:onSubmit"',
          error,
          error.stack,
        );
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const loadData = useCallback(() => {
    if (!project) return;
    const currData = project.baseoffer_set.find((t) => t.id === offer.id);
    if (currData) {
      setComment(currData.comment);
    }
  }, [offer.id, project]);

  useEffect(() => {
    loadData();
  }, [offer.id, loadData]);

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle);
    return (
      <Tooltip title="Kommentar" PopperProps={{ style: { zIndex: 9999 } }}>
        <IconButton
          disableFocusRipple
          disableRipple
          style={{ backgroundColor: "transparent" }}
          size="small"
          onClick={toggle}
        >
          <NotesOutlinedIcon
            color={comment ? "success" : ""}
            className="secondary-textcolor"
            fontSize="large"
          />
        </IconButton>
      </Tooltip>
    );
  };

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onCancel={() => handleClose(toggle)}
      />
    );
  };

  const handleClose = (toggle) => {
    loadData();
    toggle();
  };

  return (
    <CustomModal
      size="lg"
      getFooter={getFooter}
      getOpenButton={_getOpenButton}
      title={`Angebot ${offer.id}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={handleClose}
    >
      <FormGroup>
        <Typography className="secondary-textcolor">Kommentare</Typography>
        <Input
          id="input"
          required={true}
          style={{
            boxShadow: "none",
            width: "100%",
            height: "100px",
            padding: "10px",
            fontSize: "16px",
          }}
          type="textarea"
          name="offer_comment"
          value={comment}
          autoComplete="off"
          onChange={(e) => setComment(e.target.value)}
        />
      </FormGroup>
    </CustomModal>
  );
}

CommentOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
};
