import {
  checkIfValueIsEmpty,
  getEmptyFieldsError,
} from "../../../../elements/utils";
import { emptyProjectForm } from "../forms";

export const emptyEMeterForm = {
  id: null,
  bidirectional: null,
  value: null,
  value_180: null,
  value_280: null,
  e_meter_id: "",
  action: null,
  project: null,
};

export const checkIfPlantProjectFieldIsEmpty = (key, val, projectForm) => {
  if (key === "accept_mandate_signature" || key === "accept_mandate_document") {
    return (
      checkIfValueIsEmpty(projectForm.accept_mandate_signature) &&
      checkIfValueIsEmpty(projectForm.accept_mandate_document)
    );
  }
  return checkIfValueIsEmpty(val);
};

export const eMeterEmptyFieldsError = (eMeterSet) => {
  let emptyFieldsError = null;
  if (eMeterSet.length === 0) return emptyFieldsError;
  eMeterSet.forEach((emeterForm) => {
    if (emeterForm.bidirectional) {
      const bidirectionalEMeterForm = { ...emptyEMeterForm };
      delete bidirectionalEMeterForm.value;
      emptyFieldsError =
        emptyFieldsError ||
        getEmptyFieldsError(emeterForm, bidirectionalEMeterForm);
    } else {
      const nonBidirectionalEMeterForm = { ...emptyEMeterForm };
      delete nonBidirectionalEMeterForm.value_180;
      delete nonBidirectionalEMeterForm.value_280;
      emptyFieldsError =
        emptyFieldsError ||
        getEmptyFieldsError(emeterForm, nonBidirectionalEMeterForm);
    }
  });
  return emptyFieldsError;
};

export const additionalPlantProjectFieldCheck = (project) => {
  return eMeterEmptyFieldsError(project.emeter_set);
};

export const emptyPlantProjectForm = {
  ...emptyProjectForm,
  iban_project: null,
  bic_project: null,
  bank_project: null,
  account_owner_project: null,
  tax_number_project: null,
  birth_date_project: null,
  emeter_set: [],
  accept_mandate_signature: null,
  accept_mandate_document: null,
  resourcetype: "PlantProject",
};
