import React, { useState, useEffect, Fragment } from "react";
import { PropTypes } from "prop-types";
import { Container, FormGroup, Row } from "reactstrap";
import { isMobileOnly } from "react-device-detect";
import axios from "axios";

import { Stack, Typography } from "@mui/material";

import LoadingPage from "../../elements/LoadingPage";
import {
  arrSum,
  arrange,
  THIS_YEAR,
  COLORS,
  hasPermission,
} from "../../elements/utils";
import DropDown from "../../elements/DropDown";

export const getDatasets = (
  plotData,
  label,
  salesmanComparison,
  projectComparison,
) => {
  if (!(salesmanComparison || projectComparison)) {
    return [
      {
        label,
        data: plotData,
        borderColor: "#11457aff",
        backgroundColor: "#11457aff",
      },
    ];
  }
  return plotData
    .filter((x) => x.comparison)
    .map((x, xIdx) => ({
      label: x.name,
      data: x.data,
      hidden: x.hidden,
      borderColor: COLORS[xIdx % COLORS.length],
      backgroundColor: COLORS[xIdx % COLORS.length],
    }));
};

export const convert = (
  data,
  getValue,
  salesmanComparison,
  projectComparison,
  nTotal,
  projectTypesList,
) => {
  if (!data) return null;
  if (!(salesmanComparison || projectComparison))
    return arrange(0, nTotal).map((mIdx) =>
      arrSum(data.map((x) => getValue(x, mIdx))),
    );
  return data
    .filter((x) => x.active)
    .map((x) => ({
      name: projectComparison
        ? projectTypesList.find((type) => type.value === x.project_type)?.label
        : x.employee_name,
      data: arrange(0, nTotal).map((mIdx) => getValue(x, mIdx)),
      hidden: x.group !== "salesmen",
      comparison: x.group === "salesmen",
    }));
};

export default function StatsWrapper({ url, dataKeys, renderStats, session }) {
  const [projectTypesList, setProjectTypesList] = useState([]);
  const [year, setYear] = useState(THIS_YEAR);
  const [salesmen, setSalesmen] = useState(null);
  const [salesman, setSalesman] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [stats, setStats] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [resolution, setResolution] = useState("month");
  const [nTotal, setNTotal] = useState(12);

  const selectSalesman =
    hasPermission(session.user, "statistics_all") ||
    hasPermission(session.user, "statistics_sales");

  useEffect(() => {
    if (year === null) return;
    setLoaded(false);
    axios
      .get(url, { params: { year, resolution, project_type: projectType } })
      .then((res) => {
        setStats(res.data.stats);
        setProjectTypesList(res.data.project_types);
        // handle for possible duplicates
        const uniqueSalesmen = res.data.stats
          .filter((s, index, self) => {
            return index === self.findIndex((t) => t.employee === s.employee);
          })
          .filter((s) => s.active)
          .map((s) => ({ label: s.employee_name, value: s.employee }));
        setSalesmen(uniqueSalesmen);
        setNTotal(res.data.length);
        setLoaded(true);
      });
  }, [year, resolution, projectType]);

  const height = isMobileOnly ? 200 : 120;
  const salesmanComparison = salesman === 0;
  const projectComparison = projectType === "compare";
  const isComparison = salesmanComparison || projectComparison;

  const modifyData = (data) => {
    if (!data) return null;
    if (!projectTypesList) return null;
    const datasets = projectTypesList.map((type) => {
      const dataset = {
        project_type: type.value,
        group: "salesmen",
        active: true,
        employee_name: salesman,
      };
      dataKeys.forEach((key) => {
        dataset[key] = [];
      });
      const typeData = data
        .filter((x) => x.project_type === type.value)
        .filter((x) => x.active)
        .filter((x) => x.group === "salesmen");
      typeData.forEach((x, mIdx) => {
        dataKeys.forEach((key) => {
          if (mIdx === 0) dataset[key] = x[key];
          else dataset[key] = dataset[key].map((n, i) => n + x[key][i]);
        });
      });
      return dataset;
    });

    return datasets;
  };

  const data =
    stats && salesman
      ? stats.filter((x) => x.employee === salesman)
      : projectComparison
        ? modifyData(stats)
        : stats;

  if (!loaded) return <LoadingPage />;

  return (
    <Fragment>
      <Container>
        <Row>
          <Stack direction="row" spacing={2}>
            {salesmen && selectSalesman ? (
              <FormGroup>
                <Typography className="secondary-textcolor">
                  Verkäufer wählen:
                </Typography>
                <DropDown
                  onChange={setSalesman}
                  options={salesmen.concat([
                    { label: "Gesamt", value: null, order: "first" },
                    {
                      label: "Vergleich",
                      value: 0,
                      order: "first",
                      disabled: isComparison,
                    },
                  ])}
                  value={salesman}
                  text="Verkäufer wählen"
                  sort={true}
                />
              </FormGroup>
            ) : null}
            <FormGroup>
              <Typography className="secondary-textcolor">
                Jahr wählen:
              </Typography>
              <DropDown
                onChange={setYear}
                options={[THIS_YEAR - 1, THIS_YEAR]}
                value={year}
                text="Jahr wählen"
              />
            </FormGroup>
            <FormGroup>
              <Typography className="secondary-textcolor">
                Auflösung:
              </Typography>
              <DropDown
                onChange={setResolution}
                options={[
                  { value: "month", label: "Monat" },
                  { value: "week", label: "Woche" },
                ]}
                value={resolution}
                text="Auflösung wählen"
              />
            </FormGroup>
            <FormGroup>
              <Typography className="secondary-textcolor">
                Projekttyp:
              </Typography>
              <DropDown
                onChange={setProjectType}
                options={[
                  { value: null, label: "Gesamt", order: "first" },
                  {
                    value: "compare",
                    label: "Vergleich",
                    order: "first",
                    disabled: isComparison,
                  },
                ].concat(projectTypesList)}
                value={projectType}
                text="Projekttyp wählen"
              />
            </FormGroup>
          </Stack>
          <br />
          <hr className="secondary-textcolor" />
          {year &&
            stats &&
            renderStats(
              data,
              resolution,
              year,
              projectType,
              salesmanComparison,
              projectComparison,
              nTotal,
              height,
              projectTypesList,
            )}
        </Row>
      </Container>
    </Fragment>
  );
}

StatsWrapper.propTypes = {
  url: PropTypes.string,
  dataKeys: PropTypes.arrayOf(PropTypes.string),
  renderStats: PropTypes.func,
  session: PropTypes.object,
};
