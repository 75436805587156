import React, { useState } from "react";
import { PropTypes } from "prop-types";

import CustomModal from "./CustomModal";
import QuestionModalFooter from "./QuestionModalFooter";

export default function QuestionModal({
  title,
  clickYes,
  clickNo,
  resetParent,
  getOpenButton,
  yesBtnLabel = "Ja",
  noBtnLabel = "Nein",
  yesColor,
  noColor,
  ...props
}) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false,
    submitError: false,
  });

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setLoadingElements({ submitError: false, inProgress: false });
  };

  const onYes = (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    ((clickYes && clickYes()) || Promise.resolve())
      .then(() => {
        resetParent();
        onSuccess();
        setLoadingElements({ ...loadingElements, inProgress: false });
      })
      .catch(() => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const onNo = (onSuccess) => {
    setLoadingElements({
      ...loadingElements,
      submitError: false,
      inProgress: true,
    });
    ((clickNo && clickNo()) || Promise.resolve())
      .then(() => {
        resetParent();
        onSuccess();
        setLoadingElements({ ...loadingElements, inProgress: false });
      })
      .catch(() => {
        setLoadingElements({
          ...loadingElements,
          submitError: true,
          inProgress: false,
        });
      });
  };

  const getFooter = (toggle) => {
    return (
      <QuestionModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onYes={() => onYes(toggle)}
        onNo={() => onNo(toggle)}
        yesBtnLabel={yesBtnLabel}
        noBtnLabel={noBtnLabel}
        yesColor={yesColor}
        noColor={noColor}
      />
    );
  };

  return (
    <CustomModal
      getOpenButton={getOpenButton}
      getFooter={getFooter}
      onToggle={onToggle}
      size="lg"
      title={title}
      {...props}
    />
  );
}

QuestionModal.propTypes = {
  title: PropTypes.string,
  clickYes: PropTypes.func,
  clickNo: PropTypes.func,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  yesBtnLabel: PropTypes.string,
  noBtnLabel: PropTypes.string,
  yesColor: PropTypes.string,
  noColor: PropTypes.string,
};
