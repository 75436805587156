import React, { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { Col, Row } from "reactstrap";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Icon from "react-multi-date-picker/components/icon";
import { CustomButton } from "../../../../elements/StyledElements";
import {
  getCustomerName,
  string2Date,
  hasPermission,
  getProjectOverviewTable,
  checkAccessRights,
  sortByOrder,
  gregorianDe,
  date2String,
  round,
  getFirstDate,
} from "../../../../elements/utils";
import {
  API_URL_SENDCONSTRUCTIONDATE,
  PROTOCOL,
  API_URL_WAREHOUSEDELIVERYSUPPLIER,
  API_URL_WAREHOUSESUPPLIER,
  API_URL_WAREHOUSEPRODUCT,
} from "../../../../settings";
import EmailModal from "../../../shared/modal_utils/EmailModal";
// import { wrapField } from '../../../../elements/CustomFormFields'
import CustomProductItems from "../../../products/CustomProductItems";
import { Typography } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import PdfViewModal from "../../../shared/modal_utils/PdfViewModal";
import { Link } from "react-router-dom";
import ImageStage from "../../../shared/images/ImageStage";
import HeatpumpImage from "../../../shared/images/HeatpumpImage";
import { Table } from "react-bootstrap";
import WarehouseDeliveryAmountModal from "../../../warehouse/WarehouseDeliveryAmountModal";

export const getHeatpumpConstructionFormTemplate = (
  project,
  heatingInstallers,
  heatpumps,
  products,
  productGroups,
  resetProducts,
  amountCols,
  session,
) => {
  const acceptedPlanning = project
    ? project.planning_set.find(
        (p) => p.id === project.accepted_offer_obj.planning,
      )
    : null;
  const thermoStatList = ["Digital", "Analog", "Nicht Vorhanden"];
  const [suppliers, setSuppliers] = useState([]);
  const [warehouseProducts, setWarehouseProducts] = useState([]);
  const [warehouseDeliverySuppliers, setWarehouseDeliverySuppliers] = useState(
    [],
  );

  useEffect(() => {
    axios.get(API_URL_WAREHOUSESUPPLIER).then((res) => {
      setSuppliers(res.data);
    });
    getWarehouseProducts();
    getWarehouseDeliverySuppliers();
  }, []);

  const getWarehouseProducts = () => {
    axios.get(API_URL_WAREHOUSEPRODUCT).then((res) => {
      setWarehouseProducts(res.data);
    });
  };

  const getDefaultSupplierInfo = (warehouseProduct) => {
    const defaultSupplierId = warehouseProduct.amounts.find(
      (amount) => amount.location_id === 1,
    );
    const defaultSupplier = warehouseProduct.suppliers.find(
      (supplier) => supplier.id === defaultSupplierId?.active_supplier_id,
    );
    warehouseProduct.supplier_id = defaultSupplier?.supplier_id;
    warehouseProduct.supplier_name = suppliers.find(
      (supplier) => supplier.id === defaultSupplier?.supplier_id,
    )?.name;
    warehouseProduct.supplier_product_name = defaultSupplier?.product_name;
    warehouseProduct.supplier_product_id = defaultSupplier?.product_id;
    warehouseProduct.supplier_price = defaultSupplier?.price;
    return warehouseProduct;
  };

  const getWarehouseDeliverySuppliers = () => {
    if (!project.construction) return;
    axios
      .get(API_URL_WAREHOUSEDELIVERYSUPPLIER, {
        params: { project: project.id },
      })
      .then((res) => {
        setWarehouseDeliverySuppliers(res.data);
      });
  };

  const pickerRef1 = useRef(null);
  const handleOpenDatePicker = () => {
    if (pickerRef1.current) {
      pickerRef1.current.openCalendar();
    }
  };

  return [
    {
      type: "custom",
      getFieldHtml: () => (
        <Fragment>
          <Typography fontSize="h5.fontSize" className="secondary-textcolor">
            Kundeninformationen
          </Typography>
          {getProjectOverviewTable(project)}
          {checkAccessRights(session.user, "projekt/:id") ? (
            <Row>
              <Link
                to={"/projekt/" + project.id}
                style={{ textDecoration: "none" }}
              >
                <CustomButton icon="clipboard" style={{ width: "200px" }}>
                  zur Projektseite
                </CustomButton>
              </Link>
            </Row>
          ) : null}
        </Fragment>
      ),
    },
    {
      type: "dropdown",
      name: "Wärmepumpe:",
      key: "heatpump",
      condition: () => heatpumps,
      options: (heatpumps || []).map((h) => ({ label: h.name, value: h.id })),
    },
    {
      type: "dropdown",
      name: "Heizungsinstallateur:",
      key: "heating_installer",
      condition: () => heatingInstallers,
      optional: true,
      options: (heatingInstallers || []).map((h) => ({
        label: h.name,
        value: h.id,
      })),
    },
    {
      type: "custom",
      name: "Baudatum:",
      key: "constructiondates_set",
      optional: true,
      additionalUpdate: (key, value) => ({
        completion_date: value.length ? value[0] : null,
      }),
      getFieldHtml: (form, setForm) => (
        <Row>
          <Col md="auto">
            <div
              className="custom-date-picker"
              style={{ maxWidth: 300, width: "auto", minWidth: 150 }}
              onClick={handleOpenDatePicker}
            >
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "5px",
                  minHeight: "40px",
                  overflowY: "auto",
                }}
              >
                {form.constructiondates_set &&
                  form.constructiondates_set.map((dateString, index) => {
                    const date = dateString
                      ? string2Date(dateString.date)
                      : null;
                    const formattedDate = date
                      ? moment(date).format("DD/MM/YYYY")
                      : "";
                    return (
                      <span key={index}>
                        {formattedDate}{" "}
                        {index !== form.constructiondates_set.length - 1 && ","}{" "}
                        &nbsp;
                      </span>
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col md="auto">
            <DatePicker
              ref={pickerRef1}
              locale={gregorianDe}
              weekStartDayIndex={1}
              render={<Icon />}
              format="DD/MM/YYYY"
              multiple
              showOtherDays
              value={
                form.constructiondates_set !== null
                  ? form.constructiondates_set.map((dateObj) =>
                      dateObj ? string2Date(dateObj.date) : null,
                    )
                  : null
              }
              onChange={(dates) => {
                const dateObjects = dates.map((date) => ({
                  date: date2String(date.toDate()),
                  construction: project.construction,
                }));
                const maxDate = dateObjects.length
                  ? new Date(
                      Math.max(
                        ...dateObjects.map((dateObj) => new Date(dateObj.date)),
                      ),
                    )
                  : null;
                setForm((f) => ({
                  ...f,
                  constructiondates_set: dateObjects,
                  completion_date: maxDate ? date2String(maxDate) : null,
                }));
              }}
              plugins={[<DatePanel key="date-panel" header="Daten" />]}
              style={{ boxShadow: "none" }}
              sort={true}
            />
          </Col>
          <Col>
            {hasPermission(session.user, "customer_handling") &&
            project.construction !== null ? (
              <EmailModal
                customer={project.customer_obj}
                title={
                  project
                    ? "Email zum Bautermin von " +
                      getCustomerName(project.customer_obj)
                    : ""
                }
                subject={
                  project
                    ? "Bautermin für Ihre Wärmepumpe " +
                      project.street_and_number_project
                    : ""
                }
                getEmailMessage={() =>
                  axios
                    .get(API_URL_SENDCONSTRUCTIONDATE, {
                      params: { project: project.id },
                    })
                    .then((res) => {
                      let msg = res.data;
                      const formattedDates = getFirstDate(
                        form.constructiondates_set,
                        "DD.MM.YYYY",
                      );
                      msg = msg.replace("__DATE__", formattedDates);
                      return { data: msg };
                    })
                }
                submit={(subject, message) =>
                  axios.post(API_URL_SENDCONSTRUCTIONDATE, {
                    project: project.id,
                    mail: { subject, message },
                    date: getFirstDate(
                      form.constructiondates_set,
                      "YYYY-MM-DD",
                    ),
                  })
                }
                getModalOpenButton={(toggle) => (
                  <CustomButton onClick={toggle} icon="mail">
                    Setztermin erneut senden
                  </CustomButton>
                )}
                session={session}
              />
            ) : null}
          </Col>
        </Row>
      ),
    },
    {
      type: "date",
      name: "Fertigstellung:",
      key: "completion_date",
      optional: true,
    },
    {
      type: "custom",
      name: "Standort Außengerät",
      condition: () => acceptedPlanning.heatpumpplanningimage_set.length,
      getFieldHtml: (form) => {
        const heatpump = heatpumps.find((h) => h.id === form.heatpump) || null;
        return (
          <>
            {acceptedPlanning.heatpumpplanningimage_set.map(
              (heatpumpPlanningImage, index) => (
                <HeatpumpImage
                  key={index}
                  heatpumpImage={heatpumpPlanningImage}
                  heatpump={heatpump}
                  width={round((isMobileOnly ? 0.9 : 0.43) * window.innerWidth)}
                />
              ),
            )}
          </>
        );
      },
    },
    {
      type: "custom",
      name: "Heizsystem",
      condition: () => acceptedPlanning.heaterimage_set.length,
      getFieldHtml: () => (
        <>
          {acceptedPlanning.heaterimage_set.map((image, index) => (
            <ImageStage key={index} image={image.image} />
          ))}
        </>
      ),
    },
    {
      type: "custom",
      name: "Öltanks",
      condition: () => acceptedPlanning.heatertankimage_set.length,
      getFieldHtml: () => (
        <>
          {acceptedPlanning.heatertankimage_set.map((image, index) => (
            <ImageStage key={index} image={image.image} />
          ))}
        </>
      ),
    },
    {
      type: "custom",
      name: "Heizkreisverteiler",
      condition: () =>
        acceptedPlanning.heaterdistributorimage_set.length &&
        (acceptedPlanning.heating_system === "Fußbodenheizung" ||
          acceptedPlanning.heating_system === "Beide"),
      getFieldHtml: () => (
        <>
          {acceptedPlanning.heaterdistributorimage_set.map((image, index) => (
            <ImageStage key={index} image={image.image} />
          ))}
        </>
      ),
    },
    {
      type: "custom",
      name: "Zählerschrank und Umgebung",
      condition: () => acceptedPlanning.heatpumpemetercabinetimage_set.length,
      getFieldHtml: () => (
        <>
          {acceptedPlanning.heatpumpemetercabinetimage_set.map(
            (image, index) => (
              <ImageStage key={index} image={image.image} />
            ),
          )}
        </>
      ),
    },
    {
      type: "custom",
      name: "Standort Innengerät:",
      condition: () => acceptedPlanning.heatpumpcontrollerimage_set.length,
      getFieldHtml: () => (
        <>
          {acceptedPlanning.heatpumpcontrollerimage_set.map((image, index) => (
            <ImageStage key={index} image={image.image} />
          ))}
        </>
      ),
    },
    {
      type: "custom",
      getFieldHtml: () => (
        <Row>
          <Typography fontSize="h5.fontSize">Austausch</Typography>
          <Typography className="secondary-textcolor">
            Austausch der Heizkörper:{" "}
            {acceptedPlanning.replace_radiators ? <>&#10004;</> : <>&#x2716;</>}
          </Typography>
          {acceptedPlanning.update_radiator_valves ? (
            <Typography className="secondary-textcolor">
              Anzahl Heizkörperventile: {acceptedPlanning.n_radiator_valves}
            </Typography>
          ) : (
            <Typography className="secondary-textcolor">
              Update der Heizkörperventile: <>&#x2716;</>
            </Typography>
          )}
          {acceptedPlanning.replace_heating_circuit_distributor ? (
            <Typography className="secondary-textcolor">
              Anzahl Heizkreisverteiler:{" "}
              {acceptedPlanning.n_heating_circuit_distributor}
            </Typography>
          ) : (
            <Typography className="secondary-textcolor">
              Austausch der Heizkreisverteiler: <>&#x2716;</>
            </Typography>
          )}
        </Row>
      ),
    },
    {
      type: "custom",
      condition: () => acceptedPlanning,
      getFieldHtml: () => (
        <Row>
          <Typography fontSize="h5.fontSize">
            Informationen aus der Planung
          </Typography>
          <Typography className="secondary-textcolor">
            Anzahl Wohneinheiten: {acceptedPlanning.n_flats}
          </Typography>
          <Typography className="secondary-textcolor">
            Anzahl Personen: {acceptedPlanning.n_people}
          </Typography>
          {acceptedPlanning.funding ? (
            <>
              <Typography className="secondary-textcolor">
                Beantragte Fördersumme: {acceptedPlanning.funding_amount}
              </Typography>
              <Typography className="secondary-textcolor">
                Umsetzungfrist: {acceptedPlanning.funding_deadline}
              </Typography>
            </>
          ) : (
            <Typography className="secondary-textcolor">
              Will der Kunde den Antrag selbst stellen?{" "}
              {acceptedPlanning.funding_application_by_customer ? (
                <>&#10004;</>
              ) : (
                <>&#x2716;</>
              )}
            </Typography>
          )}
          <Typography className="secondary-textcolor">
            Ist eine PV-Anlage vorhanden?{" "}
            {acceptedPlanning.pv ? <>&#10004;</> : <>&#x2716;</>}
          </Typography>
          <Typography className="secondary-textcolor">
            Ist ein separater Zähler für die Wärmepumpe gewünscht?{" "}
            {acceptedPlanning.extra_e_meter ? <>&#10004;</> : <>&#x2716;</>}
          </Typography>
          {acceptedPlanning.wallbox ? (
            <>
              <Typography className="secondary-textcolor">
                Anzahl Wallboxen: {acceptedPlanning.n_wallboxes}
              </Typography>
              <Typography className="secondary-textcolor">
                Gesamt kW: {acceptedPlanning.wallboxes_kw}
              </Typography>
            </>
          ) : (
            <Typography className="secondary-textcolor">
              Sind Wallboxen vorhanden? <>&#x2716;</>
            </Typography>
          )}
          <Typography className="secondary-textcolor">
            Anzahl Durchlauferhitzer: {acceptedPlanning.n_flow_heater}
          </Typography>
          {acceptedPlanning.solar_thermal ? (
            <Typography className="secondary-textcolor">
              Soll Solarthermie entfernt werden?{" "}
              {acceptedPlanning.remove_solar_thermal ? (
                <>&#10004;</>
              ) : (
                <>&#x2716;</>
              )}
            </Typography>
          ) : (
            <Typography className="secondary-textcolor">
              Ist eine Solarthermie vorhanden?{" "}
              {acceptedPlanning.solar_thermal ? <>&#10004;</> : <>&#x2716;</>}
            </Typography>
          )}
          {acceptedPlanning.current_system === "oil" && (
            <>
              <Typography className="secondary-textcolor">
                Art des Öltanks: {acceptedPlanning.oil_tank}
              </Typography>
              <Typography className="secondary-textcolor">
                Anzahl Öltanks: {acceptedPlanning.n_oil_tanks}
              </Typography>
              <Typography className="secondary-textcolor">
                Restvolumen in l: {acceptedPlanning.oil_volume}
              </Typography>
            </>
          )}
          <Typography className="secondary-textcolor">
            Soll die Demontage der Bestandsheizung durch Planville erfolgen?{" "}
            {acceptedPlanning.disassembly_current_system ? (
              <>&#10004;</>
            ) : (
              <>&#x2716;</>
            )}
          </Typography>
          <Typography className="secondary-textcolor">
            Datum der Installation der Bestandsheizung:{" "}
            {acceptedPlanning.installation_date_current_system}
          </Typography>
          <Typography className="secondary-textcolor">
            Welches Heizsystem ist vorhanden? {acceptedPlanning.heating_system}
          </Typography>
          {(acceptedPlanning.heating_system === "Fußbodenheizung" ||
            acceptedPlanning.heating_system === "Beide") && (
            <>
              <Typography className="secondary-textcolor">
                Anzahl Heizkreise: {acceptedPlanning.n_flow_heating_circuits}
              </Typography>
              <Typography className="secondary-textcolor">
                Sind Heizkreisverteiler vorhanden?{" "}
                {acceptedPlanning.heating_circuit_distributor ? (
                  <>&#10004;</>
                ) : (
                  <>&#x2716;</>
                )}
              </Typography>
              <Typography className="secondary-textcolor">
                Anzahl Raumthermostate: {acceptedPlanning.n_thermostats}
              </Typography>
              <Typography className="secondary-textcolor">
                Sind in jedem Raum Thermostate vorhanden?{" "}
                {acceptedPlanning.thermostats_in_every_room ? (
                  <>&#10004;</>
                ) : (
                  <>&#x2716;</>
                )}
              </Typography>
            </>
          )}
          <Typography className="secondary-textcolor">
            Art der Raumthermostate:{" "}
            {thermoStatList[acceptedPlanning.thermostats_digital]}
          </Typography>
        </Row>
      ),
    },
    {
      type: "custom",
      name: "PDF Heizlastberechnung",
      condition: () => acceptedPlanning.heating_load_calculation_pdf,
      getFieldHtml: () => (
        <PdfViewModal
          title="Heizlastberechnung"
          filepath={
            acceptedPlanning.heating_load_calculation_pdf
              ? acceptedPlanning.heating_load_calculation_pdf.replace(
                  "http:",
                  PROTOCOL + ":",
                )
              : null
          }
        />
      ),
    },
    {
      type: "custom",
      condition: () => acceptedPlanning.notes,
      getFieldHtml: () => (
        <div style={{ display: "flex" }}>
          <Typography style={{ color: "red", fontWeight: 800, fontSize: 30 }}>
            !
          </Typography>
          <Typography
            className="secondary-textcolor"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Notizen: {acceptedPlanning.notes}
          </Typography>
        </div>
      ),
    },
    {
      type: "custom",
      getFieldHtml: () => (
        <Fragment>
          <Typography fontSize="h5.fontSize" className="secondary-textcolor">
            Komission
          </Typography>
          <Table>
            <thead>
              <tr>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    Zulieferer
                  </Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    Besteller
                  </Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    Lieferdatum
                  </Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    Status
                  </Typography>
                </th>
                <th className="align-middle">
                  <Typography className="secondary-textcolor">
                    Aktion
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {warehouseDeliverySuppliers.map((row) => (
                <tr key={row.id}>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {suppliers.find((e) => e.id === row.supplier)?.name}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {row.employee_name}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {row.delivery_date}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography className="secondary-textcolor">
                      {row.status}
                    </Typography>
                  </td>
                  <td className="align-middle">
                    <Typography
                      className="secondary-textcolor"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <WarehouseDeliveryAmountModal
                        resetParent={getWarehouseProducts}
                        resetState={getWarehouseDeliverySuppliers}
                        isInfo={true}
                        warehouseDeliverySupplier={row}
                        warehouseProducts={warehouseProducts.map(
                          getDefaultSupplierInfo,
                        )}
                        suppliers={suppliers}
                        session={session}
                      />
                      <WarehouseDeliveryAmountModal
                        resetParent={getWarehouseProducts}
                        resetState={getWarehouseDeliverySuppliers}
                        isInfo={false}
                        warehouseDeliverySupplier={row}
                        warehouseProducts={warehouseProducts.map(
                          getDefaultSupplierInfo,
                        )}
                        session={session}
                      />
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Fragment>
      ),
    },
    {
      type: "custom",
      name: "Produkte",
      key: "customproduct_set",
      getFieldHtml: (form, setForm) => (
        <CustomProductItems
          showDescriptions={false}
          showPrices={false}
          construction={form}
          customProducts={sortByOrder(form.customproduct_set)}
          setCustomProducts={(customProducts) =>
            setForm((f) => ({ ...f, customproduct_set: customProducts }))
          }
          session={session}
          customer={project.customer_obj}
          products={products}
          productGroups={productGroups}
          resetProducts={resetProducts}
          amountCols={amountCols}
          fixedProductKeys={[]}
          editableFields={["amount", "price"]}
          stage={"heatpump_construction"}
        />
      ),
    },
  ];
};
