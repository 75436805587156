import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  API_URL_CUSTOMERDUPLICATES,
  API_URL_LEAD,
  API_URL_LEAD_EMAIL,
  API_URL_LEADTYPE,
  API_URL_USER,
} from "../settings";
import { Col, Container, Row } from "reactstrap";
import { DISTINCTCOLORS, getLeadOverview } from "../elements/utils";
import LeadTodos from "./leads/LeadTodos";
import TextField from "../elements/TextField";
import { CustomButton } from "../elements/StyledElements";
import ArchiveLeadModal from "./leads/ArchiveLeadModal";
import CustomerProjectFormModal from "./leads/CustomerProjectFormModal";
import LoadingPage from "../elements/LoadingPage";
import ConfirmationModal from "./shared/ConfirmationModal";
import EmailModal from "./shared/modal_utils/EmailModal";
import DateTimeModal from "./shared/DateTimeModal";
import LeadActivities from "./leads/LeadActivities";
import LeadEmails from "./leads/LeadEmails";
import { isMobileOnly } from "react-device-detect";
import { useLeadsContextContext } from "../contexts/LeadsContext";
import LeadFormModal from "./leads/LeadFormModal";
import CustomModal from "./shared/modal_utils/CustomModal";
import { Typography } from "@mui/material";

const ArchiveLeadButton = ({
  lead,
  resetState,
  session,
  archieveModalState,
  setArchieveModalState,
}) => {
  return lead.archived ? (
    <CustomButton
      onClick={async () => {
        await axios.put(API_URL_LEAD + lead.id, { archived: !lead.archived });
        resetState();
      }}
      icon={"unarchive"}
    >
      Aktivieren
    </CustomButton>
  ) : (
    <ArchiveLeadModal
      isOpenFromParent={archieveModalState.isOpen}
      setIsOpenFromParent={(isOpen) => {
        setArchieveModalState((prev) => {
          return { ...prev, isOpen };
        });
      }}
      callbackOnSubmit={archieveModalState.callbackOnSubmit}
      lead={lead}
      resetParent={resetState}
      session={session}
      getOpenButton={(toggle) => {
        return (
          <CustomButton onClick={toggle} icon={"archive"}>
            Archivieren
          </CustomButton>
        );
      }}
    />
  );
};

ArchiveLeadButton.propTypes = {
  lead: PropTypes.object,
  resetState: PropTypes.func,
  session: PropTypes.object,
  archieveModalState: PropTypes.object,
  setArchieveModalState: PropTypes.func,
};

const LeadCustomerButton = ({
  lead,
  salesmen,
  leadTypes,
  resetState,
  session,
}) => {
  const history = useHistory();

  return lead.customer ? (
    <CustomButton
      onClick={() => {
        history.push(`/kunde/${lead.customer}`);
      }}
      icon={"person"}
    >
      Kundenseite
    </CustomButton>
  ) : (
    <CustomerProjectFormModal
      lead={lead}
      salesmen={salesmen}
      leadTypes={leadTypes}
      resetParent={async ([c, p]) => {
        await axios.put(API_URL_LEAD + lead.id, {
          customer: c.id,
          project: p.id,
        });
        resetState();
      }}
      session={session}
      getOpenButton={(toggle) => {
        return (
          <CustomButton onClick={toggle} icon={"person_add"}>
            Kunde erstellen
          </CustomButton>
        );
      }}
    />
  );
};

LeadCustomerButton.propTypes = {
  lead: PropTypes.object,
  salesmen: PropTypes.array,
  leadTypes: PropTypes.array,
  resetState: PropTypes.func,
  session: PropTypes.object,
};

export default function LeadDetail({ session }) {
  const { nextLead } = useLeadsContextContext();

  const [lead, setLead] = useState(null);
  const [leadTypes, setLeadTypes] = useState(null);
  const [salesmen, setSalesmen] = useState(null);
  const [duplicates, setDuplicates] = useState([]);
  const [archieveModalState, setArchieveModalState] = useState({
    isOpen: false,
    callbackOnSubmit: () => {},
  });
  const [openConfirmationState, setOpenConfirmationState] = useState({
    isOpen: false,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [emailState, setEmailState] = useState({
    title: "",
    subject: "",
    // getEmailMessage params: { key: 'lead_pv' | 'lead_heatpump' | 'lead_reminder' | 'lead_could_not_reach_lead' }
    emailMessage: null,
    submitParameter: {},
    isOpen: false,
    onDone: () => {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dateModalState, setDateModalState] = useState({
    isOpen: false,
    onSave: () => {},
  });
  const [isOpenLeadInfoModal, setIsOpenLeadInfoModal] = useState(false);
  const [isOpenNoSalesmanModal, setIsOpenNoSalesmanModal] = useState(false);

  const setIsOpenEmailModal = (isOpen) => {
    setEmailState({ ...emailState, isOpen });
  };

  const setIsOpenConfirmationModal = (isOpen) => {
    setOpenConfirmationState({ ...openConfirmationState, isOpen });
  };

  const { id } = useParams();

  const getLead = async () => {
    const response = await axios.get(API_URL_LEAD + id, {
      params: { nested: true },
    });
    setLead(response.data);
  };

  useEffect(() => {
    resetState();
  }, [id]);

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (lead) fetchDuplicates();
  }, [lead]);

  const onMount = () => {
    fetchLeadTypes();
    fetchSalesmen();
  };

  const fetchLeadTypes = async () => {
    const response = await axios.get(API_URL_LEADTYPE);
    setLeadTypes(response.data);
  };

  const fetchSalesmen = async () => {
    const response = await axios.get(API_URL_USER, {
      params: { is_staff: true, visible: true, is_active: true },
    });
    const salesmen = response.data
      .filter((user) => ["salesmen", "sales_managers"].includes(user.group_key))
      .map((s, idx) => ({
        ...s,
        area_color: DISTINCTCOLORS[idx % DISTINCTCOLORS.length],
      }));
    setSalesmen(salesmen);
  };

  const fetchDuplicates = async () => {
    const response = await axios.get(API_URL_CUSTOMERDUPLICATES, {
      params: {
        company: lead.gender === "Firma" ? lead.company : "",
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
        phone1: lead.phone1,
        phone2: lead.phone2,
        phone3: lead.phone3,
        oldest: true,
        lead_id: lead.id,
      },
    });
    setDuplicates(response.data);
  };

  const resetState = () => {
    getLead();
  };

  const leadOverview = lead ? getLeadOverview(lead, duplicates) : null;

  if (salesmen === null || leadTypes === null || lead === null)
    return <LoadingPage />;

  document.title = `PV App - ${lead.name}`;

  return (
    <Fragment>
      {isLoading && <LoadingPage />}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <CustomButton
          onClick={() => {
            nextLead();
          }}
          icon={"forward"}
          startIcon={false}
          endIcon={true}
        >
          Nächster Lead
        </CustomButton>
      </div>
      <Container>
        {isMobileOnly
          ? lead && (
              <>
                <Col>{leadOverview}</Col>
                <br />
                <Col
                  className=" align-items-center justify-content-center"
                  style={{ paddingLeft: 50, paddingRight: 50, gap: "0.5rem" }}
                >
                  <Row>
                    <LeadEmails lead={lead} />
                  </Row>
                  <br />
                  <Row>
                    <LeadFormModal
                      session={session}
                      resetParent={resetState}
                      lead={lead}
                      isOpen={isOpenLeadInfoModal}
                      setIsOpen={setIsOpenLeadInfoModal}
                      leadTypes={leadTypes}
                      salesmen={salesmen}
                    />
                  </Row>
                  <br />
                  <Row>
                    <LeadActivities lead={lead} />
                  </Row>
                  <br />
                  <Row>
                    <LeadCustomerButton
                      lead={lead}
                      salesmen={salesmen}
                      leadTypes={leadTypes}
                      resetState={resetState}
                      session={session}
                    />
                  </Row>
                  <hr className="secondary-textcolor" />
                  <Row>
                    <ArchiveLeadButton
                      lead={lead}
                      resetState={resetState}
                      session={session}
                      archieveModalState={archieveModalState}
                      setArchieveModalState={setArchieveModalState}
                    />
                  </Row>
                  <br />
                  <Row>
                    <CustomButton
                      onClick={() => {
                        setEmailState({
                          title: "Lead Reklamieren",
                          subject: "...",
                          emailMessage: "...",
                          isOpen: true,
                          submitParameter: {
                            email_type: "lead_refund",
                          },
                          emailDestination: "...",
                        });
                      }}
                      icon={""}
                    >
                      Lead Reklamieren
                    </CustomButton>
                  </Row>
                </Col>
                <br />
                <Col>
                  <LeadTodos
                    lead={lead}
                    salesmen={salesmen}
                    leadTypes={leadTypes}
                    resetParent={resetState}
                    session={session}
                    setArchieveModalState={setArchieveModalState}
                    setOpenConfirmationState={setOpenConfirmationState}
                    setEmailState={setEmailState}
                    setIsLoading={setIsLoading}
                    setDateModalState={setDateModalState}
                  />
                </Col>
              </>
            )
          : lead && (
              <Row>
                <Col className="col-4">
                  <br />
                  <Row>{leadOverview}</Row>
                  <hr className="secondary-textcolor" />
                  <TextField
                    text={lead.notes}
                    save={async (text) => {
                      await axios.put(API_URL_LEAD + lead.id, { notes: text });
                      resetState();
                    }}
                  />
                  <hr className="secondary-textcolor" />
                  <Row>
                    <Col>
                      <Row>
                        <LeadEmails lead={lead} />
                      </Row>
                      <br />
                      <Row>
                        <LeadFormModal
                          session={session}
                          resetParent={resetState}
                          lead={lead}
                          isOpen={isOpenLeadInfoModal}
                          setIsOpen={setIsOpenLeadInfoModal}
                          leadTypes={leadTypes}
                          salesmen={salesmen}
                        />
                      </Row>
                    </Col>
                    &nbsp;&nbsp;
                    <Col>
                      <Row>
                        <LeadActivities lead={lead} />
                      </Row>
                      <br />
                      <Row>
                        <LeadCustomerButton
                          lead={lead}
                          salesmen={salesmen}
                          leadTypes={leadTypes}
                          resetState={resetState}
                          session={session}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <hr className="secondary-textcolor" />
                  <Row>
                    <ArchiveLeadButton
                      lead={lead}
                      resetState={resetState}
                      session={session}
                      archieveModalState={archieveModalState}
                      setArchieveModalState={setArchieveModalState}
                    />
                  </Row>
                  <br />
                  <Row>
                    <CustomButton
                      onClick={() => {
                        setEmailState({
                          title: "Lead Reklamieren",
                          subject: "...",
                          emailMessage: "...",
                          isOpen: true,
                          submitParameter: {
                            email_type: "lead_refund",
                          },
                          emailDestination: "...",
                        });
                      }}
                      icon={""}
                    >
                      Lead Reklamieren
                    </CustomButton>
                  </Row>
                </Col>
                <Col>
                  <LeadTodos
                    lead={lead}
                    salesmen={salesmen}
                    leadTypes={leadTypes}
                    resetParent={resetState}
                    session={session}
                    setArchieveModalState={setArchieveModalState}
                    setOpenConfirmationState={setOpenConfirmationState}
                    setEmailState={setEmailState}
                    setIsLoading={setIsLoading}
                    setDateModalState={setDateModalState}
                    setIsOpenNoSalesmanModal={setIsOpenNoSalesmanModal}
                  />
                </Col>
              </Row>
            )}
      </Container>
      <br />

      <ConfirmationModal
        isOpenModal={openConfirmationState.isOpen}
        setIsOpenModal={setIsOpenConfirmationModal}
        title={"Mailbox Nachricht hinterlassen?"}
        onConfirm={openConfirmationState.onConfirm}
        onCancel={openConfirmationState.onCancel}
      />

      <EmailModal
        customer={lead}
        title={emailState.title}
        subject={emailState.subject}
        emailMessage={emailState.emailMessage}
        submit={(subject, message, destination) => {
          return axios.post(API_URL_LEAD_EMAIL, {
            lead_id: lead.id,
            email_type: emailState.submitParameter.email_type,
            subject,
            message,
            destination,
          });
        }}
        isOpen={emailState.isOpen}
        setIsOpen={setIsOpenEmailModal}
        session={session}
        getModalOpenButton={() => null}
        emailDestination={emailState.emailDestination}
        onDone={emailState.onDone}
      />

      <DateTimeModal
        title={"Erinnerung setzen"}
        isOpenModal={dateModalState.isOpen}
        setIsOpenModal={(isOpen) => {
          setDateModalState({ ...dateModalState, isOpen });
        }}
        saveText={"Speichern"}
        onSave={dateModalState.onSave}
      />

      <CustomModal
        size="lg"
        title={"Fehler"}
        isOpen={isOpenNoSalesmanModal}
        setIsOpen={setIsOpenNoSalesmanModal}
      >
        <Typography variant="h6">
          Bitte vorher einen Verkäufer auswählen!
        </Typography>
      </CustomModal>
    </Fragment>
  );
}

LeadDetail.propTypes = {
  session: PropTypes.object,
};
