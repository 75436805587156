import { PropTypes } from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import axios from "axios";

import {
  Box,
  Chip,
  IconButton,
  Link,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ListRoundedIcon from "@mui/icons-material/ListRounded";

import {
  addDaysToDate,
  date2FormattedString,
  date2String,
  hexToRGB,
  string2FormattedString,
  useDidMountEffect,
  userWithLabel,
} from "../../elements/utils";
import { API_URL_CONSTRUCTION, API_URL_TASK } from "../../settings";
import CollapsibleTable from "../../elements/CollapsibleTable";
import { CustomIconButton } from "../../elements/StyledElements";
import TaskInfo from "./TaskInfo";
import { getNextDate, getTaskColor, getTaskEmployeeNames } from "./utils";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import Carousel from "../../elements/Carousel";
import Collapsible from "../../elements/Collapsible";
import { TaskActionsMobile } from "./TaskActions";

const getDateTitle = (date) => {
  const today = new Date();
  const dateStr = date2String(date);
  if (dateStr === date2String(today)) return "Heute";
  if (dateStr === date2String(addDaysToDate(today, 1))) return "Morgen";
  if (dateStr === date2String(addDaysToDate(today, -1))) return "Gestern";
  if (dateStr === date2String(addDaysToDate(today, 2))) return "Übermorgen";
  if (dateStr === date2String(addDaysToDate(today, -2))) return "Vorgestern";
  return `${date2FormattedString(date)}`;
};

export default function TaskCarousel({
  includeConstruction = true,
  queryParams,
  resetParent,
  defaultIsOpen = true,
  collapsible = true,
  session,
}) {
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [constructions, setConstructions] = useState(null);
  const [batch, setBatch] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosenTask, setChosenTask] = useState(null);
  const [nPages, setNPages] = useState(null);
  const prevQueryParams = useRef(null);
  const prevDate = useRef(new Date());

  useEffect(() => {
    // ensure that a new request is only done, if the query params change
    // the useEffect is triggered at every rerender, as a new object is created in the parent component for
    if (
      prevDate.current === date &&
      JSON.stringify(queryParams) === JSON.stringify(prevQueryParams.current)
    )
      return;
    prevQueryParams.current = { ...queryParams };
    prevDate.current = date;
    reloadTasks();
    if (batch !== 0) setBatch(0);
    else reloadTasks();
  }, [queryParams, date]);

  useDidMountEffect(() => {
    reloadTasks();
  }, [batch]);

  const reloadTasks = () => {
    const taskParams = {
      ...(queryParams || {}),
      deadline_or_date: date2String(date),
      nested: true,
      order_by: "-priority",
      batch_size: 20,
      batch,
    };
    setLoading(true);
    Promise.all([
      axios.get(API_URL_TASK, { params: taskParams }).then((res) => {
        setTasks(res.data);
        setNPages(res.headers.length);
      }),
      ...(includeConstruction &&
      !queryParams.registered_by &&
      !queryParams.coordinator &&
      !queryParams.task_type &&
      !queryParams.labels &&
      !queryParams.priority &&
      !queryParams.finished
        ? [
            axios
              .get(API_URL_CONSTRUCTION, {
                params: {
                  constructiondates_set: date2String(date),
                  employees: queryParams.employees,
                  search: queryParams.search,
                },
              })
              .then((res) => {
                setConstructions(res.data);
              }),
          ]
        : []),
    ]).then(() => setLoading(false));
  };

  // if (!tasks || tasks.length === 0) return null
  if (!tasks) return null;

  const nTasks =
    (tasks ? tasks.length : 0) + (constructions ? constructions.length : 0);
  return (
    <Fragment>
      <Paper
        elevation={3}
        width="100%"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        <div className="text-center">
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setIsOpen(!isOpen)}
            fontSize="h4.fontsize"
            fontWeight="bold"
          >
            {collapsible &&
              (isOpen ? (
                <KeyboardArrowUp
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(!isOpen)}
                />
              ) : (
                <KeyboardArrowDown
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(!isOpen)}
                />
              ))}{" "}
            {getDateTitle(date)}
          </Typography>
        </div>

        {isOpen && (
          <Carousel
            handleNext={() => setDate(addDaysToDate(date, 1))}
            handlePrev={() => setDate(addDaysToDate(date, -1))}
            loading={loading}
          >
            {nTasks === 0 && (
              <CollapsibleTable
                collapsible={false}
                columns={[{ name: "", key: "col" }]}
                rows={[
                  {
                    col: "Keine Aufgaben vorhanden",
                    style: { textAlign: "center" },
                  },
                ]}
              />
            )}
            {nTasks > 0 && (
              <>
                <Box
                  sx={{ display: { xs: "none", md: "block" }, width: "100%" }}
                >
                  <CollapsibleTable
                    columns={[
                      { name: "Titel", key: "title" },
                      { name: "Kunde", key: "customer" },
                      { name: "Datum", key: "date" },
                      { name: "Mitarbeiter", key: "employees" },
                      { name: "Erstellt von", key: "registered_by" },
                      { name: "Aufgaben-Typ", key: "task_type" },
                      { name: "Labels", key: "labels" },
                    ]}
                    rows={[
                      ...(tasks || []).map((task) => ({
                        key: `task-${task.id}`,
                        style: { background: getTaskColor(task) },
                        // style: task.on_hold ? { background: 'repeating-linear-gradient(-45deg, #a4a4a4, #a4a4a4 5.5px, #f8f8ff 5.5px, #f8f8ff 27.5px)' } : null,
                        title: task.title,
                        date:
                          task.taskdates_set && task.taskdates_set.length > 0
                            ? string2FormattedString(
                                getNextDate(task.taskdates_set),
                              )
                            : "",
                        customer: task.customer_name || "",
                        employees: (
                          <Typography>{getTaskEmployeeNames(task)}</Typography>
                        ),
                        registered_by: userWithLabel(task.registered_by_labels),
                        // link: () => setChosenTask(task),
                        done: task.finished ? "\u2713" : "\u2715",
                        task_type: task.task_type_obj && (
                          <Chip
                            label={task.task_type_obj.name}
                            sx={{
                              backgroundColor: hexToRGB(
                                task.task_type_obj.color,
                                0.7,
                              ),
                            }}
                            size="small"
                          />
                        ),
                        labels: task.label_set.map((t, tIdx) => (
                          <Chip
                            key={`label-${tIdx}`}
                            label={t.name}
                            sx={{ backgroundColor: hexToRGB(t.color, 0.7) }}
                            size="small"
                          />
                        )),
                        child: (
                          <TaskInfo
                            task={task}
                            setTask={(f) =>
                              setTasks((ts) =>
                                ts.map((t) =>
                                  t.id !== task.id
                                    ? t
                                    : typeof f === "function"
                                      ? f(t)
                                      : t,
                                ),
                              )
                            }
                            resetParent={resetParent}
                            session={session}
                          />
                        ),
                      })),
                      ...(constructions || []).map((construction) => ({
                        key: `construction-${construction.id}`,
                        title: `Baustelle ${construction.project_name}`,
                        date:
                          construction.constructiondates_set &&
                          construction.constructiondates_set.length > 0
                            ? string2FormattedString(
                                getNextDate(
                                  construction.constructiondates_set,
                                  date,
                                ),
                              )
                            : "",
                        customer: construction.customer_name,
                        employees: (
                          <Typography>
                            {construction.employee_names.join(", ")}
                          </Typography>
                        ),
                        child: (
                          <Box
                            marginLeft={0}
                            marginTop={0}
                            marginBottom={10}
                            sx={{ textAlign: "left" }}
                          >
                            <Typography
                              fontSize="h6.fontsize"
                              fontWeight="bold"
                            >
                              <Link
                                href={`/projekt/${construction.project}/bauplanung`}
                              >{`Baustelle ${construction.project_name}`}</Link>
                            </Typography>
                            <Typography>
                              <b>Mitarbeiter: </b>
                              {construction.employee_names.join(", ")}
                            </Typography>
                            {construction.constructiondates_set &&
                            construction.constructiondates_set.length > 0 ? (
                              <Typography>
                                <b>Termin: </b>
                                {construction.constructiondates_set
                                  .map((dateObj) =>
                                    string2FormattedString(dateObj.date),
                                  )
                                  .join(", ")}
                              </Typography>
                            ) : null}
                            <Typography>
                              <b>Kunde: </b>
                              <Link href={`/kunde/${construction.customer}`}>
                                {construction.customer_name}
                              </Link>
                            </Typography>
                            <Typography>
                              <b>Projekt: </b>
                              <Link href={`/projekt/${construction.project}`}>
                                {construction.project_name}
                              </Link>
                            </Typography>
                            <Typography>
                              <b>Adresse: </b>
                              <Link
                                href={
                                  'http://maps.google.com/maps?q="' +
                                  encodeURIComponent(
                                    `${construction.street_and_number} ${construction.zip_and_city}`,
                                  ) +
                                  '"'
                                }
                              >{`${construction.street_and_number} ${construction.zip_and_city}`}</Link>
                            </Typography>
                          </Box>
                        ),
                      })),
                    ]}
                    collapsible={true}
                  />
                </Box>
                <Box
                  sx={{ display: { xs: "block", md: "none" }, width: "100%" }}
                >
                  {(tasks || []).map((task) => (
                    <Collapsible
                      key={`task-${task.id}`}
                      getOpenButton={(toggle) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          paddingY={1}
                          flex={1}
                          style={{ background: getTaskColor(task) }}
                        >
                          <Typography onClick={toggle} flexGrow={1}>
                            {task.title}
                          </Typography>
                          <Stack direction="row">
                            <IconButton onClick={toggle} size="sm">
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setChosenTask(task.id);
                              }}
                              size="sm"
                              aria-describedby="simple-popper"
                            >
                              <ListRoundedIcon fontSize="small" />
                            </IconButton>
                            <Popover
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              id="simple-popper"
                              open={chosenTask === task.id && Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => {
                                setAnchorEl(null);
                                setChosenTask(null);
                              }}
                            >
                              <TaskActionsMobile
                                task={task}
                                setTask={(f) =>
                                  setTasks((ts) =>
                                    ts.map((t) =>
                                      t.id !== task.id
                                        ? t
                                        : typeof f === "function"
                                          ? f(t)
                                          : t,
                                    ),
                                  )
                                }
                                resetParent={() => {
                                  setAnchorEl(null);
                                  resetParent();
                                }}
                                session={session}
                              />
                            </Popover>
                          </Stack>
                        </Stack>
                      )}
                    >
                      <TaskInfo
                        task={task}
                        session={session}
                        withAction={false}
                      />
                    </Collapsible>
                  ))}
                  {(constructions || []).map((construction) => (
                    <Collapsible
                      key={`construction-${construction.id}`}
                      getOpenButton={(toggle) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          paddingY={1}
                          flex={1}
                        >
                          <Typography onClick={toggle} flexGrow={1}>
                            Baustelle {construction.project_name}
                          </Typography>
                          <IconButton onClick={toggle} size="sm">
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      )}
                    >
                      <Box
                        marginTop={1}
                        marginBottom={1}
                        paddingX={3}
                        paddingBottom={1}
                        borderBottom="1px solid #00000055"
                        sx={{ textAlign: "left" }}
                      >
                        <Typography fontSize="h6.fontsize" fontWeight="bold">
                          <Link
                            href={`/projekt/${construction.project}/bauplanung`}
                          >
                            {`Baustelle ${construction.project_name}`}
                          </Link>
                        </Typography>
                        <Typography>
                          <b>Kunde: </b>
                          <Link href={`/kunde/${construction.customer}`}>
                            {construction.customer_name}
                          </Link>
                        </Typography>
                        <Typography>
                          <b>Datum: </b>{" "}
                          {string2FormattedString(
                            construction.constructiondates_set.filter(
                              (dateObj) => dateObj.date === date2String(date),
                            )[0],
                          )}
                        </Typography>
                        <Typography>
                          <b>Mitarbeiter: </b>{" "}
                          {construction.employee_names.join(", ")}
                        </Typography>
                        {construction.constructiondates_set &&
                        construction.constructiondates_set.length > 0 ? (
                          <Typography>
                            <b>Termin: </b>
                            {construction.constructiondates_set
                              .map((dateObj) =>
                                string2FormattedString(dateObj.date),
                              )
                              .join(", ")}
                          </Typography>
                        ) : null}
                        <Typography>
                          <b>Projekt: </b>
                          <Link href={`/projekt/${construction.project}`}>
                            {construction.project_name}
                          </Link>
                        </Typography>
                        <Typography>
                          <b>Adresse: </b>
                          <Link
                            href={
                              'http://maps.google.com/maps?q="' +
                              encodeURIComponent(
                                `${construction.street_and_number} ${construction.zip_and_city}`,
                              ) +
                              '"'
                            }
                          >{`${construction.street_and_number} ${construction.zip_and_city}`}</Link>
                        </Typography>
                      </Box>
                    </Collapsible>
                  ))}
                </Box>
              </>
            )}
            {nPages && nPages > 1 ? (
              <Stack direction="row" spacing={2}>
                <CustomIconButton
                  disabled={batch === 0}
                  icon="previous"
                  onClick={() => setBatch(batch - 1)}
                />
                <Typography className="secondary-textcolor">
                  {batch + 1}
                </Typography>
                <CustomIconButton
                  disabled={batch === nPages - 1}
                  icon="next"
                  onClick={() => setBatch(batch + 1)}
                />
              </Stack>
            ) : null}
          </Carousel>
        )}
      </Paper>
    </Fragment>
  );
}

TaskCarousel.propTypes = {
  includeConstruction: PropTypes.bool,
  header: PropTypes.string,
  queryParams: PropTypes.object,
  resetParent: PropTypes.func,
  boxStyle: PropTypes.object,
  defaultIsOpen: PropTypes.bool,
  collapsible: PropTypes.bool,
  session: PropTypes.object,
};
