import React, { useState, Fragment } from "react";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import HeatpumpImagePlanning from "./HeatpumpImagePlanning";
import CropImage from "./CropImage";

import CustomModal from "../modal_utils/CustomModal";

import { CustomButton } from "../../../elements/StyledElements";
import { heatpumpPropType } from "../../../elements/PropTypes";
import { round } from "../../../elements/utils";
import UploadImage from "./UploadImage";

export default function HeatpumpImageUpload({
  heatpump,
  addHeatpumpImage,
  winWidth,
  winHeight,
  session,
}) {
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [currentHeatpumpImage, setCurrentHeatpumpImage] = useState(null);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [heatpumpImageScale, setHeatpumpImageScale] = useState({
    w: 0,
    h: 0,
    r: 0,
  });

  const [page, setPage] = useState(0);
  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onToggle = (isOpen) => {
    if (!isOpen) clearData();
  };

  const clearData = () => {
    setPage(0);
    setCurrentHeatpumpImage(null);
    setOriginalImage(null);
    setCroppedImage(null);
  };

  const upload = () => {
    addHeatpumpImage(
      currentHeatpumpImage,
      croppedImage,
      heatpumpImageScale,
      currentAngle,
      currentPosition,
    );
  };

  const getOpenButton = (toggle) => {
    return (
      <CustomButton id="upload-new-roof-img-btn" onClick={toggle}>
        Neues Foto hochladen
      </CustomButton>
    );
  };

  const next = () => {
    if (page === 0) setCroppedImage(originalImage); // 0 (Upload) -> 1 (Crop)
    if (page === 1) setCurrentHeatpumpImage(croppedImage); // 1 (Crop) -> 2 (Heatpump)
    nextPage();
  };

  const previous = () => {
    if (page === 1) setCroppedImage(null); // 0 (Upload) <- 1 (Crop)
    if (page === 2) {
      setCurrentHeatpumpImage(null);
      setCroppedImage(originalImage);
    } // 1 (Crop) <- 2 (Heatpump)
    previousPage();
  };

  const getFooter = (toggle) => {
    return (
      <div>
        {page !== 0 ? (
          <CustomButton color={"#ffffff"} onClick={previous}>
            Zurück
          </CustomButton>
        ) : null}
        &nbsp; &nbsp;
        {(page === 0 && originalImage) || (page === 1 && croppedImage) ? (
          <CustomButton
            id="upload-heatpump-img-next-btn"
            color={"#ffffff"}
            onClick={next}
          >
            Weiter
          </CustomButton>
        ) : null}
        {page === 2 ? (
          <CustomButton
            id="finish-upload-heatpump-image-steps-btn"
            color={"#ffffff"}
            onClick={() => {
              upload();
              toggle();
            }}
          >
            Bild hinzufügen
          </CustomButton>
        ) : null}
      </div>
    );
  };

  return (
    <Fragment>
      <CustomModal
        size="fullscreen"
        getOpenButton={getOpenButton}
        getFooter={getFooter}
        title="Foto hochladen"
        onToggle={onToggle}
      >
        {page === 0 ? (
          <>
            <Typography fontSize="h6.fontSize" className="secondary-textcolor">
              Foto auswählen:
            </Typography>
            <br />
            <UploadImage
              id="roof-img-input"
              image={originalImage}
              setImage={setOriginalImage}
              maxWidth={round((95 / 100) * winWidth)}
              maxHeight={round((80 / 100) * winHeight)}
            />
          </>
        ) : null}
        {page === 1 ? (
          <CropImage
            image={originalImage}
            setCroppedImage={setCroppedImage}
            maxImageWidth={round((95 / 100) * winWidth)}
            maxImageHeight={round((85 / 100) * winHeight)}
          />
        ) : null}
        {page === 2 ? (
          <HeatpumpImagePlanning
            image={croppedImage}
            setImage={setCurrentHeatpumpImage}
            heatpump={heatpump}
            angle={currentAngle}
            setAngle={setCurrentAngle}
            heatpumpImageScale={heatpumpImageScale}
            setHeatpumpImageScale={setHeatpumpImageScale}
            heatpumpPosition={currentPosition}
            setHeatpumpPosition={setCurrentPosition}
            maxWidth={round((95 / 100) * winWidth)}
            maxHeight={round((80 / 100) * winHeight)}
          />
        ) : null}
      </CustomModal>
    </Fragment>
  );
}

HeatpumpImageUpload.propTypes = {
  heatpump: heatpumpPropType,
  addHeatpumpImage: PropTypes.func,
  winWidth: PropTypes.number,
  winHeight: PropTypes.number,
  session: PropTypes.object,
};
