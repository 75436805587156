import React, { Fragment } from "react";
import { PropTypes } from "prop-types";

import { Typography } from "@mui/material";

import DocumentItemList from "./DocumentItemList";
import AddDocumentItemModal from "./AddDocumentItemModal";
import { numFormatter, round, sortByOrder } from "../../elements/utils";

export default function DocumentItems({
  documentItems,
  changeDocumentItem,
  allItems,
  productGroups,
  project,
  addDocumentItem,
  deleteDocumentItem,
  changeDocumentItemsOrder,
  amountCols,
  showPrices = true,
  showDescriptions = false,
  fixedProductKeys = null,
  isDraggable,
  editableFields,
  session,
  lineMappings,
  setLineMappings,
  stage = null,
}) {
  const getNetPrice = () => {
    let price = 0;
    documentItems.forEach((item) => {
      if (!item.optional) price += round(item.amount * item.price, 2);
    });
    return round(price, 2);
  };

  const getGrossPrice = () => {
    let price = 0;
    documentItems.forEach((item) => {
      const factor = item.vat ? 1.19 : 1;
      if (!item.optional)
        price += round(round(item.amount * item.price, 2) * factor, 2);
    });
    return round(price, 2);
  };

  return (
    <Fragment>
      <DocumentItemList
        documentItems={sortByOrder(documentItems)}
        changeDocumentItem={changeDocumentItem}
        deleteDocumentItem={deleteDocumentItem}
        changeDocumentItemsOrder={changeDocumentItemsOrder}
        amountCols={amountCols}
        priceCol={showPrices}
        showDescriptions={showDescriptions}
        fixedProductKeys={fixedProductKeys}
        isDraggable={isDraggable}
        editableFields={editableFields}
        project={project}
        session={session}
        lineMappings={lineMappings}
        setLineMappings={setLineMappings}
        stage={stage}
      />
      {showPrices ? (
        <div style={{ float: "right" }}>
          <Typography className="secondary-textcolor">
            <strong> Netto: &nbsp;{numFormatter(getNetPrice())} €</strong>
            <br />
            <strong> Brutto: {numFormatter(getGrossPrice())} €</strong>
          </Typography>
        </div>
      ) : null}
      <br />
      <AddDocumentItemModal
        allItems={allItems.filter(
          (p) => !documentItems.map((cp) => cp.id).includes(p.id),
        )}
        productGroups={productGroups}
        project={project}
        addDocumentItem={addDocumentItem}
        showPrices={showPrices}
        fixedProductKeys={fixedProductKeys}
        session={session}
      />
    </Fragment>
  );
}

DocumentItems.propTypes = {
  documentItems: PropTypes.arrayOf(PropTypes.object),
  changeDocumentItem: PropTypes.func,
  allItems: PropTypes.arrayOf(PropTypes.object),
  productGroups: PropTypes.arrayOf(PropTypes.object),
  project: PropTypes.object,
  addDocumentItem: PropTypes.func,
  deleteDocumentItem: PropTypes.func,
  changeDocumentItemsOrder: PropTypes.func,
  amountCols: PropTypes.object,
  showPrices: PropTypes.bool,
  showDescriptions: PropTypes.bool,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  isDraggable: PropTypes.bool,
  editableFields: PropTypes.arrayOf(PropTypes.string),
  session: PropTypes.object,
  lineMappings: PropTypes.object,
  setLineMappings: PropTypes.func,
  stage: PropTypes.string,
};
